import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { format, parseISO } from "date-fns";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SpeedIcon from "@mui/icons-material/Speed";
import Button from "@material-ui/core/Button";

import { guesser } from "./helpers/iconGuesser";

import "./fonts/fonts.css";
import "../MapB/windmarker/fonts/fonts.css";
import "./view.css";

function getCardinalDirection(angle) {
  const directions = [
    "↑ S",
    "↗ SW",
    "→ W",
    "↘ NW",
    "↓ N",
    "↙ NE",
    "← E",
    "↖ SE",
  ];
  return directions[Math.round((angle + 180) / 45) % 8];
}

function WindMarker(props) {
  const {
    currentCity,
    currentWindSpeed,
    currentWindDirection,
    refreshLocation,
  } = props;
  const cityData = currentCity || "Obtaining Data";
  const windSpeedData = currentWindSpeed || "Obtaining Data";
  const windDirectionData = currentWindDirection || 0;
  return (
    <div>
      <div
        style={{
          padding: "0px",
          fontSize: "7pt",
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        {cityData}
      </div>
      <div>
        <Grid container spacing={0}>
          <Grid item container xs={4} justifyContent="center">
            <ExpandLessIcon
              sx={{ fontSize: 40 }}
              style={{ transform: `rotate(${180 + windDirectionData}deg)` }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            display="flex"
            justifyContent="center"
            xs={8}
          >
            {windDirectionData} Degrees
          </Grid>
          <Grid item container xs={4} justifyContent="center">
            <SpeedIcon sx={{ fontSize: 30 }} />
          </Grid>
          <Grid
            item
            container
            direction="column"
            display="flex"
            justifyContent="center"
            xs={8}
          >
            {(windSpeedData * 1.944).toFixed(2)} knots
          </Grid>
          <Grid item container xs={11} justifyContent="center">
            <Button
              style={{ margin: 10 }}
              color="primary"
              variant="contained"
              onClick={refreshLocation}
              size="small"
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function WeatherForecastView() {
  const [latestForecastsLocalData, setLatestForecastsLocalData] = useState("");
  const [latestForecasts, setForecasts] = useState("");
  const [fullForcastData, setFullForcastData] = useState("");
  const [currentCity, setcurrentCity] = useState("");
  const [currentWindSpeed, setCurrentWindSpeed] = useState("");
  const [currentWindDirection, setCurrentWindDirection] = useState("");
  const [initializedCurrentLocation, setInitializedCurrentLocation] =
    useState(false);
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const envVar = useSelector((state) => state.envVar);

  const openWeatherApiKey = envVar["api_key-openweather"].Value;
  useEffect(() => {
    // Default Location
    getWeatherForcast(1.2986167322092141, 103.78950155981211);
    // Below, called before mapbox loaded, will crash foh. But may be needed if wish to make location dynamic
    // console.log("MAPBOX CONTROLER : ", mapboxController.map.getCenter());
  }, []);

  useEffect(() => {
    setCurrentWindSpeed(fullForcastData?.data?.list[0].wind.speed);
    setCurrentWindDirection(fullForcastData?.data?.list[0].wind.deg);
    setcurrentCity(
      `${fullForcastData?.data?.city.name} - ${fullForcastData?.data?.city.country}`
    );

    // To Handle any default location
    if (
      initializedCurrentLocation === false &&
      fullForcastData?.data?.city.country
    ) {
      try {
        const { lng, lat } = mapboxController.map.getCenter();
        getWeatherForcast(lat, lng);
      } catch (err) {
        setInitializedCurrentLocation(false);
      }
      setInitializedCurrentLocation(true);
    }
  }, [fullForcastData]);

  const getWeatherForcast = async (latURL, longURL) => {
    let localForcast;
    // Worldwide Weather Data
    const weatherForcast = await axios.get(
      `https://api.openweathermap.org/data/2.5/forecast?lat=${latURL}&lon=${longURL}&appid=${openWeatherApiKey}&units=metric`
    );
    // SG Weather Data
    const currentAndForcastWeather = [];
    try {
      axios
        .get(
          "https://api.data.gov.sg/v1/environment/24-hour-weather-forecast",
          {
            responseType: "json",
          }
        )
        .then((res) => {
          const json = res.data;
          const resultItem = json.items[0];
          const generalWeather = resultItem.general;
          generalWeather.timestamp = new Date(); // 24H forcast does not provide current time
          currentAndForcastWeather.push(generalWeather);
          // currentAndForcastWeather.push(temp24HForcastLocal[0]);
        });
      await axios
        .get("https://api.data.gov.sg/v1/environment/4-day-weather-forecast", {
          responseType: "json",
        })
        .then((res) => {
          // Sometimes in morning, data.gov does not provide data
          const json = res.data;
          const resultItem = json.items[0];
          const forecasts = resultItem.forecasts.map((forecast) => {
            const newForecast = forecast;
            const date = parseISO(forecast.timestamp);
            newForecast.formattedDate = format(
              date,
              "EEE - d MMM"
            ).toUpperCase();
            return newForecast;
          });
          currentAndForcastWeather.push(...forecasts);
          // currentAndForcastWeather.push(...tempForcastLocal);
          setLatestForecastsLocalData(currentAndForcastWeather);
          localForcast = currentAndForcastWeather;
        });
    } catch (err) {
      console.log("ERROR : not recieving data.gov data");
    }
    const weatherArr = [];
    if (
      weatherForcast?.data?.city.country === "SG" &&
      latestForecastsLocalData
    ) {
      for (let i = 0; i < localForcast?.length; ) {
        weatherArr.push({
          forecast: localForcast[i].forecast,
          relative_humidity: `${localForcast[i].relative_humidity.high} - ${localForcast[i].relative_humidity.low}`,
          temperature: {
            low: localForcast[i].temperature.low,
            high: localForcast[i].temperature.high,
          },
          wind: {
            speed: localForcast[i].wind.speed.high / 1.852,
            speed_low: localForcast[i].wind.speed.low / 1.852,
            direction: localForcast[i].wind.direction,
          },
          formattedDate: format(
            new Date(localForcast[i].timestamp),
            "EEE dd MMM"
          ).toString(),
        });
        i += 1;
      }
    } else {
      for (let i = 0; i < 37; ) {
        weatherArr.push({
          forecast: weatherForcast.data.list[i].weather[0].description,
          relative_humidity: weatherForcast.data.list[i].main.humidity,
          temperature: {
            low: weatherForcast.data.list[i].main.temp_min,
            high: weatherForcast.data.list[i].main.temp_max,
          },
          wind: {
            speed: weatherForcast.data.list[i].wind.speed * 1.944,
            direction: getCardinalDirection(
              weatherForcast.data.list[i].wind.deg
            ),
          },
          // formattedDate: weatherForcast.data.list[i].dt_txt,
          formattedDate: format(
            new Date(weatherForcast.data.list[i].dt_txt),
            "EEE dd MMM"
          ).toString(),
        });
        i += 8;
      }
    }
    setFullForcastData(weatherForcast);
    setForecasts(weatherArr);
  };

  const refreshLocation = () => {
    // Get current location
    const { lng, lat } = mapboxController.map.getCenter();
    getWeatherForcast(lat, lng);
  };
  if (
    latestForecasts &&
    process.env.REACT_APP_USE_OPENWEATHER_API.toUpperCase() === "TRUE"
  ) {
    return (
      <div style={{ marginBottom: "10px" }}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecasts[0]?.formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecasts[0]?.forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecasts[0]?.forecast}
                  >
                    {latestForecasts[0]?.forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>
                      {latestForecasts[0]?.temperature.high.toFixed(2)}℃ ↑
                      <br />
                      {latestForecasts[0]?.temperature.low.toFixed(2)}℃ ↓
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>
                      {latestForecasts[0]?.wind.direction} <br />
                      {latestForecasts[0]?.wind.speed.toFixed(2)} knots
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecasts[0]?.relative_humidity} %`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecasts[1]?.formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecasts[1]?.forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecasts[1]?.forecast}
                  >
                    {latestForecasts[1]?.forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>
                      {latestForecasts[1]?.temperature.high.toFixed(2)}℃ ↑
                      <br />
                      {latestForecasts[1]?.temperature.low.toFixed(2)}℃ ↓
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>
                      {latestForecasts[1]?.wind.direction} <br />
                      {latestForecasts[1]?.wind.speed.toFixed(2)} knots
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecasts[1]?.relative_humidity} %`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecasts[2]?.formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecasts[2]?.forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecasts[2]?.forecast}
                  >
                    {latestForecasts[2]?.forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>
                      {latestForecasts[2]?.temperature.high.toFixed(2)}℃ ↑
                      <br />
                      {latestForecasts[2]?.temperature.low.toFixed(2)}℃ ↓
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>
                      {latestForecasts[2]?.wind.direction} <br />
                      {latestForecasts[2]?.wind.speed.toFixed(2)} knots
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecasts[2]?.relative_humidity} %`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecasts[3]?.formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecasts[3]?.forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecasts[3]?.forecast}
                  >
                    {latestForecasts[3]?.forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>
                      {latestForecasts[3]?.temperature.high.toFixed(2)}℃ ↑
                      <br />
                      {latestForecasts[3]?.temperature.low.toFixed(2)}℃ ↓
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>
                      {latestForecasts[3]?.wind.direction} <br />
                      {latestForecasts[3]?.wind.speed.toFixed(2)} knots
                    </span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecasts[3]?.relative_humidity} %`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginLeft: "5px", fontSize: "10px" }}>
              <WindMarker
                currentCity={currentCity}
                currentWindSpeed={currentWindSpeed}
                currentWindDirection={currentWindDirection}
                refreshLocation={refreshLocation}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  if (latestForecastsLocalData) {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecastsLocalData[0].formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecastsLocalData[0].forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecastsLocalData[0]?.forecast}
                  >
                    {latestForecastsLocalData[0].forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>{`${latestForecastsLocalData[0].temperature.low}﹣${latestForecastsLocalData[0].temperature.high}℃`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>{`${latestForecastsLocalData[0].wind.direction} ${latestForecastsLocalData[0].wind.speed.low}﹣${latestForecastsLocalData[0].wind.speed.high} km/h`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecastsLocalData[0].relative_humidity.low}﹣${latestForecastsLocalData[0].relative_humidity.high}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecastsLocalData[1].formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecastsLocalData[1].forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecastsLocalData[1]?.forecast}
                  >
                    {latestForecastsLocalData[1].forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>{`${latestForecastsLocalData[1].temperature.low}﹣${latestForecastsLocalData[1].temperature.high}℃`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>{`${latestForecastsLocalData[1].wind.direction} ${latestForecastsLocalData[1].wind.speed.low}﹣${latestForecastsLocalData[1].wind.speed.high} km/h`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecastsLocalData[1].relative_humidity.low}﹣${latestForecastsLocalData[1].relative_humidity.high}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecastsLocalData[2].formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecastsLocalData[2].forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecastsLocalData[1].forecast}
                  >
                    {latestForecastsLocalData[1].forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>{`${latestForecastsLocalData[2].temperature.low}﹣${latestForecastsLocalData[2].temperature.high}℃`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>{`${latestForecastsLocalData[2].wind.direction} ${latestForecastsLocalData[2].wind.speed.low}﹣${latestForecastsLocalData[2].wind.speed.high} km/h`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecastsLocalData[2].relative_humidity.low}﹣${latestForecastsLocalData[2].relative_humidity.high}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ padding: "0px", fontSize: "10px" }}>
              <div
                style={{
                  padding: "0px",
                  fontSize: "7pt",
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                {latestForecastsLocalData[3].formattedDate}
              </div>

              <div key="weathercontainer" className="weather-container">
                <div className="weather-title" key="title">
                  <i
                    className={`${guesser(
                      latestForecastsLocalData[2].forecast
                    )} weather-icon forecast-padding`}
                  />
                  <span
                    className="weather-title-text"
                    title={latestForecastsLocalData[1].forecast}
                  >
                    {latestForecastsLocalData[1].forecast}
                  </span>
                </div>
                <div className="weather-details">
                  <div className="weather-row">
                    <i className="icon-ht-temperature weather-row-icon" />
                    <span>{`${latestForecastsLocalData[3].temperature.low}﹣${latestForecastsLocalData[3].temperature.high}℃`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-wind-wind weather-row-icon" />
                    <span>{`${latestForecastsLocalData[3].wind.direction} ${latestForecastsLocalData[3].wind.speed.low}﹣${latestForecastsLocalData[3].wind.speed.high} km/h`}</span>
                  </div>
                  <div className="weather-row">
                    <i className="icon-ht-humidity weather-row-icon" />
                    <span>{`${latestForecastsLocalData[3].relative_humidity.low}﹣${latestForecastsLocalData[3].relative_humidity.high}%`}</span>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return "";
}

export default WeatherForecastView;
