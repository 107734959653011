import axios from "axios";
import { subMinutes, format, parseISO } from "date-fns";
import { getCurrentRoundedTime } from "../utils/floorTimeTo5Mins";

const iso8601Format = "yyyy-MM-dd'T'HH:mm:ss";

/**
 * Data is fetched twice for speed and direction, once at current time floored to nearest 5 minutes, another is 6 minutes before.
 * This is because the data.gov.sg apis contains stations that updates at different intervals to others, and this method,
 * ensures that most recent updates are correctly captured.
 * All the data is then compiled as 1 object. If newer data exist, older data is replaced.
 * @returns {{}} compiled wind data.
 */
export async function fetchWindData() {
  const roundedTime = getCurrentRoundedTime();
  const offsettedTime = subMinutes(roundedTime, 6);

  const speedURL = "https://api.data.gov.sg/v1/environment/wind-speed";
  const directionURL = "https://api.data.gov.sg/v1/environment/wind-direction";

  const commonParams = {
    now: { params: { date_time: format(roundedTime, iso8601Format) } },
    previous: { params: { date_time: format(offsettedTime, iso8601Format) } },
  };

  const promises = [
    ...dataPromiseMaker(speedURL, commonParams),
    ...dataPromiseMaker(directionURL, commonParams),
  ];

  const results = await Promise.all(promises);
  let resultData = {};

  for (let i = 0; i < results.length; i += 1) {
    resultData = compiler(results[i], resultData, i < 2);
  }

  return resultData;
}

/**
 * Generate promises
 * @param {string} url of the api to call.
 * @param {{params: {}}} params GET parameters for the api.
 * @returns {[Promise<{}>]} axios promises for api calls.
 */
function dataPromiseMaker(url, params) {
  return [
    axios
      .get(url, { responseType: "json", ...params.now })
      .then((res) => res.data),
    axios
      .get(url, { responseType: "json", ...params.previous })
      .then((res) => res.data),
  ];
}

/**
 * Compiles newest and second newest data for both wind speed and direction, into 1 object.
 * @param {{}} data fetched, unprocessed data
 * @param {{}} source previously compiled object or {}
 * @param {boolean} isSpeed true if expected data type is for speed, false for direction.
 * @returns {{}} object
 */
function compiler(data, source, isSpeed) {
  const metadata = data.metadata.stations;
  const firstItem = data.items[0];

  const { readings, timestamp } = firstItem;

  const parsedTime = parseISO(timestamp);
  const dateFormat = "dd MMM yyyy, h:mm a";
  const formattedTime = `As of ${format(parsedTime, dateFormat)}`;

  const processedData = source;
  for (const station of metadata) {
    const { id, name, location } = station;

    if (Object.prototype.hasOwnProperty.call(processedData, id)) {
      continue;
    }

    const { latitude, longitude } = location;

    processedData[id] = { name, coordinates: [longitude, latitude] };
  }

  const readingKey = isSpeed ? "speed" : "direction";

  for (const reading of readings) {
    const id = reading.station_id;
    if (Object.prototype.hasOwnProperty.call(processedData[id], readingKey)) {
      continue;
    }
    const { value } = reading;
    processedData[id][readingKey] = parseFloat(value).toFixed(1);
    processedData[id].time = formattedTime;
  }

  return processedData;
}
