import AllOutIcon from "@mui/icons-material/AllOut";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GroupsIcon from "@mui/icons-material/Groups";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import TaskIcon from "@mui/icons-material/Task";
import TopicIcon from "@mui/icons-material/Topic";
import SendIcon from "@mui/icons-material/Send";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import VideoLibrary from "@mui/icons-material/VideoLibrary";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { memo, useContext, useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { AppColourTheme } from "../../contexts/AppColourTheme";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { Colours } from "../../themes/Colours";
import { heronTheme } from "../../themes/heronTheme";
import NotificationSound from "../../alert_sound.mp3";
import { store } from "../../store";

const drawerWidth = 190;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 3px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 3px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sidebar({
  handleSidebarWidthChange,
  haveNewNotifications,
  setHaveNewNotifications,
  notifications,
  onOpenAssetManagement,
  onOpenFlightAuthorization,
  onOpenFlightAuthorizationAuthority,
  onOpenDronePortOperations,
  onOpenConstrainMgrAuthority,
  onOpenRemoteIdTrackerList,
  onOpenNotifications,
  onOpenDroneOperation,
  onOpenIncidentReporting,
  onOpenSchedules,
  onOpenOperatorManagement,
  onOpenConstraintPanel,
  onOpenReplayComponent,
  openAssetManagement,
  openNotifications,
  openDroneOperation,
  openFlightAuthorization,
  openFlightAuthorizationAuthority,
  openDronePortOperations,
  openConstrainMgrAuthority,
  openRemoteIdTrackerList,
  openIncidentReporting,
  openSchedules,
  openOperatorManagement,
  openConstraintPanel,
  openReplayFunction,
}) {
  // states are accessed directly from protected instead of having own state here.
  // can be improved and refactored.

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [badgeColor, setBadgeColor] = useState("red");
  const [badgeVisible, setBadgeVisible] = useState(false);
  const [NotificationPlay] = useSound(NotificationSound);
  const highestSeverity = useRef(0);
  const disableBadgeOnInitialRender = useRef(true); // disable new notification badge during first render
  const [sidebarNewNotifs, setSidebarNewNotifs] =
    useState(haveNewNotifications);
  const [env, setEnv] = useState("");

  const { colourTheme } = useContext(AppColourTheme);
  const { logout, username, isOperator, isAuthority, isPilot, isRemoteId } =
    useCognitoAuth();

  useEffect(() => {
    if (!store.getState().envVar["base_url-sp"]) return;
    const currenteEnvironment =
      store.getState().envVar["base_url-sp"].Value ===
      "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";
    setEnv(currenteEnvironment);
  }, []);
  const handleLogout = () => {
    logout();
  };

  let accountType = "Airbridge";
  if (isOperator) {
    accountType = "Airbridge Operator";
  }
  if (isAuthority) {
    accountType = "Airbridge Authority";
  }
  if (isPilot) {
    accountType = "Airbridge Pilot";
  }
  if (isRemoteId) {
    accountType = "Airbridge Remote Id";
  }

  const handleDrawerOpen = () => {
    handleSidebarWidthChange(drawerWidth);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    handleSidebarWidthChange(50);
    setOpen(false);
  };

  const handleOpenAssetManagement = () => {
    onOpenAssetManagement();
  };

  const handleOpenFlightAuthorization = () => {
    onOpenFlightAuthorization();
  };

  const handleOpenFlightAuthorizationAuthority = () => {
    onOpenFlightAuthorizationAuthority();
  };

  const handleOpenDronePortOperations = () => {
    onOpenDronePortOperations();
  };

  const handleOpenConstrainMgrAuthority = () => {
    onOpenConstrainMgrAuthority();
  };

  const handleOpenOperatorManagement = () => {
    onOpenOperatorManagement();
  };
  const handleOpenNotifications = () => {
    setBadgeVisible(false);
    highestSeverity.current = 0;
    onOpenNotifications();
  };
  const handleOpenDroneOperation = () => {
    onOpenDroneOperation();
  };
  const handleOpenRemoteIdTrackerList = () => {
    onOpenRemoteIdTrackerList();
  };
  const handleOpenIncidentReporting = () => {
    onOpenIncidentReporting();
  };

  const handleOpenSchedules = () => {
    onOpenSchedules();
  };

  const handleOpenConstraintPanel = () => {
    onOpenConstraintPanel();
  };
  const handleOpenReplayComponent = () => {
    onOpenReplayComponent();
  };

  const notificationsIcon = badgeVisible ? (
    <Badge
      badgeContent=""
      color="success"
      sx={{
        "& .MuiBadge-badge": {
          color: "",
          backgroundColor: badgeColor,
          height: 15,
          minWidth: 15,
          borderRadius: 10,
        },
      }}
      variant="dot"
      overlap="circular"
    >
      <NotificationsIcon />
    </Badge>
  ) : (
    <NotificationsIcon />
  );

  const operatorDrawer = [
    {
      text: "Asset Management",
      icon: <Inventory2Icon />,
      onClick: handleOpenAssetManagement,
      state: openAssetManagement,
    },
    {
      text: "Flight Authorization",
      icon: <TaskIcon />,
      onClick: handleOpenFlightAuthorization,
      state: openFlightAuthorization,
    },
    {
      text: "Drone Port Operations",
      icon: <DirectionsBoatIcon />,
      onClick: handleOpenDronePortOperations,
      state: openDronePortOperations,
    },
    {
      text: "Flight Logs",
      icon: <TopicIcon />,
      onClick: handleOpenSchedules,
      state: openSchedules,
    },
    {
      text: "Incident Reporting",
      icon: <AssignmentLateIcon />,
      onClick: handleOpenIncidentReporting,
      state: openIncidentReporting,
    },
    {
      text: "Notifications",
      icon: notificationsIcon,
      onClick: handleOpenNotifications,
      state: openNotifications,
    },
    {
      text: "Drone Imported Ops",
      icon: <SendIcon />,
      onClick: handleOpenDroneOperation,
      state: openDroneOperation,
    },
  ];

  const pilotDrawer = [
    {
      text: "Flight Authorization",
      icon: <TaskIcon />,
      onClick: handleOpenFlightAuthorization,
      state: openFlightAuthorization,
    },
    {
      text: "Flight Logs",
      icon: <TopicIcon />,
      onClick: handleOpenSchedules,
      state: openSchedules,
    },
    {
      text: "Incident Reporting",
      icon: <AssignmentLateIcon />,
      onClick: handleOpenIncidentReporting,
      state: openIncidentReporting,
    },
    {
      text: "Notifications",
      icon: <NotificationsIcon />,
      onClick: handleOpenNotifications,
      state: openNotifications,
    },
    {
      text: "Drone Imported Ops",
      icon: <SendIcon />,
      onClick: handleOpenDroneOperation,
      state: openDroneOperation,
    },
  ];
  const remoteIdDrawer = [
    {
      text: "Tracker Management",
      icon: <SettingsRemoteIcon />,
      onClick: handleOpenRemoteIdTrackerList,
      state: openRemoteIdTrackerList,
    },

    {
      text: "Flight Logs",
      icon: <TopicIcon />,
      onClick: handleOpenSchedules,
      state: openSchedules,
    },
    {
      text: "Notifications",
      icon: <NotificationsIcon />,
      onClick: handleOpenNotifications,
      state: openNotifications,
    },
  ];
  const authorityDrawer = [
    {
      text: "Create Constraint",
      icon: <AllOutIcon sx={{ fontSize: "26px", marginLeft: "-1px" }} />,
      onClick: handleOpenConstrainMgrAuthority,
      state: openConstrainMgrAuthority,
    },
    {
      text: "Constraint Panel",
      icon: <ListAltIcon />,
      onClick: handleOpenConstraintPanel,
      state: openConstraintPanel,
    },
    {
      text: "Operator Management",
      icon: <GroupsIcon />,
      onClick: handleOpenOperatorManagement,
      state: openOperatorManagement,
    },
    {
      text: "Flight Authorization",
      icon: <TaskIcon />,
      onClick: handleOpenFlightAuthorizationAuthority,
      state: openFlightAuthorizationAuthority,
    },
    {
      text: "Notifications",
      icon: <NotificationsIcon />,
      onClick: handleOpenNotifications,
      state: openNotifications,
    },
    {
      text: "Flight Replay",
      icon: <VideoLibrary />,
      onClick: handleOpenReplayComponent,
      state: openReplayFunction,
    },
  ];

  // Assign drawer here so that it renders according to account type
  let accountDrawer = [];

  if (isOperator) {
    accountDrawer = operatorDrawer;
  } else if (isAuthority) {
    accountDrawer = authorityDrawer;
  } else if (isPilot) {
    accountDrawer = pilotDrawer;
  } else if (isRemoteId) {
    accountDrawer = remoteIdDrawer;
  }

  const secondDrawerItems = [
    {
      text: "Logout",
      icon: <LogoutIcon />,
      onClick: handleLogout,
    },
  ];

  // useEffect(() => {
  //   console.log("have newnotificatinos in Sidebar!!!");
  //   if (haveNewNotifications === true) {
  //     try {
  //       const LastMsg = notifications[0];
  //       const InfoSplit = isAuthority
  //         ? LastMsg.SK.S.split("#")
  //         : LastMsg.SK.split("#");
  //       const severity = InfoSplit[5];
  //       if (severity === "Error") {
  //         setBadgeColor("yellow");
  //       }
  //       if (severity === "Warning" || severity === "Important") {
  //         setBadgeColor("orange");
  //       }
  //       if (severity === "Emergency") {
  //         setBadgeColor("red");
  //       }
  //       if (severity === "Informational") {
  //         setBadgeColor("blue");
  //       }

  //       setSidebarNewNotifs(false);
  //       setBadgeVisible(true);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  //   console.log("badgevisible", badgeVisible);
  // }, [haveNewNotifications]);

  useEffect(() => {
    if (notifications.length === 0) return;
    try {
      const LastMsg = notifications[0];
      const InfoSplit = isAuthority
        ? LastMsg?.SK?.split("#")
        : LastMsg?.SK?.split("#");
      const severity = InfoSplit[5];

      if (severity === "Emergency") {
        setBadgeColor("red");
        highestSeverity.current = 4;
      }

      if (
        (severity === "Warning" || severity === "Important") &&
        highestSeverity.current <= 3
      ) {
        setBadgeColor("orange");
        highestSeverity.current = 3;
      }

      if (severity === "Error" && highestSeverity.current <= 2) {
        setBadgeColor("yellow");
        highestSeverity.current = 2;
      }

      if (
        (severity === "Informational" || severity === "Information") &&
        highestSeverity.current <= 1
      ) {
        setBadgeColor("blue");
        highestSeverity.current = 1;
      }

      setSidebarNewNotifs(false);
      // disable new notification badge during first render
      if (!disableBadgeOnInitialRender.current) setBadgeVisible(true);
      disableBadgeOnInitialRender.current = false;
    } catch (e) {
      console.log(e);
    }
  }, [notifications]);

  return (
    <Box sx={{ display: "flex", zIndex: 2 }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography>
        </Toolbar>
      </AppBar> */}

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: Colours[colourTheme].navSidebar,
            // overflowX: "visible",
          },
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "-15px",
            marginTop: open ? "" : "6px",
          }}
        >
          {open ? (
            <img
              src="/HeronLogoMain.png"
              alt="HeronLogo"
              style={{
                width: "auto",
                height: "100%",
                maxHeight: "68px",
              }}
            />
          ) : (
            <img
              src="/HeronLogoMain.png"
              alt="HeronLogo"
              style={{
                width: "auto",
                height: "100%",
                maxHeight: "28px",
              }}
            />
          )}
        </div>
        <Typography position="absolute" margin="10px">
          {env === "dev" && " DEV"}
        </Typography>
        <DrawerHeader
          sx={{
            justifyContent: open ? "space-between" : "center",
            paddingLeft: open ? "18px" : "",
            position: "relative",
          }}
        >
          {open ? (
            <>
              <Box>
                <h4 style={{ margin: "0px", marginTop: "8px" }}>
                  {" "}
                  {accountType}
                </h4>
                <span
                  style={{
                    color: Colours[colourTheme].sidebarIcon,
                    fontSize: "16px",
                  }}
                >
                  {username}{" "}
                </span>
              </Box>
              <Box
                sx={{
                  position: "absolute", // Set position to absolute for the IconButton container
                  // top: "-100%", // Adjust top position to center the IconButton vertically
                  // transform: "translateY(-50%)", // Center the IconButton vertically
                  right: "-1px", // Adjust the distance from the right border
                  zIndex: 3,
                }}
              >
                <IconButton
                  sx={{
                    // backgroundColor: heronTheme.colors.heronBlue,
                    height: "22px",
                    width: "22px",
                    borderRadius: "10px",
                  }}
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftIcon
                    sx={{ color: Colours[colourTheme].sidebarIcon }}
                  />
                </IconButton>{" "}
              </Box>
            </>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon sx={{ color: Colours[colourTheme].sidebarIcon }} />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider sx={{ backgroundColor: Colours[colourTheme].sidebarIcon }} />
        <List
          sx={{
            paddingRight: "1px",
            paddingLeft: "1px",
          }}
        >
          {/* FIRST COLUMN ITEMS, DIFFERENT FOR EACH ACCOUNT TYPE */}

          {accountDrawer.map((item, index) => (
            <ListItem key={item.text} sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2,
                  marginLeft: "-8px",
                  marginRight: open ? "-7.5px" : "0px",
                  backgroundColor: item.state
                    ? Colours[colourTheme].sidebarSelectedButton
                    : "",
                }}
                onClick={(event) => {
                  item.onClick(event);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2.5 : "auto",
                    justifyContent: "center",
                    marginLeft: open ? "-9px" : "0px",
                    color: Colours[colourTheme].sidebarIcon,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      fontSize: "14px",
                      marginLeft: "-13px",
                      color: Colours[colourTheme].sidebarIcon,
                    }}
                    disableTypography
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ backgroundColor: Colours[colourTheme].sidebarIcon }} />
        <List>
          {secondDrawerItems.map((item, index) => (
            <ListItem key={item.text} sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2,
                  marginLeft: "-4px",
                }}
                onClick={(event) => item.onClick(event)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    marginLeft: open ? "-9px" : "-2px",
                    color: Colours[colourTheme].sidebarIcon,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      fontSize: "14px",
                      marginLeft: "-13px",
                      color: Colours[colourTheme].sidebarIcon,
                    }}
                    disableTypography
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default memo(Sidebar);

{
  /* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography>
        </Toolbar>
      </AppBar> */
}
