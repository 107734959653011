import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Stack,
  Alert,
  AlertTitle,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { FLIGHT_STATES } from "../../config/flightStatusColor";
import { messageTypes } from "../../config/messageTypes";

function severityColor(operationState) {
  let color = "#white";
  operationState.forEach((singleState) => {
    if (singleState.state === "authority_msg" && color !== "#f80303") {
      color = "#ffa500";
    }
    if (singleState.state === "Nonconforming" && color !== "#f80303") {
      color = "#ffa500";
    }
    if (singleState.state === "Contingent") {
      color = "#f80303";
    }
  });
  return color;
}

function severityColorDarken(operationState) {
  let color = "#white";
  operationState.forEach((singleState) => {
    if (singleState.state === "authority_msg" && color !== "#e40303") {
      color = "#E69502";
    }
    if (singleState.state === "Nonconforming" && color !== "#e40303") {
      color = "#E69502";
    }
    if (singleState.state === "Contingent") {
      color = "#e40303";
    }
  });
  return color;
}

function operationStateText(operationState) {
  let color;
  // if (operationState === FLIGHT_STATES.NON_CONFORMANT) {
  //   color = "red";
  // } else {
  //   color = "black";
  // }
  const stateText = (operationStates) => {
    return (
      <span style={{ fontWeight: "bold", color }}> {operationStates} </span>
    );
  };
  switch (operationState) {
    case FLIGHT_STATES.ACTIVATED: {
      color = "green";
      return stateText(operationState);
    }
    case FLIGHT_STATES.CONTINGENT: {
      color = "red";
      return stateText(operationState);
    }
    case FLIGHT_STATES.NON_CONFORMANT: {
      color = "orange";
      return stateText(operationState);
    }
    case FLIGHT_STATES.FOCUSSED: {
      color = "blue";
      return stateText(operationState);
    }
    case messageTypes.AUTHORITY_ALERT: {
      color = "orange";
      return stateText(operationState);
    }
    default:
      color = "slategrey";
      return stateText(operationState);
  }
}

function FormatBoldDisplay(info) {
  return <span style={{ fontWeight: "bold" }}> {info} </span>;
}

function AlertReasonText({
  newOperatingState,
  operationID,
  platformCallSign,
  message,
}) {
  switch (newOperatingState) {
    case FLIGHT_STATES.ACTIVATED:
      return (
        <Card sx={{ border: "2px solid green" }}>
          <CardContent>
            <Typography gutterBottom>
              The non-conforming operation has returned to its conformance
              volume and has therefore returned to an
              {operationStateText(newOperatingState)} state.
            </Typography>
          </CardContent>
        </Card>
      );
    case FLIGHT_STATES.NON_CONFORMANT:
      return (
        <Card sx={{ border: "2px solid orange" }}>
          <CardContent>
            <Typography gutterBottom>
              An operation close by is {operationStateText(newOperatingState)}.
              Please fly with caution.
            </Typography>
          </CardContent>
        </Card>
        // <Typography gutterBottom>
        //   An operation close by is {FormatBoldDisplay(platformCallSign)} has
        //   deviated outside its conformance volume and has therefore entered{" "}
        //   {operationStateText(newOperatingState)} state.
        // </Typography>
      );
    case FLIGHT_STATES.CONTINGENT:
      return (
        <Card sx={{ border: "2px solid red" }}>
          <CardContent>
            <Typography gutterBottom>
              An operation close by has deviated outside its conformance volume
              beyond the allowed tolerance and is now
              {operationStateText(newOperatingState)}. Please fly with caution.
            </Typography>
          </CardContent>
        </Card>
      );
    case messageTypes.AUTHORITY_ALERT:
      return (
        <Card sx={{ border: "2px solid orange" }}>
          <CardContent>
            <Typography gutterBottom>{message}</Typography>
          </CardContent>
        </Card>
      );
    default:
      return (
        <Typography gutterBottom>
          Operation {FormatBoldDisplay(operationID)} has entered
          {operationStateText(newOperatingState)} state.
        </Typography>
      );
  }
}

export function WebsocketAlertComponent({
  open,
  handleClose,
  websocketMessage,
}) {
  const [acknowledgeWarning, setAcknowledgeWarning] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState([]);
  // if (!websocketMessage) return null;

  const colorTheme = severityColor(websocketMessage);
  const colorThemeDarken = severityColorDarken(websocketMessage);

  const updateMessageTypeInList = (messageType) => {
    const currentList = duplicateMessage;
    setDuplicateMessage([...currentList, messageType]);
  };

  const closeHandler = (event, reason) => {
    event.preventDefault();
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      setAcknowledgeWarning(true);
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "6px",
          outline: `4px solid ${colorTheme}`,
          // padding: "12px",
          maxWidth: "450px",
        },
      }}
      open={open}
      onClose={closeHandler}
      // disableBackdropClick
    >
      {" "}
      {acknowledgeWarning && (
        <Alert severity="error">PLEASE ACKNOWLEDGE MESSAGE!</Alert>
      )}
      <DialogTitle>
        {" "}
        <Stack direction="row" spacing={2}>
          {" "}
          <WarningAmberIcon sx={{ fontSize: 32, color: colorTheme }} />
          <Typography variant="h5"> Conformance Alert </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {websocketMessage.map((singleMessage, index) => {
            return (
              <ListItem key={`wsalert${index + 1}`}>
                <AlertReasonText
                  newOperatingState={singleMessage.state}
                  operationID={singleMessage.operationID}
                  platformCallSign={singleMessage.platformCallSign}
                  message={singleMessage.message ? singleMessage.message : ""}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: colorTheme,
            color: "black",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: colorThemeDarken,
            },
            padding: "-2px",
          }}
          onClick={handleClose}
        >
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
}
