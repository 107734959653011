import { SubscriptionStatus } from "../actions";

const subscriptionStatus = (state = SubscriptionStatus.NONE, action) => {
  switch (action.type) {
    case "SET_SUBSCRIPTION_STATUS":
      return action.status;
    default:
      return state;
  }
};

export default subscriptionStatus;
