import { React, useEffect } from "react";
import "./output.css";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SnackbarMessage from "./components/SnackbarMessage";
import {
  setAuth,
  setSnackbarMessage,
  setStateConformance,
  setEnvVar,
} from "./store/actions";
import Protected from "./pages/Protected";
import { parseJwt } from "./api/operator";
import useWebsocket from "./hooks/useWebsocket";
import { AppColourThemeProvider } from "./contexts/AppColourTheme";
import LoginPage from "./pages/Login";
import LandingPage from "./pages/v1/Landing";
import DashboardPage from "./pages/v1/Dashboard";
import { parameterStore } from "./api/parameterStore";
import PuckServer from "./services/puckserver";
import { sharedEndpoints } from "./api/shared";
import { setAirmapspaceApiKey } from "./api/airmapspace";

const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
const authCognitoDomain = process.env.REACT_APP_AUTHORITY_LOGIN_DOMAIN;
const operatorClientId = process.env.REACT_APP_OPERATOR_CLIENT_ID;
const pilotClientId = process.env.REACT_APP_PILOT_CLIENT_ID;
const remoteIdClientId = process.env.REACT_APP_REMOTE_ID_CLIENT_ID;
const authClientId = process.env.REACT_APP_AUTHORITY_CLIENT_ID;
let wsBaseUrl = "";

function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateWsUrl, updateWsBaseUrl } = useWebsocket({
    channel: "notification",
  });
  const uiVersion = localStorage.getItem("ui_version");

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const initAuthInfo = (idToken, accessToken, refreshToken, role) => {
    localStorage.setItem("role", role);
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", refreshToken);
    const userInfo = parseJwt(idToken);

    const hasGroups = !!userInfo?.["cognito:groups"];
    let isPilot = false;
    let isOperator = false;
    let isAuthority = false;
    let isRemoteId = false;
    if (hasGroups) {
      isPilot =
        role === "pilot" &&
        !userInfo?.["cognito:groups"].includes("operator") &&
        !userInfo?.["cognito:groups"].includes("remote-id");
      isOperator =
        role === "operator" &&
        userInfo?.["cognito:groups"].includes("operator");
      isAuthority =
        role === "authority" &&
        userInfo?.["cognito:groups"].includes("authority");
      isRemoteId =
        role === "remote-id" &&
        userInfo?.["cognito:groups"].includes("remote-id");
    }

    if (
      !hasGroups ||
      (!isPilot && !isOperator && !isAuthority && !isRemoteId)
    ) {
      localStorage.clear();
      dispatch(
        setAuth({
          accessToken: "",
          idObject: null,
          isPilot: false,
          isOperator: false,
          isAuthority: false,
          isRemoteId: false,
          isLoggedIn: false,
        })
      );
      let clientId = operatorClientId;
      if (role === "pilot") clientId = pilotClientId;
      if (role === "remote-id") clientId = remoteIdClientId;
      if (role === "authority") {
        clientId = authClientId;
        window.location.href = `${authCognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
      } else {
        window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
      }
      // eslint-disable-next-line no-alert
      alert(`role : ${role} group ${userInfo["cognito:groups"]}`);
      alert("You do not have the required access permissions.");
      return;
    }

    dispatch(
      setAuth({
        refreshToken,
        accessToken,
        userInfo,
        isPilot,
        isOperator,
        isAuthority,
        isRemoteId,
        isLoggedIn: !!accessToken,
      })
    );
    // navigate("/protected");
    initializeFOHEnvVariables();
  };

  // used to handle functions inialized before login
  const handleEarlierInitializedFunctions = (formatEnvVar) => {
    // Puckserver service/puckserver.js
    PuckServer.updatePuckserverBaseUrl(
      `https://${formatEnvVar["base_url-tracker_server"].Value}`
    );

    // Shared Api api/shared/shared.js
    const sharedEndpointsBaseUrls = {
      sdspBaseUrl: formatEnvVar["base_url-cif"].Value,
      cifBaseUrl: formatEnvVar["base_url-cif"].Value,
      cifAuthUrl: `${formatEnvVar["cognito_domain-cif"].Value}/oauth2/token`,
      fimsAtmUrl: formatEnvVar["base_url-cif_atm"].Value,
      fimsClientId: formatEnvVar["app_client_id-cif"].Value,
      fimsClientSecret: formatEnvVar["app_client_secret-cif"].Value,
    };
    sharedEndpoints.updateSharedApiBaseUrl(sharedEndpointsBaseUrls);

    // Airmapspace /api/airmapspace/airmapspace.js
    setAirmapspaceApiKey(formatEnvVar["api_key-airmap"].Value);

    // useWebsocket
    wsBaseUrl = `wss://${formatEnvVar["base_url-notifications"].Value}`;
    updateWsBaseUrl(wsBaseUrl);
  };

  const initializeFOHEnvVariables = async () => {
    const formatEnvVar = {};
    try {
      const getEnvVar = await parameterStore();
      // TODO add encrption for type SecureString
      getEnvVar.data.message.forEach((singleEnvVar) => {
        formatEnvVar[singleEnvVar.Name] = singleEnvVar;
      });
      dispatch(setEnvVar(formatEnvVar));
      handleEarlierInitializedFunctions(formatEnvVar);

      console.log("initialize success");

      // let redirectUrl = location.pathname;
      // if (redirectUrl === "/" || redirectUrl === "/login") {
      //   redirectUrl = "/v1/dashboard";
      // }

      if (uiVersion === "v0") {
        navigate("/protected");
      } else {
        navigate("/v1/dashboard");
      }
    } catch (e) {
      // failed to initiate env vars
      localStorage.clear();

      if (uiVersion === "v0") {
        navigate("/login");
      } else {
        navigate("/v1");
      }
    }
  };

  const fetchAuthTokens = async (code, role) => {
    let clientId = operatorClientId;
    if (role === "pilot") clientId = pilotClientId;
    if (role === "remote-id") clientId = remoteIdClientId;
    if (role === "authority") clientId = authClientId;
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("client_id", clientId);
    params.append("redirect_uri", redirectUri);
    params.append("code", code);
    params.append("code_verifier", localStorage.getItem("codeVerifier"));
    try {
      if (role === "authority") {
        const { data } = await axios.post(
          `${authCognitoDomain}/oauth2/token`,
          params
        );
        const { id_token, access_token, refresh_token } = data;
        initAuthInfo(id_token, access_token, refresh_token, role);
      } else {
        const { data } = await axios.post(
          `${cognitoDomain}/oauth2/token`,
          params
        );
        const { id_token, access_token, refresh_token } = data;
        initAuthInfo(id_token, access_token, refresh_token, role);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  useEffect(() => {
    // Temporary fix for authority login based on fact that authority has no refresh token.
    // To relook at logic when refresh token is to be implemented for authority.
    const params = new URLSearchParams(
      document.location.search || document.location.hash.slice(1)
    );

    const role = params.get("state") || localStorage.getItem("role");
    const authCode = params.get("code");
    const accessToken =
      localStorage.getItem("accessToken") || params.get("access_token");
    const idToken = localStorage.getItem("idToken") || params.get("id_token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (idToken != null && wsBaseUrl) updateWsUrl(idToken, role);
    // updateWsUrl(idToken, role);
    // getWSService(idToken, role);
    // WebSocketService.addMessageListener(({ data }) => {
    //   console.log("data ws chek", data);
    //   dispatch(
    //     setSnackbarMessage({
    //       open: true,
    //       message: data.message,
    //       severity: "success",
    //     })
    //   );
    // });

    if (idToken && refreshToken && accessToken && role) {
      initAuthInfo(idToken, accessToken, refreshToken, role);
    } else if (authCode && role) {
      fetchAuthTokens(authCode, role);
    } else if (idToken && accessToken && role) {
      initAuthInfo(idToken, accessToken, null, role);
    }
  }, [location.pathname, navigate, dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (uiVersion === "v0") {
        navigate("/login");
      } else {
        navigate("/v1");
      }
    }
  }, [isLoggedIn, navigate]);

  return <Outlet />;
}

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <div className="app">
        <AppColourThemeProvider>
          <SnackbarMessage />
          <Routes>
            <Route element={<Auth />}>
              <Route path="/v1" element={<LandingPage />} />
              <Route path="/v1/dashboard" element={<DashboardPage />} />
              <Route path="/protected" element={<Protected />} />
              <Route path="/" element={<Auth />} />
              {/* // <Route path="/redirecting" element={<Redirect />}></Route> */}
              <Route path="/login" element={<LoginPage />} />
              {/* <Route path="*" element={<Navigate to="/" />} /> */}
            </Route>
          </Routes>
        </AppColourThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
