import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserAccess } from "@/store/actions";
import UserAccess from "@/model/UserAccess";
import Pilot from "@/model/api/Pilot";

import { RiMenu2Line } from "react-icons/ri";
import { IoMoon } from "react-icons/io5";
import { Auth } from "aws-amplify";
import useWebsocket from "@/hooks/useWebsocket";
import { channels } from "@/config/channels";
import { messageTypes } from "@/config/messageTypes";
import UserInformation from "@/model/UseInformation";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import LatLng from "@/model/LatLng";
import useColorMode from "@/hooks/useColorMode";
import BellNotificationType from "@/model/api/BellNotification";
import { IoMdNotificationsOutline } from "react-icons/io";
import UserDropdown from "../UserDropdown";
import PlaceAutoComplete from "../Common/PlaceAutocomplete";
import NotificationDropdown from "../NotificationDropdown";
import OwnOrganisationManagement from "../MainMenu/OwnOrganisationManagement";
import PilotForm from "../MainMenu/AssetManagement/PilotForm";

interface UserAccessState {
  userAccess: UserAccess;
}

type UserRoles =
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot";

interface DashboardHeaderProps {
  api: any;
  isAuthority?: boolean;
  onPlaceSelected: (coordinate: LatLng) => void;
  showMessage?: (message: string, isError?: boolean) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  onThemeChange: (newTheme: string) => void;
  onMenuSelected(menu: string, isOpening: boolean): void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  isDJIDevice: boolean;
}

function toProperCase(str: string) {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function DashboardHeader({
  api,
  isAuthority,
  onPlaceSelected,
  showMessage,
  onConfirmation,
  onThemeChange,
  onMenuSelected,
  onOpenModal,
  isDJIDevice,
}: DashboardHeaderProps) {
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });
  const { logout } = useCognitoAuth();
  const [colorMode, setColorMode] = useColorMode();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [userCurrentRole, setUserCurrentRole] = useState<UserRoles>("pilot");
  const [userInformation, setUserInformation] = useState<UserInformation>({
    username: "heron",
    name: "Heron",
    email: "-",
    avatar: "/default_avatar.png",
  });
  const [userPilotInformation, setUserPilotInformation] = useState<Pilot>();
  /* Bell Notifications */
  // const [bellNotifications, setBellNotifications] = useState<
  //   BellNotificationType[]
  // >([]);
  const bellNotifications = useRef<BellNotificationType[]>([]);
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);
  const dispatch = useDispatch();

  const handleMenuClick = async (menu: string) => {
    setIsDropdownOpen(false);

    if (menu === "user-profile") {
      onOpenModal(
        "User Profile",
        <PilotForm
          api={api}
          pilot={userPilotInformation}
          showMessage={showMessage}
          onSaveSucess={() => console.log("On save success")}
          hasWritePrivilege={true}
        />
      );
    }

    if (onConfirmation && menu === "signout") {
      onConfirmation(
        "Sign Out",
        "Are you sure you want to sign out?",
        (result) => {
          if (result) {
            logout();
          }
        }
      );
    }
  };

  const toggleUserDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNotificationOpen(false);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
    setIsDropdownOpen(false);
  };

  const toggleColorMode = () => {
    if (typeof setColorMode === "function") {
      const newColor = colorMode === "light" ? "dark" : "light";
      setColorMode(newColor);
      onThemeChange(newColor);
    }
  };

  const updateUserAccess = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const userRoles = await api.getUser(user.username);
      const userPrivileges = await api.getUserPrivilege(user.username);

      const newUserAccess = {
        ...userAccess,
      };
      newUserAccess.role = userRoles?.data?.current_user_role;
      newUserAccess.user_roles = userRoles?.data?.user_roles;
      newUserAccess.privileges = userPrivileges?.data?.privileges;
      dispatch(setUserAccess(newUserAccess));
      setUserCurrentRole(newUserAccess.role);
    } catch (e) {
      console.log("Unable to obtain user data : ", e);
    }
  };
  useEffect(() => {
    updateUserAccess();
  }, []);

  useEffect(() => {
    setUserCurrentRole(userAccess.role);
  }, [userAccess]);

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;

        setUserInformation({
          username: user.username,
          name: user.name,
          email: attributes.email,
          avatar: "/default_avatar.png",
        });

        return attributes;
      } catch (error) {
        console.error("Error fetching user attributes:", error);
        return null;
      }
    };

    getUserAttributes();
  }, []);

  /* Bell Notifications */

  const fetchLogMessages = async (lastBellNotificationMessage?: object) => {
    try {
      if (!lastBellNotificationMessage) {
        const res = await api?.getLogMessages();
        if (isAuthority) {
          bellNotifications.current = res?.data;
        } else {
          bellNotifications.current = res?.data?.items;
        }
      } else {
        {
          const res = await api?.getLogMessages(lastBellNotificationMessage);
          if (!res?.data?.items) return;
          const currentBellNotificationMessages = [
            ...bellNotifications.current,
            ...res.data.items,
          ];
          bellNotifications.current = currentBellNotificationMessages;
          // if (!isAuthority) {
          //   setBellNotifications([
          //     ...currentBellNotificationMessages,
          //     res?.data,
          //   ]);
          // } else {
          //   setBellNotifications([
          //     ...currentBellNotificationMessages,
          //     res?.data?.items,
          //   ]);
          // }
        }
      }
    } catch (error) {
      console.log("error fetching log messages: ", error);
    }
  };

  const handleGetOlderBellNotificationsClick = () => {
    const data = {
      lastEvaluatedKey: {
        PK: bellNotifications.current[bellNotifications.current.length - 1].PK,
        SK: bellNotifications.current[bellNotifications.current.length - 1].SK,
      },
    };
    fetchLogMessages(data);
  };

  useEffect(() => {
    if (!api) return;

    let refreshTimeout: NodeJS.Timeout;
    const fetchNotificationTimer = async () => {
      try {
        const fetchedMessages = await fetchLogMessages();
        // add new message handler here
        // const lastNotif = notificationsRef.current?.[0];
        // const lastNotifSK = lastNotif?.SK;

        // const lastMsg = fetchedMessages?.[0];
        // const lastMsgSK = lastMsg?.SK;
        // if (lastNotifSK !== lastMsgSK) {
        //   NotificationPlay();
        //   setHaveNewNotifications(true);
        //   setNotifications(fetchedMessages);
        // }
      } catch (err) {
        console.log(err);
      }
      // Log getLogMessages called every 30mins to ensure messages still in sync
      // New log messages are sent via websocket and added to notifications useState
      refreshTimeout = setTimeout(fetchNotificationTimer, 1000 * 60 * 30);
    };
    fetchNotificationTimer();
    return () => {
      clearTimeout(refreshTimeout);
    };
  }, []);

  const handleWSLogMessage = (
    singleBellNotificationWsMessage: BellNotificationType
  ) => {
    if (!singleBellNotificationWsMessage) return;
    const currentBellNotificationMessages = [
      singleBellNotificationWsMessage,
      ...bellNotifications.current,
    ];
    bellNotifications.current = currentBellNotificationMessages;
  };

  // Listener for WS Log Messages
  useEffect(() => {
    const removeLogMessageListener = channel?.addMessageListener(
      messageTypes.LOG_MESSAGE,
      handleWSLogMessage
    );
    return () => {
      removeLogMessageListener?.();
    };
  }, [channel, userAccess]);

  const getPilotDetails = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const { username } = user;
    const response = await api.getPilots();
    const pilot = response.data.find(
      (singlePilot: any) => singlePilot.pilot_username === username
    );
    setUserPilotInformation(pilot);
  };

  useEffect(() => {
    if (!api) return;
    getPilotDetails();
  }, [api, Auth, isDropdownOpen]);
  return (
    <div id="dashboardHeader" className="dashboard-header relative">
      {/* Button Toggle Menu */}
      <button className="rounded-container btn-icon">
        <RiMenu2Line
          className="fill-primary-300 dark:fill-white m-auto"
          size={24}
        />
      </button>
      <img
        src={colorMode === "light" ? "/HeronLogo.png" : "/HeronLogoWhite.png"}
        alt="logo"
        width={100}
        height={30}
        className="ml-6 object-contain w-28 h-fit"
      />
      <span className="grow" />
      {/* SearhBar */}
      <div
        className={`${
          isDJIDevice ? "w-[100px]" : "w-[400px]"
        } absolute left-1/2 transform -translate-x-1/2 z-[60]`}
      >
        <PlaceAutoComplete
          onSelectPlace={onPlaceSelected}
          showMessage={showMessage}
        />
      </div>

      {/* User Information */}
      <div className="relative">
        <div className="flex">
          <div
            className="flex ml-4 cursor-pointer"
            onClick={toggleUserDropdown}
          >
            <div className="flex flex-col justify-end items-end">
              <span className="label-blue font-semibold ml-2 text-lg">
                {userInformation.username}
              </span>
              <span className="label-secondary ml-2 text-sm">
                {toProperCase(userCurrentRole)}
              </span>
            </div>
            <img
              src="/default_avatar.png"
              alt="profile"
              width={30}
              height={30}
              className="rounded-full w-12 h-12 object-contain border-4 border-gray-400/30 ml-2"
            />
          </div>
          <button
            className="rounded-container btn-icon ml-4"
            onClick={toggleColorMode}
          >
            <IoMoon className="icon-btn" size={24} />
          </button>
          <button
            className="rounded-container btn-icon ml-4"
            onClick={toggleNotificationDropdown}
          >
            <IoMdNotificationsOutline className="icon-btn" size={26} />
          </button>
        </div>

        {/* User Dropdown */}
        {isDropdownOpen && (
          <UserDropdown
            userInformation={userInformation}
            className="absolute z-[999]"
            onMenuClicked={handleMenuClick}
            showMessage={showMessage}
            onOrganisationManagementMenuSelected={() =>
              onOpenModal(
                "Organisation Details",
                <OwnOrganisationManagement showMessage={showMessage} />
              )
            }
            isDJIDevice={isDJIDevice}
          />
        )}

        {/* Notification Dropdown */}
        {isNotificationOpen && (
          <NotificationDropdown
            bellNotifications={bellNotifications.current}
            handleGetOlderBellNotificationsClick={
              handleGetOlderBellNotificationsClick
            }
            className="absolute z-[999]"
          />
        )}
      </div>
    </div>
  );
}
