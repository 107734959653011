import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import { setOperationFormRequest } from "@/store/actions";
import { Operation } from "@/model/api/Operation";
import { IoIosMore } from "react-icons/io";
import Pilot from "@/model/api/Pilot";
import Tracker from "@/model/api/Tracker";
import Platform from "@/model/api/Platform";
import moment from "moment";
import { IoEllipsisHorizontal } from "react-icons/io5";
import ItemOperation from "../FlightAuthorization/ItemCards/ItemOperation";
import Loader from "../../Common/Loader";
import Button from "../../Common/Button";

interface ItemOperationProps {
  api: any;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  handleProposeChanges: () => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function FlightAuthorizationAuthority({
  api,
  onConfirmation,
  showMessage,
  onOpenModal,
  onCloseModal,
  handleProposeChanges,
}: ItemOperationProps) {
  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAccepting, setIsAccepting] = useState(false);

  /* Operation Data */
  const [operations, setOperations] = useState<Operation[]>([]);

  /* Assets */
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const dispatch = useDispatch();
  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);

    /* if (option === 1 && pastpOperations.length === 0) {
      fetchOperations(true);
    } else {
      setOperations(option === 0 ? upcomingOperations : pastpOperations);
    } */
  };

  const handleOperationClicked = (operation: Operation) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Operation</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.intent}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Departure</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Arrival</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_end.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPilotName(operation.operation_json.request.pilot_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Platform</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPlatformName(
                  operation.operation_json.request.platform_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Tracker</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getTrackerName(
                  operation.operation_json.request.tracker_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Status</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.details.state}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Operation ID</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.id}
              </td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Created On</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.reference.time_created.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Operation Detail", content);
  };

  const handleOperationSelected = (operationId: string) => {
    const index = selectedIds.indexOf(operationId);
    if (index === -1) {
      setSelectedIds([...selectedIds, operationId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== operationId));
    }
  };

  const fetchOperations = async () => {
    const response = await api.aas.getOperations();
    if (!response.data) return;

    const currentTime = new Date();
    const proposedOps: Operation[] = [];

    response.data.forEach((flightData: Operation) => {
      if (
        new Date(flightData.operation_json.reference.time_end.value) >=
          currentTime &&
        flightData.operation_json.details.state.toLowerCase() === "proposed"
      ) {
        proposedOps.push(flightData);
      }
    });

    setOperations(proposedOps);
    setIsLoading(false);
  };

  const fetchPilots = async () => {
    if (!userAccess.privileges.includes("airspace.assets.read")) return;
    const response = await api.aas.getPilots();
    if (response.error) {
      showMessage && showMessage("Unable to Retrive Pilots Data", false, true);
      setIsError(true);
    } else {
      setPilots(response.data);
    }
  };

  const fetchPlatforms = async () => {
    if (!userAccess.privileges.includes("airspace.assets.read")) return;
    const response = await api.aas.getPlatforms();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Platforms Data", false, true);
    } else {
      setPlatforms(response.data);
    }
  };

  const fetchTrackers = async () => {
    if (!userAccess.privileges.includes("airspace.assets.read")) return;
    const response = await api.aas.getTracker();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Trackers Data", false, true);
    } else {
      setTrackers(response.data);
    }
  };

  const getPilotName = (pilotId: string) => {
    const pilot = pilots.find((p) => p.pilot_uuid === pilotId);
    return pilot ? pilot.pilot_username : "Unknown Pilot";
  };

  const getPlatformName = (platformId: string) => {
    const platform = platforms.find((p) => p.platform_uuid === platformId);
    return platform ? platform.platform_callsign : "Unknown Platform";
  };

  const getTrackerName = (trackerId: string) => {
    const tracker = trackers.find((t) => t.tracker_uuid === trackerId);
    return tracker ? tracker.tracker_name : "Unknown Tracker";
  };

  useEffect(() => {
    if (api) {
      setIsLoading(true);
      fetchPilots().then(() => fetchOperations());
      fetchPlatforms();
      fetchTrackers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  /* Handle Flight Authorization Actions */

  const fetchFlightOperator = (operations: any, selectedFlight: string) =>
    operations.data.find(
      (e: any) => e.operation_json.reference.id === selectedFlight
    ).creator_id;

  const handleAcceptClick = async () => {
    // Find a way to reduce repeated calls to getOperations
    const operations = await api.aas.getOperations();
    setIsAccepting(true);
    try {
      const promises = selectedIds.map(async (selectedFlight) => {
        const owner = fetchFlightOperator(operations, selectedFlight);
        await api.aas.setFlightAccepted(selectedFlight);
        // send email
        await api.aas.sendEmail({
          recipientUsername: `${owner}`,
          message: `Flight ${selectedFlight} has been accepted`,
          subject: `Flight ${selectedFlight} Approved`,
        });
      });
      await Promise.all(promises);
    } catch (err) {
      console.log(err);
    }
    await fetchOperations();
    setIsAccepting(false);
  };

  const fetchFlight = (operations: any, selectedFlight: string) =>
    operations.data.find(
      (e: any) => e.operation_json.reference.id === selectedFlight
    );

  const handleRejectClick = async () => {
    showMessage("Updating Operation, Please Wait");
    setIsLoading(true);
    const operations = await api.aas.getOperations();
    setShowMoreOptions(false);
    try {
      const promises = selectedIds.map(async (selectedFlight) => {
        const singleOperation = fetchFlight(operations, selectedFlight);
        const owner = singleOperation.operation_json.reference.id;
        const { two_way } = singleOperation.operation_json.request;

        if (two_way) {
          singleOperation.operation_json.reference.mission_list.map(
            async (singleOperationUuid: any) => {
              await api.aas.setFlightRejected(singleOperationUuid);
              // send email
              await api.aas.sendEmail({
                recipientUsername: `${owner}`,
                message: `Flight ${singleOperationUuid} has been rejected and removed`,
                subject: `Flight ${singleOperationUuid} Rejected`,
              });
            }
          );
          await Promise.all(promises);
        } else {
          await api.aas.setFlightRejected(selectedFlight);
          // send email
          await api.aas.sendEmail({
            recipientUsername: `${owner}`,
            message: `Flight ${selectedFlight} has been rejected and removed`,
            subject: `Flight ${selectedFlight} Rejected`,
          });
        }
      });
      await Promise.all(promises);
    } catch (err) {
      console.log(err);
    }
    await fetchOperations();
    setIsLoading(false);
  };

  const handleProposeChangesClick = async () => {
    // handle more than one selected flights

    // console.log("selectedFlights", selectedFlights);
    // Noted: need change not to use api call again just use table data
    const response = await api.aas.getOperationData({
      operationID: selectedIds[0],
    });
    const request = response.data.request_json;
    if (request.two_way) {
      request.description = response.data.operation_json.reference.description;
    }
    dispatch(
      setOperationFormRequest({
        id: selectedIds[0],
        type: "edit",
        request,
      })
    );
    handleProposeChanges();
  };

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      <div className="flex-col h-[90%] overflow-auto">
        <div className="h-[90%] flex-col">
          {/* Header */}
          <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
            <h4>Flight Authorization</h4>
            <span className="grow" />
            <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
              <IoIosMore
                className="fill-blue-500 dark:fill-white m-auto"
                size={16}
              />
            </div>
          </div>
          <div className="flex-col px-4">
            {/* Main Tab */}
            <div className="tab-rounded mt-2">
              <span
                className={`${selectedTab === 0 && "tab-active"} mr-1`}
                onClick={() => handleSelectedTab(0)}
              >
                Pending Approval
              </span>
              <span
                className={`${selectedTab === 1 && "tab-active"} ml-1`}
                onClick={() => handleSelectedTab(1)}
              >
                Approval History
              </span>
            </div>
            <div className="flex px-4 py-2 mt-2">
              <h5 className="font-medium ml-2">Operations</h5>
              <span className="grow" />
              <button onClick={() => showMessage && showMessage("Coming Soon")}>
                <span className="text-primary-600 text-sm font-medium mr-2">
                  Sort by : Date
                </span>
              </button>
            </div>
          </div>
          {/* Content */}
          <div
            className={`${
              userAccess.privileges.includes("airspace.operations.write")
                ? "h-[80%]"
                : "h-[90%]"
            } flex-col overflow-auto p-4`}
          >
            {/* Operation List */}
            {operations
              // .filter((operation) => {
              //   if (selectedTab === 1) return true;
              //   return operation.is_upcoming === true;
              // })
              .map((operation) => (
                <ItemOperation
                  key={operation.operation_json.reference.id}
                  operation={operation}
                  pilotName={getPilotName(
                    operation.operation_json.request.pilot_uuid[0]
                  )}
                  trackerName={getTrackerName(
                    operation.operation_json.request.tracker_uuid[0]
                  )}
                  isDeleting={isDeleting}
                  isSelected={selectedIds.includes(
                    operation.operation_json.reference.id
                  )}
                  onClick={handleOperationClicked}
                  onSelected={handleOperationSelected}
                />
              ))}

            {/* No Data Available */}
            {!isLoading && operations.length === 0 && (
              <p className="p-8 text-center">No Data Available</p>
            )}

            <Loader
              isLoading={isLoading}
              isError={isError}
              errorText={errorMessage}
            />
          </div>
        </div>
        {userAccess.privileges.includes("airspace.operations.write") && (
          <div className="h-[10%] px-4 py-2 items-center content-center flex">
            <Button
              type="primaryDark"
              size="medium"
              text="Accept"
              className="mr-2"
              onClick={handleAcceptClick}
              isLoading={isAccepting}
              // disabled={isDeleting || isDisplaying}
            />

            <Button
              type="light"
              size="medium"
              fixedWidth={true}
              className="mr-2 w-20"
              icon={<IoEllipsisHorizontal size={24} />}
              onClick={() => setShowMoreOptions(!showMoreOptions)}
              // isLoading={isDeleting}
              // disabled={isDeleting || isDisplaying}
              // onClick={handleDeleteOperation}
            />
          </div>
        )}
      </div>
      {/* Button Options */}
      {showMoreOptions && (
        <div className="w-40 h-fit absolute bottom-40 right-6 popup-menu">
          {/* Options concisted of export, replay, etc */}
          <ul className="flex-col">
            <li onClick={handleRejectClick}>Reject</li>
            <li onClick={handleProposeChangesClick}>Propose Changes</li>
          </ul>
        </div>
      )}
    </div>
  );
}
