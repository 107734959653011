import React from "react";
import { IoMdPerson } from "react-icons/io";
import { GroupedOperator, OperatorUser } from "@/model/api/GroupedOperator";
import { GroupedOrganisation } from "@/model/api/GroupedOrganisation";

interface ItemOrganisationProps {
  organisation: GroupedOrganisation;
  isAssigned: boolean;
  isAssigning: boolean;
  onViewPilots?: (operation: string) => void;
  onAssign: (username: string, isAssigned: boolean) => void;
}

export default function ItemOrganisation({
  organisation,
  isAssigned,
  isAssigning,
  onViewPilots,
  onAssign,
}: ItemOrganisationProps) {
  return (
    <div className="w-full rounded-container-np flex-col px-4 py-2 mb-2">
      <div className="flex items-center mb-1">
        <IoMdPerson />
        <span className="ml-2 grow text-ellipsis truncate">
          {organisation.organisation_name}
        </span>
        {!isAssigning ? (
          <button
            onClick={() => onAssign(organisation.organisation_uuid, isAssigned)}
          >
            <span
              className={`font-medium ${
                isAssigned ? "text-red-500" : "text-blue-500"
              }`}
            >
              {isAssigned ? "Unassign" : "Assign"}
            </span>
          </button>
        ) : (
          <span className="font-medium text-grey-500">Assigning</span>
        )}
      </div>
      {isAssigned && (
        <button
          className="py-1"
          onClick={() =>
            onViewPilots && onViewPilots(organisation.organisation_name)
          }
        >
          <span className="text-blue-500 font-medium">View Pilots</span>
        </button>
      )}
    </div>
  );
}
