import React, { useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import flv from "flv.js";
import CryptoJS from "crypto-js";
import { store } from "../../../store";

function DJILiveStreaming({ userUuid }) {
  let newWindow = null;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const djiLiveStreamBaseUrl =
    store.getState().envVar["base_url-dji_livestream"].Value;
  const DJILSURL = `https://${djiLiveStreamBaseUrl}:8443/live/`;
  const DJIRTMPSecret = store.getState().envVar["dji-app-secret"].Value;
  const DJILSSecret = CryptoJS.MD5(`${userUuid}${DJIRTMPSecret}`).toString();

  const streamUrl = `${DJILSURL}${userUuid}.flv?secret=${DJILSSecret}`;

  // console.log(userUuid);
  useEffect(() => {
    if (videoRef.current) {
      const flvPlayer = flv.createPlayer({
        type: "flv",
        url: streamUrl,
      });
      flvPlayer.attachMediaElement(videoRef.current);
      flvPlayer.load();
      flvPlayer.play();

      return () => {
        flvPlayer.detachMediaElement();
        flvPlayer.destroy();
      };
    }
    return undefined;
  }, [streamUrl]);

  const handleDownloadClick = () => {
    window.open(streamUrl, "_blank");
  };

  const handleSnapshotClick = () => {
    if (videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;

      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const imageDataURL = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imageDataURL;
      link.download = "snapshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const openInNewWindow = () => {
    const htmlContent = `
      <html>
      <head>
        <style>
          body { margin: 0; }
          #player { width: 720px; height: 480px; display: flex; justify-content: center; align-items: center; }
          video { width: 100%; height: 100%; }
          canvas { display: none; }
        </style>
      </head>
      <body>
        <div id="player">
          <video id="videoElement" controls muted></video>
          <canvas></canvas>
        </div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/flv.js/1.5.0/flv.min.js"></script>
        <script>
          window.onload = function() {
            const videoElement = document.getElementById('videoElement');
            if (flvjs.isSupported()) {
              const flvPlayer = flvjs.createPlayer({
                type: 'flv',
                url: '${streamUrl}'
              });
              flvPlayer.attachMediaElement(videoElement);
              flvPlayer.load();
              flvPlayer.play().then(() => {
                console.log('Playback started successfully.');
              }).catch(error => {
                console.error('Error attempting to play:', error);
              });

              window.onunload = function() {
                flvPlayer.detachMediaElement();
                flvPlayer.destroy();
              };
            }
          };
        </script>
      </body>
      </html>
    `;

    newWindow = window.open(
      "",
      "_blank",
      "width=740,height=540,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no"
    );
    newWindow.document.write(htmlContent);
    newWindow.document.close();
    newWindow.resizeTo(740, 540);
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <h2>Live Stream Player</h2>
      </div>
      <div
        style={{
          width: "720px",
          height: "480px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <video
          ref={videoRef}
          style={{ width: "100%", height: "100%" }}
          controls
        >
          <track kind="captions" />
        </video>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadClick}
        >
          Download
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSnapshotClick}
          style={{ marginLeft: "10px" }}
        >
          Take Snapshot
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={openInNewWindow}
          style={{ marginLeft: "10px" }}
        >
          Open in New Window
        </Button>
      </div>
    </div>
  );
}

export default DJILiveStreaming;
