import { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AuthProposeOperationForm from "../AuthProposeOperationForm";
import UpcomingOperations from "../UpcomingOperations";
import { useApi } from "../../../api/useApi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function ProposeAuthorizationComponent(props) {
  const {
    selectedFlightData,
    constraints,
    telemetryData,
    onEmergencyLandingUpdateMove,
    emergencyLandingMove,
    selectedWaypointsMove,
    onUpdateSelectedWaypointsMove,
    selectedAreaWaypointsMove,
    onUpdateSelectedAreaWaypointsMove,
    selectedCircleWaypointsMove,
    onUpdateSelectedCircleWaypointsMove,
    selectedCirclePropertiesMove,
    onUpdateSelectedCirclePropertiesMove,
    handleopenAuthProposeOperationDrawer,
    setSelectedAreaWaypoints,
    setSelectedWaypoints,
    setSelectedCircleWaypoints,
    setSelectedCircleProperties,
  } = props;
  const [mapViewController, setMapViewController] = useState();

  // const [selectedWaypoints, setSelectedWaypoints] = useState([]);
  // const [selectedAreaWaypoints, setSelectedAreaWaypoints] = useState([]);
  const [emergencyLanding, setEmergencyLanding] = useState([]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  const [value, setValue] = useState(0);
  const [availablePilots, setAvailablePilots] = useState([]);
  const [availableTracker, setAvailableTracker] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getPlatforms = async () => {
    try {
      const response = await api.getPlatforms();
      if (response.data) {
        setAvailablePlatforms(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      if (response.data) {
        setAvailablePilots(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getTrackers = async () => {
    try {
      const response = await api.getTracker();
      if (response.data) {
        setAvailableTracker(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const onUpdateSelectedWaypoints = (waypoints) => {
    onUpdateSelectedWaypointsMove(waypoints);
    setSelectedWaypoints(waypoints);
  };

  const onUpdateSelectedAreaWaypoints = (waypoints) => {
    setSelectedAreaWaypoints(waypoints);
    onUpdateSelectedAreaWaypointsMove(waypoints);
  };

  const onUpdateSelectedCircleWaypoints = (waypoints) => {
    setSelectedCircleWaypoints(waypoints);
    onUpdateSelectedCircleWaypointsMove(waypoints);
  };

  const onUpdateSelectedCircleProperties = (waypoints) => {
    setSelectedCircleProperties(waypoints);
    onUpdateSelectedCirclePropertiesMove(waypoints);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // width: "100vw",
        overflow: "auto",
      }}
    >
      <div style={{ width: 600, overflow: "auto" }}>
        <AppBar position="static" color="default">
          <AuthProposeOperationForm
            mapViewController={mapViewController}
            selectedWaypoints={selectedWaypointsMove}
            selectedAreaWaypoints={selectedAreaWaypointsMove}
            selectedCircleWaypoints={selectedCircleWaypointsMove}
            selectedCircleProperties={selectedCirclePropertiesMove}
            emergencyLanding={emergencyLandingMove}
            onUpdateSelectedWaypoints={onUpdateSelectedWaypoints}
            onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypoints}
            onUpdateSelectedCircleWaypoints={onUpdateSelectedCircleWaypoints}
            onUpdateSelectedCircleProperties={onUpdateSelectedCircleProperties}
            onEmergencyLandingUpdate={onEmergencyLandingUpdateMove}
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            handleopenAuthProposeOperationDrawer={
              handleopenAuthProposeOperationDrawer
            }
          />
        </AppBar>
      </div>
    </div>
  );
}

export default ProposeAuthorizationComponent;
