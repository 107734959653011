import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { overlayManagerDropdownItems, viewDropdownItems } from "./data";

interface MapToolbarProps {
  onToggleView: (view: string, checked: boolean) => void;
  onToggleOverlay: (overlay: string, checked: boolean) => void;
  showMessage: (message: string) => void;
}

export default function MapToolbar({
  onToggleView,
  onToggleOverlay,
  showMessage,
}: MapToolbarProps) {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const [viewItem, setViewItem] = useState(viewDropdownItems);
  const [ovmItem, setOvmItem] = useState(overlayManagerDropdownItems);

  const toolbarMenu = {
    view: false,
    overlayManager: false,
  };
  const [showDropDown, setShowDropDown] = useState(toolbarMenu);

  const handleMenuClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    const { id } = e.currentTarget;
    setShowDropDown((prevState) => ({
      ...toolbarMenu,
      [id]: !prevState[id as keyof typeof showDropDown],
    }));
  };

  const handleViewItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const { id } = e.currentTarget;

    /* Temporarily show "Coming Soon" message for weather & maptool items until the feature ready */
    if (parseInt(id, 10) === 1 || parseInt(id, 10) === 2) {
      showMessage("Coming Soon");
      return;
    }

    const updatedItems = viewItem.map((view, index) => {
      if (index.toString() === id) {
        onToggleView(view.title, !view.isActive);
        return {
          ...view,
          isActive: !view.isActive,
        };
      }
      return view;
    });

    setViewItem(updatedItems);
    setShowDropDown(() => ({
      ...toolbarMenu,
      view: false,
    }));
  };

  const handleOverlayManagerItemClick = (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    const { id } = e.currentTarget;
    const updatedItems = ovmItem.map((ovm, index) => {
      if (index.toString() === id) {
        onToggleOverlay(ovm.title, !ovm.isActive);
        return {
          ...ovm,
          isActive: !ovm.isActive,
        };
      }
      return ovm;
    });

    setOvmItem(updatedItems);
    setShowDropDown(() => ({
      ...toolbarMenu,
      overlayManager: false,
    }));
  };

  return (
    <div className="toolbar">
      {/* horizontal ul */}
      <ul className="flex ">
        {/* <li className="mr-4">
          <span className="toolbar-menu">Preference</span>
        </li> */}
        <li className="mr-4">
          <div className="relative">
            <span id="view" className="toolbar-menu" onClick={handleMenuClick}>
              View
            </span>
            {showDropDown.view && (
              <ul className="toolbar-dropdown w-40">
                {viewItem.map((item, index) => (
                  <li
                    id={index.toString()}
                    key={item.title}
                    onClick={handleViewItemClick}
                  >
                    {item.isToggle && (
                      <FaCheck
                        fill="gray"
                        className={`${
                          item.isActive ? "visible" : "invisible"
                        } mr-2`}
                      />
                    )}
                    {item.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
        <li className="mr-4">
          <div className="relative">
            <span
              id="overlayManager"
              className="toolbar-menu"
              onClick={handleMenuClick}
            >
              Overlay Manager
            </span>
            {showDropDown.overlayManager && (
              <ul className="toolbar-dropdown w-60">
                {ovmItem.map((item, index) => (
                  <li
                    id={index.toString()}
                    key={item.title}
                    onClick={handleOverlayManagerItemClick}
                  >
                    {item.isToggle && (
                      <FaCheck
                        fill="gray"
                        className={`${
                          item.isActive ? "visible" : "invisible"
                        } mr-2`}
                      />
                    )}
                    {item.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </li>
        {/* <li className="mr-4">
          <span className="toolbar-menu">Help</span>
        </li> */}
      </ul>
      <span className="grow" />
      <p>{currentDate}</p>
    </div>
  );
}
