import { createContext, useState, useMemo } from "react";

export const AppColourTheme = createContext();

export function AppColourThemeProvider({ children }) {
  const [colourTheme, setColourTheme] = useState("Dark");

  const handleSetColourTheme = (colourThemeName) => {
    setColourTheme(colourThemeName);
  };

  const contextValue = useMemo(() => {
    return { colourTheme, handleSetColourTheme };
  }, [colourTheme]);

  return (
    <AppColourTheme.Provider value={contextValue}>
      {children}
    </AppColourTheme.Provider>
  );
}
