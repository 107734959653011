import axios from "axios";

export async function getGnsss(props) {
  // feature removed, moved to create operation
  const gnssData = [];
  const gnssPoints = [];

  // convert gnssData into geoJson for mapbox
  // eslint-disable-next-line no-plusplus
  for (let j = 0; j < gnssData.length; j++) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < gnssData[j].dop.bands.length; i++) {
      try {
        gnssPoints.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              gnssData[j].dop.points.pointWgs84[i].position.longitudeDeg,
              gnssData[j].dop.points.pointWgs84[i].position.latitudeDeg,
            ],
          },
          properties: {
            weight: gnssData[j].dop.bands[i].band[0],
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
  return gnssPoints;
}
