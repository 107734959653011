import { GroupedOperator, OperatorUser } from "@/model/api/GroupedOperator";
import {
  GroupedOrganisation,
  OrganisationUser,
} from "@/model/api/GroupedOrganisation";
import UserAccess from "@/model/UserAccess";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import ItemOperator from "./ItemCard/ItemOperator";
import ItemOrganisation from "./ItemCard/ItemOrganisation";

interface ItemOperationProps {
  api: any;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function OperatorManagement({
  api,
  showMessage,
  onOpenModal,
  onCloseModal,
}: ItemOperationProps) {
  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [assigningUsername, setAssigningUsername] = useState<string>("");

  /* Organisation Data */
  const [organisations, setOrganisations] = useState<GroupedOrganisation[]>([]);
  const [assignedOrganisations, setAssignedOrganisations] = useState<string[]>(
    []
  );

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const handleAddNew = () => {
    // setIsAdd(true);
    showMessage("Coming Soon");
  };

  const handleReturnToList = () => {
    setIsAdd(false);
  };

  const handleAssign = async (
    organisation_uuid: string,
    isAssigned: boolean
  ) => {
    if (!userAccess.privileges.includes("airspace.organisations.write")) return;
    showMessage(isAssigned ? "Unassigning Operator" : "Assigning Operator");
    setAssigningUsername(organisation_uuid);
    try {
      const responses = isAssigned
        ? await api.aas.unAssignOrganisation(organisation_uuid)
        : await api.aas.assignOrganisation(organisation_uuid);

      setAssigningUsername("");
      if (responses.error) {
        showMessage("Failed to Assign Operator", false, true);
      } else {
        showMessage("Operator Assigned Successfully", true);
        getAssignedOrganisations();
      }
    } catch {
      setAssigningUsername("");
      showMessage("Operator Assigned Successfully", true);
      getAssignedOrganisations();
    }
  };

  const openPilotListModal = async (organisationName: string) => {
    let pilots: OrganisationUser[] = [];
    try {
      const response = await api.aas.getOrganisationManagedPilots(
        organisationName
      );
      if (response?.data?.data) {
        pilots = response.data.data;
      }
    } catch (err) {
      showMessage && showMessage((err as any).message, true);
    }
    const content = (
      <>
        <div className="flex-col" onClick={(e) => e.stopPropagation()}>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-start">Username</th>
                <th className="px-4 py-2 text-start">Email</th>
              </tr>
            </thead>
            <tbody>
              {pilots.length > 0 &&
                pilots.map((pilot) => (
                  <tr key={pilot.userName}>
                    <td className="px-4 py-2">{pilot.userName}</td>
                    <td className="px-4 py-2">
                      {pilot.userAttributes[0].email}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </>
    );

    onOpenModal("Pilots", content);
  };

  const getOrganisationList = async () => {
    try {
      const response = await api.aas.getOrganisationList();
      setOrganisations(response.data);
    } catch (err) {
      showMessage && showMessage((err as any).message, true);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getAssignedOrganisations = async () => {
    setIsLoading(true);
    try {
      const response = await api.aas.getOrganisationManagedList();
      setAssignedOrganisations(response.data);
    } catch (err) {
      showMessage && showMessage((err as any).message, true);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getOrganisationList();
    getAssignedOrganisations();
  }, []);

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[100%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>Organisation Management</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            {/* Content */}
            <div className="h-[92%] flex-col overflow-auto p-4">
              {/* Operator List */}
              {organisations.map((singleOrganisation) => {
                return (
                  <ItemOrganisation
                    key={singleOrganisation.organisation_uuid}
                    organisation={singleOrganisation}
                    isAssigned={assignedOrganisations.includes(
                      singleOrganisation.organisation_name
                    )}
                    isAssigning={
                      assigningUsername === singleOrganisation.organisation_uuid
                    }
                    onViewPilots={openPilotListModal}
                    onAssign={handleAssign}
                  />
                );
              })}

              {/* No Data Available */}
              {!isLoading && organisations.length === 0 && (
                <p className="p-8 text-center">No Data Available</p>
              )}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          {/* <div className="h-[10%] px-4 py-2 items-center content-center">
            <Button
              type="primaryDark"
              size="medium"
              text="Add Operator"
              icon={<FaPlus size={12} color="white" />}
              onClick={handleAddNew}
            />
          </div> */}
        </div>
      )}
      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">Add New Operator</h5>
          </div>
          <hr />
        </div>
      )}
    </div>
  );
}
