import { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import CreateOperation from "../CreateOperation";
import UpcomingOperations from "../UpcomingOperations/index";
import { useApi } from "../../../api/useApi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div />
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function FlightAuthorizationComponent(props) {
  // console.log(document.querySelectorAll("p > div"));
  const {
    selectedFlightData,
    constraints,
    telemetryData,
    emergencyLanding,
    setEmergencyLanding,
    contingencyLandingPoint,
    setContingencyLandingPoint,
    emergencyLandingMove,
    selectedWaypoints,
    setSelectedWaypoints,
    onUpdateSelectedWaypoints,
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    onUpdateSelectedAreaWaypointsMove,
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    selectedCircleProperties,
    setSelectedCircleProperties,
    // onUpdateSelectedCircleWaypointsMove,
    // onUpdateSelectedCirclePropertiesMove,
    value,
    setValue,
    openFlightAuthorization,
    handleOpenFlightAuthorization,
  } = props;
  const [mapViewController, setMapViewController] = useState();
  // const [emergencyLanding, setEmergencyLanding] = useState([]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  // const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  // const [value, setValue] = useState(0); // Moved value to parent
  const [availablePilots, setAvailablePilots] = useState([]);
  const [availableTracker, setAvailableTracker] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getPlatforms = async () => {
    try {
      const response = await api.getPlatforms();
      if (response.data) {
        setAvailablePlatforms(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      if (response.data) {
        setAvailablePilots(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getTrackers = async () => {
    try {
      const response = await api.getTracker();
      if (response.data) {
        setAvailableTracker(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // width: "100vw",
        overflow: "auto",
      }}
    >
      <div style={{ width: 620, overflow: "auto" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab label="Create Flight Plan" {...a11yProps(0)} />
            <Tab label="Upcoming Operations" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <CreateOperation
            // mapViewController={mapViewController}
            /* Route props */
            selectedWaypoints={selectedWaypoints}
            setSelectedWaypoints={setSelectedWaypoints}
            /* polygon props */
            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            /* circle props */
            selectedCircleWaypoints={selectedCircleWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            setSelectedCircleProperties={setSelectedCircleProperties}
            /* emergency point props */
            emergencyLanding={emergencyLanding}
            setEmergencyLanding={setEmergencyLanding}
            /* other props */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            /* drawer toggler */
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            openFlightAuthorization={openFlightAuthorization}
            // contingencyLandingPoint={contingencyLandingPoint}
            // setContingencyLandingPoint={setContingencyLandingPoint}
            // onUpdateSelectedWaypoints={setUpdatedSelectedWaypoints}
            // onUpdateSelectedWaypoints={onUpdateSelectedWaypoints}
            // onUpdateSelectedAreaWaypoints={setUpdatedSelectedAreaWaypoints}
            // onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypointsMove}
            // onUpdateSelectedCircleWaypoints={onUpdateSelectedCircleWaypoints}
            // onUpdateSelectedCircleWaypoints={
            //   onUpdateSelectedCircleWaypoints
            // }
            // onUpdateSelectedCircleProperties={
            //   onUpdateSelectedCirclePropertiesMove
            // }

            // emergencyLanding={emergencyLanding}

            // onEmergencyLandingUpdate={setUpdatedEmergencyLanding}
            // setUpdatedEmergencyLanding={setUpdatedEmergencyLanding}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <UpcomingOperations
              setTabValue={setValue}
              handleOpenFlightAuthorization={handleOpenFlightAuthorization}

              // telemetryData={telemetryData}
            />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default FlightAuthorizationComponent;
