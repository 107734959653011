/**
 * Styles static constraints and parses them into GEOJson format
 */
import { HIGHLIGHT_COLORS } from "../../config/staticConstraintsColor";

const areasWithin5kmAerodome = require("../../lib/arcgis/Areas_within_5km_of_aerodromes.json");
const areasWithinDangerAreas = require("../../lib/arcgis/Areas_within_Danger_Areas.json");
const areasWithinProhibitedAreas = require("../../lib/arcgis/Areas_within_Prohibited_Areas.json");
const areasWithinRestrictedAreas = require("../../lib/arcgis/Areas_within_Restricted_Areas.json");
const protectedAreaUnderSection7AirNavigationAct = require("../../lib/arcgis/Protected_Area_under_Section_7_Air_Navigation_Act.json");
const areasOfInterest = require("../../lib/arcgis/Areas_of_interest.json");
const areasWithinKL = require("../../lib/arcgis/aip-data-around-kuala-lumpur.geojson");
/**
 * Outputs command to convert colour using RGB values
 * @param {*} colorArray Array containing the RGB values
 * @returns String containing command to pass in RGB values
 */
function convertColor(colorArray) {
  return `rgb(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]})`;
}

function fetchJSON(url) {
  return fetch(url).then((response) => response.json());
}

const getGeoJsonData = async () => {
  const areasWithinKLJson = await fetchJSON(areasWithinKL);
  return areasWithinKLJson;
};
/**
 * Styles static constraints and parses them into GEOJson for display on Mapbox
 * @returns Static constraints parsed into GEOJson format
 */
export default async function getStaticConstraints() {
  const getHeightinMeters = (valueInFeet) => {
    const meters = valueInFeet * 0.3048;
    return Math.round(meters);
  };

  const areas = [
    {
      area: areasWithin5kmAerodome,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 200,
    },
    {
      area: areasWithinDangerAreas,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 100,
    },
    {
      area: areasWithinProhibitedAreas,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 200,
    },
    {
      area: areasWithinRestrictedAreas,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 100,
    },
    {
      area: protectedAreaUnderSection7AirNavigationAct,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 150,
    },
    {
      area: areasOfInterest,
      color: HIGHLIGHT_COLORS.darkturquoise,
      height: 350,
    },
  ];
  const combinedConstraints = areas.flatMap((a) =>
    a.area.SrchResults.slice(1).map((r) => ({
      type: "Feature",
      properties: {
        color: convertColor(a.color),
        height: a.height,
        description: r.DESCRIPTION,
        name: r.NAME,
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          r.LatLng.split("|").map((c) =>
            c.split(",").map(parseFloat).reverse()
          ),
        ],
      },
    }))
  );
  const KLgeoJson = await getGeoJsonData();
  if (KLgeoJson.features) {
    KLgeoJson.features.map((singleFeature) => {
      combinedConstraints.push({
        geometry: singleFeature.geometry,
        type: "Feature",
        properties: {
          name: singleFeature.properties.name,
          description: singleFeature.properties.name,
          color: HIGHLIGHT_COLORS.darkturquoise,
          height: getHeightinMeters(
            singleFeature.properties.upperCeiling.value
          ),
        },
      });
    });
  }
  return combinedConstraints;
}
