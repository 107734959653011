import { SET_STATE_CONFORMANCE } from "../actions/operations";

const initState = {
  showConformanceLayer: false,
  data: { data: null },
};
const conformanceReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_STATE_CONFORMANCE: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default conformanceReducer;
