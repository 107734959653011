import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useApi } from "../../../api/useApi";
import useCognitoAuth from "../../../hooks/useCognitoAuth";
import { handleKmlImport, handleJsonImport } from "../../../services/json";
import { setSnackbarMessage } from "../../../store/actions";
import DroneImportedOperationList from "./DroneImportedOperationList";

function CreateOperationButton({
  handleOpenFlightAuthorizationForced,
  handleCloseAllDrawers,
  waylineList,
  setWaylineList,
  selectedFile,
  setSelectedFile,
  getData,
}) {
  // const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const api = useApi();

  const handleCreateOperation = async () => {
    try {
      if (!selectedFile) {
        alert("No file selected");
        return;
      }
      const DownloadFile = await api.getWaylineDownloadURL(selectedFile.Key);
      const URLResponse = DownloadFile.data;
      const fileContentResponse = await fetch(URLResponse.uploadURL);
      const fileContent2 = await fileContentResponse.blob();
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target.result;
        try {
          handleCloseAllDrawers();
          handleOpenFlightAuthorizationForced();
          try {
            const data = JSON.parse(fileContent);
            handleJsonImport(data, dispatch);
            setSelectedFile([]);
          } catch (error) {
            handleKmlImport(fileContent, dispatch);
            setSelectedFile([]);
          }
        } catch (error) {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "Error parsing file content",
              severity: "error",
            })
          );
        }
      };

      reader.readAsText(fileContent2);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Error parsing file content",
          severity: "error",
        })
      );
    }
  };

  const handleClearSelected = () => {
    setSelectedFile([]);
  };

  const deleteMissionfromCloud = async () => {
    try {
      if (!selectedFile) {
        alert("No file selected");
        return;
      }
      const filename = selectedFile.Key.split("/").pop();
      const fileOwner = selectedFile.Key.split("/")[0];
      const response = await api.deleteWayline(fileOwner, filename);
      if (response.data) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Mission deleted successfully",
            severity: "success",
          })
        );
        setWaylineList((prevList) =>
          prevList.filter((file) => file.Key !== selectedFile.Key)
        );
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Failed to delete Mission",
          severity: "error",
        })
      );
    }
    getData();
  };

  const handleDeleteMission = () => {
    deleteMissionfromCloud();
    setSelectedFile([]);
  };

  return (
    <div
      style={{
        paddingTop: "20px",
        // paddingBottom: "20px",
        width: "700px",
        // height: "500px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <div style={{ flex: 1, overflowY: "auto" }}>
        <List
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {waylineList.map((file, index) => {
            const fileName = file?.Key?.split("/").pop(); // Ensure file and file.Key are not undefined
            const uniqueKey = file?.Key || index; // Use index as fallback if file.key is undefined
            return (
              <ListItem
                key={uniqueKey} // Ensure each ListItem has a unique key prop
                button
                onClick={() => setSelectedFile(file)}
                style={{
                  outline: selectedFile === file ? "2px solid blue" : "none",
                  width: "120px",
                  height: "120px",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <CloudUploadIcon fontSize="large" />
                <ListItemText
                  primary={fileName}
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    maxHeight: "100%",
                    maxWidth: "100%",
                    wordWrap: "break-word",
                  }}
                  primaryTypographyProps={{
                    style: { maxWidth: "100%", fontSize: "inherit" },
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </div> */}
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleDeleteMission}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Delete Mission
        </Button>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleClearSelected}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Clear Selected
        </Button>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleCreateOperation}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Create Operation
        </Button>
      </div>
    </div>
  );
}

function DroneimportOperationComponent({
  handleOpenFlightAuthorizationForced,
  handleCloseAllDrawers,
}) {
  const [enableOverlay, setEnableOverlay] = useState(false);
  const [fileRaw, setFileRaw] = useState("");
  const [importedFile, setImportedFile] = useState("");
  const [isSelectedOption, setIsSelectedOption] = useState(false);
  const [waylineList, setWaylineList] = useState([]);
  const [availablePilots, setAvailablePilots] = useState([]);
  const [userList, setUserList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const api = useApi();

  const { username } = useCognitoAuth();
  const dispatch = useDispatch();

  const handleSelectOperation = (selectedOperation) => {
    setSelectedFile(selectedOperation);
  };
  const getData = async () => {
    try {
      setEnableOverlay(true);
      const wayline = await api.getWayline(username);
      if (wayline) {
        const URLResponse = wayline.data;
        let waylineListExtracted = [];
        for (const operator in URLResponse) {
          if (URLResponse[operator].downloadURL.Contents) {
            waylineListExtracted = waylineListExtracted.concat(
              URLResponse[operator].downloadURL.Contents
            );
          }
        }

        waylineListExtracted = waylineListExtracted.filter((file) => {
          return !file.Key.includes("Deconflict");
        });

        setWaylineList(waylineListExtracted);
      }
    } catch (err) {
      console.log(err);
    }
    setEnableOverlay(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      for (let u = 0; u < response.data.length; u += 1) {
        availablePilots.push(response.data[u].pilot_username);
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const handleImport = async (e) => {
    if (e) {
      const file = e.target.files[0];
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async (event) => {
          const fileContent = event.target.result;
          setFileRaw(fileContent);
          setImportedFile(file);
          setOpenDialog(true);
        };
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const handleSubmitDialog = () => {
    setOpenDialog(false);
    if (importedFile) {
      for (let i = 0; i < userList.length; i += 1) {
        const user = userList[i];
        if (user !== "None") {
          handleUpload(importedFile, user);
        }
      }
    }
    setUserList([]);
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
    setUserList([]);
  };

  const handlePilotSelect = (e) => {
    const selectedPilot = e.target.value;
    setIsSelectedOption(selectedPilot);
    setUserList((prevUserList) => [...prevUserList, username, selectedPilot]);
  };

  const handleUpload = async (file, user) => {
    const fileName = file.name;
    const response = await api.getWaylineUploadURL({
      loginUser: user,
      fileName,
    });
    const URLResponse = response.data;
    try {
      const putResponse = await axios.put(URLResponse.uploadURL, file, {
        headers: { "Content-Type": file.type },
      });
      if (putResponse.statusText === "OK") {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "File Uploaded successfully",
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <LoadingOverlay active={enableOverlay} spinner>
      <div>
        <Button
          component="label"
          size="small"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginBottom: "5px",
          }}
        >
          Upload WayLine Mission
          <input type="file" onChange={(getPilots(), handleImport)} hidden />
        </Button>
        <Dialog open={openDialog} onClose={handleCancelDialog}>
          <DialogTitle>Select Pilot</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="pilot-select-label">Pilot</InputLabel>
              <Select
                labelId="pilot-select-label"
                id="pilot-select"
                value={userList}
                onChange={handlePilotSelect}
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                {availablePilots
                  .filter(
                    (pilot_Username, index, self) =>
                      self.indexOf(pilot_Username) === index
                  )
                  .map((pilot_Username) => (
                    <MenuItem key={pilot_Username} value={pilot_Username}>
                      {pilot_Username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSubmitDialog}
              color="primary"
              disabled={!isSelectedOption}
            >
              Submit
            </Button>
            <Button onClick={handleCancelDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <DroneImportedOperationList
          waylineList={waylineList}
          selectedFile={selectedFile}
          handleSelectOperation={handleSelectOperation}
        />
        <CreateOperationButton
          handleOpenFlightAuthorizationForced={
            handleOpenFlightAuthorizationForced
          }
          handleCloseAllDrawers={handleCloseAllDrawers}
          waylineList={waylineList}
          setWaylineList={setWaylineList}
          getData={getData}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      </div>
    </LoadingOverlay>
  );
}

export default DroneimportOperationComponent;
