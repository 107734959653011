import React from "react";
import { User } from "@/model/api/User";
import { formatToDisplayDate } from "@/utils/date_utils";

interface UserItemProps {
  data: User;
  onClick?: (user: User) => void;
  onSelect?: (id: string, isSelected: boolean) => void;
}

export default function UserItem({ data, onClick, onSelect }: UserItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(data.username, e.target.checked);
  };

  return (
    <div className="relative p-2 rounded-container mb-2 cursor-pointer">
      <div
        key={data.username}
        onClick={() => onClick && onClick(data)}
        className="flex-col"
      >
        <h5 className="font-medium">{data.username}</h5>
        <p>Default Role : {data.current_user_role}</p>
        <p className="mb-2">Email : {data.email}</p>
        <div className="flex flex-wrap">
          {data.user_roles?.map((singleRole) => {
            return (
              <span
                key={`${data.username}${singleRole}`}
                className="new-badge mb-2 mr-2"
              >
                {singleRole}
              </span>
            );
          })}
        </div>
        <span className="new-badge new-badge-success mb-2">
          {formatToDisplayDate(data.date_created)}
        </span>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
