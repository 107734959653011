/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Button from "@/components/v1/Common/Button";
import Tracker from "@/model/api/Tracker";
import { useState } from "react";
import InputMask from "react-input-mask";
import moment from "moment";

interface TrackerFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  tracker?: Tracker | null;
  showMessage?: (message: string) => void;
  onSaveSucess: () => void;
  hasWritePrivilege?: boolean;
}

export default function TrackerForm({
  api,
  tracker,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
}: TrackerFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [form, setForms] = useState({
    tracker_uuid: tracker?.tracker_uuid || "",
    tracker_sn: tracker?.tracker_sn || "",
    tracker_imei: tracker?.tracker_imei || "",
    tracker_name: tracker?.tracker_name || "",
    sim_expiry: tracker?.sim_expiry || "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSave = async () => {
    showMessage && showMessage("Saving tracker details");
    setIsLoading(true);

    try {
      let response: any;

      if (tracker) {
        response = await api.updateTracker({
          selectedtrackeruuid: form.tracker_uuid,
          trackerimei: form.tracker_imei,
          trackername: form.tracker_name,
          trackerSn: form.tracker_sn,
          simexpiry: moment(form.sim_expiry).format("YYYY-MM-DDTHH:MM:SS.sssZ"),
        });
      } else {
        response = await api.submitNewTracker({
          trackerimei: form.tracker_imei,
          trackername: form.tracker_name,
          trackerSn: form.tracker_sn,
          simexpiry: moment(form.sim_expiry).format("YYYY-MM-DDTHH:MM:SS.sssZ"),
        });
      }

      if (response.data) {
        setIsLoading(false);
        onSaveSucess();
      }
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setIsLoading(false);
        setErrorMessage("User does not have rights to modify asset");
      } else {
        setIsLoading(false);
        setErrorMessage((err as any).message);
      }
    }
  };

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Call Sign
        </label>
        <input
          type="text"
          id="tracker_name"
          className="input-text"
          defaultValue={form.tracker_name}
          onChange={onChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Tracker S/N
        </label>
        <input
          type="text"
          id="tracker_sn"
          className="input-text"
          defaultValue={form.tracker_sn}
          onChange={onChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Tracker IMEI
        </label>
        <input
          type="text"
          id="tracker_imei"
          className="input-text"
          defaultValue={form.tracker_imei}
          onChange={onChange}
        />
      </div>
      <div className="mb-6">
        <label htmlFor="username" className="label">
          SIM Card Expiry Date
        </label>
        <InputMask
          id="sim_expiry"
          mask="99/99/9999"
          maskChar={null}
          placeholder="dd/mm/yyyy"
          className="input-text"
          defaultValue={moment(form.sim_expiry).format("DD/MM/YYYY")}
          onChange={onChange}
        />
      </div>

      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}
