/**
 * Computes circle center
 * @param {*} wps 2D array of lat-lon coordinates
 * @returns 1D array representing lat-lon of polygon center
 */
export function computeCircleCenter(wps) {
  let latHigh = -90;
  let latLow = 90;
  let lngHigh = -180;
  let lngLow = 180;
  for (const coord of wps) {
    const [longitude, latitude] = coord;

    // Update highest and lowest values for longitude
    if (longitude > lngHigh) {
      lngHigh = longitude;
    }
    if (longitude < lngLow) {
      lngLow = longitude;
    }

    // Update highest and lowest values for latitude
    if (latitude > latHigh) {
      latHigh = latitude;
    }
    if (latitude < latLow) {
      latLow = latitude;
    }
  }
  return [(lngHigh + lngLow) / 2, (latHigh + latLow) / 2];
}
