import { ADD_WEBSOCKET, DROP_WEBSOCKET } from "../actions/websocket";

const channels = {};
const websocket = (state = channels, action) => {
  switch (action.type) {
    case ADD_WEBSOCKET:
      return { ...channels, ...action.payload };
    case DROP_WEBSOCKET:
      return action.status;
    default:
      return state;
  }
};

export default websocket;
