import React from "react";
import { Operation } from "@/model/api/Operation";
import moment from "moment";
import { FaRegCopy } from "react-icons/fa";
import { IoMdPerson } from "react-icons/io";
import { PiDrone } from "react-icons/pi";

interface ItemOperationProps {
  operation: Operation;
  pilotName: string;
  trackerName: string;
  isDeleting?: boolean;
  isSelected?: boolean;
  copyOperationId?: (id: string) => void;
  onClick: (operation: Operation) => void;
  onSelected: (id: string) => void;
}

export default function ItemOperation({
  operation,
  pilotName,
  trackerName,
  isDeleting,
  isSelected,
  copyOperationId,
  onClick,
  onSelected,
}: ItemOperationProps) {
  return (
    <div
      className="w-full rounded-container-np flex-col mb-4 cursor-pointer"
      onClick={() => onClick(operation)}
    >
      <div className="flex p-2 items-center">
        <h5 className="font-medium mb-1 grow text-ellipsis">
          {operation.operation_json.reference.intent}
        </h5>
        <input
          type="checkbox"
          className="input-checkbox"
          disabled={isDeleting}
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSelected(operation.operation_json.reference.id)}
        />
      </div>
      <div className="flex px-2 py-4 border-t dark:border-gray-600">
        <div className="rounded-lg bg-green-200 dark:bg-gray-500 h-16 w-16 content-center items-center">
          <PiDrone
            size={28}
            className="m-auto fill-green-500 dark:fill-gray-400"
          />
        </div>
        <div className="flex flex-col px-4 grow">
          <h5 className="font-medium mb-1">{trackerName}</h5>
          <div className="flex items-center mb-1">
            <p className="text-sm text-ellipsis overflow-hidden mr-2 font-medium">
              {operation.operation_json.reference.id.substring(0, 10)}...
            </p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                copyOperationId &&
                  copyOperationId(operation.operation_json.reference.id);
              }}
            >
              <FaRegCopy size={14} color="gray" />
            </button>
          </div>
          <p className="text-sm">
            {moment(
              operation.operation_json.interuss.operational_intent_reference
                .time_start.value
            ).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex-col content-end items-end justify-end">
          <div className="flex items-center content-center">
            <span className="text-sm">
              {moment(
                operation.operation_json.interuss.operational_intent_reference
                  .time_start.value
              ).format("HH:mm A")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
          <div className="flex items-center content-center mt-5">
            <span className="text-sm">
              {moment(
                operation.operation_json.interuss.operational_intent_reference
                  .time_end.value
              ).format("HH:mm A")}
            </span>
            <span className="rounded-full bg-gray-300 h-2 w-2 mx-1" />
          </div>
        </div>
      </div>
      <div className="rounded-b-lg flex p-2 border-t dark:border-gray-600 -mt-1 bg-gray-100 dark:bg-darkTertiary items-center content-center">
        <IoMdPerson />
        <span className="ml-2">{pilotName}</span>
        <span className="grow" />
        {operation.operation_json.request.operation_type && (
          <span className="new-badge new-badge-success">
            {operation.operation_json.request.operation_type}
          </span>
        )}
        {operation.operation_json.request.operation_type && (
          <span className="new-badge">
            {operation.operation_json.details.state}
          </span>
        )}
      </div>
    </div>
  );
}
