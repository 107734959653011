import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Alert,
} from "@mui/material";

export default function AlertDialog(props) {
  const [open, setOpen] = useState(props.open);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [error, setError] = useState("");

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleConfirm = () => {
    props.sendNotification(props.message);
    handleClose();
  };

  const notifyMessageChangeHandler = (event) => {
    if (event.target.value.trim().length > 0) {
      setError(null);
    }
    setNotifyMessage(event.target.value);
  };

  const submitNotifyMessageHandler = (event) => {
    event.preventDefault();
    if (notifyMessage.trim().length === 0) {
      setError(true);
      return;
    }
    const msgPrefix = `Message for operation: ${props.operationName}`;
    props.sendNotification(`${msgPrefix}+${notifyMessage}`);
    setNotifyMessage("");
    handleClose();
  };

  // const errorHandler = () => {
  //   setError("");
  // };

  return (
    <div>
      {props.inputTextFieldRequired ? (
        <Dialog open={open} onClose={handleClose}>
          {props.dialogTitle && (
            <DialogTitle id="alert-dialog-title" align="center">
              Operation: {props.operationName}
              <hr />
              {props.dialogTitle}
            </DialogTitle>
          )}
          <DialogContent id="alert-dialog-description">
            {error && <Alert severity="error">Message cannot be empty!</Alert>}
            <Box justifyContent="center">
              {props.dialogText && (
                <DialogContentText id="alert-dialog-description">
                  <b>Operation: {props.operationName}</b>
                  <hr />
                  {props.dialogText}
                </DialogContentText>
              )}
              <form onSubmit={submitNotifyMessageHandler}>
                <TextField
                  required
                  fullWidth
                  value={notifyMessage}
                  onChange={notifyMessageChangeHandler}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    onClick={submitNotifyMessageHandler}
                    autoFocus
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" align="center">
            <Alert variant="outlined" severity="warning">
              {props.dialogTitle}
            </Alert>
          </DialogTitle>

          <DialogContent>
            {props.dialogText && (
              <DialogContentText id="alert-dialog-description">
                <b>Operation: {props.operationName}</b>
                <br />
                {props.dialogText}
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirm} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
