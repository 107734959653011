/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import mapboxgl, { PointLike } from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { getairMapSpaceData, setMapboxController } from "@/store/actions";
import UserAccess from "@/model/UserAccess";
import { RulerControl } from "mapbox-gl-controls";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import { Button } from "@mui/material";
import { useApi } from "@/api/useApi";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { format } from "date-fns";
import Tracker from "@/model/api/Tracker";
import Platform from "@/model/api/Platform";
import Pilot from "@/model/api/Pilot";
import {
  getAirSpaceFetaures,
  getConformanceFeatures,
  getConstraintsFeatures,
  getOperationFeatures,
  setLayerVisibility,
} from "@/components/MapB/layers";
import { OperationJson } from "@/model/api/Operation";
import useCognitoAuth from "@/hooks/useCognitoAuth";
import { messageTypes } from "@/config/messageTypes";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import { channels } from "../../../config/channels";
import draws from "./model";
import MapboxController from "../../MapB/controller";
import { addMapLayers, setMapListeners } from "../../MapB/init";
import useWebsocket from "../../../hooks/useWebsocket";

interface MyState {
  shipData: { visible: false; data: [] };
  ADSBDataPaid: { visible: false; data: [] };
  ADSBDataFree: { visible: false; data: [] };
  airMapSpaceData: { visible: false; data: [] };
  conformanceData: {
    showConformanceLayer: false;
    data: { data: null };
  };
}

interface MapViewProps {
  mapTheme: string;
  selectedFlightData: OperationJson[];
  selectedTrackerData: { data: Tracker }[][] | undefined;
  selectedPlatformData: { data: Platform }[][] | undefined;
  emergencyLanding: number[];
  selectedWaypoints: number[][];
  selectedAreaWaypoints: number[][];
  selectedCircleWaypoints: number[][];
  selectedCircleProperties: SelectedCircleProperties;
  constraints: [];
  showShipLayer: boolean;
  showAdsbLayer: boolean;
  showConstraintsLayer: boolean;
  showStaticConstraintsLayer: boolean;
  showRainMapLayer: boolean;
  showAirMapSpace: boolean;
  showOperationVolumeLayer: boolean;
  showMapElevation: boolean;
  showDroneIconLayer: boolean;
  showGnssLayer: boolean;
  showAnchorageLayer: boolean;
  showDronePortLayer: boolean;
  showSgBoundaryLayer: boolean;
  showSgPopulationDensity: boolean;
  showCollisionLayer: boolean;
  handleSetEmergencyLanding: (data: any) => void;
  handleSetSelectedWaypoints: (data: any) => void;
  handleSetSelectedAreaWaypoints: (data: any) => void;
  handleSetSelectedCircleProperties: (data: SelectedCircleProperties) => void;
  handleSetSelectedCircleWaypoints: (data: any) => void;
  handleToggleMostRecentComponent: (data: any) => void;
  handleOpenShipDeliveryOperationsForced: () => void;
  setSelectedShip: (data: any) => void;
}

interface UserAccessState {
  userAccess: UserAccess;
}

export default function MapView({
  mapTheme,
  selectedFlightData,
  selectedTrackerData,
  selectedPlatformData,
  emergencyLanding,
  selectedWaypoints,
  selectedAreaWaypoints,
  selectedCircleWaypoints,
  selectedCircleProperties,
  constraints,
  showShipLayer,
  showAdsbLayer,
  showConstraintsLayer,
  showStaticConstraintsLayer,
  showRainMapLayer,
  showAirMapSpace,
  showOperationVolumeLayer,
  showMapElevation,
  showDroneIconLayer,
  showGnssLayer,
  showAnchorageLayer,
  showDronePortLayer,
  showSgBoundaryLayer,
  showSgPopulationDensity,
  showCollisionLayer,
  handleSetEmergencyLanding,
  handleSetSelectedWaypoints,
  handleSetSelectedAreaWaypoints,
  handleSetSelectedCircleProperties,
  handleSetSelectedCircleWaypoints,
  handleToggleMostRecentComponent,
  handleOpenShipDeliveryOperationsForced,
  setSelectedShip,
}: MapViewProps) {
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const dispatch = useDispatch();

  const [initializeMapB, setInitializeMapB] = useState(0);
  const selectedTrackerDataRef = useRef<any>([]);
  const selectedPlatformDataRef = useRef<any>([]);
  const collisionDatasRef = useRef(new Map());

  const IS_ADSB_PAID = false;

  const [modelMap, setModelMap] = useState(new Map());
  const [zoom, setZoom] = useState(11);
  const [trackerStatusMap, setTrackerStatusMap] = useState(new Map());
  const [trackerOpsMap, setTrackerOpsMap] = useState(new Map());
  const [style, setStyle] = useState(null);

  const [coordinate, setCoordinate] = useState({
    lat: 1.3513050424172892,
    lng: 103.80809418043583,
  });

  // Get the data from the store
  const api = useApi();
  const envVar = useSelector((state: any) => state.envVar);
  const shipData = useSelector((state: MyState) => state.shipData.data);
  const ADSBData = useSelector((state: MyState) =>
    IS_ADSB_PAID ? state.ADSBDataPaid.data : state.ADSBDataFree.data
  );
  const airMapSpaceData = useSelector(
    (state: MyState) => state.airMapSpaceData.data
  );
  const conformanceData = useSelector(
    (state: MyState) => state.conformanceData.data
  );
  const showConformanceLayer = useSelector(
    (state: MyState) => state.conformanceData.showConformanceLayer
  );

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const dataConformanceGL = localStorage.getItem("conformanceData")
    ? localStorage.getItem("conformanceData")
    : JSON.stringify([]);

  // Map Controller
  const draw: MapboxDraw = draws;
  const ruler = new RulerControl();

  const platformList = useRef<Platform[]>([]);
  const trackerList = useRef<Tracker[]>([]);
  const pilotList = useRef<Pilot[]>([]);

  // Operation
  const [focussedOperation, setFocussedOperation] =
    useState<OperationJson | null>(null);

  const [teleObj, setTeleObj] = useState<any>();
  const teleObjRef = useRef<any>();
  const actualTeleObjRef = useRef<{
    [key: string]: any;
  }>({});
  const keyRef = useRef<{
    [key: string]: boolean;
  }>({});
  const actualKeyRef = useRef<{
    [key: string]: boolean;
  }>({});

  // const [selectedTrackerData, setSelectedTrackerData] = useState([]);
  // const [selectedPlatformData, setSelectedPlatformData] = useState([]);

  const [telemetryData, setTelemetryData] = useState([]);
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });

  const { username, isOperator, isAuthority, isPilot, isRemoteId } =
    useCognitoAuth();

  const addMapControllers = () => {
    if (!mapContainer.current) return;
    let zoomLevel = zoom;
    if (coordinate.lat === null || coordinate.lng === null) {
      setZoom(2);
      zoomLevel = 2; // setZoom is async so instead of await for zoom useState, this should be faster
    }

    dispatch(
      setMapboxController(
        new MapboxController(mapRef.current, draw, setFocussedOperation)
      )
    );
    // TODO: Add the rest of the controllers here
    setMapListeners(
      mapRef.current,
      draw,
      // onSelectEmergencyLanding,
      handleSetEmergencyLanding,
      // onSelectWaypoints,
      // onSelectAreaWaypoints,
      // onSelectCircleWaypoints,
      // onSelectCircleProperties,
      handleSetSelectedWaypoints,
      handleSetSelectedAreaWaypoints,
      handleSetSelectedCircleProperties,
      handleSetSelectedCircleWaypoints,
      handleToggleMostRecentComponent
    );

    /**
     * Add controls onto the current map on loading of site
     */
    // Add geolocate control to the mapRef?.
    mapRef?.current?.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );
    // TODO: Later
    mapRef?.current?.addControl(ruler, "top-right");

    mapRef?.current?.addControl(
      new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true,
      }),
      "top-right"
    );

    mapRef?.current?.addControl(draw, "top-right");

    const styles = [
      {
        title: "Satellite",
        uri: "mapbox://styles/mapbox/satellite-streets-v12",
      },
      {
        title: "Street",
        uri: "mapbox://styles/mapbox/streets-v12",
      },
      {
        title: "Outdoors",
        uri: "mapbox://styles/mapbox/outdoors-v12",
      },
      {
        title: "Light",
        uri: "mapbox://styles/mapbox/light-v11",
      },
      {
        title: "Dark",
        uri: "mapbox://styles/mapbox/dark-v11",
      },
    ];
    const options = {
      defaultStyle: "Street",
      eventListeners: {
        onChange: (event: MouseEvent, style: string) => {
          // TODO: Add logic to change the map style
          // const colourThemeName = event.target.className.split(" ")[0];
          // handleSetColourTheme(colourThemeName);
          return true;
        },
      },
    };

    mapRef?.current?.addControl(
      new MapboxStyleSwitcherControl(styles, options),
      "top-right"
    );

    /**
     * Handles logic for popup
     */
    mapRef?.current?.on("click", async (e) => {
      if (draw.getMode() !== "simple_select" || ruler.isMeasuring) {
        return;
      }
      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        closeOnMove: true,
      });

      const ADSBLayer = mapRef?.current?.getLayer("adsb-layer");
      if (!ADSBLayer) return;
      const ADSBDetails = (
        ADSBLayer as any
      ).implementation.getClickedObjectDetails(e.point);
      if (ADSBDetails) {
        const description = `
          <table>
          <style>
            tr.adsb:nth-of-type(even) {
              background-color: #ffcccb;
            }
          </style>
            <tr><td><b>CALLSIGN: ${ADSBDetails.callsign}</b></td></tr>
            <tr class="adsb"><td>ALT: ${ADSBDetails.alt}</td></tr>
            <tr class="adsb"><td>GS: ${ADSBDetails.gs}</td></tr>
            <tr class="adsb"><td>HDG: ${ADSBDetails.heading}</td></tr>
          </table>
          `;

        if (mapRef.current) {
          popup.setHTML(description).setLngLat(e.lngLat).addTo(mapRef.current);
        }
        return;
      }

      const AISLayer = mapRef?.current?.getLayer("ais-layer");
      if (!AISLayer) return;
      const AISDetails = (
        AISLayer as any
      ).implementation.getClickedObjectDetails(e.point);
      if (AISDetails) {
        const handleClickShipDelivery = (data: {
          lat: any;
          lng: any;
          vesselName: any;
          callSign: any;
        }) => {
          // TODO: Add logic to handle ship delivery
          setSelectedShip({
            latitudeDegrees: data.lat,
            longitudeDegrees: data.lng,
            vesselName: data.vesselName,
            callSign: data.callSign,
          });
          handleOpenShipDeliveryOperationsForced();
        };

        // Create a container element
        const container = document.createElement("div");

        const customButton = (
          <Button
            variant="contained"
            size="small"
            className="custom-operation-button"
            fullWidth
          >
            <LocalShippingIcon />
          </Button>
        );

        // // Render the button into the container
        ReactDOM.render(customButton, container);

        const popupContent = document.createElement("div");
        popupContent.innerHTML = `
        <table>
          <style>
            tr.ais:nth-of-type(even) {
              background-color: #ffcccb;
            }
          </style>
          <tr><td><b>NAME: ${AISDetails.vesselName}</b></td></tr>
          <tr class="ais"><td>CALLSIGN: ${AISDetails.callSign}</td></tr>
          <tr class="ais"><td>L: ${AISDetails.vesselLength} | W: ${AISDetails.vesselBreadth}</td></tr>
          <tr class="ais"><td>LAT: ${AISDetails.lat}</td></tr>
          <tr class="ais"><td>LON: ${AISDetails.lng}</td></tr>
          <tr class="adsb"><td></td></tr> <!-- This row is left empty for the custom button -->
        </table>`;

        const adsbTdElement = popupContent.querySelector(".adsb td");
        if (adsbTdElement) {
          adsbTdElement.appendChild(container);
          if (container.firstChild) {
            adsbTdElement.appendChild(container.firstChild);
          }
        }

        // Access the button inside the container
        const buttonElement = popupContent.querySelector("button");
        if (buttonElement) {
          // Add an event listener to the button
          buttonElement.addEventListener("click", () =>
            handleClickShipDelivery(AISDetails)
          );
        }
        if (mapRef.current) {
          popup
            .setDOMContent(popupContent)
            .setLngLat(e.lngLat)
            .addTo(mapRef?.current);
        }
        return;
      }

      const trackerLayer = mapRef?.current?.getLayer("tracker-layer");
      if (!trackerLayer) return;
      const trackerDetails = (
        trackerLayer as any
      ).implementation.getClickedObjectDetails(e.point);
      if (trackerDetails) {
        // const platformResponse = await api.getPlatform(
        //   // trackerDetails.opsVolume.request.platform_uuid
        //   trackerDetails.platformData.platform_uuid
        // );
        // const platformTypeResponse = await api.getPlatformType(
        //   platformResponse.data.platform_type_uuid
        // );
        const description = `
          <table>
          <style>
            tr.trackd:nth-of-type(even) {
              background-color: #d3d3d3;
            }
          </style>
            <tr><td><b>${trackerDetails?.intent}</b></td></tr>
            <tr class="trackd"><td>TRACKER: ${trackerDetails?.trackerData?.tracker_name}</td></tr>
            <tr class="trackd"><td>SPEED: ${trackerDetails?.speed}M/S | ALT: ${trackerDetails?.altitude}M | HDG: ${trackerDetails.heading}</td></tr>
            <tr class="trackd"><td>LAT: ${trackerDetails?.position?.lat} | LNG: ${trackerDetails?.position?.lng}</td></tr>
          </table>
          `;
        if (mapRef.current) {
          popup.setHTML(description).setLngLat(e.lngLat).addTo(mapRef.current);
        }
        return;
      }

      // Create Point objects for the bounding box coordinates
      const point1: PointLike = new mapboxgl.Point(
        e.point.x - 5,
        e.point.y - 5
      );
      const point2: PointLike = new mapboxgl.Point(
        e.point.x + 5,
        e.point.y + 5
      );

      // Pass the bounding box as an array of PointLike objects
      const selectedStaticConstraints = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["no-fly"],
        }
      );

      if (selectedStaticConstraints && selectedStaticConstraints.length) {
        const { description, name } = selectedStaticConstraints[0]
          .properties as { description: string; name: string };
        const staticConstraintDescription = `
          <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="statconst"><td>${description}</td></tr>
          </table>
          `;

        if (mapRef?.current) {
          popup
            .setHTML(staticConstraintDescription)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      const selectedAnchorageConstraints =
        mapRef?.current?.queryRenderedFeatures([point1, point2], {
          layers: ["sg-anchor"],
        });
      if (selectedAnchorageConstraints && selectedAnchorageConstraints.length) {
        const { id, seamark_na } = selectedAnchorageConstraints[0]
          .properties as { id: string; seamark_na: string };
        const anchorageDescription = `
          <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td><b>${id}</b></td></tr>
            <tr class="statconst"><td>${seamark_na}</td></tr>
          </table>
          `;

        if (mapRef?.current) {
          popup
            .setHTML(anchorageDescription)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      const selectedDronePort = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["sg-port"],
        }
      );

      if (selectedDronePort && selectedDronePort.length) {
        const { id, seamark_na } = selectedDronePort[0].properties as {
          id: string;
          seamark_na: string;
        };
        const dronePortDescription = `
        <table>
          <style>
            tr.statconst:nth-of-type(even) {
              background-color: #add8e6;
            }
          </style>
          <tr><td><b>${id}</b></td></tr>
          <tr class="statconst"><td>${seamark_na}</td></tr>
        </table>
        `;

        if (mapRef?.current) {
          popup
            .setHTML(dronePortDescription)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      const selectedPopulationDensity = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["sg-population-density-layer"],
        }
      );

      if (selectedPopulationDensity && selectedPopulationDensity.length) {
        const { name, census_population, dynamic_population } =
          selectedPopulationDensity[0].properties as {
            name: string;
            census_population: number;
            dynamic_population: number;
          };
        const populationDensityDescription = `
        <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td>Location : <b>${name}</b></td></tr>
            <tr class="dynamic_pop"><td>Dynamic Population : ${dynamic_population}</td></tr>
            <tr class="dynamic_pop"><td>Census Population : ${census_population}</td></tr>
          </table>
          `;

        if (mapRef?.current) {
          popup
            .setHTML(populationDensityDescription)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      const selectedConstraints = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["constraints"],
        }
      );

      if (selectedConstraints && selectedConstraints.length) {
        const {
          constraintID,
          name,
          desc,
          altitudeLower,
          altitudeHigher,
          timeStart,
          timeEnd,
          rule,
          prohibitReq,
          prohibitWhitelist,
          authReq,
          authWhitelist,
          conditions,
          recurrenceRange,
          recurring,
        } = selectedConstraints[0].properties as {
          constraintID: string;
          name: string;
          desc: string;
          altitudeLower: number;
          altitudeHigher: number;
          timeStart: string;
          timeEnd: string;
          rule: string;
          prohibitReq: string;
          prohibitWhitelist: string;
          authReq: string;
          authWhitelist: string;
          conditions: string;
          recurrenceRange: string;
          recurring: string;
        };

        const formatDate = (date: string) =>
          format(new Date(date), "dd/MM/yyyy HH:mm:ss");
        const jsonRules = JSON.parse(rule);
        const rules = Object.keys(jsonRules)
          .filter((k) => jsonRules[k])
          .join(", ")
          .replace("_", " ");

        let constraintsDescription = `
          <table>
            <style>
              tr.const:nth-of-type(even) {
                background-color: #ffcccb;
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="const"><td>CID: ${constraintID}</td></tr>
            <tr class="const"><td>${desc}</td></tr>
            <tr class="const"><td>Alt: ${altitudeLower} to ${altitudeHigher}M</td></tr>
            <tr class="const"><td>Active Window: ${timeStart} to ${timeEnd}</td></tr>
          `;
        let rulesRequired = "";
        if (recurring) {
          const rRangeJson = JSON.parse(recurrenceRange);
          const startTime = formatDate(rRangeJson.time_start.value);
          const endTime = formatDate(rRangeJson.time_end.value);
          constraintsDescription = constraintsDescription.concat(`
            <tr class="const"><td>Recurs ${recurring} from ${startTime} to ${endTime}</td></tr>
            `);
        }
        if (jsonRules.prohibited && prohibitReq) {
          const prohibitReqJSON = JSON.parse(prohibitReq);
          let startTime;
          if (prohibitReqJSON?.time_start?.value) {
            startTime = formatDate(prohibitReqJSON.time_start.value);
          }
          let endTime;
          if (prohibitReqJSON?.time_end?.value) {
            endTime = formatDate(prohibitReqJSON.time_end.value);
          }
          const whitelistJSON =
            prohibitWhitelist && JSON.parse(prohibitWhitelist);
          if (whitelistJSON?.users?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.users.length > 3
                  ? `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}... +${whitelistJSON.users.length - 3} more. `
                  : `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }
          if (whitelistJSON?.usergroups?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.usergroups.length > 3
                  ? `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}... +${
                      whitelistJSON.usergroups.length - 3
                    } more. `
                  : `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }

          if ("recurring" in prohibitReqJSON) {
            const rRangeJson = prohibitReqJSON.recurrence_range;
            startTime = formatDate(rRangeJson.time_start.value);
            endTime = formatDate(rRangeJson.time_end.value);
            const rStartTime = formatDate(prohibitReqJSON.time_start.value);
            const rEndTime = formatDate(prohibitReqJSON.time_end.value);
            rulesRequired = rulesRequired.concat(`
              Prohibited from ${startTime} to ${endTime}. <br>
              `);
            rulesRequired = rulesRequired.concat(`
              Recurs ${prohibitReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
              `);
          } else if (startTime && endTime) {
            rulesRequired = rulesRequired.concat(`
            Prohibited from ${startTime} to ${endTime}. <br>
            `);
          }
        }
        if (jsonRules.authorisation_required) {
          const authReqJSON = JSON.parse(authReq);
          let startTime;
          if (authReqJSON?.time_start?.value) {
            startTime = formatDate(authReqJSON.time_start.value);
          }
          let endTime;
          if (authReqJSON?.time_end?.value) {
            endTime = formatDate(authReqJSON.time_end.value);
          }
          const whitelistJSON = authWhitelist && JSON.parse(authWhitelist);
          if (whitelistJSON?.users?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.users.length > 3
                  ? `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}... +${whitelistJSON.users.length - 3} more. `
                  : `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }
          if (whitelistJSON?.usergroups?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.usergroups.length > 3
                  ? `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}... +${
                      whitelistJSON.usergroups.length - 3
                    } more. `
                  : `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}.<br>`
              }
              `);
          }

          if ("recurring" in authReqJSON) {
            const rRangeJson = authReqJSON.recurrence_range;
            startTime = formatDate(rRangeJson.time_start.value);
            endTime = formatDate(rRangeJson.time_end.value);
            const rStartTime = formatDate(authReqJSON.time_start.value);
            const rEndTime = formatDate(authReqJSON.time_end.value);
            rulesRequired = rulesRequired.concat(`
              Authorisation required from ${startTime} to ${endTime}.<br>
              `);
            rulesRequired = rulesRequired.concat(`
                Recurs ${authReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
                `);
          } else if (startTime && endTime) {
            rulesRequired = rulesRequired.concat(`
            Authorisation required from ${startTime} to ${endTime}.<br>
        `);
          }
        }
        if (jsonRules.conditional) {
          const condJson = JSON.parse(conditions);
          rulesRequired = rulesRequired.concat(`
            ${condJson.no_camera ? "No camera allowed. " : ""}
            ${condJson.power_tethered ? "Tethering required. " : ""}
            ${
              condJson.noise_threshold
                ? `Noise below ${condJson.noise_threshold} db. `
                : ""
            }
            ${condJson.others ? `${condJson.others}. ` : ""}
            `);
        }
        if (!jsonRules.information_only) {
          constraintsDescription = constraintsDescription.concat(`
            <tr class="const"><td>Rules: ${rules}</td></tr>
            <tr class="const"><td>${rulesRequired}</td></tr>
            `);
        }
        constraintsDescription = constraintsDescription.concat(`</table>`);

        if (mapRef?.current) {
          popup
            .setHTML(constraintsDescription)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      const selectedOpsVolume = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["operation"],
        }
      );
      if (selectedOpsVolume && selectedOpsVolume.length) {
        const {
          intent,
          operationType,
          timeStart,
          timeEnd,
          base,
          height,
          request,
          altitudeHigher,
          altitudeLower,
        } = selectedOpsVolume[0].properties as {
          intent: string;
          operationType: string;
          timeStart: string;
          timeEnd: string;
          base: number;
          height: number;
          request: string;
          altitudeHigher: number;
          altitudeLower: number;
        };
        const requestParse = JSON.parse(request);
        const { tracker_uuid, pilot_uuid, platform_uuid } = requestParse;
        const dataTrackers: any = [];
        const dataPlatforms: any = [];
        const dataPilots: any = [];
        const emerLandingPoint = requestParse.contingency_plans.landing_point;
        // add logic to check tracker and platform data here
        const getDataTracker = async () =>
          Promise.all(
            tracker_uuid.map(async (uuid: string) => {
              if (!uuid) return undefined;
              if (trackerList.current === null) return undefined;
              const filteredData = trackerList.current.filter(
                (item) => item.tracker_uuid === uuid
              );
              // const response = await api.getTrackerScan(uuid);
              // return response;
              return { data: filteredData[0] };
            })
          );
        getDataTracker().then(function (result) {
          dataTrackers.push(result);
        });
        // const getDataPilot = async () =>
        //   Promise.all(
        //     tracker_uuid.map(async (uuid, index) => {
        //       if (!uuid) return undefined;
        //       const response = await api.getPilot(uuid);
        //       return response;
        //     })
        //   );
        // getDataPilot().then(function (result) {
        //   dataPilots.push(result);
        // });
        // const getDataPlatform = async () =>
        //   Promise.all(
        //     tracker_uuid.map(async (uuid, index) => {
        //       if (!uuid) return undefined;
        //       const response = await api.getPlatform(uuid);
        //       return response;
        //     })
        //   );
        // getDataPlatform().then(function (result) {
        //   dataPlatforms.push(result);
        // });
        // tracker_uuid.map(async (uuid, index) => {
        //   if (!uuid) return undefined;
        //   const response = await api.getTrackerScan(uuid);
        //   dataTrackers.push(response.data);
        //   return response;
        // });
        pilot_uuid.map(async (uuid: string) => {
          if (!uuid) return undefined;
          // const response = await api.getPilot(uuid);
          // Filtered data array
          if (pilotList.current === null) return undefined;
          const filteredData = pilotList.current.filter(
            (item) => item.pilot_uuid === uuid
          );

          function isDuplicate(pilotUuid: string) {
            return dataPilots.some(
              (item: Pilot) => item.pilot_uuid === pilotUuid
            );
          }
          // Push filtered data if it's not a duplicate
          if (
            filteredData.length > 0 &&
            !isDuplicate(filteredData[0].pilot_uuid)
          ) {
            dataPilots.push(filteredData[0]);
          }
          return { data: filteredData[0] };
        });
        platform_uuid.map(async (uuid: string) => {
          if (!uuid) return undefined;
          // const response = await api.getPlatform(uuid);
          // dataPlatforms.push(response.data);
          // return response;
          if (platformList.current === null) return undefined;
          const filteredData = platformList.current.filter(
            (item) => item.platform_uuid === uuid
          );
          dataPlatforms.push(filteredData[0]);
          return { data: filteredData[0] };
        });
        let opsVolumeDescription = "";
        const timeDiff = function (startDate: string, endDate: string) {
          const startTime = new Date(startDate);
          const endTime = new Date(endDate);
          const diff = endTime.getTime() - startTime.getTime();
          const hrDiff = diff / 3600 / 1000; // 1.555555
          const totalHours = String(parseFloat(hrDiff.toFixed(2))); // 1.5
          const splitHourseMinute = totalHours.split(".");
          return `${splitHourseMinute[0]} H ${splitHourseMinute[1]} M`;
        };
        setTimeout(function () {
          const tR = dataTrackers[0]
            .map((x: Tracker) => x?.tracker_name)
            .join(", ");
          const pL = dataPilots.map((x: Pilot) => x?.pilot_username).join(", ");
          const pM = dataPlatforms
            .map((x: Platform) => x?.platform_callsign)
            .join(", ");
          const period = timeDiff(timeStart, timeEnd);
          opsVolumeDescription = `
              <table>
                <style>
                tr.ops:nth-of-type(even) {
                    background-color: #d3d3d3;
                  }
                  tr.ops p {
                    text-indent: 10px;
                    text-align: left;
                    margin: 2px;
                  }
                </style>
                <tr><td><b>OPERATION: ${intent}</b></td></tr>
                <tr class="ops"><td>TYPE: ${operationType.toUpperCase()}</td></tr>
                <tr class="ops"><td>ALT: ${altitudeLower} to ${altitudeHigher}M</td></tr>
                <tr class="ops"><td>START: ${timeStart}</td></tr>
                <tr class="ops"><td>END: ${timeEnd}</td></tr>
                <tr class="ops"><td>FLIGHT PERIOD: ${period}</td></tr>
                <tr class="ops"><td>EMERGENCY LANDING: <p> Latitude: ${
                  emerLandingPoint[0][0]
                }</p> <p> Longtitude: ${emerLandingPoint[0][1]}</p> </td></tr>
                <tr class="ops"><td>PILOT: ${pL}</td></tr>
                <tr class="ops"><td>TRACKER: ${tR}</td></tr>
                <tr class="ops"><td>PLATFORM: ${pM}</td></tr>
              </table>
              `;
          if (mapRef?.current) {
            popup
              .setHTML(opsVolumeDescription)
              .setLngLat(e.lngLat)
              .addTo(mapRef?.current);
          }
        }, 600); // delay 500 ms to assign array = []
      }
      // const selectedAirMapSpace = mapRef?.current.queryRenderedFeatures(bbox, {
      //   layers: ["airmap-space-fill"],
      // });
      const selectedAirMapSpace = mapRef?.current?.queryRenderedFeatures(
        [point1, point2],
        {
          layers: ["airmap-space-fill"],
        }
      );

      if (selectedAirMapSpace && selectedAirMapSpace.length) {
        const { types, name, state, country, color } = selectedAirMapSpace[0]
          .properties as {
          types: string;
          name: string;
          state: string;
          country: string;
          color: string;
        };
        const staticselectedAirMapSpace = `
          <table>
            <style>
              tr.airmap:nth-of-type(even) {
                background-color: ${color};
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="airmap"><td>${types}</td></tr>
            <tr class="airmap"><td>${state}</td></tr>
            <tr class="airmap"><td>${country}</td></tr>
          </table>
          `;

        if (mapRef?.current) {
          popup
            .setHTML(staticselectedAirMapSpace)
            .setLngLat(e.lngLat)
            .addTo(mapRef.current);
        }
      }

      // Change the cursor to a pointer when
      // the mouse is over the no fly zones or plane or ship.
      mapRef?.current?.on("mousemove", async (e) => {
        if (draw.getMode() !== "simple_select" || ruler.isMeasuring) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "crosshair";
          }
          return;
        }
        const ADSBLayer = mapRef?.current?.getLayer("adsb-layer") as any;
        if (!ADSBLayer) return;
        const ADSBDetails = ADSBLayer.implementation.getClickedObjectDetails(
          e.point
        );
        if (ADSBDetails) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }

        const AISLayer = mapRef?.current?.getLayer("ais-layer") as any;
        if (!AISLayer) return;
        const AISDetails = AISLayer.implementation.getClickedObjectDetails(
          e.point
        );
        if (AISDetails) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }

        const trackerLayer = mapRef?.current?.getLayer("tracker-layer") as any;
        if (!trackerLayer) return;
        const trackerDetails =
          trackerLayer.implementation.getClickedObjectDetails(e.point);
        if (trackerDetails) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }
        const point1: PointLike = new mapboxgl.Point(
          e.point.x - 5,
          e.point.y - 5
        );
        const point2: PointLike = new mapboxgl.Point(
          e.point.x + 5,
          e.point.y + 5
        );

        // Find features intersecting the bounding box.
        const selectedStaticConstraints =
          mapRef?.current?.queryRenderedFeatures([point1, point2], {
            layers: ["no-fly"],
          });

        if (selectedStaticConstraints && selectedStaticConstraints.length) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }
        const selectedConstraints = mapRef?.current?.queryRenderedFeatures(
          [point1, point2],
          {
            layers: ["constraints"],
          }
        );
        if (selectedConstraints && selectedConstraints.length) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }
        const selectedOpsVolumes = mapRef?.current?.queryRenderedFeatures(
          [point1, point2],
          {
            layers: ["operation"],
          }
        );

        if (selectedOpsVolumes && selectedOpsVolumes.length) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }
          return;
        }
        const selectedPopulationDensity =
          mapRef?.current?.queryRenderedFeatures([point1, point2], {
            layers: ["sg-population-density-layer"],
          });
        if (selectedPopulationDensity && selectedPopulationDensity.length) {
          if (mapRef?.current) {
            mapRef.current.getCanvas().style.cursor = "pointer";
          }

          return;
        }
        // const selectedAirMapSpaces = mapRef?.current.queryRenderedFeatures(bbox, {
        //   layers: ["airmap-space-fill"],
        // });
        // if (selectedAirMapSpaces.length) {
        //   mapRef?.current.getCanvas().style.cursor = "pointer";
        //   return;
        // }
        if (mapRef?.current) {
          mapRef.current.getCanvas().style.cursor = "";
        }
      });

      mapRef?.current?.on("styledataloading", () => {
        mapRef?.current?.once("styledata", (e) => {
          setStyle(e.style);
        });
      });
      /**
       * Handles logic for zoomend
       * @param {any} latlngData get coordinate
       */
      mapRef?.current?.on("zoomend", (e: any) => {
        const latlngData = [
          e.target.transform._center.lng,
          e.target.transform._center.lat,
        ];
        dispatch(getairMapSpaceData(latlngData));
        const adsbLayer = mapRef?.current?.getLayer("adsb-layer") as any;
        const trackerLayer = mapRef?.current?.getLayer("tracker-layer") as any;
        const airspaceLayer = mapRef?.current?.getLayer(
          "airmap-space-fill"
        ) as any;
        if (!adsbLayer || !trackerLayer || airspaceLayer) return;
        adsbLayer.implementation.repaint();
        trackerLayer.implementation.repaint();
        airspaceLayer.implementation.repaint();
      });
    });
  };

  /*
   * Initialize Mapbox
   */
  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPB_TOKEN || "";

    // console.log("mapTheme", mapTheme);

    if (mapContainer.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          mapTheme === "dark"
            ? "mapbox://styles/mapbox/dark-v11"
            : "mapbox://styles/mapbox/streets-v12",
        center: [coordinate.lng, coordinate.lat],
        zoom,
      });

      /**
       * Add controls onto the current map on loading of site
       */
      mapRef.current.on("load", () => {
        addMapControllers();
      });

      // Clean up on unmount
      return () => mapRef?.current?.remove();
    }
  }, [draw, mapTheme]);

  /**
   * Adds the different map layers, listening for changes to the layers and updating the layers accordingly
   */
  useEffect(() => {
    if (!mapRef.current || !mapRef.current.loaded) return;
    addMapLayers(
      mapRef.current,
      modelMap,
      showStaticConstraintsLayer,
      constraints,
      showConstraintsLayer,
      showShipLayer,
      shipData,
      showAdsbLayer,
      ADSBData,
      IS_ADSB_PAID,
      showRainMapLayer,
      airMapSpaceData,
      showAirMapSpace,
      conformanceData,
      showConformanceLayer,
      showGnssLayer,
      showAnchorageLayer,
      showDronePortLayer,
      showSgBoundaryLayer,
      showSgPopulationDensity,
      showMapElevation,
      showCollisionLayer
    );
  }, [
    style,
    modelMap,
    showStaticConstraintsLayer,
    constraints,
    showConstraintsLayer,
    showShipLayer,
    showAdsbLayer,
    shipData,
    ADSBData,
    airMapSpaceData,
    showAirMapSpace,
    conformanceData,
    showConformanceLayer,
    showRainMapLayer,
    showGnssLayer,
    showAnchorageLayer,
    showDronePortLayer,
    showSgBoundaryLayer,
    showSgPopulationDensity,
    showMapElevation,
    showCollisionLayer,
    initializeMapB,
  ]);

  /**
   * Sets layer visibilities
   */
  useEffect(() => {
    if (!mapRef?.current) return;
    // turn off local static constraint
    const noFlyLayer = mapRef?.current.getLayer("no-fly");
    if (!noFlyLayer) return;
    setLayerVisibility(mapRef?.current, "no-fly", showStaticConstraintsLayer);
    const noFlyOutlineLayer = mapRef?.current.getLayer("no-fly-outline");
    if (!noFlyOutlineLayer) return;
    setLayerVisibility(
      mapRef?.current,
      "no-fly-outline",
      showStaticConstraintsLayer
    );

    const anchorageLayer = mapRef?.current.getLayer("sg-anchor");
    if (!anchorageLayer) return;
    setLayerVisibility(mapRef?.current, "sg-anchor", showAnchorageLayer);
    const anchorageOutlineLayer = mapRef?.current.getLayer("sg-anchor-outline");
    if (!anchorageOutlineLayer) return;
    setLayerVisibility(
      mapRef?.current,
      "sg-anchor-outline",
      showAnchorageLayer
    );
    const aisLayer = mapRef?.current.getLayer("ais-layer");
    if (!aisLayer) return;
    setLayerVisibility(mapRef?.current, "ais-layer", showShipLayer);
    const adsbLayer = mapRef?.current.getLayer("adsb-layer");
    if (!adsbLayer) return;
    setLayerVisibility(mapRef?.current, "adsb-layer", showAdsbLayer);
    const constraintsLayer = mapRef?.current.getLayer("constraints");
    if (!constraintsLayer) return;
    setLayerVisibility(mapRef?.current, "constraints", showConstraintsLayer);
    const rainMapLayer = mapRef?.current.getLayer("rain-map-layer");
    if (!rainMapLayer) return;
    const airmapLayer = mapRef?.current.getLayer("airmap-space-fill");
    if (!airmapLayer) return;
    const operationLayer = mapRef?.current.getLayer("operation");
    if (!operationLayer) return;
    setLayerVisibility(mapRef?.current, "operation", showOperationVolumeLayer);
    setLayerVisibility(
      mapRef?.current,
      "operationbase",
      showOperationVolumeLayer
    );
    const trackerLayer = mapRef?.current.getLayer("tracker-layer");
    if (!trackerLayer) return;
    setLayerVisibility(mapRef?.current, "tracker-layer", showDroneIconLayer);
    const collisionLayer = mapRef?.current.getLayer("collision-layer");
    if (!collisionLayer) return;
    setLayerVisibility(mapRef?.current, "collision-layer", showCollisionLayer);
    const elevationLayer = mapRef?.current.getLayer("terrain-layer");
    if (!elevationLayer) return;
    setLayerVisibility(mapRef?.current, "terrain-layer", showMapElevation);
    if (showMapElevation) {
      if (mapRef?.current.getTerrain()?.source !== "terrain-elevation")
        mapRef?.current.setTerrain({ source: "terrain-elevation" });
    }
    if (!showMapElevation) {
      mapRef?.current.setTerrain();
    }
    const sgPopulationLayer = mapRef?.current.getLayer(
      "sg-population-density-layer"
    );
    if (!sgPopulationLayer) return;
    setLayerVisibility(
      mapRef?.current,
      "sg-population-density-layer",
      showSgPopulationDensity
    );
    const gnssLayer = mapRef?.current.getLayer("gnss-layer");
    if (!gnssLayer) return;
    setLayerVisibility(mapRef?.current, "gnss-layer", showGnssLayer);
    if (envVar.use_simulated_weather.Value === "true") {
      setLayerVisibility(mapRef?.current, "rain-map-layer", showRainMapLayer);
    } else {
      setLayerVisibility(mapRef?.current, "precipitation", showRainMapLayer);
    }

    // // previous method of switching weather layer based on location

    // if (process.env?.REACT_APP_USE_OPENWEATHER_API?.toUpperCase() === "TRUE") {
    //   const currentLocation = mapRef?.current.getCenter();
    //   const zoomLevel = mapRef?.current.getZoom();
    //   // If within SG disable Open Weather and Open AIP layer
    //   if (
    //     currentLocation.lng < 104.09485833182771 &&
    //     currentLocation.lng > 103.48788722370932 &&
    //     currentLocation.lat < 1.5017122189986765 &&
    //     currentLocation.lat > 1.1997149064574018 &&
    //     zoomLevel > 9
    //   ) {
    //     setLayerVisibility(mapRef?.current, "airmap-space-fill", false);
    //     setLayerVisibility(mapRef?.current, "precipitation", false);
    //     setLayerVisibility(mapRef?.current, "rain-map-layer", showRainMapLayer);
    //   } else {
    //     setLayerVisibility(
    //       mapRef?.current,
    //       "airmap-space-fill",
    //       showConstraintsLayer
    //     );
    //     setLayerVisibility(mapRef?.current, "precipitation", showRainMapLayer);
    //     setLayerVisibility(mapRef?.current, "rain-map-layer", false);
    //   }
    // } else {
    //   setLayerVisibility(mapRef?.current, "precipitation", showRainMapLayer);
    //   setLayerVisibility(
    //     mapRef?.current,
    //     "airmap-space-fill",
    //     showConstraintsLayer
    //   );
    // }
  }, [
    showStaticConstraintsLayer,
    showShipLayer,
    showAdsbLayer,
    showConstraintsLayer,
    showRainMapLayer,
    showAirMapSpace,
    showConformanceLayer,
    showOperationVolumeLayer,
    showDroneIconLayer,
    showCollisionLayer,
    showGnssLayer,
    showAnchorageLayer,
    showDronePortLayer,
    showSgBoundaryLayer,
    showSgPopulationDensity,
    showMapElevation,
  ]);

  /**
   * Adds the emergency landing point as a point on Mapbox, updating when the landing point is changed by user
   */
  useEffect(() => {
    if (!mapRef?.current?.loaded || emergencyLanding?.length === 0) return;
    const updateEmergency = async () => {
      try {
        const data = draw.getAll();
        const points = data.features.filter((f) => f.geometry.type === "Point");
        if (points.length === 0) {
          draw.add({
            type: "Point",
            coordinates: emergencyLanding,
          });
          return;
        }
        const point = points[0] as any;
        point.geometry.coordinates = emergencyLanding;
        draw.add(point);
      } catch (e) {
        // console.log("Error : ", e);
      }
    };
    updateEmergency();
  }, [draw, emergencyLanding]);

  /**
   * Adds a route onto Mapbox as a union of line segments, updating when the route is changed by user
   */
  useEffect(() => {
    if (!mapRef?.current?.loaded || selectedWaypoints?.length === 0) return;
    const updateWaypoints = async () => {
      try {
        const data = draw.getAll();
        const lines = data.features.filter(
          (f) => f.geometry.type === "LineString"
        );
        if (lines.length === 0) {
          draw.add({
            type: "LineString",
            coordinates: selectedWaypoints,
          });
          return;
        }
        const line = lines[0] as any;
        line.geometry.coordinates = selectedWaypoints;
        draw.add(line);
      } catch (e) {
        // console.log("Error : ", e);
      }
    };
    updateWaypoints();
  }, [draw, selectedWaypoints]);

  /**
   * Adds an area onto Mapbox as a set of vertices, updating when the area is changed by user
   */
  useEffect(() => {
    if (!mapRef?.current?.loaded || selectedAreaWaypoints?.length === 0) return;
    // if (typeof draw.getAll !== "function") return;
    const updateAreaWaypoints = async () => {
      try {
        const data = draw.getAll();
        const polygons = data?.features?.filter(
          (f) => f.geometry.type === "Polygon"
        );
        if (polygons.length === 0) {
          draw.add({
            type: "Polygon",
            coordinates: [selectedAreaWaypoints],
          });
          return;
        }
        const polygon = polygons[0] as any;
        polygon.geometry.coordinates = [selectedAreaWaypoints];
        draw.add(polygon);
      } catch (e) {
        // console.log("Error : ", e);
      }
    };
    updateAreaWaypoints();
  }, [draw, selectedAreaWaypoints]);

  /**
   * Adds a circle onto Mapbox as a set of vertices, updating when the circle is changed by user
   */
  // useEffect(() => {
  //   if (!mapRef?.current || updatedSelectedCircleWaypoints?.length === 0) return;
  //   const updateCircleWaypoints = async () => {
  //     console.log("update Circle Waypoints in MapB");
  //     const data = draw.getall();
  //     const polygons = data.features.filter(
  //       (f) => f.geometry.type === "Polygon"
  //     );
  //     if (polygons.length === 0) {
  //       draw.add({
  //         type: "Feature",
  //         properties: updatedSelectedCircleProperties,
  //         geometry: {
  //           type: "Polygon",
  //           coordinates: [updatedSelectedCircleWaypoints],
  //         },
  //       });
  //       return;
  //     }
  //     const polygon = polygons[0];
  //     polygon.geometry.coordinates = [updatedSelectedCircleWaypoints];
  //     polygon.properties = updatedSelectedCircleProperties;
  //     draw.add(polygon);
  //   };
  //   updateCircleWaypoints();
  // }, [updatedSelectedCircleWaypoints, updatedSelectedCircleProperties, draw]);

  useEffect(() => {
    if (
      !mapRef?.current?.loaded ||
      selectedCircleWaypoints?.length === 0 ||
      !selectedCircleProperties?.isCircle
    ) {
      return;
    }

    const updateCircleWaypoints = async () => {
      try {
        const data = draw.getAll();
        const polygons = data.features.filter(
          (f) => f.geometry.type === "Polygon"
        );

        if (polygons.length === 0) {
          draw.add({
            type: "Feature",
            properties: selectedCircleProperties,
            geometry: {
              type: "Polygon",
              coordinates: [selectedCircleWaypoints],
            },
          });
          return;
        }

        // console.log(selectedCircleProperties);

        const polygon = polygons[0] as any;
        polygon.geometry.coordinates = [selectedCircleWaypoints];
        polygon.properties = selectedCircleProperties;
        draw.add(polygon);
      } catch (e) {
        console.log("Error", e);
      }
    };
    updateCircleWaypoints();
  }, [selectedCircleWaypoints, selectedCircleProperties, draw]);

  // /**
  //  * Adds AIS data onto Mapbox, updating when AIS data changes
  //  */
  useEffect(() => {
    if (!mapRef?.current) return;
    const updateShipLayer = async () => {
      const layer = mapRef?.current?.getLayer("ais-layer") as any;
      if (!layer) return;
      await layer.implementation.setData(shipData, modelMap);
    };
    updateShipLayer();
  }, [modelMap, shipData]);

  /**
   * Adds ADSB data onto Mapbox, updating when ADSB data changes
   */
  useEffect(() => {
    if (!mapRef?.current) return;
    const updateADSBLayer = async () => {
      const layer = mapRef?.current?.getLayer("adsb-layer") as any;
      if (!layer) return;
      await layer.implementation.setData(ADSBData, modelMap);
    };
    updateADSBLayer();
  }, [ADSBData, modelMap]);

  /**
   * Adds telemetry and flight data onto Mapbox, updating when either data changes
   */
  useEffect(() => {
    if (!mapRef?.current) return;

    const updateOperationLayer = async () => {
      const operationSource = mapRef?.current?.getSource(
        "operation-coords"
      ) as any;
      const trackerLayer = mapRef?.current?.getLayer("tracker-layer") as any;
      if (!operationSource || !trackerLayer) return;
      operationSource.setData({
        type: "FeatureCollection",
        features: getOperationFeatures(
          selectedFlightData,
          trackerStatusMap,
          trackerOpsMap,
          focussedOperation,
          isRemoteId,
          mapRef?.current
        ),
      });
      await trackerLayer.implementation.setData(
        selectedFlightData,
        telemetryData.filter((item) => Object.keys(item).length > 0),
        modelMap,
        trackerStatusMap,
        trackerOpsMap,
        api,
        isRemoteId,
        mapRef?.current
      );
    };
    updateOperationLayer();
  }, [
    selectedFlightData,
    telemetryData,
    focussedOperation,
    trackerStatusMap,
    trackerOpsMap,
    modelMap,
    api,
    isRemoteId,
  ]);

  /* collision listen and handle */
  useEffect(() => {
    const handleCollision = (data: any) => {
      if (!data) return;
      const collisionLayer = mapRef?.current?.getLayer(`collision-layer`);
      if (!collisionLayer) return;
      const cells = Object.keys(data);
      for (const cell of cells) {
        const details = data[cell];
        collisionDatasRef.current.set(cell, details);
      }
    };
    const collisionListener = channel?.addMessageListener(
      messageTypes.COLLISION,
      handleCollision
    );
    return () => {
      collisionListener?.();
    };
  }, [channel, userAccess]);

  /* collision update and clear */
  useEffect(() => {
    const update = setInterval(() => {
      const collisionLayer = mapRef?.current?.getLayer(
        `collision-layer`
      ) as any;
      if (!collisionLayer) return;
      const isTerrainEnabled = mapRef?.current?.getTerrain() !== null;
      const currentTime = Date.now();
      collisionDatasRef.current = new Map(
        [...collisionDatasRef.current].filter(
          // intermittent ws message recieved after slight delay
          // this can cause collision cell to dissappear as currenttime - messagetime > 3000
          // set to 5 seconds
          ([cell, details]) => currentTime - details.time <= 5000
        )
      );
      collisionLayer.implementation.setData(
        collisionDatasRef.current,
        modelMap,
        isTerrainEnabled
      );
    }, 1000);
    return () => {
      clearInterval(update);
    };
  }, []);

  /**
   * Adds airmap space  data onto Mapbox, updating when either data changes
   */
  useEffect(() => {
    if (!mapRef?.current) return;
    const updateAirmapSpaceLayer = async () => {
      const airmapSpaceSource = mapRef?.current?.getSource(
        "airmap-space-coords"
      ) as any;
      const trackerLayer = mapRef?.current?.getLayer("airmap-space-fill");
      if (!airmapSpaceSource || !trackerLayer) return;
      airmapSpaceSource.setData({
        type: "FeatureCollection",
        features: getAirSpaceFetaures(airMapSpaceData),
      });
    };
    updateAirmapSpaceLayer();
  }, [airMapSpaceData]);

  /**
   * Adds dynamic constraints onto Mapbox, updating when dynamic constraints change
   */
  useEffect(() => {
    if (!mapRef?.current) return;
    const updateConstraintsLayer = async () => {
      const constraintSource = mapRef?.current?.getSource(
        "constraints-coords"
      ) as any;
      if (!constraintSource) return;
      constraintSource.setData({
        type: "FeatureCollection",
        features: getConstraintsFeatures(constraints, focussedOperation),
      });
    };
    updateConstraintsLayer();
  }, [constraints, focussedOperation]);
  /**
   * Adds dynamic conformance onto Mapbox, updating when dynamic conformance change
   */
  useEffect(() => {
    const updateConformanceLayer = async () => {
      if (!mapRef?.current) return;
      const conformanceSource = mapRef?.current.getSource(
        "conformances-coords"
      ) as any;
      if (!conformanceSource) return;
      const dataConformance = conformanceData;
      // console.log("check conformance data in map", dataConformance);
      if (dataConformanceGL === null) return;
      const dataConformanceGLs = JSON.parse(dataConformanceGL) as any;
      conformanceSource.setData({
        type: "FeatureCollection",
        features: getConformanceFeatures(dataConformanceGLs),
      });
    };
    const retriveConformanceInterval = setInterval(() => {
      updateConformanceLayer();
    }, 1000 * 1); // Updates every 1 sec call local storage
    return () => {
      clearInterval(retriveConformanceInterval);
    };
  }, [conformanceData, dataConformanceGL, focussedOperation]);

  /**
   * Handle Telemetry data here for better performance
   */
  useEffect(() => {
    const checkTrackerSerialNumber = (trackerSn: string) => {
      const dataTracker: Tracker[] = [];
      selectedTrackerDataRef.current.map((data: any) => {
        return data.map((datas: any) => {
          return dataTracker.push(datas);
        });
      });
      const filterDataTracker = dataTracker.filter((tracker: any) => {
        return tracker?.data?.tracker_sn === trackerSn;
      });
      return filterDataTracker;
    };
    const handleMessage = (data: any) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid: string) => {
          const dataPlatform: any = [];
          selectedPlatformDataRef.current.map((p: any) => {
            return p.map((datas: any) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (
            platform: any
          ) {
            return platform?.data?.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        const key = [data.extras.tracker_sn, data.operation_id].join("/");

        if (!keyRef.current) {
          keyRef.current = {};
        }
        keyRef.current[key] = true;
        // if (!keyRef.current) {
        // making sure no duplicate keys in keyRef
        // keyRef.current = {
        //   ...keyRef.current,
        //   [key]: true,
        // };
        // }
        // setTeleObj({
        //   ...teleObj,
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerSn: data.extras?.tracker_sn,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.tracker_sn),
                checkTrackerSerialNumber(data.extras.tracker_sn)
                  ? checkTrackerSerialNumber(data.extras.tracker_sn)
                  : [],
              ],
            ],
          },
        };

        // const ttt = Object.keys(teleObj).map((k) => teleObj[k]);
        // setTelemetryData(ttt);
        // console.log("Ttt", ttt);
        // console.log("teleobj", teleObj);
      }
      //   else {
      //  setTelemetryData([]);
      //   }
    };

    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      handleMessage
    );

    return () => {
      // clearInterval(interval);
      removeListener?.();
    };
  }, [teleObj, channel, userAccess]);

  useEffect(() => {
    // This stops administrator from displaying telemetry
    if (userAccess.role === "administrator") {
      setTelemetryData([]);
      return;
    }
    // This effect runs every 1 second, batches telemetryData for performance
    const intervalId = setInterval(() => {
      try {
        // Sort teleObj by timestamp in descending..order simply to remove DUPLICATE KEY DATAS
        const sortedData = Object.keys(teleObjRef.current)
          .map((key) => teleObjRef.current[key])
          .sort((a, b) => b.timestamp - a.timestamp);
        // run through, clear duplicate keys
        const processedData = [];
        const seenKeys = new Set(); // just to get rid of duplicate keys in sortedData purposes

        sortedData.forEach((entry) => {
          const key = [entry.trackerSn, entry.gufi].join("/");
          if (!seenKeys.has(key)) {
            processedData.push(entry);
            seenKeys.add(key);
          }
        });
        // at this point, processedData is the latest Data of SAME and NEW keys
        // we also have keyRef with SAME and NEW keys
        // how to consider trackers that dont send data anymore ie OLD keys?

        // ANS: compare both key objects. 3 kinds of keys, SAME, OLD, NEW. create Temp holders for them?
        // if SAME, replace that in actual teleobj
        // if NEW,  create new entry in ActualKey and ActualTeleObj .
        // if OLD, check timestamp, if older than 5 secs, OLD OLD: delete from actualTeleObj & actualKey
        // if less than 5 secs, do nothing: keep it in actualTeleObjRef and actualKeyRef

        const commonKeys = new Set();
        const newKeysInKeyRef = new Set(Object.keys(keyRef.current));
        const oldKeysInActualKeyRef = new Set(
          Object.keys(actualKeyRef.current)
        );
        for (const key of newKeysInKeyRef) {
          if (oldKeysInActualKeyRef.has(key)) {
            commonKeys.add(key);
            newKeysInKeyRef.delete(key);
            oldKeysInActualKeyRef.delete(key);
          }
        }
        // 3 separate loops to actually set actualTeleObj for telemetryData
        // for key in SAME keys, replace actualTeleobj with teleObj. check timestamp too.
        // for key in NEW keys, create new entry in actual teleobj and actualKey
        // for key in OLD keys, do nothing. actually no loop here required.
        // for key in OLD OLD keys, delete from actualTeleObj and actualkey

        // this will also contain trackers that do not send anymore telemetry; their previous data (key) still exists in keyRef,
        // just that the previous data is outdated (old). thus timestamp check occurs here as well, to remove trackers that stopped
        const commonData = Array.from(commonKeys)
          .filter((key) => {
            // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef
            if (
              Date.now() -
                (actualTeleObjRef.current[key as any]?.timestamp ||
                  Date.now()) >
              20000
            ) {
              delete actualKeyRef.current[key as any];
              delete actualTeleObjRef.current[key as any];
              delete keyRef.current[key as any];
              delete teleObjRef.current[key as any];
              return false;
            }
            return true;
          })
          .map((key) => actualTeleObjRef.current[key as any]);

        const newDataInKeyRef = Array.from(newKeysInKeyRef).map(
          (key) => teleObjRef.current[key]
        );

        // this loop does not seem to trigger
        // reason: a key will never be present in oldKeysInActualKeyRef but be present in keyRef;
        // no function exists to delete key from oldKeysInActualKeyRef but not keyRef
        // the idea for this loop is to check for tracker that no longer sends data, but instead it is in commonKeys instead
        // const oldDataInActualKeyRef = Array.from(oldKeysInActualKeyRef)
        //   .filter((key) => {
        //     // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef
        //     if (
        //       Date.now() -
        //         (actualTeleObjRef.current[key]?.timestamp || Date.now()) >
        //       20000
        //     ) {
        //       delete actualKeyRef.current[key];
        //       delete actualTeleObjRef.current[key];
        //       delete keyRef.current[key];
        //       delete teleObjRef.current[key];
        //       return false;
        //     }
        //     return true; // Include in oldDataInActualKeyRef
        //   })
        //   .map((key) => actualTeleObjRef.current[key]);

        // Update actualTeleObjRef with commonData
        commonData.forEach((data) => {
          const key = [data.trackerSn, data.gufi].join("/");
          actualTeleObjRef.current[key] = teleObjRef.current[key];
        });

        // Update actualKeyRef and actualTeleObjRef with newDataInKeyRef
        newDataInKeyRef.forEach((data) => {
          const key = [data.trackerSn, data.gufi].join("/");
          actualKeyRef.current[key] = true;
          actualTeleObjRef.current[key] = teleObjRef.current[key];
        });

        const ttt = Object.keys(actualTeleObjRef.current).map(
          (k) => actualTeleObjRef.current[k]
        ) as any;

        // finally Set telemetryData with  actualTeleObj
        // console.log("ttt:", ttt);
        setTelemetryData(ttt);
      } catch (e) {
        // console.log("Error : ", e);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [userAccess]);

  // useEffect(() => {
  //   setTrackerStatusMap();
  // });

  const getPilotPlatformTrackerList = async () => {
    if (userAccess.privileges.includes("assets.platform.read")) {
      const responsePlatforms = await api.getPlatforms();
      platformList.current = responsePlatforms.data;
    }
    if (userAccess.privileges.includes("assets.tracker.read")) {
      const responseTrackers = await api.getTracker();
      trackerList.current = responseTrackers.data;
    }
    if (userAccess.privileges.includes("assets.pilot.read")) {
      const responsePilots = await api.getPilots();
      pilotList.current = responsePilots.data;
    }
  };

  useEffect(() => {
    if (!api) return;
    getPilotPlatformTrackerList();
  }, [api]);

  useEffect(() => {
    if (selectedTrackerData)
      selectedTrackerDataRef.current = selectedTrackerData;
    if (selectedPlatformData)
      selectedPlatformDataRef.current = selectedPlatformData;
  }, [selectedPlatformData, selectedTrackerData]);

  return <div ref={mapContainer} className="w-full h-full" />;
}
