import axios from "axios";

export default async function forecastGnss(response) {
  try {
    // const response = await axios.get(
    //   `https://4dhzy30gbe.execute-api.ap-southeast-1.amazonaws.com/dev/getforcast?nwData=${JSON.stringify(
    //     props.nwData
    //   )}&neData=${JSON.stringify(props.neData)}&swData=${JSON.stringify(
    //     props.swData
    //   )}&seData=${JSON.stringify(props.seData)}`
    // );

    if (response.status === 200) {
      const geoJson = response.data.map((singleResponse) => {
        const coordinates = [singleResponse.lat.S, singleResponse.long.N];
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(coordinates[1]),
              parseFloat(coordinates[0]),
            ],
          },
          properties: {
            weight: parseInt(singleResponse.band.S, 10),
          },
        };
      });
      return geoJson;
    }
  } catch (e) {
    return "Error";
  }
  return "Error";
}
