import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setOperationFormRequest } from "@/store/actions";
import FlightPlan from "@/model/FlightPlan";
import { OperationJson } from "@/model/api/Operation";
import moment from "moment";

import EmbedMapB from "../../../MapB/embedMapB";

interface RerouteRescheduleDialogProps {
  isRerouted: boolean;
  isRescheduled: boolean;
  compareData: OperationJson | null;
  flightPlan: FlightPlan | null;
  onAcceptChanges: (isTwoWayOperation: boolean) => void;
  onDiscardPlan: () => void;
  onResubmitPlan: () => void;
  onEditPlan: () => void;
}

export default function RerouteRescheduleDialog({
  isRerouted,
  isRescheduled,
  compareData,
  flightPlan,
  onAcceptChanges,
  onDiscardPlan,
  onResubmitPlan,
  onEditPlan,
}: RerouteRescheduleDialogProps) {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isResubmit, setIsResubmit] = useState(false);

  const dispatch = useDispatch();

  const handleDiscardPlan = () => {
    setIsConfirmation(true);
    setIsDelete(true);
  };

  const handleResubmitPlan = () => {
    setIsConfirmation(true);
    setIsResubmit(true);
  };

  const handleOnCancel = () => {
    setIsConfirmation(false);
    setIsDelete(false);
    setIsResubmit(false);
  };

  const handleOnEdit = () => {
    if (!compareData) return;
    // handle two way return trip
    if (compareData?.request?.two_way) {
      const { request } = compareData;
      request.description = compareData?.reference?.description;
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request,
        })
      );
    } else {
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request: compareData.request,
        })
      );
    }
    onEditPlan();
  };

  const handleOnConfirm = () => {
    if (isDelete) {
      onDiscardPlan();
    } else if (isResubmit) {
      onResubmitPlan();
    }
  };

  return (
    <div className="h-[590px] w-[60vw]">
      {compareData && flightPlan && (
        <div className="w-full h-full bg-white rounded-lg text-black">
          <div className="flex-col mb-2 p-4">
            <h4>
              Operation Was
              {isRerouted && "rerouted"} {isRescheduled && "rescheduled"}
            </h4>
            <h5>
              The Flight Plan you have submitted conflicts with existing
              operation/constraints. It has been automatically deconflicted for
              you.
            </h5>
          </div>
          <table className="table-auto w-full">
            <tbody>
              <tr className="border rounded-t-md text-black">
                <td className="px-4 py-2">Original Start Time</td>
                <th>:</th>
                <td className="px-4 py-2">
                  {moment(flightPlan?.time_start).format("DD/MM/YYYY HH:mm A")}
                </td>
              </tr>
              <tr className={`border ${isRescheduled && "text-red-500"}`}>
                <td className="px-4 py-2">Rescheduled Start Time</td>
                <th>:</th>
                <td className="px-4 py-2">
                  {isRescheduled ? (
                    <p>
                      {moment(
                        compareData?.interuss?.operational_intent_reference
                          ?.time_start.value
                      ).format("DD/MM/YYYY HH:mm A")}
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <EmbedMapB
            mapHeight="350px"
            compareCurrentData={flightPlan}
            compareData={compareData}
          />
          {isConfirmation ? (
            <div className="flex-col mt-2">
              {isDelete ? (
                <h5 className="font-medium text-center">
                  Are you sure you want to discard the original operation?
                </h5>
              ) : (
                <h5 className="font-medium text-center">
                  Are you sure you want to resubmit the original operation for
                  manual approval?
                </h5>
              )}
              <div className="flex p-4 justify-center w-full">
                <button
                  type="button"
                  className="text-xs btn-rounded-secondary"
                  onClick={handleOnCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="text-xs btn-rounded-primary ml-2"
                  onClick={handleOnConfirm}
                >
                  <p>Confirm</p>
                </button>
              </div>
            </div>
          ) : (
            <div className="flex mt-2 p-4 justify-center">
              <button
                type="button"
                className="text-xs btn-rounded-danger"
                onClick={handleDiscardPlan}
              >
                Discard Plan
              </button>
              <button
                type="button"
                className="text-xs btn-rounded-primary mr-2"
                onClick={handleOnEdit}
              >
                <p>Edit Plan</p>
              </button>
              <button
                type="button"
                className="text-xs btn-rounded-primary mr-2"
                onClick={handleResubmitPlan}
              >
                <p>Resubmit</p>
              </button>
              <button
                type="button"
                className="text-xs btn-rounded-primary"
                onClick={() => onAcceptChanges(false)}
              >
                <p>Accept Changes</p>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
