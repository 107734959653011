// Extend Number class to allow left padding, used for time conversion
// eslint-disable-next-line func-names, no-extend-native
Number.prototype.padLeft = function (base, chr) {
  const len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

/**
 * Converts Zulu time to local time
 * @param {String} time String representing Zulu time in ISO 8601-format
 * @returns Local time, formatted as YYYY-MM-DD HH:mm:ss
 */
export function convertZuluToLocalTime(time) {
  const localTime = new Date(time);
  const formattedTime = `${[
    localTime.getFullYear(),
    (localTime.getMonth() + 1).padLeft(),
    localTime.getDate().padLeft(),
  ].join("-")} ${[
    localTime.getHours(),
    localTime.getMinutes(),
    localTime.getSeconds(),
  ]
    .map((x) => x.padLeft())
    .join(":")}`;

  return formattedTime;
}
