import axios from "axios";
import { Auth } from "aws-amplify";
import { sharedEndpoints } from "./shared";
import { setAuth } from "../store/actions";
import { store } from "../store";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const createAuthorityRequestInstance = (jwtToken, refreshToken) => {
  const baseURL = `${store.getState().envVar["base_url-mars-authority"].Value}`;
  const instance = axios.create({
    baseURL,
  });
  /**
   * Mars Error Handling
   * Workflow
   * 1. Interceptor Request
   *  -if token expiring, refresh token before submit
   *  -if refresh token expired, attemptrefresh() will trigger logout
   *  -leave other error handling to response
   *
   * 2. Interceptor Response
   *  -handle response errors
   *  -check token expired in case expired right after request sent
   */

  const handleInterceptorResponseError = (error) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // decode accessToken
      const jwtPayload = JSON.parse(window.atob(accessToken.split(".")[1]));
      // refresh token when expiring in 30mins
      if (jwtPayload.exp <= Date.now() / 1000 + 3000) {
        return attemptRefresh()
          .then((token) => {
            const { config } = error;
            config.headers.Authorization = token;
            return axios.request(config);
          })
          .catch((err) => {
            return Promise.reject(error);
          });
      }
    }
    if (error?.toJSON?.().status === 401 && localStorage.getItem("idToken")) {
      // localStorage.clear();
      return attemptRefresh()
        .then((token) => {
          const { config } = error;
          config.headers.Authorization = token;
          return axios.request(config);
        })
        .catch(() => {
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  };

  const attemptRefresh = async () => {
    // const clientId = store.getState().envVar["app_client_id-authority"].Value;
    // const authCognitoDomain =
    //   store.getState().envVar["cognito_domain-authority"].Value;

    // const params = new URLSearchParams({
    //   grant_type: "refresh_token",
    //   client_id: clientId,
    //   refresh_token: refreshToken,
    // });
    try {
      // const { data } = await axios.post(
      //   `${authCognitoDomain}/oauth2/token`,
      //   params
      // );
      // const { id_token, access_token } = data;
      // handle refresh token here
      const user = await Auth.currentAuthenticatedUser();
      const access_token = user.signInUserSession.accessToken.jwtToken;
      const id_token = user.signInUserSession.idToken.jwtToken;
      refreshAuthInfo(id_token, access_token);
      return access_token;
    } catch (e) {
      handleSessionExpired(e);
    }
    return null;
  };

  const refreshAuthInfo = (idToken, accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", refreshToken);
    const userInfo = parseJwt(idToken);
    store.dispatch(
      setAuth({
        refreshToken,
        accessToken,
        userInfo,
        isPilot: false,
        isOperator: false,
        isAuthority: true,
        isRemoteId: false,
        isLoggedIn: !!accessToken,
      })
    );
  };

  let sessionExpiredTriggered = false;
  const handleSessionExpired = (e) => {
    if (!sessionExpiredTriggered) {
      localStorage.clear();
      console.log(e);
      window.location.href = "/login";
      alert("Your session has expired. Please login again.");
      sessionExpiredTriggered = true;
    }
  };

  instance.interceptors.request.use((config) => {
    if (!jwtToken) return new axios.Cancel("No token no request");
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // decode accessToken
      const jwtPayload = JSON.parse(window.atob(accessToken.split(".")[1]));
      // refresh token when expiring in 10mins
      if (jwtPayload.exp <= Date.now() / 1000 + 600) {
        // localStorage.clear();
        return attemptRefresh()
          .then((token) => {
            return {
              ...config,
              headers: {
                ...config.headers,
                "content-type": "application/json",
                Authorization: token,
              },
            };
          })
          .catch((err) => {
            console.log("Error : ", err);
          });
      }
    }

    return {
      ...config,
      headers: {
        ...config.headers,
        "content-type": "application/json",
        Authorization: store.getState().auth.accessToken,
      },
    };
  });

  instance.interceptors.response.use(
    (config) => config,
    (error) => {
      return handleInterceptorResponseError(error);
    }
  );

  /**
   * Functions
   */

  const getPilots = () => instance.get(`/pilot`);
  const getPilot = (pilot_uuid) => instance.get(`/pilot/${pilot_uuid}`);

  const getLogState = () => instance.get("/operations/state/logs");

  const getFlightStateData = (id) =>
    instance.get(`/operations/state/logs${id ? `?id=${id}` : ""}`);

  const getPlatformTypes = () => instance.get(`/platform/types`);

  const getOperations = () => instance.get(`/operations`);

  // const getOperationsLog = (offset) =>
  //   instance.get(`/operations/logs${offset ? `?offset=${offset}` : ""}`);

  const getOperationsLog = (offset, operatorGroup) =>
    instance.get(
      `/operations/logs${offset ? `?offset=${offset}&` : "?"}${
        operatorGroup ? `operatorGroup=${operatorGroup}` : ""
      }`
    );

  const getAllOperationSelection = () => instance.get(`/operations/selection`);

  const getOperatorsUsers = (operatorName) =>
    instance.get(`/operators/${operatorName}/users`);

  const assignOperator = (operatorName) =>
    instance.put(`/operators/${operatorName}/assign`);

  const unassignOperator = (operatorName) =>
    instance.put(`/operators/${operatorName}/unassign`);

  const getAssignedOperators = () => instance.get(`/operators/assigned`);

  const getOperators = () => instance.get("/operators");

  const setFlightContingent = (operationId) =>
    instance.post(`/operations/${operationId}/contingent`);

  const setFlightAccepted = (operationId) =>
    instance.post(`/operations/${operationId}/accepted`);

  const setFlightRejected = (operationId, operation) =>
    instance.post(`/operations/${operationId}/rejected`, operation);

  const getPlatforms = () => instance.get(`/platform`);

  const getPlatform = async (platform_uuid) => {
    if (!platform_uuid) {
      console.warn("platform uuid is empty");
      return new Promise();
    }
    return instance.get(`/platform/${platform_uuid}`);
  };

  const getTracker = () => instance.get(`/tracker`);

  const getTrackerScan = async (tracker_uuid) => {
    // console.log("check tracker uui authority", tracker_uuid);
    if (!tracker_uuid) {
      return instance.get(`/tracker`);
    }
    return instance.get(`/tracker/${tracker_uuid}`);
  };
  const getPlatformType = async (platform_type_uuid) => {
    if (!platform_type_uuid) {
      console.warn("platform type uuid is empty");
      return new Promise();
    }
    return instance.get(`/platform/types/${platform_type_uuid}`);
  };
  const getLogMessages = async () => {
    const res = await instance.get(`/log-messages`);
    return res.data;
  };

  const createLogMessage = async () => [];

  const getActiveOperations = () => {};

  const setContingent = (operationID) =>
    instance.post(`/operations/${operationID}/contingent`);

  const sendOperationNotification = async (data) => {
    instance.post(`/operations/notify`, data);
  };

  const getOperationOwner = (operation_uuids) =>
    instance.post(`/operations/owner`, operation_uuids);

  const getOperationData = async ({ operationID }) =>
    instance.request({
      method: "get",
      url: `/operations/${operationID}`,
    });

  const getOperationsApprovedUsers = (operation_uuids) =>
    instance.post(`/operations/approved-users`, operation_uuids);

  const submitNewOperator = ({
    username,
    email,
    permit_number,
    permit_expiry,
  }) =>
    instance.request({
      method: "post",
      url: `/operators/create`,
      data: {
        username,
        email,
        customAttributes: {
          permit_number,
          permit_expiry,
        },
      },
    });

  const sendEmail = (details) => instance.post(`/send-email`, details);

  const getOrganisationList = () => instance.get(`/organisation/list`);

  const getOrganisationManagedList = () =>
    instance.get(`/organisation/manage/list`);

  const getOrganisationManagedPilots = (organisation_name) =>
    instance.get(
      `/organisation/manage/list/users?organisation=${organisation_name}`
    );

  const assignOrganisation = (organisation_id) =>
    instance.post(`/organisation/manage/${organisation_id}`);

  const unAssignOrganisation = (organisation_id) =>
    instance.delete(`/organisation/manage/${organisation_id}`);

  return {
    sendEmail,
    getPilots,
    getPilot,
    getPlatformTypes,
    getOperations,
    getAllOperationSelection,
    getOperators,
    assignOperator,
    unassignOperator,
    getAssignedOperators,
    getOperatorsUsers,
    setFlightContingent,
    getPlatforms,
    getLogMessages,
    createLogMessage,
    getPlatform,
    getTracker,
    getTrackerScan,
    getPlatformType,
    setContingent,
    getLogState,
    sendOperationNotification,
    getOperationOwner,
    getOperationData,
    getOperationsApprovedUsers,
    setFlightAccepted,
    setFlightRejected,
    submitNewOperator,
    getOperationsLog,
    getFlightStateData,
    getActiveOperations,
    getOrganisationList,
    getOrganisationManagedList,
    assignOrganisation,
    unAssignOrganisation,
    getOrganisationManagedPilots,
    // ...sharedEndpoints,
  };
};
