import axios from "axios";
import circle from "@turf/circle";
import point from "turf-point";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import * as turfHelpers from "@turf/helpers";

/**
 * Handles api calls
 * @function getDataAirmapSpace(data) call api for the airmap
 * @param {any} data getting di coordinate when zoom end
 * @var buffered turn square meters with 40000 kliometers to get airmap space data
 */

let apiKey = "";

async function getDataAirmapSpace(data) {
  const types =
    "airport,special_use_airspace,park,hospital,heliport,power_plant,school,tfr,prison,wildfire,controlled_airspace";
  const turfPoint = point(data);
  const buffered = buffer(turfPoint, 40000, { units: "kilometers" });
  const bboxs = bbox(buffered);
  const advisoryCoordinate = bboxPolygon(bboxs);
  const { geometry } = advisoryCoordinate;
  const paramss = {
    geometry,
    full: true,
    types,
    geometry_format: "geojson",
  };

  const headerss = {
    "X-API-Key": apiKey,
  };

  const res = await axios
    .get("https://api.airmap.com/airspace/v2/search", {
      params: paramss,
      headers: headerss,
    })
    .then((response) => {
      const responseFilter = response;
      const dataFilter = responseFilter.data.data.filter(
        (obj) => obj.country !== null && obj.country !== "SGP"
      );
      return dataFilter;
    })
    .catch(function (error) {
      console.error(error);
      return error.response;
    });
  return res;
}
export function getAirmapspace(data) {
  return getDataAirmapSpace(data);
}

export function setAirmapspaceApiKey(data) {
  apiKey = data;
}
