import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
  PointMode,
} from "../../MapB/modes";

const draws = new MapboxDraw({
  displayControlsDefault: false,
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
    drag_circle: DragCircleMode,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode,
    draw_rectangle: DrawRectangle,
    // draw_point: PointMode,
  },
});

export default draws;
