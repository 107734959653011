import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl-style-switcher/styles.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "./custom-mapbox.css";
import "./windmarker/fonts/fonts.css";
import "./windmarker/windmarker.css";

import { useEffect, useState, useRef, useContext, useCallback } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { format } from "date-fns";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RulerControl } from "mapbox-gl-controls";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import { Button } from "@mui/material";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

import { getairMapSpaceData, setMapboxController } from "../../store/actions";
import { addMapLayers, setMapListeners } from "./init";

import {
  getOperationFeatures,
  getConstraintsFeatures,
  setLayerVisibility,
  getAirSpaceFetaures,
  getConformanceFeatures,
} from "./layers";
import MapboxController from "./controller";
import ExtendDrawBar from "./drawbar";
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
  PointMode,
} from "./modes";
import { useApi } from "../../api/useApi";
import { channels } from "../../config/channels";
import { messageTypes } from "../../config/messageTypes";
import getLocation from "../../hooks/getLocalLocation";
import { computePolygonCenter } from "../../api/polygonCenter";
import { AppColourTheme } from "../../contexts/AppColourTheme";
import useWebsocket from "../../hooks/useWebsocket";
import OverlayManager from "../OverlayManager";
import SnackbarMessage from "../SnackbarMessage";
import WeatherCurrent from "../Weather/weatherCurrentView";
import WeatherForecast from "../Weather/weatherForecastView";
import useCognitoAuth from "../../hooks/useCognitoAuth";

const IS_ADSB_PAID = false;

/**
 * Initialises MapboxDraw object to enable drawing
 */
const draws = new MapboxDraw({
  displayControlsDefault: false,
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
    drag_circle: DragCircleMode,
    direct_select: DirectMode,
    simple_select: SimpleSelectMode,
    draw_rectangle: DrawRectangle,
    // draw_point: PointMode,
  },
});

// const ruler = new RulerControl();

/**
 * Component encapsulating MapBox map
 * @param {*} props Props to pass in for the MapB component
 * @returns Component that displays MapBox map
 */

export default function MapB(props) {
  const {
    mapBoxToken,
    selectedFlightData,
    selectedTrackerData,
    selectedPlatformData,
    // telemetryData,
    constraints,
    // mapHeight = document.documentElement.clientHeight - 60, Reduce map height to accomodate header
    mapHeight = document.documentElement.clientHeight,
    /* emergency point */
    emergencyLanding,
    handleSetEmergencyLanding,
    updatedEmergencyLanding,
    contingencyLandingPoint,
    setContingencyLandingPoint,
    setMapViewController,
    onSelectEmergencyLanding,
    // route waypoints
    selectedWaypoints,
    onSelectWaypoints,
    updatedSelectedWaypoints,
    handleSetSelectedWaypoints,
    // area waypoints
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    updatedSelectedAreaWaypoints,
    handleSetSelectedAreaWaypoints,
    /* circle waypoints */
    selectedCircleWaypoints,
    selectedCircleProperties,
    onSelectCircleWaypoints,
    onSelectCircleProperties,
    handleSetSelectedCircleProperties,
    handleSetSelectedCircleWaypoints,
    /* ship delivery operation  */
    setSelectedShip,
    handleOpenShipDeliveryOperationsForced,
    /* layer booleans */
    showShipLayer,
    showAdsbLayer,
    showConstraintsLayer,
    showStaticConstraintsLayer,
    showRainMapLayer,
    showAirMapSpace,
    showOperationVolumeLayer,
    showMapElevation,
    showDroneIconLayer,
    showGnssLayer,
    showAnchorageLayer,
    showDronePortLayer,
    showSgBoundaryLayer,
    showSgPopulationDensity,
    showCollisionLayer,
    /* layer togglers */
    toggleShowShipLayer,
    toggleShowAdsbLayer,
    toggleShowConstraintsLayer,
    toggleShowStaticConstraintsLayer,
    toggleShowBuildingLayer,
    toggleShowOperationVolumeLayer,
    togglesshowDroneIconLayer,
    toggleShowRainMapLayer,
    togglesshowAirMapSpace,
    togglesshowGnssLayer,
    toggleShowAnchorageLayer,
    toggleSgPopulationDensity,
    toggleSgBoundaryLayer,
    toggleShowDronePortLayer,
    toggleMapElevation,
    toggleShowCollisionLayer,

    /* drawer open togglers */
    mostRecentComponent,
    toggleMostRecentComponent,
  } = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState();
  const [initializeMapB, setInitializeMapB] = useState(0);

  const selectedTrackerDataRef = useRef([]);
  const selectedPlatformDataRef = useRef([]);
  const collisionDatasRef = useRef(new Map());

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    handleChange();
  }, [expanded]);
  // const [lng, setLng] = useState(103.8167107035549);
  // const [lat, setLat] = useState(1.366701902383488);
  const [zoom, setZoom] = useState(11);
  const [modelMap, setModelMap] = useState(new Map());
  const [trackerStatusMap, setTrackerStatusMap] = useState(new Map());
  const [trackerOpsMap, setTrackerOpsMap] = useState(new Map());
  const [style, setStyle] = useState(null);
  const [focussedOperation, setFocussedOperation] = useState(null);
  // const [showConformanceLayer, setShowConformanceLayer] = useState(true);

  const envVar = useSelector((state) => state.envVar);

  const shipData = useSelector((state) => state.shipData.data);
  const ADSBData = useSelector((state) =>
    IS_ADSB_PAID ? state.ADSBDataPaid.data : state.ADSBDataFree.data
  );
  const airMapSpaceData = useSelector((state) => state.airMapSpaceData.data);
  const api = useApi();
  const conformanceData = useSelector((state) => state.conformanceData.data);
  const showConformanceLayer = useSelector(
    (state) => state.conformanceData.showConformanceLayer
  );
  const dataConformanceGL = localStorage.getItem("conformanceData")
    ? localStorage.getItem("conformanceData")
    : JSON.stringify([]);

  // const { isRemoteId } = useCognitoAuth();
  const [teleObj, setTeleObj] = useState({});
  const teleObjRef = useRef({});
  const actualTeleObjRef = useRef({});
  const keyRef = useRef({});
  const actualKeyRef = useRef({});
  const mostRecentComponentRef = useRef(mostRecentComponent);

  // const [selectedTrackerData, setSelectedTrackerData] = useState([]);
  // const [selectedPlatformData, setSelectedPlatformData] = useState([]);

  const [telemetryData, setTelemetryData] = useState([]);
  const { colourTheme, handleSetColourTheme } = useContext(AppColourTheme);
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });

  const { username, isOperator, isAuthority, isPilot, isRemoteId } =
    useCognitoAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const draw = draws;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ruler = new RulerControl();

  mapboxgl.accessToken = mapBoxToken;
  // keep copy of list of platforms, pilot and tracker
  const platformList = useRef({});
  const trackerList = useRef({});
  const pilotList = useRef({});
  /**
   * Initialise map with Dark styling with default zoom level 11 and centered at Singapore
   */
  // const lat = localStorage.getItem("localLat");
  // const lng = localStorage.getItem("localLng"); // changed to let as will be changed later on
  // useEffect(() => {
  //   try {
  //     getLocation();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  /* useRef to ensure the mostRecentComponent value being used is the most updated due to async */
  useEffect(() => {
    mostRecentComponentRef.current = mostRecentComponent;
  }, [mostRecentComponent]);

  const handleToggleMostRecentComponent = () => {
    toggleMostRecentComponent(mostRecentComponentRef.current);
  };

  useEffect(() => {
    if (selectedTrackerData)
      selectedTrackerDataRef.current = selectedTrackerData;
    if (selectedPlatformData)
      selectedPlatformDataRef.current = selectedPlatformData;
  }, [selectedPlatformData, selectedTrackerData]);

  let lat = localStorage.getItem("localLat");
  let lng = localStorage.getItem("localLng");

  useEffect(() => {
    if (map.current !== null) return; // initialize map only once
    let zoomLevel = zoom;

    if (lat === null || lng === null) {
      // setZoom(2);
      // zoomLevel = 2; // setZoom is async so instead of await for zoom useState, this should be faster
      setZoom(11);
      zoomLevel = 11; // setZoom is async so instead of await for zoom useState, this should be faster
      lat = 1.35535753953; // added default lat lng on SG if none found
      lng = 103.827984887;
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [lng, lat],
      zoom: zoomLevel,
    });

    /**
     * Add controls onto the current map on loading of site
     */
    map.current.on("load", () => {
      // map.on load does not work as intended, known mapbox issue https://github.com/mapbox/mapbox-gl-js/issues/6707
      // intermittent issue where creating new map (new mapboxgl.Map) does not create properly, resulting in unable to draw
      // awaiting map to load before adding controllers does not resolve issue, most consistent solution is reload map after short delay
      addMapControllers();
    });
  }, [
    api,
    dispatch,
    draw,
    // lat,
    // lng,
    // onSelectAreaWaypoints, // is it really necessary to have these in the useEffect? this code is on load, so it should only happen once anyways on load
    // onSelectCircleProperties,
    // onSelectCircleWaypoints,
    // onSelectEmergencyLanding,
    // onSelectWaypoints,
    ruler,
    zoom,
  ]);

  const addMapControllers = () => {
    if (!mapContainer.current) return;
    let zoomLevel = zoom;
    if (lat === null || lng === null) {
      setZoom(2);
      zoomLevel = 2; // setZoom is async so instead of await for zoom useState, this should be faster
    }
    // removed this extra creation of mapbox object since it is already in the above useeffect
    // map.current = new mapboxgl.Map({
    //   container: mapContainer.current,
    //   style: "mapbox://styles/mapbox/dark-v11",
    //   center: [lng, lat],
    //   zoom: zoomLevel,
    // });

    dispatch(
      setMapboxController(
        new MapboxController(map.current, draw, setFocussedOperation)
      )
    );
    setMapListeners(
      map.current,
      draw,
      // onSelectEmergencyLanding,
      handleSetEmergencyLanding,
      // onSelectWaypoints,
      // onSelectAreaWaypoints,
      // onSelectCircleWaypoints,
      // onSelectCircleProperties,
      handleSetSelectedWaypoints,
      handleSetSelectedAreaWaypoints,
      handleSetSelectedCircleProperties,
      handleSetSelectedCircleWaypoints,
      handleToggleMostRecentComponent
    );

    /**
     * Add controls onto the current map on loading of site
     */

    // removed this extra on load effect since it is in the above useeffect
    // map.current.on("load", () => {
    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        placeholder: "Find address or place",
      })
    );
    // Add geolocate control to the map.
    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );
    map.current.addControl(ruler, "top-right");

    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true,
      }),
      "top-right"
    );

    map.current.addControl(draw, "top-right");

    const styles = [
      {
        title: "Satellite",
        uri: "mapbox://styles/mapbox/satellite-streets-v12",
      },
      {
        title: "Street",
        uri: "mapbox://styles/mapbox/streets-v12",
      },
      {
        title: "Outdoors",
        uri: "mapbox://styles/mapbox/outdoors-v12",
      },
      {
        title: "Light",
        uri: "mapbox://styles/mapbox/light-v11",
      },
      {
        title: "Dark",
        uri: "mapbox://styles/mapbox/dark-v11",
      },
    ];
    const options = {
      defaultStyle: "Dark",
      eventListeners: {
        onChange: (event) => {
          const colourThemeName = event.target.className.split(" ")[0];
          handleSetColourTheme(colourThemeName);
        },
      },
    };

    // map.current.addControl()

    map.current.addControl(
      new MapboxStyleSwitcherControl(styles, options),
      "top-right"
    );

    /**
     * Handles logic for popup
     */
    map.current.on("click", async (e) => {
      if (draw.getMode() !== "simple_select" || ruler.isMeasuring) {
        return;
      }
      const popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        closeOnMove: true,
      });

      const ADSBLayer = map.current.getLayer("adsb-layer");
      if (!ADSBLayer) return;
      const ADSBDetails = ADSBLayer.implementation.getClickedObjectDetails(
        e.point
      );
      if (ADSBDetails) {
        const description = `
          <table>
          <style>
            tr.adsb:nth-of-type(even) {
              background-color: #ffcccb;
            }
          </style>
            <tr><td><b>CALLSIGN: ${ADSBDetails.callsign}</b></td></tr>
            <tr class="adsb"><td>ALT: ${ADSBDetails.alt}</td></tr>
            <tr class="adsb"><td>GS: ${ADSBDetails.gs}</td></tr>
            <tr class="adsb"><td>HDG: ${ADSBDetails.heading}</td></tr>
          </table>
          `;

        popup.setHTML(description).setLngLat(e.lngLat).addTo(map.current);
        return;
      }

      const AISLayer = map.current.getLayer("ais-layer");
      if (!AISLayer) return;
      const AISDetails = AISLayer.implementation.getClickedObjectDetails(
        e.point
      );
      if (AISDetails) {
        const handleClickShipDelivery = (data) => {
          setSelectedShip({
            latitudeDegrees: data.lat,
            longitudeDegrees: data.lng,
            vesselName: data.vesselName,
            callSign: data.callSign,
          });
          handleOpenShipDeliveryOperationsForced();
        };

        // Create a container element
        const container = document.createElement("div");

        // Create the Material-UI Button
        const customButton = (
          <Button
            variant="contained"
            size="small"
            className="custom-operation-button"
            fullWidth
          >
            <LocalShippingIcon />
          </Button>
        );

        // Render the button into the container
        ReactDOM.render(customButton, container);

        const popupContent = document.createElement("div");
        popupContent.innerHTML = `
        <table>
          <style>
            tr.ais:nth-of-type(even) {
              background-color: #ffcccb;
            }
          </style>
          <tr><td><b>NAME: ${AISDetails.vesselName}</b></td></tr>
          <tr class="ais"><td>CALLSIGN: ${AISDetails.callSign}</td></tr>
          <tr class="ais"><td>L: ${AISDetails.vesselLength} | W: ${AISDetails.vesselBreadth}</td></tr>
          <tr class="ais"><td>LAT: ${AISDetails.lat}</td></tr>
          <tr class="ais"><td>LON: ${AISDetails.lng}</td></tr>
          <tr class="adsb"><td></td></tr> <!-- This row is left empty for the custom button -->
        </table>`;

        // Append the container to your popup
        popupContent.querySelector(".adsb td").appendChild(container);

        // Append the Material-UI Button to the empty row
        popupContent.querySelector(".adsb td").appendChild(
          container.firstChild // Assuming that the first child is the button element
        );

        // Access the button inside the container
        const buttonElement = popupContent.querySelector("button");
        if (buttonElement) {
          // Add an event listener to the button
          buttonElement.addEventListener("click", () =>
            handleClickShipDelivery(AISDetails)
          );
        }
        popup
          .setDOMContent(popupContent)
          .setLngLat(e.lngLat)
          .addTo(map.current);
        return;
      }

      const trackerLayer = map.current.getLayer("tracker-layer");
      if (!trackerLayer) return;
      const trackerDetails =
        trackerLayer.implementation.getClickedObjectDetails(e.point);
      if (trackerDetails) {
        // const platformResponse = await api.getPlatform(
        //   // trackerDetails.opsVolume.request.platform_uuid
        //   trackerDetails.platformData.platform_uuid
        // );
        // const platformTypeResponse = await api.getPlatformType(
        //   platformResponse.data.platform_type_uuid
        // );
        const description = `
          <table>
          <style>
            tr.trackd:nth-of-type(even) {
              background-color: #d3d3d3;
            }
          </style>
            <tr><td><b>${trackerDetails?.intent}</b></td></tr>
            <tr class="trackd"><td>TRACKER: ${trackerDetails?.trackerData?.tracker_name}</td></tr>
            <tr class="trackd"><td>SPEED: ${trackerDetails?.speed}M/S | ALT: ${trackerDetails?.altitude}M | HDG: ${trackerDetails.heading}</td></tr>
            <tr class="trackd"><td>LAT: ${trackerDetails?.position?.lat} | LNG: ${trackerDetails?.position?.lng}</td></tr>
          </table>
          `;
        popup.setHTML(description).setLngLat(e.lngLat).addTo(map.current);
        return;
      }

      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      // Find features intersecting the bounding box.
      const selectedStaticConstraints = map.current.queryRenderedFeatures(
        bbox,
        {
          layers: ["no-fly"],
        }
      );
      if (selectedStaticConstraints.length) {
        const { description, name } = selectedStaticConstraints[0].properties;
        const staticConstraintDescription = `
          <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="statconst"><td>${description}</td></tr>
          </table>
          `;

        popup
          .setHTML(staticConstraintDescription)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }

      const selectedAnchorageConstraints = map.current.queryRenderedFeatures(
        bbox,
        {
          layers: ["sg-anchor"],
        }
      );
      if (selectedAnchorageConstraints.length) {
        const { id, seamark_na } = selectedAnchorageConstraints[0].properties;
        const anchorageDescription = `
          <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td><b>${id}</b></td></tr>
            <tr class="statconst"><td>${seamark_na}</td></tr>
          </table>
          `;

        popup
          .setHTML(anchorageDescription)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }

      const selectedDronePort = map.current.queryRenderedFeatures(bbox, {
        layers: ["sg-port"],
      });

      if (selectedDronePort.length) {
        const { id, seamark_na } = selectedDronePort[0].properties;
        const dronePortDescription = `
        <table>
          <style>
            tr.statconst:nth-of-type(even) {
              background-color: #add8e6;
            }
          </style>
          <tr><td><b>${id}</b></td></tr>
          <tr class="statconst"><td>${seamark_na}</td></tr>
        </table>
        `;

        popup
          .setHTML(dronePortDescription)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }

      const selectedPopulationDensity = map.current.queryRenderedFeatures(
        bbox,
        {
          layers: ["sg-population-density-layer"],
        }
      );

      if (selectedPopulationDensity.length) {
        const { name, census_population, dynamic_population } =
          selectedPopulationDensity[0].properties;
        const populationDensityDescription = `
          <table>
            <style>
              tr.statconst:nth-of-type(even) {
                background-color: #add8e6;
              }
            </style>
            <tr><td>Location : <b>${name}</b></td></tr>
            <tr class="dynamic_pop"><td>Dynamic Population : ${dynamic_population}</td></tr>
            <tr class="dynamic_pop"><td>Census Population : ${census_population}</td></tr>
          </table>
          `;

        popup
          .setHTML(populationDensityDescription)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }

      const selectedConstraints = map.current.queryRenderedFeatures(bbox, {
        layers: ["constraints"],
      });
      if (selectedConstraints.length) {
        const {
          constraintID,
          timeStart,
          timeEnd,
          base,
          height,
          name,
          desc,
          rule,
          whitelist,
          authReq,
          prohibitReq,
          authWhitelist,
          prohibitWhitelist,
          conditions,
          recurring,
          recurrenceRange,
          altitudeHigher,
          altitudeLower,
        } = selectedConstraints[0].properties;
        const formatDate = (date) =>
          format(new Date(date), "dd/MM/yyyy HH:mm:ss");
        const jsonRules = JSON.parse(rule);
        const rules = Object.keys(jsonRules)
          .filter((k) => jsonRules[k])
          .join(", ")
          .replace("_", " ");

        let constraintsDescription = `
          <table>
            <style>
              tr.const:nth-of-type(even) {
                background-color: #ffcccb;
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="const"><td>CID: ${constraintID}</td></tr>
            <tr class="const"><td>${desc}</td></tr>
            <tr class="const"><td>Alt: ${altitudeLower} to ${altitudeHigher}M</td></tr>
            <tr class="const"><td>Active Window: ${timeStart} to ${timeEnd}</td></tr>
          `;
        let rulesRequired = "";
        if (recurring) {
          const rRangeJson = JSON.parse(recurrenceRange);
          const startTime = formatDate(rRangeJson.time_start.value);
          const endTime = formatDate(rRangeJson.time_end.value);
          constraintsDescription = constraintsDescription.concat(`
            <tr class="const"><td>Recurs ${recurring} from ${startTime} to ${endTime}</td></tr>
            `);
        }
        if (jsonRules.prohibited && prohibitReq) {
          const prohibitReqJSON = JSON.parse(prohibitReq);
          let startTime;
          if (prohibitReqJSON?.time_start?.value) {
            startTime = formatDate(prohibitReqJSON.time_start.value);
          }
          let endTime;
          if (prohibitReqJSON?.time_end?.value) {
            endTime = formatDate(prohibitReqJSON.time_end.value);
          }
          const whitelistJSON =
            prohibitWhitelist && JSON.parse(prohibitWhitelist);
          if (whitelistJSON?.users?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.users.length > 3
                  ? `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}... +${whitelistJSON.users.length - 3} more. `
                  : `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }
          if (whitelistJSON?.usergroups?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.usergroups.length > 3
                  ? `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}... +${
                      whitelistJSON.usergroups.length - 3
                    } more. `
                  : `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }

          if ("recurring" in prohibitReqJSON) {
            const rRangeJson = prohibitReqJSON.recurrence_range;
            startTime = formatDate(rRangeJson.time_start.value);
            endTime = formatDate(rRangeJson.time_end.value);
            const rStartTime = formatDate(prohibitReqJSON.time_start.value);
            const rEndTime = formatDate(prohibitReqJSON.time_end.value);
            rulesRequired = rulesRequired.concat(`
              Prohibited from ${startTime} to ${endTime}. <br>
              `);
            rulesRequired = rulesRequired.concat(`
              Recurs ${prohibitReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
              `);
          } else if (startTime && endTime) {
            rulesRequired = rulesRequired.concat(`
            Prohibited from ${startTime} to ${endTime}. <br>
            `);
          }
        }
        if (jsonRules.authorisation_required) {
          const authReqJSON = JSON.parse(authReq);
          let startTime;
          if (authReqJSON?.time_start?.value) {
            startTime = formatDate(authReqJSON.time_start.value);
          }
          let endTime;
          if (authReqJSON?.time_end?.value) {
            endTime = formatDate(authReqJSON.time_end.value);
          }
          const whitelistJSON = authWhitelist && JSON.parse(authWhitelist);
          if (whitelistJSON?.users?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.users.length > 3
                  ? `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}... +${whitelistJSON.users.length - 3} more. `
                  : `Whitelisted Users: ${whitelistJSON.users
                      .slice(0, 3)
                      .join(", ")}. `
              }
              `);
          }
          if (whitelistJSON?.usergroups?.length) {
            rulesRequired = rulesRequired.concat(`
              ${
                whitelistJSON.usergroups.length > 3
                  ? `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}... +${
                      whitelistJSON.usergroups.length - 3
                    } more. `
                  : `Whitelisted Groups: ${whitelistJSON.usergroups
                      .slice(0, 3)
                      .join(", ")}.<br>`
              }
              `);
          }

          if ("recurring" in authReqJSON) {
            const rRangeJson = authReqJSON.recurrence_range;
            startTime = formatDate(rRangeJson.time_start.value);
            endTime = formatDate(rRangeJson.time_end.value);
            const rStartTime = formatDate(authReqJSON.time_start.value);
            const rEndTime = formatDate(authReqJSON.time_end.value);
            rulesRequired = rulesRequired.concat(`
              Authorisation required from ${startTime} to ${endTime}.<br>
              `);
            rulesRequired = rulesRequired.concat(`
                Recurs ${authReqJSON.recurring} from ${rStartTime} to ${rEndTime}<br>
                `);
          } else if (startTime && endTime) {
            rulesRequired = rulesRequired.concat(`
            Authorisation required from ${startTime} to ${endTime}.<br>
        `);
          }
        }
        if (jsonRules.conditional) {
          const condJson = JSON.parse(conditions);
          rulesRequired = rulesRequired.concat(`
            ${condJson.no_camera ? "No camera allowed. " : ""}
            ${condJson.power_tethered ? "Tethering required. " : ""}
            ${
              condJson.noise_threshold
                ? `Noise below ${condJson.noise_threshold} db. `
                : ""
            }
            ${condJson.others ? `${condJson.others}. ` : ""}
            `);
        }
        if (!jsonRules.information_only) {
          constraintsDescription = constraintsDescription.concat(`
            <tr class="const"><td>Rules: ${rules}</td></tr>
            <tr class="const"><td>${rulesRequired}</td></tr>
            `);
        }
        constraintsDescription = constraintsDescription.concat(`</table>`);

        popup
          .setHTML(constraintsDescription)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }
      const selectedOpsVolume = map.current.queryRenderedFeatures(bbox, {
        layers: ["operation"],
      });
      if (selectedOpsVolume.length) {
        const {
          intent,
          operationType,
          timeStart,
          timeEnd,
          base,
          height,
          request,
          altitudeHigher,
          altitudeLower,
        } = selectedOpsVolume[0].properties;
        const requestParse = JSON.parse(request);
        const { tracker_uuid, pilot_uuid, platform_uuid } = requestParse;
        const dataTrackers = [];
        const dataPlatforms = [];
        const dataPilots = [];
        const emerLandingPoint = requestParse.contingency_plans.landing_point;
        // add logic to check tracker and platform data here
        const getDataTracker = async () =>
          Promise.all(
            tracker_uuid.map(async (uuid, index) => {
              if (!uuid) return undefined;
              const filteredData = trackerList.current.data.filter(
                (item) => item.tracker_uuid === uuid
              );
              // const response = await api.getTrackerScan(uuid);
              // return response;
              return { data: filteredData[0] };
            })
          );
        getDataTracker().then(function (result) {
          dataTrackers.push(result);
        });
        // const getDataPilot = async () =>
        //   Promise.all(
        //     tracker_uuid.map(async (uuid, index) => {
        //       if (!uuid) return undefined;
        //       const response = await api.getPilot(uuid);
        //       return response;
        //     })
        //   );
        // getDataPilot().then(function (result) {
        //   dataPilots.push(result);
        // });
        // const getDataPlatform = async () =>
        //   Promise.all(
        //     tracker_uuid.map(async (uuid, index) => {
        //       if (!uuid) return undefined;
        //       const response = await api.getPlatform(uuid);
        //       return response;
        //     })
        //   );
        // getDataPlatform().then(function (result) {
        //   dataPlatforms.push(result);
        // });
        // tracker_uuid.map(async (uuid, index) => {
        //   if (!uuid) return undefined;
        //   const response = await api.getTrackerScan(uuid);
        //   dataTrackers.push(response.data);
        //   return response;
        // });
        pilot_uuid.map(async (uuid, index) => {
          if (!uuid) return undefined;
          // const response = await api.getPilot(uuid);
          // Filtered data array
          const filteredData = pilotList.current.data.filter(
            (item) => item.pilot_uuid === uuid
          );

          function isDuplicate(pilotUuid) {
            return dataPilots.some((item) => item.platform_uuid === pilotUuid);
          }
          // Push filtered data if it's not a duplicate
          if (
            filteredData.length > 0 &&
            !isDuplicate(filteredData[0].platform_uuid)
          ) {
            dataPilots.push(filteredData[0]);
          }
          return { data: filteredData[0] };
        });
        platform_uuid.map(async (uuid, index) => {
          if (!uuid) return undefined;
          // const response = await api.getPlatform(uuid);
          // dataPlatforms.push(response.data);
          // return response;
          const filteredData = platformList.current.data.filter(
            (item) => item.platform_uuid === uuid
          );
          dataPlatforms.push(filteredData[0]);
          return { data: filteredData[0] };
        });
        let opsVolumeDescription = "";
        const timeDiff = function (startDate, endDate) {
          const startTime = new Date(startDate);
          const endTime = new Date(endDate);
          const diff = endTime.getTime() - startTime.getTime();
          const hrDiff = diff / 3600 / 1000; // 1.555555
          const totalHours = String(parseFloat(hrDiff.toFixed(2))); // 1.5
          const splitHourseMinute = totalHours.split(".");
          return `${splitHourseMinute[0]} H ${splitHourseMinute[1]} M`;
        };
        setTimeout(function () {
          const tR = dataTrackers[0]
            .map((x) => x?.data?.tracker_name)
            .join(", ");
          const pL = dataPilots.map((x) => x?.pilot_name).join(", ");
          const pM = dataPlatforms.map((x) => x?.platform_callsign).join(", ");
          const period = timeDiff(timeStart, timeEnd);
          const emerLandingLat = emerLandingPoint[0][0] || 0;
          const emerLandingLng = emerLandingPoint[0][1] || 0;
          opsVolumeDescription = `
              <table>
                <style>
                tr.ops:nth-of-type(even) {
                    background-color: #d3d3d3;
                  }
                  tr.ops p {
                    text-indent: 10px;
                    text-align: left;
                    margin: 2px;
                  }
                </style>
                <tr><td><b>OPERATION: ${intent}</b></td></tr>
                <tr class="ops"><td>TYPE: ${operationType.toUpperCase()}</td></tr>
                <tr class="ops"><td>ALT: ${altitudeLower} to ${altitudeHigher}M</td></tr>
                <tr class="ops"><td>START: ${timeStart}</td></tr>
                <tr class="ops"><td>END: ${timeEnd}</td></tr>
                <tr class="ops"><td>FLIGHT PERIOD: ${period}</td></tr>
                <tr class="ops"><td>EMERGENCY LANDING: <p> Latitude: ${emerLandingLat}</p> <p> Longtitude: ${emerLandingLng}</p> </td></tr>
                <tr class="ops"><td>PILOT: ${pL}</td></tr>
                <tr class="ops"><td>TRACKER: ${tR}</td></tr>
                <tr class="ops"><td>PLATFORM: ${pM}</td></tr>
              </table>
              `;
          popup
            .setHTML(opsVolumeDescription)
            .setLngLat(e.lngLat)
            .addTo(map.current);
        }, 600); // delay 500 ms to assign array = []
      }
      const selectedAirMapSpace = map.current.queryRenderedFeatures(bbox, {
        layers: ["airmap-space-fill"],
      });
      if (selectedAirMapSpace.length) {
        const { types, name, state, country, color } =
          selectedAirMapSpace[0].properties;
        const staticselectedAirMapSpace = `
          <table>
            <style>
              tr.airmap:nth-of-type(even) {
                background-color: ${color};
              }
            </style>
            <tr><td><b>${name}</b></td></tr>
            <tr class="airmap"><td>${types}</td></tr>
            <tr class="airmap"><td>${state}</td></tr>
            <tr class="airmap"><td>${country}</td></tr>
          </table>
          `;

        popup
          .setHTML(staticselectedAirMapSpace)
          .setLngLat(e.lngLat)
          .addTo(map.current);
      }
    });

    // Change the cursor to a pointer when
    // the mouse is over the no fly zones or plane or ship.
    map.current.on("mousemove", (e) => {
      if (draw.getMode() !== "simple_select" || ruler.isMeasuring) {
        map.current.getCanvas().style.cursor = "crosshair";
        return;
      }
      const ADSBLayer = map.current.getLayer("adsb-layer");
      if (!ADSBLayer) return;
      const ADSBDetails = ADSBLayer.implementation.getClickedObjectDetails(
        e.point
      );
      if (ADSBDetails) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }

      const AISLayer = map.current.getLayer("ais-layer");
      if (!AISLayer) return;
      const AISDetails = AISLayer.implementation.getClickedObjectDetails(
        e.point
      );
      if (AISDetails) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }

      const trackerLayer = map.current.getLayer("tracker-layer");
      if (!trackerLayer) return;
      const trackerDetails =
        trackerLayer.implementation.getClickedObjectDetails(e.point);
      if (trackerDetails) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }

      const bbox = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      // Find features intersecting the bounding box.
      const selectedStaticConstraints = map.current.queryRenderedFeatures(
        bbox,
        {
          layers: ["no-fly"],
        }
      );
      if (selectedStaticConstraints.length) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }
      const selectedConstraints = map.current.queryRenderedFeatures(bbox, {
        layers: ["constraints"],
      });
      if (selectedConstraints.length) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }
      const selectedOpsVolumes = map.current.queryRenderedFeatures(bbox, {
        layers: ["operation"],
      });
      if (selectedOpsVolumes.length) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }
      const selectedPopulationDensity = map.current.queryRenderedFeatures(
        bbox,
        {
          layers: ["sg-population-density-layer"],
        }
      );
      if (selectedPopulationDensity.length) {
        map.current.getCanvas().style.cursor = "pointer";
        return;
      }
      // const selectedAirMapSpaces = map.current.queryRenderedFeatures(bbox, {
      //   layers: ["airmap-space-fill"],
      // });
      // if (selectedAirMapSpaces.length) {
      //   map.current.getCanvas().style.cursor = "pointer";
      //   return;
      // }
      map.current.getCanvas().style.cursor = "";
    });

    map.current.on("styledataloading", () => {
      map.current.once("styledata", (e) => {
        setStyle(e.style);
      });
    });
    /**
     * Handles logic for zoomend
     * @param {any} latlngData get coordinate
     */
    map.current.on("zoomend", (e) => {
      const latlngData = [
        e.target.transform._center.lng,
        e.target.transform._center.lat,
      ];
      dispatch(getairMapSpaceData(latlngData));
      const adsbLayer = map.current.getLayer("adsb-layer");
      const trackerLayer = map.current.getLayer("tracker-layer");
      const airspaceLayer = map.current.getLayer("airmap-space-fill");
      if (!adsbLayer || !trackerLayer || airspaceLayer) return;
      adsbLayer.implementation.repaint();
      trackerLayer.implementation.repaint();
      airspaceLayer.implementation.repaint();
    });
  };

  useEffect(() => {
    // AddMapLayers is triggered in useEffect to load layers into Map B
    // There is a chance that map gets loaded but useEffect is not triggered yet
    // or useEffect triggered but map.current not initialized
    // This scenerio will cause a delay in layers being loaded into mapbox
    // To prevent this scenerio, added this interval to trigger AddMapLayers every sec until initialized
    const intervalId = setInterval(() => {
      if (map.current._loaded) {
        clearInterval(intervalId);
      }
      setInitializeMapB((s) => s + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  /**
   * Adds the different map layers, listening for changes to the layers and updating the layers accordingly
   */
  useEffect(() => {
    if (!map.current || !map.current._loaded) return;
    addMapLayers(
      map.current,
      modelMap,
      showStaticConstraintsLayer,
      constraints,
      showConstraintsLayer,
      showShipLayer,
      shipData,
      showAdsbLayer,
      ADSBData,
      IS_ADSB_PAID,
      showRainMapLayer,
      airMapSpaceData,
      showAirMapSpace,
      conformanceData,
      showConformanceLayer,
      showGnssLayer,
      showAnchorageLayer,
      showDronePortLayer,
      showSgBoundaryLayer,
      showSgPopulationDensity,
      showMapElevation
    );
  }, [
    style,
    modelMap,
    showStaticConstraintsLayer,
    constraints,
    showConstraintsLayer,
    showShipLayer,
    showAdsbLayer,
    shipData,
    ADSBData,
    airMapSpaceData,
    showAirMapSpace,
    conformanceData,
    showConformanceLayer,
    showRainMapLayer,
    showGnssLayer,
    showAnchorageLayer,
    showDronePortLayer,
    showSgBoundaryLayer,
    showSgPopulationDensity,
    showMapElevation,
    initializeMapB,
  ]);

  /**
   * Sets layer visibilities
   */
  useEffect(() => {
    if (!map.current) return;
    // turn off local static constraint
    const noFlyLayer = map.current.getLayer("no-fly");
    if (!noFlyLayer) return;
    setLayerVisibility(map.current, "no-fly", showStaticConstraintsLayer);
    const noFlyOutlineLayer = map.current.getLayer("no-fly-outline");
    if (!noFlyOutlineLayer) return;
    setLayerVisibility(
      map.current,
      "no-fly-outline",
      showStaticConstraintsLayer
    );

    const anchorageLayer = map.current.getLayer("sg-anchor");
    if (!anchorageLayer) return;
    setLayerVisibility(map.current, "sg-anchor", showAnchorageLayer);
    const anchorageOutlineLayer = map.current.getLayer("sg-anchor-outline");
    if (!anchorageOutlineLayer) return;
    setLayerVisibility(map.current, "sg-anchor-outline", showAnchorageLayer);
    const aisLayer = map.current.getLayer("ais-layer");
    if (!aisLayer) return;
    setLayerVisibility(map.current, "ais-layer", showShipLayer);
    const adsbLayer = map.current.getLayer("adsb-layer");
    if (!adsbLayer) return;
    setLayerVisibility(map.current, "adsb-layer", showAdsbLayer);
    const constraintsLayer = map.current.getLayer("constraints");
    if (!constraintsLayer) return;
    setLayerVisibility(map.current, "constraints", showConstraintsLayer);
    const rainMapLayer = map.current.getLayer("rain-map-layer");
    if (!rainMapLayer) return;
    const airmapLayer = map.current.getLayer("airmap-space-fill");
    if (!airmapLayer) return;
    const operationLayer = map.current.getLayer("operation");
    if (!operationLayer) return;
    setLayerVisibility(map.current, "operation", showOperationVolumeLayer);
    const trackerLayer = map.current.getLayer("tracker-layer");
    if (!trackerLayer) return;
    setLayerVisibility(map.current, "tracker-layer", showDroneIconLayer);
    const collisionLayer = map.current.getLayer("collision-layer");
    if (!collisionLayer) return;
    setLayerVisibility(map.current, "collision-layer", showCollisionLayer);
    const elevationLayer = map.current.getLayer("terrain-layer");
    if (!elevationLayer) return;
    setLayerVisibility(map.current, "terrain-layer", showMapElevation);
    if (showMapElevation) {
      if (map.current.getTerrain()?.source !== "terrain-elevation")
        map.current.setTerrain({ source: "terrain-elevation" });
    }
    if (!showMapElevation) {
      map.current.setTerrain();
    }
    const sgPopulationLayer = map.current.getLayer(
      "sg-population-density-layer"
    );
    if (!sgPopulationLayer) return;
    setLayerVisibility(
      map.current,
      "sg-population-density-layer",
      showSgPopulationDensity
    );
    const gnssLayer = map.current.getLayer("gnss-layer");
    if (!gnssLayer) return;
    setLayerVisibility(map.current, "gnss-layer", showGnssLayer);
    if (envVar.use_simulated_weather.Value === "true") {
      setLayerVisibility(map.current, "rain-map-layer", showRainMapLayer);
    } else {
      setLayerVisibility(map.current, "precipitation", showRainMapLayer);
    }
    setLayerVisibility(map.current, "airmap-space-fill", false);

    // // previous method of switching weather layer based on location
    // if (process.env.REACT_APP_USE_OPENWEATHER_API.toUpperCase() === "TRUE") {
    //   const currentLocation = map.getCenter();
    //   const zoomLevel = map.getZoom();
    //   // If within SG disable Open Weather and Open AIP layer
    //   if (
    //     currentLocation.lng < 104.09485833182771 &&
    //     currentLocation.lng > 103.48788722370932 &&
    //     currentLocation.lat < 1.5017122189986765 &&
    //     currentLocation.lat > 1.1997149064574018 &&
    //     zoomLevel > 9
    //   ) {
    //     setLayerVisibility(map.current, "airmap-space-fill", false);
    //     setLayerVisibility(map.current, "precipitation", false);
    //   } else {
    //     setLayerVisibility(
    //       map.current,
    //       "airmap-space-fill",
    //       showConstraintsLayer
    //     );
    //     setLayerVisibility(map.current, "precipitation", showRainMapLayer);
    //     setLayerVisibility(map.current, "rain-map-layer", false);
    //   }
    // } else {
    //   setLayerVisibility(map.current, "precipitation", showRainMapLayer);
    //   setLayerVisibility(
    //     map.current,
    //     "airmap-space-fill",
    //     showConstraintsLayer
    //   );
    // }
  }, [
    showStaticConstraintsLayer,
    showShipLayer,
    showAdsbLayer,
    showConstraintsLayer,
    showRainMapLayer,
    showAirMapSpace,
    showConformanceLayer,
    showOperationVolumeLayer,
    showDroneIconLayer,
    showCollisionLayer,
    showGnssLayer,
    showAnchorageLayer,
    showDronePortLayer,
    showSgBoundaryLayer,
    showSgPopulationDensity,
    showMapElevation,
  ]);

  /**
   * Adds the emergency landing point as a point on Mapbox, updating when the landing point is changed by user
   */
  useEffect(() => {
    if (!map.current._loaded || emergencyLanding?.length === 0) return;
    const updateEmergency = async () => {
      try {
        const data = draw.getAll();
        const points = data.features.filter((f) => f.geometry.type === "Point");
        if (points.length === 0) {
          draw.add({
            type: "Point",
            coordinates: emergencyLanding,
          });
          return;
        }
        const point = points[0];
        point.geometry.coordinates = emergencyLanding;
        draw.add(point);
      } catch (e) {
        console.log("Error : ", e);
      }
    };
    updateEmergency();
  }, [draw, emergencyLanding]);

  /**
   * Adds a route onto Mapbox as a union of line segments, updating when the route is changed by user
   */
  useEffect(() => {
    if (!map.current._loaded || selectedWaypoints?.length === 0) return;
    const updateWaypoints = async () => {
      try {
        const data = draw.getAll();
        const lines = data.features.filter(
          (f) => f.geometry.type === "LineString"
        );
        if (lines.length === 0) {
          draw.add({
            type: "LineString",
            coordinates: selectedWaypoints,
          });
          return;
        }
        const line = lines[0];
        line.geometry.coordinates = selectedWaypoints;
        draw.add(line);
      } catch (e) {
        console.log("Error : ", e);
      }
    };
    updateWaypoints();
  }, [draw, selectedWaypoints]);

  /**
   * Adds an area onto Mapbox as a set of vertices, updating when the area is changed by user
   */
  useEffect(() => {
    if (!map.current._loaded || selectedAreaWaypoints?.length === 0) return;
    // if (typeof draw.getAll !== "function") return;
    const updateAreaWaypoints = async () => {
      try {
        const data = draw.getAll();
        const polygons = data?.features?.filter(
          (f) => f.geometry.type === "Polygon"
        );
        if (polygons.length === 0) {
          draw.add({
            type: "Polygon",
            coordinates: [selectedAreaWaypoints],
          });
          return;
        }
        const polygon = polygons[0];
        polygon.geometry.coordinates = [selectedAreaWaypoints];
        draw.add(polygon);
      } catch (e) {
        console.log("Error : ", e);
      }
    };
    updateAreaWaypoints();
  }, [draw, selectedAreaWaypoints]);

  /**
   * Adds a circle onto Mapbox as a set of vertices, updating when the circle is changed by user
   */
  // useEffect(() => {
  //   if (!map.current || updatedSelectedCircleWaypoints?.length === 0) return;
  //   const updateCircleWaypoints = async () => {
  //     console.log("update Circle Waypoints in MapB");
  //     const data = draw.getall();
  //     const polygons = data.features.filter(
  //       (f) => f.geometry.type === "Polygon"
  //     );
  //     if (polygons.length === 0) {
  //       draw.add({
  //         type: "Feature",
  //         properties: updatedSelectedCircleProperties,
  //         geometry: {
  //           type: "Polygon",
  //           coordinates: [updatedSelectedCircleWaypoints],
  //         },
  //       });
  //       return;
  //     }
  //     const polygon = polygons[0];
  //     polygon.geometry.coordinates = [updatedSelectedCircleWaypoints];
  //     polygon.properties = updatedSelectedCircleProperties;
  //     draw.add(polygon);
  //   };
  //   updateCircleWaypoints();
  // }, [updatedSelectedCircleWaypoints, updatedSelectedCircleProperties, draw]);

  useEffect(() => {
    if (
      !map.current._loaded ||
      selectedCircleWaypoints?.length === 0 ||
      !selectedCircleProperties?.isCircle
    ) {
      return;
    }
    const updateCircleWaypoints = async () => {
      try {
        const data = draw.getAll();
        const polygons = data.features.filter(
          (f) => f.geometry.type === "Polygon"
        );
        if (polygons.length === 0) {
          draw.add({
            type: "Feature",
            properties: selectedCircleProperties,
            geometry: {
              type: "Polygon",
              coordinates: [selectedCircleWaypoints],
            },
          });
          return;
        }
        const polygon = polygons[0];
        polygon.geometry.coordinates = [selectedCircleWaypoints];
        polygon.properties = selectedCircleProperties;
        draw.add(polygon);
      } catch (e) {
        console.log("Error : ", e);
      }
    };
    updateCircleWaypoints();
  }, [selectedCircleWaypoints, selectedCircleProperties, draw]);

  /**
   * Adds AIS data onto Mapbox, updating when AIS data changes
   */
  useEffect(() => {
    if (!map.current) return;
    const updateShipLayer = async () => {
      const layer = map.current.getLayer("ais-layer");
      if (!layer) return;
      await layer.implementation.setData(shipData, modelMap);
    };
    updateShipLayer();
  }, [modelMap, shipData]);

  /**
   * Adds ADSB data onto Mapbox, updating when ADSB data changes
   */
  useEffect(() => {
    if (!map.current) return;
    const updateADSBLayer = async () => {
      const layer = map.current.getLayer("adsb-layer");
      if (!layer) return;
      await layer.implementation.setData(ADSBData, modelMap);
    };
    updateADSBLayer();
  }, [ADSBData, modelMap]);

  /**
   * Adds telemetry and flight data onto Mapbox, updating when either data changes
   */
  useEffect(() => {
    if (!map.current) return;
    const updateOperationLayer = async () => {
      const operationSource = map.current.getSource("operation-coords");
      const trackerLayer = map.current.getLayer("tracker-layer");
      if (!operationSource || !trackerLayer) return;
      operationSource.setData({
        type: "FeatureCollection",
        features: getOperationFeatures(
          selectedFlightData,
          trackerStatusMap,
          trackerOpsMap,
          focussedOperation,
          isRemoteId,
          map.current
        ),
      });
      await trackerLayer.implementation.setData(
        selectedFlightData,
        telemetryData,
        modelMap,
        trackerStatusMap,
        trackerOpsMap,
        api,
        isRemoteId,
        map.current
      );
    };
    updateOperationLayer();
  }, [
    selectedFlightData,
    telemetryData,
    focussedOperation,
    trackerStatusMap,
    trackerOpsMap,
    modelMap,
    api,
  ]);

  // collision listen and handle
  useEffect(() => {
    const handleCollision = (data) => {
      if (!data) return;
      const collisionLayer = map.current.getLayer(`collision-layer`);
      if (!collisionLayer) return;
      const cells = Object.keys(data);
      for (const cell of cells) {
        const details = data[cell];
        collisionDatasRef.current.set(cell, details);
      }
    };
    const collisionListener = channel?.addMessageListener(
      messageTypes.COLLISION,
      handleCollision
    );
    return () => {
      collisionListener?.();
    };
  }, [channel]);

  // collision update and clear
  useEffect(() => {
    const update = setInterval(() => {
      const collisionLayer = map.current.getLayer(`collision-layer`);
      if (!collisionLayer) return;
      const isTerrainEnabled = map.current.getTerrain() !== null;
      const currentTime = Date.now();
      collisionDatasRef.current = new Map(
        [...collisionDatasRef.current].filter(
          // intermittent ws message recieved after slight delay
          // this can cause collision cell to dissappear as currenttime - messagetime > 3000
          // set to 5 seconds
          ([cell, details]) => currentTime - details.time <= 5000
        )
      );
      collisionLayer.implementation.setData(
        collisionDatasRef.current,
        modelMap,
        isTerrainEnabled
      );
    }, 1000);
    return () => {
      clearInterval(update);
    };
  }, []);

  /**
   * Adds airmap space  data onto Mapbox, updating when either data changes
   */
  useEffect(() => {
    if (!map.current) return;
    const updateAirmapSpaceLayer = async () => {
      const airmapSpaceSource = map.current.getSource("airmap-space-coords");
      const trackerLayer = map.current.getLayer("airmap-space-fill");
      if (!airmapSpaceSource || !trackerLayer) return;
      airmapSpaceSource.setData({
        type: "FeatureCollection",
        features: getAirSpaceFetaures(airMapSpaceData),
      });
    };
    updateAirmapSpaceLayer();
  }, [airMapSpaceData]);
  /**
   * Adds dynamic constraints onto Mapbox, updating when dynamic constraints change
   */
  useEffect(() => {
    if (!map.current) return;
    const updateConstraintsLayer = async () => {
      const constraintSource = map.current.getSource("constraints-coords");
      if (!constraintSource) return;
      constraintSource.setData({
        type: "FeatureCollection",
        features: getConstraintsFeatures(constraints, focussedOperation),
      });
    };
    updateConstraintsLayer();
  }, [constraints, focussedOperation]);
  /**
   * Adds dynamic conformance onto Mapbox, updating when dynamic conformance change
   */
  useEffect(() => {
    const updateConformanceLayer = async () => {
      if (!map.current) return;
      const conformanceSource = map.current.getSource("conformances-coords");
      if (!conformanceSource) return;
      const dataConformance = conformanceData;
      // console.log("check conformance data in map", dataConformance);
      const dataConformanceGLs = JSON.parse(dataConformanceGL);
      conformanceSource.setData({
        type: "FeatureCollection",
        features: getConformanceFeatures(dataConformanceGLs, focussedOperation),
      });
    };
    const retriveConformanceInterval = setInterval(() => {
      updateConformanceLayer();
    }, 1000 * 1); // Updates every 1 sec call local storage
    return () => {
      clearInterval(retriveConformanceInterval);
    };
  }, [conformanceData, dataConformanceGL, focussedOperation]);

  /**
   * Handle Telemetry data here for better performance
   */
  useEffect(() => {
    const checkTrackerSerialNumber = (trackerSn) => {
      const dataTracker = [];
      selectedTrackerDataRef.current.map((data, index) => {
        return data.map((datas) => {
          return dataTracker.push(datas);
        });
      });
      const filterDataTracker = dataTracker.filter(function (tracker) {
        return tracker?.data?.tracker_sn === trackerSn;
      });
      return filterDataTracker;
    };
    const handleMessage = (data) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid) => {
          const dataPlatform = [];
          selectedPlatformDataRef.current.map((p, index) => {
            return p.map((datas) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (platform) {
            return platform.data.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        const key = [data.extras.tracker_sn, data.operation_id].join("/");
        if (!keyRef.current[key]) {
          // making sure no duplicate keys in keyRef
          keyRef.current = {
            ...keyRef.current,
            [key]: true,
          };
        }
        // setTeleObj({
        //   ...teleObj,
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerSn: data.extras?.tracker_sn,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.tracker_sn),
                checkTrackerSerialNumber(data.extras.tracker_sn)
                  ? checkTrackerSerialNumber(data.extras.tracker_sn)
                  : [],
              ],
            ],
          },
        };

        // const ttt = Object.keys(teleObj).map((k) => teleObj[k]);
        // setTelemetryData(ttt);
        // console.log("Ttt", ttt);
        // console.log("teleobj", teleObj);
      }
      //   else {
      //  setTelemetryData([]);
      //   }
    };

    // console.log("teleobj", teleObj);
    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      handleMessage
    );

    return () => {
      // clearInterval(interval);
      removeListener?.();
    };
  }, [teleObj, channel]);

  useEffect(() => {
    // This effect runs every 1 second, batches telemetryData for performance
    const intervalId = setInterval(() => {
      // Sort teleObj by timestamp in descending..order simply to remove DUPLICATE KEY DATAS
      const sortedData = Object.keys(teleObjRef.current)
        .map((key) => teleObjRef.current[key])
        .sort((a, b) => b.timestamp - a.timestamp);
      // run through, clear duplicate keys
      const processedData = [];
      const seenKeys = new Set(); // just to get rid of duplicate keys in sortedData purposes

      sortedData.forEach((entry) => {
        const key = [entry.trackerSn, entry.gufi].join("/");
        if (!seenKeys.has(key)) {
          processedData.push(entry);
          seenKeys.add(key);
        }
      });
      // at this point, processedData is the latest Data of SAME and NEW keys
      // we also have keyRef with SAME and NEW keys
      // how to consider trackers that dont send data anymore ie OLD keys?

      // ANS: compare both key objects. 3 kinds of keys, SAME, OLD, NEW. create Temp holders for them?
      // if SAME, replace that in actual teleobj
      // if NEW,  create new entry in ActualKey and ActualTeleObj .
      // if OLD, check timestamp, if older than 5 secs, OLD OLD: delete from actualTeleObj & actualKey
      // if less than 5 secs, do nothing: keep it in actualTeleObjRef and actualKeyRef

      const commonKeys = new Set();
      const newKeysInKeyRef = new Set(Object.keys(keyRef.current));
      const oldKeysInActualKeyRef = new Set(Object.keys(actualKeyRef.current));

      for (const key of newKeysInKeyRef) {
        if (oldKeysInActualKeyRef.has(key)) {
          commonKeys.add(key);
          newKeysInKeyRef.delete(key);
          oldKeysInActualKeyRef.delete(key);
        }
      }
      // 3 separate loops to actually set actualTeleObj for telemetryData
      // for key in SAME keys, replace actualTeleobj with teleObj. check timestamp too.
      // for key in NEW keys, create new entry in actual teleobj and actualKey
      // for key in OLD keys, do nothing. actually no loop here required.
      // for key in OLD OLD keys, delete from actualTeleObj and actualkey

      // this will also contain trackers that do not send anymore telemetry; their previous data (key) still exists in keyRef,
      // just that the previous data is outdated (old). thus timestamp check occurs here as well, to (remove) trackers that stopped

      const commonData = Array.from(commonKeys)
        .filter((key) => {
          // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef
          if (
            Date.now() -
              (actualTeleObjRef.current[key]?.timestamp || Date.now()) >
            20000
          ) {
            delete actualKeyRef.current[key];
            delete actualTeleObjRef.current[key];
            delete keyRef.current[key];
            delete teleObjRef.current[key];
            return false;
          }
          return true;
        })
        .map((key) => actualTeleObjRef.current[key]);
      const newDataInKeyRef = Array.from(newKeysInKeyRef).map(
        (key) => teleObjRef.current[key]
      );

      // this loop does not seem to trigger
      // reason: a key will never be present in oldKeysInActualKeyRef but be present in keyRef;
      // no function exists to delete key from oldKeysInActualKeyRef but not keyRef
      // the idea for this loop is to check for tracker that no longer sends data, but instead it is in commonKeys instead
      const oldDataInActualKeyRef = Array.from(oldKeysInActualKeyRef)
        .filter((key) => {
          // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef
          if (
            Date.now() -
              (actualTeleObjRef.current[key]?.timestamp || Date.now()) >
            20000
          ) {
            delete actualKeyRef.current[key];
            delete actualTeleObjRef.current[key];
            delete keyRef.current[key];
            delete teleObjRef.current[key];
            return false;
          }
          return true; // Include in oldDataInActualKeyRef
        })
        .map((key) => actualTeleObjRef.current[key]);

      // Update actualTeleObjRef with commonData
      commonData.forEach((data) => {
        const key = [data.trackerSn, data.gufi].join("/");
        actualTeleObjRef.current[key] = teleObjRef.current[key];
      });

      // Update actualKeyRef and actualTeleObjRef with newDataInKeyRef
      newDataInKeyRef.forEach((data) => {
        const key = [data.trackerSn, data.gufi].join("/");
        actualKeyRef.current[key] = true;
        actualTeleObjRef.current[key] = teleObjRef.current[key];
      });

      const ttt = Object.keys(actualTeleObjRef.current).map(
        (k) => actualTeleObjRef.current[k]
      );
      // finally Set telemetryData with  actualTeleObj
      // console.log("ttt:", ttt);
      setTelemetryData(ttt);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // useEffect(() => {
  //   setTrackerStatusMap();
  // });

  const getPilotPlatformTrackerList = async () => {
    platformList.current = await api.getPlatforms();
    pilotList.current = await api.getPilots();
    trackerList.current = await api.getTracker();
  };

  useEffect(() => {
    if (!api) return;
    getPilotPlatformTrackerList();
  }, [api]);

  return (
    <div style={{ position: "relative" }}>
      <SnackbarMessage />
      <div ref={mapContainer} style={{ height: "100vh", width: "auto" }} />
      <div
        style={{
          // position: "fixed",
          position: "absolute",
          bottom: 0,
          left: 0,
          // minWidth: "500px",
          // maxWidth: "1000px",
          width: "450px",
          // float: "left",
        }}
      >
        <div
        // style={{
        //   border: 1,
        //   position: "relative",
        //   margin: "0 5px",
        // }}
        >
          <Accordion
            id="weather"
            sx={{
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "33px",
                marginBottom: "3px",
              },
              "& .MuiAccordionDetails-root": {
                padding: "0px",
                margin: "-3px",
                marginBottom: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
              }
              // id="panel2-header"
              sx={{
                minHeight: "28px",
                maxHeight: "28px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                  alignContent: "center",
                  display: "inline",
                }}
              >
                Weather
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {process.env.REACT_APP_USE_OPENWEATHER_API.toUpperCase() ===
              "TRUE" ? (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <WeatherForecast />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <WeatherCurrent />
                  </Grid>
                  <Grid item xs={10}>
                    <WeatherForecast />
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div
        style={{
          // position: "fixed",
          position: "absolute",
          bottom: 0,
          // left: 750,
          right: 0,
          width: "420px",
          // float: "right",
        }}
      >
        <div
          style={
            {
              // border: 1,
              // position: "relative",
              // // margin: "0 5px",
              // left: "-4px",
            }
          }
        >
          <Accordion
            id="overlayManager"
            sx={{
              "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "33px",
              },
              "& .MuiAccordionDetails-root": {
                padding: "0px 2px",
                margin: "-3px",
                marginBottom: "0px",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
              }
              id="panel3-header"
              sx={{ minHeight: "28px", maxHeight: "28px" }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Overlay Manager
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ padding: "5px" }}>
                <OverlayManager
                  toggleShowShipLayer={toggleShowShipLayer}
                  toggleShowAdsbLayer={toggleShowAdsbLayer}
                  toggleShowConstraintsLayer={toggleShowConstraintsLayer}
                  toggleShowStaticConstraintsLayer={
                    toggleShowStaticConstraintsLayer
                  }
                  toggleShowBuildingLayer={toggleShowBuildingLayer}
                  toggleShowOperationVolumeLayer={
                    toggleShowOperationVolumeLayer
                  }
                  togglesshowDroneIconLayer={togglesshowDroneIconLayer}
                  toggleShowRainMapLayer={toggleShowRainMapLayer}
                  togglesshowAirMapSpace={togglesshowAirMapSpace}
                  togglesshowGnssLayer={togglesshowGnssLayer}
                  toggleShowAnchorageLayer={toggleShowAnchorageLayer}
                  toggleShowDronePortLayer={toggleShowDronePortLayer}
                  isRemoteId={isRemoteId}
                  toggleSgBoundaryLayer={toggleSgBoundaryLayer}
                  toggleSgPopulationDensity={toggleSgPopulationDensity}
                  toggleMapElevation={toggleMapElevation}
                  toggleShowCollisionLayer={toggleShowCollisionLayer}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
