import React from "react";
import { makeStyles } from "@material-ui/core";
import { useApi } from "../../api/useApi";

const divDetails = makeStyles((theme) => ({
  multiDetails: {
    paddingTop: 1,
    paddingBottom: 2,
    margin: 1,
  },
}));

export default function DetailsPilots(props) {
  const classesDetails = divDetails();
  const [selectedData, setSelectedData] = React.useState([]);
  const api = useApi();
  const PilotUuid = props.pilot_uuid;
  const getData = async () =>
    Promise.all(
      PilotUuid.map(async (uuid, index) => {
        if (!uuid) return undefined;
        const PilotsData = await api.getPilot(uuid);
        return PilotsData;
      })
    );

  React.useEffect(() => {
    getData().then(function (result) {
      setSelectedData(result);
    });
  }, []);
  return (
    <div>
      {selectedData.length > 0 &&
        selectedData.map((pilotData) => {
          return (
            <p
              //  key={pilotData.data.pilot_uuid.toString()}
              className={classesDetails.multiDetails}
            >
              {pilotData.data.pilot_name ? pilotData.data.pilot_name : "-"}
            </p>
          );
        })}
    </div>
  );
}
