import circle from "@turf/circle";
import area from "@turf/area";
import turfPolygon from "turf-polygon";

function calculatePolygonFloorArea(coordinates) {
  if (coordinates.length < 4) {
    return;
  }

  try {
    const polygon = turfPolygon([coordinates]);
    const areaSQM = area(polygon);
    return areaSQM;
  } catch (err) {
    console.log("turfPolygon error: ", err);
    return null;
  }
}

export { calculatePolygonFloorArea };
