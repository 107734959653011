import React from "react";
import Plot from "react-plotly.js";
import Paper from "material-ui/Paper";
import { makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    position: "relative",
    border: "1px solid #000",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2),
    width: 380,
    height: 320,
    textAlign: "left",
  },
}));

export function PieChart(props) {
  const [expandCard, setExpandCard] = React.useState(false);
  const { chartData, chartName = "", showLegend = false } = props;
  const classes = useStyles();

  const handleOpenCard = (e) => {
    setExpandCard((s) => !s);
  };

  return (
    <Paper className={classes.card} onClick={(e) => handleOpenCard(e)}>
      {chartData && (
        <Plot
          data={[
            {
              values: Object.values(chartData),
              labels: Object.keys(chartData),
              domain: { x: [0, 0.5], y: [0, 1] },
              name: `${chartName} Data`,
              hoverinfo: "label+value+percent",
              // hovertemplate:
              //   "<b>%{label}</b><br><br>" +
              //   "Count: %{value}<br>" +
              //   "Percentage: %{percent}<br><extra></extra>",
              hole: 0.4,
              type: "pie",
            },
          ]}
          layout={{
            width: 350,
            height: 300,
            title: `${chartName}`,
            showlegend: showLegend,
            font: {
              family: "Roboto, sans-serif", // Specify Roboto font
            },
          }}
          config={{
            displayModeBar: false, // Set to false to hide the toolbar
          }}
        />
      )}
      <Dialog maxWidth="md" open={expandCard}>
        {chartData && (
          <Plot
            data={[
              {
                values: Object.values(chartData),
                labels: Object.keys(chartData),
                domain: { x: [0, 0.5], y: [0, 1] },
                name: `${chartName} Data`,
                hoverinfo: "label+value+percent",
                hole: 0.4,
                type: "pie",
              },
            ]}
            layout={{
              title: `${chartName}`,
              showlegend: showLegend,
              font: {
                family: "Roboto, sans-serif", // Specify Roboto font
              },
            }}
            config={{
              displayModeBar: false, // Set to false to hide the toolbar
            }}
          />
        )}
      </Dialog>
    </Paper>
  );
}

export function BarChart(props) {
  const [expandCard, setExpandCard] = React.useState(false);
  const { chartData, chartName = "", showLegend = false } = props;
  const handleOpenCard = (e) => {
    setExpandCard((s) => !s);
  };
  const classes = useStyles();
  return (
    <Paper className={classes.card} onClick={(e) => handleOpenCard(e)}>
      {chartData && (
        <Plot
          data={[
            {
              x: Object.keys(chartData),
              y: Object.values(chartData),
              type: "bar",
            },
          ]}
          layout={{
            width: 350,
            height: 300,
            title: `${chartName}`,
            showlegend: showLegend,
            font: {
              family: "Roboto, sans-serif", // Specify Roboto font
            },
            yaxis: {
              ticksuffix: "hours",
            },
          }}
          config={{
            displayModeBar: false, // Set to false to hide the toolbar
          }}
        />
      )}
      <Dialog maxWidth="md" open={expandCard}>
        {chartData && (
          <Plot
            data={[
              {
                x: Object.keys(chartData),
                y: Object.values(chartData),
                type: "bar",
              },
            ]}
            layout={{
              title: `${chartName}`,
              showlegend: showLegend,
              font: {
                family: "Roboto, sans-serif", // Specify Roboto font
              },
              yaxis: {
                ticksuffix: "hours",
              },
            }}
            config={{
              displayModeBar: false, // Set to false to hide the toolbar
            }}
          />
        )}
      </Dialog>
    </Paper>
  );
}

export function StackedBarChart(props) {
  const [expandCard, setExpandCard] = React.useState(false);
  const { chartData, chartName = "", showLegend = false } = props;
  const handleOpenCard = (e) => {
    setExpandCard((s) => !s);
  };
  const classes = useStyles();
  return (
    <Paper className={classes.card} onClick={(e) => handleOpenCard(e)}>
      {chartData && (
        <Plot
          data={Object.keys(chartData[Object.keys(chartData)[0]]).map(
            (singleChartValue) => {
              return {
                x: Object.keys(chartData),
                y: Object.keys(chartData).map((singleOperator) => {
                  return chartData[singleOperator][singleChartValue];
                }),
                type: "bar",
                name: singleChartValue,
                base: 0,
              };
            }
          )}
          layout={{
            barmode: "stack",
            width: 350,
            height: 300,
            title: `${chartName}`,
            showlegend: showLegend,
            font: {
              family: "Roboto, sans-serif", // Specify Roboto font
            },
            yaxis: {
              ticksuffix: "hours",
            },
          }}
          config={{
            displayModeBar: false, // Set to false to hide the toolbar
          }}
        />
      )}
      <Dialog maxWidth="md" open={expandCard}>
        {chartData && (
          <Plot
            data={Object.keys(chartData[Object.keys(chartData)[0]]).map(
              (singleChartValue) => {
                return {
                  x: Object.keys(chartData),
                  y: Object.keys(chartData).map((singleOperator) => {
                    return chartData[singleOperator][singleChartValue];
                  }),
                  type: "bar",
                  name: singleChartValue,
                  base: 0,
                };
              }
            )}
            layout={{
              barmode: "stack",
              title: `${chartName}`,
              showlegend: showLegend,
              font: {
                family: "Roboto, sans-serif", // Specify Roboto font
              },
              yaxis: {
                ticksuffix: "hours",
              },
            }}
            config={{
              displayModeBar: false, // Set to false to hide the toolbar
            }}
          />
        )}
      </Dialog>
    </Paper>
  );
}

export function CombinedBarChart(props) {
  const [expandCard, setExpandCard] = React.useState(false);
  const { chartData, chartName = "", showLegend = false } = props;
  const handleOpenCard = (e) => {
    setExpandCard((s) => !s);
  };
  const classes = useStyles();
  const xValues = Object.keys(chartData).reduce(
    (accumulator, singleOperator) => {
      return [...accumulator, ...Object.keys(chartData[singleOperator])];
    },
    []
  );
  return (
    <Paper className={classes.card} onClick={(e) => handleOpenCard(e)}>
      {chartData && (
        <Plot
          data={Object.keys(chartData).map((singleOperator) => {
            return {
              x: Object.keys(chartData[singleOperator]),
              y: Object.values(chartData[singleOperator]),
              type: "bar",
              name: singleOperator,
            };
          })}
          layout={{
            barmode: "group",
            width: 350,
            height: 300,
            title: `${chartName}`,
            showlegend: showLegend,
            font: {
              family: "Roboto, sans-serif", // Specify Roboto font
            },
            yaxis: {
              ticksuffix: "hours",
            },
          }}
          config={{
            displayModeBar: false, // Set to false to hide the toolbar
          }}
        />
      )}
      <Dialog maxWidth="md" open={expandCard}>
        {chartData && (
          <Plot
            data={Object.keys(chartData).map((singleOperator) => {
              return {
                x: Object.keys(chartData[singleOperator]),
                y: Object.values(chartData[singleOperator]),
                type: "bar",
                name: singleOperator,
              };
            })}
            layout={{
              barmode: "group",
              title: `${chartName}`,
              showlegend: showLegend,
              font: {
                family: "Roboto, sans-serif", // Specify Roboto font
              },
              yaxis: {
                ticksuffix: "hours",
              },
            }}
            config={{
              displayModeBar: false, // Set to false to hide the toolbar
            }}
          />
        )}
      </Dialog>
    </Paper>
  );
}
