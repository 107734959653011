import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl-style-switcher/styles.css";
import "../MapB/custom-mapbox.css";
import "../MapB/windmarker/windmarker.css";
import "../MapB/windmarker/fonts/fonts.css";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { useApi } from "../../api/useApi";
import { ModelLayer } from "../MapB/model-layer";
import PuckServer from "../../services/puckserver";
import { setSnackbarMessage } from "../../store/actions";
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import {
  load3dModels,
  addTerrainSource,
  addNoFlyLayers,
  addNoFlySources,
  addBuildingLayer,
} from "../MapB/layers";

/**
 * Converts angle from degrees to radians
 * @param {Number} degrees Angle in degrees
 * @returns Angle in radians
 */
function degToRad(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}
const formatDate = (date) => {
  let d = new Date(date);
  const timezoneoffset = `${d.getTimezoneOffset() * 60 * 1000}`;
  d = new Date(d.valueOf() - timezoneoffset);
  return [`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`];
};

/**
 * Get the earliest and latest timestamps from an array of responses.
 * @param {Array} responses Array of response arrays, each containing objects with a timestamp property.
 * @returns {Object} Object with `earliest` and `latest` properties representing the earliest and latest timestamps.
 */
function getEarliestAndLatestTimestamps(responses) {
  if (!responses || responses.length === 0) {
    return { earliest: null, latest: null };
  }

  let earliestTimestamp = null;
  let latestTimestamp = null;

  responses.forEach((response) => {
    response.forEach((entry) => {
      const responseTimestamp = entry.timestamp;

      if (responseTimestamp) {
        if (
          earliestTimestamp === null ||
          responseTimestamp < earliestTimestamp
        ) {
          earliestTimestamp = responseTimestamp;
        }

        if (latestTimestamp === null || responseTimestamp > latestTimestamp) {
          latestTimestamp = responseTimestamp;
        }
      }
    });
  });

  return { earliest: earliestTimestamp, latest: latestTimestamp };
}

/**
 * Component encapsulating MapBox map
 * @param {*} props Props to pass in for the MapB component
 * @returns Component that displays MapBox map
 */
export default function FrameRenderer(props) {
  const {
    selectedFlights,
    mapHeight = document.documentElement.clientHeight - 300, // Reduce map height to accomodate header
    toggleShowBuildingLayer,
  } = props;

  const envVar = useSelector((state) => state.envVar);
  const mapBoxToken = envVar["api_key-mapbox"].Value;
  mapboxgl.accessToken = mapBoxToken;

  // Split Authority & Operator Function due to Authority's Nested Array
  // For Loop for single OperationID with multiple Trackers
  const trackerIds = [];
  const operationIds = [];

  const { isAuthority } = useCognitoAuth();
  let i = 0;
  let j = 0;
  let d = 0;
  let h = 0;

  if (isAuthority) {
    for (let w = 0; w < selectedFlights[1].length; w += 1) {
      i = selectedFlights[0].length;
      j = selectedFlights[1].length;
      if (i === j) {
        d = selectedFlights[0][w].length;
        h = selectedFlights[1][w].length;
        if (d === h) {
          const OperationId = selectedFlights[0][w];
          operationIds.push(OperationId);
          const TrackerId = selectedFlights[1][w][0];
          trackerIds.push(TrackerId);
        } else {
          for (let r = 0; r < h; r += 1) {
            const TrackerId = selectedFlights[1][w][r];
            trackerIds.push(TrackerId);
            const OperationId = selectedFlights[0][w];
            operationIds.push(OperationId);
          }
        }
      }
    }
  } else {
    for (let w = 0; w < selectedFlights[1].length; w += 1) {
      i = selectedFlights[0].length;
      j = selectedFlights[1].length;
      if (i === j) {
        d = selectedFlights[0][w].length;
        h = selectedFlights[1][w].length;
        if (d === h) {
          const OperationId = selectedFlights[0][w];
          operationIds.push(OperationId);
          const TrackerId = selectedFlights[1][w];
          trackerIds.push(TrackerId);
        } else {
          // Check Operator side again
          for (let r = 0; r < h; r += 1) {
            const TrackerId = selectedFlights[1][w][r];
            trackerIds.push(TrackerId);
            const OperationId = selectedFlights[0][w];
            operationIds.push(OperationId);
          }
        }
      }
    }
  }
  const dispatch = useDispatch();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(103.8167107035549);
  const [lat, setLat] = useState(1.366701902383488);
  const [modelMap, setModelMap] = useState(new Map());
  const [currentMapStyle, setCurrentMapStyle] = useState(
    "mapbox://styles/mapbox/dark-v11"
  );
  const [style, setStyle] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [telemetryData, setTelemetryData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [startConstraintsTime, setStartConstraintsTime] = useState(0);
  const [endConstraintsTime, setEndConstraintsTime] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [recordDuration, setRecordDuration] = useState(0);
  const [intervalID, setIntervalID] = useState(null);
  const [AllResponses, setAllResponse] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const status = useRef(null);
  const [k, setK] = useState(0);
  const [eventState, setEventState] = useState([]);
  const api = useApi();

  const getTelemetryAndStates = async (tracker, operation) => {
    try {
      let state;
      if (isAuthority) {
        state = await api.getFlightStateData(operation);
        if (state.data.length > 0) {
          setEventState((prevEventState) => [...prevEventState, state.data]);
        } else {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: `Selected operation does not have any Tracker's data`,
              severity: "error",
            })
          );
          setLimitExceeded(true);
          return;
        }
      } else {
        state = await api.getOperationLogState(operation);
        if (state.data.length > 0) {
          setEventState((prevEventState) => [...prevEventState, state.data]);
        } else {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: `Selected operation does not have any Tracker's data`,
              severity: "error",
            })
          );
          setLimitExceeded(true);
          return;
        }
      }
      let data;
      if (isAuthority) {
        data = await api.getOperationData({ operationID: operation });
        setFlightData((prevFlightData) => [...prevFlightData, data.data]);
      } else {
        data = await api.getOperationData({ operationID: operation });
        setFlightData((prevFlightData) => [...prevFlightData, data.data]);
      }

      const [puckErr, puckResponse] = await PuckServer.getTrackerData(
        tracker,
        operation
      );
      if (puckResponse.length === 0 || puckResponse.error) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: `Selected operation does not have any Tracker's data`,
            severity: "error",
          })
        );
        setLimitExceeded(true);
      } else {
        setAllResponse((prevResponse) => [
          ...prevResponse,
          puckResponse.reverse(),
        ]);
      }
    } catch (error) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Selected operation does not have any Tracker's data`,
          severity: "error",
        })
      );
      setLimitExceeded(true);
    }
  };
  /**
   * Fill gaps in responses with fake data based on a reference array of timestamps.
   * @param {Array} referenceArray Array of timestamps to use as a reference.
   * @param {Array} responses Array of response arrays, each containing objects with a timestamp property.
   * @returns {Array} Filled responses.
   */
  const fillGapsInResponses = (referenceArray, responses) => {
    const filledResponses = responses.map((response) => {
      const responseTimestamps = new Map();
      if (response.length === 0) {
        setLat(1.366701902383488);
        setLng(103.8167107035549);
      } else {
        setLat(response[0].position.lat);
        setLng(response[0].position.lng);
      }
      let previousEntry = null;

      response.forEach((entry) => {
        const timestamp = new Date(entry.timestamp).toISOString().slice(0, -5);
        responseTimestamps.set(timestamp, entry);
      });

      const filledArray = referenceArray.map((timestamp) => {
        const isoTimestamp = new Date(timestamp).toISOString().slice(0, -5);
        const actualData = responseTimestamps.get(isoTimestamp);
        const fakeData = createFakeData(timestamp);
        const lastArrayTime = new Date(response[response.length - 1].timestamp)
          .toISOString()
          .slice(0, -5);

        if (actualData) {
          // Save the previous entry for reference
          previousEntry = actualData;
          return actualData;
        }
        if (isoTimestamp === lastArrayTime) {
          return fakeData;
        }
        // If there's no actual data and no match with the last response, use the previous entry or fake data
        return previousEntry
          ? { ...previousEntry, timestamp: fakeData.timestamp }
          : fakeData;
      });
      setRawData(filledArray);
      return filledArray;
    });
    setAllData(filledResponses);
    return filledResponses;
  };

  // Function to create fake data (adjust as needed)
  const createFakeData = (timestamp) => ({
    position: { lat: 0, lng: 0, alt: 0 },
    track: 0,
    timestamp: timestamp || "fake_timestamp",
  });

  useEffect(() => {
    const { earliest, latest } = getEarliestAndLatestTimestamps(AllResponses);
    // Check if the time difference exceeds 12 hours
    if (earliest && latest) {
      const timeDifference = Math.abs(new Date(latest) - new Date(earliest));
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      setEndConstraintsTime(new Date(latest).getTime());
      setStartConstraintsTime(new Date(earliest).getTime());

      if (hoursDifference > 12) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Selected flights exceed the time limit (12 hours).",
            severity: "error",
          })
        );
        setLimitExceeded(true);
      } else {
        const referenceArray = [];
        const currentTimestamp = new Date(earliest);

        while (currentTimestamp <= new Date(latest)) {
          referenceArray.push(currentTimestamp.toISOString());
          currentTimestamp.setSeconds(currentTimestamp.getSeconds() + 1);
        }

        // Add 20 additional timestamps outside the while loop (ALLOW TIME TO CHANGE TO "ENDED STATE")
        for (let m = 0; m < 20; m += 1) {
          currentTimestamp.setSeconds(currentTimestamp.getSeconds() + 1);
          referenceArray.push(currentTimestamp.toISOString());
        }
        setRecordDuration(referenceArray.length);
        const filledResponses = fillGapsInResponses(
          referenceArray,
          AllResponses,
          eventState
        );
      }
    }
  }, [AllResponses, recordDuration]);

  // Retrieve the list of constraints
  async function addConstraintsLayer(currMap) {
    try {
      const res1Extents = [];
      const res2Extents = [];

      // List of Constraint based on Start of Operation Time to Present/Future
      const res1 = await api.getConstraints(startConstraintsTime);
      res1.data.forEach((item) => {
        if (Array.isArray(item.extents)) {
          res1Extents.push(item.extents);
        }
      });
      // List of Constraint based on End of Operation Time to Present/Future
      const res2 = await api.getConstraints(endConstraintsTime);
      res2.data.forEach((item) => {
        if (Array.isArray(item.extents)) {
          res2Extents.push(item.extents);
        }
      });
      // List of Constraint that are Present/Future
      const overlappingConstraints = res1Extents.filter((extent) =>
        res2Extents.some(
          (extent2) => JSON.stringify(extent) === JSON.stringify(extent2)
        )
      );

      // List of Constraint that are present during the Operation Duration
      const nonOverlappingConstraints = [
        ...res1Extents.filter(
          (extent) =>
            !res2Extents.some(
              (extent2) => JSON.stringify(extent) === JSON.stringify(extent2)
            )
        ),
        ...res2Extents.filter(
          (extent2) =>
            !res1Extents.some(
              (extent) => JSON.stringify(extent2) === JSON.stringify(extent)
            )
        ),
      ];

      //  Change to Constraint Function (Able to retrieve constraint based on Start and End Time) in Future
      //  Change away nonOverlapping Constraints and able to remove the code above that retrieve constraints
      const constraintVolume = nonOverlappingConstraints
        .filter((entry) => Array.isArray(entry))
        .map((entry) =>
          entry.map((row) => ({
            type: "Feature",
            properties: {
              height: row.volume.altitude_upper.value,
              base: row.volume.altitude_lower.value,
              color: "#FF0000",
            },
            geometry: row.volume.outline_polygon,
          }))
        )
        .flat();

      const constraintVolSource = currMap.getSource(`constraintVolSource`);

      if (constraintVolSource) {
        constraintVolSource.setData({
          type: "FeatureCollection",
          features: constraintVolume,
        });
      } else {
        currMap.addSource(`constraintVolSource`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: constraintVolume,
          },
        });

        currMap.addLayer({
          id: `Constraint-Volume`,
          type: "fill-extrusion",
          source: `constraintVolSource`,
          paint: {
            "fill-extrusion-color": "#FF0000",
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": ["get", "base"],
            "fill-extrusion-opacity": 0.2,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Call the function with the current map

  async function convertTrackerData(trackerData, modelM) {
    const output = trackerData.flatMap((data) => {
      return data.map(async (t) => {
        const positionLng = t?.position.lng ?? 0;
        const positionLat = t?.position.lat ?? 0;
        const color = t?.operation_state;

        if (color === "Activated") {
          status.current = "ActivatedArrowModel";
        } else if (color === "Contingent") {
          status.current = "ContingentArrowModel";
        } else if (color === "Nonconforming") {
          status.current = "NonconformingArrowModel";
        } else if (color === "Ended") {
          status.current = "EndedArrowModel";
        }
        const model = modelM.get(status.current);
        const track = t?.track;
        const alt = t?.position.alt;
        const time = t?.timestamp;
        return {
          model,
          fixedSize: true,
          maxZoomScale: 0.15,
          scale: [0.0000013, 0.0000013, 0.0000013],
          rotate: [degToRad(90), degToRad(180 + track), 0],
          position: {
            lng: positionLng,
            lat: positionLat,
          },
          altitude: Number(alt),
          currentTime: formatDate(time),
          color,
        };
      });
    });
    const outputData = await Promise.all(output);
    return outputData;
  }

  function addTrackerLayer(currMap) {
    if (currMap.getLayer("tracker-layer")) return;
    const trackerLayer = new ModelLayer("tracker-layer", convertTrackerData);
    currMap.addLayer(trackerLayer);
  }

  function handleOnFocus() {
    map.current.flyTo({
      center: [lng, lat],
      zoom: 11,
    });
  }

  function handlePlay() {
    setIsPlaying(!isPlaying);
  }
  const handleX2 = (e) => {
    e.preventDefault();
    setSpeed(2);
  };
  const handleX4 = (e) => {
    e.preventDefault();
    setSpeed(4);
  };
  const handleX6 = (e) => {
    e.preventDefault();
    setSpeed(6);
  };
  const handleX10 = (e) => {
    e.preventDefault();
    setSpeed(10);
  };
  const handleX1 = (e) => {
    e.preventDefault();
    setSpeed(1);
  };

  function mapOperationStateToAllData(
    inputAllData,
    inputEventState,
    inputAllResponses
  ) {
    if (!inputAllData || !inputEventState) {
      return inputAllData;
    }

    const editAllData = inputAllData.map((response) =>
      response.map((entry) => ({ ...entry }))
    );
    const editEventState = inputEventState.map((array) =>
      array.map((event) => ({ ...event }))
    );

    const editAllResponses = inputAllResponses.map((operation) =>
      operation.map((flight) => ({ ...flight }))
    );

    editAllData.forEach((response, responseIndex) => {
      if (!response || !response.length) {
        return;
      }
      const eventStateArray = editEventState[responseIndex];
      const allResponsesArray = editAllResponses[responseIndex];

      let previousOperationState = eventStateArray[0]?.operation_state;

      response.forEach((entry, entryIndex) => {
        const entryTimestamp = new Date(entry.timestamp)
          .toISOString()
          .slice(0, -5);
        const lastEventTimestamp = new Date(
          parseInt(eventStateArray[eventStateArray.length - 1].event_time, 10)
        );
        const formattedLastEventTime = lastEventTimestamp
          .toISOString()
          .slice(0, -5);
        const responseLastTimeStamp = new Date(
          allResponsesArray[allResponsesArray.length - 1].timestamp
        )
          .toISOString()
          .slice(0, -5);
        const matchingEvent = eventStateArray.find((event) => {
          const eventTime = new Date(parseInt(event.event_time, 10));
          const formattedEventTime = eventTime.toISOString().slice(0, -5);
          return formattedEventTime === entryTimestamp;
        });
        const operationState = matchingEvent
          ? matchingEvent.operation_state
          : previousOperationState;

        editAllData[responseIndex][entryIndex].operation_state = operationState;

        // SET "ENDED BASED ON TRACKER DATA INSTEAD OF STATE"
        if (entryTimestamp > responseLastTimeStamp) {
          previousOperationState = "Ended";
        } else {
          previousOperationState = operationState;
        }
      });
    });

    return editAllData;
  }

  const updatedAllData = mapOperationStateToAllData(
    allData,
    eventState,
    AllResponses
  );

  const styles = [
    {
      title: "Satellite",
      uri: "mapbox://styles/mapbox/satellite-streets-v12",
      onClick: () =>
        setCurrentMapStyle("mapbox://styles/mapbox/satellite-streets-v12"),
    },
    {
      title: "Street",
      uri: "mapbox://styles/mapbox/streets-v12",
      onClick: () => setCurrentMapStyle("mapbox://styles/mapbox/streets-v12"),
    },
    {
      title: "Outdoors",
      uri: "mapbox://styles/mapbox/outdoors-v12",
      onClick: () => setCurrentMapStyle("mapbox://styles/mapbox/outdoors-v12"),
    },
    {
      title: "Light",
      uri: "mapbox://styles/mapbox/light-v11",
      onClick: () => setCurrentMapStyle("mapbox://styles/mapbox/light-v11"),
    },
    {
      title: "Dark",
      uri: "mapbox://styles/mapbox/dark-v11",
      onClick: () => setCurrentMapStyle("mapbox://styles/mapbox/dark-v11"),
    },
  ];

  const mapOperationStateToColor = (operationState) => {
    switch (operationState) {
      case "Activated":
        return "#00FF00";
      case "Contingent":
        return "#FF0000";
      case "Nonconforming":
        return "#EE9F18";
      case "Ended":
        return "#696969";
      default:
        return "#696969";
    }
  };

  useEffect(() => {
    if (!flightData || flightData.length === 0 || !map.current) {
      return;
    }

    try {
      flightData.forEach((flight, index) => {
        if (
          !flight ||
          !flight.operation_json ||
          !flight.operation_json.details ||
          !flight.operation_json.details.operation_volumes
        ) {
          console.error(
            `Invalid flight data structure at index ${index}. Unable to retrieve operation volumes.`
          );
          return;
        }

        const operationVolume =
          flight.operation_json.details.operation_volumes.map((entry) => {
            return {
              type: "Feature",
              properties: {
                height: entry.volume.altitude_upper.value,
                base: entry.volume.altitude_lower.value,
                color: mapOperationStateToColor(
                  telemetryData[index]?.[0]?.operation_state
                ),
              },
              geometry: entry.volume.outline_polygon,
            };
          });

        const trackerPathSource = map.current.getSource(
          `Tracker-Path-Area-${index}`
        );

        if (trackerPathSource) {
          trackerPathSource.setData({
            type: "FeatureCollection",
            features: operationVolume,
          });
        } else {
          map.current.addSource(`Tracker-Path-Area-${index}`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: operationVolume,
            },
          });

          map.current.addLayer({
            id: `Tracker-Path-${index}`,
            type: "fill-extrusion",
            source: `Tracker-Path-Area-${index}`,
            paint: {
              "fill-extrusion-color": ["get", "color"],
              "fill-extrusion-height": ["get", "height"],
              "fill-extrusion-base": ["get", "base"],
              "fill-extrusion-opacity": 0.2,
            },
          });
          // addTerrainSource(map.current, true);
          addBuildingLayer(map.current);
          addNoFlySources(map.current);
          addNoFlyLayers(map.current, true);
          addConstraintsLayer(map.current);
          addTrackerLayer(map.current);
        }
      });
    } catch (error) {
      console.error("Error processing flight data:", error);
    }
  }, [flightData, telemetryData]);

  useEffect(() => {
    if (!map.current) return;
    const trackerLayer = map.current.getLayer("tracker-layer");
    if (trackerLayer) {
      trackerLayer.implementation.setData(telemetryData, modelMap);
    }
  }, [telemetryData, modelMap]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: currentMapStyle,
      center: [lng, lat],
      zoom: 11,
    });

    map.current.on("load", async () => {
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          trackUserLocation: true,
        })
      );

      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        }),
        "top-right"
      );

      map.current.addControl(
        new MapboxStyleSwitcherControl(styles),
        "top-right"
      );
      load3dModels(modelMap);
      addBuildingLayer(map.current);
      addTrackerLayer(map.current);
      // addTerrainSource(map.current, true);
      addNoFlySources(map.current);
      addNoFlyLayers(map.current, true);
      addConstraintsLayer(map.current);

      for (let n = 0; n < trackerIds.length; n += 1) {
        getTelemetryAndStates(trackerIds[n], operationIds[n]);
      }
    });
  }, [lng, lat, currentMapStyle]);

  useEffect(() => {
    const updateConvertedData = async () => {
      if (telemetryData && modelMap) {
        const data = await convertTrackerData(telemetryData, modelMap);
      }
    };

    updateConvertedData();
  }, [telemetryData, modelMap]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const allTeleData = updatedAllData.map((data) => [data[currentIndex]]);
      setTelemetryData(allTeleData);
      setCurrentIndex((prevIndex) => prevIndex + speed);
    }, 1000);

    setIntervalID(intervalId);

    // Clear the interval when currentIndex exceeds recordDuration or isPlaying is false
    if (currentIndex > recordDuration || !isPlaying) {
      clearInterval(intervalId);
      setTimeout(() => {
        setCurrentIndex(0);
      }, 10000);
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, recordDuration, isPlaying]);

  /**
   * Converts total seconds to minutes and seconds
   * @param {Number} x time in seconds
   * @returns time in minutes and seconds
   */
  const valueLabelFormat = (value) => {
    if (rawData && rawData.length > 0) {
      const currentIndexTimestamp = rawData[value]?.timestamp;
      if (currentIndexTimestamp) {
        return formatDate(currentIndexTimestamp);
      }
    }
    return "";
  };

  const onChangeSlider = (event, value) => {
    handleSliderChange(value);
  };

  /**
   * Handles moving the playback slider/ progress bar
   * @param {*} event The event registered by the callback
   * @param {*} value the new time on the slider bar
   */

  const handleSliderChange = (newIndex) => {
    const newIndexTimestamp = rawData[newIndex]?.timestamp;
    if (newIndexTimestamp) {
      setCurrentIndex(newIndex);
    }
    setIsPlaying(isPlaying);
    setK(0);
  };

  return (
    <Grid>
      <LoadingOverlay>
        <div ref={mapContainer} style={{ height: mapHeight, width: "100%" }} />
      </LoadingOverlay>
      <Slider
        autoFocus
        size="small"
        min={0}
        max={recordDuration}
        onChange={onChangeSlider}
        value={currentIndex}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
      />
      <Button onClick={handleX1} color="primary" disabled={!isPlaying}>
        Default Speed
      </Button>
      <Button onClick={handleX2} color="primary" disabled={!isPlaying}>
        X2
      </Button>
      <Button onClick={handleX4} color="primary" disabled={!isPlaying}>
        X4
      </Button>
      <Button onClick={handleX6} color="primary" disabled={!isPlaying}>
        X6
      </Button>
      <Button onClick={handleX10} color="primary" disabled={!isPlaying}>
        X10
      </Button>
      <Button
        size="small"
        style={{ marginTop: "5px", marginBottom: "5px", width: "10%" }}
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleOnFocus();
          handlePlay();
        }}
        disabled={limitExceeded}
      >
        {isPlaying ? "Pause" : "Play"}
      </Button>
    </Grid>
  );
}
