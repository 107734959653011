import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import CreateConstraintAuthority from "../CreateConstraintAuthority";
import UpcomingConstraintsAuthority from "../UpcomingConstraintsAuthority";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function ConstrainRules(index) {
  return <Typography>Coming Soon</Typography>;
}
function ConstraintMgrAuthorityComponent(props) {
  const {
    selectedFlightData,
    constraints,
    editConstraints,
    seteditConstraints,
    // onEmergencyLandingUpdateMove,
    emergencyLanding,
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    selectedCircleProperties,
    setSelectedCircleProperties,
    handleOpenConstrainMgrAuthority,
    openConstrainMgrAuthority,
    // deprecated :
    onUpdateSelectedCircleWaypointsMove,
    onUpdateSelectedCirclePropertiesMove,
    onUpdateSelectedAreaWaypointsMove,
    onUpdateSelectedWaypointsMove,
  } = props;
  // const [mapViewController, setMapViewController] = useState();

  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  const [value, setValue] = useState(0);
  const editConstraintsData = useSelector((state) => state.constraints);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    editConstraintsData.isEdit ? setValue(0) : setValue(0);
  }, [editConstraintsData]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // width: "100vw",
        overflow: "auto",
      }}
    >
      <div style={{ width: 600, overflow: "auto" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab label="Create New" {...a11yProps(0)} />
            <Tab label="Constraint List" {...a11yProps(1)} />
            <Tab label="Constraint Rules" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <CreateConstraintAuthority
            // mapViewController={mapViewController}

            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            emergencyLanding={emergencyLanding}
            /* circle */
            selectedCircleWaypoints={selectedCircleWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            setSelectedCircleProperties={setSelectedCircleProperties}
            //
            editConstraints={editConstraints}
            handleOpenConstrainMgrAuthority={handleOpenConstrainMgrAuthority}
            openConstrainMgrAuthority={openConstrainMgrAuthority}
            //
            onUpdateSelectedWaypoints={onUpdateSelectedWaypointsMove}
            onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypointsMove}
            // onEmergencyLandingUpdate={onEmergencyLandingUpdateMove}
            onUpdateSelectedCircleWaypoints={
              onUpdateSelectedCircleWaypointsMove
            }
            onUpdateSelectedCircleProperties={
              onUpdateSelectedCirclePropertiesMove
            }
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UpcomingConstraintsAuthority
            constraints={constraints}
            seteditConstraints={seteditConstraints}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConstrainRules />
        </TabPanel>
      </div>
    </div>
  );
}

export default ConstraintMgrAuthorityComponent;
