import React from "react";
import { makeStyles } from "@material-ui/core";
import { useApi } from "../../api/useApi";

const divDetails = makeStyles((theme) => ({
  multiDetails: {
    paddingTop: 1,
    paddingBottom: 2,
    margin: 1,
  },
}));
export default function DetailsPaltforms(props) {
  const classesDetails = divDetails();
  const [selectedData, setSelectedData] = React.useState([]);
  const api = useApi();
  const playformUuid = props.platform_uuid;
  const getData = async () =>
    Promise.all(
      playformUuid.map(async (uuid, index) => {
        if (!uuid) return undefined;
        const paltformData = await api.getPlatform(uuid);
        return paltformData;
      })
    );

  React.useEffect(() => {
    getData().then(function (result) {
      setSelectedData(result);
    });
  }, []);
  return (
    <div>
      {selectedData.length > 0 &&
        selectedData.map((paltformData) => {
          return (
            <p
              key={paltformData.data.platform_uuid.toString()}
              className={classesDetails.multiDetails}
            >
              {paltformData.data.platform_callsign
                ? paltformData.data.platform_callsign
                : "-"}
            </p>
          );
        })}
    </div>
  );
}
