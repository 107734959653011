import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={!!fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage}
    />
  );
}

export default function AddTrackerFormComponent({
  open,
  trackerData,
  updating,
  handleClose,
  handleTrackerUpdate,
}) {
  const [trackername, setTrackername] = React.useState("");
  const [trackernameError, setTrackernameError] = React.useState("");
  const [trackerSn, setTrackerSn] = React.useState("");
  const [trackerSnError, setTrackerSnError] = React.useState("");
  const [trackerimei, setTrackerImei] = React.useState("");
  const [trackerimeiError, setTrackerImeiError] = React.useState("");

  const [simexpiry, setSimExpiry] = React.useState(new Date());
  const [expiryError, setExpiryError] = React.useState("");
  const [submittingNewTracker, setSubmittingNewTracker] = React.useState(false);

  const [selectedtrackeruuid, setSelectedTrackeruuid] = React.useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (updating) {
      const {
        tracker_sn,
        tracker_uuid,
        tracker_name,
        tracker_imei,
        sim_expiry,
      } = trackerData;
      setSelectedTrackeruuid(tracker_uuid);
      setTrackername(tracker_name);
      setTrackerSn(tracker_sn);
      setTrackerImei(tracker_imei);
      setSimExpiry(sim_expiry);
    } else {
      setSelectedTrackeruuid("");
      setTrackername("");
      setTrackerSn("");
      setTrackerImei("");
      setSimExpiry(new Date());
    }
    setTrackernameError("");
    setTrackerImeiError("");
    setTrackerSnError("");
    setExpiryError("");
  }, [open]);
  const api = useApi();

  const parseStrToUUIDFormat = (_puckID) => {
    const puckStr = _puckID.replaceAll("-", "");
    const strLength = puckStr.length;
    if (strLength > 32) {
      return [true, "Too many characters to parse to UUID format!", null];
    }
    const zero = "0";
    const charsShort = zero.repeat(32 - strLength);
    const UUIDStr = puckStr.concat(charsShort);
    // `${UUIDStr.slice(0, 8)
    //   }-${
    //   UUIDStr.slice(8, 12)
    //   }-${
    //   UUIDStr.slice(12, 16)
    //   }-${
    //   UUIDStr.slice(16, 20)
    //   }-${
    //   UUIDStr.slice(20)}`;
    // console.log(`Parsed UUID: ${  UUIDStr}`);
    return [false, null, UUIDStr];
  };
  const submitNewTracker = async () => {
    // let puckUUID = puckID + "00000000000000000";
    // puckUUID =
    //   puckUUID.slice(0, 8) +
    //   "-" +
    //   puckUUID.slice(8, 12) +
    //   "-" +
    //   puckUUID.slice(12, 16) +
    //   "-" +
    //   puckUUID.slice(16, 20) +
    //   "-" +
    //   puckUUID.slice(20);
    // const [parseErr, errMsg, trackerimeis] = parseStrToUUIDFormat(trackerimei);

    try {
      const response = await api.submitNewTracker({
        trackername,
        simexpiry,
        trackerimei,
        trackerSn,
      });
      if (response.data) {
        handleTrackerUpdate(response.data);
        setSubmittingNewTracker(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Tracker successfully registered",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewTracker(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.message,
          severity: "error",
        })
      );
    }
  };

  const updateTrackerInfo = async () => {
    // const [parseErr, errMsg, trackerimeis] = parseStrToUUIDFormat(trackerimei);
    try {
      const response = await api.updateTracker({
        ...trackerData,
        selectedtrackeruuid,
        trackername,
        trackerSn,
        simexpiry,
        trackerimei,
      });
      if (response.data) {
        handleTrackerUpdate(response.data);
        setSubmittingNewTracker(false);
        dispatch(
          setSnackbarMessage({
            message: "Tracker successfully registered",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewTracker(false);
      dispatch(
        setSnackbarMessage({
          message: "Unable to register Tracker",
          severity: "error",
        })
      );
    }
  };

  const validateForm = () => {
    let error = false;
    if (!trackername) {
      setTrackernameError("Callsign is required");
      error = true;
    }
    // if (!trackerimei) {
    //   setTrackerImeiError("Tracker IMEI is required");
    //   error = true;
    // } else {
    //   const [parseErr, errMsg, trackerimeis] =
    //     parseStrToUUIDFormat(trackerimei);
    //   if (parseErr) {
    //     setTrackerImeiError(errMsg);
    //     error = true;
    //   }
    // }
    if (!simexpiry) {
      setExpiryError("Tracker registration SIM expiry date is required");
      error = true;
    }
    if (!trackerSn) {
      setTrackerSnError("Tracker serial number is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewTracker(true);
    if (updating) {
      updateTrackerInfo();
    } else {
      submitNewTracker();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <LoadingOverlay
        active={submittingNewTracker}
        spinner
        text={
          updating
            ? "Updating tracker information..."
            : "Submitting new tracker..."
        }
      >
        <div style={{ height: "250px", width: "500px", margin: "30px" }}>
          <Typography component="div" display="inline" variant="h6">
            {updating ? "Update Tracker" : "Add Tracker"}
          </Typography>
          <CustomTextField
            label="Call Sign"
            value={trackername}
            setFuncton={setTrackername}
            fullWidth
            errormessage={trackernameError}
            setError={setTrackernameError}
          />
          <CustomTextField
            label="Tracker Serial Number"
            value={trackerSn}
            setFuncton={setTrackerSn}
            fullWidth
            errormessage={trackerSnError}
            setError={setTrackerSnError}
          />
          <CustomTextField
            label="Tracker IMEI (Optional)"
            value={trackerimei}
            setFuncton={setTrackerImei}
            fullWidth
            errormessage={trackerimeiError}
            setError={setTrackerImeiError}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              style={{ marginLeft: "0px" }}
              id="Expiry-Date"
              label="Sim Card Expiry Date"
              format="MM/dd/yyyy"
              value={simexpiry}
              errormessage={expiryError}
              onChange={(event) => {
                setSimExpiry(event);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
