import { combineReducers } from "redux";
// import { reducer as authReducer } from "aws-cognito-redux-saga";
import { reducer as formReducer } from "redux-form";
import subscriptionStatus from "./subscriptions";
import snackbar from "./snackbar";
import shipData from "./shipData";
import ADSBDataPaid from "./ADSBDataPaid";
import ADSBDataFree from "./ADSBDataFree";
import operationsReducer from "./operations";
import mapsReducer from "./maps";
import authReducer from "./auth";
import userAccessReducer from "./useAccess";
import constriantsReducer from "./constraints";
import airMapSpaceData from "./airMapSpaceData";
import conformanceReducer from "./conformance";
import websocket from "./websocket";

const reducers = combineReducers({
  auth: authReducer,
  form: formReducer,
  operations: operationsReducer,
  userAccess: userAccessReducer,
  subscriptionStatus,
  snackbar,
  shipData,
  ADSBDataPaid,
  ADSBDataFree,
  maps: mapsReducer,
  constraints: constriantsReducer,
  airMapSpaceData,
  conformanceData: conformanceReducer,
  websocket,
});

// const reducers = {
//   auth: authReducer,
//   form: formReducer,
//   operations: operationsReducer,
//   subscriptionStatus,
//   snackbar,
//   shipData,
//   ADSBDataPaid,
//   ADSBDataFree,
//   maps: mapsReducer,
//   constraints: constriantsReducer,
//   airMapSpaceData,
//   conformanceData: conformanceReducer,
//   websocket,
// };

export default reducers;
