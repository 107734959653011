import React from "react";
import Pilot from "@/model/api/Pilot";
import { formatToDisplayDate } from "@/utils/date_utils";

interface PilotItemProps {
  data: Pilot;
  onClick?: (pilot: Pilot) => void;
  onSelect?: (id: string, isSelected: boolean) => void;
}

export default function PilotItem({ data, onClick, onSelect }: PilotItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(data.pilot_uuid, e.target.checked);
  };

  return (
    <div className="relative p-2 rounded-container mb-2 cursor-pointer">
      <div
        key={data.pilot_username}
        onClick={() => onClick && onClick(data)}
        className="flex-col"
      >
        <h5 className="font-medium">{data.pilot_username}</h5>
        <p>License Number : {data.pilot_registration}</p>
        <p className="mb-2">Email : {data.email}</p>
        <div className="flex flex-wrap">
          <span className="new-badge new-badge-success mb-2">
            {data.registration_expiry
              ? formatToDisplayDate(data.registration_expiry)
              : ""}
          </span>
          <span className="new-badge mb-2 mr-2">{data.contact}</span>
        </div>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
