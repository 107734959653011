import React from "react";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import MaintenanceLogTable from "./maintenanceLogs";
import MaintenanceReportForm from "./createMaintenanceReport";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MaintenanceLogs({
  open,
  platformData,
  updating,
  handleClose,
  platformTypes,
  handlePlatformUpdate,
}) {
  // console.log(platformData); // PlatformData
  const [callsign, setCallsign] = React.useState("");
  const [callsignError, setCallsignError] = React.useState("");

  const [registration, setRegistration] = React.useState("");
  const [registrationError, setRegistrationError] = React.useState("");

  const [puckID, setPuckID] = React.useState("");
  const [puckIDError, setPuckIDError] = React.useState("");

  const [expiry, setExpiry] = React.useState(new Date());
  const [expiryError, setExpiryError] = React.useState("");

  const [platformType, setPlatformType] = React.useState("");

  const [selectedPlatformID, setSelectedPlatformID] = React.useState("");
  React.useEffect(() => {
    if (updating) {
      const {
        id,
        platform_callsign,
        platform_registration,
        puck_uuid,
        platform_type_uuid,
        registration_expiry,
      } = platformData;
      setSelectedPlatformID(id);
      setCallsign(platform_callsign);
      setRegistration(platform_registration);
      setPuckID(puck_uuid);
      setPlatformType(platform_type_uuid);
      setExpiry(registration_expiry);
    } else {
      setSelectedPlatformID("");
      setCallsign("");
      setRegistration("");
      setPuckID("");
      setExpiry(new Date());
      setPlatformType("");
    }
    setCallsignError("");
    setRegistrationError("");
    setPuckIDError("");
    setExpiryError("");
    setValue(0);
  }, [open]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // {platformData?.platform_callsign} - Maintenance Logs
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab label="Logs" {...a11yProps(0)} />
          <Tab label="Create New" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MaintenanceLogTable
          platform_uuid={platformData?.platform_uuid}
          platform_callsign={platformData?.platform_callsign}
          handleClose={handleClose}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* Create New Maintenance - {platformData?.platform_callsign} */}
        <MaintenanceReportForm
          platform_uuid={platformData?.platform_uuid}
          platform_callsign={platformData?.platform_callsign}
          handleClose={handleClose}
          updating={false}
        />
      </TabPanel>
    </Dialog>
  );
}
