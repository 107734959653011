const sidebarTracker = [
  {
    iconDefault: "/sidebar-menu/menu_tracker_light_default.png",
    iconActive: "/sidebar-menu/menu_tracker_light_active.png",
    title: "Tracker Management",
    isActive: true,
  },
  {
    iconDefault: "/sidebar-menu/menu_flight_log_light.png",
    iconActive: "/sidebar-menu/menu_flight_log_active.png",
    title: "Flight Logs",
    isActive: false,
  },
];

const sideBarPilot = [
  {
    iconDefault: "/sidebar-menu/menu_drone_light_default.png",
    iconActive: "/sidebar-menu/menu_drone_light_active.png",
    title: "Flight Authorization",
    isActive: true,
  },
  {
    iconDefault: "/sidebar-menu/menu_flight_log_light.png",
    iconActive: "/sidebar-menu/menu_flight_log_active.png",
    title: "Flight Logs",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_reports_light_default.png",
    iconActive: "/sidebar-menu/menu_reports_light_active.png",
    title: "Incident Reports",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_import_light_default.png",
    iconActive: "/sidebar-menu/menu_import_light_active.png",
    title: "Import Operation",
    isActive: false,
  },
];

const sideBarOperator = [
  {
    iconDefault: "/sidebar-menu/menu_drone_light_default.png",
    iconActive: "/sidebar-menu/menu_drone_light_active.png",
    title: "Flight Authorization",
    isActive: true,
  },
  // {
  //   iconDefault: "/sidebar-menu/menu_operation_light_default.png",
  //   iconActive: "/sidebar-menu/menu_operation_light_active.png",
  //   title: "Drone Ports Operations",
  //   isActive: false,
  // },
  {
    iconDefault: "/sidebar-menu/menu_assets_light_default.png",
    iconActive: "/sidebar-menu/menu_assets_light_active.png",
    title: "Assets Management",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_flight_log_light.png",
    iconActive: "/sidebar-menu/menu_flight_log_active.png",
    title: "Flight Logs",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_reports_light_default.png",
    iconActive: "/sidebar-menu/menu_reports_light_active.png",
    title: "Incident Reports",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_import_light_default.png",
    iconActive: "/sidebar-menu/menu_import_light_active.png",
    title: "Import Operation",
    isActive: false,
  },
];

const sideBarAuthority = [
  {
    iconDefault: "/sidebar-menu/menu_constraint_light_default.png",
    iconActive: "/sidebar-menu/menu_constraint_light_active.png",
    title: "Constraints Management",
    isActive: true,
  },
  // {
  //   iconDefault: "/sidebar-menu/menu_panel_light_default.png",
  //   iconActive: "/sidebar-menu/menu_panel_light_active.png",
  //   title: "Constraint Panel",
  //   isActive: false,
  // },
  {
    iconDefault: "/sidebar-menu/menu_group_light_default.png",
    iconActive: "/sidebar-menu/menu_group_light_active.png",
    title: "Operator Management",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_drone_light_default.png",
    iconActive: "/sidebar-menu/menu_drone_light_active.png",
    title: "Flight Authorization",
    isActive: false,
  },
  {
    iconDefault: "/sidebar-menu/menu_replay_light_default.png",
    iconActive: "/sidebar-menu/menu_replay_light_active.png",
    title: "Flight Replay",
    isActive: false,
  },
];

const data = {
  sidebarTracker,
  sideBarPilot,
  sideBarOperator,
  sideBarAuthority,
};

export default data;
