import React from "react";
import Tracker from "@/model/api/Tracker";
import { formatToDisplayDate } from "@/utils/date_utils";

interface TrackerItemProps {
  data: Tracker;
  onClick: (data: Tracker) => void;
  onSelect: (id: string, isSelected: boolean) => void;
}

export default function TrackerItem({
  data,
  onClick,
  onSelect,
}: TrackerItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(data.tracker_uuid, e.target.checked);
  };

  return (
    <div className="relative p-4 rounded-container mb-2 cursor-pointer">
      <div
        className="flex-col"
        key={data.tracker_uuid}
        onClick={() => onClick(data)}
      >
        <h5 className="font-medium">{data.tracker_name}</h5>
        <p>S/N : {data.tracker_sn}</p>
        <p>IMEI : {data.tracker_imei}</p>
        <p>SIM Card Expiry : {formatToDisplayDate(data.sim_expiry)}</p>
      </div>

      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
