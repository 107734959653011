import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import PuckServer from "../../services/puckserver";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { setSnackbarMessage } from "../../store/actions";
import ReplayDialog from "./RelayDialog";

function CustomTextField({
  label,
  value,
  setFunction,
  fullWidth,
  errorMessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errorMessage) setError("");
        setFunction(event.target.value);
      }}
      margin="normal"
      fullWidth={!!fullWidth}
      autoComplete="off"
      required
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
}

export default function ReplayComponent({ validating }) {
  const [OpsID, setOpsID] = useState("");
  const [OpsIDError, setOpsIDError] = useState("");
  const [TrackerID, setTrackerID] = useState("");
  const [TrackerIDError, setTrackerIDError] = useState("");
  const [submittingOpsDetails, setSubmittingOpsDetails] = useState(false);
  const [validationResult, setValidationResult] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { username } = useCognitoAuth();
  const [inputPairs, setInputPairs] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [operationIDs, setOperationIDs] = useState([]);
  const [trackerIDs, setTrackerIDs] = useState([]);

  useEffect(() => {
    setCanSubmit(inputPairs.length > 0 && OpsID === "" && TrackerID === "");
    setOpsIDError("");
    setTrackerIDError("");
  }, [inputPairs, OpsID, TrackerID]);

  const dispatch = useDispatch();

  const handleAddInputPair = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (!formErrors) {
      if (!validating) {
        RequiredInfo();
      }
    }
    if (OpsID && TrackerID) {
      const isPairAlreadyAdded = inputPairs.some(
        (pair) => pair[0] === OpsID && pair[1] === TrackerID
      );
      try {
        setSubmittingOpsDetails(true);
        const [puckErr, puckResponse] = await PuckServer.getTrackerData(
          TrackerID,
          OpsID
        );
        if (isPairAlreadyAdded) {
          setValidationResult("Operation Details already exist in the list!");
          dispatch(
            setSnackbarMessage({
              open: true,
              message: `Operation Details: Operation ID - ${OpsID} & Tracker ID - ${TrackerID} can't be entered`,
              severity: "error",
            })
          );
        } else if (
          puckErr === null &&
          puckResponse.length > 0 &&
          puckResponse[0].operation_id === OpsID &&
          puckResponse[0].extras.imei === TrackerID
        ) {
          if (
            puckResponse[0].approved_user_list.some((userObj) =>
              userObj.user.includes(username)
            )
          ) {
            const newPairs = [...inputPairs, [OpsID, TrackerID]];
            setInputPairs(newPairs);
            setOperationIDs([...operationIDs, OpsID]);
            setTrackerIDs([...trackerIDs, TrackerID]);
            setOpsID("");
            setTrackerID("");
            setOpsIDError("");
            setTrackerIDError("");
            setValidationResult("Operation Details are valid!");
            dispatch(
              setSnackbarMessage({
                open: true,
                message: `Operation Details: Operation ID - ${OpsID} & Tracker ID - ${TrackerID} have been entered`,
                severity: "success",
              })
            );
          } else {
            setValidationResult("Unauthorised Access to Flight Operation!");
            dispatch(
              setSnackbarMessage({
                open: true,
                message: `Operation Details: Operation ID - ${OpsID} & TrackerID - ${TrackerID} can't be entered`,
                severity: "error",
              })
            );
          }
        } else {
          setValidationResult("Operation Details are invalid!");
          dispatch(
            setSnackbarMessage({
              open: true,
              message: `Operation Details: Operation ID - ${OpsID} & TrackerID - ${TrackerID} can't be entered`,
              severity: "error",
            })
          );
        }
        const timeout = 5000;
        setTimeout(() => {
          setValidationResult("");
        }, timeout);
      } catch (error) {
        setValidationResult("Validation Failed. Please Try Again");
        dispatch(
          setSnackbarMessage({
            open: true,
            message: `Operation Details: Operation ID - ${OpsID} & Tracker ID - ${TrackerID} cant be entered`,
            severity: "error",
          })
        );
      } finally {
        setSubmittingOpsDetails(false);
      }
    }
  };

  const validateForm = () => {
    let error = false;
    if (!OpsID) {
      setOpsIDError("Operation ID is Required");
      error = true;
    }
    if (!TrackerID) {
      setTrackerIDError("Tracker ID is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (canSubmit) {
      setOpenDialog(true);
      const InputList = inputPairs.reduce(
        (acc, pair) => {
          acc[0].push(pair[0]);
          acc[1].push(pair[1]);
          return acc;
        },
        [[], []]
      );
      // console.log("Input List:", InputList);
      // console.log(inputPairs);
      setSelectedFlights(InputList);
    }
  };

  const handleClear = () => {
    setOpsID("");
    setTrackerID("");
    setValidationResult("");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemoveInputPair = (index) => {
    const updatedPairs = [...inputPairs];
    updatedPairs.splice(index, 1);
    setInputPairs(updatedPairs);
  };

  const handleClearInputList = () => {
    setInputPairs([]);
  };

  const RequiredInfo = () => {
    setSubmittingOpsDetails(true);
    handleAddInputPair();
  };

  return (
    <div>
      <LoadingOverlay
        active={submittingOpsDetails}
        spinner
        text={
          validating
            ? "Validating Operation information..."
            : "Submitting Operation Information..."
        }
      >
        <div
          style={{
            width: "700px",
            height: "auto",
            backgroundColor: "white",
            border: "2px solid black",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            component="div"
            variant="h6"
            style={{
              color: "black",
              margin: "16px",
            }}
          >
            {validating
              ? "Validate Operation Details"
              : "Enter Operation Details"}
          </Typography>
          <div
            style={{
              margin: "16px",
            }}
          >
            <CustomTextField
              label="Operation ID"
              value={OpsID}
              setFunction={setOpsID}
              fullWidth
              errorMessage={OpsIDError}
              setError={setOpsIDError}
              id="operation-id-input"
            />
            <CustomTextField
              label="Tracker ID"
              value={TrackerID}
              setFunction={setTrackerID}
              fullWidth
              errorMessage={TrackerIDError}
              setError={setTrackerIDError}
              id="tracker-id-input"
            />
          </div>
          <div>
            <Button onClick={handleAddInputPair} color="primary">
              Add Input Pair
            </Button>
          </div>
          {inputPairs.map((pair, index) => (
            <div key={index.id}>
              <Typography variant="body1">
                Pair {index + 1}: OpsID - {pair[0]}, TrackerID - {pair[1]}
              </Typography>
              <Button
                onClick={() => handleRemoveInputPair(index)}
                color="primary"
              >
                Remove
              </Button>
            </div>
          ))}
          <DialogActions>
            <div>
              <Button onClick={handleClearInputList} color="primary">
                Clear Input List
              </Button>
            </div>
            <Button onClick={handleClear} color="primary">
              Clear
            </Button>
            <Button
              autoFocus
              onClick={handleSubmit}
              color="primary"
              disabled={!canSubmit}
            >
              Submit
            </Button>
          </DialogActions>
          {validationResult && (
            <Typography variant="body1" style={{ margin: "16px" }}>
              {validationResult}
            </Typography>
          )}
        </div>
      </LoadingOverlay>
      <ReplayDialog
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        selectedFlights={selectedFlights}
      />
    </div>
  );
}
