import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  makeStyles,
  useTheme,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getWSService } from "../../../services/websocket";
import WebsocketAlert from "../../../components/WebsocketAlert";

import { useApi } from "../../../api/useApi";
import {
  setFocussedOperation,
  setEditConstraint,
  setSnackbarMessage,
} from "../../../store/actions";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPage className={classes.icon} />
        ) : (
          <FirstPage className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPage className={classes.icon} />
        ) : (
          <LastPage className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 150,
    width: 1000,
    // height: 20,
  },

  tableHead: {
    minWidth: 1000,
    minHeight: 53,
    position: "sticky",
    top: 0,
    backgroundColor: "#fafafa",
  },
  tableRow: {
    height: 53,
    width: 200,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 10,
    width: 1000,
  },
  tableCell: {
    color: "black",
    wordWrap: "break-word",
    maxWidth: 1000,
    minWidth: 150,
  },
  pagination: {
    color: "black",
    background: "white",
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
  tabelFooter: {
    position: "sticky",
    bottom: 0,
  },
}));
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));
function OperationsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [operations, setOperations] = useState(props.operations);
  const rowsPerPage = 10;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.operations.length - page * rowsPerPage);
  // Sort operations by time created
  function compare(a, b) {
    if (a.reference.time_created.value < b.reference.time_created.value) {
      return -1;
    }
    if (a.reference.time_created.value > b.reference.time_created.value) {
      return 1;
    }
    return 0;
  }
  // operations.sort(compare);
  // operations.reverse();
  useEffect(() => {
    setOperations(props.operations);
  }, [props.operations]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = operations.map((n) => n.constraint_id);
      setSelected(newSelecteds);
      if (props.setSelectedFlights) props.setSelectedFlights(newSelecteds);
      return;
    }
    setSelected([]);
    if (props.setSelectedFlights) props.setSelectedFlights([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    if (props.setSelectedFlights) props.setSelectedFlights(newSelected);
  };

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  return (
    // <TableContainer>
    <Table className={classes.table} aria-label="a dense table">
      <TableHead className={classes.tableHead}>
        <TableRow className={classes.tableHeaderRow}>
          <TableCell padding="checkbox">
            <Checkbox
              checked={
                operations.length > 0 && selected.length === operations.length
              }
              onChange={handleSelectAllClick}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>CID</TableCell>
          <TableCell className={classes.tableCell} align="left">
            Name
          </TableCell>
          <TableCell className={classes.tableCell} align="left" width={300}>
            Description
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Start Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            End Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Altitude
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Authorisation Required
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Authorisation Whitelists
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Authorisation Recurrence Type
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Authorisation Recurrence Start Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Authorisation Recurrence End Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Prohibited
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Prohibited Whitelists
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Prohibited Recurrence Type
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Prohibited Recurrence Start Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Prohibited Recurrence End Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Conditions
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Recurrence Type
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Recurrence Start Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Recurrence End Date & Time
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Status
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Created On
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {operations
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = isSelected(row.constraint_id);

            return (
              <TableRow className={classes.tableRow} key={row.constraint_id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    onClick={(event) => handleClick(event, row.constraint_id)}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {`${row.constraint_id}`}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  {row.name}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="left"
                  width={300}
                >
                  {row.description}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row.extents.map((timestart) =>
                    formatDate(timestart.time_start.value)
                  )}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row.extents.map((timeend) =>
                    formatDate(timeend.time_end.value)
                  )}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {/* {row.details.state} */}
                  {row.extents.map(
                    (alt) =>
                      `Alt: ${alt.volume.altitude_lower.value} to ${alt.volume.altitude_upper.value} meters`
                  )}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row.rule.authorisation_required
                    ? `${formatDate(
                        row.authorisation_conditions.time_start.value
                      )} - ${formatDate(
                        row.authorisation_conditions.time_end.value
                      )}`
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.authorisation_conditions?.whitelist?.users?.length ||
                  row?.authorisation_conditions?.whitelist?.usergroups?.length
                    ? `Users: ${row?.authorisation_conditions?.whitelist?.users?.join(
                        ", "
                      )},\nGroups: ${row?.authorisation_conditions?.whitelist?.usergroups?.join(
                        ", "
                      )}`
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.authorisation_conditions?.recurring || "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.authorisation_conditions?.recurrence_range?.time_start
                    ?.value
                    ? formatDate(
                        row?.authorisation_conditions?.recurrence_range
                          ?.time_start?.value
                      )
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.authorisation_conditions?.recurrence_range?.time_start
                    ?.value
                    ? formatDate(
                        row?.authorisation_conditions?.recurrence_range
                          ?.time_end?.value
                      )
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row.rule.prohibited &&
                  row?.prohibited_conditions?.time_start?.value &&
                  row?.prohibited_conditions?.time_end?.value
                    ? `${formatDate(
                        row?.prohibited_conditions?.time_start?.value
                      )} - ${formatDate(
                        row?.prohibited_conditions?.time_end?.value
                      )}`
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.prohibited_conditions?.whitelist?.users?.length ||
                  row?.prohibited_conditions?.whitelist?.usergroups?.length
                    ? `Users: ${row?.prohibited_conditions?.whitelist?.users?.join(
                        ", "
                      )},\nGroups: ${row?.prohibited_conditions?.whitelist?.usergroups?.join(
                        ", "
                      )}`
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.prohibited_conditions?.recurring || "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.prohibited_conditions?.recurrence_range?.time_start
                    ?.value
                    ? formatDate(
                        row?.prohibited_conditions?.recurrence_range?.time_start
                          ?.value
                      )
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row?.prohibited_conditions?.recurrence_range?.time_start
                    ?.value
                    ? formatDate(
                        row?.prohibited_conditions?.recurrence_range?.time_end
                          ?.value
                      )
                    : "-"}
                </TableCell>

                <TableCell className={classes.tableCell} align="right">
                  {row.rule.conditional
                    ? `${JSON.stringify(
                        row.conditions || row.conditional_conditions
                      )
                        .replace(/[{(")}]/g, "")
                        .replace(/,/g, ", ")}`
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {"recurring" in row ? row.recurring : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {"recurring" in row
                    ? formatDate(row.recurrence_range.time_start.value)
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {"recurring" in row
                    ? formatDate(row.recurrence_range.time_end.value)
                    : "-"}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {row.state}
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  {formatDate(row.time_created)}
                </TableCell>
              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={5} />
          </TableRow>
        )}
      </TableBody>
      <TableFooter className={classes.tabelFooter}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[]}
            colSpan={6}
            count={operations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            className={classes.pagination}
            onChangePage={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
    // </TableContainer>
  );
}
function UpcomingOperationsHeader({
  selectedConstraint,
  history,
  setLoading,
  upcomingOperationsConstrain,
  seteditConstraints,
}) {
  const classesFloating = useFloatingButton();
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [focusedConstrain, setFocusedConstrain] = useState();
  const handleOnFocus = (operationOnFocus) => {
    const coordinate = {
      reference: {
        id: operationOnFocus[0]?.constraint_id,
      },
      request: {
        arrival:
          operationOnFocus[0]?.extents[0].volume.outline_polygon
            .coordinates[0][0],
      },
    };
    mapboxController.setFocussedOperation(coordinate);
    dispatch(setFocussedOperation(operationOnFocus));
    setFocusedConstrain(operationOnFocus.constraint_id);
  };
  const api = useApi();
  const dispatch = useDispatch();
  const handleDeleteConstOpsClick = async () => {
    const deleteConstraints = async (_selectedConstraint) => {
      try {
        const response = await api.deleteAuthorityAreaConstrain(
          _selectedConstraint
        );
        if (response) {
          setLoading(true);
          dispatch(
            setSnackbarMessage({
              message: response.data.message,
              severity: "success",
            })
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    await selectedConstraint.map((_selectedConstraint) => {
      deleteConstraints(_selectedConstraint);
    });
  };
  const handleEditConstraintClick = async () => {
    const upcomingConstraintFilter = upcomingOperationsConstrain.filter(
      ({ constraint_id }) => constraint_id === selectedConstraint[0]
    );
    seteditConstraints(upcomingConstraintFilter[0]);
    handleOnFocus(upcomingConstraintFilter);
    dispatch(
      setEditConstraint({
        isEdit: true,
        // data: upcomingConstraintFilter[0],
      })
    );
  };
  return (
    <div className={classesFloating.buttonFloating}>
      <Grid
        container
        spacing={0}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            disabled={!selectedConstraint.length}
            onClick={handleDeleteConstOpsClick}
            style={{ fontSize: "12px", fontWeight: "bold" }}
            color="secondary"
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={!selectedConstraint.length}
            style={{ fontSize: "12px", fontWeight: "bold" }}
            color="primary"
            onClick={handleEditConstraintClick}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

function UpcomingConstraintsAuthorityComponent({
  history,
  constraints,
  seteditConstraints,
}) {
  const [loading, setLoading] = useState(true);
  const [upcomingOperationsConstrain, setUpcomingOperationsConstrain] =
    useState([]);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);
  const api = useApi();
  useEffect(() => {
    // getData();
    getGlobaConstraints();
  }, [constraints]);

  const getData = async () => {
    const promises = [];
    promises.push(getGlobaConstraints());
    // promises.push(getPlatformData())

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getGlobaConstraints = async () => {
    //  const responseConstrain = await api.getConstraints();
    const responseConstrain = constraints;
    if (!responseConstrain) return;
    const currentTime = new Date();
    const responseConstrainArray = [];
    responseConstrain.forEach((constraintData) => {
      if (new Date(constraintData.time_end) < currentTime) {
        responseConstrainArray.push(constraintData);
      }
    });

    setUpcomingOperationsConstrain(responseConstrainArray);
    setLoading(false);
  };

  return (
    <div>
      {upcomingOperationsConstrain.length > 0 && (
        <LoadingOverlay active={loading} spinner text="Loading..">
          <Paper
            style={{
              height: `${450}px`,
              maxHeight: "calc(50% - 0px)",
              width: "100%",
              overflow: "auto",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <OperationsTable
              operations={upcomingOperationsConstrain}
              setSelectedFlights={setSelectedFlights}
            />
          </Paper>
          <UpcomingOperationsHeader
            selectedConstraint={selectedFlights}
            upcomingOperationsConstrain={upcomingOperationsConstrain}
            setLoading={setLoading}
            history={history}
            seteditConstraints={seteditConstraints}
          />
          {/* <WebsocketAlert
            open={websocketAlertOpen}
            handleClose={onWebsocketAlertClose}
            websocketMessage={websocketMessage}
          /> */}
        </LoadingOverlay>
      )}
    </div>
  );
}

export default UpcomingConstraintsAuthorityComponent;
