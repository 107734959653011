import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingOverlay from "react-loading-overlay";

import { format, isSameDay } from "date-fns";
import { useDispatch } from "react-redux";
import Circle from "./circle";
import EnhancedTableHead from "./enhancedTableHead";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { useApi } from "../../api/useApi";
import DetailsOperationsDialog from "../UpcomingOperationsDetailsDialog";
import { setOperationFormRequest } from "../../store/actions";

const colorToHexMap = {
  "#95f985": "GREEN",
  "#FFF300": "YELLOW",
  "#ffff01": "RYELLOW",
  "#ffa500": "ORANGE",
  "#AA4A44": "RED",
  "#9FC5EC": "BLUE",
  "#dddddd": "GREY",
};

const hexToColorMap = {
  GREEN: "#95f985",
  YELLOW: "#FFF300",
  RYELLOW: "#ffff01",
  ORANGE: "#ffa500",
  RED: "#AA4A44",
  BLUE: "#9FC5EC",
  GREY: "#dddddd",
};

const severityColorToOrderMap = {
  GREY: 6,
  BLUE: 5,
  YELLOW: 4,
  RYELLOW: 3,
  ORANGE: 2,
  RED: 1,
};
const useTableStyles = makeStyles((theme) => ({
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
}));
/**
 * Maps severity level to associated button color
 * @param {String} severity Severity level of notification
 * @returns Button color in hex
 */
const getSeverityColor = (severity) => {
  if (severity === "Information") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Informational") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Error") {
    return hexToColorMap.YELLOW;
  }
  if (severity === "Important") {
    return hexToColorMap.RYELLOW;
  }
  if (severity === "Warning") {
    return hexToColorMap.ORANGE;
  }
  if (severity === "Emergency") {
    return hexToColorMap.RED;
  }
  // return if none of the severities match, should not occur
  return "#dddddd";
};

const getSeverityBorderColor = (severity) => {
  if (severity === "Information") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Informational") {
    return hexToColorMap.BLUE;
  }
  if (severity === "Error") {
    return hexToColorMap.YELLOW;
  }
  if (severity === "Important") {
    return hexToColorMap.RED;
  }
  if (severity === "Warning") {
    return hexToColorMap.ORANGE;
  }
  if (severity === "Emergency") {
    return hexToColorMap.RED;
  }
  // return if none of the severities match, should not occur
  return "#dddddd";
};

/**
 * Implements a comparator in descending order
 * @param {Object} a 1st object to compare
 * @param {Object} b 2nd object to compare
 * @param {String} orderBy Field to use for comparison
 * @returns Comparator value to use for ordering in descending order
 */
function descendingComparator(a, b, orderBy) {
  let comp1;
  let comp2;

  if (orderBy === "severityColor") {
    const color1 = colorToHexMap[a.severityColor];
    const color2 = colorToHexMap[b.severityColor];
    comp1 = severityColorToOrderMap[color1];
    comp2 = severityColorToOrderMap[color2];
  } else {
    comp1 = a[orderBy];
    comp2 = b[orderBy];
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Help dialog popup for Notification classifications
function NotifsHelpDialog({ openNotifsHelpDialog, handleCloseNotifsDialog }) {
  return (
    <Dialog
      open={openNotifsHelpDialog}
      onClose={handleCloseNotifsDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "550px",
          },
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "20px",
          margin: " 6px 0px -30px 0px",
        }}
      >
        {" "}
        <IconButton
          aria-label="CloseNotifsHelp"
          onClick={handleCloseNotifsDialog}
        >
          <CancelPresentationIcon fontSize="14px" />
        </IconButton>{" "}
      </div>
      <DialogTitle>
        <div>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div> Notifications Help</div>
          </div>
          <div
            style={{
              display: "flex",
              // justifyContent: "space-between",
              marginTop: "7px",
              marginBottom: "-23px",
            }}
          >
            <div
              style={{
                width: "160px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {" "}
              <Typography
                variant="button"
                component="div"
                sx={{
                  display: "inline-block",
                  padding: "12px 16px 8px 16px",
                }}
              >
                Category
              </Typography>
            </div>
            <div
              style={{
                width: "390px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Typography
                variant="button"
                component="div"
                sx={{
                  display: "inline-block",
                  padding: "12px 16px 8px 16px",
                }}
              >
                Events
              </Typography>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ justifyContent: "center" }}>
          <NotifsHelpDialogCards category="Emergency" />
          <NotifsHelpDialogCards category="Warning" />
          <NotifsHelpDialogCards category="Important" />
          <NotifsHelpDialogCards category="Error" />
          <NotifsHelpDialogCards category="Information" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function NotifsHelpDialogCards({ category }) {
  let listItems;

  if (category === "Emergency") {
    listItems = (
      <>
        <li>Contingent operation </li>
        <li>Adjacent OIV contingent operation </li>
      </>
    );
  } else if (category === "Warning") {
    listItems = (
      <>
        <li>Non-Conforming operation</li>
        <li>Adjacent OIC Non-Conforming operation</li>
        <li>New constraint nearby</li>
        <li> Message/Updates from Authority</li>
        <li>New constraint overlaps with active operations</li>
      </>
    );
  } else if (category === "Important") {
    listItems = (
      <div>
        <li>Manual flight authorisation sent, accepted or rejected</li>
      </div>
    );
  } else if (category === "Error") {
    listItems = (
      <>
        <li>Error in creating operations, queries, etc</li>
        <li>Airspace conflict when submitting OIV</li>
        <li>System errors</li>
      </>
    );
  } else if (category === "Information") {
    listItems = (
      <>
        <li>Informational events</li>
        <li>Successful operation sent, updated, etc</li>
        <li>Acknowledgement of Emergency/Warning level notifications</li>
      </>
    );
  }
  return (
    <div
      style={{
        borderBottom: "1px solid #dedede",
        display: "flex",
        // justifyContent: "center",
      }}
    >
      {" "}
      <div style={{ display: "flex", width: "50px", alignItems: "center" }}>
        {" "}
        <Circle
          color={getSeverityColor(category)}
          borderColor={getSeverityBorderColor(category)}
          size={15}
        />{" "}
      </div>
      <div
        style={{
          display: "flex",
          width: "110px",
          borderRight: "1px solid #dedede",
          alignItems: "center",
        }}
      >
        {category}
      </div>
      <div style={{ flex: 1 }}>
        {" "}
        <ul> {listItems}</ul>
      </div>
    </div>
  );
}

// Custom message table for notifications
export default function ActionMessagesTable(props) {
  const { username, isOperator, isAuthority, isPilot } = useCognitoAuth();
  const classes = useTableStyles();
  const {
    data,
    handleOperatorProposeOperation,
    loadOlderMessages,
    olderMessages,
    handleClickOpenFlightAuthorizationAuthority,
    handleOpenNotifications,
  } = props;

  const propsValid = (datas) =>
    Object.values(datas).every((prop) => prop !== undefined);

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const rowsRef = useRef([]);
  const [todayRows, setTodayRows] = useState([]);
  const [olderRows, setOlderRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("datetime");
  const [openDetailsOperationsMapDialog, setOpenDetailsOperationsMapDialog] =
    useState(false);
  const [selectedDialogData, setSelectedDialogData] = useState("");
  const [openNotifsHelpDialog, setOpenNotifsHelpDialog] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  // lifted filterValue state up to here to set a Default value
  const [filterValue, setFilterValue] = useState([
    "Emergency",
    "Warning",
    "Important",
  ]);
  const dispatch = useDispatch();
  /**
   * Parses data from DynamoDB into an object for insertion into notifications table
   * @param {*} row Data to be parsed
   * @returns Object containing fields to display
   */

  // added a isToday function, return severity and isToday for use in filtering
  const createTodayData = (row) => {
    try {
      const key = row.SK;
      const rowInfoSplit = row.SK.split("#");
      const ts = rowInfoSplit[1];
      const severity = rowInfoSplit[5];
      const type = rowInfoSplit[3];
      const { message } = row.data;
      const datetime = format(Number.parseInt(ts, 10), "yyyy-MM-dd HH:mm:ss");
      const severityColor = getSeverityColor(severity);
      const severityBorderColor = getSeverityBorderColor(severity);
      const parsedDatetime = new Date(datetime);
      const isToday = isSameDay(parsedDatetime, new Date());
      return {
        severity,
        severityColor,
        severityBorderColor,
        message,
        datetime,
        type,
        isToday,
        key,
      };
    } catch (e) {
      console.log(e);
    }
    return {};
  };
  const createTodayDataAuth = (row) => {
    try {
      const key = row.SK;
      const rowInfoSplit = row.SK.split("#");
      const user = row.PK.split("#")[1];
      const ts = rowInfoSplit[1];
      const severity = rowInfoSplit[5];
      const type = rowInfoSplit[3];
      const { message } = row.data;
      const datetime = format(Number.parseInt(ts, 10), "yyyy-MM-dd HH:mm:ss");
      const severityColor = getSeverityColor(severity);
      const severityBorderColor = getSeverityBorderColor(severity);
      const parsedDatetime = new Date(datetime);
      const isToday = isSameDay(parsedDatetime, new Date());
      return {
        severity,
        severityColor,
        severityBorderColor,
        message,
        datetime,
        user,
        type,
        isToday,
        key,
      };
    } catch (e) {
      console.log(e);
    }
    return {};
  };

  const api = useApi();

  const columns = [
    { id: "severityColor", label: "Type", minWidth: "20" },
    { id: "message", label: "Message", minWidth: "170" },
    { id: "datetime", label: "Created At", minWidth: "170" },
  ];

  const [activePage, setActivePage] = useState("today");

  // if (isAuthority) {
  //   propsValid(props)
  //     ? (rows = data?.map((row) => createTodayDataAuth(row)))
  //     : (rows = []);
  // } else {
  //   const mergedMessages = [...data, ...olderMessages];
  //   propsValid(props)
  //     ? (rows = mergedMessages?.map((row) => createTodayData(row)))
  //     : (rows = []);
  // }

  // set rows first, when older Messages or data (first 20 msgs) changes
  // later, filter the rows based on filter & isToday
  // then finally, set displayedNotifs
  useEffect(() => {
    if (isAuthority) {
      propsValid(props)
        ? setRows(data?.map((row) => createTodayDataAuth(row)))
        : setRows([]);
    } else {
      const mergedMessages = [...data, ...olderMessages];
      propsValid(props)
        ? setRows(mergedMessages?.map((row) => createTodayData(row)))
        : setRows([]);
    }
  }, [data, olderMessages]);

  // created a useState for the actual notifications to be shown in the table,
  // but default value seems to not be in much effect due to useEffect hook below
  const [displayedTodayNotifications, setDisplayedTodayNotifications] =
    useState(todayRows);
  const [displayedOldNotifications, setDisplayedOldNotifications] =
    useState(olderRows);

  // 2 filter handlers for today and old data, will change state of displayedNotifiations

  const handleTodayFilter = () => {
    if (filterValue.includes("All")) {
      setDisplayedTodayNotifications(todayRows);
    } else {
      const filteredRows = todayRows.filter((row) =>
        filterValue.includes(row.severity)
      );
      setDisplayedTodayNotifications(filteredRows);
    }
  };

  const handleOldFilter = () => {
    if (filterValue.includes("All")) {
      setDisplayedOldNotifications(olderRows);
    } else {
      const filteredRows = olderRows.filter((row) =>
        filterValue.includes(row.severity)
      );
      setDisplayedOldNotifications(filteredRows);
    }
  };

  // combined both filters into 1 so that the filters are consistent
  // when switching between Today and Older page
  // setting the displayed notifications based on filter as well
  const handleAllFilter = (selectedFilterValues = null) => {
    // this function can be passed in value if manually changing filter value
    // or no value if it is called to reSet the displayedNotifs
    // so need to duplicate the code to account for both scenarios

    /* eslint-disable */
    if (selectedFilterValues !== null) {
      setFilterValue(selectedFilterValues);
      if (selectedFilterValues.includes("All")) {
        setDisplayedOldNotifications(olderRows);
        setDisplayedTodayNotifications(todayRows);
      } else {
        const handleOlderRows = olderRows;
        const filteredOlderRows = handleOlderRows.filter((row) =>
          selectedFilterValues.includes(row.severity)
        );
        setDisplayedOldNotifications(filteredOlderRows);
        const handleTodayRows = todayRows;
        const filteredTodayRows = handleTodayRows.filter((row) =>
          selectedFilterValues.includes(row.severity)
        );
        setDisplayedTodayNotifications(filteredTodayRows);
      }
    } else {
      if (filterValue.includes("All")) {
        setDisplayedOldNotifications(olderRows);
        setDisplayedTodayNotifications(todayRows);
      } else {
        const handleOlderRows = olderRows;
        const filteredOlderRows = handleOlderRows.filter((row) =>
          filterValue.includes(row.severity)
        );
        setDisplayedOldNotifications(filteredOlderRows);
        const handleTodayRows = todayRows;
        const filteredTodayRows = handleTodayRows.filter((row) =>
          filterValue.includes(row.severity)
        );
        setDisplayedTodayNotifications(filteredTodayRows);
      }
    }
    /* eslint-enable */
  };

  useEffect(() => {
    if (rowsRef.current !== rows) {
      // to prevent unncessary renders
      setTodayRows(rows.filter((row) => row.isToday));
      setOlderRows(rows.filter((row) => !row.isToday));
      handleAllFilter();
      rowsRef.current = rows;
    }
  }, [rows]);

  useEffect(() => {
    // changed to call the filter function, because this should setDisplayed
    // based on the active filter
    handleAllFilter();
    // }, [olderMessages]);
  }, [olderRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    if (isAuthority) {
      handleClickOpenFlightAuthorizationAuthority();
      // handleOpenNotifications();
      // no need to "close" notifs as new implementation closes everything else already
      return;
    }
    if (row.type.substring(0, 30) === "OPERATION_PROPOSE_CHANGES_UUID") {
      const operationID = row.type.split("_CHANGES_UUID_");
      try {
        openOperatorProposeOperation(operationID[operationID.length - 1]);
        // handleOperatorProposeOperation();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const openOperatorProposeOperation = async (operationID) => {
    try {
      const response = await api.getOperationData({
        operationID,
      });
      const epochArrivalTime = Date.parse(
        response.data.operation_json.interuss?.operational_intent_reference
          ?.time_end?.value
      );
      if (epochArrivalTime < Date.now()) {
        window.alert("Operation Has Been Rejected or Has Expired ");
        return;
      }
      setSelectedDialogData(response.data.operation_json);
      setOpenDetailsOperationsMapDialog(true);
      // Closing the notification will also close this window
      // handleOpenNotifications(false);
    } catch (err) {
      window.alert("Operation Has Been Rejected or Has Expired ");
    }
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsOperationsMapDialog(false);
  };

  const handleOperatorProposeOperationFromActionMessageTable = async (
    operationID
  ) => {
    const response = await api.getOperationData({
      operationID,
    });
    dispatch(
      setOperationFormRequest({
        id: operationID,
        type: "edit",
        request: response.data.request_json,
      })
    );
    handleOpenNotifications(false);
    handleOperatorProposeOperation();
  };

  const handleHelpClick = () => {
    setOpenNotifsHelpDialog(true);
  };
  const handleCloseNotifsDialog = () => {
    setOpenNotifsHelpDialog(false);
  };

  const handleClickGetOlderMessage = () => {
    setLoadingMessages(true);
    loadOlderMessages();
    setTimeout(() => {
      setLoadingMessages(false);
    }, 500);
  };

  const handleChangeTab = (pageType) => {
    setActivePage(pageType);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "170px",
            height: "36.5px",
            // border: "1px solid black",
          }}
        >
          <div>
            <Typography variant="button" color="grey.600">
              {" "}
              Notifications{" "}
            </Typography>
          </div>
        </div>
        <div>
          <Button
            variant={activePage === "today" ? "contained" : "outlined"}
            onClick={() => handleChangeTab("today")}
            sx={{
              width: 130,
            }}
          >
            Today
          </Button>
        </div>
        <div>
          <Button
            variant={activePage === "older" ? "contained" : "outlined"}
            onClick={() => handleChangeTab("older")}
            sx={{
              width: 130,
            }}
          >
            Older
          </Button>
        </div>
        <div
          style={{
            width: "170px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton aria-label="NotifsHelp" onClick={handleHelpClick}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
      </div>

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          {activePage === "today" ? (
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              onRequestFilter={handleAllFilter}
              getSeverityBorderColor={getSeverityBorderColor}
              getSeverityColor={getSeverityColor}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          ) : (
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              onRequestFilter={handleAllFilter}
              getSeverityBorderColor={getSeverityBorderColor}
              getSeverityColor={getSeverityColor}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          )}

          <TableBody>
            {activePage === "today"
              ? stableSort(
                  displayedTodayNotifications,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={isAuthority ? row.key : row.key} // some messages have same datetime, causing duplicate key error. added SK to key as contains epoch time
                      onClick={(event) => handleClick(event, row)}
                      className={classes.tableRow}
                    >
                      <TableCell align="center">
                        <Circle
                          color={row.severityColor}
                          borderColor={row.severityBorderColor}
                          size={15}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {isAuthority && (
                          <span style={{ display: "block", color: "blue" }}>
                            {row.user}
                          </span>
                        )}
                        {row.message.includes("Click here to view updates") ? (
                          <>
                            {row.message.replace(
                              /Click here to view updates/g,
                              ""
                            )}
                            {!isAuthority && (
                              <span style={{ display: "block", color: "blue" }}>
                                {" "}
                                Click here to view updates
                              </span>
                            )}
                          </>
                        ) : (
                          row.message
                        )}
                      </TableCell>
                      <TableCell align="center">{row.datetime}</TableCell>
                    </TableRow>
                  ))
              : stableSort(
                  displayedOldNotifications,
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={isAuthority ? row.key : row.key} // some messages have same datetime, causing duplicate key error. added SK to key as contains epoch time
                      onClick={(event) => handleClick(event, row)}
                      className={classes.tableRow}
                    >
                      <TableCell align="center">
                        <Circle
                          color={row.severityColor}
                          borderColor={row.severityBorderColor}
                          size={15}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {isAuthority && (
                          <span style={{ display: "block", color: "blue" }}>
                            {row.user}
                          </span>
                        )}
                        {row?.message?.includes(
                          "Click here to view updates"
                        ) ? (
                          <>
                            {row.message.replace(
                              /Click here to view updates/g,
                              ""
                            )}
                            {!isAuthority && (
                              <span style={{ display: "block", color: "blue" }}>
                                {" "}
                                Click here to view updates
                              </span>
                            )}
                          </>
                        ) : (
                          row.message
                        )}
                      </TableCell>
                      <TableCell align="center">{row.datetime}</TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isAuthority && (
        <Box sx={{ position: "relative" }}>
          <Button
            onClick={() => handleClickGetOlderMessage()}
            variant="contained"
            sx={{
              width: "100%",
            }}
            // disabled={
            //   activePage === "today" && displayedTodayNotifications.length < 20
            // }
            disabled={loadingMessages}
          >
            {loadingMessages ? "Loading..." : "Load Older Messages"}
          </Button>
          {/* {loadingMessages && (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            /> */}
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={
          activePage === "today"
            ? displayedTodayNotifications.length
            : displayedOldNotifications.length
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DetailsOperationsDialog
        openDetailsDialog={openDetailsOperationsMapDialog}
        handleCloseDetailsDialog={handleCloseDetailsDialog}
        selectedDialogData={selectedDialogData}
        // handleopenAuthProposeOperationDrawer={}
        // handleOpenFlightAuthorizationAuthority={}
        handleOperatorProposeOperation={
          handleOperatorProposeOperationFromActionMessageTable
        }
      />
      <NotifsHelpDialog
        openNotifsHelpDialog={openNotifsHelpDialog}
        handleCloseNotifsDialog={handleCloseNotifsDialog}
      />
    </Paper>
  );
}
