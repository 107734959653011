import { htmlColors } from "./htmlColors";

export const heronTheme = {
  colors: {
    primary: "#0A2463",
    accent: "#3E92CC",
    heronBlue: "#51bff7",
    neutralBlack: "#1E1B18",
    neutralDarkGray: "#808080",
    neutralLightGray: "#A8A8A8",
    neutralLight: "#F2F2F2",
    neutralWhite: "#FFFAFF",
    semanticRed: "#D8315B",
    semanticGreen: "#52D5BA",
    semanticYellow: htmlColors.BROWN.GoldenRod,
    semanticOrange: "#FB6B18",
  },
  font: {
    body: "Roboto",
    heading: "Roboto",
  },
  borderRadius: 22,
};
