import * as React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import EmbedMapB from "../MapB/embedMapB";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { useApi } from "../../api/useApi";

const styles = {
  customMaxWidth: {
    maxWidth: "none", // arbitrary value
  },
};
export default function PilotLicenseExpiredDialog({
  openPilotExpiredDialog,
  handleClosePilotExpiredDialog,
  pilotsDataAlert,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const api = useApi();
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        // disableBackdropClick
        // fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        open={openPilotExpiredDialog}
        onClose={handleClosePilotExpiredDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <LoadingOverlay
          active={submitLoading}
          spinner
          text="Submitting operation..."
        >
          <DialogTitle id="responsive-dialog-title">
            The following pilots&apos; license is expiring soon.
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={1}
              // direction="column"
              justifyContent="flex-start"
            >
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Pilot
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Expiry
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {pilotsDataAlert.map((pilots) => (
              // <li key={pilots.pilot_uuid}>{pilots.pilot_name}</li>
              <Grid
                key={pilots.pilot_uuid}
                container
                spacing={0}
                // direction="column"
                justifyContent="flex-start"
              >
                <Grid item xs={6}>
                  {pilots.pilot_name}
                </Grid>
                <Grid item xs={6}>
                  {convertZuluToLocalTime(pilots.registration_expiry)}
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={handleClosePilotExpiredDialog}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}
