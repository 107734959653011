import axios from "axios";
import { format as dateFormatter, subMinutes } from "date-fns";
import sampleWxRadar from "./weatherlayer.png";
import { store } from "../../../store";

const rainMapTimestampFormat = "yyyyMMddHHmm";

/**
 * HEAD request to check if rain map exists for target date and time.
 * @param {number} date (epoch time)
 * @returns
 */
function checkByRequest(date) {
  // const useSampleRainLayer =
  //   store.getState().envVar.use_simulated_weather?.Value;
  // const timestamp = dateFormatter(date, rainMapTimestampFormat);
  // let url = `https://www.weather.gov.sg/files/rainarea/50km/v2/dpsri_70km_${timestamp}0000dBR.dpsri.png`;
  // if (useSampleRainLayer) {
  //   if (useSampleRainLayer.toString().toLowerCase() === "true") {
  //     url = sampleWxRadar;
  //   }
  // }
  const url = sampleWxRadar;

  return axios
    .head(url)
    .then((res) => {
      const obj = { code: res.status, url };
      return obj;
    })
    .catch((err) => {
      const obj = { code: err.response.status, url };
      return obj;
    });
}

/**
 * Returns the newest possible rain map url based on current time.
 * @param {number} date (epoch time)
 * @param {number} maxRequests maximum failed requests to check for map, before giving up
 * @returns {Promise<string|null>} url of the rain map, null if no map found when max request has hit.
 */
export function getLatestRainMapURL(date, maxRequests = 5) {
  function handle(res) {
    if (res.code === 200) {
      return res.url;
    }
    if (maxRequests === 0) {
      return null;
    }
    const nextDate = subMinutes(date, 10);
    const nextRequestLimit = maxRequests - 1;

    return getLatestRainMapURL(nextDate, nextRequestLimit);
  }

  return checkByRequest(date).then(handle).catch(handle);
}
