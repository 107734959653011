import React, { useEffect, useState, useRef } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { FaPlus } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosMore,
} from "react-icons/io";
import { Operation, OperationJson } from "@/model/api/Operation";
import Pilot from "@/model/api/Pilot";
import LatLng from "@/model/LatLng";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import Waypoint from "@/model/Waypoint";
import { CreateOperationType } from "@/enum/CreateOperationType";
import { SelectedCircleProperties } from "@/pages/v1/Dashboard/DashboardModel";
import FlightPlan from "@/model/FlightPlan";
import { setOperationFormRequest } from "@/store/actions";
import moment from "moment";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { handleKmlImport, handleJsonImport } from "@/services/json";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import ItemOperation from "./ItemCards/ItemOperation";
import CreateFlightPlanForm from "./CreateFlightPlanForm";
import RerouteRescheduleDialog from "../../Dialog/RerouteRescheduleDialog";

interface FlightAuthorizationSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  /* Route Operation */
  selectedWaypoint: number[][];
  /* Polygon Operation */
  selectedAreaWaypoints: number[][];
  /* Circle Operation */
  selectedCircleWaypoints: number[][];
  setSelectedCircleWaypoints: (waypoints: number[][]) => void;
  selectedCircleProperties: SelectedCircleProperties;
  setSelectedCircleProperties: (properties: SelectedCircleProperties) => void;
  /*  */
  setIsFormOpen: (value: boolean) => void;
  onWaypointUpdated: (waypoints: number[][]) => void;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
  onCloseSidebar: () => void;
  handleSetEmergencyLanding: (waypoint: [number, number]) => void;
  emergencyLanding: number[];
  handleOperationFormWaypointChange: (
    formData: Waypoint[],
    type: CreateOperationType | "ResetForm"
  ) => void;
  handleEditOperation: (operationId: string) => void;
  isAuthority?: boolean;
  hasOperationWriteRight: boolean;
  hasPilotAssetReadRight: boolean;
  hasPlatformAssetReadRight: boolean;
  hasTrackerAssetReadRight: boolean;
}

export default function FlightAuthorizationSidebar({
  api,
  selectedWaypoint,
  selectedCircleWaypoints,
  selectedAreaWaypoints,
  // setSelectedCircleWaypoints,
  selectedCircleProperties,
  // setSelectedCircleProperties,
  setIsFormOpen,
  onWaypointUpdated,
  onConfirmation,
  showMessage,
  goToLocation,
  onOpenModal,
  onCloseModal,
  onCloseSidebar,
  handleSetEmergencyLanding,
  emergencyLanding,
  handleOperationFormWaypointChange,
  handleEditOperation,
  isAuthority,
  hasOperationWriteRight,
  hasPilotAssetReadRight,
  hasPlatformAssetReadRight,
  hasTrackerAssetReadRight,
}: FlightAuthorizationSidebarProps) {
  const [selectedOption, setSelectedOption] = useState("Route");
  const [selectedOperationType, setSelectedOperationType] =
    useState<CreateOperationType>(CreateOperationType.ROUTE);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  /* More Options */
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditOperation, setIsEditOperation] = useState(false);

  /* Operation Data */
  const [upcomingOperations, setUpcomingOperations] = useState<Operation[]>([]);
  const [pastpOperations, setPastOperations] = useState<Operation[]>([]);
  const [operations, setOperations] = useState<Operation[]>([]);

  /* Assets */
  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  /* Revision Dialog State */
  const [revisionAction, setRevisionAction] = useState({
    isDiscard: false,
    isEdit: false,
    isResubmit: false,
    isAccepted: false,
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [lastRefreshTime, setLastRefreshTime] = useState<string>("");

  const inputButtonRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const operationFormRequest = useSelector(
    (state: RootStateOrAny) => state.operations.operationFormRequest
  );

  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);

    if (option === 1) {
      fetchOperations(true);
    } else {
      setOperations(option === 0 ? upcomingOperations : pastpOperations);
    }
  };

  const handleSelectedOption = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    switch (option) {
      case "Route":
        setSelectedOperationType(CreateOperationType.ROUTE);
        break;
      case "Circle":
        setSelectedOperationType(CreateOperationType.CIRCLE);
        break;
      case "Polygon":
        setSelectedOperationType(CreateOperationType.POLYGON);
        break;
      default:
        setSelectedOperationType(CreateOperationType.ROUTE);
    }
  };

  const handleOperationClicked = (operation: Operation) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Operation</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.intent}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Departure</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_start.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Arrival</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.interuss.operational_intent_reference
                    .time_end.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPilotName(operation.operation_json.request.pilot_uuid[0])}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Platform</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getPlatformName(
                  operation.operation_json.request.platform_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Tracker</td>
              <th>:</th>
              <td className="px-4 py-2">
                {getTrackerName(
                  operation.operation_json.request.tracker_uuid[0]
                )}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Status</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.details.state}
              </td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Operation ID</td>
              <th>:</th>
              <td className="px-4 py-2">
                {operation.operation_json.reference.id}
              </td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Created On</td>
              <th>:</th>
              <td className="px-4 py-2">
                {moment(
                  operation.operation_json.reference.time_created.value
                ).format("DD/MM/YYYY HH:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Operation Detail", content);
  };

  const handleOperationSelected = (operationId: string) => {
    const index = selectedIds.indexOf(operationId);
    if (index === -1) {
      setSelectedIds([...selectedIds, operationId]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== operationId));
    }
  };

  const handleDeleteOperation = () => {
    setShowMoreOptions(false);

    onConfirmation(
      "Delete Operation",
      "Are you sure you want to delete the selected operation? Action cannot be undone",
      (result) => {
        if (result) {
          const operationPromises = selectedIds.map((operationId) =>
            api.deleteOperation(operationId)
          );

          setIsDeleting(true);
          showMessage("Deleting Operation, Please Wait");

          Promise.all(operationPromises)
            .then(() => {
              showMessage && showMessage("Operation Deleted", true);
              setSelectedIds([]);
              fetchOperations(selectedTab === 1);
            })
            .catch(() => {
              showMessage("Unable to Delete Operation", false, true);
            })
            .finally(() => setIsDeleting(false));
        }
      }
    );
  };

  const handleDisplayOperation = () => {
    const operationPromises = selectedIds.map((operationId) =>
      api.addOperationSelection(operationId)
    );

    setIsDisplaying(true);

    Promise.all(operationPromises)
      .then(() => {
        showMessage && showMessage("Operation Displayed", true);
        setSelectedIds([]);
      })
      .catch(() => {
        showMessage("Unable to Display Operation", false, true);
      })
      .finally(() => setIsDisplaying(false));
  };

  const handleAddNew = () => {
    setIsAdd(true);
    setIsFormOpen(true);
  };

  /* Import Operation */
  const handleImportButtonClick = () => {
    if (inputButtonRef.current) {
      inputButtonRef.current.click();
    }
  };

  const handleImportOperation = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<string | undefined> => {
    try {
      if (!e.target.files) return "";
      const [file] = e.target.files;
      e.target.files = null; // Clear the input field
      const importFile = (reader: FileReader) => {
        return new Promise((resolve) => {
          reader.addEventListener("load", () => {
            resolve(reader.result);
          });
          if (file) {
            reader.readAsText(file);
          }
        });
      };
      if (file.type === "application/json") {
        const reader = new FileReader();
        const rawData = await importFile(reader);
        const data = typeof rawData === "string" ? JSON.parse(rawData) : "";
        handleJsonImport(data, dispatch);
      } else if (file.type === "") {
        const reader = new FileReader();
        const kmlData = await importFile(reader);
        handleKmlImport(kmlData, dispatch);
      } else {
        showMessage &&
          showMessage("Import must be a JSON or KML file.", false, true);
      }
    } catch {
      showMessage("Invalid file", false, true);
    }
  };

  useEffect(() => {
    if (!operationFormRequest) return;
    if (operationFormRequest.type === "edit") {
      setIsAdd(true);
      setIsFormOpen(true);
      setIsEditOperation(true);
    }
    if (operationFormRequest.type === "duplicate") {
      setIsAdd(true);
      setIsFormOpen(true);
    }
    // change tab based on operation type (route, circle, area)
    if (!operationFormRequest?.request?.properties) {
      handleSelectedOption("Route");
    } else if (operationFormRequest?.request?.properties?.isCircle) {
      handleSelectedOption("Circle");
    } else {
      handleSelectedOption("Polygon");
    }
  }, [operationFormRequest]);

  /* End of Import Operation */

  /* Editing Operation */
  const handleEditOperationClick = async () => {
    setIsEditOperation(true);
    await handleEditOperation(selectedIds[0]);
    setShowMoreOptions(false);
    setIsAdd(true);
    setIsFormOpen(true);
  };

  const handleReturnToList = () => {
    setIsAdd(false);
    setIsEditOperation(false);
    setIsFormOpen(false);
    dispatch(setOperationFormRequest(null));
    setRevisionAction({
      isDiscard: false,
      isEdit: false,
      isResubmit: false,
      isAccepted: false,
    });
    if (isAuthority) onCloseModal();
  };

  const handleOnOperationSubmitted = () => {
    setIsAdd(false);
    setIsEditOperation(false);
    setIsFormOpen(false);
    onWaypointUpdated([]);
    dispatch(setOperationFormRequest(null));
    fetchOperations();
    setRevisionAction({
      isDiscard: false,
      isEdit: false,
      isResubmit: false,
      isAccepted: false,
    });
    if (isAuthority) onCloseModal();
  };

  const handleOnOperationRevision = (
    isRerouted: boolean,
    isRescheduled: boolean,
    operationData: OperationJson,
    flightPlan: FlightPlan
  ) => {
    const content = (
      <RerouteRescheduleDialog
        isRerouted={isRerouted}
        isRescheduled={isRescheduled}
        compareData={operationData}
        flightPlan={flightPlan}
        onAcceptChanges={() => handleRevisionDialogAction("isAccepted")}
        onDiscardPlan={() => handleRevisionDialogAction("isDiscard")}
        onResubmitPlan={() => handleRevisionDialogAction("isResubmit")}
        onEditPlan={() => handleRevisionDialogAction("isEdit")}
      />
    );

    onOpenModal("Operation Was Re Routed", content, false, true);
  };

  const handleRevisionDialogAction = (action: string) => {
    setRevisionAction({
      ...revisionAction,
      [action]: true,
    });
    onCloseModal();
  };

  const options = [
    "Route",
    "Circle",
    "Polygon",
    // "Upload Mission (Coming Soon)",
  ];

  const fetchOperations = async (all?: boolean) => {
    setIsError(false);
    setErrorMessage("");
    setIsLoading(true);
    try {
      const upcomingResponse = await api.getOperations(false, 0, true, false);
      const isPastResponse = await api.getOperations(true, 0, true, false);
      if (upcomingResponse.error || isPastResponse.error) {
        setIsError(true);
        setErrorMessage("Unable to Retrive Operations Data");
      } else {
        const allOperations = [
          ...upcomingResponse.data,
          ...isPastResponse.data,
        ];
        const UpcomingOperationsSorted = upcomingResponse.data.sort(
          (a: Operation, b: Operation) => {
            const timeStartA = new Date(
              a.operation_json.interuss.operational_intent_reference.time_start.value
            );
            const timeStartB = new Date(
              b.operation_json.interuss.operational_intent_reference.time_start.value
            );
            return timeStartB.getTime() - timeStartA.getTime();
          }
        );
        const allOperationsSorted = allOperations.sort(
          (a: Operation, b: Operation) => {
            const timeStartA = new Date(
              a.operation_json.interuss.operational_intent_reference.time_start.value
            );
            const timeStartB = new Date(
              b.operation_json.interuss.operational_intent_reference.time_start.value
            );
            return timeStartB.getTime() - timeStartA.getTime();
          }
        );

        setPastOperations(allOperationsSorted);
        setUpcomingOperations(UpcomingOperationsSorted);
        if (all) {
          setOperations(allOperationsSorted);
        } else {
          setOperations(UpcomingOperationsSorted);
        }
      }
      const currentTime = moment(new Date()).format("HH:mm A");

      setLastRefreshTime(currentTime);
      setIsLoading(false);
    } catch (e) {
      setIsError(true);
      setErrorMessage("Unable to Retrive Operations Data");
    }
  };

  const fetchPilots = async () => {
    if (hasPilotAssetReadRight) {
      const response = await api.getPilots();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Pilots Data", false, true);
        setIsError(true);
      } else {
        setPilots(response.data);
      }
    }
  };

  const fetchPlatforms = async () => {
    if (hasPlatformAssetReadRight) {
      const response = await api.getPlatforms();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Platforms Data", false, true);
      } else {
        setPlatforms(response.data);
      }
    }
  };

  const fetchTrackers = async () => {
    if (hasTrackerAssetReadRight) {
      const response = await api.getTracker();
      if (response.error) {
        showMessage &&
          showMessage("Unable to Retrive Trackers Data", false, true);
      } else {
        setTrackers(response.data);
      }
    }
  };

  const copyOperationId = (operationId: string) => {
    navigator.clipboard.writeText(operationId);
    showMessage && showMessage("Operation ID Copied", true);
  };

  const getPilotName = (pilotId: string) => {
    const pilot = pilots.find((p) => p.pilot_uuid === pilotId);
    return pilot ? pilot.pilot_username : "Unknown Pilot";
  };

  const getPlatformName = (platformId: string) => {
    const platform = platforms.find((p) => p.platform_uuid === platformId);
    return platform ? platform.platform_callsign : "Unknown Platform";
  };

  const getTrackerName = (trackerId: string) => {
    const tracker = trackers.find((t) => t.tracker_uuid === trackerId);
    return tracker ? tracker.tracker_name : "Unknown Tracker";
  };

  useEffect(() => {
    if (api) {
      setIsLoading(true);
      fetchPilots().then(() => fetchOperations());
      fetchPlatforms();
      fetchTrackers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>Flight Authorization</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            <div className="flex-col px-4">
              {/* Main Tab */}
              <div className="tab-rounded mt-2">
                <span
                  className={`${selectedTab === 0 && "tab-active"} mr-1`}
                  onClick={() => handleSelectedTab(0)}
                >
                  Upcoming
                </span>
                <span
                  className={`${selectedTab === 1 && "tab-active"} ml-1`}
                  onClick={() => handleSelectedTab(1)}
                >
                  All
                </span>
              </div>
              <div className="flex px-4 py-2 mt-2">
                <h5 className="font-medium ml-2">Operations</h5>
                <span className="grow" />
                {/* <button
                  onClick={() => showMessage && showMessage("Coming Soon")}
                >
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Sort by : Date
                  </span>
                </button> */}
                <button onClick={() => fetchOperations(selectedTab === 1)}>
                  <span className="text-primary-600 text-sm font-medium mr-2">
                    Last Refreshed : {lastRefreshTime}
                  </span>
                </button>
              </div>
            </div>
            {/* Content */}
            <div className="h-[80%] flex-col overflow-auto p-4">
              {/* Operation List */}
              {!isLoading &&
                operations
                  // .filter((operation) => {
                  //   if (selectedTab === 1) return true;
                  //   return operation.is_upcoming === true;
                  // })
                  .map((operation) => (
                    <ItemOperation
                      key={operation.operation_json.reference.id}
                      operation={operation}
                      pilotName={getPilotName(
                        operation.operation_json.request.pilot_uuid[0]
                      )}
                      trackerName={getTrackerName(
                        operation.operation_json.request.tracker_uuid[0]
                      )}
                      isDeleting={isDeleting}
                      isSelected={selectedIds.includes(
                        operation.operation_json.reference.id
                      )}
                      copyOperationId={copyOperationId}
                      onClick={handleOperationClicked}
                      onSelected={handleOperationSelected}
                    />
                  ))}

              {/* No Data Available */}
              {!isLoading && operations.length === 0 && (
                <p className="p-8 text-center">No Data Available</p>
              )}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          <div className="h-[10%] px-4 py-2 items-center content-center">
            {selectedIds.length > 0 ? (
              <div className="flex">
                {/* <Button
                  type="danger"
                  size="medium"
                  text="Delete"
                  className="mr-2"
                  isLoading={isDeleting}
                  disabled={isDeleting || isDisplaying}
                  onClick={handleDeleteOperation}
                /> */}

                <Button
                  type="light"
                  size="medium"
                  text="Display"
                  className="mr-2"
                  isLoading={isDisplaying}
                  disabled={isDeleting || isDisplaying}
                  onClick={handleDisplayOperation}
                />
                {hasOperationWriteRight && (
                  <Button
                    type="light"
                    size="medium"
                    fixedWidth={true}
                    className="mr-2 w-20"
                    isLoading={isDisplaying || isDeleting}
                    icon={<IoEllipsisHorizontal size={24} />}
                    onClick={() => setShowMoreOptions(!showMoreOptions)}
                    // isLoading={isDeleting}
                    // disabled={isDeleting || isDisplaying}
                    // onClick={handleDeleteOperation}
                  />
                )}
              </div>
            ) : (
              <div className="flex mt-4 mb-8 pb-8">
                <button
                  className="mr-4 btn-primary-dark w-full h-12"
                  onClick={handleImportButtonClick}
                >
                  Import
                  <input
                    type="file"
                    onChange={handleImportOperation}
                    hidden
                    ref={inputButtonRef}
                  />
                </button>

                {hasOperationWriteRight && (
                  <Button
                    type="primaryDark"
                    size="medium"
                    text="Create Flight Plan"
                    icon={<FaPlus size={12} color="white" />}
                    onClick={handleAddNew}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">Create Flight Plan</h5>
          </div>
          <hr />

          <h5 className="font-medium ml-6 mt-4 mb-2">
            Select Flight Plan Type
          </h5>
          {/* Dropdown Option Picker */}
          <div className="relative px-4 mb-4">
            <button
              className="input-select"
              type="button"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <span className="grow">{selectedOption}</span>
              {isDropdownOpen ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </button>

            {isDropdownOpen && (
              <div className="absolute top-14 w-[95%] z-[150]">
                <ul className="dropdown-ul">
                  {options.map((option) => (
                    <li
                      key={option}
                      className="dropdown-li"
                      onClick={() => handleSelectedOption(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Form */}
          <CreateFlightPlanForm
            api={api}
            selectedWaypoint={selectedWaypoint}
            selectedAreaWaypoints={selectedAreaWaypoints}
            selectedCircleWaypoint={selectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            pilots={pilots}
            platforms={platforms}
            trackers={trackers}
            operationType={selectedOperationType}
            revisionAction={revisionAction}
            showMessage={showMessage}
            onConfirmation={onConfirmation}
            goToLocation={goToLocation}
            onOperationSubmitted={handleOnOperationSubmitted}
            onOperationRevision={handleOnOperationRevision}
            onCloseSidebar={onCloseSidebar}
            handleSetEmergencyLanding={handleSetEmergencyLanding}
            emergencyLanding={emergencyLanding}
            operationFormRequest={operationFormRequest}
            handleOperationFormWaypointChange={
              handleOperationFormWaypointChange
            }
            isEditOperation={isEditOperation}
            setIsEditOperation={setIsEditOperation}
            isAuthority={isAuthority}
            hasOperationWriteRight={hasOperationWriteRight}
          />
        </div>
      )}

      {/* Button Options */}
      {showMoreOptions && (
        <div className="w-40 h-18 absolute bottom-40 right-6 popup-menu">
          {/* Options concisted of export, replay, etc */}
          <ul className="flex-col">
            <li onClick={handleEditOperationClick}>Edit</li>
            <li onClick={handleDeleteOperation}>Delete</li>
          </ul>
        </div>
      )}
    </div>
  );
}
