import axios from "axios";
import config from "../config.json";

export const weatherApi = () => {
  const weather = axios.create({
    baseURL: "https://api.openweathermap.org",
  });

  const weatherMap = axios.create({
    baseURL: "https://api.openweathermap.org",
  });

  const getWeatherData = ({ latitude, longitude }) =>
    weather.request({
      method: "get",
      url: `/data/2.5/onecall`,
      params: {
        lat: latitude,
        lon: longitude,
        units: "metric",
        appid: config.weather_api_key,
      },
    });

  const getRainData = ({ latitude, longitude }) =>
    weatherMap.request({
      method: "get",
      url: `/data/2.5/weather`,
      params: {
        // appid: "c001009e45d9c6d4905f37671a5a4b2c"
        // lat: 46.20541866959406, // Geneva
        // lon: 6.141321754299673,
        // lat: 46.28795558628513, // Versoix
        // lon: 6.165624982320921,
        lat: 46.23706100885918, // Geneva Airport
        lon: 6.109099526872633,
        appid: config.weather_api_key,
      },
    });

  return {
    getWeatherData,
    getRainData,
  };
};
