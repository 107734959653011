/**
 * Convert number value from knots to km/h.
 * @param {number} knots
 * @returns {number} km/h
 */
function toKmph(knots) {
  return (knots * 1.852).toFixed(1);
}

/**
 * Invert the angle.
 * @param {number} angle
 * @returns {number} inverted angle
 */
function invert(angle) {
  return (parseInt(angle, 10) + 180) % 360;
}

/**
 * Generate html element for wind speed and direction marker.
 * Accepts string inputs from api response. No need to parse as number.
 * @param {string} windSpeed
 * @param {string} windDirection
 * @returns {HTMLDivElement} html div that represents the marker.
 */
export function generateMarker(windSpeed, windDirection) {
  const content = document.createElement("div");
  content.classList.add("wind-marker");

  const kmph = toKmph(parseFloat(windSpeed)); // original in knots
  const speedLabel = document.createElement("div");
  speedLabel.textContent = `${kmph}  `;
  speedLabel.style.width = "max-content";

  const arrow = document.createElement("i");
  arrow.classList.add("icon-wind-chevron-up");
  arrow.style.paddingLeft = "5px";
  arrow.style.paddingRight = "5px";
  arrow.style.color = "rgba(0,0,0,0.5)";
  content.append(speedLabel);
  content.append(arrow);

  content.style.position = "absolute";
  content.style.transform = "translate(-50%, -100%)";

  const inverseDirection = invert(parseFloat(windDirection));
  arrow.style.transform = `rotate(${inverseDirection}deg)`; // needs inverted, wind direction arrows are confusing.

  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.cursor = "pointer";
  container.appendChild(content);
  return container;
}

/**
 * Generate html markup in string for popup of the wind markers.
 * Accepts string inputs from api response. No need to parse as number.
 * @param {string} name
 * @param {string} windDirection
 * @param {string} windSpeed
 * @param {string} lastUpdated should be pre-formatted.
 * @returns {string} html element
 */
export function generatePopupContent(
  name,
  windDirection,
  windSpeed,
  lastUpdated
) {
  const floatDirection = parseFloat(windDirection);
  const inverseDirection = invert(floatDirection);
  const kmph = toKmph(parseFloat(windSpeed));
  const directionInt = Math.round(floatDirection);
  const popupContent = `
        <div class='display-flex flex-direction-column center-content' style='padding-left: 10px; padding-right: 10px'>
            <span style='font-size: 15px; font-weight: 500;'>${name}</span>
            <div class='display-flex flex-direction-row flex1'>
                <div class='display-flex flex-direction-column center-content flex1' style='margin: 5px'>
                    <i class='icon icon-wind-speed info-window-icon'></i>
                    <span style='font-size: 14px; font-weight: 400; opacity: 0.6'>Speed</span>
                    <span style='font-size: 17px; font-weight: 700;'>${kmph}</span>
                    <span style='font-size: 13px; font-weight: 700;'>km/h</span>
                </div>
                <div class='display-flex flex-direction-column center-content flex1' style='margin: 5px'>
                    <i class='icon icon-wind-chevron-up info-window-icon' style='transform: rotate(${inverseDirection}deg);'></i>
                    <span style='font-size: 14px; font-weight: 400; opacity: 0.6'>Direction</span>
                    <span style='font-size: 17px; font-weight: 700;'>${directionInt}&#176;</span>
                    <span style='font-size: 13px; font-weight: 700;'>(Source)</span>
                </div>
            </div>
            <span style='font-size: 12px; font-weight: 300;'>${lastUpdated}</span>
        </div>
    `;

  return popupContent;
}

/**
 * Predefined offset values so that popup will not be displayed offscreen.
 */
export const popupOffsets = {
  // [x, y]
  top: [0, -10],
  "top-left": [0, -10],
  "top-right": [0, -10],
  bottom: [0, -30],
  "bottom-left": [0, -30],
  "bottom-right": [0, -30],
  left: [30, -20],
  right: [-30, -20],
};
