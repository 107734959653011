import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { useApi } from "../../api/useApi";

import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage}
    />
  );
}

export default function AddPilotFormComponent({
  open,
  updating,
  getPilots,
  pilotData,
  handleClose,
  handlePilotUpdate,
}) {
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");

  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const [license, setLicense] = React.useState("");
  const [licenseError, setLicenseError] = React.useState("");

  const [expiry, setExpiry] = React.useState(new Date());
  const [expiryError, setExpiryError] = React.useState("");

  const [submittingNewPilot, setSubmittingNewPilot] = React.useState(false);
  const api = useApi();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (updating) {
      const {
        pilot_name,
        pilot_email,
        pilot_contact,
        pilot_registration,
        registration_expiry,
      } = pilotData;
      setName(pilot_name);
      setEmail(pilot_email);
      setPhone(pilot_contact);
      setLicense(pilot_registration);
      setExpiry(new Date(registration_expiry));
    } else {
      setName("");
      setEmail("");
      setPhone("");
      setLicense("");
      setExpiry(new Date());
    }
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setLicenseError("");
    setExpiryError("");
  }, [open]);

  const submitNewPilot = async () => {
    try {
      const response = await api.createPilot({
        name,
        username,
        email,
        phone,
        license,
        expiry,
      });
      if (response.data) {
        handlePilotUpdate(response.data);
        setSubmittingNewPilot(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Pilot created",
            severity: "success",
          })
        );
        getPilots();
        handleClose();
      }
    } catch (err) {
      setSubmittingNewPilot(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const updatePilotInfo = async () => {
    try {
      const response = await api.updatePilot(pilotData.pilot_uuid, {
        name,
        email,
        phone,
        license,
        expiry,
        username: pilotData.pilot_username,
      });
      if (response.data) {
        handlePilotUpdate(response.data);
        setSubmittingNewPilot(false);
        getPilots();
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Pilot updated",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      console.log(err.response);
      setSubmittingNewPilot(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.data,
          severity: "error",
        })
      );
    }
  };

  const validateForm = () => {
    let error = false;
    if (!name) {
      setNameError("Pilot name is required");
      error = true;
    }
    if (!updating) {
      if (!username) {
        setUsernameError("Pilot username is required");
        error = true;
      }
      if (username.includes(" ")) {
        setUsernameError("Pilot username must not contain spaces");
        error = true;
      }
    }
    if (!email) {
      setEmailError("Pilot email is required");
      error = true;
    }
    if (!phone) {
      setPhoneError("Pilot phone number is required");
      error = true;
    }
    if (!license) {
      setLicenseError("Pilot license number is required");
      error = true;
    }
    if (!expiry) {
      setExpiryError("Pilot expiry date is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewPilot(true);
    if (updating) {
      updatePilotInfo();
    } else {
      submitNewPilot();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <LoadingOverlay
        active={submittingNewPilot}
        spinner
        text={
          updating ? "Updating pilot information..." : "Submitting new pilot..."
        }
      >
        <div style={{ height: "400px", width: "500px", margin: "30px" }}>
          <Typography
            component="div"
            display="inline"
            variant="h6"
            style={{ color: "black" }}
          >
            {updating ? "Update Pilot" : "Add Pilot"}
          </Typography>
          <CustomTextField
            label="Name"
            value={name}
            setFuncton={setName}
            fullWidth
            errormessage={nameError}
            setError={setNameError}
          />
          {!updating ? (
            <CustomTextField
              label="Username"
              value={username}
              setFuncton={setUsername}
              fullWidth
              errormessage={usernameError}
              setError={setUsernameError}
            />
          ) : null}
          <CustomTextField
            label="Email"
            value={email}
            setFuncton={setEmail}
            fullWidth
            errormessage={emailError}
            setError={setEmailError}
          />
          <CustomTextField
            label="Contact"
            value={phone}
            setFuncton={setPhone}
            fullWidth
            errormessage={phoneError}
            setError={setPhoneError}
          />
          <CustomTextField
            label="Registration No."
            value={license}
            setFuncton={setLicense}
            fullWidth={false}
            errormessage={licenseError}
            setError={setLicenseError}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              style={{ marginLeft: "8px" }}
              id="Expiry-Date"
              label="Registration Expiry Date"
              format="MM/dd/yyyy"
              value={expiry}
              errormessage={expiryError}
              onChange={(date) => setExpiry(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
