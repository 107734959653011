import React, { useState, useEffect, useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Divider from "@material-ui/core/Divider";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";
import PuckServer from "../../../services/puckserver";

import generateKML from "../../../services/kml";
import { useApi } from "../../../api/useApi";

import {
  setFocussedOperation,
  setSnackbarMessage,
  setEditConstraint,
} from "../../../store/actions";

import {
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
  CONSTRAINT_STATES,
} from "../../../config/flightStatusColor";

const puckServer = PuckServer;

const ConstraintOperationControlList = React.memo(
  ({
    onRemoveClick,
    constraintsData,
    onopenConstrainMgrAuthority,
    handleOpenConstraintPanel,
    seteditConstraints,
    editConstrainStatus,
  }) => {
    // function ConstraintOperationControlList({
    //   onRemoveClick,
    //   constraintsData,
    //   onopenConstrainMgrAuthority,
    //   seteditConstraints,
    //   editConstrainStatus,
    // }) {
    const mapboxController = useSelector(
      (state) => state.maps.mapboxController
    );
    const dispatch = useDispatch();
    const [focusedConstrain, setFocusedConstrain] = useState();
    const [upcomingOperationsConstrain, setUpcomingOperationsConstrain] =
      useState([]);
    const handleOnFocus = (operationOnFocus) => {
      const coordinate = {
        reference: {
          id: operationOnFocus?.constraint_id,
        },
        request: {
          arrival:
            operationOnFocus?.extents[0].volume.outline_polygon
              .coordinates[0][0],
        },
      };
      mapboxController.setFocussedOperation(coordinate);
      // dispatch(setFocussedOperation(operationOnFocus));
      setFocusedConstrain(coordinate ? coordinate.reference.id : null);
    };
    useEffect(() => {
      getGlobaConstraints();
    }, [constraintsData]);

    const getGlobaConstraints = async () => {
      const responseConstrain = constraintsData;
      if (!responseConstrain) return;
      const currentTime = new Date();
      const oldDateObj = new Date();
      const newDateObj = new Date();
      newDateObj.setTime(oldDateObj.getTime() - 10 * 60 * 1000);
      const responseConstrainArray = [];
      responseConstrain.forEach((constraintData) => {
        if (
          new Date(constraintData.extents[0].time_start.value).getTime() <=
            currentTime.getTime() ||
          new Date(constraintData.extents[0].time_end.value).getTime() >
            currentTime.getTime()
        ) {
          responseConstrainArray.push(constraintData);
        }
      });
      setUpcomingOperationsConstrain(responseConstrainArray);
    };
    return upcomingOperationsConstrain.map((currentConstraint, index) => (
      <ConstraintOperationControl
        key={currentConstraint.constraint_id}
        currentConstraint={currentConstraint}
        highlighted={currentConstraint.constraint_id === focusedConstrain}
        onFocus={handleOnFocus}
        onRemoveClick={onRemoveClick}
        onopenConstrainMgrAuthority={onopenConstrainMgrAuthority}
        handleOpenConstraintPanel={handleOpenConstraintPanel}
        seteditConstraints={seteditConstraints}
        editConstrainStatus={editConstrainStatus}
      />
    ));
  }
);

ConstraintOperationControlList.displayName = "ConstraintOperationControlList";

function ConstrainDetails({ currentConstraint }) {
  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy HH:mm");
  let recurring = "Recurring -";
  let authRequired = "-";
  let authRecurring;
  let prohibitDescription = "-";
  let prohibitRecurring;
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getRecurringDescription = (recurrance, time_start, time_end) => {
    let startDesc;
    let endDesc;
    if (recurrance === "daily" || recurrance === "weekly") {
      startDesc = new Date(time_start).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      endDesc = new Date(time_end).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
    if (recurrance === "weekly") {
      startDesc += ` ${daysOfWeek[new Date(time_start).getDay()]}`;
      endDesc += ` ${daysOfWeek[new Date(time_end).getDay()]}`;
    }
    if (recurrance === "monthly") {
      startDesc = formatDate(time_start);
      endDesc = formatDate(time_end);
    }
    return `Reccurs ${recurrance} from ${startDesc} to ${endDesc}`;
  };
  // Recurring
  if ("recurring" in currentConstraint) {
    recurring = getRecurringDescription(
      currentConstraint.recurring,
      currentConstraint.recurrence_range.time_start.value,
      currentConstraint.recurrence_range.time_end.value
    );
  }

  // Authorisation Required
  if (currentConstraint.rule.authorisation_required) {
    authRequired = `: ${formatDate(
      currentConstraint.authorisation_conditions.time_start.value
    )}  -  ${formatDate(
      currentConstraint.authorisation_conditions.time_end.value
    )}`;
  }
  if (currentConstraint?.authorisation_conditions?.recurring) {
    authRequired = `: ${formatDate(
      currentConstraint.authorisation_conditions.recurrence_range.time_start
        .value
    )}  -  ${formatDate(
      currentConstraint.authorisation_conditions.recurrence_range.time_end.value
    )}`;

    authRecurring = getRecurringDescription(
      currentConstraint.authorisation_conditions.recurring,
      currentConstraint.authorisation_conditions.time_start.value,
      currentConstraint.authorisation_conditions.time_end.value
    );
  }

  // Prohibited Rule
  if (
    currentConstraint.rule.prohibited &&
    currentConstraint?.prohibited_conditions?.time_start?.value &&
    currentConstraint?.prohibited_conditions?.time_end?.value
  ) {
    prohibitDescription = `: ${formatDate(
      currentConstraint.prohibited_conditions.time_start.value
    )}  -  ${formatDate(
      currentConstraint.prohibited_conditions.time_end.value
    )}`;

    // in case of underfined time value
    if (
      currentConstraint?.prohibited_conditions?.recurring &&
      currentConstraint?.prohibited_conditions?.recurrence_range?.time_start &&
      currentConstraint?.prohibited_conditions?.recurrence_range?.time_end
        ?.value
    ) {
      prohibitDescription = `: ${formatDate(
        currentConstraint.prohibited_conditions.recurrence_range.time_start
          .value
      )}  -  ${formatDate(
        currentConstraint.prohibited_conditions.recurrence_range.time_end.value
      )}`;
      prohibitRecurring = getRecurringDescription(
        currentConstraint.prohibited_conditions.recurring,
        currentConstraint.prohibited_conditions.time_start.value,
        currentConstraint.prohibited_conditions.time_end.value
      );
    }
  }

  return (
    <div style={{ textAlign: "left" }}>
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {`Name: ${currentConstraint.name}`}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {`Desc: ${currentConstraint.description}`}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {currentConstraint.extents.map(
          (alt) =>
            `Alt: ${alt.volume.altitude_lower.value} to ${alt.volume.altitude_upper.value} meters (WGS84)`
        )}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {currentConstraint.extents.map(
          (time) =>
            `${formatDate(time.time_start.value)} - ${formatDate(
              time.time_end.value
            )}`
        )}{" "}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        Rules -{" "}
        {Object.keys(currentConstraint.rule)
          .filter((k) => currentConstraint.rule[k])
          .join(", ")
          .replace("_", " ")}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {recurring}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        Authorisation {authRequired}
      </Typography>
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {authRecurring}
      </Typography>
      <Divider />
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        Prohibited {prohibitDescription}
      </Typography>
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {prohibitRecurring}
      </Typography>
      <Divider />
    </div>
  );
}

function ConstraintOperationControl({
  onRemoveClick,
  highlighted,
  onFocus,
  currentConstraint,
  onopenConstrainMgrAuthority,
  seteditConstraints,
  editConstrainStatus,
  handleOpenConstraintPanel,
}) {
  const dispatch = useDispatch();
  const api = useApi();
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleRemoveClick = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      const response = await api.deleteAuthorityAreaConstrain(
        currentConstraint.constraint_id
      );
      if (response) {
        onRemoveClick(currentConstraint.constraint_id);
        dispatch(
          setSnackbarMessage({
            message: response.data.message,
            severity: "success",
          })
        );
        setSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (e) => {
    seteditConstraints(currentConstraint);
    onFocus(currentConstraint);
    onopenConstrainMgrAuthority();
    editConstrainStatus();
    dispatch(
      setEditConstraint({
        isEdit: true,
        data: currentConstraint,
      })
    );
  };
  const currentTime = new Date();
  let constraintStatus = "";
  if (
    new Date(currentConstraint.extents[0].time_start.value).getTime() <=
    currentTime.getTime()
  ) {
    constraintStatus = "Active";
  } else {
    constraintStatus = "Inactive";
  }
  const backgroundColor = () => {
    let caseColour = "";
    if (
      new Date(currentConstraint.extents[0].time_start.value).getTime() <=
      currentTime.getTime()
    ) {
      caseColour = "Activated";
    } else {
      caseColour = "Inactivated";
    }
    switch (caseColour) {
      case CONSTRAINT_STATES.ACTIVATED: {
        return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
      }
      case CONSTRAINT_STATES.INACTIVATED: {
        return `rgb(${HIGHLIGHT_COLORS.white.slice(0, 3).join(",")})`;
      }

      default:
        return "white";
    }
  };
  return (
    <LoadingOverlay active={submitLoading} spinner text="Processing...">
      <Paper>
        <Grid
          container
          spacing={0}
          style={{
            padding: "5px",
            marginBottom: "5px",
            backgroundColor: backgroundColor(),
          }}
          wrap="nowrap"
        >
          <Grid
            item
            container
            xs={2}
            alignItems="flex-start"
            justifyContent="center"
          >
            <div>
              <Button
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  backgroundColor: highlighted ? "blue" : "white",
                  color: highlighted ? "white" : "black",
                }}
                variant="contained"
                onClick={(e) => {
                  if (!highlighted) {
                    onFocus(currentConstraint);
                    handleOpenConstraintPanel();
                  } else {
                    onFocus(null);
                    handleOpenConstraintPanel();
                  }
                }}
              >
                {!highlighted ? "Focus" : "DeFocus"}
              </Button>
            </div>
            <div>
              <Button
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",

                  width: "20px",
                }}
                variant="contained"
                onClick={handleEditClick}
              >
                Edit
              </Button>
            </div>
            <div>
              <Button
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",

                  width: "20px",
                }}
                color="secondary"
                onClick={handleRemoveClick}
                variant="contained"
              >
                Delete
              </Button>
            </div>
          </Grid>
          <Grid
            item
            style={{
              width: "100%",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            <ConstrainDetails currentConstraint={currentConstraint} />
            <Typography
              style={{ fontWeight: "bold", color: "primary", fontSize: "12px" }}
            >
              Status - {constraintStatus}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
}

function ConstraintOperationsControlComponent({
  onRemoveClick,
  onUpdateOperationState,
  onVisabilityClick,
  constraints,
  onopenConstrainMgrAuthority,
  handleOpenConstraintPanel,
  seteditConstraints,
  editConstrainStatus,
}) {
  const [updatingOperation, setUpdatingOperation] = useState(false);

  return (
    <LoadingOverlay
      active={updatingOperation}
      spinner
      text="Updating Constraints..."
    >
      {constraints.length > 0 && (
        <ConstraintOperationControlList
          onRemoveClick={onRemoveClick}
          onUpdateOperationState={onUpdateOperationState}
          onVisabilityClick={onVisabilityClick}
          setUpdatingOperation={setUpdatingOperation}
          constraintsData={constraints}
          onopenConstrainMgrAuthority={onopenConstrainMgrAuthority}
          handleOpenConstraintPanel={handleOpenConstraintPanel}
          seteditConstraints={seteditConstraints}
          editConstrainStatus={editConstrainStatus}
        />
      )}
    </LoadingOverlay>
  );
}

export default ConstraintOperationsControlComponent;
