export default function recurringConstraintMessageHandler(
  startDate: Date,
  endDate: Date,
  recurrenceType: string
) {
  if (!startDate || !endDate) return;

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getDayWithSuffix = (date: Date) => {
    const day = date.getDate();
    const suffix =
      day >= 11 && day <= 13
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][
            day % 10
          ];

    return day + suffix;
  };

  let message = "";
  if (recurrenceType === "daily") {
    const descriptionDepartureTime = startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const descriptionEndTime = endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    message = `Recurs ${recurrenceType} : ${descriptionDepartureTime} to ${descriptionEndTime}`;
  }
  if (recurrenceType === "weekly") {
    const descriptionDepartureDay = daysOfWeek[startDate.getDay()];
    const descriptionDepartureTime = startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const descriptionEndDay = daysOfWeek[endDate.getDay()];
    const descriptionEndTime = endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    message = `Recurs weekly : ${descriptionDepartureDay} ${descriptionDepartureTime} to ${descriptionEndDay} ${descriptionEndTime}`;
  }
  if (recurrenceType === "monthly") {
    const descriptionDepartureDaySuffix = getDayWithSuffix(startDate);
    const descriptionDepartureTime = startDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const descriptionEndDaySuffix = getDayWithSuffix(endDate);
    const descriptionEndTime = endDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    message = `Recurs monthly from ${descriptionDepartureDaySuffix} at ${descriptionDepartureTime} till ${descriptionEndDaySuffix} at ${descriptionEndTime} `;
  }
  return message;
}
