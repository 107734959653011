const allPilotScopes = [
  "assets.maintenance.read",
  "assets.maintenance.write",
  "assets.permit.read",
  "assets.permit.write",
  "assets.pilot.read",
  "assets.pilot.write",
  "assets.platform.read",
  "assets.platform.write",
  "assets.platformtype.read",
  "assets.platformtype.write",
  "assets.tracker.read",
  "assets.tracker.write",
  "constraints.read",
  "general.logs.read",
  "general.organisation.read",
  "general.organisation.write",
  "general.privilege.read",
  "general.subscription.read",
  "general.user.read",
  "general.user.write",
  "operations.integrations.read",
  "operations.integrations.write",
  "operations.read",
  "operations.write",
  "sdsp.read",
  "telemetry.read",
];

const allFlightManagerScopes = [
  "assets.maintenance.read",
  "assets.maintenance.write",
  "assets.permit.read",
  "assets.permit.write",
  "assets.pilot.read",
  "assets.pilot.write",
  "assets.platform.read",
  "assets.platform.write",
  "assets.platformtype.read",
  "assets.platformtype.write",
  "assets.tracker.read",
  "assets.tracker.write",
  "constraints.read",
  "general.logs.read",
  "general.organisation.read",
  "general.organisation.write",
  "general.privilege.read",
  "general.subscription.read",
  "general.user.read",
  "general.user.write",
  "operations.integrations.read",
  "operations.integrations.write",
  "operations.read",
  "operations.write",
  "sdsp.read",
  "telemetry.read",
];

const allAirspaceManagerScopes = [
  "assets.maintenance.read",
  "assets.permit.read",
  "assets.pilot.read",
  "assets.pilot.write",
  "assets.platform.read",
  "assets.platformtype.read",
  "assets.tracker.read",
  "constraints.read",
  "general.logs.read",
  "general.organisation.read",
  "general.organisation.write",
  "general.privilege.read",
  "general.subscription.read",
  "general.user.read",
  "general.user.write",
  "operations.integrations.read",
  "operations.integrations.write",
  "operations.read",
  "constraints.write",
  "sdsp.read",
  "telemetry.read",
  "airspace.operations.read",
  "airspace.operations.write",
  "airspace.broadcast.write",
  "airspace.organisation.read",
  "airspace.organisation.write",
  "airspace.assets.read",
];

const allAdminScopes = [
  "assets.pilot.read",
  "assets.pilot.write",
  "general.logs.read",
  "general.organisation.read",
  "general.organisation.write",
  "general.privilege.read",
  "general.subscription.read",
  "general.user.read",
  "general.user.write",
  "general.privilege.write",
  "general.subscription.write",
];

const allGeneralScopes = [
  "operations.stream.read",
  "operations.stream.write",
  "operations.incident.read",
  "operations.incident.write",
  "airspace.organisations.read",
  "airspace.organisations.write",
  "operations.rid.read",
  "operations.rid.write",
];

type UserRole =
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot";

type UserRolesArr = UserRole[];

const getAvailableScopesToAssign = (
  selectedRoles: UserRolesArr,
  organisationAvailablePrivileges: string[]
) => {
  const availableScopesToAssign: string[] = [];

  organisationAvailablePrivileges.forEach((value) => {
    if (allGeneralScopes.includes(value)) {
      availableScopesToAssign.push(value);
    }
  });

  if (selectedRoles.includes("administrator")) {
    organisationAvailablePrivileges.forEach((value) => {
      if (allAdminScopes.includes(value)) {
        availableScopesToAssign.push(value);
      }
    });
  }
  if (selectedRoles.includes("airspace_manager")) {
    organisationAvailablePrivileges.forEach((value) => {
      if (allAirspaceManagerScopes.includes(value)) {
        availableScopesToAssign.push(value);
      }
    });
  }
  if (selectedRoles.includes("flight_manager")) {
    organisationAvailablePrivileges.forEach((value) => {
      if (allFlightManagerScopes.includes(value)) {
        availableScopesToAssign.push(value);
      }
    });
  }
  if (selectedRoles.includes("pilot")) {
    organisationAvailablePrivileges.forEach((value) => {
      if (allPilotScopes.includes(value)) {
        availableScopesToAssign.push(value);
      }
    });
  }

  const uniqueArray = [...new Set(availableScopesToAssign)];
  return uniqueArray;
};

const addScopesToAssignedRole = (
  selectedRoles: string,
  currentPrivileges: string[],
  organisationAvailablePrivileges: string[]
) => {
  const newPrivileges = [...currentPrivileges];

  allGeneralScopes.forEach((singleScope) => {
    if (!newPrivileges.includes(singleScope)) newPrivileges.push(singleScope);
  });

  if (selectedRoles === "administrator") {
    allAdminScopes.forEach((singleScope) => {
      if (!newPrivileges.includes(singleScope)) newPrivileges.push(singleScope);
    });
  }
  if (selectedRoles === "flight_manager") {
    allFlightManagerScopes.forEach((singleScope) => {
      if (!newPrivileges.includes(singleScope)) newPrivileges.push(singleScope);
    });
  }
  if (selectedRoles === "airspace_manager") {
    allAirspaceManagerScopes.forEach((singleScope) => {
      if (!newPrivileges.includes(singleScope)) newPrivileges.push(singleScope);
    });
  }
  if (selectedRoles === "pilot") {
    allPilotScopes.forEach((singleScope) => {
      if (!newPrivileges.includes(singleScope)) newPrivileges.push(singleScope);
    });
  }
  const newPrivilegesFiltered = newPrivileges.filter((singleScope) =>
    organisationAvailablePrivileges.includes(singleScope)
  );
  return newPrivilegesFiltered;
};

const removeScopesFromAssignedRole = (
  selectedRoles: UserRolesArr,
  currentPrivileges: string[]
) => {
  let minimumRequiredScopes = [...allGeneralScopes];
  if (selectedRoles.includes("administrator"))
    minimumRequiredScopes = [...minimumRequiredScopes, ...allAdminScopes];
  if (selectedRoles.includes("flight_manager"))
    minimumRequiredScopes = [
      ...minimumRequiredScopes,
      ...allFlightManagerScopes,
    ];
  if (selectedRoles.includes("airspace_manager"))
    minimumRequiredScopes = [
      ...minimumRequiredScopes,
      ...allAirspaceManagerScopes,
    ];
  if (selectedRoles.includes("pilot"))
    minimumRequiredScopes = [...minimumRequiredScopes, ...allPilotScopes];

  const privilegesWithRoleRemoved = currentPrivileges.filter(
    (singlePrivilege) => minimumRequiredScopes.includes(singlePrivilege)
  );
  return privilegesWithRoleRemoved;
};

export {
  getAvailableScopesToAssign,
  addScopesToAssignedRole,
  removeScopesFromAssignedRole,
};
