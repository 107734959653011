import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarMessage } from "../../store/actions";

const styles = {
  root: {
    width: "50%",
  },
};
function SnackbarMessage() {
  const open = useSelector((state) => state.snackbar.open);
  const message = useSelector((state) => state.snackbar.message);
  const severity = useSelector((state) => state.snackbar.severity);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      setSnackbarMessage({
        open: false,
        message,
        severity,
      })
    );
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity={severity || "info"}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarMessage;
