import { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CreateOperation from "../CreateOperation";
import UpcomingOperationsForAuthority from "../UpcomingOperationsForAuthority";
import PendingOperationsForAuthority from "../PendingOperationsForAuthority";
import ApprovHistoryOperationsForAuthority from "../ApprovHistoryOperationsForAuthority";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FlightAuthorizationComponent(props) {
  const {
    selectedFlightData,
    constraints,
    onEmergencyLandingUpdateMove,
    emergencyLandingMove,
    selectedWaypointsMove,
    onUpdateSelectedWaypointsMove,
    selectedAreaWaypointsMove,
    onUpdateSelectedAreaWaypointsMove,
    handleopenAuthProposeOperationDrawer,
    handleOpenFlightAuthorizationAuthority,
    flightAuthorizationAuthorityDrawerIsOpen,
  } = props;
  const [mapViewController, setMapViewController] = useState();

  const [selectedWaypoints, setSelectedWaypoints] = useState([]);
  const [selectedAreaWaypoints, setSelectedAreaWaypoints] = useState([]);
  const [emergencyLanding, setEmergencyLanding] = useState([]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // width: "100vw",
        overflow: "auto",
      }}
    >
      <div style={{ width: 600, overflow: "auto" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab label="Pending Approval" {...a11yProps(0)} />
            <Tab label="Approval History" {...a11yProps(1)} />
            <Tab label="Upcoming Operations" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <PendingOperationsForAuthority
            handleopenAuthProposeOperationDrawer={
              handleopenAuthProposeOperationDrawer
            }
            handleOpenFlightAuthorizationAuthority={
              handleOpenFlightAuthorizationAuthority
            }
            flightAuthorizationAuthorityDrawerIsOpen={
              flightAuthorizationAuthorityDrawerIsOpen
            }
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ApprovHistoryOperationsForAuthority />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UpcomingOperationsForAuthority />
        </TabPanel>
      </div>
    </div>
  );
}

export default FlightAuthorizationComponent;
