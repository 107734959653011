import React, { useState, useEffect, useRef, memo } from "react";
import Card from "@mui/material/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@mui/material/CardContent";
import CardTitle from "material-ui/Card/CardTitle";
import Paper from "material-ui/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import {
  PieChart,
  BarChart,
  StackedBarChart,
  CombinedBarChart,
} from "./addChart";
import { useApi } from "../../api/useApi";

import { setSnackbarMessage } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    padding: theme.spacing(0.5, 0.5),
    width: 390,
    height: 330,
    margin: theme.spacing(2),
  },
  card: {
    // boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    position: "relative",
    border: "1px solid #000",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2),
    width: 390,
    textAlign: "left",
  },
}));

function OperatorDetails(props) {
  const { chartData, operator } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardTitle>
        <Typography variant="h6" color="primary">
          {operator.operatorName.split(":")[1]}
        </Typography>
      </CardTitle>
      <Divider />
      <CardContent>
        <Typography variant="caption" display="block">
          Total Flight Time
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {chartData?.totalFlightDuration?.toFixed(2)} Hours
        </Typography>
        <Typography variant="caption" display="block">
          Operation Count
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {chartData?.totalOperationCount} Operations
        </Typography>
        <Typography variant="caption" display="block">
          Non Conformance Count
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {chartData?.countOfNonConformance} Operations
        </Typography>
        <Typography variant="caption" display="block">
          Total Non Conformance Time
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {chartData?.totalNonConformingDuration.toFixed(2)} Hours
        </Typography>
      </CardContent>
    </Card>
  );
}

function OperatorDashBoard(props) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [combinedChartData, setCombinedChartData] = useState({});
  const {
    operator,
    pilotList,
    platformList,
    platformTypeList,
    logState,
    isCompareMode,
    handleCloseOperatorStatistics,
  } = props;
  const api = useApi();
  const operatorOperationsRef = useRef({});
  const chartDataRef = useRef({});
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    operatorOperationsRef.current = {};
    operator.forEach((singleOperator) => {
      getData(singleOperator.operatorName);
    });
  }, []);

  const getData = async (operatorName, maxLoops = 2) => {
    try {
      const operationLength =
        operatorOperationsRef.current[operatorName]?.length || 0;
      // maxLoops caps the response at 200 operations
      const [operatorOperationsResponse] = await Promise.all([
        api.getOperationsLog(operationLength, operatorName),
      ]);
      if (operatorOperationsRef.current[operatorName]) {
        operatorOperationsRef.current[operatorName] = [
          ...operatorOperationsRef.current[operatorName],
          ...operatorOperationsResponse.data,
        ];
      } else {
        operatorOperationsRef.current[operatorName] =
          operatorOperationsResponse.data;
      }
      if (operatorOperationsResponse.data.length > 99 && maxLoops > 1) {
        getData(operatorName, maxLoops - 1);
      } else {
        organizeData();
        // disable overlay here
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to Obtain Data ${err}`,
          severity: "error",
        })
      );
      handleCloseOperatorStatistics();
    }
  };

  const cardDetails = isCompareMode
    ? [
        {
          cardHeader: "Operations Count",
          chartData: "opsCountbyOperator",
          type: "pieChart",
        },
        {
          cardHeader: "Conformance Count",
          chartData: "nonConformingbyOperator",
          type: "pieChart",
        },
        {
          cardHeader: "Operations Time",
          chartData: "opsTimebyOperator",
          type: "pieChart",
        },
        {
          cardHeader: "Conformance Time",
          chartData: "nonConformingTimebyOperator",
          type: "pieChart",
        },
        {
          cardHeader: "Operation and Conformance Time",
          chartData: "totalNonConformingDurationByOperator",
          type: "stackedBarChart",
        },
        {
          cardHeader: "Total Operations per Month",
          chartData: "totalOperationsPerMonthByOperator",
          type: "combinedBarChart",
        },
        {
          cardHeader: "Total Conformance per Month",
          chartData: "totalNonConformingPerMonthByOperator",
          type: "combinedBarChart",
        },
        {
          cardHeader: "Total Platform Type",
          chartData: "totalPlatformTypeByOperator",
          type: "combinedBarChart",
        },
      ]
    : [
        {
          cardHeader: "Operations By Pilot",
          chartData: "opsByPilot",
          type: "pieChart",
        },
        {
          cardHeader: "Operations By Platform",
          chartData: "opsByPlatform",
          type: "pieChart",
        },
        {
          cardHeader: "Operations By Platform Type",
          chartData: "opsByPlatformType",
          type: "pieChart",
        },
        {
          cardHeader: "Operations By Month",
          chartData: "opsByMonth",
          type: "pieChart",
        },
        {
          cardHeader: "Total Non Conforming by Pilot",
          chartData: "totalNonConformingDurationByPilot",
          type: "barChart",
        },
        {
          cardHeader: "Total Non Conforming by Platform",
          chartData: "totalNonConformingDurationByPlatform",
          type: "barChart",
        },
        {
          cardHeader: "Total Non Conforming by Platform Type",
          chartData: "totalNonConformingDurationByPlatformType",
          type: "barChart",
        },
        {
          cardHeader: "Total Non Conforming Duration by Month",
          chartData: "totalNonConformingDurationByMonth",
          type: "barChart",
        },
      ];

  const organizeData = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const checkTimeDifference = (timestamp1, timestamp2) => {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);

      const epochTime1 = date1.getTime();
      const epochTime2 = date2.getTime();

      const timeDifference = Math.abs(epochTime1 - epochTime2);
      const timeDifferenceInHours = parseFloat(
        (timeDifference / (1000 * 60 * 60)).toFixed(2)
      );

      return timeDifferenceInHours;
    };

    const combinedOrganizeDataComplete = {
      opsCountbyOperator: {},
      nonConformingbyOperator: {},
      opsTimebyOperator: {},
      nonConformingTimebyOperator: {},
      totalNonConformingDurationByOperator: {},
      totalOperationsPerMonthByOperator: {},
      totalNonConformingPerMonthByOperator: {},
      totalPlatformTypeByOperator: {},
    };
    let organizeDataComplete = {
      opsByPilot: {},
      opsByPlatform: {},
      opsByPlatformType: {},
      opsByMonth: {},
      totalNonConformingDurationByPlatformType: {},
      totalNonConformingDurationByPlatform: {},
      totalNonConformingDurationByPilot: {},
      totalNonConformingDurationByMonth: {},
      countOfNonConformance: 0,
      totalOperationCount: 0,
      totalFlightDuration: 0,
      totalNonConformingDuration: 0,
    };

    monthNames.forEach((month) => {
      organizeDataComplete.totalNonConformingDurationByMonth[month] = 0;
      organizeDataComplete.opsByMonth[month] = 0;
    });

    const nonConformingRemoveDuplicates = {};
    logState?.forEach((operation) => {
      // Ended State
      if (
        nonConformingRemoveDuplicates[operation.operation_uuid] &&
        operation.operation_state === "Ended"
      ) {
        nonConformingRemoveDuplicates[
          operation.operation_uuid
        ].nonConformingDuration +=
          parseFloat(operation.event_time / (1000 * 60 * 60)).toFixed(2) -
          nonConformingRemoveDuplicates[operation.operation_uuid]
            .nonConformingStart;

        nonConformingRemoveDuplicates[operation.operation_uuid].state =
          operation.operation_state;
      }
      // operation.operation_state === "contingent"
      // NonConforming or Contingent State
      if (operation.operation_state === "Nonconforming") {
        // nonConformingDuration handle situation where nonconform happen multiple times
        // also initializes nonConformingDuration if doesnt exist
        if (!nonConformingRemoveDuplicates[operation.operation_uuid]) {
          nonConformingRemoveDuplicates[operation.operation_uuid] = {
            nonConformingDuration: 0,
          };
        }
        nonConformingRemoveDuplicates[
          operation.operation_uuid
        ].nonConformingStart = parseFloat(
          operation.event_time / (1000 * 60 * 60)
        ).toFixed(2);
        nonConformingRemoveDuplicates[operation.operation_uuid].state =
          operation.operation_state;
      }

      if (operation.operation_state === "contingent") {
        if (!nonConformingRemoveDuplicates[operation.operation_uuid]) {
          nonConformingRemoveDuplicates[operation.operation_uuid] = {
            nonConformingDuration: 0,
          };
        }
        // previously in nonconforming state will trigger this
        if (nonConformingRemoveDuplicates[operation.operation_uuid]) {
          if (
            nonConformingRemoveDuplicates[operation.operation_uuid].state ===
            "Nonconforming"
          )
            nonConformingRemoveDuplicates[operation.operation_uuid].state =
              operation.operation_state;
          // entered from normal state or activated (recover from nonconform)
          nonConformingRemoveDuplicates[
            operation.operation_uuid
          ].nonConformingStart = parseFloat(
            operation.event_time / (1000 * 60 * 60)
          ).toFixed(2);
          nonConformingRemoveDuplicates[operation.operation_uuid].state =
            operation.operation_state;
        }
      }

      // recover from nonconform will trigger this
      if (
        nonConformingRemoveDuplicates[operation.operation_uuid] &&
        operation.operation_state === "Activated"
      ) {
        nonConformingRemoveDuplicates[
          operation.operation_uuid
        ].nonConformingStart = parseFloat(
          operation.event_time / (1000 * 60 * 60)
        ).toFixed(2);
        nonConformingRemoveDuplicates[operation.operation_uuid].state =
          operation.operation_state;
        nonConformingRemoveDuplicates[
          operation.operation_uuid
        ].nonConformingDuration +=
          parseFloat(operation.event_time / (1000 * 60 * 60)).toFixed(2) -
          nonConformingRemoveDuplicates[operation.operation_uuid]
            .nonConformingStart;
      }
    });

    Object.keys(operatorOperationsRef.current).forEach((singleOperator) => {
      organizeDataComplete = {
        opsByPilot: {},
        opsByPlatform: {},
        opsByPlatformType: {},
        opsByMonth: {},
        totalNonConformingDurationByPlatformType: {},
        totalNonConformingDurationByPlatform: {},
        totalNonConformingDurationByPilot: {},
        totalNonConformingDurationByMonth: {},
        countOfNonConformance: 0,
        totalOperationCount: 0,
        totalFlightDuration: 0,
        totalNonConformingDuration: 0,
      };

      monthNames.forEach((month) => {
        organizeDataComplete.totalNonConformingDurationByMonth[month] = 0;
        organizeDataComplete.opsByMonth[month] = 0;
      });
      operatorOperationsRef.current[singleOperator].forEach(
        (singleOperation) => {
          organizeDataComplete.totalOperationCount += 1;
          organizeDataComplete.totalFlightDuration += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          );

          if (
            nonConformingRemoveDuplicates[
              singleOperation.operation_json.reference.id
            ]
          ) {
            organizeDataComplete.countOfNonConformance += 1;
            organizeDataComplete.totalNonConformingDuration +=
              nonConformingRemoveDuplicates[
                singleOperation.operation_json.reference.id
              ].nonConformingDuration;
          }
          const month = new Date(
            singleOperation.operation_json.reference.time_start.value
          );
          // Pilot Calculations
          singleOperation.operation_json.request.pilot_uuid.forEach(
            (singlePilotUuid) => {
              organizeDataComplete.opsByPilot[pilotList[singlePilotUuid].name]
                ? (organizeDataComplete.opsByPilot[
                    pilotList[singlePilotUuid].name
                  ] += 1)
                : (organizeDataComplete.opsByPilot[
                    pilotList[singlePilotUuid].name
                  ] = 1);

              if (
                nonConformingRemoveDuplicates[
                  singleOperation.operation_json.reference.id
                ]
              ) {
                organizeDataComplete.totalNonConformingDurationByPilot[
                  pilotList[singlePilotUuid].name
                ]
                  ? (organizeDataComplete.totalNonConformingDurationByPilot[
                      pilotList[singlePilotUuid].name
                    ] +=
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration)
                  : (organizeDataComplete.totalNonConformingDurationByPilot[
                      pilotList[singlePilotUuid].name
                    ] =
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration);
              }
            }
          );
          // ***Pilot Calculations End
          // Platform Calculations
          singleOperation.operation_json.request.platform_uuid.forEach(
            (singlePlatformUuid) => {
              organizeDataComplete.opsByPlatform[
                platformList[singlePlatformUuid].callsign
              ]
                ? (organizeDataComplete.opsByPlatform[
                    platformList[singlePlatformUuid].callsign
                  ] += 1)
                : (organizeDataComplete.opsByPlatform[
                    platformList[singlePlatformUuid].callsign
                  ] = 1);
              organizeDataComplete.opsByPlatformType[
                platformTypeList[platformList[singlePlatformUuid].typeUuid]
              ]
                ? (organizeDataComplete.opsByPlatformType[
                    platformTypeList[platformList[singlePlatformUuid].typeUuid]
                  ] += 1)
                : (organizeDataComplete.opsByPlatformType[
                    platformTypeList[platformList[singlePlatformUuid].typeUuid]
                  ] = 1);

              if (
                nonConformingRemoveDuplicates[
                  singleOperation.operation_json.reference.id
                ]
              ) {
                organizeDataComplete.totalNonConformingDurationByPlatform[
                  platformList[singlePlatformUuid].callsign
                ]
                  ? (organizeDataComplete.totalNonConformingDurationByPlatform[
                      platformList[singlePlatformUuid].callsign
                    ] +=
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration)
                  : (organizeDataComplete.totalNonConformingDurationByPlatform[
                      platformList[singlePlatformUuid].callsign
                    ] =
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration);

                organizeDataComplete.totalNonConformingDurationByPlatformType[
                  platformTypeList[platformList[singlePlatformUuid].typeUuid]
                ]
                  ? (organizeDataComplete.totalNonConformingDurationByPlatformType[
                      platformTypeList[
                        platformList[singlePlatformUuid].typeUuid
                      ]
                    ] +=
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration)
                  : (organizeDataComplete.totalNonConformingDurationByPlatformType[
                      platformTypeList[
                        platformList[singlePlatformUuid].typeUuid
                      ]
                    ] =
                      nonConformingRemoveDuplicates[
                        singleOperation.operation_json.reference.id
                      ].nonConformingDuration);
              }
            }
          );
          // ***Platform Calculations End
          organizeDataComplete.opsByMonth[monthNames[month.getMonth() - 1]]
            ? (organizeDataComplete.opsByMonth[
                monthNames[month.getMonth() - 1]
              ] += 1)
            : (organizeDataComplete.opsByMonth[
                monthNames[month.getMonth() - 1]
              ] = 1);

          if (
            nonConformingRemoveDuplicates[
              singleOperation.operation_json.reference.id
            ]
          ) {
            organizeDataComplete.totalNonConformingDurationByMonth[
              monthNames[month.getMonth() - 1]
            ]
              ? (organizeDataComplete.totalNonConformingDurationByMonth[
                  monthNames[month.getMonth() - 1]
                ] +=
                  nonConformingRemoveDuplicates[
                    singleOperation.operation_json.reference.id
                  ].nonConformingDuration)
              : (organizeDataComplete.totalNonConformingDurationByMonth[
                  monthNames[month.getMonth() - 1]
                ] =
                  nonConformingRemoveDuplicates[
                    singleOperation.operation_json.reference.id
                  ].nonConformingDuration);
          }
        }
      );
      chartDataRef.current = {
        ...chartDataRef.current,
        [singleOperator]: organizeDataComplete,
      };
    });

    if (isCompareMode && chartDataRef.current) {
      Object.keys(chartDataRef.current).forEach((singleOperator) => {
        combinedOrganizeDataComplete.opsCountbyOperator[singleOperator] =
          chartDataRef.current[singleOperator].totalOperationCount;

        combinedOrganizeDataComplete.nonConformingbyOperator[singleOperator] =
          chartDataRef.current[singleOperator].countOfNonConformance;

        combinedOrganizeDataComplete.opsTimebyOperator[singleOperator] =
          chartDataRef.current[singleOperator].totalFlightDuration;

        combinedOrganizeDataComplete.nonConformingTimebyOperator[
          singleOperator
        ] = chartDataRef.current[singleOperator].totalNonConformingDuration;

        combinedOrganizeDataComplete.totalNonConformingDurationByOperator[
          singleOperator
        ] = {
          Operation: chartDataRef.current[singleOperator].totalFlightDuration,
          Nonconforming:
            chartDataRef.current[singleOperator].totalNonConformingDuration,
        };

        combinedOrganizeDataComplete.totalOperationsPerMonthByOperator[
          singleOperator
        ] = chartDataRef.current[singleOperator].opsByMonth;

        combinedOrganizeDataComplete.totalNonConformingPerMonthByOperator[
          singleOperator
        ] =
          chartDataRef.current[
            singleOperator
          ].totalNonConformingDurationByMonth;

        combinedOrganizeDataComplete.totalPlatformTypeByOperator[
          singleOperator
        ] =
          chartDataRef.current[
            singleOperator
          ].totalNonConformingDurationByPlatformType;
      });
    }
    setChartData(chartDataRef.current);
    setCombinedChartData(combinedOrganizeDataComplete);
  };

  useEffect(() => {
    // for overlay to not disable too fast
    // use loading to prevent premature render when chart data not ready
    if (Object.keys(chartData) && loading)
      if (Object.keys(chartData).length === operator.length) setLoading(false);
  }, [chartData]);

  return (
    <Paper style={{ overflow: "auto", padding: 10, width: "100%" }}>
      <LoadingOverlay active={loading} spinner>
        <Grid container style={{ width: 1700, minHeight: 100 }}>
          {!loading &&
            chartData &&
            operator.map((singleOperator) => {
              return (
                <Grid item key={singleOperator.operatorName}>
                  <OperatorDetails
                    operator={singleOperator}
                    countOfNonConformance={
                      chartData[singleOperator.operatorName]
                        .countOfNonConformance
                    }
                    chartData={chartData[singleOperator.operatorName]}
                    isCompareMode={isCompareMode}
                  />
                </Grid>
              );
            })}
        </Grid>
        {!loading && (
          <Grid container style={{ width: 1700 }}>
            {combinedChartData &&
              cardDetails.map((singleCard, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item>
                  <Paper className={classes.container}>
                    {singleCard.type === "pieChart" && (
                      <PieChart
                        chartData={
                          isCompareMode
                            ? combinedChartData[singleCard.chartData]
                            : chartData[operator[0].operatorName][
                                singleCard.chartData
                              ]
                        }
                        chartName={singleCard.cardHeader}
                        showLegend
                      />
                    )}
                    {singleCard.type === "barChart" && (
                      <BarChart
                        chartData={
                          chartData[operator[0].operatorName][
                            singleCard.chartData
                          ]
                        }
                        chartName={singleCard.cardHeader}
                      />
                    )}
                    {singleCard.type === "stackedBarChart" && (
                      <StackedBarChart
                        chartData={combinedChartData[singleCard.chartData]}
                        chartName={singleCard.cardHeader}
                        showLegend
                      />
                    )}
                    {singleCard.type === "combinedBarChart" && (
                      <CombinedBarChart
                        chartData={combinedChartData[singleCard.chartData]}
                        chartName={singleCard.cardHeader}
                        showLegend
                      />
                    )}
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
      </LoadingOverlay>
    </Paper>
  );
}
export default memo(OperatorDashBoard);
