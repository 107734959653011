import { SET_MAPBOX_CONTROLLER, SET_TRACKING_MAP_VIEW } from "../actions/maps";

const initState = {
  trackingMap: null,
  planningMap: null,
  mapboxMap: null,
};
const mapsReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TRACKING_MAP_VIEW: {
      return {
        ...state,
        trackingMapView: action.data.trackingMapView,
      };
    }
    case SET_MAPBOX_CONTROLLER: {
      return {
        ...state,
        mapboxController: action.data.mapboxController,
      };
    }
    default:
      return state;
  }
};

export default mapsReducer;
