import { ButtonGroup, Button } from "@material-ui/core";
import { Fragment } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";

export default function AreaEntries(props) {
  const { selectedAreaWaypoints, onChange, onFocus } = props;
  const numbersOfWaypoints = selectedAreaWaypoints?.length;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>WP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      {selectedAreaWaypoints.map((waypoint, i) => (
        <Fragment key={`waypoint${i + 1}`}>
          <span style={{ height: "12px", lineHeight: "12px", margin: "auto" }}>
            {i + 1}
          </span>
          <input
            type="number"
            width={100}
            value={waypoint[1]}
            name={`waypointLatititude${i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][1] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          />
          <input
            width={100}
            type="number"
            value={waypoint[0]}
            name={`waypointLongitude${i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][0] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          />
          <ButtonGroup>
            <Button onClick={() => onFocus(waypoint)}>
              <CenterFocusIcon />
            </Button>
            <Button
              onClick={() => {
                onChange([
                  ...selectedAreaWaypoints.slice(0, i),
                  ...selectedAreaWaypoints.slice(i + 1),
                ]);
              }}
              // disabled={i === 0 || i + 1 === numbersOfWaypoints}
              disabled={i <= 2}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </Fragment>
      ))}

      <Button
        variant="outlined"
        fullWidth
        style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
        onClick={() => {
          onChange([
            ...selectedAreaWaypoints.slice(0, -1),
            [0, 0],
            selectedAreaWaypoints[numbersOfWaypoints - 1],
          ]);
        }}
      >
        Add way point
      </Button>
    </div>
  );
}
