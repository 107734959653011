/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useApi } from "@/api/useApi";
import toast from "react-hot-toast";
import { Auth } from "aws-amplify";
import Button from "@/components/v1/Common/Button";
import { User } from "@/model/api/User";
import Loader from "../../Common/Loader";

interface UserFormProps {
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
}

export default function OwnOrganisationManagement({
  showMessage,
}: UserFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    organisation_uuid: "",
    organisation_name: "",
    subscription_plan_name: "",
    subscription_plan_expiry: "",
    date_created: "",
  });

  const [formError, setFormError] = useState({
    organisation_name: "",
  });

  const api = useApi();

  const getOrganisationDetails = async () => {
    try {
      const response = await api.getOwnOrganisation();
      setForms(response.data);
    } catch (err) {
      showMessage && showMessage((err as any).message, true);

      setIsLoading(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getOrganisationDetails();
  }, []);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      organisation_name: "",
    };

    if (form.organisation_name === "") {
      error.organisation_name = "Organisation name is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async (isNewOrganisation: boolean) => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Updating Organisation details");
    setIsLoading(true);

    // Update Organisation Name
    if (!isNewOrganisation) {
      try {
        await api.updateOwnOrganisation({
          organisation_name: form.organisation_name,
        });
        showMessage && showMessage("Organisation Updated", true);
      } catch (err) {
        showMessage && showMessage((err as any).message, true);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="flex-col">
      {/* No Organisation Available */}
      {/* {!isLoading && <p className="p-8 text-center">No Data Available</p>} */}

      {!isLoading && (
        <div>
          <div className="mb-2">
            <label htmlFor="organisation_uuid" className="label">
              Organisation UUID
            </label>
            <input
              type="text"
              id="organisation_uuid"
              className="input-text"
              defaultValue={form.organisation_uuid}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="organisation_name" className="label">
              Organisation Name*
            </label>
            <input
              type="text"
              id="organisation_name"
              className="input-text"
              defaultValue={form.organisation_name}
              onChange={onChange}
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            />
          </div>
          {formError.organisation_name && (
            <p className="text-red-500 text-sm mt-1">
              {formError.organisation_name}
            </p>
          )}
          <div className="mb-2">
            <label htmlFor="subscription_plan_name" className="label">
              Subsciption Type
            </label>
            <input
              type="text"
              id="subscription_plan_name"
              className="input-text"
              defaultValue={form.subscription_plan_name || ""}
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="username" className="label">
              Subscription Expiry
            </label>
            <input
              type="text"
              id="phone"
              className="input-text"
              defaultValue={
                form.subscription_plan_expiry
                  ? form.subscription_plan_expiry.split("T")[0]
                  : ""
              }
              disabled
            />
          </div>
          <div className="mb-2">
            <label htmlFor="username" className="label">
              Date Created
            </label>
            <input
              type="text"
              id="phone"
              className="input-text mb-10"
              defaultValue={
                form.date_created ? form.date_created.split("T")[0] : ""
              }
              disabled
            />
          </div>

          <Button
            type="primaryDark"
            isLoading={isLoading}
            text="Update Organisation"
            onClick={(e: any) => {
              e.stopPropagation();
              handleSave(false);
            }}
          />
        </div>
      )}

      <Loader isLoading={isLoading} isError={false} />
    </div>
  );
}
