import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, TextField } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import SchedulesHeader from "../SchedulesHeader";

const formatDate = (date) => {
  // console.log("Original Date : ", date);
  // let d = new Date(date);
  // const timezoneoffset = `${d.getTimezoneOffset() * 60 * 1000}`;
  // d = new Date(d.valueOf() - timezoneoffset);
  // console.log("Updated Date : ", d.toLocaleDateString());
  // return [`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`];
  return format(new Date(date), "MM/dd/yyyy HH:mm:ss");
};
const formatDate2 = (date) => {
  try {
    if (date === 0) {
      return "Unknown";
    }
    if (date === "Unknown") {
      return "Unknown";
    }
    const dateInt = parseInt(date, 10);
    return format(new Date(dateInt), "MM/dd/yyyy HH:mm:ss");
  } catch (err) {
    return "Unknown";
  }
};

const formartDateForSort = (date) =>
  format(new Date(date), "yyyy/MM/dd HH:mm:ss");

const formartDateForSort2 = (date) => {
  try {
    const dateInt = parseInt(date, 10);
    return format(new Date(dateInt), "yyyy/MM/dd HH:mm:ss");
  } catch (err) {
    return "Unknown";
  }
};

const durationCalculator2 = (dateTakeOff, dateLanding) => {
  try {
    if (
      dateTakeOff === "Unknown" ||
      dateLanding === "Unknown" ||
      dateTakeOff === 0 ||
      dateLanding === 0
    ) {
      return "Unknown";
    }
    const dateTakeOffInt = parseInt(dateTakeOff, 10);
    const dateLandingInt = parseInt(dateLanding, 10);
    const a = new Date(dateTakeOffInt);
    const b = new Date(dateLandingInt);
    const c = b - a;
    const d = [
      Math.floor(c / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0"),
      Math.floor((c / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0"),
      Math.floor((c / 1000) % 60)
        .toString()
        .padStart(2, "0"),
    ];
    return d.join(":");
  } catch (err) {
    return "Unknown";
  }
};

const durationCalculator = (dateTakeOff, dateLanding) => {
  try {
    if (dateTakeOff === "Unknown" || dateLanding === "Unknown") {
      return "Unknown";
    }
    const a = new Date(dateTakeOff);
    const b = new Date(dateLanding);

    const c = a - b;
    const d = [
      Math.floor(c / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0"),
      Math.floor((c / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0"),
      Math.floor((c / 1000) % 60)
        .toString()
        .padStart(2, "0"),
    ];
    return d.join(":");
  } catch (err) {
    return "Unknown";
  }
};

function descendingComparator(a, b, orderBy) {
  let comp1;
  let comp2;

  if (orderBy === "plannedDeparture") {
    comp1 = formartDateForSort(
      a.interuss.operational_intent_reference.time_start.value
    );
    comp2 = formartDateForSort(
      b.interuss.operational_intent_reference.time_start.value
    );
  } else if (orderBy === "plannedArrival") {
    comp1 = formartDateForSort(
      a.interuss.operational_intent_reference.time_end.value
    );
    comp2 = formartDateForSort(
      b.interuss.operational_intent_reference.time_end.value
    );
  } else if (orderBy === "flightDeparture") {
    comp1 = formartDateForSort2(a.details.takeoff_time);
    comp2 = formartDateForSort2(b.details.takeoff_time);
  } else if (orderBy === "flightArrival") {
    comp1 = formartDateForSort2(a.details.landing_time);
    comp2 = formartDateForSort2(b.details.landing_time);
  } else if (orderBy === "status") {
    comp1 = a.details.state;
    comp2 = b.details.state;
  } else if (orderBy === "plannedDuration") {
    comp1 = durationCalculator(
      a.interuss.operational_intent_reference.time_end.value,
      a.interuss.operational_intent_reference.time_start.value
    ).replace(/:/g, "");
    comp2 = durationCalculator(
      b.interuss.operational_intent_reference.time_end.value,
      b.interuss.operational_intent_reference.time_start.value
    ).replace(/:/g, "");
    if (comp1 !== "Unknown") {
      comp1 = Number(comp1);
    }
    if (comp2 !== "Unknown") {
      comp2 = Number(comp2);
    }
  } else if (orderBy === "flightDuration") {
    comp1 = durationCalculator2(
      a.details.takeoff_time,
      a.details.landing_time
    ).replace(/:/g, "");
    comp2 = durationCalculator2(
      b.details.takeoff_time,
      b.details.landing_time
    ).replace(/:/g, "");
    if (comp1 !== "Unknown") {
      comp1 = Number(comp1);
    }
    if (comp2 !== "Unknown") {
      comp2 = Number(comp2);
    }
  } else if (orderBy === "operationID") {
    comp1 = a.reference.id;
    comp2 = b.reference.id;
  } else if (orderBy === "pilot") {
    comp1 = a.details.pilot_name;
    comp2 = b.details.pilot_name;
  } else if (orderBy === "tracker") {
    comp1 = a.details.tracker_name;
    comp2 = b.details.tracker_name;
  } else if (orderBy === "platform") {
    comp1 = a.details.platform_name;
    comp2 = b.details.platform_name;
  } else if (orderBy === "platformType") {
    comp1 = a.details.platform_type;
    comp2 = b.details.platform_type;
  } else if (orderBy === "created") {
    comp1 = formartDateForSort(a.reference.time_created.value);
    comp2 = formartDateForSort(b.reference.time_created.value);
  } else {
    comp1 = a.reference.description;
    comp2 = b.reference.description;
  }

  if (comp2 === "Unknown" && comp1 === "Unknown") {
    return 0;
  }

  if (comp1 === "Unknown") {
    return 1;
  }

  if (comp2 === "Unknown") {
    return -1;
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ActionMessagesTable(props) {
  const {
    data,
    setDuplicateTriggered,
    isRemoteId,
    toggleLoadOlderLogs,
    loadOlderLogs,
  } = props;
  const [selectedOperations, setSelectedOperations] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const usePaginationActionsStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },

    table: {
      tableLayout: "fixed",
      maxWidth: 1200,
    },
    tableRow: {
      height: 53,
      "&:hover": {
        background: "#b3caf3",
        cursor: "pointer",
      },
    },
    tableHeaderRow: {
      height: 53,
    },
    tableCell: {
      color: "black",
    },
    pagination: {
      color: "black",
      background: "white",
    },
    icon: {
      color: "black",
    },
  }));

  const handleClearFilters = (columnName) => {
    setFilters((prevFilters) => ({ ...prevFilters, [columnName]: "" }));
  };

  const [filters, setFilters] = useState({
    operation: "",
    status: "",
    plannedDeparture: "",
    plannedArrival: "",
    plannedDuration: "",
    operationId: "",
    pilot: "",
    flightArrival: "",
    flightDeparture: "",
    flightDuration: "",
    platform: "",
    tracker: "",
    platformType: "",
    createdOn: "",
  });

  const handleFilterChange = (event, columnName) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: value,
    }));
  };

  const handleInputChange = (inputValue, columnName) => {
    setFilters((prevFilters) => ({ ...prevFilters, [columnName]: inputValue }));
  };

  const updatedDatas = data.map((operation) => {
    const updatedData = { ...operation };
    const formattedTimeCreated = formatDate(
      operation.reference.time_created.value
    );
    const formattedTimeEnd = formatDate(
      operation.interuss.operational_intent_reference.time_end.value
    );
    const formattedTimeStart = formatDate(
      operation.interuss.operational_intent_reference.time_start.value
    );
    const formattedActTimeEnd = formatDate2(operation.details.landing_time);
    const formattedActTimeStart = formatDate2(operation.details.takeoff_time);
    updatedData.interuss = {
      ...updatedData.interuss,
      operational_intent_reference: {
        ...updatedData.interuss.operational_intent_reference,
        formattedTimeCreated,
        formattedTimeEnd,
        formattedTimeStart,
        formattedActualTimeEnd: formattedActTimeEnd,
        formattedActualTimeStart: formattedActTimeStart,
      },
    };
    return updatedData;
  });

  const filteredData = updatedDatas.filter((row) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      let filterValue = filters[key];
      if (typeof filterValue !== "string") {
        filterValue = filterValue.toString();
      }
      filterValue = filterValue.toLowerCase();
      switch (key) {
        case "operation":
          return row.reference.description.toLowerCase().includes(filterValue);
        case "status":
          return row.details.state.toLowerCase().includes(filterValue);
        case "plannedDeparture":
          return row.interuss.operational_intent_reference.formattedTimeStart
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "plannedArrival":
          return row.interuss.operational_intent_reference.formattedTimeEnd
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "plannedDuration":
          return durationCalculator(
            row.interuss.operational_intent_reference.time_end.value,
            row.interuss.operational_intent_reference.time_start.value
          )
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "flightDeparture":
          return row.interuss.operational_intent_reference.formattedActualTimeStart
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "flightArrival":
          return row.interuss.operational_intent_reference.formattedActualTimeEnd
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "flightDuration":
          return durationCalculator2(
            row.details.takeoff_time,
            row.details.landing_time
          )
            .toString()
            .toLowerCase()
            .includes(filterValue);
        case "operationId":
          return row.reference.id.toLowerCase().includes(filterValue);
        case "pilot":
          if (Array.isArray(row.details.pilot_name)) {
            return row.details.pilot_name
              .join(", ")
              .toLowerCase()
              .includes(filterValue);
          }
          return row.details.pilot_name.toLowerCase().includes(filterValue);
        case "tracker":
          if (Array.isArray(row.details.tracker_name)) {
            return row.details.tracker_name
              .join(", ")
              .toLowerCase()
              .includes(filterValue);
          }
          return row.details.tracker_name.toLowerCase().includes(filterValue);
        case "platform":
          if (Array.isArray(row.details.platform_name)) {
            return row.details.platform_name
              .join(", ")
              .toLowerCase()
              .includes(filterValue);
          }
          return row.details.platform_name.toLowerCase().includes(filterValue);
        case "platformType":
          if (Array.isArray(row.details.platform_type)) {
            return row.details.platform_type
              .join(", ")
              .toLowerCase()
              .includes(filterValue);
          }
          return row.details.platform_type.toLowerCase().includes(filterValue);
        case "createdOn":
          return row.interuss.operational_intent_reference.formattedTimeCreated
            .toString()
            .toLowerCase()
            .includes(filterValue);
        default:
          return true;
      }
    });
  });

  const classes = usePaginationActionsStyles();
  const flattenedPilotNames = filteredData.flatMap(
    (row) => row.details.pilot_name
  );
  const uniquePilotNames = [...new Set(flattenedPilotNames)];
  const flattenedTrackerNames = filteredData.flatMap(
    (row) => row.details.tracker_name
  );
  const uniqueTrackerNames = [...new Set(flattenedTrackerNames)];
  const flattenedPlatformNames = filteredData.flatMap(
    (row) => row.details.platform_name
  );
  const uniquePlatformNames = [...new Set(flattenedPlatformNames)];
  const flattenedPlatformTypes = filteredData.flatMap(
    (row) => row.details.platform_type
  );
  const uniquePlatformTypes = [...new Set(flattenedPlatformTypes)];

  const uniqueFilteredData = filteredData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (v) =>
          durationCalculator(
            v?.interuss?.operational_intent_reference?.time_end?.value,
            v?.interuss?.operational_intent_reference?.time_start?.value
          ) ===
          durationCalculator(
            value?.interuss?.operational_intent_reference?.time_end?.value,
            value?.interuss?.operational_intent_reference?.time_start?.value
          )
      )
  );

  const uniqueFilteredData2 = filteredData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (v) =>
          durationCalculator2(
            v?.details?.takeoff_time,
            v?.details?.landing_time
          ) ===
          durationCalculator2(
            value?.details?.takeoff_time,
            value?.details?.landing_time
          )
      )
  );

  const isSelected = (operation) => {
    return selectedOperations.some(
      (selectedOperation) =>
        selectedOperation.reference.id === operation.reference.id
    );
  };

  const selected = filteredData.filter((operation) => isSelected(operation));
  const unselected = filteredData.filter((operation) => !isSelected(operation));

  const sortedFilteredData = [
    ...selected,
    ...stableSort(unselected, getComparator(order, orderBy)),
  ];

  if (isRemoteId) {
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    checked={
                      !!data.length && selectedOperations.length === data.length
                    }
                    onChange={(evt, checked) => {
                      if (checked) {
                        setSelectedOperations(data);
                      } else {
                        setSelectedOperations([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="operation-select"
                      options={filteredData
                        .map((row) => row.reference.description)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Operation"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      value={filters.operation || null}
                      onChange={(event, value) => {
                        const operation = value || "";
                        handleFilterChange(
                          { target: { value: operation } },
                          "operation"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "operation")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("operation");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="flightDeparture-select"
                      options={filteredData
                        .map(
                          (row) =>
                            row.interuss.operational_intent_reference
                              .formattedActualTimeStart
                        )
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Flight Departure"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      value={filters.flightDeparture || null}
                      onChange={(event, value) => {
                        const departure = value || "";
                        handleFilterChange(
                          {
                            target: {
                              value: departure,
                            },
                          },
                          "flightDeparture"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "flightDeparture")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("flightDeparture");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="flightArrival-select"
                      options={filteredData
                        .map(
                          (row) =>
                            row.interuss.operational_intent_reference
                              .formattedActualTimeEnd
                        )
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Flight Arrival"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      value={filters.flightArrival || null}
                      onChange={(event, value) => {
                        const arrival = value || "";
                        handleFilterChange(
                          {
                            target: {
                              value: arrival,
                            },
                          },
                          "flightArrival"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "flightArrival")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("flightArrival");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="flightDuration-select"
                      options={uniqueFilteredData2}
                      getOptionLabel={(option) => {
                        const displayValue = durationCalculator2(
                          option?.details?.takeoff_time,
                          option?.details?.landing_time
                        );
                        return displayValue;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Flight Duration"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        let duration = "";
                        if (value) {
                          duration = durationCalculator2(
                            value?.details?.takeoff_time,
                            value?.details?.landing_time
                          );
                        }
                        handleFilterChange(
                          {
                            target: {
                              value: duration,
                            },
                          },
                          "flightDuration"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "flightDuration")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("flightDuration");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="operationId-select"
                      options={filteredData
                        .map((row) => row.reference.id)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Operation ID"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      value={filters.operationId || null}
                      onChange={(event, value) => {
                        const operationId = value || "";
                        handleFilterChange(
                          { target: { value: operationId } },
                          "operationId"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "operationId")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("operationId");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  <FormControl style={{ width: 100 }}>
                    <Autocomplete
                      id="createdOn-select"
                      options={filteredData
                        .map(
                          (row) =>
                            row.interuss.operational_intent_reference
                              .formattedTimeCreated
                        )
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Created On"
                          InputLabelProps={{
                            style: {
                              fontWeight: "bold",
                            },
                          }}
                        />
                      )}
                      value={filters.createdOn || null}
                      onChange={(event, value) => {
                        const createdOn = value || "";
                        handleFilterChange(
                          {
                            target: {
                              value: createdOn,
                            },
                          },
                          "createdOn"
                        );
                      }}
                      onInputChange={(event, inputValue) =>
                        handleInputChange(inputValue, "createdOn")
                      }
                      onClose={(event, reason) => {
                        if (reason === "clear") {
                          handleClearFilters("createdOn");
                        }
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFilteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.reference.id + Math.random()}
                  >
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        checked={
                          !!selectedOperations.filter(
                            (operation) =>
                              operation.reference.id === row.reference.id
                          ).length
                        }
                        onChange={(evt, checked) => {
                          if (checked) {
                            setSelectedOperations([...selectedOperations, row]);
                          } else {
                            setSelectedOperations(
                              selectedOperations.filter(
                                (operation) =>
                                  operation.reference.id !== row.reference.id
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {`${row.reference.description}`}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate2(row.details.takeoff_time)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate2(row.details.landing_time)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {durationCalculator2(
                        row.details.takeoff_time,
                        row.details.landing_time
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.reference.id}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {formatDate(row.reference.time_created.value)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <SchedulesHeader
          selectedOperations={selectedOperations}
          setDuplicateTriggered={setDuplicateTriggered}
          isRemoteId={isRemoteId}
          toggleLoadOlderLogs={toggleLoadOlderLogs}
          loadOlderLogs={loadOlderLogs}
        />
      </Paper>
    );
  }
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" align="center">
                <Checkbox
                  checked={
                    !!data.length && selectedOperations.length === data.length
                  }
                  onChange={(evt, checked) => {
                    if (checked) {
                      setSelectedOperations(data);
                    } else {
                      setSelectedOperations([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="operation-select"
                    options={filteredData
                      .map((row) => row.reference.description)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Operation"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.operation || null}
                    onChange={(event, value) => {
                      const operation = value || "";
                      handleFilterChange(
                        { target: { value: operation } },
                        "operation"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "operation")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("operation");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="status-select"
                    options={filteredData
                      .map((row) => row.details.state)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.status || null}
                    onChange={(event, value) => {
                      const status = value || "";
                      handleFilterChange(
                        { target: { value: status } },
                        "status"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "status")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("status");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="plannedDeparture-select"
                    options={filteredData
                      .map(
                        (row) =>
                          row.interuss.operational_intent_reference
                            .formattedTimeStart
                      )
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Planned Departure"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.plannedDeparture || null}
                    onChange={(event, value) => {
                      const departure = value || "";
                      handleFilterChange(
                        {
                          target: {
                            value: departure,
                          },
                        },
                        "plannedDeparture"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "plannedDeparture")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("plannedDeparture");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="plannedArrival-select"
                    options={filteredData
                      .map(
                        (row) =>
                          row.interuss.operational_intent_reference
                            .formattedTimeEnd
                      )
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <div>
                            Planned
                            <br />
                            Arrival
                          </div>
                        }
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.plannedArrival || null}
                    onChange={(event, value) => {
                      const arrival = value || "";
                      handleFilterChange(
                        {
                          target: {
                            value: arrival,
                          },
                        },
                        "plannedArrival"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "plannedArrival")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("plannedArrival");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="plannedDuration-select"
                    options={uniqueFilteredData}
                    getOptionLabel={(option) => {
                      const displayValue = durationCalculator(
                        option?.interuss?.operational_intent_reference?.time_end
                          ?.value,
                        option?.interuss?.operational_intent_reference
                          ?.time_start?.value
                      );
                      return displayValue;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Planned Duration"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      let duration = "";
                      if (value) {
                        duration = durationCalculator(
                          value?.interuss?.operational_intent_reference
                            ?.time_end?.value,
                          value?.interuss?.operational_intent_reference
                            ?.time_start?.value
                        );
                      }
                      handleFilterChange(
                        {
                          target: {
                            value: duration,
                          },
                        },
                        "plannedDuration"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "plannedDuration")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("plannedDuration");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="flightDeparture-select"
                    options={filteredData
                      .map(
                        (row) =>
                          row.interuss.operational_intent_reference
                            .formattedActualTimeStart
                      )
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <div>
                            Flight
                            <br />
                            Departure
                          </div>
                        }
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.flightDeparture || null}
                    onChange={(event, value) => {
                      const departure = value || "";
                      handleFilterChange(
                        {
                          target: {
                            value: departure,
                          },
                        },
                        "flightDeparture"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "flightDeparture")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("flightDeparture");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="flightArrival-select"
                    options={filteredData
                      .map(
                        (row) =>
                          row.interuss.operational_intent_reference
                            .formattedActualTimeEnd
                      )
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <div>
                            Flight
                            <br />
                            Arrival
                          </div>
                        }
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.flightArrival || null}
                    onChange={(event, value) => {
                      const arrival = value || "";
                      handleFilterChange(
                        {
                          target: {
                            value: arrival,
                          },
                        },
                        "flightArrival"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "flightArrival")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("flightArrival");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="flightDuration-select"
                    options={uniqueFilteredData2}
                    getOptionLabel={(option) => {
                      const displayValue = durationCalculator2(
                        option?.details?.takeoff_time,
                        option?.details?.landing_time
                      );
                      return displayValue;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <div>
                            Flight
                            <br />
                            Duration
                          </div>
                        }
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      let duration = "";
                      if (value) {
                        duration = durationCalculator2(
                          value?.details?.takeoff_time,
                          value?.details?.landing_time
                        );
                      }
                      handleFilterChange(
                        {
                          target: {
                            value: duration,
                          },
                        },
                        "flightDuration"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "flightDuration")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("flightDuration");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="operationId-select"
                    options={filteredData
                      .map((row) => row.reference.id)
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Operation ID"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.operationId || null}
                    onChange={(event, value) => {
                      const operationId = value || "";
                      handleFilterChange(
                        { target: { value: operationId } },
                        "operationId"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "operationId")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("operationId");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="pilot-select"
                    options={uniquePilotNames}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pilot Name"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.pilot || null}
                    onChange={(event, value) => {
                      const pilot = value || "";
                      handleFilterChange({ target: { value: pilot } }, "pilot");
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "pilot")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("pilot");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="tracker-select"
                    options={uniqueTrackerNames}
                    getOptionLabel={(option) => option?.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tracker Name"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.tracker || null}
                    onChange={(event, value) => {
                      const tracker = value || "";
                      handleFilterChange(
                        { target: { value: tracker } },
                        "tracker"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "tracker")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("tracker");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="platform-select"
                    options={uniquePlatformNames}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Platform Name"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.platform || null}
                    onChange={(event, value) => {
                      const platform = value || "";
                      handleFilterChange(
                        { target: { value: platform } },
                        "platform"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "platform")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("platform");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="platformType-select"
                    options={uniquePlatformTypes}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Platform Type"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.platformType || null}
                    onChange={(event, value) => {
                      const platformType = value || "";
                      handleFilterChange(
                        { target: { value: platformType } },
                        "platformType"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "platformType")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("platformType");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <FormControl style={{ width: 100 }}>
                  <Autocomplete
                    id="createdOn-select"
                    options={filteredData
                      .map(
                        (row) =>
                          row.interuss.operational_intent_reference
                            .formattedTimeCreated
                      )
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Created On"
                        InputLabelProps={{
                          style: {
                            fontWeight: "bold",
                            textAlign: "left", // Align label text center
                            display: "block", // Ensure label takes full width
                          },
                        }}
                      />
                    )}
                    value={filters.createdOn || null}
                    onChange={(event, value) => {
                      const createdOn = value || "";
                      handleFilterChange(
                        {
                          target: {
                            value: createdOn,
                          },
                        },
                        "createdOn"
                      );
                    }}
                    onInputChange={(event, inputValue) =>
                      handleInputChange(inputValue, "createdOn")
                    }
                    onClose={(event, reason) => {
                      if (reason === "clear") {
                        handleClearFilters("createdOn");
                      }
                    }}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFilteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.reference.id + Math.random()}
                >
                  <TableCell padding="checkbox" align="center">
                    <Checkbox
                      checked={
                        !!selectedOperations.filter(
                          (operation) =>
                            operation.reference.id === row.reference.id
                        ).length
                      }
                      onChange={(evt, checked) => {
                        if (checked) {
                          setSelectedOperations([...selectedOperations, row]);
                        } else {
                          setSelectedOperations(
                            selectedOperations.filter(
                              (operation) =>
                                operation.reference.id !== row.reference.id
                            )
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {`${row.reference.description}`}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.details.state}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.interuss?.operational_intent_reference?.time_start
                      ?.value &&
                      formatDate(
                        row.interuss.operational_intent_reference.time_start
                          .value
                      )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.interuss?.operational_intent_reference?.time_end
                      ?.value &&
                      formatDate(
                        row.interuss.operational_intent_reference.time_end.value
                      )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {durationCalculator(
                      row.interuss.operational_intent_reference.time_end.value,
                      row.interuss.operational_intent_reference.time_start.value
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {formatDate2(row.details.takeoff_time)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {formatDate2(row.details.landing_time)}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {durationCalculator2(
                      row.details.takeoff_time,
                      row.details.landing_time
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {row.reference.id}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {Array.isArray(row.details.pilot_name)
                      ? row.details.pilot_name.join(", ")
                      : row.details.pilot_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {Array.isArray(row.details.tracker_name)
                      ? row.details.tracker_name.join(", ")
                      : row.details.tracker_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {Array.isArray(row.details.platform_name)
                      ? row.details.platform_name.join(", ")
                      : row.details.platform_name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {Array.isArray(row.details.platform_type)
                      ? row.details.platform_type.join(", ")
                      : row.details.platform_type}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {row.reference?.time_created?.value &&
                      formatDate(row.reference.time_created.value)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <SchedulesHeader
        selectedOperations={selectedOperations}
        setDuplicateTriggered={setDuplicateTriggered}
        toggleLoadOlderLogs={toggleLoadOlderLogs}
        loadOlderLogs={loadOlderLogs}
      />
    </Paper>
  );
}
