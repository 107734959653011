import { ButtonGroup, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { useEffect } from "react";

export default function EmergencyLandingPointEntry(props) {
  const {
    emergencyLandingPoint,
    onChange,
    onFocus,
    mapboxController,
    handleCloseLastDrawer,
  } = props;

  const handleEmergencyLatChange = (newLatitude) => {
    emergencyLandingPoint[1] = newLatitude;
    onChange([...emergencyLandingPoint]);
    // if (newLatitude !== "" && newLatitude !== null && !newLatitude) {
    //   emergencyLandingPoint[1] = newLatitude;
    //   onChange([...newLatitude]);
    // } else {
    //   onChange();
    // }
  };

  const handleEmergencyLongChange = (newLongitude) => {
    emergencyLandingPoint[0] = newLongitude;
    onChange([...emergencyLandingPoint]);
    // if (newLongitude !== "" && newLongitude !== null && !newLongitude) {

    //   onChange([...newLongitude]);
    // } else {
    //   onChange("");
    // }
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>EP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      <div style={{ margin: "auto" }}>1</div>
      <input
        type="number"
        width={100}
        value={emergencyLandingPoint[1]}
        name="emergencyLandingPointLatititude"
        onChange={(ev) => {
          const inputValue = ev.target.value;
          if (inputValue.trim() === "") {
            handleEmergencyLatChange("");
          } else {
            const parsedValue = Number.parseFloat(inputValue);
            if (!isNaN(parsedValue)) {
              handleEmergencyLatChange(parsedValue);
            }
          }
          // emergencyLandingPoint[1] = Number.parseFloat(ev.target.value);
          // onChange([...emergencyLandingPoint]);
        }}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      />
      <input
        width={100}
        type="number"
        value={emergencyLandingPoint[0]}
        name="emergencyLandingPointLongitude"
        onChange={(ev) => {
          const inputValue = ev.target.value;
          if (inputValue.trim() === "") {
            handleEmergencyLongChange("");
          } else {
            const parsedValue = Number.parseFloat(inputValue);
            if (!isNaN(parsedValue)) {
              handleEmergencyLongChange(parsedValue);
            }
          }
          // emergencyLandingPoint[0] = Number.parseFloat(ev.target.value);
          // onChange([...emergencyLandingPoint]);
        }}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      />
      <ButtonGroup>
        <Button
          onClick={() => {
            mapboxController.changeDrawMode("draw_point");
            mapboxController.removeDrawFeatures("Point");
            handleCloseLastDrawer();
          }}
        >
          <PinDropIcon />
        </Button>
        <Button
          onClick={() => {
            onFocus(emergencyLandingPoint);
          }}
        >
          <CenterFocusIcon />
        </Button>
        {/* <Button disabled>
          <DeleteIcon />
        </Button> */}
      </ButtonGroup>
    </div>
  );
}
