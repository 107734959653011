import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FrameRenderer from "../FrameRenderer";

function ReplayDialog({ open, handleCloseDialog, selectedFlights }) {
  return (
    <div>
      <Dialog
        open={open} // Use the open prop to control visibility
        onClose={handleCloseDialog}
        fullWidth // Use fullWidth instead of width
        maxWidth="md" // Adjust maxWidth as needed
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Replay Flight Operation
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <FrameRenderer selectedFlights={selectedFlights} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReplayDialog;
