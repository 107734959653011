import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";

interface AssetDetails {
  id: number;
  pilot_uuid: string;
  platform_uuid: string;
  tracker_uuid: string;
}
interface AssetSelectionProps {
  pilots: Pilot[];
  platforms: Platform[];
  trackers: Tracker[];
  selectedAsset?: AssetDetails;
  assetIndex: number;
  onPilotSelect: (pilot_uuid: string) => void;
  onPlatformSelect: (platform_uuid: string) => void;
  onTrackerSelect: (tracker_uuid: string) => void;
  onRemove(): void;
}

export default function AssetSelection({
  pilots,
  platforms,
  trackers,
  selectedAsset,
  assetIndex,
  onPilotSelect,
  onPlatformSelect,
  onTrackerSelect,
  onRemove,
}: AssetSelectionProps) {
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    name: string
  ) => {
    switch (name) {
      case "pilot_uuid":
        onPilotSelect(e.target.value);
        break;
      case "platform_uuid":
        onPlatformSelect(e.target.value);
        break;
      case "tracker_uuid":
        onTrackerSelect(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex-col">
      <div className="mb-2">
        <label htmlFor="pilot" className="label">
          <span className="grow">Pilot Name</span>
          <button className="font-medium text-red-400" onClick={onRemove}>
            Remove
          </button>
        </label>
        <select
          name="pilot_uuid"
          onChange={(e) => handleSelectChange(e, "pilot_uuid")}
          className="input-select"
          value={selectedAsset?.pilot_uuid}
        >
          {pilots.map((pilot) => (
            <option
              key={`${pilot.pilot_uuid}${assetIndex}`}
              value={pilot.pilot_uuid}
            >
              {pilot.pilot_username}
            </option>
          ))}
        </select>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="platform" className="label">
            Platform Callsign
          </label>
          <select
            name="platform_uuid"
            onChange={(e) => handleSelectChange(e, "platform_uuid")}
            className="input-select"
            value={selectedAsset?.platform_uuid}
          >
            {platforms.map((platform) => (
              <option
                key={`${platform.platform_uuid}${assetIndex}`}
                value={platform.platform_uuid}
              >
                {platform.platform_callsign}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <label htmlFor="tracker" className="label">
            Tracker Callsign
          </label>
          <select
            name="tracker_uuid"
            onChange={(e) => handleSelectChange(e, "tracker_uuid")}
            className="input-select"
            value={selectedAsset?.tracker_uuid}
          >
            {trackers.map((tracker) => (
              <option
                key={`${tracker.tracker_uuid}${assetIndex}`}
                value={tracker.tracker_uuid}
              >
                {tracker.tracker_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
