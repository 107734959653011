import { ButtonGroup, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import { Fragment } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function WaypointsEntries(props) {
  const {
    selectedWaypoints,
    onChange,
    onFocus,
    handleOpenFlightAuthorization,
    updateWaypointLongitude,
    updateWaypointLatitude,
    addPermitWaypoint = false,
  } = props;
  const numbersOfWaypoints = selectedWaypoints?.length;

  const handleLatitudeChange = (newLatitude, index) => {
    if (newLatitude !== "" && newLatitude !== null) {
      updateWaypointLatitude(newLatitude, index);
    } else {
      updateWaypointLatitude("", index);
    }
  };

  const handleLongitudeChange = (newLongitude, index) => {
    if (newLongitude !== "" && newLongitude !== null) {
      updateWaypointLongitude(newLongitude, index);
    } else {
      updateWaypointLongitude("", index);
    }
  };

  const getMiddlePoint = (currentWaypoint) => {
    try {
      const lat =
        (currentWaypoint.slice(-1)[0][1] -
          currentWaypoint.slice(-2, -1)[0][1]) /
          2 +
        currentWaypoint.slice(-2, -1)[0][1];
      const lon =
        (currentWaypoint.slice(-1)[0][0] -
          currentWaypoint.slice(-2, -1)[0][0]) /
          2 +
        currentWaypoint.slice(-2, -1)[0][0];
      return [lon, lat];
    } catch {
      return [0, 0];
    }
  };

  return (
    <Grid container columns={12} spacing={0.5} sx={{ marginTop: "15px" }}>
      <Grid item xs={1}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          WP
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LAT (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LONG (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={2.5}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          Actions
        </Typography>{" "}
      </Grid>
      {selectedWaypoints?.map((waypoint, i) => (
        <Fragment key={`route${i + 1}`}>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
            {i === 0 && <FlightTakeoffIcon style={{ margin: "auto" }} />}
            {i + 1 === numbersOfWaypoints && (
              <FlightLandIcon style={{ margin: "auto" }} />
            )}
            {i > 0 && i + 1 < numbersOfWaypoints && (
              <span
                style={{ height: "12px", lineHeight: "12px", margin: "auto" }}
              >
                {i}
              </span>
            )}
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="route-latitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[1]}
              onChange={(ev) => {
                const inputValue = ev.target.value;
                if (inputValue.trim() === "") {
                  handleLatitudeChange("", i);
                } else {
                  const parsedValue = Number.parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    handleLatitudeChange(parsedValue, i);
                  }
                }
                // selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="route-longitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[0]}
              onChange={(ev) => {
                const inputValue = ev.target.value;
                if (inputValue.trim() === "") {
                  handleLongitudeChange("", i);
                } else {
                  const parsedValue = Number.parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    handleLongitudeChange(parsedValue, i);
                  }
                }
                // selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>
          <Grid item xs={2.5}>
            <ButtonGroup
              sx={{
                "& .MuiButton-root": { borderColor: "#b8b8b8" },
              }}
            >
              {!addPermitWaypoint && (
                <Button
                  onClick={() => {
                    onFocus(waypoint);
                  }}
                >
                  <CenterFocusIcon style={{ color: "black" }} />
                </Button>
              )}
              <Button
                onClick={() => {
                  onChange([
                    ...selectedWaypoints.slice(0, i),
                    ...selectedWaypoints.slice(i + 1),
                  ]);
                }}
                disabled={i === 0 || i + 1 === numbersOfWaypoints}
              >
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          onClick={() => {
            onChange([
              ...selectedWaypoints.slice(0, -1),
              // [0, 0],
              getMiddlePoint(selectedWaypoints),
              selectedWaypoints[numbersOfWaypoints - 1],
            ]);
          }}
        >
          Add way point
        </Button>
      </Grid>
    </Grid>
    // <div
    //   style={{
    //     display: "grid",
    //     gridTemplateColumns: "1fr 5fr 5fr 1fr",
    //     gap: "0.25rem",
    //   }}
    // >
    //   <h4 style={{ textAlign: "center" }}>WP</h4>
    //   <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
    //   <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
    //   <h4 style={{ textAlign: "center" }}>Actions</h4>
    //   {selectedWaypoints.map((waypoint, i) => (
    //     <Fragment key={`route${i + 1}`}>
    //       {i === 0 && <FlightTakeoffIcon style={{ margin: "auto" }} />}
    //       {i + 1 === numbersOfWaypoints && (
    //         <FlightLandIcon style={{ margin: "auto" }} />
    //       )}
    //       {i > 0 && i + 1 < numbersOfWaypoints && (
    //         <span
    //           style={{ height: "12px", lineHeight: "12px", margin: "auto" }}
    //         >
    //           {i}
    //         </span>
    //       )}
    //       <input
    //         type="number"
    //         width={100}
    //         value={waypoint[1]}
    //         name={`waypointLatititude${i}`}
    //         onChange={(ev) => {
    //           selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
    //           onChange([...selectedWaypoints]);
    //         }}
    //       />
    //       <input
    //         width={100}
    //         type="number"
    //         value={waypoint[0]}
    //         name={`waypointLongitude${i}`}
    //         onChange={(ev) => {
    //           selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
    //           onChange([...selectedWaypoints]);
    //         }}
    //       />
    //       <ButtonGroup>
    //         <Button
    //           onClick={() => {
    //             onFocus(waypoint);
    //             handleOpenFlightAuthorization();
    //           }}
    //         >
    //           <CenterFocusIcon />
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             onChange([
    //               ...selectedWaypoints.slice(0, i),
    //               ...selectedWaypoints.slice(i + 1),
    //             ]);
    //           }}
    //           disabled={i === 0 || i + 1 === numbersOfWaypoints}
    //         >
    //           <DeleteIcon />
    //         </Button>
    //       </ButtonGroup>
    //     </Fragment>
    //   ))}

    //   <Button
    //     variant="outlined"
    //     fullWidth
    //     style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
    //     onClick={() => {
    //       onChange([
    //         ...selectedWaypoints.slice(0, -1),
    //         [0, 0],
    //         selectedWaypoints[numbersOfWaypoints - 1],
    //       ]);
    //     }}
    //   >
    //     Add way point
    //   </Button>
    // </div>
  );
}
