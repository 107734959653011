import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import LoadingOverlay from "react-loading-overlay";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { downloadCSV } from "../../services/json";
import { useApi } from "../../api/useApi";
import MaintenanceReportForm from "./createMaintenanceReport";

const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");

function descendingComparator(a, b, orderBy) {
  let comp1;
  let comp2;
  if (orderBy === "purpose") {
    comp1 = a.purpose;
    comp2 = b.purpose;
  } else if (orderBy === "defect") {
    comp1 = a.defect;
    comp2 = b.defect;
  } else if (orderBy === "rectification") {
    comp1 = a.rectification;
    comp2 = b.rectification;
  } else if (orderBy === "conducted_by") {
    comp1 = a.conducted_by;
    comp2 = b.conducted_by;
  } else if (orderBy === "time_created") {
    comp1 = a.time_created;
    comp2 = b.time_created;
  } else {
    comp1 = a.maintenance_date;
    comp2 = b.maintenance_date;
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Copy enhanced table head from ActionMessagesTable to edit
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    data,
    selectedOperations,
    setSelectedOperations,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            checked={data.length && selectedOperations.length === data.length}
            onChange={(evt, checked) => {
              if (checked) {
                setSelectedOperations(data);
              } else {
                setSelectedOperations([]);
              }
            }}
          />
        </TableCell>
        {columns.map((headCell) =>
          headCell.id !== "message" ? (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              width={headCell.minWidth} // does not adjust width
            >
              <div style={{ minWidth: headCell.minWidth }}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </div>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align="center"
              width={headCell.minWidth}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function ActionMessagesTable({
  platform_uuid,
  platform_callsign,
  handleClose,
}) {
  const [selectedOperations, setSelectedOperations] = React.useState([]);
  const [activeOverlay, setActiveOverlay] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [updateMaintenance, setUpdateMaintenance] = React.useState(false);

  const columns = [
    { id: "maintenance_date", label: "Maintenance Date" },
    { id: "purpose", label: "Purpose" },
    { id: "defect", label: "Defect", minWidth: "200px" },
    { id: "rectification", label: "Rectification", minWidth: "200px" },
    { id: "conducted_by", label: "Conducted By" },
    { id: "time_created", label: "Time Created" },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("maintenance_date");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCancel = async () => {
    setUpdateMaintenance(false);
  };

  const usePaginationActionsStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    icon: {
      color: "black",
    },
  }));

  const classes = usePaginationActionsStyles();
  // const theme = useTheme();

  const api = useApi();

  const getData = async () => {
    try {
      if (platform_uuid) {
        const response = await api.getMaintenanceData({
          platform_uuid,
        });
        if (response.data) {
          setData(response.data);
        }
        // const testGetAllMaintenance = await api.getMaintenance();
        // console.log(testGetAllMaintenance);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const awaitGetData = async () => {
      setActiveOverlay(true);
      await getData();
      setActiveOverlay(false);
    };

    awaitGetData();
  }, []);

  useEffect(() => {
    const awaitGetData = async () => {
      setActiveOverlay(true);
      await getData();
      setActiveOverlay(false);
    };

    awaitGetData();
  }, [updateMaintenance]);

  const handleExportClick = async (e) => {
    downloadCSV(selectedOperations, `maintenance-logs-${platform_callsign}`);
  };

  const handleEditClick = () => {
    setUpdateMaintenance(true);
  };

  const handleDeleteClick = async () => {
    try {
      selectedOperations.forEach(async (operation) => {
        await api.deleteMaintenance(operation.record_uuid);
      });
      window.alert("Record deleted");
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <LoadingOverlay active={activeOverlay} spinner>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              data={data}
              selectedOperations={selectedOperations}
              setSelectedOperations={setSelectedOperations}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        checked={
                          selectedOperations.filter((operation) => {
                            return operation.record_uuid === row.record_uuid;
                          }).length
                        }
                        onChange={(evt, checked) => {
                          if (checked) {
                            setSelectedOperations([...selectedOperations, row]);
                          } else {
                            setSelectedOperations(
                              selectedOperations.filter(
                                (operation) =>
                                  operation.record_uuid !== row.record_uuid
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {formatDate(row.maintenance_date)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {row.purpose}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {row.defect}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {row.rectification}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {row.conducted_by}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">
                      {formatDate(row.time_created)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          style={{ fontSize: "12px", fontWeight: "bold", margin: "10px" }}
          color="primary"
          onClick={handleExportClick}
          disabled={selectedOperations.length === 0}
        >
          Export Report
        </Button>
        <Button
          variant="contained"
          style={{ fontSize: "12px", fontWeight: "bold", margin: "10px" }}
          color="primary"
          onClick={handleEditClick}
          disabled={selectedOperations.length !== 1}
        >
          Edit Report Details
        </Button>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            margin: "10px",
            float: "right",
          }}
          color="secondary"
          onClick={handleDeleteClick}
          disabled={selectedOperations.length === 0}
        >
          Delete Report
        </Button>
      </LoadingOverlay>
      <Dialog open={updateMaintenance}>
        <div style={{ margin: "15px" }}>
          <MaintenanceReportForm
            handleClose={handleClose}
            handleCancel={handleCancel}
            platform_uuid={platform_uuid}
            platform_callsign={platform_callsign}
            defectField={selectedOperations[0]?.defect}
            maintenanceRectificationField={selectedOperations[0]?.rectification}
            maintenanceConductorField={selectedOperations[0]?.conducted_by}
            maintenancePurposeField={selectedOperations[0]?.purpose}
            dateField={selectedOperations[0]?.maintenance_date}
            record_uuid={selectedOperations[0]?.record_uuid}
            updating
          />
        </div>
      </Dialog>
    </Paper>
  );
}
