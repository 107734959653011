/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Loader from "@/components/v1/Common/Loader";
import Pilot from "@/model/api/Pilot";
import { PieChart, BarChart } from "../../../../StatisticsDashboard/addChart";

interface ChartData {
  totalFlightDuration: number;
  totalOperationCount: number;
  countOfNonConformance: number;
  opsByPlatformType: any;
  opsByPlatform: any;
  opsByOpsType: any;
  opsByMonth: any;
  totalFlightDurationByPlatformType: any;
  totalFlightDurationByPlatform: any;
  totalFlightDurationByOpsType: any;
  totalFlightDurationByMonth: any;
}

interface PilotStatisticProps {
  api: any;
  pilotData: Pilot;
}

export default function PilotStatistic({
  api,
  pilotData,
}: PilotStatisticProps) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const pilotOperationsRef = useRef<any>([]);

  /* Assets */
  const [platformList, setPlatformList] = useState<any>({});
  const [platformTypeList, setPlatformTypeList] = useState<any>({});

  const cardDetails = [
    {
      cardHeader: "Operations By Platform Type",
      chartData: "opsByPlatformType",
      type: "pieChart",
    },
    {
      cardHeader: "Operations By Platform",
      chartData: "opsByPlatform",
      type: "pieChart",
    },
    {
      cardHeader: "Operations By Operation Type",
      chartData: "opsByOpsType",
      type: "pieChart",
    },
    {
      cardHeader: "Operations by Month",
      chartData: "opsByMonth",
      type: "pieChart",
    },
    {
      cardHeader: "Total Flight Duration by Platform Type",
      chartData: "totalFlightDurationByPlatformType",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Platform",
      chartData: "totalFlightDurationByPlatform",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Operation Type",
      chartData: "totalFlightDurationByOpsType",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Month",
      chartData: "totalFlightDurationByMonth",
      type: "barChart",
    },
  ];

  const getAssets = async () => {
    const [platforms, platformTypes] = await Promise.all([
      api.getPlatforms(),
      api.getPlatformTypes(),
    ]);

    const platformList = platforms.data.reduce((result: any, platform: any) => {
      return {
        ...result,
        [platform.platform_uuid]: {
          callsign: platform.platform_callsign,
          typeUuid: platform.platform_type_uuid,
        },
      };
    }, {});

    const platformTypeList = platformTypes.data.reduce(
      (result: any, platform: any) => {
        return {
          ...result,
          [platform.platform_type_uuid]: platform.model,
        };
      },
      {}
    );

    setPlatformList(platformList);
    setPlatformTypeList(platformTypeList);
  };

  const getData = async (maxLoops = 2) => {
    try {
      // maxLoops caps the response at 200 operations
      const [pilotOperationsResponse] = await Promise.all([
        api.getPilotOperations(
          pilotData.pilot_uuid,
          pilotOperationsRef.current.length
        ),
      ]);
      pilotOperationsRef.current = [
        ...pilotOperationsRef.current,
        ...pilotOperationsResponse.data,
      ];
      if (pilotOperationsResponse.data.length > 99 && maxLoops > 1) {
        getData(maxLoops - 1);
      } else {
        organizeData();
      }
    } catch (err) {
      /* dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to Obtain Data ${err}`,
          severity: "error",
        })
      );
      handleClosePilotStatistics(); */
    }
  };

  const organizeData = async () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const checkTimeDifference = (timestamp1: string, timestamp2: string) => {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);

      const epochTime1 = date1.getTime();
      const epochTime2 = date2.getTime();

      const timeDifference = Math.abs(epochTime1 - epochTime2);
      const timeDifferenceInHours = parseFloat(
        (timeDifference / (1000 * 60 * 60)).toFixed(2)
      );

      return timeDifferenceInHours;
    };

    const organizeDataComplete: ChartData = {
      totalFlightDuration: 0,
      totalOperationCount: pilotOperationsRef.current.length,
      countOfNonConformance: 0,
      opsByPlatformType: {},
      opsByPlatform: {},
      opsByOpsType: {},
      opsByMonth: {},
      totalFlightDurationByPlatformType: {},
      totalFlightDurationByPlatform: {},
      totalFlightDurationByOpsType: {},
      totalFlightDurationByMonth: {},
    };

    monthNames.forEach((month) => {
      organizeDataComplete.opsByMonth[month] = 0;
      organizeDataComplete.totalFlightDurationByMonth[month] = 0;
    });

    const logState = await api.getLogState();

    const nonConformingRemoveDuplicates = logState.data.reduce(
      (result: any, operation: any) => {
        if (
          operation.operation_state === "Nonconforming" ||
          operation.operation_state === "contingent"
        )
          return {
            ...result,
            [operation.operation_uuid]: operation.operation_state,
          };
        return result;
      },
      {}
    );

    pilotOperationsRef.current.forEach((singleOperation: any) => {
      if (
        nonConformingRemoveDuplicates[
          singleOperation.operation_json.reference.id
        ]
      )
        organizeDataComplete.countOfNonConformance += 1;
      const month = new Date(
        singleOperation.operation_json.reference.time_start.value
      );
      // not sure if need to use telemetry data to calculate instead of operation json
      // use operation json for now, for display purporse
      // *** Duration Calculations
      organizeDataComplete.totalFlightDuration += checkTimeDifference(
        singleOperation.operation_json.reference.time_start.value,
        singleOperation.operation_json.reference.time_end.value
      );

      organizeDataComplete.totalFlightDurationByMonth[
        monthNames[month.getMonth() - 1]
      ]
        ? (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));

      organizeDataComplete.totalFlightDurationByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));
      // *** Duration Calculations END

      try {
        singleOperation.operation_json.request.platform_uuid.forEach(
          (singlePlatformUuid: any) => {
            organizeDataComplete.opsByPlatform[
              platformList[singlePlatformUuid].callsign
            ]
              ? (organizeDataComplete.opsByPlatform[
                  platformList[singlePlatformUuid].callsign
                ] += 1)
              : (organizeDataComplete.opsByPlatform[
                  platformList[singlePlatformUuid].callsign
                ] = 1);

            organizeDataComplete.totalFlightDurationByPlatform[
              platformList[singlePlatformUuid].callsign
            ]
              ? (organizeDataComplete.totalFlightDurationByPlatform[
                  platformList[singlePlatformUuid].callsign
                ] += checkTimeDifference(
                  singleOperation.operation_json.reference.time_start.value,
                  singleOperation.operation_json.reference.time_end.value
                ))
              : (organizeDataComplete.totalFlightDurationByPlatform[
                  platformList[singlePlatformUuid].callsign
                ] = checkTimeDifference(
                  singleOperation.operation_json.reference.time_start.value,
                  singleOperation.operation_json.reference.time_end.value
                ));

            organizeDataComplete.opsByPlatformType[
              platformTypeList[platformList[singlePlatformUuid].typeUuid]
            ]
              ? (organizeDataComplete.opsByPlatformType[
                  platformTypeList[platformList[singlePlatformUuid].typeUuid]
                ] += 1)
              : (organizeDataComplete.opsByPlatformType[
                  platformTypeList[platformList[singlePlatformUuid].typeUuid]
                ] = 1);

            organizeDataComplete.totalFlightDurationByPlatformType[
              platformTypeList[platformList[singlePlatformUuid].typeUuid]
            ]
              ? (organizeDataComplete.totalFlightDurationByPlatformType[
                  platformTypeList[platformList[singlePlatformUuid].typeUuid]
                ] += checkTimeDifference(
                  singleOperation.operation_json.reference.time_start.value,
                  singleOperation.operation_json.reference.time_end.value
                ))
              : (organizeDataComplete.totalFlightDurationByPlatformType[
                  platformTypeList[platformList[singlePlatformUuid].typeUuid]
                ] = checkTimeDifference(
                  singleOperation.operation_json.reference.time_start.value,
                  singleOperation.operation_json.reference.time_end.value
                ));
          }
        );
      } catch (e) {
        console.log(e);
      }
      // *** Platform Calculations END

      organizeDataComplete.opsByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] += 1)
        : (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] = 1);

      organizeDataComplete.opsByMonth[monthNames[month.getMonth() - 1]]
        ? (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] += 1)
        : (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] = 1);
    });
    setChartData(organizeDataComplete);
    setLoading(false);
  };

  useEffect(() => {
    pilotOperationsRef.current = [];
    getAssets();
  }, []);

  useEffect(() => {
    getData();
  }, [platformList, platformTypeList]);

  return (
    <div
      className="p-2 h-[600px] overflow-auto w-[800px]"
      onClick={(e) => e.stopPropagation()}
    >
      {loading && (
        <Loader className="mt-40" isLoading={loading} isError={false} />
      )}
      {!loading && (
        <div className="flex-col">
          <div className="flex">
            <table className="table-auto w-full mr-2">
              <tbody>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Pilot Name</td>
                  <th>:</th>
                  <td className="px-4 py-2">{pilotData.pilot_username}</td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">License Number</td>
                  <th>:</th>
                  <td className="px-4 py-2">{pilotData.pilot_registration}</td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Expiry Date</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {pilotData.registration_expiry.split("T")[0]}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Total Flight Time</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.totalFlightDuration?.toFixed(2)} Hours
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Operation Count</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.totalOperationCount} Operations
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Non Conformance Status</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.countOfNonConformance} Operations
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid grid-cols-2 -ml-3">
            {cardDetails.map((singleCard, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${index}${singleCard.cardHeader}`}>
                {singleCard.type === "pieChart" && (
                  <PieChart
                    chartData={chartData[singleCard.chartData]}
                    chartName={singleCard.cardHeader}
                    showLegend
                  />
                )}
                {singleCard.type === "barChart" && (
                  <BarChart
                    chartData={chartData[singleCard.chartData]}
                    chartName={singleCard.cardHeader}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
