import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import axios from "axios";

import PuckServer from "../../../services/puckserver";
import {
  downloadSingleJSON,
  downloadKML,
  downloadKMLURL,
  downloadJSONURL,
  uploadKMLtoCloudDeconflict,
  uploadJSONtoCloudDeconflict,
  uploadKMLtoDJICloudDeconflict,
} from "../../../services/json";
import { useApi } from "../../../api/useApi";
import { setSnackbarMessage } from "../../../store/actions";
import { convertZuluToLocalTime } from "../../../api/timeConvert";
import AlertDialog from "../../../components/AuthorityOperationAlertDialog/AuthorityOperationAlertDialogInput";
import {
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
} from "../../../config/flightStatusColor";

import useCognitoAuth from "../../../hooks/useCognitoAuth";
import useWebsocket from "../../../hooks/useWebsocket";
import { channels } from "../../../config/channels";
import { messageTypes } from "../../../config/messageTypes";
import { UasIdComponent } from "../../../components/UasIdInput";
import { ContigentComponent } from "../../../components/ContigentConfirm";
import OperationsCard from "../../../components/OperationsCard/OperationsCard";
import OperationsCardRId from "../../../components/OperationsCardRId/OperationsCardRId";
import RejectAcceptOperationResponsiveDialog from "../../../components/RejectAcceptOperation";
import DJILiveStreaming from "../Drone_Integration/DJILiveStreaming";
import { store } from "../../../store";

import { Colours } from "../../../themes/Colours";

// function PlatformCallSign({ operation }) {
//   if (operation != null) {
//     return (
//       <div>
//         <Grid
//           container
//           spacing={0}
//           style={{
//             padding: "0px",
//             marginTop: "-5px",
//           }}
//         >
//           <Grid item xs={4}>
//             <p
//               style={{
//                 fontSize: "8px",
//               }}
//             >
//               Type -{" "}
//               <span
//                 style={{
//                   fontSize: "11px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {operation.details.type.toUpperCase()}
//               </span>
//             </p>
//           </Grid>
//           <Grid item xs={8}>
//             <p
//               style={{
//                 fontSize: "8px",
//               }}
//             >
//               State -{" "}
//               <span
//                 style={{
//                   fontSize: "11px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {operation.details.state}
//               </span>
//             </p>
//           </Grid>
//         </Grid>
//       </div>
//     );
//   }
//   return (
//     <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
//       Undefined
//     </Typography>
//   );
// }
// const isNumeric = (num) =>
//   (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
//   !isNaN(num);
// function TelemetryDetails({ telemetryData, dataTracker, dataPilot }) {
//   const [telemetryDataArr, setTelemetryDataArr] = useState([]);
//   const [telemetryDataArrPush, setTelemetryDataArrPush] = useState([]);
//   const api = useApi();
//   useEffect(() => {
//     if (
//       telemetryData !== undefined &&
//       telemetryData?.puckResponses?.length > 0
//     ) {
//       setTelemetryDataArr(telemetryData?.puckResponses);
//     }
//     const mapingTelemetery = (tracker_imei) => {
//       const dataTelemetry = [];
//       if (telemetryData.length > 0) {
//         telemetryData.map((p, index) => {
//           if (p?.puckResponses[0][1]?.extras?.imei === tracker_imei) {
//             dataTelemetry.push(p?.puckResponses[0][1]);
//           }
//           return dataTelemetry;
//         });
//         const filterDataTele = dataTelemetry.filter(function (teleObj) {
//           return teleObj.extras.imei === tracker_imei;
//         });
//         return filterDataTele;
//       }
//       return false;
//     };
//     const loopData = () => {
//       const promisePlatform = [];
//       dataTracker
//         // .filter((t) => !t[1].error)
//         .map((t, indexT) => {
//           // if (!t[1]?.error) {}
//           // const trackStatus = () => {
//           //   if (t[1]?.operational_status !== "Undeclared") {
//           //     return true;
//           //   }
//           //   return false;
//           // };
//           promisePlatform.push({
//             type: "TRACKER_STATUS",
//             tracker_callsign: t?.data?.tracker_name, // Todo: map imei to tracker name.
//             telemetryData: mapingTelemetery(t?.data?.tracker_imei),
//             tracker_imei: t?.data?.tracker_imei,
//           });
//           setTelemetryDataArrPush(promisePlatform);
//         });
//     };
//     loopData();
//   }, [telemetryData, dataTracker, telemetryDataArr]);

//   return (
//     <div>
//       <TelemetryDetailsLoop
//         telemetryData={telemetryDataArrPush}
//         dataTracker={dataTracker}
//         dataPilot={dataPilot}
//       />
//     </div>
//   );
// }
// function TelemetryDetailsLoop({ telemetryData, dataTracker, dataPilot }) {
//   // useEffect(() => {}, [telemetryData, dataTracker, dataPilot]);

//   return (
//     <div
//       style={{
//         padding: "0px",
//         paddingTop: "0.5rem",
//       }}
//     >
//       {" "}
//       {Object.keys(dataTracker).length > 0 ? (
//         dataTracker
//           // .filter((t) => !t[1].error)
//           .flatMap((t, index) => (
//             <div
//               key={t?.data?.tracker_uuid.toString()}
//               style={{
//                 textAlign: "left",
//                 padding: "0px",
//                 // marginBottom: "0x",
//                 marginTop: "-15px",
//               }}
//             >
//               <Grid
//                 container
//                 alignItems="center"
//                 spacing={1}
//                 style={{
//                   padding: "0px",
//                   marginBottom: "-10px",
//                   marginTop: "-15px",
//                 }}
//               >
//                 <Grid item xs={3}>
//                   <p
//                     style={{
//                       fontSize: "8px",
//                     }}
//                   >
//                     Tracker-{" "}
//                     <span
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       {t?.data?.tracker_name ? t?.data?.tracker_name : " "}{" "}
//                     </span>
//                   </p>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <p
//                     style={{
//                       fontSize: "8px",
//                     }}
//                   >
//                     Pilot-{" "}
//                     <span
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       {dataPilot[index]?.data?.pilot_name
//                         ? dataPilot[index]?.data?.pilot_name
//                         : " "}{" "}
//                     </span>
//                   </p>
//                 </Grid>
//                 <Grid container alignItems="flex-start" item xs={1}>
//                   <ArrowForwardIcon
//                     style={{
//                       fontSize: "11px",
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={2}>
//                   <p
//                     style={{
//                       fontSize: "8px",
//                     }}
//                   >
//                     Alt -{" "}
//                     <span
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       {isNumeric(
//                         telemetryData[index]?.telemetryData[0]?.position?.alt
//                       )
//                         ? telemetryData[
//                             index
//                           ]?.telemetryData[0]?.position?.alt.toFixed(1)
//                         : telemetryData[index]?.telemetryData[0]?.position
//                             ?.alt}{" "}
//                       m AMSL
//                     </span>
//                   </p>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <p
//                     style={{
//                       fontSize: "8px",
//                     }}
//                   >
//                     Gs -{" "}
//                     <span
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       {isNumeric(telemetryData[index]?.telemetryData[0]?.speed)
//                         ? telemetryData[index]?.telemetryData[0]?.speed.toFixed(
//                             1
//                           )
//                         : " "}{" "}
//                       m/s
//                     </span>
//                   </p>
//                 </Grid>
//                 <Grid item xs={2}>
//                   <p
//                     style={{
//                       fontSize: "8px",
//                     }}
//                   >
//                     Hdg -{" "}
//                     <span
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       {telemetryData
//                         ? telemetryData[index]?.telemetryData[0]?.track
//                         : " "}{" "}
//                       deg
//                     </span>
//                   </p>
//                 </Grid>
//               </Grid>
//               <hr
//                 style={{
//                   height: "4px",
//                   borderTop: "dashed 2px",
//                 }}
//               />
//             </div>
//           ))
//       ) : (
//         // <div>
//         //   {" "}
//         //   <LoadingOverlay active={loading} spinner text="Loading tracker...">
//         //     <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
//         //       Loading..
//         //     </Typography>
//         //   </LoadingOverlay>
//         // </div>
//         <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
//           {/* Undefined */}
//         </Typography>
//       )}
//       {/* <hr /> */}
//     </div>
//   );
// }
function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

function OperationMasterControl({
  operation,
  telemetryData,
  highlighted,
  handleOnFocus,
  onRemoveClick,
  platformData,
  onUpdateOperationState,
  onVisabilityClick,
  // setUpdatingOperation,
  role,
  trackerId,
  pilotId,
  mapboxController,
  dataPilot,
  platformNames,
  handleOpenFlightAuthorizationForced,
  getTrackerFromUuid,
  getPilotFromUuid,
}) {
  const [updatingOperation, setUpdatingOperation] = useState(false);
  const [selectedDataTracker, setSelectedDataTracker] = useState([]);
  const [selectedDataPilot, setSelectedDataPilot] = useState([]);
  const [showUasIdPopup, setShowUasIdPopup] = useState(false);
  const [showContigentPopup, setShowContigentPopup] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const [fileDialog, setFileDialog] = useState(false);
  const [typeDialog, setTypeDialog] = useState(false);
  const [URLDialog, setURLDialog] = useState(false);
  const [UserDialog, setUserDialog] = useState(false);
  const [type, setType] = useState("");
  const [downloadData, setDownloadData] = useState("");
  const [opsNotificationDialogText, setOpsNotificationDialogText] = useState(
    {}
  );
  const [openOpsNotificationDialog, setOpenOpsNotificationDialog] =
    useState(false);
  // Reject Accept Dialogue
  const [openReject, setOpenReject] = useState(false);
  const [reScheduled, setReScheduled] = useState(false);
  const [reRouted, setReRouted] = useState(false);
  const [compareCurrentData, setCompareCurrentData] = useState({});
  const [compareData, setCompareData] = useState({});
  const [availablePilots, setAvailablePilots] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isSelectedOption, setIsSelectedOption] = useState(false);
  const [liveStreamDialog, setLiveStreamDialog] = useState(false);
  const [userUuid, setUserUuid] = useState("");
  const [platformUuid, setPlatformUuid] = useState("");
  const [UAVType, setUAVType] = useState("");
  const [env, setEnv] = useState([]);

  const closeOpsNotificationDialog = () => {
    setOpenOpsNotificationDialog(false);
  };

  const api = useApi();
  const dispatch = useDispatch();
  const { username } = useCognitoAuth();

  // MAIN LOGIC
  const { isOperator, isAuthority, isPilot, isRemoteId } = useCognitoAuth();

  useEffect(() => {
    if (!store.getState().envVar["base_url-sp"]) return;
    const currenteEnvironment =
      store.getState().envVar["base_url-sp"].Value ===
      "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";
    setEnv(currenteEnvironment);
  }, []);

  const backgroundColor = () => {
    switch (operation.details.state) {
      case FLIGHT_STATES.ACTIVATED: {
        return `rgb(${HIGHLIGHT_COLORS.green.slice(0, 3).join(",")})`;
      }

      case FLIGHT_STATES.CONTINGENT: {
        return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
      }
      // case FLIGHT_STATES.NON_CONFORMANT: {
      //   return `rgb(${HIGHLIGHT_COLORS.orange.slice(0, 3).join(",")})`;
      // }
      case FLIGHT_STATES.NON_CONFORMANT: {
        return HIGHLIGHT_COLORS.brightOrange;
      }
      case FLIGHT_STATES.ENDED: {
        return `rgb(${HIGHLIGHT_COLORS.grey2.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.ACCEPTED: {
        return `rgb(${HIGHLIGHT_COLORS.gray.slice(0, 3).join(",")})`;
      }
      default:
        return "white";
    }
  };

  const trackerUuid = trackerId;
  const PilotUuid = pilotId;
  const getDataPilots = async () =>
    Promise.all(
      PilotUuid.map(async (uuid, index) => {
        if (!uuid) return undefined;
        // const PilotsData = await api.getPilot(uuid);
        const PilotsData = { data: await getPilotFromUuid(uuid) };
        return PilotsData;
      })
    );
  const getDataTracker = async () =>
    Promise.all(
      trackerUuid.map(async (uuid, index) => {
        if (!uuid) return undefined;
        // const trackerData = await api.getTrackerScan(uuid);
        const trackerData = { data: await getTrackerFromUuid(uuid) };
        // console.log("trackerData: ", trackerData);
        return trackerData;
      })
    );

  useEffect(() => {
    getDataTracker().then(function (result) {
      setSelectedDataTracker(result);
    });
    getDataPilots().then(function (result) {
      setSelectedDataPilot(result);
    });
  }, []);

  const handleRemoveClick = async (e) => {
    e.preventDefault();

    if (
      operation.details.state !== "Proposed" &&
      operation.details.state !== "Rejected" &&
      operation.details.state !== "Accepted" &&
      operation.details.state !== "Ended"
    ) {
      dispatch(
        setSnackbarMessage({
          message: "Cannot remove an ongoing operation",
          severity: "error",
        })
      );
      return;
    }
    setUpdatingOperation(true);
    try {
      const res = await api.removeOperationSelection(operation.reference.id);
      // onRemoveClick(operation.reference.id);
      // const res = await api.deleteOperation(operation.reference.id);

      if (res) {
        onUpdateOperationState();
      }

      dispatch(
        setSnackbarMessage({
          message: "Operation removed",
          severity: "success",
        })
      );
    } catch (err) {
      console.log(err);
    }
    setUpdatingOperation(false);
  };

  const handleUasId = async (uasId) => {
    setUpdatingOperation(true);
    try {
      const takeoffRes = await api.postMissionDetails(
        operation,
        false,
        env,
        uasId
      );
      const handleActivateOperation = async () => {
        // eslint-disable-next-line no-param-reassign
        operation.operation_state = "Activated";
        try {
          // post mission details to tracker server to enable puck_uuid and operation_id mapping for conformance monitoring
          const res = await api.updateOperation(
            operation.reference.id,
            operation.operation_state
          );
          if (res) {
            onUpdateOperationState(res.data);
          }
        } catch (err) {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: `Unable to notify MARS of operation state change - ${err}`,
              severity: "error",
            })
          );
        }
      };
      // await puckServer.postMission(operation, false, uasId);
      if (takeoffRes?.status === 200) {
        if (
          takeoffRes.data.operations[0].details.rescheduled ||
          takeoffRes.data.operations[0].details.rerouted
        ) {
          setReScheduled(takeoffRes.data.operations[0].details.rescheduled);
          setReRouted(takeoffRes.data.operations[0].details.rerouted);
          setCompareCurrentData(operation.request);
          setCompareData(takeoffRes.data.operations[0]);
          setOpenReject(true);
        } else {
          await handleActivateOperation();
        }
      }
      //  Send notification to MARS
      if (takeoffRes?.status === 204) {
        await handleActivateOperation();
      }
    } catch (err) {
      if (err?.response?.status === 400 || err?.response?.status === 409) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      }
    }

    setUpdatingOperation(false);
  };

  const handleActivateOperationOnly = async () => {
    setUpdatingOperation(true);
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Activated";
    try {
      // post mission details to tracker server to enable puck_uuid and operation_id mapping for conformance monitoring
      const res = await api.updateOperation(
        operation.reference.id,
        operation.operation_state
      );
      if (res) {
        onUpdateOperationState(res.data);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
    }
    setUpdatingOperation(false);
  };

  const handleStartClick = async (e) => {
    e.preventDefault();
    if (operation.details.state !== "Accepted") {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Cannot takeoff an ${operation.details.state} operation.`,
          severity: "error",
        })
      );
      return;
    }
    setShowUasIdPopup(true);
  };

  const handleLandClick = async (e) => {
    e.preventDefault();
    setUpdatingOperation(true);

    // Send notification to MARS
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Ended";
    try {
      const res = await api.updateOperation(
        operation.reference.id,
        operation.operation_state
      );

      if (res) {
        onUpdateOperationState(res.data);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
      setUpdatingOperation(false);
      return;
    }

    setUpdatingOperation(false);
  };

  const handleContingentClick = async (e) => {
    e.preventDefault();
    setShowContigentPopup(true);
  };

  const handleContingent = async () => {
    setUpdatingOperation(true);

    // Send notification to MARS
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Contingent";

    try {
      const res = isAuthority
        ? await api.setContingent(operation.reference.id)
        : await api.updateOperation(
            operation.reference.id,
            operation.operation_state
          );

      onUpdateOperationState(res.data);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
      setUpdatingOperation(false);
      return;
    }

    setUpdatingOperation(false);
  };

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      for (let u = 0; u < response.data.length; u += 1) {
        availablePilots.push(response.data[u].pilot_username);
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault();
    const response = await api.getOperationData({
      operationID: operation.reference.id,
    });
    setDownloadData(response.data);
    // operation_json.details.waypoints
    if (response.data.operation_json.details.waypoints) {
      // downloadKML(response.data, operation.reference.description);
      setDownloadDialog(true);
    } else {
      downloadSingleJSON(response.data, operation.reference.description);
    }
  };

  const handleLSClose = () => {
    setLiveStreamDialog(false);
    setUserUuid("");
  };

  useEffect(() => {
    if (userUuid !== "") {
      setLiveStreamDialog(true);
    }
  }, [userUuid]);
  const handleLiveStreamingClick = async (e) => {
    e.preventDefault();
    const response = await api.getOperationData({
      operationID: operation.reference.id,
    });
    setUserUuid(response.data.operation_json.request.pilot_uuid[0]);
    if (!response?.data?.operation_json?.request?.pilot_uuid[0]) {
      dispatch(
        setSnackbarMessage({
          message: `Unable to obtain URL Livestream Details`,
          severity: "error",
        })
      );
    }
  };

  const droneWithCloudAPI = [
    "DJI M300",
    "DJI M600",
    "DJI M30",
    "DJI M30T",
    "DJI Mavic 3E",
  ];

  //   const handleCheckApprovedDrone = async (e) => {
  //     e.preventDefault();
  //     const response = await api.getOperationData({
  //       operationID: operation.reference.id,
  //     });
  //     setUAVType(response.data.operation_json.request.platform_uuid[0])
  // const UAVName = getPlatform(UAVType);
  // console.log(UAVName);
  //   }

  const handlePilotSelect = (e) => {
    const selectedPilot = e.target.value;
    setIsSelectedOption(selectedPilot);
    setUserList((prevUserList) => [...prevUserList, username, selectedPilot]);
  };

  const handleDownloadClickJSON = () => {
    downloadSingleJSON(downloadData, operation.reference.description);
    setDownloadDialog(false);
  };

  const handleDownloadClickKML = () => {
    downloadKML(downloadData, operation.reference.description);
    setDownloadDialog(false);
  };

  const handleDownloadClickCloud = () => {
    setTypeDialog(true);
    setDownloadDialog(false);
    getPilots();
  };

  const handleDownloadFile = () => {
    setFileDialog(true);
    setDownloadDialog(false);
  };

  const handleDownloadURL = () => {
    setURLDialog(true);
    setDownloadDialog(false);
  };

  const handleTypeJSON = () => {
    setTypeDialog(false);
    setUserDialog(true);
    setType("JSON");
  };

  const handleTypeKML = () => {
    setTypeDialog(false);
    setUserDialog(true);
    setType("KML");
  };

  const handleTypeKMLDJI = () => {
    setTypeDialog(false);
    setUserDialog(true);
    setType("KMLDJI");
  };

  const handleUploadFile = () => {
    if (type === "JSON") {
      for (let i = 0; i < userList.length; i += 1) {
        const user = userList[i];
        if (user !== "None") {
          uploadJSONtoCloudDeconflict(
            downloadData,
            operation.reference.description,
            user,
            api
          );
        }
      }
    } else if (type === "KML") {
      for (let i = 0; i < userList.length; i += 1) {
        const user = userList[i];
        if (user !== "None") {
          uploadKMLtoCloudDeconflict(
            downloadData,
            operation.reference.description,
            user,
            api
          );
        }
      }
    } else if (type === "KMLDJI") {
      for (let i = 0; i < userList.length; i += 1) {
        const user = userList[i];
        if (user !== "None") {
          uploadKMLtoDJICloudDeconflict(
            downloadData,
            operation.reference.description,
            user,
            api
          );
        }
      }
    }
    setUserDialog(false);
    setUserList([]);
  };

  const sendNotification = async (message) => {
    try {
      const res = await api.getOperationsApprovedUsers({
        operation_uuids: [operation.reference.id],
      });
      res.data.forEach((r) => {
        const approvedUsersArray = r.approved_users;
        approvedUsersArray.forEach(async (approvedUser) => {
          const { user, userGroups } = approvedUser;
          let userGroup;
          if (userGroups.includes("operator")) {
            userGroup = "operator";
          } else if (userGroups.includes("pilot")) {
            userGroup = "pilot";
          }
          await api.sendOperationNotification({
            user,
            userGroup,
            message,
          });
          dispatch(
            setSnackbarMessage({
              message: `Notification sent.`,
              severity: "success",
            })
          );
        });
      });
    } catch (error) {
      dispatch(
        setSnackbarMessage({
          message: "Error in sending notification.",
          severity: "error",
        })
      );
      console.log(error);
    } finally {
      setOpsNotificationDialogText({});
    }
  };

  const handleHoldNotifyButtonClick = async (e) => {
    e.preventDefault();
    const operationName = operation.reference.description;
    setOpsNotificationDialogText({
      operationName,
      message: `You have been notified to HOLD flight operation: ${operationName}.`,
      dialogTitle: "CAUTION",
      dialogText: `Requesting this operation to HOLD. Proceed?`,
    });
    setOpenOpsNotificationDialog(true);
  };

  const handleLandNotifyButtonClick = async (e) => {
    e.preventDefault();
    const operationName = operation.reference.description;
    setOpsNotificationDialogText({
      operationName,
      message: `You have been notified to LAND flight operation: ${operationName}.`,
      dialogTitle: "CAUTION",
      dialogText: `Requesting this operation to LAND. Proceed?`,
    });
    setOpenOpsNotificationDialog(true);
  };

  const handleNotifyButtonClick = async (e) => {
    e.preventDefault();
    const operationName = operation.reference.description;
    setOpsNotificationDialogText({
      operationName,
      message: "",
      dialogTitle: ``,
      dialogText: "Submit a notification to this operation:",
      inputTextFieldRequired: "true",
    });
    setOpenOpsNotificationDialog(true);
  };

  const focusTracker = (tracker, telemetry) => {
    if (!telemetry.lat || !telemetry.lng) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to locate ${tracker[0].data.tracker_name}.`,
          severity: "error",
        })
      );
    } else {
      mapboxController.flyTo([telemetry.lng, telemetry.lat]);
    }
  };

  // Reject Accept Dialogue

  const handleClose = (openFlightAuth) => {
    setOpenReject(false);
    if (openFlightAuth) {
      handleOpenFlightAuthorizationForced();
    }
  };

  return (
    <Paper sx={{ borderRadius: "5px" }}>
      <div>
        {(isOperator || isPilot || isRemoteId) && (
          <UasIdComponent
            handleUasId={handleUasId}
            showPopup={showUasIdPopup}
            setShowPopup={setShowUasIdPopup}
          />
        )}

        {isAuthority && openOpsNotificationDialog && (
          <AlertDialog
            open={openOpsNotificationDialog}
            onClose={closeOpsNotificationDialog}
            operationName={opsNotificationDialogText.operationName}
            message={opsNotificationDialogText.message}
            dialogTitle={opsNotificationDialogText.dialogTitle}
            dialogText={opsNotificationDialogText.dialogText}
            sendNotification={sendNotification}
            inputTextFieldRequired={
              opsNotificationDialogText.inputTextFieldRequired
            }
          />
        )}

        <ContigentComponent
          showPopup={showContigentPopup}
          setShowPopup={setShowContigentPopup}
          handleContingent={handleContingent}
        />
        <div
          style={{
            // backgroundColor: backgroundColor(),
            borderRadius: "5px",
          }}
        >
          <LoadingOverlay
            active={updatingOperation}
            spinner
            text="Updating Operation State..."
          >
            {!isRemoteId && (
              <OperationsCard
                operation={operation}
                handleOnFocus={handleOnFocus}
                telemetryData={telemetryData}
                dataPilot={selectedDataPilot}
                dataTracker={selectedDataTracker}
                highlighted={highlighted}
                platformNames={platformNames}
                handleStartClick={handleStartClick}
                handleLandClick={handleLandClick}
                handleContingentClick={handleContingentClick}
                handleDownloadClick={handleDownloadClick}
                handleLiveStreamingClick={handleLiveStreamingClick}
                handleRemoveClick={handleRemoveClick}
                backgroundColor={backgroundColor()}
                handleNotifyButtonClick={handleNotifyButtonClick}
                handleLandNotifyButtonClick={handleLandNotifyButtonClick}
                handleHoldNotifyButtonClick={handleHoldNotifyButtonClick}
              />
            )}

            {isRemoteId && (
              <OperationsCardRId
                operation={operation}
                focusTracker={focusTracker}
                handleOnFocus={handleOnFocus}
                telemetryData={telemetryData}
                dataPilot={selectedDataPilot}
                dataTracker={selectedDataTracker}
                highlighted={highlighted}
                platformNames={platformNames}
                handleStartClick={handleStartClick}
                handleLandClick={handleLandClick}
                handleContingentClick={handleContingentClick}
                handleDownloadClick={handleDownloadClick}
                handleLiveStreamingClick={handleLiveStreamingClick}
                handleRemoveClick={handleRemoveClick}
                backgroundColor={backgroundColor()}
                handleNotifyButtonClick={handleNotifyButtonClick}
                handleLandNotifyButtonClick={handleLandNotifyButtonClick}
                handleHoldNotifyButtonClick={handleHoldNotifyButtonClick}
              />
            )}
          </LoadingOverlay>
        </div>
        {/* download dialog has to be put at this level due to div behaviours */}
        <Dialog
          open={downloadDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setDownloadDialog(false);
            }
          }}
        >
          <DialogTitle
            style={{
              fontSize: "15px",
            }}
          >
            Select Download Type
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton key="Cloud" onClick={() => handleDownloadFile()}>
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="Download as File"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton key="Cloud" onClick={() => handleDownloadURL()}>
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="Download Via URL"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="Cloud"
                onClick={() => handleDownloadClickCloud()}
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="Upload to Cloud"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="cancelButton"
                onClick={() => setDownloadDialog(false)}
              >
                <ListItemText
                  sx={{ color: "red", textAlign: "center" }}
                  primary="Cancel"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Dialog>
        <Dialog
          open={liveStreamDialog}
          PaperProps={{
            style: {
              maxWidth: "90vw",
              maxHeight: "90vh",
              overflow: "hidden",
            },
          }}
        >
          <div
            style={{
              width: "900px",
              height: "700px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={handleLSClose}
              style={{ position: "absolute", top: 0, right: 0 }}
            />
            <DJILiveStreaming userUuid={userUuid} />
          </div>
        </Dialog>

        <Dialog
          open={fileDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setFileDialog(false);
            }
          }}
        >
          <DialogTitle
            style={{
              fontSize: "15px",
            }}
          >
            Select File Type
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton
                key="JSON"
                onClick={() => handleDownloadClickJSON()}
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="JSON"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="KML"
                onClick={() => handleDownloadClickKML()}
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="KML"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="cancelButton"
                onClick={() => setFileDialog(false)}
              >
                <ListItemText
                  sx={{ color: "red", textAlign: "center" }}
                  primary="Cancel"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Dialog>
        <Dialog
          open={typeDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setTypeDialog(false);
            }
          }}
        >
          <DialogTitle
            style={{
              fontSize: "15px",
            }}
          >
            Select File Type
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton key="JSON" onClick={() => handleTypeJSON()}>
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="JSON"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton key="KML" onClick={() => handleTypeKML()}>
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="KML"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton key="KMLDJI" onClick={() => handleTypeKMLDJI()}>
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="KMLDJI"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="cancelButton"
                onClick={() => setTypeDialog(false)}
              >
                <ListItemText
                  sx={{ color: "red", textAlign: "center" }}
                  primary="Cancel"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Dialog>
        <Dialog
          open={URLDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setURLDialog(false);
            }
          }}
        >
          <DialogTitle
            style={{
              fontSize: "15px",
            }}
          >
            Select File Type
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton
                key="JSON"
                onClick={() =>
                  downloadJSONURL(downloadData, operation.reference.description)
                }
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="JSON"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="JSON"
                onClick={() =>
                  downloadKMLURL(downloadData, operation.reference.description)
                }
              >
                <ListItemText
                  sx={{
                    textAlign: "center",
                  }}
                  primary="KML"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                key="cancelButton"
                onClick={() => setURLDialog(false)}
              >
                <ListItemText
                  sx={{ color: "red", textAlign: "center" }}
                  primary="Cancel"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Dialog>
        <Dialog
          open={UserDialog}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setUserDialog(false);
            }
          }}
        >
          <DialogTitle style={{ fontSize: "15px" }}>Select Pilot</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel id="pilot-select-label">Pilot</InputLabel>
              <Select
                labelId="pilot-select-label"
                id="pilot-select"
                value={userList}
                onChange={handlePilotSelect}
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                {availablePilots
                  .filter(
                    (pilot_Username, index, self) =>
                      self.indexOf(pilot_Username) === index
                  )
                  .map((pilot_Username) => (
                    <MenuItem key={pilot_Username} value={pilot_Username}>
                      {pilot_Username}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleUploadFile}
              color="primary"
              disabled={!isSelectedOption}
            >
              Submit
            </Button>
            <Button onClick={() => setUserDialog(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <RejectAcceptOperationResponsiveDialog
          reScheduled={reScheduled}
          reRouted={reRouted}
          openReject={openReject}
          handleClose={handleClose}
          compareCurrentData={compareCurrentData}
          compareData={compareData}
          twoWayOperationUuid={[]}
          isTakeOffOperation
          handleTakeOffOperation={handleStartClick}
          handleActivateOperationOnly={handleActivateOperationOnly}
          // handleDiscardTwoWayOperation={handleDiscardTwoWayOperation}
          // setRoutedSchedEdit={setRoutedSchedEdit}
          // setIfEditMode={setIfEditMode}
        />
      </div>
    </Paper>
  );
}

export default function OperationsManagement({
  operationsData,
  // telemetryData,
  selectedPlatformData,
  onRemoveClick,
  onUpdateOperationState,
  onVisabilityClick,
  role,
  handleOpenFlightAuthorizationForced,
  platformList,
  trackerList,
  pilotList,
}) {
  // const [updatingOperation, setUpdatingOperation] = useState(false);
  const platformNames = useRef([]);
  const [platformsMapping, setPlatformsMapping] = useState([]);
  const [platformTypesMapping, setPlatformTypesMapping] = useState([]);

  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [focusedFlight, setFocusedFlight] = useState(null);
  const handleOnFocus = (operationOnFocus) => {
    mapboxController.setFocussedOperation(operationOnFocus);
    setFocusedFlight(operationOnFocus ? operationOnFocus.reference.id : null);
  };

  const [telemetryData, setTelemetryData] = useState([]);
  const teleObjRef = useRef({});
  const actualTeleObjRef = useRef({});
  const keyRef = useRef({});
  const actualKeyRef = useRef({});
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });

  const api = useApi();
  const dispatch = useDispatch();

  const getTrackerFromUuid = async (uuid) => {
    await trackerList.current;
    if (!trackerList.current) return "";
    if (!uuid) return "";
    if (!(uuid in trackerList.current)) return "";
    return trackerList.current[uuid];
  };
  const getPilotFromUuid = (uuid) => {
    if (!pilotList.current) return "";
    if (!uuid) return "";
    if (!(uuid in pilotList.current)) return "";
    return pilotList.current[uuid];
  };

  // PLATFORM NAME & TYPE MAPPING
  const handlePlatformTypesMapping = async () => {
    const mapping = [];
    try {
      const response = await api.getPlatformTypes();

      if (response.data) {
        response.data.forEach((obj) => {
          const { platform_type_uuid, model } = obj;
          mapping.push({ platform_type_uuid, model });
        });
      }
      return mapping;
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
    return null;
  };

  const handleSetPlatformTypesMapping = async () => {
    try {
      const result = await handlePlatformTypesMapping();
      setPlatformTypesMapping(result);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };

  const handlePlatformsMapping = async () => {
    const mapping = [];
    try {
      const response = await api.getPlatforms();

      if (response.data) {
        response.data.forEach((obj) => {
          const { platform_type_uuid, platform_uuid } = obj;
          mapping.push({ platform_type_uuid, platform_uuid });
        });
      }
      return mapping;
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
    return null;
  };

  const handleSetPlatformsMapping = async () => {
    try {
      const result = await handlePlatformsMapping();
      setPlatformsMapping(result);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    handleSetPlatformsMapping();
    handleSetPlatformTypesMapping();
  }, []);

  platformNames.current = platformsMapping?.map((platform) => {
    const { platform_type_uuid } = platform;
    const matchedPlatform = platformTypesMapping.find(
      (platformType) => platformType.platform_type_uuid === platform_type_uuid
    );
    const platform_name = matchedPlatform ? matchedPlatform.model : "";
    return { platform_name, platform_uuid: platform.platform_uuid };
  });

  useEffect(() => {
    const checkTrackerImei = (trakcerImei) => {
      const dataTracker = [];
      // eslint-disable-next-line func-names
      const filterDataTracker = dataTracker.filter(function (tracker) {
        return tracker.data.tracker_imei === trakcerImei;
      });
      return filterDataTracker;
    };

    const handleMessage = (data) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid) => {
          const dataPlatform = [];
          selectedPlatformData.map((p, index) => {
            return p.map((datas) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (platform) {
            return platform.data.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        const key = [data.extras.imei, data.operation_id].join("/");
        if (!keyRef.current[key]) {
          keyRef.current = {
            ...keyRef.current,
            [key]: true,
          };
        }
        // setTeleObj({
        //   ...teleObj,
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerImei: data.extras?.imei,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.imei),
                checkTrackerImei(data.extras.imei)
                  ? checkTrackerImei(data.extras.imei)
                  : [],
              ],
            ],
          },
        };
        // const ttt = Object.keys(teleObj).map((k) => teleObj[k]);
        // setTelemetryData(ttt);
        // console.log("Ttt", ttt);
        // console.log("teleobj", teleObj);
      }
      //   else {
      //  setTelemetryData([]);
      //   }
    };

    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      // debouncedHandleMessage
      handleMessage
    );

    return () => {
      // clearInterval(interval);
      removeListener?.();
    };
  }, [channel]);

  useEffect(() => {
    // This effect runs every 1 second
    const intervalId = setInterval(() => {
      // // console.log("Teleobj passedin", teleObjRef.current);
      // // const sortedData = Object.values(teleObjRef.current).sort(
      // //   (a, b) => b.timestamp - a.timestamp
      // // );

      // // Sort teleObj by timestamp in descending..order simply to remove DUPLICATE KEY DATAS

      // const sortedData = Object.keys(teleObjRef.current)
      //   .map((key) => teleObjRef.current[key])
      //   .sort((a, b) => b.timestamp - a.timestamp);
      // // run through, clear duplicate keys
      // const processedData = [];
      // const seenKeys = new Set(); // just for duplicate keys in data purposes

      // sortedData.forEach((entry) => {
      //   const key = [entry.trackerImei, entry.gufi].join("/");
      //   if (!seenKeys.has(key)) {
      //     processedData.push(entry);
      //     seenKeys.add(key);
      //   }
      // });
      // // at this point, processedData is the latest Data of SAME and NEW keys
      // // we also have keyRef with SAME and NEW keys
      // // how to consider the missing Data check?

      // // ANS: compare both key objects. 3 kinds of keys, SAME, OLD, NEW. create Temp holders for them?
      // // if SAME, replace that in actual teleobj
      // // if NEW,  create new entry in ActualKey and ActualTeleObj .
      // // if OLD, check timestamp, if older than 5 secs, OLD OLD: delete from actualTeleObj & actualKey
      // // if less than 5 secs, do nothing: keep it in actualTeleObjRef and actualKeyRef

      // const commonKeys = new Set();
      // const newKeysInKeyRef = new Set(Object.keys(keyRef.current));
      // const oldKeysInActualKeyRef = new Set(Object.keys(actualKeyRef.current));

      // for (const key of newKeysInKeyRef) {
      //   if (oldKeysInActualKeyRef.has(key)) {
      //     commonKeys.add(key);
      //     newKeysInKeyRef.delete(key);
      //     oldKeysInActualKeyRef.delete(key);
      //   }
      // }

      // // 3 separate loops?
      // // for key in SAME keys, replace actualTeleobj with teleObj
      // // for key in NEW keys, create new entry in actual teleobj and actualKey
      // // for key in OLD keys, do nothing. actually no loop here required.
      // // for key in OLD OLD keys, delete from actualTeleObj and actualkey
      // const newDataInKeyRef = Array.from(newKeysInKeyRef).map(
      //   (key) => teleObjRef.current[key]
      // );

      // const commonData = Array.from(commonKeys)
      //   .filter((key) => {
      //     // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef
      //     if (
      //       Date.now() -
      //         (actualTeleObjRef.current[key]?.timestamp || Date.now()) >
      //       20000
      //     ) {
      //       delete actualKeyRef.current[key];
      //       delete actualTeleObjRef.current[key];
      //       delete keyRef.current[key];
      //       delete teleObjRef.current[key];
      //       return false;
      //     }
      //     return true;
      //   })
      //   .map((key) => actualTeleObjRef.current[key]);

      // const oldDataInActualKeyRef = Array.from(oldKeysInActualKeyRef)
      //   .filter((key) => {
      //     // Check timestamp, if older than 5 seconds ago, remove from actualKeyRef and actualTeleObjRef

      //     if (
      //       Date.now() -
      //         (actualTeleObjRef.current[key]?.timestamp || Date.now()) >
      //       5000
      //     ) {
      //       delete actualKeyRef.current[key];
      //       delete actualTeleObjRef.current[key];
      //       delete keyRef.current[key];
      //       delete teleObjRef.current[key];
      //       return false; // Exclude from uniqueDataInActualKeyRef
      //     }
      //     return true; // Include in uniqueDataInActualKeyRef
      //   })
      //   .map((key) => actualTeleObjRef.current[key]);

      // // Update actualTeleObjRef with commonData
      // commonData.forEach((data) => {
      //   const key = [data.trackerImei, data.gufi].join("/");
      //   actualTeleObjRef.current[key] = teleObjRef.current[key];
      // });

      // // Update actualKeyRef and actualTeleObjRef with uniqueDataInKeyRef
      // newDataInKeyRef.forEach((data) => {
      //   const key = [data.trackerImei, data.gufi].join("/");
      //   actualKeyRef.current[key] = true;
      //   actualTeleObjRef.current[key] = teleObjRef.current[key];
      // });

      // const ttt = Object.keys(actualTeleObjRef.current).map(
      //   (k) => actualTeleObjRef.current[k]
      // );

      Object.keys(teleObjRef.current).forEach((k) => {
        if (
          Date.now() - (teleObjRef.current[k]?.timestamp || Date.now()) >
          20000
        ) {
          delete teleObjRef.current[k];
        }
      });

      // const ttt = Object.keys(teleObjRef.current).map(
      //   (k) => teleObjRef.current[k]
      // );
      // finally Set telemetryData with  actualTeleObj
      setTelemetryData(teleObjRef.current);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    // <LoadingOverlay
    //   active={updatingOperation}
    //   spinner
    //   text="Updating Operation State..."
    // >
    <div>
      {operationsData &&
        Array.isArray(operationsData) &&
        operationsData.map((operation, index) => (
          <OperationMasterControl
            key={operation.reference.id}
            operation={operation}
            // telemetryData={telemetryData[index]}
            telemetryData={telemetryData}
            highlighted={operation.reference.id === focusedFlight}
            handleOnFocus={handleOnFocus}
            onRemoveClick={onRemoveClick}
            platformData={selectedPlatformData[index]}
            onUpdateOperationState={onUpdateOperationState}
            onVisabilityClick={onVisabilityClick}
            // setUpdatingOperation={setUpdatingOperation}
            role={role}
            trackerId={operation.request.tracker_uuid}
            pilotId={operation.request.pilot_uuid}
            mapboxController={mapboxController}
            platformNames={platformNames.current}
            handleOpenFlightAuthorizationForced={
              handleOpenFlightAuthorizationForced
            }
            getTrackerFromUuid={getTrackerFromUuid}
            getPilotFromUuid={getPilotFromUuid}
          />
        ))}
    </div>
    // </LoadingOverlay>
  );
}
