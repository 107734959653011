import axios from "axios";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { sharedEndpoints } from "./shared";
import { weatherApi } from "./weather";
import { setAuth } from "../store/actions";
import { store } from "../store";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const createOperatorRequestInstance = (
  jwtToken,
  refreshToken,
  isOperator,
  isRemoteId
) => {
  /**
   * Mars API
   */
  const mars = axios.create({
    baseURL: store.getState().envVar["base_url-mars"].Value,
  });

  const cognitoDomain =
    store.getState().envVar["cognito_domain-non_authority"].Value;
  const operatorClientId =
    store.getState().envVar["app_client_id-operator"].Value;
  const pilotClientId = store.getState().envVar["app_client_id-pilot"].Value;
  const remoteIdClientId =
    store.getState().envVar["app_client_id-remote_id"].Value;
  const gnssUrl = store.getState().envVar.gnss_api_url.Value;
  /**
   * Mars Error Handling
   * Workflow
   * 1. Interceptor Request
   *  -if token expiring, refresh token before submit
   *  -if refresh token expired, attemptrefresh() will trigger logout
   *  -leave other error handling to response
   *
   * 2. Interceptor Response
   *  -handle response errors
   *  -check token expired in case expired right after request sent
   */

  const handleInterceptorResponseError = (error) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // decode accessToken
      const jwtPayload = JSON.parse(window.atob(accessToken.split(".")[1]));
      if (jwtPayload.exp <= Date.now() / 1000) {
        return attemptRefresh()
          .then((token) => {
            const { config } = error;
            config.headers.Authorization = `Bearer ${token}`;
            return axios.request(config);
          })
          .catch((err) => {
            return Promise.reject(error);
          });
      }
    }
    if (error?.toJSON?.().status === 502 && localStorage.getItem("idToken")) {
      console.log("502 Error Detected");
    }
    if (error?.toJSON?.().status === 401 && localStorage.getItem("idToken")) {
      // localStorage.clear();
      return attemptRefresh()
        .then((token) => {
          const { config } = error;
          config.headers.Authorization = `Bearer ${token}`;
          return axios.request(config);
        })
        .catch(() => {
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  };

  const refreshAuthInfo = (idToken, accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", refreshToken);
    const userInfo = parseJwt(idToken);
    store.dispatch(
      setAuth({
        refreshToken,
        accessToken,
        userInfo,
        isPilot:
          userInfo?.["cognito:groups"].some((group) =>
            group.startsWith("company:")
          ) &&
          !userInfo?.["cognito:groups"].includes("operator") &&
          !userInfo?.["cognito:groups"].includes("remote-id"),
        isOperator: userInfo?.["cognito:groups"].includes("operator"),
        isAuthority: userInfo?.["cognito:groups"].includes("authority"),
        isRemoteId: userInfo?.["cognito:groups"].includes("remote-id"),
        isLoggedIn: !!accessToken,
      })
    );
  };

  const attemptRefresh = async () => {
    // let clientId = pilotClientId;
    // if (isOperator) clientId = operatorClientId;
    // if (isRemoteId) clientId = remoteIdClientId;
    // const params = new URLSearchParams({
    //   grant_type: "refresh_token",
    //   client_id: clientId,
    //   refresh_token: refreshToken,
    // });
    try {
      // const { data } = await axios.post(
      //   `${cognitoDomain}/oauth2/token`,
      //   params
      // );
      // const { id_token, access_token } = data;

      // handle refresh token here
      const user = await Auth.currentAuthenticatedUser();
      const access_token = user.signInUserSession.accessToken.jwtToken;
      const id_token = user.signInUserSession.idToken.jwtToken;

      refreshAuthInfo(id_token, access_token);
      return access_token;
    } catch (err) {
      console.log("Mars Error : ", err);
      handleSessionExpired(err);
    }
    return null;
  };

  let sessionExpiredTriggered = false;
  const handleSessionExpired = (e) => {
    if (!sessionExpiredTriggered) {
      localStorage.clear();
      console.log(e);
      window.location.href = "/login";
      alert("Your session has expired. Please login again.");
      sessionExpiredTriggered = true;
    }
  };
  // mars.interceptors.response.use(
  //   (config) => config,
  //   (error) => {
  //     console.log("Mars", error);
  //     if (error?.toJSON?.().status === 401 && localStorage.getItem("idToken")) {
  //       localStorage.clear();
  //       return attemptRefresh()
  //         .then((token) => {
  //           const { config } = error;
  //           config.headers.Authorization = token;
  //           return axios.request(config);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           alert("Your session has expired. Please login again.");
  //           window.location.href = "/login";
  //         });
  //     }
  //     // Any status codes that falls outside the range of 2xx cause this function to trigger
  //     // Do something with response error
  //     return Promise.reject(error);
  //   }
  // );

  mars.interceptors.request.use(async (config) => {
    if (!jwtToken) return new axios.Cancel("No token no request");
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // decode accessToken
      const jwtPayload = JSON.parse(window.atob(accessToken.split(".")[1]));

      // refresh token when expiring in 10mins
      if (jwtPayload.exp <= Date.now() / 1000) {
        // localStorage.clear();
        return attemptRefresh()
          .then((token) => {
            return {
              ...config,
              headers: {
                ...config.headers,
                "content-type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
          })
          .catch((err) => {
            // should not trigger, attemptRefresh should trigger logout on error
            console.log("Mars Error : ", err);
          });
      }
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        "content-type": "application/json",
        Authorization: `Bearer ${store.getState().auth.accessToken}`,
      },
    };
  });

  mars.interceptors.response.use(
    (config) => config,
    (error) => {
      return handleInterceptorResponseError(error);
    }
  );

  // sdspbuilding.interceptors.request.use((config) => {
  //   config.headers = {
  //     ...config.headers,
  //     "content-type": "application/json",
  //     Authorization: jwtToken,
  //   };
  //   return config;
  // });

  /**
   * Functions
   */

  const getPilotOperations = (pilot_uuid, offset) => {
    return mars.get(
      `/operations/list/${pilot_uuid}?isPast=true&assetType=pilot&tags=operation${
        offset ? `&offset=${offset}` : "&offset=0"
      }`
    );
  };

  const getPlatformOperations = (platform_uuid, offset) => {
    return mars.get(
      `/operations/list/${platform_uuid}?isPast=true&assetType=platform&tags=operation${
        offset ? `&offset=${offset}` : "&offset=0"
      }`
    );
  };

  const getPilots = () => mars.get("/pilot/list");
  const getPilot = (pilot_uuid) => mars.get(`/pilot/${pilot_uuid}`);

  const getLogState = (offset) => {
    let queryString = `/operations/state/logs`;
    if (offset) queryString += `?offset=${offset}`;
    return mars.get(queryString);
  };
  const getOperationLogState = (operationId) => {
    let queryString = `/operations/state/logs`;
    if (operationId) queryString += `?opId=${operationId}`;
    return mars.get(queryString);
  };

  const createPilot = ({ name, username, email, phone, license, expiry }) =>
    mars.request({
      method: "post",
      url: `/pilot`,
      data: {
        pilot_name: name,
        pilot_username: username,
        pilot_email: email,
        pilot_contact: phone,
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const deletePilot = (pilot_uuid, pilot_username) =>
    mars.request({
      method: "delete",
      url: `/pilot/${pilot_uuid}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        pilot_username,
      },
    });

  const updatePilot = (pilot_uuid, { license, expiry }) =>
    mars.request({
      method: "put",
      url: `/pilot/${pilot_uuid}`,
      data: {
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const getRemoteId = () => mars.get("/remote-id/list");

  const createRemoteId = ({ username, email }) =>
    mars.request({
      method: "post",
      url: `/remote-id`,
      data: {
        remote_id_username: username,
        remote_id_email: email,
      },
    });

  const updateRemoteId = (username, { email }) =>
    mars.request({
      method: "put",
      url: `/remote-id/${username}`,
      data: {
        remote_id_email: email,
      },
    });

  const deleteRemoteId = (username) =>
    mars.request({
      method: "delete",
      url: `/remote-id/${username}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const submitNewPlatform = ({
    callsign,
    registration,
    expiry,
    // puckUUID,
    platformType,
    remotecontroller,
    flightcontroller,
  }) =>
    mars.request({
      method: "post",
      url: `/platform`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        // puck_uuid: puckUUID,
        platform_type_uuid: platformType,
        remote_controller_sn: remotecontroller,
        flight_controller_sn: flightcontroller,
      },
    });

  const updatePlatformInfo = ({
    platform_uuid,
    callsign,
    registration,
    expiry,
    // puckID,
    platformType,
    remotecontroller,
    flightcontroller,
  }) =>
    mars.request({
      method: "put",
      url: `/platform/${platform_uuid}`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        // puck_uuid: puckID,
        platform_type_uuid: platformType,
        remote_controller_sn: remotecontroller,
        flight_controller_sn: flightcontroller,
      },
    });

  const getPlatform = async (platform_uuid) =>
    mars.request({
      method: "get",
      url: `/platform/${platform_uuid}`,
    });
  const submitNewTracker = ({
    trackername,
    trackerSn,
    simexpiry,
    trackerimei,
  }) =>
    mars.request({
      method: "post",
      url: `/tracker`,
      data: {
        tracker_sn: trackerSn,
        tracker_imei: trackerimei,
        tracker_name: trackername,
        sim_expiry: simexpiry,
      },
    });
  const getTracker = () =>
    mars.request({
      method: "get",
      url: `/tracker/list`,
    });
  const getTrackerScan = (tracker_uuid) =>
    mars.request({
      method: "get",
      url: `/tracker/${tracker_uuid}`,
    });
  const updateTracker = ({
    selectedtrackeruuid,
    trackername,
    trackerSn,
    simexpiry,
    trackerimei,
  }) =>
    mars.request({
      method: "put",
      url: `/tracker/${selectedtrackeruuid}`,
      data: {
        tracker_imei: trackerimei,
        tracker_name: trackername,
        tracker_sn: trackerSn,
        sim_expiry: simexpiry,
      },
    });
  const deleteTracker = (tracker_uuid) =>
    mars.delete(`/tracker/${tracker_uuid}`);

  const getOperationData = async ({ operationID }) =>
    mars.request({
      method: "get",
      url: `/operations/${operationID}`,
    });

  const getMaintenance = () => mars.get("/platform/maintenance/list");

  const getMaintenanceData = async ({ platform_uuid }) =>
    mars.request({
      method: "get",
      url: `/platform/maintenance/${platform_uuid}`,
    });

  const deleteMaintenance = (record_uuid) =>
    mars.request({
      method: "delete",
      url: `/platform/maintenance/${record_uuid}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const submitNewMaintenance = ({
    platform_id,
    maintenance_date,
    purpose,
    defect,
    rectification,
    conducted_by,
    creator_id,
    time_created,
  }) => {
    mars.request({
      method: "post",
      url: `/platform/maintenance`,
      data: {
        platform_uuid: platform_id,
        maintenance_date,
        purpose,
        defect,
        rectification,
        conducted_by,
        creator_id,
        time_created,
      },
    });
  };

  const updateMaintenance = (
    record_uuid,
    {
      platform_id,
      maintenance_date,
      purpose,
      defect,
      rectification,
      conducted_by,
      creator_id,
      time_created,
    }
  ) => {
    mars.request({
      method: "put",
      url: `/platform/maintenance/${record_uuid}`,
      data: {
        platform_uuid: platform_id,
        maintenance_date,
        purpose,
        defect,
        rectification,
        conducted_by,
        creator_id,
        time_created,
      },
    });
  };
  const submitNewPlatformType = ({
    model,
    airframe,
    takeoffspeed,
    cruisingspeed,
    landingspeed,
    endurance,
    radiorange,
    tsemean,
    tsevariance,
    weight,
  }) =>
    mars.request({
      method: "post",
      url: `/platform/type`,
      data: {
        model,
        airframe,
        speed_takeoff: takeoffspeed,
        speed_curising: cruisingspeed,
        speed_landing: landingspeed,
        endurance,
        radio_range: radiorange,
        tse_mean: tsemean,
        tse_variance: tsevariance,
        weight,
      },
    });

  const updatePlatformTypeInfo = (
    platform_type_uuid,
    {
      model,
      airframe,
      takeoffspeed,
      cruisingspeed,
      landingspeed,
      endurance,
      radiorange,
      tsemean,
      tsevariance,
      weight,
    }
  ) =>
    mars.request({
      method: "put",
      url: `/platform/type/${platform_type_uuid}`,
      data: {
        model,
        airframe,
        speed_takeoff: takeoffspeed,
        speed_curising: cruisingspeed,
        speed_landing: landingspeed,
        endurance,
        radio_range: radiorange,
        tse_mean: tsemean,
        tse_variance: tsevariance,
        weight,
      },
    });

  const getPlatformTypes = () =>
    mars.request({
      method: "get",
      url: `/platform/type/list`,
    });

  const getPlatformType = async (platform_type_uuid) =>
    mars.request({
      method: "get",
      url: `/platform/type/${platform_type_uuid}`,
    });

  const submitOperation = (data) =>
    // set way points including p2p and intermediate
    mars.request({
      method: "post",
      url: `/operations/wps`,
      data,
    });
  const submitAreaOperation = (data) =>
    // set area operations
    mars.request({
      method: "post",
      url: `/operations`,
      data,
    });
  const getPlatforms = () => mars.get(`/platform/list`);

  const getOperations = (isPast, offset, isOperations, isRidOperations) => {
    let url = "/operations/list";
    url += isPast ? "?isPast=true" : "?isPast=";
    url += offset ? `&offset=${offset}` : "";
    url += isOperations && "&tags=operation";
    url += isOperations && isRidOperations ? ",rid" : "";
    url += !isOperations && isRidOperations ? "&tags=rid" : "";
    return mars.get(url);
  };

  const getActiveOperations = (timeStart, timeEnd, ignoreLanded) => {
    return mars.get(
      `/operations/list/timeframe?timeStart=${timeStart}&timeEnd=${timeEnd}&ignoreLanded=${ignoreLanded}&tags=operation`
    );
  };

  const deleteOperation = (operationID) =>
    mars.request({
      method: "delete",
      url: `/operations/${operationID}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const updateOperation = (operationID, operationState) =>
    mars.request({
      method: "post",
      url: "/operations/state",
      headers: {
        "content-type": "application/json",
      },
      data: {
        operation_uuid: operationID,
        new_state: operationState,
      },
    });

  const editOperation = (operationID, operation, force = "false") =>
    mars.request({
      method: "put",
      url: `/operations/${operationID}?force=${force}`,
      headers: {
        "content-type": "application/json",
      },
      data: operation,
    });

  const postMissionDetails = (OperationDetails, broadcast, env, uasID) =>
    mars.request({
      method: "post",
      url: `/operations/takeoff`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        operation_uuid: OperationDetails.reference.id,
        env,
        broadcast,
        uas_id: uasID,
      },
    });

  // /**
  //  * MarineTraffic API
  //  */
  //  const marineTrafficBaseURL = "https://services.marinetraffic.com/api/exportvessels/fc14e1966a9e9e9a9d445ba0e794c520539bfe82?v=8&timespan=5&msgtype=extended&protocol=json";

  // const getThaiShips = () => {
  //   return marineTraffic.get(marineTrafficBaseURL);
  //     // headers: {
  //     //   Authorization: null,
  //     // },
  //     // data: {
  //     //   v: 8,
  //     //   MAXLAT: 13.19086,
  //     //   MINLON: 100.77824,
  //     //   MAXLON: 100.84073,
  //     //   MINLAT: 13.10761,
  //     //   protocol: "json",
  //     //   msgtype: "simple",
  //     // },
  //   });
  // };

  const getLogMessages = async (data) =>
    mars.request({
      method: "POST",
      url: `/log-messages/get`,
      data,
    });

  const createLogMessage = (data) =>
    mars.request({
      method: "POST",
      url: `/log-messages`,
      data,
    });

  const deletePlatformType = (platform_type_uuid) =>
    mars.delete(`/platform/type/${platform_type_uuid}`);

  const deletePlatform = (platform_uuid) =>
    mars.delete(`/platform/${platform_uuid}`);

  const addOperationSelection = (operation_uuid) =>
    mars.post(`/operations/selection`, { operation_uuid });

  const removeOperationSelection = (operation_uuid) =>
    mars.delete(`/operations/selection/${operation_uuid}`);

  const getAllOperationSelection = () => mars.get(`/operations/selection`);

  const getOperationOwner = (operation_uuids) =>
    mars.post(`/operations/owner`, operation_uuids);

  /**
   *
   * @param {<Object>} {"operation_uuids": Array of operation_uuids}
   * @returns {<Array>} Array of operations with operation_uuid, creator_id, and an Array of approved users.
   */
  const getOperationsApprovedUsers = (operation_uuids) =>
    mars.post(`/operations/approved-users`, operation_uuids);

  const getPermits = (login_user) => {
    return mars.get(`/permit/${login_user}`);
  };

  const getPermitUploadURL = (data) =>
    mars.request({
      method: "POST",
      url: `/permit/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
        wayPoints: data.wayPoints,
        activityStart: data.activityStart,
        permitExpiry: data.permitExpiry,
        altitude: data.altitude,
        location: data.location,
      },
    });

  const deletePermit = (username, key) =>
    mars.request({
      method: "delete",
      url: `/permit/${username}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        key,
      },
    });

  const getPermitMetaData = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/permit/metadata/${encodedData}`);
  };

  const getPermitDownloadURL = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/permit/download/${encodedData}`);
  };

  const getWayline = (user) => {
    return mars.get(`/wayline/${user}`);
  };

  const deleteWayline = (user, filename) => {
    return mars.delete(`/wayline/${user}?fileName=${filename}`);
  };

  const getWaylineUploadURL = (data) =>
    mars.request({
      method: "POST",
      url: `/wayline/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
      },
    });

  const getWaylineMetaData = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/metadata/${encodedData}`);
  };

  const getWaylineDownloadURL = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/download/${encodedData}`);
  };

  const getWaylineDeconflict = (login_user) => {
    return mars.get(`/wayline/${login_user}`);
  };

  const getWaylineUploadURLDeconflict = (data) =>
    mars.request({
      method: "POST",
      url: `/wayline/upload`,
      data: {
        loginUser: data.loginUser,
        fileName: data.fileName,
      },
    });

  const getWaylineMetaDataDeconflict = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/metadata/${encodedData}`);
  };

  const getWaylineDownloadURLDeconflict = (file_name) => {
    const encodedData = encodeURIComponent(file_name);
    return mars.get(`/wayline/download/${encodedData}`);
  };

  const getGnssData = (coordinates) => {
    return mars.get(
      `${gnssUrl}?nwData=${JSON.stringify(
        coordinates.nwData
      )}&neData=${JSON.stringify(coordinates.neData)}&swData=${JSON.stringify(
        coordinates.swData
      )}&seData=${JSON.stringify(coordinates.seData)}&altitude=${
        coordinates.altitude
      }`
    );
  };

  const sendEmail = (recipientUsername, message, subject) => {
    mars.request({
      method: "POST",
      url: `/send-email`,
      data: {
        recipientUsername,
        message,
        subject,
      },
    });
  };

  const submitPortOperation = (data) => {
    return mars.request({
      method: "POST",
      url: `/operations/corridor`,
      data,
    });
  };

  const getCorridorAvailability = (data) => {
    return mars.request({
      method: "POST",
      url: `/operations/corridor/availability`,
      data,
    });
  };

  const saveRCInformation = (data, userUUID) => {
    return mars.request({
      method: "POST",
      url: `/workspaces/${userUUID}/devices/save`,
      baseURL: `${
        store.getState().envVar["base_url-mars"].Value
      }/dji-cloud/manage/api/v1`,
      headers: {
        "x-auth-token": localStorage.getItem("idToken"),
        Authorization: localStorage.getItem("accessToken"),
      },
      data,
    });
  };

  const updateUserRole = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}/role`,
      data: {
        current_user_role: data.current_user_role,
        user_roles: data.user_roles,
      },
    });
  };

  const updateUserDetails = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}`,
      data: {
        email: data.email,
        name: data.name,
        contact: data.contact,
      },
    });
  };

  const getUser = (username) => {
    return mars.get(`/users/${username}`);
  };

  const createUser = (data) => {
    return mars.request({
      method: "POST",
      url: `/users`,
      data: {
        username: data.username,
        email: data.email,
        user_roles: data.user_roles,
        privileges: data.privileges,
        default_role: data.current_user_role,
        name: data.name,
        contact: data.contact,
      },
    });
  };

  const getUserPrivilege = (username) => {
    return mars.get(`/users/${username}/privilege`);
  };

  const updateUserPrivilege = (data) => {
    return mars.request({
      method: "PUT",
      url: `/users/${data.username}/privilege`,
      data: {
        privileges: data.privileges,
      },
    });
  };

  const deleteUser = (username) => mars.delete(`/users/${username}`);

  const getOrganisationUsers = () => {
    return mars.get(`/organisation/users/list`);
  };

  const getOrganisationRoles = (fullList = false) => {
    return mars.get(
      `/organisation/subscription/role${fullList ? "?full_list=true" : ""}`
    );
  };

  const getOrganisationPrivilege = (fullList = false) => {
    return mars.get(
      `/organisation/subscription/privilege${fullList ? "?full_list=true" : ""}`
    );
  };

  const getOwnOrganisation = () => mars.get(`/organisation`);

  const updateOwnOrganisation = ({ organisation_name }) =>
    mars.request({
      method: "put",
      url: `/organisation`,
      data: {
        organisation_name,
      },
    });

  return {
    getOperationLogState,
    getCorridorAvailability,
    submitPortOperation,
    sendEmail,
    getGnssData,
    getPermits,
    getPermitUploadURL,
    getPermitMetaData,
    getPermitDownloadURL,
    deletePermit,
    getWayline,
    getWaylineUploadURL,
    getWaylineMetaData,
    getWaylineDownloadURL,
    getWaylineDeconflict,
    getWaylineUploadURLDeconflict,
    getWaylineMetaDataDeconflict,
    getWaylineDownloadURLDeconflict,
    deleteWayline,
    getRemoteId,
    createRemoteId,
    updateRemoteId,
    deleteRemoteId,
    getPilotOperations,
    getPlatformOperations,
    getPilots,
    getPilot,
    createPilot,
    updatePilot,
    deletePilot,
    submitNewPlatform,
    updatePlatformInfo,
    submitNewPlatformType,
    updatePlatformTypeInfo,
    getPlatform,
    getOperationData,
    submitOperation,
    submitAreaOperation,
    getPlatforms,
    getOperations,
    getActiveOperations,
    deleteOperation,
    editOperation,
    updateOperation,
    postMissionDetails,
    getLogMessages,
    createLogMessage,
    getPlatformType,
    getPlatformTypes,
    deletePlatformType,
    deletePlatform,
    addOperationSelection,
    getAllOperationSelection,
    removeOperationSelection,
    getLogState,
    getTracker,
    getTrackerScan,
    submitNewTracker,
    updateTracker,
    deleteTracker,
    getMaintenanceData,
    getMaintenance,
    deleteMaintenance,
    submitNewMaintenance,
    updateMaintenance,
    getOperationOwner,
    getOperationsApprovedUsers,
    saveRCInformation,
    getUser,
    createUser,
    deleteUser,
    updateUserRole,
    updateUserDetails,
    getOrganisationUsers,
    getOrganisationRoles,
    getOrganisationPrivilege,
    getOwnOrganisation,
    getUserPrivilege,
    updateUserPrivilege,
    updateOwnOrganisation,
    weather: weatherApi(),
    jwtToken,
    ...sharedEndpoints,
  };
};
