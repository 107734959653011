import React from "react";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import TextField from "@material-ui/core/TextField";
import FileSaver from "file-saver";
import { format } from "date-fns";
import axios from "axios";
import { useApi } from "../../api/useApi";
import { downloadSingleJSON } from "../../services/json";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import Waypoints from "../../pages/Protected/CreateOperationForm/route";

// worker for pdf viewer
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errorMessage,
  helperText,
  setError,
  type,
  readOnly,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      type={type}
      onChange={(event) => {
        if (errorMessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={errorMessage}
      helperText={errorMessage || helperText}
      InputProps={{
        readOnly,
      }}
    />
  );
}

export default function AddPermit(props) {
  const {
    handleClose,
    updatingPermit,
    updatingPermitURL,
    updatingPermitMetaData,
  } = props;
  const [fileBase64, setFileBase64] = React.useState("");
  const [fileRaw, setFileRaw] = React.useState("");
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pdfText, setPdfText] = React.useState([]);
  const [pdfZoom, setPdfZoom] = React.useState(1);
  const [browseDisable, setBrowseDisable] = React.useState(false);
  const [waypoint, setWaypoint] = React.useState("");
  const [waypointError, setWaypointError] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [locationError, setLocationError] = React.useState("");
  const [altitude, setAltitude] = React.useState("");
  const [altitudeError, setAltitudeError] = React.useState("");
  const [textRendered, setTextRendered] = React.useState(false);
  const [startTime, setStartTime] = React.useState(
    format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );
  const [endTime, setEndTime] = React.useState(
    format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );

  const [selectedWaypoints, setSelectedWaypoints] = React.useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);

  const api = useApi();

  React.useEffect(() => {
    if (updatingPermit) {
      setFileBase64(updatingPermitURL.uploadURL);
      const waypointData = JSON.parse(
        updatingPermitMetaData.metaData.waypoints
      );
      setLocation(updatingPermitMetaData.metaData.location);
      setSelectedWaypoints(waypointData);
      setAltitude(updatingPermitMetaData.metaData.altitude);
      setStartTime(updatingPermitMetaData.metaData.activitystart);
      setEndTime(updatingPermitMetaData.metaData.permitexpiry);
    }
  }, []);

  const onDocumentLoadSuccess = (numOfPages) => {
    setNumPages(numOfPages._pdfInfo.numPages);
    setPageNumber(1);
    setBrowseDisable(true);
  };

  const { username } = useCognitoAuth();

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const searchValues = async () => {
    if (textRendered || updatingPermit) {
      return;
    }

    setTextRendered(true);
    const indexEnd = pdfText
      .join(" ")
      .search(
        "Table 1. List of Activity Dates, Activity Times and Operating Altitude for Proposed Unmanned Aircraft Operations"
      );
    const indexStart = pdfText
      .join(" ")
      .search("Activity Date Activity Time Operating Altitude");
    const pdfTextEdit = {};
    pdfTextEdit.data = pdfText?.join(" ").slice(indexStart + 46, indexEnd);
    const firstPoint = pdfTextEdit?.data.toLowerCase().search("to");
    const secondPoint = pdfTextEdit?.data
      .toLowerCase()
      .slice(firstPoint + 2)
      .search("to");
    const middlePoint = firstPoint + secondPoint;
    pdfTextEdit.activityDate = pdfTextEdit.data.slice(0, middlePoint - 7);
    pdfTextEdit.activityTime = pdfTextEdit.data.slice(
      middlePoint - 7,
      middlePoint + 12
    );

    pdfTextEdit.operatingAltitude = pdfTextEdit.data.slice(middlePoint + 12);

    setAltitude(
      pdfTextEdit.operatingAltitude
        ? Math.floor(pdfTextEdit.operatingAltitude.match(/\d+/)[0] * 0.304)
        : 0
    );

    const activityTimeArr = pdfTextEdit.activityTime
      .replace(/[^a-z0-9]/gi, "")
      .toUpperCase()
      .split("TO");

    try {
      setStartTime(
        format(
          new Date(
            `${
              pdfTextEdit.activityDate.toLowerCase().split("to")[0]
            } ${activityTimeArr[0]
              .replace(/\D/g, "")
              .slice(0, 2)}:${activityTimeArr[0].replace(/\D/g, "").slice(2)}`
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
      );

      setEndTime(
        format(
          new Date(
            `${
              pdfTextEdit.activityDate.toLowerCase().split("to")[1]
            } ${activityTimeArr[1]
              .replace(/\D/g, "")
              .slice(0, 2)}:${activityTimeArr[0].replace(/\D/g, "").slice(2)}`
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
      );
    } catch (e) {
      setStartTime(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
      setEndTime(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
    }
  };

  const handleSubmit = async () => {
    if (fileRaw.name.slice(-4).toLowerCase() !== ".pdf") {
      window.alert("Please Select a PDF File");
      return;
    }
    const getURL = async () => {
      const fileName = `${location}_${fileRaw.name.slice(
        0,
        -4
      )} ${startTime.slice(0, 10)} ${endTime.slice(0, 10)}.pdf`;
      const response = await api.getPermitUploadURL({
        loginUser: username,
        fileName,
        wayPoints: JSON.stringify(selectedWaypoints),
        activityStart: startTime,
        permitExpiry: endTime,
        altitude,
        location,
      });
      // const URLResponse = JSON.parse(response.data);
      const URLResponse = response.data;
      const putResponse = await axios.put(URLResponse.uploadURL, fileRaw, {
        headers: { "Content-Type": "application/pdf" },
      });
      if (putResponse.statusText === "OK") {
        window.alert("PDF Uploaded");
        handleClose();
      }
    };
    getURL();
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const handleImport = (e) => {
    if (e) {
      const file = e?.target.files[0];
      setFileRaw(file);
      // file.length > 0 && setFileBase64(e.target.result);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e2) => {
        setFileBase64(e2.target.result);
      };
    }
  };

  const textRenderer = (textItem) => {
    if (textItem.str !== " ") {
      const textUpdate = pdfText;
      textUpdate.push(textItem.str);
      setPdfText(textUpdate);
    }
    return textItem.str;
  };

  // For future add on, allows user to export permit details (waypoint, altitude, time) to JSON file and import to operation

  // const handleExportJSON = async () => {
  //   console.log("Altitude :", altitude);
  //   console.log(updatingPermitURL.Key);
  //   console.log("Start Time :", startTime);
  //   console.log("End Time", endTime);
  //   const exportData = {
  //     operation_json: {
  //       reference: {
  //         id: "abcd",
  //       },
  //     },
  //     request_json: {
  //       pilot_uuid: ["a"],
  //       platform_uuid: ["a"],
  //       tracker_uuid: ["a"],
  //       waypoints: [
  //         [103.86410903625483, 1.270252815502075],
  //         [103.8655565669834, 1.268897524271452],
  //       ],
  //       altitude: 60,
  //       altitude_reference: "W84",
  //       time_start: "2022-11-23T02:35:24.035Z",
  //       time_end: "2022-11-23T03:05:24.035Z",
  //       ground_speed: 5,
  //       operation_type: "vlos",
  //       description: "Sample Upload Operation",
  //       contingency_plans: {
  //         landing_point: [[103.86543634855944, 1.2699796170719662]],
  //         safe_altitude: 60,
  //       },
  //       departure: [103.86410903625483, 1.270252815502075],
  //       arrival: [103.8655565669834, 1.268897524271452],
  //     },
  //   };
  //   await downloadSingleJSON(exportData, "TEST");
  // };

  const handleZoomIn = () => {
    if (pdfZoom < 2.5) {
      setPdfZoom(pdfZoom + 0.5);
    }
  };
  const handleZoomOut = () => {
    if (pdfZoom > 1) {
      setPdfZoom(pdfZoom - 0.5);
    }
  };

  const testFunction = () => {
    setPdfZoom(2.0);
  };

  const updateWaypointLatitude = (newLatitude, index) => {
    setSelectedWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][1] = newLatitude;
      return updatedWaypoints;
    });
  };

  const updateWaypointLongitude = (newLongitude, index) => {
    setSelectedWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][0] = newLongitude;
      return updatedWaypoints;
    });
  };

  return (
    <Paper
      style={{
        position: "relative",
        minWidth: "500px",
        minHeight: "100px",
        margin: "10px",
      }}
    >
      <Grid>
        <Typography
          variant="h6"
          style={{
            marginBottom: "5px",
          }}
        >
          Upload Permit
        </Typography>
        <Button
          component="label"
          size="small"
          variant="contained"
          color="primary"
          fullWidth
          style={{
            marginBottom: "5px",
          }}
          disabled={browseDisable}
        >
          Browse
          <input type="file" onChange={handleImport} accept=".pdf" hidden />
        </Button>
        <CustomTextField
          id="location"
          label="Location"
          fullWidth
          value={location}
          setFuncton={setLocation}
          errorMessage={locationError}
          setError={setLocationError}
          helperText="ex. Maritime Drone Estate - Marina South Pier"
          readOnly={updatingPermit}
        />
        <Waypoints
          selectedWaypoints={selectedWaypoints}
          onChange={(updatedAreaWaypoints) => {
            setSelectedWaypoints(updatedAreaWaypoints);
          }}
          updateWaypointLongitude={updateWaypointLongitude}
          updateWaypointLatitude={updateWaypointLatitude}
          addPermitWaypoint
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomTextField
              id="startTime"
              label="Start Time (Local Time)"
              type="datetime-local"
              // fullWidth
              value={startTime}
              setFuncton={setStartTime}
              readOnly={updatingPermit}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              id="endTime"
              label="End Time (Local Time)"
              type="datetime-local"
              // fullWidth
              value={endTime}
              setFuncton={setEndTime}
              readOnly={updatingPermit}
            />
          </Grid>
        </Grid>
        <CustomTextField
          id="altitude"
          label="Altitude (m)"
          // fullWidth
          value={altitude}
          setFuncton={setAltitude}
          errorMessage={altitudeError}
          setError={setAltitudeError}
          readOnly={updatingPermit}
        />
        <br />
        <Button
          color="primary"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          {"<"}
        </Button>
        {pageNumber}
        <Button
          color="primary"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          {">"}
        </Button>
        <Button
          type="button"
          disabled={pdfZoom >= 2.5}
          onClick={handleZoomIn}
          color="primary"
        >
          Zoom In
        </Button>
        <Button
          type="button"
          disabled={pdfZoom === 1}
          onClick={handleZoomOut}
          color="primary"
        >
          Zoom Out
        </Button>
        <Paper style={{ maxHeight: 6400, overflow: "auto" }}>
          <Document
            file={fileBase64 || ""}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              customTextRenderer={textRenderer}
              onRenderTextLayerSuccess={searchValues}
              scale={pdfZoom}
            />
          </Document>
        </Paper>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Cancel
          </Button>
          {updatingPermit ? (
            ""
          ) : (
            <Button
              autoFocus
              onClick={handleSubmit}
              color="primary"
              disabled={numPages === 0}
            >
              Submit
            </Button>
          )}
        </MuiDialogActions>
      </Grid>
    </Paper>
  );
}
