import { TakeoffPoint } from "@/model/PortOperation";
import Waypoint from "@/model/Waypoint";

const initialTakeoffLandingPoint: Waypoint[] = [
  {
    position: 1,
    latitude: 0,
    longitude: 0,
    isFirst: true,
    isLast: false,
  },
  {
    position: 2,
    latitude: 0,
    longitude: 0,
    isFirst: false,
    isLast: true,
  },
];

const initialEmergencyLandingPoint: Waypoint[] = [
  {
    position: 1,
    latitude: 0,
    longitude: 0,
    isFirst: true,
    isLast: true,
  },
];

const initialCircleWaypoint: Waypoint[] = [
  {
    position: 1,
    latitude: 0,
    longitude: 0,
    radius: 0,
    isFirst: true,
    isLast: true,
  },
];

const initialPolygonWaypoint: Waypoint[] = [
  {
    position: 1,
    latitude: 0,
    longitude: 0,
    isFirst: true,
    isLast: true,
  },
  {
    position: 2,
    latitude: 0,
    longitude: 0,
    isFirst: false,
    isLast: false,
  },
  {
    position: 3,
    latitude: 0,
    longitude: 0,
    isFirst: false,
    isLast: true,
  },
];

const initialRouteWaypoint: Waypoint[] = [
  {
    position: 1,
    latitude: 0,
    longitude: 0,
    isFirst: true,
    isLast: false,
  },
  {
    position: 2,
    latitude: 0,
    longitude: 0,
    isFirst: false,
    isLast: true,
  },
];

const takeOffPoint: TakeoffPoint[] = [
  {
    label: "MPA Port Helipad East",
    lat: 1.272078663964143,
    lon: 103.8649961903046,
  },
  {
    label: "MPA Port Helipad West",
    lat: 1.2718565832210516,
    lon: 103.86469803651755,
  },
];

const data = {
  initialCircleWaypoint,
  initialPolygonWaypoint,
  initialRouteWaypoint,
  initialTakeoffLandingPoint,
  initialEmergencyLandingPoint,
  takeOffPoint,
};

export default data;
