import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Paper from "material-ui/Paper";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { Select, MenuItem, ListItemText } from "@mui/material";
import { useApi } from "../../api/useApi";
import PilotStatistics from "./PilotStatistics";
import PlatformStatistics from "./PlatformStatistics";
import OperatorStatistics from "./OperatorStatistics";
import useCognitoAuth from "../../hooks/useCognitoAuth";

export default function StatisticsDashboard(props) {
  const [availablePilots, setAvailablePilots] = useState([]);
  const [availablePlatform, setAvailablePlatform] = useState([]);
  const [availableOperator, setAvailableOperator] = useState([]);
  const [selectedPilot, setSelectedPilot] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [openPilotStatistics, setOpenPilotStatistics] = useState(false);
  const [openPlatformStatistics, setOpenPlatformStatistics] = useState(false);
  const [openOperatorStatistics, setOpenOperatorStatistics] = useState(false);
  const [logState, setLogState] = useState([]);
  const platformCallsignRef = useRef({});
  const pilotNameRef = useRef({});
  const platformTypeRef = useRef({});
  const api = useApi();
  const { isAuthority, username, isPilot } = useCognitoAuth();

  const getDataAuth = async () => {
    const [
      operatorList,
      platformList,
      platformTypeList,
      pilotList,
      logStateList,
    ] = await Promise.all([
      api.getOperators(),
      api.getPlatforms(),
      api.getPlatformTypes(),
      api.getPilots(),
      api.getLogState(),
    ]);
    if (operatorList.status === 200) setAvailableOperator(operatorList.data);
    if (logStateList.status === 200) setLogState(logStateList.data);
    if (pilotList.status === 200) {
      setAvailablePilots(pilotList.data);
      pilotNameRef.current = pilotList.data.reduce((result, pilot) => {
        return {
          ...result,
          [pilot.pilot_uuid]: {
            name: pilot.pilot_name,
          },
        };
      }, {});
    }
    if (platformList.status === 200) {
      setAvailablePlatform(platformList.data);
      platformCallsignRef.current = platformList.data.reduce(
        (result, platform) => {
          return {
            ...result,
            [platform.platform_uuid]: {
              callsign: platform.platform_callsign,
              typeUuid: platform.platform_type_uuid,
            },
          };
        },
        {}
      );
    }
    if (platformTypeList.status === 200) {
      platformTypeRef.current = platformTypeList.data.reduce(
        (result, platform) => {
          return {
            ...result,
            [platform.platform_type_uuid]: platform.model,
          };
        },
        {}
      );
    }
  };

  const getData = async () => {
    const [platformList, platformTypeList, pilotList, logStateList] =
      await Promise.all([
        api.getPlatforms(),
        api.getPlatformTypes(),
        api.getPilots(),
        api.getLogState(),
      ]);
    if (pilotList.status === 200) {
      if (isPilot) {
        pilotList.data.forEach((singlePilot) => {
          if (singlePilot.pilot_username === username) {
            setAvailablePilots([singlePilot]);
          }
        });
      } else {
        setAvailablePilots(pilotList.data);
      }
      pilotNameRef.current = pilotList.data.reduce((result, pilot) => {
        return {
          ...result,
          [pilot.pilot_uuid]: {
            name: pilot.pilot_name,
          },
        };
      }, {});
    }
    if (platformList.status === 200) {
      setAvailablePlatform(platformList.data);
      platformCallsignRef.current = platformList.data.reduce(
        (result, platform) => {
          return {
            ...result,
            [platform.platform_uuid]: {
              callsign: platform.platform_callsign,
              typeUuid: platform.platform_type_uuid,
            },
          };
        },
        {}
      );
    }
    if (platformTypeList.status === 200) {
      platformTypeRef.current = platformTypeList.data.reduce(
        (result, platform) => {
          return {
            ...result,
            [platform.platform_type_uuid]: platform.model,
          };
        },
        {}
      );
    }
    if (logStateList.status === 200) {
      setLogState(logStateList.data);
    }
  };

  useEffect(() => {
    if (!isAuthority) getData();
    if (isAuthority) getDataAuth();
  }, []);

  const handleChange = (event, type) => {
    if (type === "pilot") setSelectedPilot(event.target.value);
    if (type === "platform") setSelectedPlatform(event.target.value);
    if (type === "operator")
      event.target.value.length < 5 && setSelectedOperator(event.target.value);
  };

  const handleOpenPilotStatistics = () => {
    setOpenPilotStatistics(true);
  };

  const handleClosePilotStatistics = () => {
    setOpenPilotStatistics(false);
  };

  const handleOpenPlatformStatistics = () => {
    setOpenPlatformStatistics(true);
  };

  const handleClosePlatformStatistics = () => {
    setOpenPlatformStatistics(false);
  };

  const handleOpenOperatorStatistics = () => {
    setOpenOperatorStatistics(true);
  };

  const handleCloseOperatorStatistics = () => {
    setOpenOperatorStatistics(false);
  };

  return (
    <Paper>
      <Grid container alignItems="center">
        {isAuthority && (
          <Grid item xs={12}>
            <Card>
              <Button
                variant="contained"
                onClick={handleOpenOperatorStatistics}
                disabled={!Number.isInteger(selectedOperator[0])}
              >
                Select Operator
              </Button>
              <CardContent>
                <Select
                  multiple
                  value={selectedOperator || []}
                  onChange={(event) => handleChange(event, "operator")}
                >
                  {availableOperator.map((option, index) => (
                    <MenuItem
                      key={`operator${option.operatorName}`}
                      value={index}
                    >
                      {option.operatorName.split("company:")[1]}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!isAuthority && (
          <Grid item xs={6}>
            <Card maxWidth>
              <Button
                variant="contained"
                onClick={handleOpenPilotStatistics}
                disabled={!Number.isInteger(selectedPilot)}
              >
                Select Pilot
              </Button>
              <CardContent>
                <Select
                  value={selectedPilot}
                  onChange={(event) => handleChange(event, "pilot")}
                >
                  {availablePilots.map((option, index) => (
                    <MenuItem
                      key={option.pilot_uuid}
                      value={index}
                      sx={{ justifyContent: "space-between" }}
                    >
                      {option.pilot_name}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!isAuthority && (
          <Grid item xs={6}>
            <Card maxWidth>
              <Button
                variant="contained"
                onClick={handleOpenPlatformStatistics}
                disabled={!Number.isInteger(selectedPlatform)}
              >
                Select Platform
              </Button>
              <CardContent>
                <Select
                  value={selectedPlatform}
                  onChange={(event) => handleChange(event, "platform")}
                >
                  {availablePlatform.map((option, index) => (
                    <MenuItem
                      key={option.platform_uuid}
                      value={index}
                      sx={{ justifyContent: "space-between" }}
                    >
                      {option.platform_callsign}
                    </MenuItem>
                  ))}
                </Select>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={openPilotStatistics}
          onClose={handleClosePilotStatistics}
        >
          {availablePilots[selectedPilot] && (
            <PilotStatistics
              pilot={availablePilots[selectedPilot]}
              platformList={platformCallsignRef.current}
              platformTypeList={platformTypeRef.current}
              logState={logState}
              handleClosePilotStatistics={handleClosePilotStatistics}
            />
          )}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={openPlatformStatistics}
          onClose={handleClosePlatformStatistics}
        >
          {availablePlatform[selectedPlatform] && (
            <PlatformStatistics
              platform={availablePlatform[selectedPlatform]}
              pilotList={pilotNameRef.current}
              platformTypeList={platformTypeRef.current}
              logState={logState}
              handleClosePlatformStatistics={handleClosePlatformStatistics}
            />
          )}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={openOperatorStatistics}
          onClose={handleCloseOperatorStatistics}
        >
          {selectedOperator && (
            <OperatorStatistics
              operator={selectedOperator.map((index) => {
                return availableOperator[index];
              })}
              pilotList={pilotNameRef.current}
              platformList={platformCallsignRef.current}
              platformTypeList={platformTypeRef.current}
              logState={logState}
              isCompareMode={selectedOperator.length > 1}
              handleCloseOperatorStatistics={handleCloseOperatorStatistics}
            />
          )}
        </Dialog>
      </Grid>
    </Paper>
  );
}
