export const HIGHLIGHT_COLORS = {
  gray: [105, 105, 105, 0.2],
  darkblue: [52, 97, 199, 0.2], // protectedAreaUnderSection7AirNavigationAct
  magneta: [255, 20, 232, 0.2], // areasWithinProhibitedAreas
  orange: [255, 190, 105, 0.2], // areasWithin5kmAerodome
  darkgray: [0, 0, 0, 0.2], // areasWithinRestrictedAreas
  red: [255, 0, 55, 0.2], // areasWithinDangerAreas
  lightblue: [173, 216, 230, 0.2],
  navy: [0, 0, 128, 0.1],
  lightskyblue: [135, 206, 250, 0.1],
  darkturquoise: [0, 206, 209, 0.1],
};
