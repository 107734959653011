/* eslint-disable @typescript-eslint/no-unused-vars */
import IncidentReport from "@/model/IncidentReport";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import CreateIncidentReportForm from "./CreateReportForm";
import ItemReport from "./ItemCards/ItemReport";

interface ReportSidebarProps {
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenModal: (
    title: string,
    content: JSX.Element,
    showTitle?: boolean,
    disableDismiss?: boolean
  ) => void;
  onCloseModal: () => void;
}

export default function ReportsSidebar({
  showMessage,
  onOpenModal,
  onCloseModal,
}: ReportSidebarProps) {
  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const sampleData: IncidentReport[] = [
    {
      reportId: "ABC0001",
      title: "Sample Report",
      description: "Sample Incident Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "23/9/2022",
      incidentDate: "2022-09-23T03:07:46",
      flightno: "7e3028e3-7e9b-441b-bfaa-b0eafa003acb",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
    {
      reportId: "ABC0002",
      title: "Sample Report 2",
      description: "Sample Incident 2 Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "24/9/2022",
      incidentDate: "2022-09-24T03:07:46",
      flightno: "7e3028e3-7e9b-441b-bfaa-b0eafa003acb",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
    {
      reportId: "ABC0003",
      title: "Sample Report 3",
      description: "Sample Incident 2 Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "24/9/2022",
      incidentDate: "2022-06-10T03:07:46",
      flightno: "606349f4-c4ff-4b27-9b4d-539c24a09dbe",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
  ];

  const handleReturnToList = () => {
    setIsAdd(false);
  };

  const handleAddNew = () => {
    setIsAdd(true);
  };

  const handleReportClicked = (data: IncidentReport) => {
    const content = (
      <div className="flex-col" onClick={(e) => e.stopPropagation()}>
        <table className="table-auto w-full">
          <tbody>
            <tr className="border rounded-t-md">
              <td className="px-4 py-2">Report ID</td>
              <th>:</th>
              <td className="px-4 py-2">{data.reportId}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Title</td>
              <th>:</th>
              <td className="px-4 py-2">{data.title}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Description</td>
              <th>:</th>
              <td className="px-4 py-2">{data.description}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Pilot Name</td>
              <th>:</th>
              <td className="px-4 py-2">{data.pilotName}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Reported By</td>
              <th>:</th>
              <td className="px-4 py-2">{data.name}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Date Reported</td>
              <th>:</th>
              <td className="px-4 py-2">{data.reportDate}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Incident Date</td>
              <th>:</th>
              <td className="px-4 py-2">{data.incidentDate}</td>
            </tr>
            <tr className="border">
              <td className="px-4 py-2">Operation ID</td>
              <th>:</th>
              <td className="px-4 py-2">{data.flightno}</td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Aircraft Registration</td>
              <th>:</th>
              <td className="px-4 py-2">{data.aircraftRegistration}</td>
            </tr>
            <tr className="border rounded-b-md">
              <td className="px-4 py-2">Aircraft Model</td>
              <th>:</th>
              <td className="px-4 py-2">{data.aircraftModel}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    onOpenModal("Report Detail", content);
  };

  const handleReportSelected = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  return (
    <div className="absolute top-0 bottom-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div className="h-[90%] flex-col">
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>Incident Reports</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            {/* Content */}
            <div className="h-[85%] flex-col overflow-auto p-4">
              {/* Operation List */}
              {sampleData
                // .filter((operation) => {
                //   if (selectedTab === 1) return true;
                //   return operation.is_upcoming === true;
                // })
                .map((item) => (
                  <ItemReport
                    key={item.reportId}
                    data={item}
                    onClick={handleReportClicked}
                    onSelected={handleReportSelected}
                  />
                ))}

              {/* No Data Available */}
              {/* {!isLoading && operations.length === 0 && (
                <p className="p-8 text-center">No Data Available</p>
              )} */}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
            </div>
          </div>
          <div className="h-[10%] px-4 py-2 items-center content-center">
            {selectedIds.length > 0 ? (
              <Button
                type="primaryDark"
                size="medium"
                text="Export Report"
                className="mr-2"
                // isLoading={isDeleting}
                // disabled={isDeleting}
                onClick={() => showMessage("Coming Soon")}
              />
            ) : (
              <Button
                type="primaryDark"
                size="medium"
                text="Create Report"
                icon={<FaPlus size={12} color="white" />}
                onClick={handleAddNew}
              />
            )}
          </div>
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={handleReturnToList} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">Create Incident Report</h5>
          </div>
          <hr />

          {/* Form */}
          <CreateIncidentReportForm showMessage={showMessage} />
        </div>
      )}
    </div>
  );
}
