import React, { useCallback, useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  makeStyles,
  useTheme,
  IconButton,
  Checkbox,
  TextField,
  FormControl,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { format } from "date-fns";
import { useApi } from "../../../api/useApi";
import DetailsTrackers from "../../../components/UpcomingOperationsDetailsDialog/DetailsTrackers";
import DetailsPaltforms from "../../../components/UpcomingOperationsDetailsDialog/DetailsPaltforms";
import DetailsPilots from "../../../components/UpcomingOperationsDetailsDialog/DetailsPilots";
import ReplayDialog from "../../../components/FlightLogs/RelayDialog";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPage className={classes.icon} />
        ) : (
          <FirstPage className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPage className={classes.icon} />
        ) : (
          <LastPage className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  table: {
    tableLayout: "fixed",
    maxWidth: 1200,
  },
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
  },
}));

function OperationsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [, setSelectedNames] = useState([]);
  const [, setSelectedPuckUuids] = useState([]);
  const [filters, setFilters] = useState({
    operation: "",
    status: "",
    operationId: "",
    pilot: "",
    arrival: "",
    departure: "",
    platform: "",
    tracker: "",
    createdOn: "",
  });

  const { operations, pilotNames, platformCallsigns, trackerNames } = props;
  const rowsPerPage = 5;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, operations.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const formatDate = (date) => {
    // format(new Date(date), "MM/dd/yyyy HH:mm:ss");
    // console.log("Original Date : ", date);
    // let d = new Date(date);
    // const timezoneoffset = `${d.getTimezoneOffset() * 60 * 1000}`;
    // d = new Date(d.valueOf() - timezoneoffset);
    // console.log(`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`);
    // return [`${d.toLocaleDateString()} ${d.toLocaleTimeString()}`];
    return format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  };
  const updatedOperations = operations.map((operation) => {
    const updatedOperation = { ...operation };
    const pilotUuid = operation.request.pilot_uuid[0];
    const pilotIndex = pilotNames.uuids
      ? pilotNames.uuids.indexOf(pilotUuid)
      : -1;

    if (pilotIndex !== -1) {
      const pilotName = pilotNames.names[pilotIndex];
      updatedOperation.request = {
        ...updatedOperation.request,
        pilot_name: pilotName,
      };
    }
    const trackerUuid = operation.request.tracker_uuid[0];
    const trackerIndex = trackerNames.uuids
      ? trackerNames.uuids.indexOf(trackerUuid)
      : -1;
    if (trackerIndex !== -1) {
      const trackerName = trackerNames.names[trackerIndex];
      updatedOperation.request = {
        ...updatedOperation.request,
        tracker_name: trackerName,
      };
    }
    const platformUuid = operation.request.platform_uuid[0];
    const platformIndex = platformCallsigns.uuids
      ? platformCallsigns.uuids.indexOf(platformUuid)
      : -1;

    if (platformIndex !== -1) {
      const platformName = platformCallsigns.names[platformIndex];
      updatedOperation.request = {
        ...updatedOperation.request,
        platform_name: platformName,
      };
    }
    const formattedTimeCreated = formatDate(
      operation.reference.time_created.value
    );
    const formattedTimeEnd = formatDate(
      operation.interuss.operational_intent_reference.time_end.value
    );
    const formattedTimeStart = formatDate(
      operation.interuss.operational_intent_reference.time_start.value
    );
    updatedOperation.interuss = {
      ...updatedOperation.interuss,
      operational_intent_reference: {
        ...updatedOperation.interuss.operational_intent_reference,
        formattedTimeCreated,
        formattedTimeEnd,
        formattedTimeStart,
      },
    };
    return updatedOperation;
  });

  const filteredOperations = updatedOperations
    .sort((a, b) => {
      const isSelectedA = isSelected(a.reference.id);
      const isSelectedB = isSelected(b.reference.id);

      if (isSelectedA !== isSelectedB) {
        return isSelectedA ? -1 : 1; // Move the selected item to the top
      }

      // If both selected or both not selected, maintain the order
      return 0;
    })
    .filter((row) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        let filterValue = filters[key];
        if (typeof filterValue !== "string") {
          filterValue = filterValue.toString();
        }
        filterValue = filterValue.toLowerCase();

        switch (key) {
          case "operationId":
            return row.reference.id.toLowerCase().includes(filterValue);
          case "operation":
            return row.reference.description
              .toLowerCase()
              .includes(filterValue);
          case "status":
            return row.details.state.toLowerCase().includes(filterValue);
          case "arrival":
            return row.interuss.operational_intent_reference.formattedTimeEnd
              .toLowerCase()
              .includes(filterValue);
          case "departure":
            return row.interuss.operational_intent_reference.formattedTimeStart
              .toLowerCase()
              .includes(filterValue);
          case "pilot":
            return row.request.pilot_name.toLowerCase().includes(filterValue);
          case "tracker":
            return row.request.tracker_name.toLowerCase().includes(filterValue);
          case "platform":
            return row.request.platform_name
              .toLowerCase()
              .includes(filterValue);
          case "createdOn":
            return row.interuss.operational_intent_reference.formattedTimeCreated
              .toLowerCase()
              .includes(filterValue);
          default:
            return true;
        }
      });
    });

  const handleFilterChange = (event, columnName) => {
    const { value } = event.target;
    const newFilters = { ...filters, [columnName]: value };
    setFilters(newFilters);
  };

  const handleClearFilters = (columnName) => {
    setFilters((prevFilters) => ({ ...prevFilters, [columnName]: "" }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = operations.map((n) => n.reference.id);
      const newLogSelecteds = operations.map((n) => n.details.puck_uuid);
      setSelected(newSelecteds);
      setSelectedNames(newSelecteds);
      setSelectedPuckUuids(newLogSelecteds);
      if (props.setSelectedFlights)
        props.setSelectedFlights([newSelecteds, newLogSelecteds]);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
    setSelectedPuckUuids([]);
    if (props.setSelectedFlights) props.setSelectedFlights([]);
  };

  const handleClick = (event, id, puck_uuid) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];
    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
    if (props.setSelectedFlights) {
      const selectedPuckUuids = newSelected.map((selectedId) => {
        const foundOperation = operations.find(
          (operationItem) => operationItem.reference.id === selectedId
        );
        return foundOperation && foundOperation.details
          ? foundOperation.details.puck_uuid
          : null;
      });
      props.setSelectedFlights([newSelected, selectedPuckUuids]);
    }
  };

  const handleInputChange = (inputValue, columnName) => {
    setFilters((prevFilters) => ({ ...prevFilters, [columnName]: inputValue }));
  };

  return (
    <div>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={
                  operations.length > 0 && selected.length === operations.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="operation-select"
                  options={filteredOperations
                    .map((row) => row.reference.description)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operation"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.operation || null}
                  onChange={(event, value) => {
                    const operation = value || "";
                    handleFilterChange(
                      { target: { value: operation } },
                      "operation"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "operation")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("operation");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="departure-select"
                  options={filteredOperations
                    .map(
                      (row) =>
                        row.interuss.operational_intent_reference
                          .formattedTimeStart
                    )
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departure"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.departure || null}
                  onChange={(event, value) => {
                    const departure = value || "";
                    handleFilterChange(
                      {
                        target: {
                          value: departure,
                        },
                      },
                      "departure"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "departure")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("departure");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="arrival-select"
                  options={filteredOperations
                    .map(
                      (row) =>
                        row.interuss.operational_intent_reference
                          .formattedTimeEnd
                    )
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Arrival"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.arrival || null}
                  onChange={(event, value) => {
                    const arrival = value || "";
                    handleFilterChange(
                      {
                        target: {
                          value: arrival,
                        },
                      },
                      "arrival"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "arrival")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("arrival");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="pilot-select"
                  options={filteredOperations
                    .map((row) => row.request.pilot_name)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pilot"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.pilot || null}
                  onChange={(event, value) => {
                    const pilot = value || "";
                    handleFilterChange({ target: { value: pilot } }, "pilot");
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "pilot")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("pilot");
                    }
                  }}
                />
              </FormControl>
            </TableCell>

            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="platform-select"
                  options={filteredOperations
                    .map((row) => row.request.platform_name)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Platform"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.platform || null}
                  onChange={(event, value) => {
                    const platform = value || "";
                    handleFilterChange(
                      { target: { value: platform } },
                      "platform"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "platform")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("platform");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="tracker-select"
                  options={filteredOperations
                    .map((row) => row.request.tracker_name)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tracker"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.tracker || null}
                  onChange={(event, value) => {
                    const tracker = value || "";
                    handleFilterChange(
                      { target: { value: tracker } },
                      "tracker"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "tracker")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("tracker");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="status-select"
                  options={filteredOperations
                    .map((row) => row.details.state)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.status || null}
                  onChange={(event, value) => {
                    const status = value;
                    handleFilterChange({ target: { value: status } }, "status");
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "status")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("status");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="operationId-select"
                  options={filteredOperations
                    .map((row) => row.reference.id)
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operation Id"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.operationId || null}
                  onChange={(event, value) => {
                    const operationId = value || "";
                    handleFilterChange(
                      { target: { value: operationId } },
                      "operationId"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "operationId")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("operationId");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <FormControl style={{ width: 100 }}>
                <Autocomplete
                  id="createdOn-select"
                  options={filteredOperations
                    .map(
                      (row) =>
                        row.interuss.operational_intent_reference
                          .formattedTimeCreated
                    )
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Created On"
                      InputLabelProps={{
                        style: {
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                  value={filters.createdOn || null}
                  onChange={(event, value) => {
                    const createdOn = value || "";
                    handleFilterChange(
                      {
                        target: {
                          value: createdOn,
                        },
                      },
                      "createdOn"
                    );
                  }}
                  onInputChange={(event, inputValue) =>
                    handleInputChange(inputValue, "createdOn")
                  }
                  onClose={(event, reason) => {
                    if (reason === "clear") {
                      handleClearFilters("createdOn");
                    }
                  }}
                />
              </FormControl>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOperations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.reference.id);
              return (
                <TableRow className={classes.tableRow} key={row.reference.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) =>
                        handleClick(
                          event,
                          row.reference.id,
                          row.details.puck_uuid
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {`${row.reference.description}`}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {formatDate(
                      row.interuss.operational_intent_reference.time_start.value
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {formatDate(
                      row.interuss.operational_intent_reference.time_end.value
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <DetailsPilots pilot_uuid={row.request.pilot_uuid} />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <DetailsPaltforms
                      platform_uuid={row.request.platform_uuid}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    <DetailsTrackers tracker_uuid={row.request.tracker_uuid} />
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.details.state}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {row.reference.id}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {formatDate(row.reference.time_created.value)}
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter className={classes.tabelFooter}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              count={filteredOperations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

function OperationsHeader({
  selectedFlights,
  setLoading,
  handleMoreLogsClick,
}) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleSubmitClick = async () => {
    setLoading(true);
    try {
      setOpenDialog(true);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Grid item xs={8} style={{ textAlign: "right" }}>
          <Button
            disabled={!selectedFlights.length}
            variant="contained"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={handleSubmitClick}
            color="primary"
          >
            Submit
          </Button>
          <Button
            variant="contained"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={handleMoreLogsClick}
            color="primary"
          >
            Next 100 Logs
          </Button>
          <ReplayDialog
            open={openDialog}
            handleCloseDialog={handleCloseDialog}
            selectedFlights={selectedFlights}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function FlightHistoryComponent({ history }) {
  const [loading, setLoading] = useState(true);
  const [operations, setOperations] = useState([]);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pilotNames, setPilotNames] = useState([]);
  const [platformCallsigns, setPlatformCallsigns] = useState([]);
  const [trackerNames, setTrackerNames] = useState([]);
  const api = useApi();
  const getOperations = useCallback(async () => {
    try {
      const response = await api.getOperationsLog(offset);
      if (!response.data) return;
      const newOperationsData = response.data
        .map((flightData) => flightData.operation_json)
        .sort(
          (a, b) =>
            new Date(b.reference.time_created.value) -
            new Date(a.reference.time_created.value)
        );
      if (offset === 0) {
        setOperations(newOperationsData);
      } else {
        setOperations((prevOperations) => [
          ...prevOperations,
          ...newOperationsData,
        ]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [api, offset]);

  const handleMoreLogsClick = () => {
    setLoading(true);
    setOffset(offset + 100);
  };
  useEffect(() => {
    const getAllData = async () => {
      const listAllPilots = await api.getPilots();
      const pilotNamesData = listAllPilots.data.map(
        (pilot) => pilot.pilot_name
      );
      const pilotUuids = listAllPilots.data.map((pilot) => pilot.pilot_uuid);
      setPilotNames({ names: pilotNamesData, uuids: pilotUuids });
      const listAllPlatforms = await api.getPlatforms();
      const platformNames = listAllPlatforms.data.map(
        (platform) => platform.platform_callsign
      );
      const platformUuids = listAllPlatforms.data.map(
        (platform) => platform.platform_uuid
      );
      setPlatformCallsigns({ names: platformNames, uuids: platformUuids });
      const listAllTracker = await api.getTrackerScan();
      const trackerNamesData = listAllTracker.data.map(
        (tracker) => tracker.tracker_name
      );
      const trackerUuids = listAllTracker.data.map(
        (tracker) => tracker.tracker_uuid
      );
      setTrackerNames({ names: trackerNamesData, uuids: trackerUuids });
    };
    getAllData();
  }, [api]);

  useEffect(() => {
    const getData = async () => {
      try {
        await getOperations();
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };
    getData();
  }, [getOperations, offset]);

  return (
    <LoadingOverlay active={loading} spinner text="">
      <OperationsTable
        operations={operations}
        setSelectedFlights={setSelectedFlights}
        getOperations={getOperations}
        pilotNames={pilotNames}
        platformCallsigns={platformCallsigns}
        trackerNames={trackerNames}
      />
      <OperationsHeader
        selectedFlights={selectedFlights}
        history={history}
        getOperations={getOperations}
        setLoading={setLoading}
        handleMoreLogsClick={handleMoreLogsClick}
      />
    </LoadingOverlay>
  );
}

export default FlightHistoryComponent;
