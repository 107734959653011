import { useEffect, useState } from "react";

import { IoMdHelp } from "react-icons/io";
import LatLng from "@/model/LatLng";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import ShipOperationForm from "./ShipOperationForm";
import PortOperationForm from "./PortOperationForm";

interface DronePortsOperationSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  setSelectedWaypoints?: (waypoints: number[][]) => void;
}

export default function DronePortsOperationSidebar({
  api,
  onConfirmation,
  showMessage,
  goToLocation,
  setSelectedWaypoints,
}: DronePortsOperationSidebarProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const [pilots, setPilots] = useState<Pilot[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  const handleSelectedTab = (tabIndex: number) => {
    setSelectedTab(tabIndex);
  };

  const fetchPilots = async () => {
    const response = await api.getPilots();
    if (response.error) {
      showMessage && showMessage("Unable to Retrive Pilots Data", false, true);
    } else {
      setPilots(response.data);
    }
  };

  const fetchPlatforms = async () => {
    const response = await api.getPlatforms();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Platforms Data", false, true);
    } else {
      setPlatforms(response.data);
    }
  };

  const fetchTrackers = async () => {
    const response = await api.getTracker();
    if (response.error) {
      showMessage &&
        showMessage("Unable to Retrive Trackers Data", false, true);
    } else {
      setTrackers(response.data);
    }
  };

  useEffect(() => {
    fetchPilots();
    fetchPlatforms();
    fetchTrackers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="absolute top-0 bottom-0 w-full">
      <div className="flex-col h-[90%] overflow-auto">
        <div className="h-[100%] flex-col">
          {/* Header */}
          <div className="flex p-4">
            <h4>Drone Ports Operation</h4>
            <span className="grow" />
            <div className="w-6 h-6 bg-blue-200 rounded-lg flex">
              <IoMdHelp className="fill-blue-500 m-auto" size={16} />
            </div>
          </div>
          <hr />
          <div className="flex-col p-4">
            {/* Main Tab */}
            <div className="tab-rounded">
              <span
                className={`${selectedTab === 0 && "tab-active"} mr-1`}
                onClick={() => handleSelectedTab(0)}
              >
                Ship Delivery
              </span>
              <span
                className={`${selectedTab === 1 && "tab-active"} ml-1`}
                onClick={() => handleSelectedTab(1)}
              >
                Drone Port
              </span>
            </div>
          </div>
          {/* Forms Content */}
          <ShipOperationForm
            api={api}
            className={`${selectedTab === 0 ? "block" : "hidden"}`}
            pilots={pilots}
            platforms={platforms}
            trackers={trackers}
            onConfirmation={onConfirmation}
            showMessage={showMessage}
            goToLocation={goToLocation}
            setSelectedWaypoints={setSelectedWaypoints}
          />
          <PortOperationForm
            api={api}
            className={`${selectedTab === 1 ? "block" : "hidden"}`}
            pilots={pilots}
            platforms={platforms}
            trackers={trackers}
            onConfirmation={onConfirmation}
            showMessage={showMessage}
            goToLocation={goToLocation}
          />
        </div>
      </div>
    </div>
  );
}
