import { Content } from "@/model/api/Permit";

interface PermitItemProps {
  data: Content;
  onClick: (data: Content) => void;
}

export default function PermitItem({ data, onClick }: PermitItemProps) {
  const extractFileName = (filePath: string) => {
    const parts = filePath.split("/");
    const lastPart = parts[parts.length - 1];

    const fileName = lastPart.replace(
      / \d{4}-\d{2}-\d{2} \d{4}-\d{2}-\d{2}/,
      ""
    );

    return fileName;
  };

  return (
    <div
      className="flex-col p-4 rounded-md border-2 border-gray-200 mb-2 cursor-pointer"
      onClick={() => onClick(data)}
    >
      <h5 className="font-medium">{extractFileName(data.Key)}</h5>
    </div>
  );
}
