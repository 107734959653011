import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  makeStyles,
  useTheme,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getWSService } from "../../../services/websocket";
import { useApi } from "../../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../../store/actions";
import DetailsOperationsDialog from "../../../components/UpcomingOperationsDetailsDialog";
import DetailsTrackers from "../../../components/UpcomingOperationsDetailsDialog/DetailsTrackers";
import DetailsPaltforms from "../../../components/UpcomingOperationsDetailsDialog/DetailsPaltforms";
import DetailsPilots from "../../../components/UpcomingOperationsDetailsDialog/DetailsPilots";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPage className={classes.icon} />
        ) : (
          <FirstPage className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPage className={classes.icon} />
        ) : (
          <LastPage className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStylesUpOps = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 150,
    width: 1000,
    // height: 20,
  },

  tableHead: {
    minWidth: 1000,
    minHeight: 53,
    position: "sticky",
    top: 0,
    backgroundColor: "#fafafa",
  },
  tableRow: {
    height: 53,
    width: 200,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 10,
    width: 1000,
  },
  tableCell: {
    color: "black",
    wordWrap: "break-word",
    maxWidth: 1000,
    minWidth: 150,
  },
  pagination: {
    color: "black",
    background: "white",
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
  tabelFooter: {
    position: "sticky",
    bottom: 0,
  },
}));
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));
function OperationsTable(props) {
  const classes = useTableStylesUpOps();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  const [selectedDialogData, setSelectedDialogData] = React.useState([]);
  const rowsPerPage = 10;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.operations.length - page * rowsPerPage);

  const {
    operations,
    setEditable,
    setLoading,
    refreshOperations,
    handleOperatorProposeOperation,
    setTwoWayOperationsRefObj,
  } = props;

  React.useEffect(() => {
    if (operations.length > 0) {
      setLoading();
      return null;
    }
    const interval = setTimeout(() => {
      setLoading();
    }, 1000 * 20);
    return () => {
      clearInterval(interval);
    };
  }, []);

  operations.sort(compare);
  operations.reverse();
  // Sort operations by time created
  function compare(a, b) {
    if (a.reference.time_created.value < b.reference.time_created.value) {
      return -1;
    }
    if (a.reference.time_created.value > b.reference.time_created.value) {
      return 1;
    }
    return 0;
  }
  operations.sort(compare);
  operations.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = operations.map((n) => n.reference.id);
      setSelected(operations);
      if (props.setSelectedFlights) props.setSelectedFlights(newSelecteds);
    } else {
      setSelected([]);
      if (props.setSelectedFlights) props.setSelectedFlights([]);
    }
    setEditable(false);
  };

  const isSelected = (name) =>
    selected.filter((op) => op.reference.id === name).length === 1;

  const handleClick = (event, op) => {
    const name = op.reference.id;
    event.stopPropagation();
    let newSelected = [];

    if (isSelected(name)) {
      newSelected = selected.filter((s) => s.reference.id !== name);
    } else {
      newSelected = newSelected.concat(selected, op);
    }

    setSelected(newSelected);
    if (props.setSelectedFlights) {
      props.setSelectedFlights(newSelected.map((n) => n.reference.id));
    }
    if (
      newSelected.length === 1 &&
      (newSelected[0].details.state === "Accepted" ||
        newSelected[0].details.state === "Rejected" ||
        newSelected[0].details.state === "Proposed")
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  };

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  const handlePopUpClick = (event, rowData) => {
    setSelectedDialogData(rowData);
    setOpenDetailsDialog(true);
  };
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };

  useEffect(() => {
    if (!operations) return;
    const twoWayOperationsRefObj = {};
    operations.forEach((singleOperation) => {
      twoWayOperationsRefObj[singleOperation.reference.id] =
        singleOperation.reference.mission_list;
    });
    setTwoWayOperationsRefObj(twoWayOperationsRefObj);
  }, [operations]);
  return (
    <div>
      {/* <TableContainer sx={{ maxHeight: 440 }}> */}
      <Table className={classes.table} aria-label="a dense table">
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={
                  operations.length > 0 && selected.length === operations.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>Operation</TableCell>
            <TableCell className={classes.tableCell} align="center">
              Departure
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Arrival
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Pilot
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Platform
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Tracker
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Status
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Operation ID
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Created On
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.reference.id);
              return (
                <Tooltip title="Click for details">
                  <TableRow
                    className={classes.tableRow}
                    key={row.reference.id}
                    onClick={(event) => handlePopUpClick(event, row)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => {
                          handleClick(event, row);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {`${row.reference.description}`}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate(
                        row.interuss.operational_intent_reference.time_start
                          .value
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate(
                        row.interuss.operational_intent_reference.time_end.value
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsPilots pilot_uuid={row.request.pilot_uuid} />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsPaltforms
                        platform_uuid={row.request.platform_uuid}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsTrackers
                        tracker_uuid={row.request.tracker_uuid}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.details.state}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.reference.id}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate(row.reference.time_created.value)}
                    </TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter className={classes.tabelFooter}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              count={operations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DetailsOperationsDialog
        handleCloseDetailsDialog={handleCloseDetailsDialog}
        openDetailsDialog={openDetailsDialog}
        selectedDialogData={selectedDialogData}
        refreshOperations={refreshOperations}
        handleOperatorProposeOperation={handleOperatorProposeOperation}
      />
      {/* </TableContainer> */}
    </div>
  );
}
function UpcomingOperationsHeader({
  selectedFlights,
  history,
  setTabValue,
  isEditable,
  setLoading,
  handleOpenFlightAuthorization,
  twoWayOperationsRefObj,
  refreshOperations,
}) {
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [displayButtonLoading, setDisplayButtonLoading] = useState(false);

  const api = useApi();
  const dispatch = useDispatch();
  const classesFloating = useFloatingButton();

  const mapboxController = useSelector((state) => state.maps.mapboxController);

  const handleCancelOpsClick = async () => {
    // let data;
    // await selectedFlights.forEach((selectedFlight) => {
    //   data = api.deleteOperation(selectedFlight);
    // });
    setDeleteButtonLoading(true);
    const promises = [];
    try {
      // deletedFlight array to prevent duplicate calling api if both
      // two way flight in selectedFlight
      const deletedFlight = [];
      selectedFlights.map(async (selectedFlight) => {
        // delete both of two way operation
        if (twoWayOperationsRefObj[selectedFlight]) {
          twoWayOperationsRefObj[selectedFlight].forEach(
            async (individualTwoWayOperationUuid) => {
              if (!deletedFlight.includes(individualTwoWayOperationUuid)) {
                deletedFlight.push(individualTwoWayOperationUuid);
                promises.push(
                  api.deleteOperation(individualTwoWayOperationUuid)
                );
              }
            }
          );
        } else {
          promises.push(api.deleteOperation(selectedFlight));
        }
      });
    } catch (error) {
      // Handle any errors that might occur during the API requests.
      setTabValue(0);
      handleOpenFlightAuthorization();
      setDeleteButtonLoading(false);
    }

    try {
      const response = await Promise.all(promises);
      const responseStatusOk = response.every(
        (singleResponse) => singleResponse.status === 200
      );

      if (responseStatusOk) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message:
              response.length > 1 ? "Operations Deleted" : "Operation Deleted",
            severity: "success",
          })
        );
        setTabValue(0);
        handleOpenFlightAuthorization();
      } else {
        dispatch(
          setSnackbarMessage({
            open: true,
            message:
              "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
            severity: "Error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbarMessage({
          open: true,
          // using e.message might show code errors instead of just api errors
          message: "Error",
          severity: "Error",
        })
      );
      refreshOperations();
      setDeleteButtonLoading(false);
    }
    setDeleteButtonLoading(false);
    // window.location.reload();
  };

  const handleFlyOperationClick = async () => {
    // await selectedFlights.forEach(api.addOperationSelection);
    // flightData.map((operation) => {
    //   puckServer.postMission(
    //     operation.data.operation_json,
    //     operation.data.operation_json.details.puck_uuid
    //   );
    // });
    setDisplayButtonLoading(true);
    try {
      const promises = selectedFlights.map(async (selectedFlight) => {
        await api.addOperationSelection(selectedFlight);
      });

      const data = await Promise.all(promises);
    } catch (error) {
      // Handle any errors that might occur during the API requests.
      console.error("Error:", error);
    }
    setTabValue(0);
    handleOpenFlightAuthorization();
    setDisplayButtonLoading(false);
  };

  const handleEditOperationclick = async () => {
    if (mapboxController) mapboxController.removeDrawFeaturesAll();
    const response = await api.getOperationData({
      operationID: selectedFlights[0],
    });
    // const anchorageValues = ["subsection", "corridor", "outbound"];
    // if (
    //   anchorageValues.some(
    //     (singleKey) => singleKey in response.data.operation_json.request
    //   )
    if (response?.data?.operation_json?.request?.corridor) {
      dispatch(
        setSnackbarMessage({
          message: "Drone Port Operation functions still in development",
          error: "error",
        })
      );
    } else {
      const request = response.data.request_json;
      if (request.two_way) {
        request.description =
          response.data.operation_json.reference.description;
      }
      dispatch(
        setOperationFormRequest({
          id: selectedFlights[0],
          type: "edit",
          request,
        })
      );
      setTabValue(0);
    }
  };

  return (
    <div className={classesFloating.buttonFloating}>
      <Grid
        container
        spacing={0}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
        className={classesFloating.buttonFloating}
      >
        <Grid item xs={3}>
          <LoadingOverlay active={deleteButtonLoading} spinner text="">
            <Button
              variant="contained"
              fullWidth
              disabled={!selectedFlights.length}
              style={{ fontSize: "12px", fontWeight: "bold" }}
              color="secondary"
              onClick={handleCancelOpsClick}
            >
              Delete Ops
            </Button>
          </LoadingOverlay>
        </Grid>
        <Grid item xs={1} sm={3} sx={{ color: "black" }}>
          {" "}
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            disabled={!isEditable}
            onClick={handleEditOperationclick}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            color="primary"
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={3}>
          <LoadingOverlay active={displayButtonLoading} spinner text="">
            <Button
              variant="contained"
              fullWidth
              disabled={!selectedFlights.length}
              onClick={handleFlyOperationClick}
              style={{ fontSize: "12px", fontWeight: "bold" }}
              color="primary"
            >
              Display Ops
            </Button>
          </LoadingOverlay>
        </Grid>
      </Grid>
    </div>
  );
}

function UpcomingOperationsComponent({
  setTabValue,
  history,
  setOperationRequestToEdit,
  handleOpenFlightAuthorization,
}) {
  const [loading, setLoading] = React.useState(true);
  const [upcomingOperations, setUpcomingOperations] = React.useState([]);
  // const [platformData, setPlatformData] = React.useState([]);

  const [selectedFlights, setSelectedFlights] = React.useState([]);
  const [isEditable, setEditable] = React.useState(false);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const [twoWayOperationsRefObj, setTwoWayOperationsRefObj] = React.useState(
    {}
  );
  const api = useApi();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getData();
    // enableSocketConnection();
  }, []);

  const getData = async () => {
    const promises = [];
    promises.push(getOperations());
    // promises.push(getPlatformData())

    try {
      await Promise.all(promises);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    setLoading(false);
  };
  const Loading = async () => {
    setLoading(false);
  };

  const getOperations = async () => {
    const response = await api.getOperations();
    if (!response.data) return;
    const currentTime = new Date();
    const temp = [];
    response.data.forEach((flightData) => {
      const flightEnd = new Date(
        flightData.operation_json.reference.time_end.value
      );
      if (flightEnd >= currentTime) {
        temp.push(flightData.operation_json);
      }
    });
    setUpcomingOperations(temp);
  };

  const handleOperatorProposeOperation = async (operationID) => {
    const response = await api.getOperationData({
      operationID,
    });
    dispatch(
      setOperationFormRequest({
        id: operationID,
        type: "edit",
        request: response.data.request_json,
      })
    );
    setTabValue(0);
  };
  return (
    <div>
      <LoadingOverlay active={loading} spinner text="">
        {upcomingOperations.length > 0 && (
          <Paper
            style={{
              height: `${400}px`,
              maxHeight: "calc(50% - 0px)",
              width: "100%",
              overflow: "auto",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <OperationsTable
              operations={upcomingOperations}
              setSelectedFlights={setSelectedFlights}
              setEditable={setEditable}
              setLoading={Loading}
              refreshOperations={getData}
              handleOperatorProposeOperation={handleOperatorProposeOperation}
              setTwoWayOperationsRefObj={setTwoWayOperationsRefObj}
            />
          </Paper>
        )}
        <UpcomingOperationsHeader
          selectedFlights={selectedFlights}
          history={history}
          setTabValue={setTabValue}
          isEditable={isEditable}
          setLoading={setLoading}
          handleOpenFlightAuthorization={handleOpenFlightAuthorization}
          twoWayOperationsRefObj={twoWayOperationsRefObj}
          refreshOperations={getData}
        />
        {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
      </LoadingOverlay>
    </div>
  );
}

export default UpcomingOperationsComponent;
