import React, { useEffect, useState } from "react";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import { getFutureDate } from "@/utils/date_utils";
import recurringConstraintMessageHandler from "./recurringConstraintMessageHandler";

type RecurrenceType = "daily" | "weekly" | "monthly";

interface ReccuringConstraintFormProps {
  handleRecurringCancelClick: () => void;
  handleRecurringConfirmClick: (
    recurrenceType: string,
    startDate: Date,
    endDate: Date,
    recurrenceDescription: string
  ) => void;
}

export default function ReccuringConstraintForm({
  handleRecurringCancelClick,
  handleRecurringConfirmClick,
}: ReccuringConstraintFormProps) {
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>("daily");
  const [startDate, setStartDate] = useState<Date | null>(getFutureDate(3));
  const [endDate, setEndDate] = useState<Date | null>(getFutureDate(60));
  const [recurrenceDescription, setRecurrenceDescription] = useState("");

  const handleDateChange = (date: Date | null, type: string) => {
    if (type === "start") {
      setStartDate(date);
    }
    if (type === "end") {
      setEndDate(date);
    }
  };

  const handleConfirmClick = () => {
    if (!startDate || !endDate) return;
    handleRecurringConfirmClick(
      recurrenceType,
      startDate,
      endDate,
      recurrenceDescription
    );
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    const message =
      recurringConstraintMessageHandler(startDate, endDate, recurrenceType) ||
      "";
    setRecurrenceDescription(message);
  }, [startDate, endDate, recurrenceType]);

  return (
    <div className="h-full flex-col px-4 z-[999]">
      <h5 className="font-medium mb-2 mt-4">Recurrence Detail</h5>

      <ul className="rounded-radio-container mb-2 z-[999]">
        <li
          className="w-full border-r dark:border-gray-600 cursor-pointer"
          onClick={() => setRecurrenceType("daily")}
        >
          <div className="flex items-center ps-3">
            <span className="flex items-center pointer-events-none">
              <input
                type="radio"
                value=""
                name="recurring-date"
                className="input-radio"
                disabled
                checked={recurrenceType === "daily"}
              />
              <label className="label user-select-none">Daily</label>
            </span>
          </div>
        </li>
        <li
          className="w-full cursor-pointer"
          onClick={() => setRecurrenceType("weekly")}
        >
          <div className="flex items-center ps-3">
            <span className="flex items-center pointer-events-none">
              <input
                type="radio"
                value=""
                name="recurring-date"
                className="input-radio"
                disabled
                checked={recurrenceType === "weekly"}
              />
              <label className="label user-select-none">Weekly</label>
            </span>
          </div>
        </li>
        <li
          className="w-full cursor-pointer"
          onClick={() => setRecurrenceType("monthly")}
        >
          <div className="flex items-center ps-3">
            <span className="flex items-center pointer-events-none">
              <input
                type="radio"
                value=""
                name="recurring-date"
                className="input-radio"
                disabled
                checked={recurrenceType === "monthly"}
              />
              <label className="label user-select-none">Monthly</label>
            </span>
          </div>
        </li>
      </ul>
      {recurrenceType === "daily" ? (
        <div className="flex mb-4 flex-col z-[99999]">
          <div className="w-full mr-2 z-[99999]">
            <RoundedDatePicker
              label="Start Date Time"
              value={startDate}
              className="mr-2"
              format="hh:mm aa"
              onChange={(date) => handleDateChange(date, "start")}
            />
          </div>
          <div className="w-full mr-2 mt-3">
            <RoundedDatePicker
              label="End Date Time"
              value={endDate}
              format="hh:mm aa"
              onChange={(date) => handleDateChange(date, "end")}
            />
          </div>
        </div>
      ) : (
        <div className="flex mb-4 flex-col z-[99999]">
          <div className="w-full mr-2 z-[99999]">
            <RoundedDatePicker
              label="Start Date Time"
              value={startDate}
              className="mr-2"
              onChange={(date) => handleDateChange(date, "start")}
            />
          </div>
          <div className="w-full mr-2 mt-3">
            <RoundedDatePicker
              label="End Date Time"
              value={endDate}
              onChange={(date) => handleDateChange(date, "end")}
            />
          </div>
        </div>
      )}
      <div className="w-full m-3">{recurrenceDescription}</div>
      <div className="flex border-t border-gray-200">
        <button
          onClick={() => handleConfirmClick()}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Confirm
        </button>
        <button
          onClick={() => handleRecurringCancelClick()}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
