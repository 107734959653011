import { SET_MAPBOX_CONTROLLER, SET_TRACKING_MAP_VIEW } from "./maps";
import {
  SET_FOCUSSED_OPERATION,
  SET_OPERATION_FORM_REQUEST,
  SET_STATE_CONFORMANCE,
} from "./operations";
import { SET_USER_ACCESS } from "./userAccess";
import { INIT_ENV_VAR } from "./envVar";

export const SET_AUTH = "SET_AUTH";
export const setSubscriptionStatus = (status) => ({
  type: "SET_SUBSCRIPTION_STATUS",
  status,
});

export const setSnackbarMessage = ({ open = true, message, severity }) => ({
  type: "SET_SNACKBAR_MESSAGE",
  open,
  message,
  severity,
});

export const setShipData = (data) => ({
  type: "SET_SHIP_DATA",
  data,
});

export const getShipData = (data) => ({
  type: "GET_SHIP_DATA",
  data,
});

export const setairMapSpaceData = (data) => ({
  type: "SET_AIRMAP_DATA",
  data,
});

export const getairMapSpaceData = (data) => ({
  type: "GET_AIRMAP_DATA",
  data,
});

export const setConstraintsData = (data) => ({
  type: "SET_CONSTRAINTS_DATA",
  data,
});

export const setADSBdataPaid = (data) => ({
  type: "SET_ADSB_DATA_PAID",
  data,
});

export const getADSBDataPaid = (data) => ({
  type: "GET_ADSB_DATA_PAID",
  data,
});

export const setADSBdataFree = (data) => ({
  type: "SET_ADSB_DATA_FREE",
  data,
});

export const getADSBDataFree = (data) => ({
  type: "GET_ADSB_DATA_FREE",
  data,
});

export const setTrackingMapView = (trackingMapView) => ({
  type: SET_TRACKING_MAP_VIEW,
  data: {
    trackingMapView,
  },
});

export const setMapboxController = (mapboxController) => ({
  type: SET_MAPBOX_CONTROLLER,
  data: {
    mapboxController,
  },
});

export const setFocussedOperation = (focussedOperation) => ({
  type: SET_FOCUSSED_OPERATION,
  data: {
    focussedOperation,
  },
});

export const setOperationFormRequest = (operationFormRequest) => ({
  type: SET_OPERATION_FORM_REQUEST,
  data: {
    operationFormRequest,
  },
});

export const setEnvVar = (envVar) => ({
  type: INIT_ENV_VAR,
  data: {
    ...envVar,
  },
});

export const setStateConformance = (conformanceData) => ({
  type: SET_STATE_CONFORMANCE,
  data: conformanceData,
});
export const SubscriptionStatus = {
  NONE: "None",
  SELECTING: "Selecting",
  CREATING: "Creating",
  ACTIVE: "Active",
  DELETING: "Deleting",
};

export const setAuth = (auth) => ({
  type: SET_AUTH,
  data: auth,
});

export const setUserAccess = (userAccess) => ({
  type: SET_USER_ACCESS,
  data: userAccess,
});

export const setEditConstraint = (data) => ({
  type: "EDIT_CONSTRAINT",
  data,
});
