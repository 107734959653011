import { useState, useEffect } from "react";
import { Resizable, ResizableBox } from "react-resizable";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import Drawer from "@material-ui/core/Drawer";
import { CircularProgress, Drawer } from "@mui/material";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {
  Message,
  Notifications,
  ExitToApp,
  ExpandMore,
  Minimize,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import OverlayManager from "../../../components/OverlayManager";
import WeatherCurrent from "../../../components/Weather/weatherCurrentView";
import WeatherForecast from "../../../components/Weather/weatherForecastView";
import { getWSService } from "../../../services/websocket";
import OperationsControl from "../OperationsControl";
import "./resizeable.css";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
    background: "transparent",
    border: 0,
    maxHeight: "calc(29% - 208px)",
  },
  drawerPaperLeft: {
    width: 500,
    zIndex: 1,
    background: "transparent",
    border: 0,
    top: 450,
    // paddingBottom: "100%",
    float: "left",
  },
  drawerPaperRight: {
    width: 500,
    height: 300,
    zIndex: 1,
    background: "transparent",
    border: 0,
    top: 450,
  },

  drawerContainer: {
    overflow: "auto",
  },
  drawerContainerLeft: {
    overflow: "auto",
    // maxWidth: "100%",
    paddingTop: 0,
    margin: "0 auto",
  },

  drawerContainerLeftContain: {
    position: "fixed",
    bottom: 0,
    left: 0,
    minWidth: "700px",
    maxWidth: "1000px",
    // width: "55%",
    // rigth: 0,
    float: "left",
  },
  drawerContainerLeftPosition: {
    border: 1,
    position: "relative",
    margin: "0 5px",
    width: "75%",
  },
  drawerContainerRightContain: {
    position: "fixed",
    bottom: 0,
    // left: 750,
    rigth: 0,
    float: "right",
  },
  drawerContainerRightPosition: {
    border: 1,
    position: "relative",
    margin: "0 5px",
    left: "-4px",
  },
  drawerContainerRightContainUp: {
    position: "fixed",
    top: "68px",
    rigth: 0,
    float: "right",
  },
  drawerContainerRightPositionUp: {
    border: 1,
    position: "relative",
    margin: "0 5px",
    left: "-4px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  contentTextCenter: {
    justifyContent: "center",
  },
  accordionHeader: {
    height: "300px",
  },
  accordionSumary: {
    height: "300px",
  },
  root: {
    minHeight: 15,
    maxHeight: 15,
    backgroundColor: "#a5a5a5",
    "&.Mui-expanded": {
      minHeight: 15,
      maxHeight: 15,
      backgroundColor: "#a5a5a5",
    },
  },
  accordionRoot: {
    height: "200px",
  },
  table: {
    border: 0,
    minWidth: 300,
  },
  tableRow: {
    height: 20,
  },
  tableCell: {
    fontSize: "10px",
    fontWeight: "bold",
  },
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingTop: "3px",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    borderBottom: "none",
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});
const summaryStyles = {
  root: {
    minHeight: 5 * 4,
    "&$expanded": {
      minHeight: 5 * 4,
    },
  },
  content: {
    margin: "1px 0",
    "&$expanded": {
      margin: "3px 0",
    },
    justifyContent: "center",
  },
  expandIcon: {
    padding: 1,
  },
  expanded: {},
};

const detailsStyles = {
  root: {
    minHeight: 5 * 4,
    "&$expanded": {
      minHeight: 12 * 4,
    },
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 5,
    paddingLeft: 5,
  },
};
const CompactAccordionSummary = withStyles(summaryStyles)(AccordionSummary);
const CompactAccordionDetails = withStyles(detailsStyles)(AccordionDetails);
export default function TasksComponent({
  auth,
  setConstraintsData,
  selectedFlightData,
  telemetryData,
  selectedPlatformData,
  onRemoveOperation,
  onUpdateOperationState,
  isDrawerOpen = false,
  toggleShowShipLayer,
  toggleShowAdsbLayer,
  toggleShowConstraintsLayer,
  toggleShowStaticConstraintsLayer,
  role,
  OperationsSummary,
  toggleShowBuildingLayer,
  toggleShowOperationVolumeLayer,
  togglesshowDroneIconLayer,
  toggleShowRainMapLayer,
  GroundSummary,
  AirborneSummary,
  togglesshowAirMapSpace,
  flightDataIsLoading,
  togglesshowGnssLayer,
  toggleShowAnchorageLayer,
  toggleShowDronePortLayer,
  toggleSgBoundaryLayer,
  toggleSgPopulationDensity,
  isRemoteId,
}) {
  const classes = useStyles();

  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);

  const handleMessage = (message) => {
    setWebsocketMessage(message);
  };

  const handleRemoveOperationClick = () => {
    onRemoveOperation();
  };

  const handleVisabilityClick = (visability, operationID) => {
    //     let operations = JSON.parse(JSON.stringify(selectedFlightData));
    //     const index = operations.findIndex((x) => x.reference.id === operationID);
    //     if (index > -1) {
    //       operations[index].visable = visability;
    //       setSelectedFlightData(operations);
    //     }
  };

  const handleUpdateOperation = (newOperationData) => {
    onUpdateOperationState();
    // let operations = JSON.parse(JSON.stringify(selectedFlightData));
    // console.log(operations);
    // const index = operations.findIndex(
    //   (x) => x.reference.id === newOperationData.reference.id
    // );
    // if (index > -1) {
    //   operations[index] = newOperationData;
    //   setSelectedFlightData(operations);
    // }
  };
  const [expanded, setExpanded] = useState();
  const [resizeWidth, setResizeWidth] = useState(200);
  const [resizeHeight, setResizeHeight] = useState(300);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    handleChange();
  }, [expanded]);
  const onResize = (event, { element, size }) => {
    setResizeWidth(size.width);
    setResizeHeight(size.height);
  };

  return (
    <div>
      <Drawer
        open={isDrawerOpen}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        borderright={1}
        anchor="right"
      >
        {/* <Paper style={{ background: "transparent", zIndex: 1, elevation: 8 }}> */}
        <Toolbar />
        <div className={classes.drawerContainerRightContainUp}>
          <div className={classes.drawerContainerRightPositionUp}>
            <Accordion defaultExpanded>
              <CompactAccordionSummary
                expandIcon={
                  expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                }
                id="panel1-header"
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  Operations Management
                </Typography>
              </CompactAccordionSummary>
              <CompactAccordionDetails>
                <Resizable
                  className="box"
                  height={resizeHeight}
                  width={resizeWidth}
                  onResize={onResize}
                >
                  <div
                    className="box"
                    style={{
                      width: "480px",
                    }}
                    id="opsManagement"
                  >
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Operations Summary
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Ground Summary
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Airborne Summary
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow>
                            <StyledTableCell
                              align="center"
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              {OperationsSummary}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              {GroundSummary}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{ fontSize: "10px", fontWeight: "bold" }}
                            >
                              {AirborneSummary}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Paper
                      style={{
                        height: `${resizeHeight}px`,
                        // maxHeight: "calc(90% - 18px)",
                        overflow: "auto",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      {flightDataIsLoading ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          // minHeight="100vh"
                          sx={{ p: 2 }}
                        >
                          <CircularProgress style={{ color: "darkgrey" }} />
                        </Box>
                      ) : (
                        <OperationsControl
                          operationsData={selectedFlightData}
                          telemetryData={telemetryData}
                          onRemoveClick={handleRemoveOperationClick}
                          selectedPlatformData={selectedPlatformData}
                          onUpdateOperationState={onUpdateOperationState}
                          onVisabilityClick={handleVisabilityClick}
                          role={role}
                        />
                      )}
                    </Paper>
                  </div>
                </Resizable>
              </CompactAccordionDetails>
            </Accordion>
          </div>
        </div>
        {/* </Paper> */}

        <div className={classes.drawerContainerLeftContain}>
          <div className={classes.drawerContainerLeftPosition}>
            <Accordion defaultExpanded>
              <CompactAccordionSummary
                expandIcon={
                  expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                }
                id="panel2-header"
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  Weather
                </Typography>
              </CompactAccordionSummary>
              <CompactAccordionDetails>
                {process.env.REACT_APP_USE_OPENWEATHER_API.toUpperCase() ===
                "TRUE" ? (
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <WeatherForecast />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={0}>
                    <Grid item xs={2}>
                      <WeatherCurrent />
                    </Grid>
                    <Grid item xs={10}>
                      <WeatherForecast />
                    </Grid>
                  </Grid>
                )}
              </CompactAccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className={classes.drawerContainerRightContain}>
          <div className={classes.drawerContainerRightPosition}>
            <Accordion defaultExpanded>
              <CompactAccordionSummary
                expandIcon={
                  expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                }
                id="panel3-header"
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  Overlay Manager
                </Typography>
              </CompactAccordionSummary>
              <CompactAccordionDetails>
                <Paper>
                  <div style={{ padding: "5px" }}>
                    <OverlayManager
                      toggleShowShipLayer={toggleShowShipLayer}
                      toggleShowAdsbLayer={toggleShowAdsbLayer}
                      toggleShowConstraintsLayer={toggleShowConstraintsLayer}
                      toggleShowStaticConstraintsLayer={
                        toggleShowStaticConstraintsLayer
                      }
                      toggleShowBuildingLayer={toggleShowBuildingLayer}
                      toggleShowOperationVolumeLayer={
                        toggleShowOperationVolumeLayer
                      }
                      togglesshowDroneIconLayer={togglesshowDroneIconLayer}
                      toggleShowRainMapLayer={toggleShowRainMapLayer}
                      togglesshowAirMapSpace={togglesshowAirMapSpace}
                      togglesshowGnssLayer={togglesshowGnssLayer}
                      toggleShowAnchorageLayer={toggleShowAnchorageLayer}
                      toggleShowDronePortLayer={toggleShowDronePortLayer}
                      toggleSgBoundaryLayer={toggleSgBoundaryLayer}
                      toggleSgPopulationDensity={toggleSgPopulationDensity}
                      isRemoteId={isRemoteId}
                    />
                  </div>
                </Paper>
              </CompactAccordionDetails>
            </Accordion>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
