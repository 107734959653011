import React from "react";
import { makeStyles } from "@material-ui/core";
import { useApi } from "../../api/useApi";

const divDetails = makeStyles((theme) => ({
  multiDetails: {
    paddingTop: 1,
    paddingBottom: 2,
    margin: 1,
  },
}));
export default function DetailsTrackers(props) {
  const [selectedData, setSelectedData] = React.useState([]);
  const classesDetails = divDetails();
  const api = useApi();
  const trackerUuid = props.tracker_uuid;
  const getData = async () =>
    Promise.all(
      trackerUuid.map(async (uuid, index) => {
        if (!uuid) return undefined;
        const trackerData = await api.getTrackerScan(uuid);
        return trackerData;
      })
    );

  React.useEffect(() => {
    getData().then(function (result) {
      setSelectedData(result);
    });
  }, []);
  return (
    <div>
      {selectedData.length > 0 &&
        selectedData.map((trackerData) => {
          return (
            <p
              key={trackerData.data.tracker_uuid.toString()}
              className={classesDetails.multiDetails}
            >
              {trackerData.data.tracker_name
                ? trackerData.data.tracker_name
                : "-"}
            </p>
          );
        })}
    </div>
  );
}
