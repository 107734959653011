import React, { useState } from "react";

interface TooltipProps {
  title: string;
  children: React.ReactNode;
  marginL?: string;
  marginT?: string;
}

export default function Tooltip({
  title,
  children,
  marginL = "ml-[70px]",
  marginT = "mt-[15px]",
}: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="relative">
      <span
        className={`absolute tooltip opacity-0 w-fit whitespace-nowrap inline-block z-[99] ${marginL} ${marginT} ${
          showTooltip && `opacity-100 ease-in-out duration-500`
        }`}
      >
        {title}
      </span>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </div>
  );
}
