import React from "react";
import Button from "@/components/v1/Common/Button";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import { getFutureDate } from "@/utils/date_utils";
import { FaClipboardList } from "react-icons/fa";

interface CreateIncidentReportFormProps {
  className?: string;
  showMessage(message: string, isSuccess?: boolean, isError?: boolean): void;
}

export default function CreateIncidentReportForm({
  className,
  showMessage,
}: CreateIncidentReportFormProps) {
  const reportDate = getFutureDate(1);

  const handleDateChange = (date: Date | null, type: string) => {
    console.log(date, type);
  };

  return (
    <div className={`h-full flex-col px-4 ${className}`}>
      <div className="w-full mt-4 mb-2">
        <div className="flex mb-2 items-center">
          <FaClipboardList size={14} color="gray" />
          <h5 className="ms-2 font-medium grow">Operation</h5>
          <div
            className="flex items-center px-2 cursor-pointer"
            // onClick={handleOnDraw}
          >
            <button
              className="ml-1 text-blue-500 text-sm font-medium"
              onClick={() => showMessage("Coming Soon")}
            >
              Select Operation
            </button>
          </div>
        </div>
        <input
          type="text"
          className="input-text"
          disabled
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Title</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Title of your report</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Reported By</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Reporter&#39;s Name</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Email</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Reporter&#39;s Email</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <RoundedDatePicker
          label="Report Date"
          value={reportDate}
          className="mr-2"
          onChange={(date) => handleDateChange(date, "departure")}
        />
        <p className="text-gray-500 text-sm mt-1">Reported Date</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <RoundedDatePicker
          label="Incident Date Time"
          value={reportDate}
          className="mr-2"
          onChange={(date) => handleDateChange(date, "departure")}
        />
        <p className="text-gray-500 text-sm mt-1">Incident Date</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">AOC Reference</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Attestation of Compliance (AOC) certifying your company’s PCI DSS
          compliance.
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Pilot Name</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Name of Drone Pilot</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Flight Number</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Flight Number or Operation ID
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Aircraft Registration</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Drone Registration Number</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Aircraft Manufacturer</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Drone Manufacturer (DJI, Intel, Yunneec)
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Aircraft Model</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Drone Model Number (DJI M300, DJI Mini, Swoop)
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Last Departure Point</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">Country of Depature Point</p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Planned Destination Point</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Country of Destination Point
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Flight Phase</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Indicate the phase of flight (During Flight, Landing, Takeoff)
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">State Reporting</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          State you are reporting from
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">State Occurrence</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          State where incident occured
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">
          Narrative (Description of Accident/Incident)
        </label>
        <textarea
          className="input-text"
          //   defaultValue={form.description}
          //   onChange={handleTextAreaChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Description of Accident/Incident
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>
      <div className="w-full mb-2">
        <label className="label">Potential Hazard(s) Identified</label>
        <input
          type="text"
          className="input-text"
          //   defaultValue={form.elevation}
          //   onChange={handleTextChange}
        />
        <p className="text-gray-500 text-sm mt-1">
          Potential Hazard(s) Identified (if any)
        </p>
        {/* {formError.elevation && (
          <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
        )} */}
      </div>

      <div className="flex mt-4 mb-8 pb-8">
        <Button
          type="light"
          size="medium"
          text="Reset"
          className="mr-2"
          //   disabled={isLoading}
          onClick={() => showMessage("Coming Soon")}
        />

        <Button
          type="primaryDark"
          size="medium"
          text="Submit"
          //   isLoading={isLoading}
          onClick={() => showMessage("Coming Soon")}
        />
      </div>
    </div>
  );
}
