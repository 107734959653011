import * as React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Grid, Button, Table, useTheme, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";
import DetailsTrackers from "./DetailsTrackers";
import DetailsPaltforms from "./DetailsPaltforms";
import DetailsPilots from "./DetailsPilots";
import DetailsOperationsMapDialog from "./DetailsOperationsMapOperator";

export default function DetailsOperationsDialog({
  openDetailsDialog,
  handleCloseDetailsDialog,
  selectedDialogData,
  refreshOperations,
  handleOperatorProposeOperation,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {}, [selectedDialogData]);
  if (
    selectedDialogData?.request?.original_request &&
    selectedDialogData?.details?.state !== "Accepted" // Accept after propose changes should not appear
  ) {
    return (
      <div>
        <DetailsOperationsMapDialog
          openDetailsDialog={openDetailsDialog}
          handleCloseDetailsDialog={handleCloseDetailsDialog}
          selectedDialogData={selectedDialogData}
          // handleopenAuthProposeOperationDrawer={}
          // handleOpenFlightAuthorizationAuthority={}
          getOperations={refreshOperations}
          handleOperatorProposeOperation={handleOperatorProposeOperation}
        />
      </div>
    );
  }
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        // disableBackdropClick
        // fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <LoadingOverlay
          active={submitLoading}
          spinner
          text="Submitting operation..."
        >
          <DialogTitle id="responsive-dialog-title">
            {`Details of ${selectedDialogData?.reference?.description}`}
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Operation :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {`${selectedDialogData?.reference?.description}`}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Departure :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {convertZuluToLocalTime(
                      selectedDialogData?.interuss?.operational_intent_reference
                        ?.time_start?.value
                    )}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Arrival :{" "}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {convertZuluToLocalTime(
                      selectedDialogData?.interuss?.operational_intent_reference
                        ?.time_end?.value
                    )}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Pilot :{" "}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    <DetailsPilots
                      pilot_uuid={selectedDialogData?.request?.pilot_uuid}
                    />
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Platform :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    <DetailsPaltforms
                      platform_uuid={selectedDialogData?.request?.platform_uuid}
                    />
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Tracker :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    <DetailsTrackers
                      tracker_uuid={selectedDialogData?.request?.tracker_uuid}
                    />
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Status :{" "}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {`${selectedDialogData?.details?.state}`}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Operation ID :
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {`${selectedDialogData?.reference?.id}`}{" "}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid item xs={3}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Created On :{" "}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    {convertZuluToLocalTime(
                      selectedDialogData?.reference?.time_created?.value
                    )}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={handleCloseDetailsDialog}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}
