import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import GeoJsonFile from "../../lib/arcgis/Drone-Port-geojson.geojson";

function fetchJSON(url) {
  return fetch(url).then((response) => response.json());
}

export default function DroneCorridorCard(props) {
  const { corridorData, index, selectedCorridor, setSelectedCorridor } = props;
  const [retData, setRetData] = useState([]);
  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  const mapboxController = useSelector((state) => state.maps.mapboxController);

  useEffect(() => {
    async function fetchData() {
      try {
        const portData = await fetchJSON(GeoJsonFile);
        const portDataFeatures = portData.features || [];
        const anchoragesOptions = portDataFeatures.map((feature) => ({
          name: feature.properties.seamark_na,
          geometry: feature.geometry,
        }));

        const groupedAnchorages = anchoragesOptions.reduce(
          (acc, option) => {
            if (option.name.toLowerCase().includes("corridor")) {
              acc.Corridors.push(option);
            } else if (option.name.toLowerCase().includes("section")) {
              acc.Subsections.push(option);
            } else {
              acc.faf.push(option);
            }
            return acc;
          },
          { Corridors: [], Subsections: [], faf: [] }
        );

        const availableAnchorages2 = [
          {
            name: "Eastern Anchorage",
            point: [103.88618933181681, 1.2642828293283515],
            subsections: groupedAnchorages.Subsections.map((entry) => ({
              ...entry,
              name: entry.name.includes("Section")
                ? entry.name.replace("Section", "Subsection")
                : entry.name,
            })),
            corridors: groupedAnchorages.Corridors,
            faf: groupedAnchorages.faf.map((entry) => ({
              name:
                entry.name === "Maritime Drone Estate Helipad"
                  ? "Maritime Drone Estate"
                  : entry.name,
              tolNames: [{ name: entry.name }],
            })),
          },
        ];
        setRetData(availableAnchorages2[0].corridors);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const handleSelected = () => {
    const corridorName = corridorData.corridor;
    const matchingCorridor = retData.find(
      (item) => corridorName === item?.name
    );

    if (matchingCorridor) {
      mapboxController.highlightArea2(matchingCorridor?.geometry);
    } else {
      mapboxController.removeHighlightedArea2();
    }

    selectedCorridor === index
      ? setSelectedCorridor(-1)
      : setSelectedCorridor(index);
  };

  const handleHover = () => {
    const corridorName = corridorData.corridor;
    const matchingCorridor = retData.find(
      (item) => corridorName === item?.name
    );

    if (matchingCorridor) {
      mapboxController.highlightGeometry(matchingCorridor?.geometry);
    } else {
      mapboxController.removeHighlight();
    }

    // setTimeout(() => {
    //   mapboxController.removeHighlight();
    // }, 3000);
  };

  const handleHoverOut = () => {
    mapboxController.removeHighlight();
  };

  return (
    <MenuItem
      item
      selected={selectedCorridor === index}
      onMouseEnter={() => handleHover()}
      onMouseOut={() => handleHoverOut()}
      onClick={() => handleSelected()}
      sx={{ marginBottom: "10px", marginTop: "4px", border: "3px solid grey" }}
    >
      <Grid>
        <div>{corridorData.corridor}</div>
        <div>Start time: {formatDate(corridorData?.time_start.value)}</div>
        <div>End time: {formatDate(corridorData?.time_end.value)}</div>
      </Grid>
    </MenuItem>
  );
}
