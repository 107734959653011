import { useState, useEffect } from "react";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import HeightIcon from "@mui/icons-material/Height";
import SpeedIcon from "@mui/icons-material/Speed";
import ExploreIcon from "@mui/icons-material/Explore";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import { useApi } from "../../api/useApi";
import useCognitoAuth from "../../hooks/useCognitoAuth";

import { downloadSingleJSON, downloadKML } from "../../services/json";
import { setSnackbarMessage } from "../../store/actions";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import DroneIcon from "../DroneIcon/DroneIcon";

export default function OperationsCard({
  operation,
  handleOnFocus,
  highlighted,
  telemetryData,
  dataTracker,
  dataPilot,
  platformNames,
  handleStartClick,
  handleLandClick,
  handleContingentClick,
  handleDownloadClick,
  handleLiveStreamingClick,
  handleRemoveClick,
  backgroundColor,
  handleNotifyButtonClick,
  handleLandNotifyButtonClick,
  handleHoldNotifyButtonClick,
}) {
  const [telemetryDataArr, setTelemetryDataArr] = useState([]);
  const [telemetryDataArrPush, setTelemetryDataArrPush] = useState([]);
  const [platformNamesOrdered, setPlatformNamesOrdered] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCardView = () => {
    setIsExpanded(!isExpanded);
  };

  const api = useApi();
  const { username, isOperator, isAuthority, isPilot, isRemoteId } =
    useCognitoAuth();

  const theme = useTheme();
  const numColumns = 12;
  const ICON_FONT_SIZE = "14px";
  const TEXT_FONT_SIZE = "13px";
  const SUBTITLE_FONT_SIZE = "9px";
  const iconStyles = {
    fontSize: ICON_FONT_SIZE,
    marginBottom: "-2px",
    marginRight: "2px",
  };
  const operationButtonStyles = {
    fontSize: "9px",
    // fontWeight: "bold",
    width: "20px",
    marginBottom: "3px",
    height: "25px",
  };

  // PLATFORM NAMES
  const opsPlatformUuid = operation.request?.platform_uuid;

  useEffect(() => {
    const updatedPlatformNamesOrdered = opsPlatformUuid.map((platform_uuid) => {
      const matchedPlatform = platformNames.find(
        (platform) => platform.platform_uuid === platform_uuid
      );
      return matchedPlatform ? matchedPlatform.platform_name : "";
    });

    setPlatformNamesOrdered(updatedPlatformNamesOrdered);
  }, [opsPlatformUuid, platformNames]);

  // TELEMETRY DETAILS LOGIC
  const isNumeric = (num) =>
    (typeof num === "number" ||
      (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num);

  useEffect(() => {
    if (
      telemetryData !== undefined &&
      telemetryData?.puckResponses?.length > 0
    ) {
      setTelemetryDataArr(telemetryData?.puckResponses);
    }
    const mapingTelemetery = (tracker_imei) => {
      if (telemetryData[tracker_imei]) {
        return [telemetryData[tracker_imei].puckResponses[0][1]];
      }

      return false;
    };
    const loopData = () => {
      const promisePlatform = [];
      dataTracker
        // .filter((t) => !t[1].error)
        .map((t, indexT) => {
          // if (!t[1]?.error) {}
          // const trackStatus = () => {
          //   if (t[1]?.operational_status !== "Undeclared") {
          //     return true;
          //   }
          //   return false;
          // };
          promisePlatform.push({
            type: "TRACKER_STATUS",
            tracker_callsign: t?.data?.tracker_name, // Todo: map imei to tracker name.
            telemetryData: mapingTelemetery(
              `${t?.data?.tracker_imei}/${operation.reference.id}`
            ),
            tracker_imei: t?.data?.tracker_imei,
          });
          setTelemetryDataArrPush(promisePlatform);
        });
    };
    loopData();
  }, [telemetryData, dataTracker, telemetryDataArr]);

  const droneWithCloudAPI = [
    "DJI M300",
    "DJI M300 RTK",
    "DJI Matrice 300",
    "DJI Matrice 300 RTK",
    "DJI M350",
    "DJI M350 RTK",
    "DJI Matrice 350",
    "DJI Matrice 350 RTK",
    "DJI M3",
    "DJI M3D",
    "DJI M3TD",
    "DJI Matrice 3D",
    "DJI Matrice 3TD",
    "DJI Matrice 30",
    "DJI M30",
    "DJI Matrice 30",
    "DJI M30T",
    "DJI Matrice 30T",
    "DJI Mavic 3E",
    "DJI Mavic 3M",
    "DJI Mavic 3 Enterprise",
  ];

  const ApprovedUAV = (platform_name) => {
    return droneWithCloudAPI.includes(platform_name);
  };

  return (
    <Grid
      container
      onClick={toggleCardView}
      sx={{
        borderRadius: "5px",
        margin: " 3.5px 0px",
        border: `3px solid ${backgroundColor}`,
        borderBottom: "none",
        borderRight: "none",
        // borderLeft: "none",
      }}
    >
      <Grid
        item
        container
        columns={numColumns}
        sx={{
          height: "7px",
          // marginBottom: "-5px",
          backgroundColor: { backgroundColor },
        }}
      >
        {" "}
      </Grid>

      <Grid
        container
        columns={numColumns}
        sx={{
          cursor: "pointer",
          margin: `${theme.spacing(0, 1)}`,
          // marginLeft: "0px",
        }}
      >
        {isExpanded ? (
          <>
            {/* OPERATION NAME & TYPE */}
            <Grid
              item
              xs={numColumns}
              sx={{ marginBottom: "-3px", marginTop: "-4px" }}
            >
              <span style={{ color: "grey", fontSize: SUBTITLE_FONT_SIZE }}>
                OPERATION DETAILS
              </span>
            </Grid>
            <Grid
              item
              container
              sx={{
                backgroundColor: "",
                // border: "solid 0.5px grey ",
                borderBottom: isExpanded ? "solid 0.5px grey" : "",
                // padding: `${theme.spacing(0, 1)}`,
              }}
            >
              <Grid item id="OpDesc" xs={numColumns / (3 / 2)}>
                <DescriptionIcon sx={iconStyles} />

                <span
                  style={{
                    fontSize: TEXT_FONT_SIZE,
                    fontWeight: "bold",
                  }}
                >
                  {operation.reference.description}
                </span>
              </Grid>

              <Grid item id="OpType" xs={numColumns / 3}>
                <MultilineChartIcon sx={iconStyles} />
                <span
                  style={{
                    fontSize: TEXT_FONT_SIZE,
                    // fontWeight: "bold",
                  }}
                >
                  {operation.details.type.toUpperCase()}
                </span>
              </Grid>
            </Grid>
            {/* TELEMETRY ROWS */}
            <Grid item xs={numColumns} sx={{ marginBottom: "-3px" }}>
              <span style={{ color: "grey", fontSize: SUBTITLE_FONT_SIZE }}>
                TRACKER DETAILS
              </span>
            </Grid>
            {Object.keys(dataTracker).length > 0 &&
              dataTracker
                // .filter((t) => !t[1].error)
                .flatMap((t, index) => (
                  <Grid
                    container
                    key={t?.data?.tracker_uuid?.toString()}
                    direction="column"
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      columns={numColumns}
                      // spacing={0.5}
                      sx={{
                        // padding: `${theme.spacing(0, 1)}`,
                        // border: "solid 0.75px grey ",
                        borderBottom: "solid 0.5px grey",
                      }}
                    >
                      <Grid
                        item
                        xs={numColumns / (3 / 2)}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <AccountBoxIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                            fontWeight: "bold",
                          }}
                        >
                          {dataPilot[index]?.data?.pilot_name
                            ? dataPilot[index]?.data?.pilot_name
                            : "anonymous pilot"}{" "}
                        </span>
                      </Grid>

                      <Grid item xs={numColumns / 3}>
                        <SpeedIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                            // fontWeight: "bold",
                          }}
                        >
                          {isNumeric(
                            telemetryDataArrPush[index]?.telemetryData[0]?.speed
                          )
                            ? telemetryDataArrPush[
                                index
                              ]?.telemetryData[0]?.speed.toFixed(1)
                            : " "}{" "}
                          m/s
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={numColumns / (3 / 2)}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <DroneIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                            fontWeight: "bold",
                          }}
                        >
                          {platformNamesOrdered[index]}{" "}
                        </span>
                      </Grid>

                      <Grid item xs={numColumns / 3}>
                        <HeightIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                          }}
                        >
                          {isNumeric(
                            telemetryDataArrPush[index]?.telemetryData[0]
                              ?.position?.alt
                          )
                            ? telemetryDataArrPush[
                                index
                              ]?.telemetryData[0]?.position?.alt.toFixed(1)
                            : telemetryDataArrPush[index]?.telemetryData[0]
                                ?.position?.alt}{" "}
                          m
                        </span>
                      </Grid>

                      <Grid item xs={numColumns / (3 / 2)}>
                        <SettingsRemoteIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                            fontWeight: "bold",
                          }}
                        >
                          {t?.data?.tracker_name ? t?.data?.tracker_name : " "}{" "}
                        </span>
                      </Grid>

                      <Grid item xs={numColumns / 3}>
                        <ExploreIcon sx={iconStyles} />
                        <span
                          style={{
                            fontSize: TEXT_FONT_SIZE,
                            // fontWeight: "bold",
                          }}
                        >
                          {telemetryDataArrPush
                            ? telemetryDataArrPush[index]?.telemetryData[0]
                                ?.track
                            : " "}{" "}
                          deg
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            {/*  DEPART ARRIVAL ROWS */}
            <Grid item xs={numColumns}>
              <span style={{ color: "grey", fontSize: SUBTITLE_FONT_SIZE }}>
                OPERATION TIMINGS{" "}
              </span>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              columns={numColumns}
              sx={{
                marginBottom: "5px",
                borderBottom: "solid 0.5px grey",
              }}
            >
              <Grid item xs={numColumns} id="depart">
                <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  Depart -{" "}
                </span>
                <span style={{ fontSize: "14px" }}>
                  {convertZuluToLocalTime(
                    operation.interuss.operational_intent_reference.time_start
                      .value
                  )}
                </span>
              </Grid>

              <Grid item xs={numColumns} id="arrive">
                <span
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Arrive -{" "}
                </span>
                <span style={{ fontSize: "14px" }}>
                  {convertZuluToLocalTime(
                    operation.interuss.operational_intent_reference.time_end
                      .value
                  )}
                </span>
              </Grid>
            </Grid>
            {/* OPERATIONAL BUTTONS */}
            <Grid item xs={numColumns}>
              <span style={{ color: "grey", fontSize: SUBTITLE_FONT_SIZE }}>
                OPERATION ACTIONS{" "}
              </span>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              columns={numColumns}
              sx={{
                // borderRadius: "4px",
                // padding: `${theme.spacing(0, 1)}`,
                marginBottom: "5px",
                // border: "solid 0.75px grey ",
                borderBottom: "solid 0.5px grey",
              }}
            >
              {/* FIRST ROW OF BUTTONS */}
              <Grid item container sx={{ justifyContent: "space-between" }}>
                {(isOperator || isPilot) && (
                  <>
                    <Grid item id="TakeoffButton">
                      <Button
                        sx={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleStartClick(e);
                        }}
                        // color="primary"
                        variant="contained"
                      >
                        Takeoff
                      </Button>
                    </Grid>

                    <Grid item id="LandButton">
                      <Button
                        sx={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLandClick(e);
                        }}
                        variant="contained"
                      >
                        Landed
                      </Button>
                    </Grid>
                  </>
                )}

                {isAuthority && (
                  <>
                    <Grid item id="RequestHold">
                      <Button
                        sx={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHoldNotifyButtonClick(e);
                        }}
                        variant="contained"
                      >
                        Hold
                      </Button>
                    </Grid>

                    <Grid item id="RequestLand">
                      <Button
                        sx={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLandNotifyButtonClick(e);
                        }}
                        variant="contained"
                      >
                        Land
                      </Button>
                    </Grid>
                  </>
                )}

                <Grid item id="FocusButton">
                  <Button
                    sx={{
                      fontSize: "9px",
                      // fontWeight: "bold",
                      width: "20px",
                      marginBottom: "3px",
                      height: "25px",
                      backgroundColor: highlighted ? "purple" : "",
                    }}
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!highlighted) {
                        handleOnFocus(operation);
                      } else {
                        handleOnFocus(null);
                      }
                    }}
                  >
                    {!highlighted ? "Focus" : "Defocus"}
                  </Button>
                </Grid>
              </Grid>

              {/* SECOND ROW OF BUTTONS */}

              <Grid item container sx={{ justifyContent: "space-between" }}>
                {(isOperator || isPilot) && (
                  <>
                    <Grid item id="DownloadButton">
                      <Button
                        style={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadClick(e);
                        }}
                        variant="outlined"
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item id="CancelButton">
                      <Button
                        style={operationButtonStyles}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveClick(e);
                        }}
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                )}

                {isAuthority && (
                  <Grid item id="NotifyButton">
                    <Button
                      style={operationButtonStyles}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNotifyButtonClick(e);
                      }}
                      variant="outlined"
                    >
                      Notify
                    </Button>
                  </Grid>
                )}

                <Grid item id="ContingentButton">
                  <Button
                    style={operationButtonStyles}
                    variant="contained"
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleContingentClick(e);
                    }}
                  >
                    Contingent
                  </Button>
                </Grid>
              </Grid>

              {/* THIRD ROW OF BUTTONS */}

              <Grid item container sx={{ justifyContent: "space-between" }}>
                {platformNamesOrdered.some((platform) =>
                  ApprovedUAV(platform)
                ) && (
                  <Grid item xs={12} id="LiveStreamingButton">
                    <Button
                      style={operationButtonStyles}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLiveStreamingClick(e);
                      }}
                      variant="outlined"
                    >
                      Livestream
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          // MINIMIZED VIEW
          <>
            <Grid item id="OpDesc" xs={numColumns / (3 / 2)}>
              <DescriptionIcon sx={iconStyles} />

              <span
                style={{
                  fontSize: TEXT_FONT_SIZE,
                  fontWeight: "bold",
                }}
              >
                {operation.reference.description}
              </span>
            </Grid>

            <Grid item id="OpType" xs={numColumns / 3}>
              <MultilineChartIcon sx={iconStyles} />
              <span
                style={{
                  fontSize: TEXT_FONT_SIZE,
                  // fontWeight: "bold",
                }}
              >
                {operation.details.type.toUpperCase()}
              </span>
            </Grid>

            <Grid item container direction="column" xs={numColumns / (3 / 2)}>
              {Object.keys(dataTracker).length > 0 &&
                dataTracker.flatMap((t, index) => (
                  <Grid
                    item
                    id="PlatformNames"
                    xs={numColumns / 2}
                    sx={{
                      // overflow: "hidden",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                    }}
                    key={t?.data?.tracker_uuid?.toString()}
                  >
                    <DroneIcon sx={iconStyles} />
                    <span
                      style={{
                        fontSize: TEXT_FONT_SIZE,
                        fontWeight: "bold",
                      }}
                    >
                      {platformNamesOrdered[index]}{" "}
                    </span>
                  </Grid>
                ))}
            </Grid>
            <Grid item id="FocusButton" xs={numColumns / 3}>
              <Button
                sx={{
                  fontSize: "9px",
                  // fontWeight: "bold",
                  width: "20px",
                  marginBottom: "3px",
                  height: "25px",
                  backgroundColor: highlighted ? "purple" : "",
                }}
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!highlighted) {
                    handleOnFocus(operation);
                  } else {
                    handleOnFocus(null);
                  }
                }}
              >
                {!highlighted ? "Focus" : "Defocus"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
