import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import SchedulesHeader from "../SchedulesHeader";

const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
const formatDate2 = (date) => {
  try {
    if (date === 0) {
      return "Unknown";
    }
    const dateInt = parseInt(date, 10);
    return format(new Date(dateInt), "MM/dd/yyyy HH:mm:ss");
  } catch (err) {
    return "Unknown";
  }
};

const formartDateForSort = (date) =>
  format(new Date(date), "yyyy/MM/dd HH:mm:ss");

const formartDateForSort2 = (date) => {
  try {
    const dateInt = parseInt(date, 10);
    return format(new Date(dateInt), "yyyy/MM/dd HH:mm:ss");
  } catch (err) {
    return "Unknown";
  }
};

const durationCalculator2 = (dateTakeOff, dateLanding) => {
  try {
    if (
      dateTakeOff === "Unknown" ||
      dateLanding === "Unknown" ||
      dateTakeOff === 0 ||
      dateLanding === 0
    ) {
      return "Unknown";
    }
    const dateTakeOffInt = parseInt(dateTakeOff, 10);
    const dateLandingInt = parseInt(dateLanding, 10);
    const a = new Date(dateTakeOffInt);
    const b = new Date(dateLandingInt);
    const c = b - a;
    const d = [
      Math.floor(c / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0"),
      Math.floor((c / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0"),
      Math.floor((c / 1000) % 60)
        .toString()
        .padStart(2, "0"),
    ];
    return d.join(":");
  } catch (err) {
    return "Unknown";
  }
};

const durationCalculator = (dateTakeOff, dateLanding) => {
  try {
    if (dateTakeOff === "Unknown" || dateLanding === "Unknown") {
      return "Unknown";
    }
    const a = new Date(dateTakeOff);
    const b = new Date(dateLanding);

    const c = a - b;
    const d = [
      Math.floor(c / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0"),
      Math.floor((c / (1000 * 60)) % 60)
        .toString()
        .padStart(2, "0"),
      Math.floor((c / 1000) % 60)
        .toString()
        .padStart(2, "0"),
    ];
    return d.join(":");
  } catch (err) {
    return "Unknown";
  }
};

function descendingComparator(a, b, orderBy) {
  let comp1;
  let comp2;

  if (orderBy === "plannedDeparture") {
    comp1 = formartDateForSort(
      a.interuss.operational_intent_reference.time_start.value
    );
    comp2 = formartDateForSort(
      b.interuss.operational_intent_reference.time_start.value
    );
  } else if (orderBy === "plannedArrival") {
    comp1 = formartDateForSort(
      a.interuss.operational_intent_reference.time_end.value
    );
    comp2 = formartDateForSort(
      b.interuss.operational_intent_reference.time_end.value
    );
  } else if (orderBy === "flightDeparture") {
    comp1 = formartDateForSort2(a.details.takeoff_time);
    comp2 = formartDateForSort2(b.details.takeoff_time);
  } else if (orderBy === "flightArrival") {
    comp1 = formartDateForSort2(a.details.landing_time);
    comp2 = formartDateForSort2(b.details.landing_time);
  } else if (orderBy === "status") {
    comp1 = a.details.state;
    comp2 = b.details.state;
  } else if (orderBy === "plannedDuration") {
    comp1 = durationCalculator(
      a.interuss.operational_intent_reference.time_end.value,
      a.interuss.operational_intent_reference.time_start.value
    ).replace(/:/g, "");
    comp2 = durationCalculator(
      b.interuss.operational_intent_reference.time_end.value,
      b.interuss.operational_intent_reference.time_start.value
    ).replace(/:/g, "");
    if (comp1 !== "Unknown") {
      comp1 = Number(comp1);
    }
    if (comp2 !== "Unknown") {
      comp2 = Number(comp2);
    }
  } else if (orderBy === "flightDuration") {
    comp1 = durationCalculator2(
      a.details.takeoff_time,
      a.details.landing_time
    ).replace(/:/g, "");
    comp2 = durationCalculator2(
      b.details.takeoff_time,
      b.details.landing_time
    ).replace(/:/g, "");
    if (comp1 !== "Unknown") {
      comp1 = Number(comp1);
    }
    if (comp2 !== "Unknown") {
      comp2 = Number(comp2);
    }
  } else if (orderBy === "operationID") {
    comp1 = a.reference.id;
    comp2 = b.reference.id;
  } else if (orderBy === "pilot") {
    comp1 = a.details.pilot_name;
    comp2 = b.details.pilot_name;
  } else if (orderBy === "platform") {
    comp1 = a.details.platform_name;
    comp2 = b.details.platform_name;
  } else if (orderBy === "platformType") {
    comp1 = a.details.platform_type;
    comp2 = b.details.platform_type;
  } else if (orderBy === "created") {
    comp1 = formartDateForSort(a.reference.time_created.value);
    comp2 = formartDateForSort(b.reference.time_created.value);
  } else {
    comp1 = a.reference.description;
    comp2 = b.reference.description;
  }

  if (comp2 === "Unknown" && comp1 === "Unknown") {
    return 0;
  }

  if (comp1 === "Unknown") {
    return 1;
  }

  if (comp2 === "Unknown") {
    return -1;
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Copy enhanced table head from ActionMessagesTable to edit
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    data,
    selectedOperations,
    setSelectedOperations,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            checked={!!data.length && selectedOperations.length === data.length}
            onChange={(evt, checked) => {
              if (checked) {
                setSelectedOperations(data);
              } else {
                setSelectedOperations([]);
              }
            }}
          />
        </TableCell>
        {columns.map((headCell) =>
          headCell.id !== "message" ? (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              width={headCell.minWidth}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align="center"
              width={headCell.minWidth}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function ActionMessagesTable(props) {
  const { data, setDuplicateTriggered, toggleLoadOlderLogs, loadOlderLogs } =
    props;
  const [selectedOperations, setSelectedOperations] = React.useState([]);

  // Sort Data by pilotname
  const dataGrped = [];
  data.forEach((flightData) => {
    if (typeof flightData.details.pilot_name == "object") {
      flightData.details.pilot_name.forEach((pilot) => {
        const pilotName = pilot;
        dataGrped[pilotName] = dataGrped[pilotName] ?? [];
        dataGrped[pilotName].push(flightData);
      });
    } else {
      const pilotName = flightData.details.pilot_name;
      dataGrped[pilotName] = dataGrped[pilotName] ?? [];
      dataGrped[pilotName].push(flightData);
    }
  });

  const columns = [
    { id: "operation", label: "Operation" },
    { id: "status", label: "Status" },
    { id: "plannedDeparture", label: "Planned Departure" },
    { id: "plannedArrival", label: "Planned Arrival" },
    { id: "plannedDuration", label: "Planned Duration" },
    { id: "flightDeparture", label: "Flight Departure" },
    { id: "flightArrival", label: "Flight Arrival" },
    { id: "flightDuration", label: "Flight Duration" },
    { id: "operationID", label: "Operation ID" },
    { id: "pilot", label: "Pilot" },
    { id: "platform", label: "Platform" },
    { id: "platformType", label: "Platform Type" },
    { id: "created", label: "Created" },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("created");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const usePaginationActionsStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    icon: {
      color: "black",
    },
  }));

  const classes = usePaginationActionsStyles();
  // const theme = useTheme();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {Object.keys(dataGrped).map((pilotNameGrp) => (
        <Accordion key={pilotNameGrp}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              display="inline"
              variant="body2"
              style={{ marginLeft: "3px" }}
            >
              {pilotNameGrp}
            </Typography>
          </AccordionSummary>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table id="pilotas">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
                data={data}
                selectedOperations={selectedOperations}
                setSelectedOperations={setSelectedOperations}
              />
              <TableBody>
                {stableSort(
                  dataGrped[pilotNameGrp],
                  getComparator(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={pilotNameGrp + row.reference.id + Math.random()}
                    >
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          checked={
                            !!selectedOperations.filter(
                              (operation) =>
                                operation.reference.id === row.reference.id
                            ).length
                          }
                          onChange={(evt, checked) => {
                            if (checked) {
                              setSelectedOperations([
                                ...selectedOperations,
                                row,
                              ]);
                            } else {
                              setSelectedOperations(
                                selectedOperations.filter(
                                  (operation) =>
                                    operation.reference.id !== row.reference.id
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {`${row.reference.description}`}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.details.state}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.interuss?.operational_intent_reference?.time_start
                          ?.value &&
                          formatDate(
                            row.interuss.operational_intent_reference.time_start
                              .value
                          )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.interuss?.operational_intent_reference?.time_end
                          ?.value &&
                          formatDate(
                            row.interuss.operational_intent_reference.time_end
                              .value
                          )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {durationCalculator(
                          row.interuss.operational_intent_reference.time_end
                            .value,
                          row.interuss.operational_intent_reference.time_start
                            .value
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {formatDate2(row.details.takeoff_time)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {formatDate2(row.details.landing_time)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {durationCalculator2(
                          row.details.takeoff_time,
                          row.details.landing_time
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.reference.id}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {Array.isArray(row.details.pilot_name)
                          ? row.details.pilot_name.join(", ")
                          : row.details.pilot_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {Array.isArray(row.details.platform_name)
                          ? row.details.platform_name.join(", ")
                          : row.details.platform_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {Array.isArray(row.details.platform_type)
                          ? row.details.platform_type.join(", ")
                          : row.details.platform_type}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.reference?.time_created?.value &&
                          formatDate(row.reference.time_created.value)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataGrped[pilotNameGrp].length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Accordion>
      ))}
      <SchedulesHeader
        selectedOperations={selectedOperations}
        setDuplicateTriggered={setDuplicateTriggered}
        toggleLoadOlderLogs={toggleLoadOlderLogs}
        loadOlderLogs={loadOlderLogs}
      />
    </Paper>
  );
}
