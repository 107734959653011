import { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import circle from "@turf/circle";
import area from "@turf/area";
import turfPolygon from "turf-polygon";
import centroid from "@turf/centroid";
import length from "@turf/length";
import turfLineString from "turf-linestring";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  Tabs,
  Tab,
  ButtonGroup,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  MenuItem,
  Box,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import LoadingOverlay from "react-loading-overlay";
import Paper from "@material-ui/core/Paper";
import { format } from "date-fns";

import {
  Add,
  DeleteOutlined,
  LensOutlined,
  CropSquareOutlined,
  LabelOutlined,
} from "@material-ui/icons";
import FlatButton from "material-ui/FlatButton";
import Divider from "@material-ui/core/Divider";
import { setEditConstraint, setSnackbarMessage } from "../../../store/actions";
import { useApi } from "../../../api/useApi";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { computeCircleCenter } from "../../../api/circleCenter";
import Rule from "./rule";
import Requiring from "./requiring";
import useCognitoAuth from "../../../hooks/useCognitoAuth";

function CreateConstraintAuthorityFormComponent(props) {
  const {
    selectedAreaWaypoints,
    handleOpenConstrainMgrAuthority,
    isCircleEntries,
    isAreaEntries,
    isRequiring,
    requiringComponent,
    circleEntriesComponent,
    areaEntriesComponent,
    ruleComponent,
    name,
    setName,
    description,
    setDescription,
    departureTime,
    departureTimeError,
    endTime,
    endTimeError,
    handleDepartureTimeChange,
    handleEndTimeChange,
    altitude_reference,
    setAltitude_reference,
    handleAltRefChange,
    floor,
    setFloor,
    ceiling,
    setCeiling,
    mapboxController,
    isInEditMode,
    handleDiscardEdit,
    largeArea,
    classesFloating,
    recurringCheck,
    resetForm,
    selectedCircleWaypoints,
  } = props;
  const editConstraintsData = useSelector((state) => state.constraints);

  return (
    <>
      <Paper
        style={{
          height: `${350}px`,
          maxHeight: "calc(50% - 0px)",
          width: "100%",
          overflow: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div
          style={{
            marginTop: "0px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <Grid container sx={{ marginTop: "8px" }}>
            <Grid item xs={6}>
              <TextField
                id="constrainName"
                label="Name of Constraint"
                type="text"
                value={name || ""}
                onChange={(item) => setName(item.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "8px" }}>
            <Grid item xs={9}>
              <TextField
                id="contraintDescription"
                label="Description"
                value={description || ""}
                onChange={(item) => setDescription(item.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "0px" }}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  id="start-time"
                  label="Start Date & Time (Local Time)"
                  value={departureTime}
                  onChange={(value) => handleDepartureTimeChange(value)}
                  views={["day", "month", "year", "hours", "minutes"]}
                  format="dd-MM-yyyy hh:mm aa"
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    popper: {
                      sx: {
                        "& .MuiMultiSectionDigitalClock-root": {
                          maxHeight: "330px",
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                  error={!!departureTimeError}
                  helperText={departureTimeError}
                />
              </LocalizationProvider>
              {/* <TextField
                id="start-time"
                label="Start Date & Time (Local Time)"
                inputProps={{
                  min: "2022-05-01",
                  max: "2022-05-07",
                }}
                type="datetime-local"
                value={[
                  format(departureTime, "yyyy-MM-dd"),
                  "T",
                  format(departureTime, "HH:mm"),
                ].join("")}
                // fullWidth
                onChange={(item) =>
                  handleDepartureTimeChange(item.target.value)
                }
                error={!!departureTimeError}
                helperText={departureTimeError}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  id="end-time"
                  label="End Date & Time (Local Time)"
                  value={endTime}
                  onChange={(value) => handleEndTimeChange(value)}
                  views={["day", "month", "year", "hours", "minutes"]}
                  format="dd-MM-yyyy hh:mm aa"
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    popper: {
                      sx: {
                        "& .MuiMultiSectionDigitalClock-root": {
                          maxHeight: "330px",
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                  error={!!departureTimeError}
                  helperText={departureTimeError}
                />
              </LocalizationProvider>
              {/* <TextField
                id="end-time"
                label="End Date & Time (Local Time)"
                type="datetime-local"
                // defaultValue={handleDefaultEndTime(new Date())}
                value={[
                  format(endTime, "yyyy-MM-dd"),
                  "T",
                  format(endTime, "HH:mm"),
                ].join("")}
                fullWidth
                onChange={(item) => handleEndTimeChange(item.target.value)}
                error={!!endTimeError}
                helperText={endTimeError}
              /> */}
            </Grid>
          </Grid>
          <Grid item>{isRequiring && requiringComponent}</Grid>

          <Grid container spacing={1} sx={{ marginTop: "10px" }}>
            <Grid item xs={3}>
              <TextField
                id="altitudeReference"
                label="Altitude Reference"
                fullWidth
                size="small"
                select
                value={altitude_reference}
                onChange={(e) => {
                  setAltitude_reference(e.target.value);
                  handleAltRefChange(e.target.value);
                }}
              >
                <MenuItem value="W84">W84</MenuItem>
                <MenuItem value="AGL">AGL</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={4}>
              <TextField
                id="Floor"
                label="Elevation (meters)"
                fullWidth
                size="small"
                value={floor}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setFloor(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setFloor(parsedValue);
                    }
                  }
                }}
                type="number"
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="Ceiling"
                label="Ceiling (meters)"
                fullWidth
                size="small"
                value={ceiling}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setCeiling(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setCeiling(parsedValue);
                    }
                  }
                }}
                type="number"
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              />
            </Grid>

            <Grid item container spacing={3} xs={12} sx={{ marginTop: "-7px" }}>
              {/* <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "space-around",
                  alignItems: "end",
                }}
              > */}
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                DRAWING TOOLS:
              </Grid>
              <Grid
                item
                container
                spacing={1}
                xs={7}
                sx={{ alignItems: "flex-start" }}
              >
                <Grid item>
                  {isCircleEntries && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleOpenConstrainMgrAuthority();
                        mapboxController.changeDrawMode("draw_circle");
                        mapboxController.removeDrawFeatures("Polygon");
                      }}
                    >
                      <LensOutlined />
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {isAreaEntries && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        mapboxController.changeDrawMode("draw_polygon");
                        mapboxController.removeDrawFeatures("Polygon");
                        handleOpenConstrainMgrAuthority();
                      }}
                    >
                      <LabelOutlined />
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {isAreaEntries && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        mapboxController.changeDrawMode("draw_rectangle");
                        mapboxController.removeDrawFeatures("Polygon");
                        handleOpenConstrainMgrAuthority();
                      }}
                    >
                      <CropSquareOutlined />
                    </Button>
                  )}
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      mapboxController.trash();
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "-10px" }}>
              {isCircleEntries && circleEntriesComponent}
              {isAreaEntries && areaEntriesComponent}
            </Grid>
          </Grid>

          <Divider />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "5px", marginBottom: "10px" }}
          >
            <Grid item xs={12}>
              <Typography>
                Constraint floor area: {largeArea} &#13217;
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {ruleComponent}
        </div>
      </Paper>
      <div className={classesFloating.buttonFloating}>
        <div style={{ marginTop: "25px" }}>
          {isInEditMode ? (
            <Button
              type="button"
              size="small"
              variant="contained"
              color="secondary"
              fullWidth
              // disabled={!isInEditMode}
              onClick={handleDiscardEdit}
            >
              Discard Changes
            </Button>
          ) : (
            <Button
              type="button"
              size="small"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={resetForm}
            >
              Reset Form
            </Button>
          )}
        </div>
        <div style={{ marginTop: "25px" }}>
          {isAreaEntries && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                !!selectedAreaWaypoints.filter(([a, b]) => !a || !b).length
              }
            >
              {editConstraintsData.isEdit
                ? "Update Constraint"
                : "Create Constraint"}
            </Button>
          )}
          {isCircleEntries && (
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                selectedCircleWaypoints?.filter(([a, b]) => !a || !b).length
              }
            >
              {editConstraintsData.isEdit
                ? "Update Constraint"
                : "Create Constraint"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateConstraintAuthorityFormComponent;
