import MapboxDraw from "@mapbox/mapbox-gl-draw";

const Constants = MapboxDraw.constants;
const PointModeOverride = { ...MapboxDraw.modes.draw_point };

PointModeOverride.onSetup = function (opts) {
  const point = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: Constants.geojsonTypes.POINT,
      coordinates: [],
    },
  });

  this.addFeature(point);

  this.clearSelectedFeatures();
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.POINT);

  this.setActionableState({
    trash: true,
  });

  return { point };
};

// PointModeOverride.onClick = function (state, e) {
//   externalCloseFlightAuth();
// };
PointModeOverride.onClick = function (state, e) {
  this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  state.point.updateCoordinate("", e.lngLat.lng, e.lngLat.lat);
  this.map.fire(Constants.events.CREATE, {
    features: [state.point.toGeoJSON()],
  });
  this.changeMode(Constants.modes.SIMPLE_SELECT, {
    featureIds: [state.point.id],
  });
};

export default PointModeOverride;
