import PlatformType from "@/model/api/PlatformType";

interface PlatformTypeItemProps {
  data: PlatformType;
  onClick: (data: PlatformType) => void;
  onSelect: (id: string, isSelected: boolean) => void;
}

export default function PlatformTypeItem({
  data,
  onClick,
  onSelect,
}: PlatformTypeItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(data.platform_type_uuid, e.target.checked);
  };

  return (
    <div className="relative p-4 rounded-container mb-2 cursor-pointer">
      <div
        className="flex-col"
        key={data.platform_type_uuid}
        onClick={() => onClick(data)}
      >
        <h5 className="font-medium">{data.model}</h5>
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
