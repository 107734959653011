import React, { useEffect, useRef, useState } from "react";
import {
  MenuItem,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import { Button } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import AddOperatorForm from "../AddOperatorForm";
import { getWSService } from "../../services/websocket";
import { useApi } from "../../api/useApi";
import { setSnackbarMessage } from "../../store/actions";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    // marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon className={classes.icon} />
        ) : (
          <FirstPageIcon className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon className={classes.icon} />
        ) : (
          <LastPageIcon className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    height: 53,
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
  },
}));

function OperatorsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const { operators, onSeeUsers, loading, setLoading, refreshOperatorsList } =
    props;
  const {
    assignedOperators,
    setAssignedOperators,
    handleClickAddOperators,
    setAuthorityAssignOperation,
  } = props;

  const rowsPerPage = 7;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, operators.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    const getAssignedOperators = async () => {
      const res = await api.getAssignedOperators();
      setAssignedOperators(res.data);
    };
    getAssignedOperators();
    setLoading(false);
  }, []);

  const UpdateAssignOperator = () => {
    setAuthorityAssignOperation((s) => !s);
  };

  const getAssignedOperators = async () => {
    const res = await api.getAssignedOperators();
    setAssignedOperators(res.data);
  };

  useEffect(() => {
    getAssignedOperators();
  }, [api]);

  const handleAssignOperator = async (operatorName) => {
    try {
      const res = await api.assignOperator(operatorName.split(":")[1]);
      dispatch(
        setSnackbarMessage({
          message: res.data.message,
          severity: "success",
        })
      );
      refreshOperatorsList();
      UpdateAssignOperator();
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data?.message ?? err.response.data,
          severity: "error",
        })
      );
    }
  };

  const handleUnassignOperator = async (operatorName) => {
    try {
      const res = await api.unassignOperator(operatorName.split(":")[1]);
      dispatch(
        setSnackbarMessage({
          message: res.data.message,
          severity: "success",
        })
      );
      refreshOperatorsList();
      UpdateAssignOperator();
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data?.message ?? err.response.data,
          severity: "error",
        })
      );
    }
  };

  return (
    <Grid>
      <TableContainer>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableCell
              className={classes.tableCell}
              component="th"
              scope="row"
              colSpan="4"
            >
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{
                  width: "100%",
                  height: "40px",
                  marginLeft: "3px",
                  marginRight: "3px",
                }}
                onClick={handleClickAddOperators}
              >
                {" "}
                Add Operator{" "}
              </Button>
            </TableCell>
          </TableHead>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              <TableCell className={classes.tableCell}> Name </TableCell>
              <TableCell className={classes.tableCell}> Created at </TableCell>
              <TableCell className={classes.tableCell}> Description </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {" "}
                Actions{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operators
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isAssigned = assignedOperators.includes(row.operatorName);
                return (
                  <TableRow className={classes.tableRow} key={row.operatorName}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {`${row.operatorName}`}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {formatDate(new Date(row.operatorCreatedAt))}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                      style={{
                        textOverflow: "ellipsis",
                        width: "300px",
                      }}
                    >
                      {row.operatorDescription}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {!isAssigned ? (
                        <Button
                          variant="contained"
                          color="success"
                          style={{
                            width: "90px",
                          }}
                          onClick={() => handleAssignOperator(row.operatorName)}
                        >
                          Assign
                        </Button>
                      ) : (
                        <Button
                          style={{
                            width: "90px",
                          }}
                          variant="contained"
                          color="error"
                          onClick={() =>
                            handleUnassignOperator(row.operatorName)
                          }
                        >
                          Unassign
                        </Button>
                      )}
                      <Button
                        onClick={() =>
                          onSeeUsers(row.operatorName.split(":")[1])
                        }
                      >
                        See users
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={6}
                count={operators.length}
                rowsPerPage={rowsPerPage}
                page={page}
                className={classes.pagination}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}

function OperatorManagementTab(props) {
  // const { x } = props;
  // const anchorEl = useRef();
  // const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [operators, setOperators] = useState([]);
  const [operatorUsers, setOperatorUsers] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [assignedOperators, setAssignedOperators] = useState([]);
  const [addOperatorIsOpen, setAddOperatorIsOpen] = useState(false);
  const { setAuthorityAssignOperation } = props;
  const api = useApi();

  const getOperators = async () => {
    const res = await api.getOperators();
    setOperators(res.data);
  };
  const getAssignedOperators = async () => {
    const res = await api.getAssignedOperators();
    setAssignedOperators(res.data);
  };
  useEffect(() => {
    getOperators();
  }, [api]);

  useEffect(() => {
    getAssignedOperators();
  }, [api]);

  const handleClickAddOperators = () => {
    setAddOperatorIsOpen(true);
  };

  const handleCloseAddOperators = () => {
    setAddOperatorIsOpen(false);
    refreshOperatorsList();
  };

  const refreshOperatorsList = () => {
    getOperators();
    getAssignedOperators();
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      {/* <MenuItem
        style={{
          color: "black",
          fontSize: "12px",
          fontWeight: "bold",
          marginLeft: "5px",
        }}
        onClick={(event) => {
          setIsOpen(true);
          anchorEl.current = event.currentTarget;
        }}
      >
        Operator Management
      </MenuItem>
      <Popover
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsOpen(false)}
        id="OperatorManagementTab"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      > */}
      <OperatorsTable
        onSeeUsers={async (operatorName) => {
          setIsOpenDialog(true);
          const res = await api.getOperatorsUsers(operatorName);
          setOperatorUsers(res.data.data);
        }}
        operators={operators}
        assignedOperators={assignedOperators}
        setAssignedOperators={setAssignedOperators}
        handleClickAddOperators={handleClickAddOperators}
        loading={loading}
        setLoading={setLoading}
        refreshOperatorsList={refreshOperatorsList}
        setAuthorityAssignOperation={setAuthorityAssignOperation}
      />
      {/* </Popover> */}
      <AddOperatorForm
        open={addOperatorIsOpen}
        handleClose={handleCloseAddOperators}
      />
      <Dialog open={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
        <DialogTitle>Users</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingBottom: 16 }}>
            <Grid item xs={4}>
              <div
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Name
              </div>
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Email
              </div>
            </Grid>
          </Grid>
          {operatorUsers.map((user) => (
            <Grid container spacing={1} style={{ paddingBottom: 16 }}>
              <>
                <Grid item xs={4}>
                  <div>{user.userName}</div>
                </Grid>
                <Grid item xs={4}>
                  {
                    user.userAttributes.reduce(
                      (a, b) => Object.assign(a, b),
                      {}
                    ).email
                  }
                </Grid>
              </>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    </LoadingOverlay>
  );
}

export default OperatorManagementTab;
