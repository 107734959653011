import MapboxDraw from "@mapbox/mapbox-gl-draw";
import circle from "@turf/circle";
import * as distance from "@turf/distance";
import * as turfHelpers from "@turf/helpers";
import dragPan from "../utils/drag_pan";

const { doubleClickZoom } = MapboxDraw.lib;
const Constants = MapboxDraw.constants;
const DragCircleMode = { ...MapboxDraw.modes.draw_polygon };

DragCircleMode.onSetup = function (_opts) {
  const polygon = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      isCircle: true,
      center: [],
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  dragPan.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

DragCircleMode.onTouchStart = (state, e) => {
  const currentCenter = state.polygon.properties.center;
  if (currentCenter.length === 0) {
    // eslint-disable-next-line no-param-reassign
    state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat];
  }
};
DragCircleMode.onMouseDown = DragCircleMode.onTouchStart;

DragCircleMode.onMouseMove = (state, e) => {
  const { center } = state.polygon.properties;
  if (center.length > 0) {
    const distanceInKm = distance(
      turfHelpers.point(center),
      turfHelpers.point([e.lngLat.lng, e.lngLat.lat]),
      { units: "kilometers" }
    );
    const circleFeature = circle(center, distanceInKm);
    state.polygon.incomingCoords(circleFeature.geometry.coordinates);
    // eslint-disable-next-line no-param-reassign
    state.polygon.properties.radiusInKm = distanceInKm;
  }
};

DragCircleMode.onDrag = DragCircleMode.onMouseMove;

DragCircleMode.onTouchEnd = (state, _e) => {
  dragPan.enable(this);
  return this.changeMode(Constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
};
DragCircleMode.onMouseUp = DragCircleMode.onTouchEnd;
DragCircleMode.onTap = (state, _e) => {
  // don't draw the circle if its a tap or click event
  // eslint-disable-next-line no-param-reassign
  state.polygon.properties.center = [];
};
DragCircleMode.onClick = DragCircleMode.onTap;
DragCircleMode.toDisplayFeatures = (state, geojson, display) => {
  const isActivePolygon = geojson.properties.id === state.polygon.id;
  // eslint-disable-next-line no-param-reassign
  geojson.properties.active = isActivePolygon
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  return display(geojson);
};

export default DragCircleMode;
