import WebSocket from "isomorphic-ws";

// not in use, remove env variable
const WS_URL = "";
let WSService = null;
export class WebSocketService extends WebSocket {
  static messageListeners = {
    telemetry: [],
    alert: [],
  };

  static keepAlive = null;

  constructor(url, jwtToken, userGroup) {
    super(`${url}?jwtToken=${jwtToken}&userGroup=${userGroup}`);
    this.isOpen = false;
    this.dataConformance = {
      SET_STATE_CONFORMANCE: [],
    };
  }

  /**
   *  Show connection status to us in the log
   */
  onopen = () => {
    // eslint-disable-next-line no-console
    this.isOpen = true;
    // API gateway times out after 10 min of inactivity. Send ping every 9 min to keep connection alive.
    WebSocketService.keepAlive = setInterval(() => {
      this.sendMessage("ping", { message: "test" });
    }, 540000);
  };

  /**
   *  Log lost connection for now
   */
  // eslint-disable-next-line class-methods-use-this
  onclose = () => {
    clearInterval(WebSocketService.keepAlive);
  };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   */
  sendMessage = (routeKey, message) => {
    if (!this.isOpen) {
      console.error(message);
      return;
    }

    this.send(
      JSON.stringify({
        message: routeKey,
        data: JSON.stringify(message),
      })
    );
  };

  /**
   *  Used by application to register different listeners for
   *  different message types
   *  @param listener Function to handle message type
   */
  static addMessageListener = (listener) => {
    console.warn(`Unknown message channel ${listener}`);
    if (typeof listener !== "function") {
      return;
    }
    this.dataConformance = listener;
    // if (typeof listener !== "function") {
    //   return;
    // }
    // if (!WebSocketService.messageListeners[type]) {
    //   console.warn(`Unknown message channel ${type}`);
    //   return;
    // }
    // WebSocketService.messageListeners[type].push(listener);
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body to the appropriate
   * registered handler
   * @param data Message body received from WebSocket
   */
  onmessage = (data) => {
    // eslint-disable-next-line no-console
    // const dispatch = useDispatch();
    // dispatch(
    //   setStateConformance({
    //     showConformanceLayer: true,
    //     data,
    //   })
    // );
    console.info("ws connection data", this, data.data);
    if (data) {
      const body = JSON.parse(data.data);
      this.dataConformance = body;
    }
    // if (data) {
    //   const body = JSON.parse(data.data);

    //   if (!WebSocketService.messageListeners[body.type])
    //     console.warn(`Unknown message channel ${body.type}`);
    //   for (const listener of WebSocketService.messageListeners[body.type])
    //     listener(body.data);
    // }
  };

  consformanceListenData() {
    return this.dataConformance;
  }
}

export const getWSService = (jwtToken, role) => {
  if (!jwtToken || !role) return null;
  if (!WSService) WSService = new WebSocketService(WS_URL, jwtToken, role);
  return WSService;
};
// export const getWSMessage = () => {
//   return "hantu";
// };
