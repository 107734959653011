import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@material-ui/core/TextField";

export function UasIdComponent({ handleUasId, showPopup, setShowPopup }) {
  const [uasId, setUasId] = useState("");
  const [uasIdError, setUasIdError] = useState("");
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleSkip = () => {
    setShowPopup(false);
    handleUasId("");
  };
  const handleSubmit = () => {
    if (!uasId) {
      setUasIdError("UAS ID cannot be empty");
      return;
    }
    if (uasId.length !== 6) {
      setUasIdError("UAS ID must be 6 characters long");
      return;
    }
    setShowPopup(false);
    handleUasId(uasId);
  };
  return (
    <Dialog
      disableEscapeKeyDown
      // disableBackdropClick
      open={showPopup}
      onClose={handleClose}
    >
      <DialogTitle>
        UAS ID
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="uas-id"
          label="Enter the UAS ID from your FlyItSafe App, if applicable"
          type="text"
          style={{ width: "350px" }}
          value={uasId}
          onChange={(e) => {
            setUasId(e.target.value);
          }}
          error={!!uasIdError}
          helperText={uasIdError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSkip} color="primary">
          Skip
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
