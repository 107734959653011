// import { useEffect, useState } from "react";
// import LoadingOverlay from "react-loading-overlay";
// import CreateOperationForm from "../CreateOperationForm";

// import { getWSService } from "../../../services/websocket";
// import WebsocketAlert from "../../../components/WebsocketAlert";

import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import circle from "@turf/circle";
import area from "@turf/area";
import turfPolygon from "turf-polygon";
import centroid from "@turf/centroid";
import length from "@turf/length";
import turfLineString from "turf-linestring";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { Tabs, Tab, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import LoadingOverlay from "react-loading-overlay";

import { setEditConstraint, setSnackbarMessage } from "../../../store/actions";
import { useApi } from "../../../api/useApi";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { computeCircleCenter } from "../../../api/circleCenter";
import CreateConstraintAuthorityForm from "../CreateConstraintAuthorityForm";
import Rule from "../CreateConstraintAuthorityForm/rule";
import Requiring from "../CreateConstraintAuthorityForm/requiring";
import useCognitoAuth from "../../../hooks/useCognitoAuth";

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography variant="body1" component="div">
          {props.children}
        </Typography>
      )}
    </div>
  );
}
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));

function CircleEntries(props) {
  const {
    selectedCircleProperties,
    setSelectedCircleProperties,
    setSelectedCircleWaypoints,
    onUpdateSelectedCircleProperties,
    onUpdateSelectedCircleWaypoints,
    handleOpenConstrainMgrAuthority,
    onFocus,
  } = props;
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (
      selectedCircleProperties.center &&
      selectedCircleProperties.center[1] &&
      selectedCircleProperties.center[0]
    ) {
      setLatitude(selectedCircleProperties?.center[1]);
      setLongitude(selectedCircleProperties?.center[0]);
      setRadius(selectedCircleProperties?.radiusInKm);
    } else {
      setLatitude(0);
      setLongitude(0);
      setRadius(0);
    }
  }, [selectedCircleProperties]);

  useEffect(() => {
    updateDrawnCircle();
  }, [longitude, latitude, radius]);

  const handleLongitudeChange = (newLongitude) => {
    setLongitude(newLongitude);
  };

  const handleLatitudeChange = (newLatitude) => {
    setLatitude(newLatitude);
  };

  const handleRadiusChange = (newRadius) => {
    setRadius(newRadius);
  };

  const updateDrawnCircle = () => {
    if (longitude && latitude && radius) {
      const feature = circle([longitude, latitude], radius);
      const circleProps = {
        isCircle: true,
        center: [longitude, latitude],
        radiusInKm: radius,
      };

      // setSelectedAreaPolgon(feature.geometry.coordinates[0]);
      // onUpdateSelectedCircleWaypoints(feature.geometry.coordinates[0]);
      // onUpdateSelectedCircleProperties(circleProps);
      setSelectedCircleWaypoints(feature.geometry.coordinates[0]);
      setSelectedCircleProperties(circleProps);
    }
  };
  return (
    <Grid container columns={12} spacing={0.5} sx={{ marginTop: "0px" }}>
      <Grid item xs={3.75}>
        <Typography textAlign="center" fontWeight="bold" fontSize="14px">
          LAT (CENTER)
        </Typography>
      </Grid>
      <Grid item xs={3.75}>
        <Typography textAlign="center" fontWeight="bold" fontSize="14px">
          LONG (CENTER)
        </Typography>
      </Grid>
      <Grid item xs={2.5}>
        <Typography textAlign="center" fontWeight="bold" fontSize="14px">
          RADIUS (KM)
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography textAlign="center" fontWeight="bold" fontSize="14px">
          ZOOM
        </Typography>
      </Grid>

      <Grid item xs={3.75}>
        <TextField
          id="latitude-center"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          // color="black"
          inputProps={{ sx: { fontSize: 14 } }} // font size of input text
          // value={circleCenter ? circleCenter[1] : ""}
          value={latitude}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleLatitudeChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleLatitudeChange(parsedValue);
              }
            }
          }}
          // onChange={(ev) => {
          //   setLatitude(ev);
          // }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Grid>
      <Grid item xs={3.75}>
        <TextField
          id="longitude-center"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          inputProps={{ sx: { fontSize: 14 } }} // font size of input text
          // value={circleCenter ? circleCenter[0] : ""}
          value={longitude}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleLongitudeChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleLongitudeChange(parsedValue);
              }
            }
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          id="radius"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          inputProps={{
            sx: { fontSize: 14 },
          }} // font size of input text
          value={radius !== undefined ? radius : ""}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleRadiusChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleRadiusChange(parsedValue);
              }
            }
          }}
          //   // const center = circleCenter || [0.0, 0.0];
          //   // updateDrawnCircle(center, Number.parseFloat(ev.target.value));
          // }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          // onBlur={checkNegativeValue(radiusInKm, "Radius")}
        />
      </Grid>
      <Grid item xs={2} sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => {
            onFocus(selectedCircleProperties.center);
            handleOpenConstrainMgrAuthority();
          }}
          variant="outlined"
        >
          <CenterFocusIcon />
        </Button>
      </Grid>
    </Grid>
  );
}

function AreaEntries(props) {
  const {
    selectedAreaWaypoints,
    onChange,
    onFocus,
    handleOpenConstrainMgrAuthority,
  } = props;
  const numbersOfWaypoints = selectedAreaWaypoints?.length;
  const [keysCounter] = useState(0);
  const d = new Date();
  return (
    <Grid container columns={12} spacing={0.5} sx={{ marginTop: "0px" }}>
      <Grid item xs={1}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          WP
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LAT (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LONG (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={2.5}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          Actions
        </Typography>{" "}
      </Grid>

      {selectedAreaWaypoints.map((waypoint, i) => (
        <Fragment key={`waypoint${i + 1}`}>
          {/* <span style={{ height: "12px", lineHeight: "12px", margin: "auto" }}> */}
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {i + 1}
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="waypoint-latitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[1]}
              onChange={
                (ev) => {
                  const parsedValue = Number.parseFloat(ev.target.value);
                  if (!isNaN(parsedValue)) {
                    selectedAreaWaypoints[i][1] = parsedValue;
                    onChange([...selectedAreaWaypoints]);
                  } else {
                    selectedAreaWaypoints[i][1] = "";
                    onChange([...selectedAreaWaypoints]);
                  }
                }
                // selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="waypoint-longitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[0]}
              onChange={
                (ev) => {
                  const parsedValue = Number.parseFloat(ev.target.value);
                  if (!isNaN(parsedValue)) {
                    selectedAreaWaypoints[i][0] = parsedValue;
                    onChange([...selectedAreaWaypoints]);
                  } else {
                    selectedAreaWaypoints[i][0] = "";
                    onChange([...selectedAreaWaypoints]);
                  }
                }
                // selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>

          <Grid item xs={2.5}>
            <ButtonGroup>
              <Button
                onClick={() => {
                  onFocus(waypoint);
                  handleOpenConstrainMgrAuthority();
                }}
              >
                <CenterFocusIcon />
              </Button>
              <Button
                onClick={() => {
                  onChange([
                    ...selectedAreaWaypoints.slice(0, i),
                    ...selectedAreaWaypoints.slice(i + 1),
                  ]);
                }}
                // disabled={i === 0 || i + 1 === numbersOfWaypoints}
                disabled={i <= 2}
              >
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          onClick={() => {
            onChange([
              ...selectedAreaWaypoints.slice(0, -1),
              [0, 0],
              selectedAreaWaypoints[numbersOfWaypoints - 1],
            ]);
          }}
        >
          <AddIcon />
        </Button>
      </Grid>
    </Grid>

    // <div
    //   style={{
    //     display: "grid",
    //     gridTemplateColumns: "1fr 5fr 5fr 1fr",
    //     gap: "0.25rem",
    //   }}
    // >
    //   <h4 style={{ textAlign: "center" }}>WP</h4>
    //   <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
    //   <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
    //   <h4 style={{ textAlign: "center" }}>Actions</h4>
    //   {selectedAreaWaypoints.map((waypoint, i) => (
    //     <>
    //       <span style={{ height: "12px", lineHeight: "12px", margin: "auto" }}>
    //         {i + 1}
    //       </span>
    //       <input
    //         type="number"
    //         width={100}
    //         value={waypoint[1]}
    //         name={`waypointLatititude-${i}`}
    //         onChange={(ev) => {
    //           selectedAreaWaypoints[i][1] = Number.parseFloat(ev.target.value);
    //           onChange([...selectedAreaWaypoints]);
    //         }}
    //       />
    //       <input
    //         width={100}
    //         type="number"
    //         value={waypoint[0]}
    //         name={`waypointLongitude-${i}`}
    //         onChange={(ev) => {
    //           selectedAreaWaypoints[i][0] = Number.parseFloat(ev.target.value);
    //           onChange([...selectedAreaWaypoints]);
    //         }}
    //       />
    //       <ButtonGroup>
    //         <Button
    //           onClick={() => {
    //             onFocus(waypoint);
    //             onOpenConstrainMgrAuthority();
    //           }}
    //         >
    //           <CenterFocusIcon />
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             onChange([
    //               ...selectedAreaWaypoints.slice(0, i),
    //               ...selectedAreaWaypoints.slice(i + 1),
    //             ]);
    //           }}
    //           // disabled={i === 0 || i + 1 === numbersOfWaypoints}
    //           disabled={!!i <= 2}
    //         >
    //           <DeleteIcon />
    //         </Button>
    //       </ButtonGroup>
    //     </>
    //   ))}
    //   <Button
    //     variant="outlined"
    //     fullWidth
    //     style={{ gridColumnStart: 5, gridColumnEnd: 1 }}
    //     onClick={() => {
    //       onChange([
    //         ...selectedAreaWaypoints.slice(0, -1),
    //         [0, 0],
    //         selectedAreaWaypoints[numbersOfWaypoints - 1],
    //       ]);
    //     }}
    //   >
    //     {" "}
    //     <AddIcon />
    //   </Button>
    // </div>
  );
}

function CreateConstraintAuthorityComponent(props) {
  const {
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    selectedCircleProperties,
    setSelectedCircleProperties,
    onUpdateSelectedAreaWaypoints,
    // mapViewController,
    editConstraints,
    // onUpdateSelectedCircleWaypoints,
    // onUpdateSelectedCircleProperties,
    handleOpenConstrainMgrAuthority,
    openConstrainMgrAuthority,
  } = props;
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const envVar = useSelector((state) => state.envVar);
  const currentTime = new Date();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [altitude_reference, setAltitude_reference] = useState("W84");
  const [departureTime, setDepartureTime] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeError, setDepartureTimeError] = useState("");
  const [endTime, setEndTime] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeError, setEndTimeError] = useState("");
  const [ceiling, setCeiling] = useState(100);
  const [floor, setFloor] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabIndexM, setTabIndexM] = useState(0);
  const [selectTabIndexMethod, setSelectTabIndexMethod] = useState("dynamic");
  const [tabIndex, setTabIndex] = useState(0);
  const [valueTimeThick, setvalueTimeThick] = useState("daily");

  const [rule, setRule] = useState({
    prohibited: false,
    authorisation_required: false,
    conditional: false,
    information_only: false,
  });
  const [whitelistUser, setWhitelistUser] = useState([]);
  const [whitelistUserNested, setWhitelistUserNested] = useState([]);
  const [whitelistUserGroup, setWhitelistUserGroup] = useState([]);
  const [threshold, setThreshold] = useState(40);
  const [others, setOthers] = useState("");

  const [nocameratehetered, setnNoCameraThetered] = useState({
    no_camera: true,
    power_tethered: true,
  });
  const [personNameWhitelistUsers, setPersonNameWhitelistUsers] = useState([]);
  const [personNameWhitelistGroups, setPersonNameWhitelistGroups] = useState(
    []
  );
  const [
    authorisationPersonNameWhitelistUsers,
    setAuthorisationPersonNameWhitelistUsers,
  ] = useState([]);
  const [
    authorisationPersonNameWhitelistGroups,
    setAuthorisationPersonNameWhitelistGroups,
  ] = useState([]);
  const [departureTimeAuthCon, setDepartureTimeAuthCon] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeErrorAuthCon, setDepartureTimeErrorAuthCon] =
    useState("");
  const [endTimeAuthCon, setEndTimeAuthCon] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeErrorAuthCon, setEndTimeErrorAuthCon] = useState("");

  const [departureTimeProhibitCon, setDepartureTimeProhibitCon] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeErrorProhibitCon, setDepartureTimeErrorProhibitCon] =
    useState("");
  const [endTimeProhibitCon, setEndTimeProhibitCon] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeErrorProhibitCon, setEndTimeErrorProhibitCon] = useState("");

  const [departureTimeRequiring, setDepartureTimeRequiring] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeErrorRequiring, setDepartureTimeErrorRequiring] =
    useState("");
  const [endTimeRequiring, setEndTimeRequiring] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeErrorRequiring, setEndTimeErrorRequiring] = useState("");
  const [recurringCheck, setRecurringChek] = useState({
    recurring_Chek: false,
  });

  // Authorization Condition Recurring
  const [authConValueTimeThick, setAuthConValueTimeThick] = useState("daily");
  const [authConDepartureTimeRequiring, setAuthConDepartureTimeRequiring] =
    useState(new Date(currentTime.getTime() + 3 * 60000)); // Default set as 3 mins from current time
  const [
    authConDepartureTimeErrorRequiring,
    setAuthConDepartureTimeErrorRequiring,
  ] = useState("");
  const [authConEndTimeRequiring, setAuthConEndTimeRequiring] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [authConEndTimeErrorRequiring, setAuthConEndTimeErrorRequiring] =
    useState("");
  const [authConRecurringCheck, setAuthConRecurringChek] = useState({
    recurring_Chek: false,
  });

  // Prohibited Condition Recurring
  const [prohibitConValueTimeThick, setProhibitConValueTimeThick] =
    useState("daily");
  const [
    prohibitConDepartureTimeRequiring,
    setProhibitConDepartureTimeRequiring,
  ] = useState(new Date(currentTime.getTime() + 3 * 60000)); // Default set as 3 mins from current time
  const [
    prohibitConDepartureTimeErrorRequiring,
    setProhibitConDepartureTimeErrorRequiring,
  ] = useState("");
  const [prohibitConEndTimeRequiring, setProhibitConEndTimeRequiring] =
    useState(new Date(currentTime.getTime() + 33 * 60000)); // Default set as 30 mins from start time
  const [
    prohibitConEndTimeErrorRequiring,
    setProhibitConEndTimeErrorRequiring,
  ] = useState("");
  const [prohibitConRecurringCheck, setProhibitConRecurringChek] = useState({
    recurring_Chek: false,
  });

  const { username, isOperator, isAuthority, isPilot } = useCognitoAuth();
  const uss_base_url = envVar["base_url-sp"].Value;
  const onTabClicked = (event, index) => {
    setTabIndex(index);
    if (index === 0) {
      onSelectWaypointMethod("circle");
    } else if (index === 1) {
      onSelectWaypointMethod("area");
    }
  };
  const onTabMClicked = (event, index) => {
    setTabIndexM(index);
    if (index === 0) {
      setSelectTabIndexMethod("dynamic");
    } else if (index === 1) {
      setSelectTabIndexMethod("static");
    }
  };
  const handleTimeThickChange = (event) => {
    setvalueTimeThick(event.target.value);
  };
  const dispatch = useDispatch();
  const api = useApi();
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("circle");
  const editConstraintsData = useSelector((state) => state.constraints);
  const [isInEditMode, setIfEditMode] = useState(false);
  const [largeArea, setLargeArea] = useState(0);
  const classesFloating = useFloatingButton();

  const handleSubmit = async (e) => {
    // Based on if recurring is selected, time start/end values will be swapped with
    // recurring range time start/end values
    const wgs84Elevation =
      altitude_reference === "AGL" ? floor + ceiling : ceiling;
    e.preventDefault();
    setSubmitLoading(true);

    let outlinePolygonCoordinates = null;
    if (selectWaypointMethod === "area") {
      outlinePolygonCoordinates = [selectedAreaWaypoints];
    } else if (selectWaypointMethod === "circle") {
      outlinePolygonCoordinates = [selectedCircleWaypoints];
    }
    if (outlinePolygonCoordinates === null) {
      // need to handle case if coordinates is invalid?
      return;
    }
    // if (selectWaypointMethod === "area") {
    if (selectTabIndexMethod === "static") {
      const prohibited_conditions = {
        // Required only if rule.authorisation_required is true
        whitelist: {
          // Required only if rule.prohibited is true
          users: personNameWhitelistUsers,
          usergroups: personNameWhitelistGroups,
        },
        time_start: {
          // (optional)
          format: "RFC3339",
          value: departureTimeProhibitCon.toISOString(),
        },
        time_end: {
          // (optional)
          format: "RFC3339",
          value: endTimeProhibitCon.toISOString(),
        },
      };

      if (prohibitConRecurringCheck.recurring_Chek) {
        prohibited_conditions.time_start = {
          // (optional)
          format: "RFC3339",
          value: prohibitConDepartureTimeRequiring.toISOString(),
        };
        prohibited_conditions.time_end = {
          // (optional)
          format: "RFC3339",
          value: prohibitConEndTimeRequiring.toISOString(),
        };

        prohibited_conditions.recurring = prohibitConValueTimeThick;

        prohibited_conditions.recurrence_range = {
          time_start: {
            // (optional)
            format: "RFC3339",
            value: departureTimeProhibitCon.toISOString(),
          },
          time_end: {
            // (optional)
            format: "RFC3339",
            value: endTimeProhibitCon.toISOString(),
          },
        };
      }

      const authorisation_conditions = {
        whitelist: {
          // Required only if rule.prohibited is true
          users: authorisationPersonNameWhitelistUsers,
          usergroups: authorisationPersonNameWhitelistGroups,
        },
        time_start: {
          // (optional)
          format: "RFC3339",
          value: departureTimeAuthCon.toISOString(),
        },
        time_end: {
          // (optional)
          format: "RFC3339",
          value: endTimeAuthCon.toISOString(),
        },
      };

      if (authConRecurringCheck.recurring_Chek) {
        authorisation_conditions.time_start = {
          // (optional)
          format: "RFC3339",
          value: authConDepartureTimeRequiring.toISOString(),
        };
        authorisation_conditions.time_end = {
          // (optional)
          format: "RFC3339",
          value: authConEndTimeRequiring.toISOString(),
        };

        authorisation_conditions.recurring = prohibitConValueTimeThick;

        authorisation_conditions.recurrence_range = {
          time_start: {
            // (optional)
            format: "RFC3339",
            value: departureTimeAuthCon.toISOString(),
          },
          time_end: {
            // (optional)
            format: "RFC3339",
            value: endTimeAuthCon.toISOString(),
          },
        };
      }
      const withoutRequring = {
        extents: [
          {
            time_end: {
              format: "RFC3339",
              value: endTime.toISOString(),
            },
            time_start: {
              format: "RFC3339",
              value: departureTime.toISOString(),
            },
            volume: {
              altitude_lower: {
                reference: "W84",
                units: "M",
                value: parseInt(floor, 10),
              },
              altitude_upper: {
                reference: "W84",
                units: "M",
                value: parseInt(wgs84Elevation, 10),
              },
              outline_polygon: {
                coordinates: outlinePolygonCoordinates,
                type: "Polygon",
              },
            },
          },
        ],
        old_version: 0,
        uss_base_url,
        name,
        description,
        rule,
        prohibited_conditions,
        authorisation_conditions,
        conditions: {
          // Required only if rule.conditions is true
          noise_threshold: parseInt(threshold, 10),
          no_camera: nocameratehetered.no_camera,
          power_tethered: nocameratehetered.power_tethered,
          others,
        },
        constraint_owner: username,
      };

      const withRequring = {
        extents: [
          {
            time_end: {
              format: "RFC3339",
              value: endTime.toISOString(),
            },
            time_start: {
              format: "RFC3339",
              value: departureTime.toISOString(),
            },
            volume: {
              altitude_lower: {
                reference: "W84",
                units: "M",
                value: parseInt(floor, 10),
              },
              altitude_upper: {
                reference: "W84",
                units: "M",
                value: parseInt(wgs84Elevation, 10),
              },
              outline_polygon: {
                coordinates: outlinePolygonCoordinates,
                type: "Polygon",
              },
            },
          },
        ],
        old_version: 0,
        uss_base_url,
        name,
        description,
        rule,
        prohibited_conditions,
        authorisation_conditions,
        conditions: {
          // Required only if rule.conditions is true
          noise_threshold: parseInt(threshold, 10),
          no_camera: nocameratehetered.no_camera,
          power_tethered: nocameratehetered.power_tethered,
          others,
        },
        constraint_owner: username,
        recurring: valueTimeThick,
        recurrence_range: {
          time_start: {
            format: "RFC3339",
            value: departureTime.toISOString(),
          },
          time_end: {
            format: "RFC3339",
            value: endTime.toISOString(),
          },
        },
      };

      if (valueTimeThick) {
        withRequring.extents[0].time_end.value = endTimeRequiring.toISOString();
        withRequring.extents[0].time_start.value =
          departureTimeRequiring.toISOString();
      }
      try {
        const response = await api.submitAuthorityAreaConstrainPerm(
          recurringCheck?.recurring_Chek ? withRequring : withoutRequring
        );
        if (response.data) {
          setSubmitLoading(false);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: response.data.message,
              error: "success",
            })
          );
          dispatch(
            setEditConstraint({
              isEdit: false,
            })
          );
          if (selectWaypointMethod === "area") {
            setSelectedAreaWaypoints([
              [0.0, 0.0],
              [0.0, 0.0],
              [0.0, 0.0],
            ]);
          } else if (selectWaypointMethod === "circle") {
            setSelectedCircleWaypoints([
              [0.0, 0.0],
              [0.0, 0.0],
            ]);
          }
          setCeiling(0);
          mapboxController.removeDrawFeaturesAll();
          handleDiscardEdit();
        }
      } catch (err) {
        setSubmitLoading(false);
        // console.log("error", err);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: err.response.data.message,
            severity: "error",
          })
        );
      }
    } else {
      try {
        const response = await api.submitAuthorityAreaConstrain({
          extents: [
            {
              time_end: {
                format: "RFC3339",
                value: endTime.toISOString(),
              },
              time_start: {
                format: "RFC3339",
                value: departureTime.toISOString(),
              },
              volume: {
                altitude_lower: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(floor, 10),
                },
                altitude_upper: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(wgs84Elevation, 10),
                },
                outline_polygon: {
                  coordinates: outlinePolygonCoordinates,
                  type: "Polygon",
                },
              },
            },
          ],
          old_version: 0,
          uss_base_url,
          name,
          description,
          rule,
          prohibited_conditions: {
            // Required only if rule.authorisation_required is true
            whitelist: {
              // Required only if rule.prohibited is true
              users: personNameWhitelistUsers,
              usergroups: personNameWhitelistGroups,
            },
            time_start: {
              // (optional)
              format: "RFC3339",
              value: departureTimeProhibitCon.toISOString(),
            },
            time_end: {
              // (optional)
              format: "RFC3339",
              value: endTimeProhibitCon.toISOString(),
            },
          },
          authorisation_conditions: {
            // Required only if rule.authorisation_required is true
            whitelist: {
              // Required only if rule.prohibited is true
              users: authorisationPersonNameWhitelistUsers,
              usergroups: authorisationPersonNameWhitelistGroups,
            },
            time_start: {
              // (optional)
              format: "RFC3339",
              value: departureTimeAuthCon.toISOString(),
            },
            time_end: {
              // (optional)
              format: "RFC3339",
              value: endTimeAuthCon.toISOString(),
            },
          },
          conditions: {
            // Required only if rule.conditions is true
            noise_threshold: parseInt(threshold, 10),
            no_camera: nocameratehetered.no_camera,
            power_tethered: nocameratehetered.power_tethered,
            others,
          },
          constraint_owner: username,
        });
        if (response.data) {
          setSubmitLoading(false);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: response.data.message,
              error: "success",
            })
          );
          dispatch(
            setEditConstraint({
              isEdit: false,
            })
          );
          if (selectWaypointMethod === "area") {
            setSelectedAreaWaypoints([
              [0.0, 0.0],
              [0.0, 0.0],
              [0.0, 0.0],
            ]);
          } else if (selectWaypointMethod === "circle") {
            setSelectedCircleWaypoints([
              [0.0, 0.0],
              [0.0, 0.0],
            ]);
          }
          setCeiling(0);
          mapboxController.removeDrawFeaturesAll();
          handleDiscardEdit();
        }
      } catch (err) {
        setSubmitLoading(false);
        // console.log("error", err);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: err.response.data.message,
            severity: "error",
          })
        );
      }
    }
    handleOpenConstrainMgrAuthority();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const wgs84Elevation =
      altitude_reference === "AGL" ? floor + ceiling : ceiling;

    setSubmitLoading(true);
    const dateNow = new Date(departureTime);
    const temporaryEndtime = dateNow.setTime(dateNow.getTime() + 600 * 60000);
    /**
     * Temporary for the Demo the time_end use static endtime start_time now + 10 Hours
     * @param {*} endTime state change to temporary  @param {*} temporaryEndtime
     */
    const haskey = Object.keys(editConstraints).some(
      (key) => key === "constraint_id"
    );
    if (haskey) {
      // if (
      //   selectWaypointMethod === "area" ||
      //   selectWaypointMethod === "circle"
      // ) {
      let outlinePolygonCoordinates = null;
      if (selectWaypointMethod === "area") {
        outlinePolygonCoordinates = [selectedAreaWaypoints];
      } else if (selectWaypointMethod === "circle") {
        outlinePolygonCoordinates = [selectedCircleWaypoints];
      }
      if (outlinePolygonCoordinates === null) {
        // need to handle case if coordinates is invalid?
        return;
      }
      const { time_start, time_end } = editConstraints;
      const constraintid = editConstraints.constraint_id;
      const oldVersion = editConstraints.old_version;
      if (selectTabIndexMethod === "static") {
        const prohibited_conditions = {
          // Required only if rule.authorisation_required is true
          whitelist: {
            // Required only if rule.prohibited is true
            users: personNameWhitelistUsers,
            usergroups: personNameWhitelistGroups,
          },
          time_start: {
            // (optional)
            format: "RFC3339",
            value: departureTimeProhibitCon.toISOString(),
          },
          time_end: {
            // (optional)
            format: "RFC3339",
            value: endTimeProhibitCon.toISOString(),
          },
        };

        if (prohibitConRecurringCheck.recurring_Chek) {
          prohibited_conditions.time_start = {
            // (optional)
            format: "RFC3339",
            value: prohibitConDepartureTimeRequiring.toISOString(),
          };
          prohibited_conditions.time_end = {
            // (optional)
            format: "RFC3339",
            value: prohibitConEndTimeRequiring.toISOString(),
          };

          prohibited_conditions.recurring = prohibitConValueTimeThick;

          prohibited_conditions.recurrence_range = {
            time_start: {
              // (optional)
              format: "RFC3339",
              value: departureTimeProhibitCon.toISOString(),
            },
            time_end: {
              // (optional)
              format: "RFC3339",
              value: endTimeProhibitCon.toISOString(),
            },
          };
        }

        const authorisation_conditions = {
          whitelist: {
            // Required only if rule.prohibited is true
            users: authorisationPersonNameWhitelistUsers,
            usergroups: authorisationPersonNameWhitelistGroups,
          },
          time_start: {
            // (optional)
            format: "RFC3339",
            value: departureTimeAuthCon.toISOString(),
          },
          time_end: {
            // (optional)
            format: "RFC3339",
            value: endTimeAuthCon.toISOString(),
          },
        };

        if (authConRecurringCheck.recurring_Chek) {
          authorisation_conditions.time_start = {
            // (optional)
            format: "RFC3339",
            value: authConDepartureTimeRequiring.toISOString(),
          };
          authorisation_conditions.time_end = {
            // (optional)
            format: "RFC3339",
            value: authConEndTimeRequiring.toISOString(),
          };

          authorisation_conditions.recurring = prohibitConValueTimeThick;

          authorisation_conditions.recurrence_range = {
            time_start: {
              // (optional)
              format: "RFC3339",
              value: departureTimeAuthCon.toISOString(),
            },
            time_end: {
              // (optional)
              format: "RFC3339",
              value: endTimeAuthCon.toISOString(),
            },
          };
        }

        const withoutRequringUpdated = {
          constraint_id: constraintid,
          extents: [
            {
              time_end: {
                format: "RFC3339",
                value: endTime.toISOString(),
              },
              time_start: {
                format: "RFC3339",
                value: departureTime.toISOString(),
              },
              volume: {
                altitude_lower: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(floor, 10),
                },
                altitude_upper: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(wgs84Elevation, 10),
                },
                outline_polygon: {
                  coordinates: outlinePolygonCoordinates,
                  type: "Polygon",
                },
              },
            },
          ],
          old_version: parseInt(oldVersion, 10),
          uss_base_url,
          name,
          description,
          rule,
          time_start,
          time_end,
          prohibited_conditions,
          authorisation_conditions,
          conditions: {
            // Required only if rule.conditions is true
            noise_threshold: parseInt(threshold, 10),
            no_camera: nocameratehetered.no_camera,
            power_tethered: nocameratehetered.power_tethered,
            others,
          },
          constraint_owner: username,
        };

        const withRequringUpdated = {
          constraint_id: constraintid,
          extents: [
            {
              time_end: {
                format: "RFC3339",
                value: endTime.toISOString(),
              },
              time_start: {
                format: "RFC3339",
                value: departureTime.toISOString(),
              },
              volume: {
                altitude_lower: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(floor, 10),
                },
                altitude_upper: {
                  reference: "W84",
                  units: "M",
                  value: parseInt(wgs84Elevation, 10),
                },
                outline_polygon: {
                  coordinates: outlinePolygonCoordinates,
                  type: "Polygon",
                },
              },
            },
          ],
          old_version: parseInt(oldVersion, 10),
          uss_base_url,
          name,
          description,
          rule,
          prohibited_conditions,
          authorisation_conditions,
          conditions: {
            // Required only if rule.conditions is true
            noise_threshold: parseInt(threshold, 10),
            no_camera: nocameratehetered.no_camera,
            power_tethered: nocameratehetered.power_tethered,
            others,
          },
          constraint_owner: username,
          recurring: valueTimeThick,
          recurrence_range: {
            time_start: {
              format: "RFC3339",
              value: departureTime.toISOString(),
            },
            time_end: {
              format: "RFC3339",
              value: endTime.toISOString(),
            },
          },
        };

        if (valueTimeThick) {
          withRequringUpdated.extents[0].time_end.value =
            endTimeRequiring.toISOString();
          withRequringUpdated.extents[0].time_start.value =
            departureTimeRequiring.toISOString();
        }
        // const withRequringUpdated = {
        //   constraint_id: constraintid,
        //   extents: [
        //     {
        //       time_end: {
        //         format: "RFC3339",
        //         value: endTime.toISOString(),
        //       },
        //       time_start: {
        //         format: "RFC3339",
        //         value: departureTime.toISOString(),
        //       },
        //       volume: {
        //         altitude_lower: {
        //           reference: "W84",
        //           units: "M",
        //           value: parseInt(floor, 10),
        //         },
        //         altitude_upper: {
        //           reference: "W84",
        //           units: "M",
        //           value: parseInt(wgs84Elevation, 10),
        //         },
        //         outline_polygon: {
        //           coordinates: outlinePolygonCoordinates,
        //           type: "Polygon",
        //         },
        //       },
        //     },
        //   ],
        //   old_version: parseInt(oldVersion, 10),
        //   uss_base_url,
        //   name,
        //   description,
        //   rule,
        //   time_start,
        //   time_end,
        //   prohibited_conditions: {
        //     // Required only if rule.authorisation_required is true
        //     whitelist: {
        //       // Required only if rule.prohibited is true
        //       users: personNameWhitelistUsers,
        //       usergroups: personNameWhitelistGroups,
        //     },
        //     time_start: {
        //       // (optional)
        //       format: "RFC3339",
        //       value: departureTimeProhibitCon.toISOString(),
        //     },
        //     time_end: {
        //       // (optional)
        //       format: "RFC3339",
        //       value: endTimeProhibitCon.toISOString(),
        //     },
        //   },
        //   authorisation_conditions: {
        //     // Required only if rule.authorisation_required is true
        //     whitelist: {
        //       // Required only if rule.prohibited is true
        //       users: authorisationPersonNameWhitelistUsers,
        //       usergroups: authorisationPersonNameWhitelistGroups,
        //     },
        //     time_start: {
        //       // (optional)
        //       format: "RFC3339",
        //       value: departureTimeAuthCon.toISOString(),
        //     },
        //     time_end: {
        //       // (optional)
        //       format: "RFC3339",
        //       value: endTimeAuthCon.toISOString(),
        //     },
        //   },
        //   conditions: {
        //     // Required only if rule.conditions is true
        //     noise_threshold: parseInt(threshold, 10),
        //     no_camera: nocameratehetered.no_camera,
        //     power_tethered: nocameratehetered.power_tethered,
        //     others,
        //   },
        //   constraint_owner: username,
        //   recurring: valueTimeThick,
        //   recurrence_range: {
        //     time_start: {
        //       format: "RFC3339",
        //       value: departureTimeRequiring.toISOString(),
        //     },
        //     time_end: {
        //       format: "RFC3339",
        //       value: endTimeRequiring.toISOString(),
        //     },
        //   },
        // };
        try {
          const response = await api.submitUpdateAuthorityAreaConstrainPerm(
            recurringCheck?.recurring_Chek
              ? withRequringUpdated
              : withoutRequringUpdated
          );

          if (response.data) {
            setSubmitLoading(false);
            dispatch(
              setSnackbarMessage({
                open: true,
                message: response.data.message,
                error: "success",
              })
            );
            dispatch(
              setEditConstraint({
                isEdit: false,
                data: editConstraints,
              })
            );
            if (selectWaypointMethod === "area") {
              setSelectedAreaWaypoints([
                [0.0, 0.0],
                [0.0, 0.0],
                [0.0, 0.0],
              ]);
            } else if (selectWaypointMethod === "circle") {
              setSelectedCircleWaypoints([
                [0.0, 0.0],
                [0.0, 0.0],
              ]);
            }
            mapboxController.removeDrawFeaturesAll();
            handleDiscardEdit();
          }
        } catch (err) {
          setSubmitLoading(false);
          console.log("error", err);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "error update constraint",
              severity: "error",
            })
          );
        }
      } else {
        try {
          const response = await api.submitUpdateAuthorityAreaConstrain({
            constraint_id: constraintid,
            extents: [
              {
                time_end: {
                  format: "RFC3339",
                  value: endTime.toISOString(),
                },
                time_start: {
                  format: "RFC3339",
                  value: departureTime.toISOString(),
                },
                volume: {
                  altitude_lower: {
                    reference: "W84",
                    units: "M",
                    value: parseInt(floor, 10),
                  },
                  altitude_upper: {
                    reference: "W84",
                    units: "M",
                    value: parseInt(wgs84Elevation, 10),
                  },
                  outline_polygon: {
                    coordinates: outlinePolygonCoordinates,
                    type: "Polygon",
                  },
                },
              },
            ],
            old_version: parseInt(oldVersion, 10),
            uss_base_url,
            name,
            description,
            rule,
            time_start,
            time_end,
            prohibited_conditions: {
              // Required only if rule.authorisation_required is true
              whitelist: {
                // Required only if rule.prohibited is true
                users: personNameWhitelistUsers,
                usergroups: personNameWhitelistGroups,
              },
              time_start: {
                // (optional)
                format: "RFC3339",
                value: departureTimeProhibitCon.toISOString(),
              },
              time_end: {
                // (optional)
                format: "RFC3339",
                value: endTimeProhibitCon.toISOString(),
              },
            },
            authorisation_conditions: {
              // Required only if rule.authorisation_required is true
              whitelist: {
                // Required only if rule.prohibited is true
                users: authorisationPersonNameWhitelistUsers,
                usergroups: authorisationPersonNameWhitelistGroups,
              },
              time_start: {
                // (optional)
                format: "RFC3339",
                value: departureTimeAuthCon.toISOString(),
              },
              time_end: {
                // (optional)
                format: "RFC3339",
                value: endTimeAuthCon.toISOString(),
              },
            },
            conditions: {
              // Required only if rule.conditions is true
              noise_threshold: parseInt(threshold, 10),
              no_camera: nocameratehetered.no_camera,
              power_tethered: nocameratehetered.power_tethered,
              others,
            },
            constraint_owner: username,
          });

          if (response.data) {
            setSubmitLoading(false);
            dispatch(
              setSnackbarMessage({
                open: true,
                message: response.data.message,
                error: "success",
              })
            );
            dispatch(
              setEditConstraint({
                isEdit: false,
                data: editConstraints,
              })
            );
            if (selectWaypointMethod === "area") {
              setSelectedAreaWaypoints([
                [0.0, 0.0],
                [0.0, 0.0],
                [0.0, 0.0],
              ]);
            } else if (selectWaypointMethod === "circle") {
              setSelectedCircleWaypoints([
                [0.0, 0.0],
                [0.0, 0.0],
              ]);
            }
            mapboxController.removeDrawFeaturesAll();
            handleDiscardEdit();
          }
        } catch (err) {
          setSubmitLoading(false);
          console.log("error", err);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "error update constraint",
              severity: "error",
            })
          );
        }
      }
    }
  };
  const handleOnFocus = async (center) => {
    // mapViewController.center = center;
    mapboxController.flyTo(center);
  };

  const handleArea = (polygonArea) => {
    if (polygonArea.length < 4) {
      return;
    }

    try {
      const polygon = turfPolygon([polygonArea]);
      const areaSQM = area(polygon);
      setLargeArea(areaSQM);
    } catch (err) {
      console.log("turfPolygon error: ", err);
    }
  };
  useEffect(() => {
    if (selectedAreaWaypoints.length && selectedAreaWaypoints.length > 2) {
      setSelectedAreaWaypoints(selectedAreaWaypoints);
      handleArea(selectedAreaWaypoints);
      const selectedAreaWaypointsCenter = computePolygonCenter(
        selectedAreaWaypoints
      );
      if (selectedAreaWaypointsCenter[1] && selectedAreaWaypointsCenter[0]) {
        const selectedAreaWaypointsElevation = Math.round(
          mapboxController?.map.queryTerrainElevation({
            lat: selectedAreaWaypointsCenter[1],
            lng: selectedAreaWaypointsCenter[0],
          })
        );
        setFloor(selectedAreaWaypointsElevation);
        setCeiling(selectedAreaWaypointsElevation + 100);
        setAltitude_reference("W84");
      }
    }
  }, [selectedAreaWaypoints]);

  // useEffect(() => {
  //   if (selectedCircleWaypoints.length) {
  //     // setSelectedAreaPolgon(_selectedCircleWaypoints);
  //     setSelectedCircleWaypoints(selectedCircleWaypoints);
  //     setSelectedCircleProperties(selectedCircleProperties);
  //     handleArea(selectedCircleWaypoints);
  //     const selectedCircleWaypointsCenter = computeCircleCenter(
  //       selectedCircleWaypoints
  //     );
  //     const selectedCircleWaypointsElevation = Math.round(
  //       mapboxController.map.queryTerrainElevation({
  //         lat: selectedCircleWaypointsCenter[1],
  //         lng: selectedCircleWaypointsCenter[0],
  //       })
  //     );
  //     setFloor(selectedCircleWaypointsElevation);
  //     setCeiling(selectedCircleWaypointsElevation + 100);
  //     setAltitude_reference("W84");
  //   }
  // }, [selectedCircleWaypoints, selectedCircleProperties]);

  // change Elevation when coordinates are changed
  useEffect(() => {
    // if (selectedCircleProperties && selectedCircleWaypoints.length > 62) {
    if (selectedCircleWaypoints.length > 62) {
      handleArea(selectedCircleWaypoints);
      const selectedCircleWaypointsCenter = computeCircleCenter(
        selectedCircleWaypoints
      );
      if (
        selectedCircleWaypointsCenter[1] &&
        selectedCircleWaypointsCenter[0]
      ) {
        const selectedCircleWaypointsElevation = Math.round(
          mapboxController?.map.queryTerrainElevation({
            lat: selectedCircleWaypointsCenter[1],
            lng: selectedCircleWaypointsCenter[0],
          })
        );
        setFloor(selectedCircleWaypointsElevation);
        setCeiling(selectedCircleWaypointsElevation + 100);
        setAltitude_reference("W84");
      }
    }
  }, [selectedCircleWaypoints]);

  const resetForm = () => {
    const newDepartureTime = new Date();
    setDepartureTime(new Date(newDepartureTime.getTime() + 3 * 60000));
    setEndTime(new Date(newDepartureTime.getTime() + 33 * 60000));
    if (selectWaypointMethod === "area") {
      setSelectedAreaWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
    } else if (selectWaypointMethod === "circle") {
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: true,
        center: [0.0, 0.0],
        radiusInKm: 0,
      });
    }
    // onUpdateSelectedAreaWaypoints([
    //   [0.0, 0.0],
    //   [0.0, 0.0],
    //   [0.0, 0.0],
    // ]);

    setName("");
    setDescription("");
    setDepartureTimeAuthCon(new Date(newDepartureTime.getTime() + 3 * 60000));
    setEndTimeAuthCon(new Date(newDepartureTime.getTime() + 33 * 60000));
    setDepartureTimeProhibitCon(
      new Date(new Date(newDepartureTime.getTime() + 3 * 60000))
    );
    setEndTimeProhibitCon(
      new Date(new Date(newDepartureTime.getTime() + 33 * 60000))
    );
    setCeiling(100);
    setFloor(0);
    setLargeArea(0);
    setRule({
      prohibited: false,
      authorisation_required: false,
      conditional: false,
      information_only: false,
    });
    setThreshold(40);
    setOthers("");
    setnNoCameraThetered({
      no_camera: true,
      power_tethered: true,
    });
    setPersonNameWhitelistUsers([]);
    setPersonNameWhitelistGroups([]);
    setAuthorisationPersonNameWhitelistUsers([]);
    setAuthorisationPersonNameWhitelistGroups([]);
    setRecurringChek({
      recurring_Chek: false,
    });
    setAuthConRecurringChek({
      recurring_Chek: false,
    });
    setProhibitConRecurringChek({
      recurring_Chek: false,
    });
    setDepartureTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 3 * 60000))
    );
    setEndTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 33 * 60000))
    );
    setAuthConDepartureTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 3 * 60000))
    );
    setAuthConEndTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 33 * 60000))
    );
    setProhibitConDepartureTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 3 * 60000))
    );
    setProhibitConEndTimeRequiring(
      new Date(new Date(newDepartureTime.getTime() + 33 * 60000))
    );
    setvalueTimeThick("daily");
    setAuthConValueTimeThick("daily");
    setProhibitConValueTimeThick("daily");
  };
  const handleDiscardEdit = () => {
    resetForm();
    setIfEditMode(false);
    dispatch(
      setEditConstraint({
        isEdit: false,
        data: editConstraints,
      })
    );
  };
  useEffect(() => {
    setIfEditMode(false);
    resetForm();
    if (mapboxController) mapboxController.removeDrawFeaturesAll();
    if (!editConstraints?.constraint_id) {
      return;
    }
    if (!editConstraintsData.isEdit) return;
    setIfEditMode(true);
    const constraint = editConstraints;
    setName(constraint?.name);
    setDescription(constraint?.description);
    setDepartureTime(new Date(constraint.extents[0].time_start.value));
    setEndTime(new Date(constraint.extents[0].time_end.value));
    setCeiling(constraint.extents[0].volume.altitude_upper.value);

    setRule(constraint?.rule);
    setThreshold(constraint?.conditions?.noise_threshold);
    setOthers(constraint?.conditions?.others);
    setnNoCameraThetered({
      no_camera: constraint?.conditions?.no_camera,
      power_tethered: constraint?.conditions?.power_tethered,
    });
    setPersonNameWhitelistUsers(
      constraint?.prohibited_conditions?.whitelist?.users
    );
    setPersonNameWhitelistGroups(
      constraint?.prohibited_conditions?.whitelist?.usergroups
    );
    setAuthorisationPersonNameWhitelistUsers(
      constraint?.authorisation_conditions?.whitelist?.users
    );
    setAuthorisationPersonNameWhitelistGroups(
      constraint?.authorisation_conditions?.whitelist?.usergroups
    );

    if (constraint?.rule?.authorisation_required) {
      setDepartureTimeAuthCon(
        new Date(constraint?.authorisation_conditions?.time_start?.value)
      );
      setEndTimeAuthCon(
        new Date(constraint?.authorisation_conditions?.time_end?.value)
      );
    }

    if (constraint?.rule?.prohibited) {
      setDepartureTimeProhibitCon(
        new Date(constraint?.prohibited_conditions?.time_start?.value)
      );
      setEndTimeProhibitCon(
        new Date(constraint?.prohibited_conditions?.time_end?.value)
      );
    }

    if (constraint?.authorisation_conditions?.recurring) {
      setAuthConValueTimeThick(constraint?.authorisation_conditions?.recurring);
      setAuthConDepartureTimeRequiring(
        new Date(constraint?.authorisation_conditions?.time_start?.value)
      );
      setAuthConEndTimeRequiring(
        new Date(constraint?.authorisation_conditions?.time_end?.value)
      );
      setAuthConRecurringChek({
        recurring_Chek: true,
      });
      setDepartureTimeAuthCon(
        new Date(
          constraint?.authorisation_conditions?.recurrence_range?.time_start?.value
        )
      );
      setEndTimeAuthCon(
        new Date(
          constraint?.authorisation_conditions?.recurrence_range?.time_end?.value
        )
      );
    }

    if (constraint?.prohibited_conditions?.recurring) {
      setProhibitConValueTimeThick(
        constraint?.prohibited_conditions?.recurring
      );
      setProhibitConDepartureTimeRequiring(
        new Date(constraint?.prohibited_conditions?.time_start?.value)
      );
      setProhibitConEndTimeRequiring(
        new Date(constraint?.prohibited_conditions?.time_end?.value)
      );
      setProhibitConRecurringChek({
        recurring_Chek: true,
      });
      setDepartureTimeProhibitCon(
        new Date(
          constraint?.prohibited_conditions?.recurrence_range?.time_start?.value
        )
      );
      setEndTimeProhibitCon(
        new Date(
          constraint?.prohibited_conditions?.recurrence_range?.time_end?.value
        )
      );
    }
    if (constraint?.constraint_type === "LTC") {
      const checkRecurringExist = "recurring" in constraint;
      if (checkRecurringExist) {
        setRecurringChek({
          recurring_Chek: true,
        });
        setDepartureTime(
          new Date(constraint?.recurrence_range?.time_start?.value)
        );
        setEndTime(new Date(constraint?.recurrence_range?.time_end?.value));

        setDepartureTimeRequiring(
          new Date(constraint?.extents[0]?.time_start?.value)
        );
        setEndTimeRequiring(new Date(constraint?.extents[0]?.time_end?.value));
        setvalueTimeThick(constraint?.recurring);
      }

      setSelectTabIndexMethod("static");
      setTabIndexM(1);
    } else {
      setSelectTabIndexMethod("dynamic");
      setTabIndexM(0);
    }
    const countCoordinate =
      constraint.extents[0].volume.outline_polygon.coordinates[0].length;
    /**
     * Parses constraints
     * @param {*} countCoordinate Count to make a diffrent between circle and polygon
     * @returns Constraints Circle Category
     */
    if (countCoordinate > 60) {
      const polygon = turfPolygon([
        constraint.extents[0].volume.outline_polygon.coordinates[0],
      ]);
      const centroidS = centroid(polygon);
      const center = centroidS;
      const lineS = turfLineString([
        center.geometry.coordinates,
        constraint.extents[0].volume.outline_polygon.coordinates[0][0],
      ]);
      const lengthS = length(lineS, { units: "kilometers" });
      const circleProps = {
        isCircle: true,
        center: center.geometry.coordinates,
        radiusInKm: lengthS,
      };
      setSelectedAreaWaypoints(
        constraint.extents[0].volume.outline_polygon.coordinates[0]
      );
      // onUpdateSelectedCircleWaypoints(
      //   constraint.extents[0].volume.outline_polygon.coordinates[0]
      // );
      // onUpdateSelectedCircleProperties(circleProps);
      setSelectedCircleProperties(circleProps);
      setSelectWaypointMethod("circle");
      handleArea(constraint.extents[0].volume.outline_polygon.coordinates[0]);
      setTabIndex(0);
      return;
    }
    setSelectedAreaWaypoints(
      constraint.extents[0].volume.outline_polygon.coordinates[0]
    );

    setSelectWaypointMethod("area");
    handleArea(constraint.extents[0].volume.outline_polygon.coordinates[0]);
    setTabIndex(1);
  }, [editConstraints]);

  useEffect(() => {
    setCeiling(100);
    const getPilots = async () => {
      const res = await api.getPilots();
      setWhitelistUser(res.data);
    };
    getPilots();
    const getAssignedOperators = async () => {
      const res = await api.getAssignedOperators();
      setWhitelistUserGroup(res.data);
    };
    getAssignedOperators();
    // eslint-disable-next-line no-shadow
    const getOperators = async () => {
      const res = await api.getOperators();
      const ArrayOperators = res?.data.map(async (item) => {
        const split = item?.operatorName.split(":").reverse();
        const res2Data = await api.getOperatorsUsers(split[0]);
        const dataNested = {
          id: Math.random(),
          operatorName: split[0],
          operatorUser: res2Data?.data?.data,
        };
        return dataNested;
      });
      const allUserOperators = await Promise.all(ArrayOperators);
      setWhitelistUserNested(allUserOperators);
    };
    getOperators();
  }, []);

  const handleAuthConDepartureTimeChange = (item) => {
    setAuthConDepartureTimeRequiring(new Date(item));
    setAuthConDepartureTimeErrorRequiring("");
  };

  const handleAuthConEndTimeChange = (item) => {
    setAuthConEndTimeRequiring(new Date(item));
    setAuthConEndTimeErrorRequiring("");
  };

  const handleProhibitConDepartureTimeChange = (item) => {
    setProhibitConDepartureTimeRequiring(new Date(item));
    setProhibitConDepartureTimeErrorRequiring("");
  };

  const handleProhibitConEndTimeChange = (item) => {
    setProhibitConEndTimeRequiring(new Date(item));
    setProhibitConEndTimeErrorRequiring("");
  };

  const handleDepartureTimeRequiringChange = (item) => {
    setDepartureTimeRequiring(new Date(item));
    setDepartureTimeErrorRequiring("");
  };

  const handleEndTimeRequiringChange = (item) => {
    setEndTimeRequiring(new Date(item));
    setEndTimeErrorRequiring("");
  };

  const handleDepartureTimeChange = (item) => {
    setDepartureTime(new Date(item));
    setDepartureTimeError("");
    // set Prohibit and Auth Req timing when change start and end time
    setDepartureTimeProhibitCon(new Date(item));
    setDepartureTimeAuthCon(new Date(item));
  };

  const handleEndTimeChange = (item) => {
    setEndTime(new Date(item));
    setEndTimeError("");
    // set Prohibit and Auth Req timing when change start and end time
    setEndTimeProhibitCon(new Date(item));
    setEndTimeAuthCon(new Date(item));
  };
  const onSelectWaypointMethod = (label) => {
    setSelectWaypointMethod(label);
  };
  const handleAltRefChange = (altitudeType) => {
    if (altitudeType === "W84") {
      setCeiling((prev) => prev + floor);
    } else if (altitudeType === "AGL") {
      setCeiling((prev) => prev - floor);
    }
  };

  useEffect(() => {
    if (isInEditMode) {
      dispatch(
        setEditConstraint({
          isEdit: true,
        })
      );
      return;
    }
    if (!openConstrainMgrAuthority) {
      return;
    }
    if (endTime < currentTime) {
      setEndTime(new Date(currentTime.getTime() + 33 * 60000));
    }
    // setDepartureTime(new Date(currentTime.getTime() + 3 * 60000));
    // setEndTime(new Date(currentTime.getTime() + 33 * 60000));
  }, [openConstrainMgrAuthority]);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={editConstraintsData.isEdit ? handleUpdate : handleSubmit}
    >
      <LoadingOverlay
        active={submitLoading}
        spinner
        text="Submitting Constraint..."
      >
        <div>
          <Tabs variant="scrollable" value={tabIndexM} onChange={onTabMClicked}>
            <Tab label="Create Temp Constraint" />
            <Tab label="Create Perm Constraint" />
          </Tabs>

          {/* TEMP CONSTRAINT */}
          <Panel value={tabIndexM} index={0}>
            <Typography variant="subtitle1" component="div">
              Select static setup methods:
            </Typography>
            <Tabs variant="scrollable" value={tabIndex} onChange={onTabClicked}>
              <Tab label="Circle" />
              <Tab label="Polygon" />
            </Tabs>
            {/* Circle */}
            <Panel value={tabIndex} index={0}>
              <CreateConstraintAuthorityForm
                isCircleEntries
                selectedCircleWaypoints={selectedCircleWaypoints}
                circleEntriesComponent={
                  <CircleEntries
                    onFocus={handleOnFocus}
                    selectedCircleWaypoints={selectedCircleWaypoints}
                    selectedCircleProperties={selectedCircleProperties}
                    setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                    setSelectedCircleProperties={setSelectedCircleProperties}
                    handleOpenConstrainMgrAuthority={
                      handleOpenConstrainMgrAuthority
                    }
                    // onChange={(updatedWaypoints, updatedProperties) => {
                    //   setSelectedCircleWaypoints(updatedWaypoints);
                    //   onUpdateSelectedCircleWaypoints(updatedWaypoints);
                    //   onUpdateSelectedCircleProperties(updatedProperties);
                    // }}
                  />
                }
                ruleComponent={
                  <Rule
                    rule={rule}
                    setRule={setRule}
                    whitelistUser={whitelistUser}
                    setWhitelistUser={setWhitelistUser}
                    whitelistUserNested={whitelistUserNested}
                    setWhitelistUserNested={setWhitelistUserNested}
                    whitelistUserGroup={whitelistUserGroup}
                    setWhitelistUserGroup={setWhitelistUserGroup}
                    threshold={threshold}
                    setThreshold={setThreshold}
                    others={others}
                    setOthers={setOthers}
                    nocameratehetered={nocameratehetered}
                    setnNoCameraThetered={setnNoCameraThetered}
                    departureTimeAuthCon={departureTimeAuthCon}
                    setDepartureTimeAuthCon={setDepartureTimeAuthCon}
                    departureTimeErrorAuthCon={departureTimeErrorAuthCon}
                    endTimeAuthCon={endTimeAuthCon}
                    setEndTimeAuthCon={setEndTimeAuthCon}
                    endTimeErrorAuthCon={endTimeErrorAuthCon}
                    setEndTimeErrorAuthCon={setEndTimeErrorAuthCon}
                    setDepartureTimeErrorAuthCon={setDepartureTimeErrorAuthCon}
                    departureTimeProhibitCon={departureTimeProhibitCon}
                    setDepartureTimeProhibitCon={setDepartureTimeProhibitCon}
                    departureTimeErrorProhibitCon={
                      departureTimeErrorProhibitCon
                    }
                    setDepartureTimeErrorProhibitCon={
                      setDepartureTimeErrorProhibitCon
                    }
                    endTimeProhibitCon={endTimeProhibitCon}
                    setEndTimeProhibitCon={setEndTimeProhibitCon}
                    endTimeErrorProhibitCon={endTimeErrorProhibitCon}
                    setEndTimeErrorProhibitCon={setEndTimeErrorProhibitCon}
                    personNameWhitelistUsers={personNameWhitelistUsers}
                    setPersonNameWhitelistUsers={setPersonNameWhitelistUsers}
                    personNameWhitelistGroups={personNameWhitelistGroups}
                    setPersonNameWhitelistGroups={setPersonNameWhitelistGroups}
                    authorisationPersonNameWhitelistUsers={
                      authorisationPersonNameWhitelistUsers
                    }
                    setAuthorisationPersonNameWhitelistUsers={
                      setAuthorisationPersonNameWhitelistUsers
                    }
                    authorisationPersonNameWhitelistGroups={
                      authorisationPersonNameWhitelistGroups
                    }
                    setAuthorisationPersonNameWhitelistGroups={
                      setAuthorisationPersonNameWhitelistGroups
                    }
                  />
                }
                selectedAreaWaypoints={selectedAreaWaypoints}
                editConstraintsData={editConstraintsData}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                departureTime={departureTime}
                departureTimeError={departureTimeError}
                handleDepartureTimeChange={handleDepartureTimeChange}
                endTime={endTime}
                endTimeError={endTimeError}
                handleEndTimeChange={handleEndTimeChange}
                altitude_reference={altitude_reference}
                setAltitude_reference={setAltitude_reference}
                handleAltRefChange={handleAltRefChange}
                floor={floor}
                setFloor={setFloor}
                ceiling={ceiling}
                setCeiling={setCeiling}
                mapboxController={mapboxController}
                isInEditMode={isInEditMode}
                handleDiscardEdit={handleDiscardEdit}
                largeArea={largeArea}
                classesFloating={classesFloating}
                handleOpenConstrainMgrAuthority={
                  handleOpenConstrainMgrAuthority
                }
                resetForm={resetForm}
              />
            </Panel>
            {/* Area */}
            <Panel value={tabIndex} index={1}>
              <CreateConstraintAuthorityForm
                isAreaEntries
                areaEntriesComponent={
                  <AreaEntries
                    onFocus={handleOnFocus}
                    selectedAreaWaypoints={selectedAreaWaypoints}
                    onChange={(updatedWaypoints) => {
                      setSelectedAreaWaypoints(updatedWaypoints);
                      // onUpdateSelectedAreaWaypoints(updatedWaypoints);
                    }}
                    handleOpenConstrainMgrAuthority={
                      handleOpenConstrainMgrAuthority
                    }
                  />
                }
                ruleComponent={
                  <Rule
                    rule={rule}
                    setRule={setRule}
                    whitelistUser={whitelistUser}
                    setWhitelistUser={setWhitelistUser}
                    whitelistUserNested={whitelistUserNested}
                    setWhitelistUserNested={setWhitelistUserNested}
                    whitelistUserGroup={whitelistUserGroup}
                    setWhitelistUserGroup={setWhitelistUserGroup}
                    threshold={threshold}
                    setThreshold={setThreshold}
                    others={others}
                    setOthers={setOthers}
                    nocameratehetered={nocameratehetered}
                    setnNoCameraThetered={setnNoCameraThetered}
                    departureTimeAuthCon={departureTimeAuthCon}
                    setDepartureTimeAuthCon={setDepartureTimeAuthCon}
                    departureTimeErrorAuthCon={departureTimeErrorAuthCon}
                    setDepartureTimeErrorAuthCon={setDepartureTimeErrorAuthCon}
                    endTimeAuthCon={endTimeAuthCon}
                    setEndTimeAuthCon={setEndTimeAuthCon}
                    endTimeErrorAuthCon={endTimeErrorAuthCon}
                    setEndTimeErrorAuthCon={setEndTimeErrorAuthCon}
                    departureTimeProhibitCon={departureTimeProhibitCon}
                    setDepartureTimeProhibitCon={setDepartureTimeProhibitCon}
                    departureTimeErrorProhibitCon={
                      departureTimeErrorProhibitCon
                    }
                    setDepartureTimeErrorProhibitCon={
                      setDepartureTimeErrorProhibitCon
                    }
                    endTimeProhibitCon={endTimeProhibitCon}
                    setEndTimeProhibitCon={setEndTimeProhibitCon}
                    endTimeErrorProhibitCon={endTimeErrorProhibitCon}
                    setEndTimeErrorProhibitCon={setEndTimeErrorProhibitCon}
                    personNameWhitelistUsers={personNameWhitelistUsers}
                    setPersonNameWhitelistUsers={setPersonNameWhitelistUsers}
                    personNameWhitelistGroups={personNameWhitelistGroups}
                    setPersonNameWhitelistGroups={setPersonNameWhitelistGroups}
                    authorisationPersonNameWhitelistUsers={
                      authorisationPersonNameWhitelistUsers
                    }
                    setAuthorisationPersonNameWhitelistUsers={
                      setAuthorisationPersonNameWhitelistUsers
                    }
                    authorisationPersonNameWhitelistGroups={
                      authorisationPersonNameWhitelistGroups
                    }
                    setAuthorisationPersonNameWhitelistGroups={
                      setAuthorisationPersonNameWhitelistGroups
                    }
                  />
                }
                selectedAreaWaypoints={selectedAreaWaypoints}
                editConstraintsData={editConstraintsData}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                departureTime={departureTime}
                departureTimeError={departureTimeError}
                handleDepartureTimeChange={handleDepartureTimeChange}
                endTime={endTime}
                endTimeError={endTimeError}
                handleEndTimeChange={handleEndTimeChange}
                altitude_reference={altitude_reference}
                setAltitude_reference={setAltitude_reference}
                handleAltRefChange={handleAltRefChange}
                floor={floor}
                setFloor={setFloor}
                ceiling={ceiling}
                setCeiling={setCeiling}
                mapboxController={mapboxController}
                isInEditMode={isInEditMode}
                handleDiscardEdit={handleDiscardEdit}
                largeArea={largeArea}
                classesFloating={classesFloating}
                handleOpenConstrainMgrAuthority={
                  handleOpenConstrainMgrAuthority
                }
                resetForm={resetForm}
              />
            </Panel>
          </Panel>

          {/* *****PERM CONSTRAINT******** */}
          <Panel value={tabIndexM} index={1}>
            <Typography variant="subtitle1" component="div">
              Select dynamic setup methods:
            </Typography>
            <Tabs variant="scrollable" value={tabIndex} onChange={onTabClicked}>
              <Tab label="Circle" />
              <Tab label="Polygon" />
            </Tabs>
            {/* Circle */}
            <Panel value={tabIndex} index={0}>
              <CreateConstraintAuthorityForm
                isCircleEntries
                isRequiring
                selectedCircleWaypoints={selectedCircleWaypoints}
                recurringCheck={recurringCheck}
                requiringComponent={
                  <Requiring
                    valueTimeThick={valueTimeThick}
                    setvalueTimeThick={setvalueTimeThick}
                    departureTime={departureTimeRequiring}
                    departureTimeError={departureTimeErrorRequiring}
                    handleDepartureTimeChange={
                      handleDepartureTimeRequiringChange
                    }
                    endTime={endTimeRequiring}
                    endTimeError={endTimeErrorRequiring}
                    handleEndTimeChange={handleEndTimeRequiringChange}
                    recurringCheck={recurringCheck}
                    setRecurringChek={setRecurringChek}
                  />
                }
                circleEntriesComponent={
                  <CircleEntries
                    onFocus={handleOnFocus}
                    selectedCircleWaypoints={selectedCircleWaypoints}
                    selectedCircleProperties={selectedCircleProperties}
                    setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                    setSelectedCircleProperties={setSelectedCircleProperties}
                    handleOpenConstrainMgrAuthority={
                      handleOpenConstrainMgrAuthority
                    }
                    // onChange={(updatedWaypoints, updatedProperties) => {
                    //   setSelectedCircleWaypoints(updatedWaypoints);
                    //   onUpdateSelectedCircleWaypoints(updatedWaypoints);
                    //   onUpdateSelectedCircleProperties(updatedProperties);
                    // }}
                  />
                }
                ruleComponent={
                  <Rule
                    isRequiring
                    rule={rule}
                    setRule={setRule}
                    whitelistUser={whitelistUser}
                    setWhitelistUser={setWhitelistUser}
                    whitelistUserNested={whitelistUserNested}
                    setWhitelistUserNested={setWhitelistUserNested}
                    whitelistUserGroup={whitelistUserGroup}
                    setWhitelistUserGroup={setWhitelistUserGroup}
                    threshold={threshold}
                    setThreshold={setThreshold}
                    others={others}
                    setOthers={setOthers}
                    nocameratehetered={nocameratehetered}
                    setnNoCameraThetered={setnNoCameraThetered}
                    departureTimeAuthCon={departureTimeAuthCon}
                    setDepartureTimeAuthCon={setDepartureTimeAuthCon}
                    departureTimeErrorAuthCon={departureTimeErrorAuthCon}
                    setDepartureTimeErrorAuthCon={setDepartureTimeErrorAuthCon}
                    endTimeAuthCon={endTimeAuthCon}
                    setEndTimeAuthCon={setEndTimeAuthCon}
                    endTimeErrorAuthCon={endTimeErrorAuthCon}
                    setEndTimeErrorAuthCon={setEndTimeErrorAuthCon}
                    departureTimeProhibitCon={departureTimeProhibitCon}
                    setDepartureTimeProhibitCon={setDepartureTimeProhibitCon}
                    departureTimeErrorProhibitCon={
                      departureTimeErrorProhibitCon
                    }
                    setDepartureTimeErrorProhibitCon={
                      setDepartureTimeErrorProhibitCon
                    }
                    endTimeProhibitCon={endTimeProhibitCon}
                    setEndTimeProhibitCon={setEndTimeProhibitCon}
                    endTimeErrorProhibitCon={endTimeErrorProhibitCon}
                    setEndTimeErrorProhibitCon={setEndTimeErrorProhibitCon}
                    personNameWhitelistUsers={personNameWhitelistUsers}
                    setPersonNameWhitelistUsers={setPersonNameWhitelistUsers}
                    personNameWhitelistGroups={personNameWhitelistGroups}
                    setPersonNameWhitelistGroups={setPersonNameWhitelistGroups}
                    authorisationPersonNameWhitelistUsers={
                      authorisationPersonNameWhitelistUsers
                    }
                    setAuthorisationPersonNameWhitelistUsers={
                      setAuthorisationPersonNameWhitelistUsers
                    }
                    authorisationPersonNameWhitelistGroups={
                      authorisationPersonNameWhitelistGroups
                    }
                    setAuthorisationPersonNameWhitelistGroups={
                      setAuthorisationPersonNameWhitelistGroups
                    }
                    authConRequiringComponent={
                      <Requiring
                        valueTimeThick={authConValueTimeThick}
                        setvalueTimeThick={setAuthConValueTimeThick}
                        departureTime={authConDepartureTimeRequiring}
                        departureTimeError={authConDepartureTimeErrorRequiring}
                        handleDepartureTimeChange={
                          handleAuthConDepartureTimeChange
                        }
                        endTime={authConEndTimeRequiring}
                        endTimeError={authConEndTimeErrorRequiring}
                        handleEndTimeChange={handleAuthConEndTimeChange}
                        recurringCheck={authConRecurringCheck}
                        setRecurringChek={setAuthConRecurringChek}
                      />
                    }
                    prohibitConRequiringComponent={
                      <Requiring
                        valueTimeThick={prohibitConValueTimeThick}
                        setvalueTimeThick={setProhibitConValueTimeThick}
                        departureTime={prohibitConDepartureTimeRequiring}
                        departureTimeError={
                          prohibitConDepartureTimeErrorRequiring
                        }
                        handleDepartureTimeChange={
                          handleProhibitConDepartureTimeChange
                        }
                        endTime={prohibitConEndTimeRequiring}
                        endTimeError={prohibitConEndTimeErrorRequiring}
                        handleEndTimeChange={handleProhibitConEndTimeChange}
                        recurringCheck={prohibitConRecurringCheck}
                        setRecurringChek={setProhibitConRecurringChek}
                      />
                    }
                  />
                }
                selectedAreaWaypoints={selectedAreaWaypoints}
                editConstraintsData={editConstraintsData}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                departureTime={departureTime}
                departureTimeError={departureTimeError}
                handleDepartureTimeChange={handleDepartureTimeChange}
                endTime={endTime}
                endTimeError={endTimeError}
                handleEndTimeChange={handleEndTimeChange}
                altitude_reference={altitude_reference}
                setAltitude_reference={setAltitude_reference}
                handleAltRefChange={handleAltRefChange}
                floor={floor}
                setFloor={setFloor}
                ceiling={ceiling}
                setCeiling={setCeiling}
                mapboxController={mapboxController}
                isInEditMode={isInEditMode}
                handleDiscardEdit={handleDiscardEdit}
                largeArea={largeArea}
                classesFloating={classesFloating}
                handleOpenConstrainMgrAuthority={
                  handleOpenConstrainMgrAuthority
                }
                resetForm={resetForm}
              />
            </Panel>

            {/* Area */}
            <Panel value={tabIndex} index={1}>
              <CreateConstraintAuthorityForm
                isAreaEntries
                isRequiring
                recurringCheck={recurringCheck}
                requiringComponent={
                  <Requiring
                    valueTimeThick={valueTimeThick}
                    setvalueTimeThick={setvalueTimeThick}
                    departureTime={departureTimeRequiring}
                    departureTimeError={departureTimeErrorRequiring}
                    handleDepartureTimeChange={
                      handleDepartureTimeRequiringChange
                    }
                    endTime={endTimeRequiring}
                    endTimeError={endTimeErrorRequiring}
                    handleEndTimeChange={handleEndTimeRequiringChange}
                    recurringCheck={recurringCheck}
                    setRecurringChek={setRecurringChek}
                  />
                }
                areaEntriesComponent={
                  <AreaEntries
                    onFocus={handleOnFocus}
                    selectedAreaWaypoints={selectedAreaWaypoints}
                    onChange={(updatedWaypoints) => {
                      setSelectedAreaWaypoints(updatedWaypoints);
                      // onUpdateSelectedAreaWaypoints(updatedWaypoints);
                    }}
                    handleOpenConstrainMgrAuthority={
                      handleOpenConstrainMgrAuthority
                    }
                  />
                }
                ruleComponent={
                  <Rule
                    isRequiring
                    rule={rule}
                    setRule={setRule}
                    whitelistUser={whitelistUser}
                    setWhitelistUser={setWhitelistUser}
                    whitelistUserNested={whitelistUserNested}
                    setWhitelistUserNested={setWhitelistUserNested}
                    whitelistUserGroup={whitelistUserGroup}
                    setWhitelistUserGroup={setWhitelistUserGroup}
                    threshold={threshold}
                    setThreshold={setThreshold}
                    others={others}
                    setOthers={setOthers}
                    nocameratehetered={nocameratehetered}
                    setnNoCameraThetered={setnNoCameraThetered}
                    departureTimeAuthCon={departureTimeAuthCon}
                    setDepartureTimeAuthCon={setDepartureTimeAuthCon}
                    departureTimeErrorAuthCon={departureTimeErrorAuthCon}
                    setDepartureTimeErrorAuthCon={setDepartureTimeErrorAuthCon}
                    endTimeAuthCon={endTimeAuthCon}
                    setEndTimeAuthCon={setEndTimeAuthCon}
                    endTimeErrorAuthCon={endTimeErrorAuthCon}
                    setEndTimeErrorAuthCon={setEndTimeErrorAuthCon}
                    departureTimeProhibitCon={departureTimeProhibitCon}
                    setDepartureTimeProhibitCon={setDepartureTimeProhibitCon}
                    departureTimeErrorProhibitCon={
                      departureTimeErrorProhibitCon
                    }
                    setDepartureTimeErrorProhibitCon={
                      setDepartureTimeErrorProhibitCon
                    }
                    endTimeProhibitCon={endTimeProhibitCon}
                    setEndTimeProhibitCon={setEndTimeProhibitCon}
                    endTimeErrorProhibitCon={endTimeErrorProhibitCon}
                    setEndTimeErrorProhibitCon={setEndTimeErrorProhibitCon}
                    personNameWhitelistUsers={personNameWhitelistUsers}
                    setPersonNameWhitelistUsers={setPersonNameWhitelistUsers}
                    personNameWhitelistGroups={personNameWhitelistGroups}
                    setPersonNameWhitelistGroups={setPersonNameWhitelistGroups}
                    authorisationPersonNameWhitelistUsers={
                      authorisationPersonNameWhitelistUsers
                    }
                    setAuthorisationPersonNameWhitelistUsers={
                      setAuthorisationPersonNameWhitelistUsers
                    }
                    authorisationPersonNameWhitelistGroups={
                      authorisationPersonNameWhitelistGroups
                    }
                    setAuthorisationPersonNameWhitelistGroups={
                      setAuthorisationPersonNameWhitelistGroups
                    }
                    authConRequiringComponent={
                      <Requiring
                        valueTimeThick={authConValueTimeThick}
                        setvalueTimeThick={setAuthConValueTimeThick}
                        departureTime={authConDepartureTimeRequiring}
                        departureTimeError={authConDepartureTimeErrorRequiring}
                        handleDepartureTimeChange={
                          handleAuthConDepartureTimeChange
                        }
                        endTime={authConEndTimeRequiring}
                        endTimeError={authConEndTimeErrorRequiring}
                        handleEndTimeChange={handleAuthConEndTimeChange}
                        recurringCheck={authConRecurringCheck}
                        setRecurringChek={setAuthConRecurringChek}
                      />
                    }
                    prohibitConRequiringComponent={
                      <Requiring
                        valueTimeThick={prohibitConValueTimeThick}
                        setvalueTimeThick={setProhibitConValueTimeThick}
                        departureTime={prohibitConDepartureTimeRequiring}
                        departureTimeError={
                          prohibitConDepartureTimeErrorRequiring
                        }
                        handleDepartureTimeChange={
                          handleProhibitConDepartureTimeChange
                        }
                        endTime={prohibitConEndTimeRequiring}
                        endTimeError={prohibitConEndTimeErrorRequiring}
                        handleEndTimeChange={handleProhibitConEndTimeChange}
                        recurringCheck={prohibitConRecurringCheck}
                        setRecurringChek={setProhibitConRecurringChek}
                      />
                    }
                  />
                }
                selectedAreaWaypoints={selectedAreaWaypoints}
                editConstraintsData={editConstraintsData}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                departureTime={departureTime}
                departureTimeError={departureTimeError}
                handleDepartureTimeChange={handleDepartureTimeChange}
                endTime={endTime}
                endTimeError={endTimeError}
                handleEndTimeChange={handleEndTimeChange}
                altitude_reference={altitude_reference}
                setAltitude_reference={setAltitude_reference}
                handleAltRefChange={handleAltRefChange}
                floor={floor}
                setFloor={setFloor}
                ceiling={ceiling}
                setCeiling={setCeiling}
                mapboxController={mapboxController}
                isInEditMode={isInEditMode}
                handleDiscardEdit={handleDiscardEdit}
                largeArea={largeArea}
                classesFloating={classesFloating}
                handleOpenConstrainMgrAuthority={
                  handleOpenConstrainMgrAuthority
                }
                resetForm={resetForm}
              />
            </Panel>
          </Panel>
        </div>
      </LoadingOverlay>
    </form>
  );
}

export default CreateConstraintAuthorityComponent;
