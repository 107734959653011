import axios from "axios";
import config from "../config.json";

let puck_base_url = "";

const server = {
  updatePuckserverBaseUrl: (url) => {
    puck_base_url = url;
  },
  getTrackerData: async (trackerID, operationID) => {
    const requestParams = {
      method: "get",
      url: `${puck_base_url}/flighthistory/${trackerID}/${operationID}`,
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  getFlightTelemetry: async (puckID) => {
    const requestParams = {
      method: "get",
      url: `${puck_base_url}/api/getownshipflights/${puckID}`,
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  sendCommand: async (puckID, command) => {
    const requestParams = {
      method: "post",
      url: `${puck_base_url}/api/sendcommand/${puckID}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        gcs: command,
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  uploadCommand: async (puckID, route, speed, command) => {
    const requestParams = {
      method: "post",
      url: `${puck_base_url}/api/sendcommand/${puckID}`,
      data: {
        gcs: command,
        route,
        // route: [
        //     {
        //         lon: 103.86715348805345,
        //         lat: 1.2723252940785097,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87018642930259,
        //         lat: 1.2718825279408739,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87067103740121,
        //         lat: 1.2712251401402583,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.86936295270267,
        //         lat: 1.2702389140875576,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87113725130179,
        //         lat: 1.267824965911375,
        //         alt: 30
        //     }
        // ],
        speed,
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  pairUserTracker: async (data) => {
    const requestParams = {
      method: "post",
      url: `${puck_base_url}/api/tracker-user-pair`,
      headers: {
        "content-type": "application/json",
      },
      data,
    };
    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  unpairUserTracker: async (trackerSn) => {
    try {
      const response = await axios.delete(
        `${puck_base_url}/api/tracker-user-pair/${trackerSn}`
      );
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  // postMission: async (OperationDetails, puckID, uasID) => {
  //   const requestParams = {
  //     method: "post",
  //     url: `${puck_base_url}/api/mission`,
  //     headers: {
  //       "content-type": "application/json",
  //     },
  //   };
  //   const env =
  //     process.env.REACT_APP_NOVAUSS_BASE_URL === "https://sp.heronairbridge.com"
  //       ? "prod"
  //       : "dev";

  //   if (!OperationDetails.details.waypoints) {
  //     requestParams.data = {
  //       id: Math.floor(Math.random() * 1000 + 1),
  //       flight_plan: {
  //         id: Math.floor(Math.random() * 1000 + 1),
  //         status: "ACCEPTED",
  //         node_departure: 1,
  //         time_departure: OperationDetails.reference.time_start.value,
  //         time_arrival: OperationDetails.reference.time_end.value,
  //         altitude_reference: "WGS84",
  //         rnp: 0,
  //         date_created: OperationDetails.reference.time_created.value,
  //       },
  //       gufi: OperationDetails.reference.id,
  //       operation_state: "ACCEPTED",
  //       rank: OperationDetails.reference.version,
  //       time_submitted: new Date(),
  //       puck_id: puckID,
  //       uas_id: uasID,
  //       env,
  //     };
  //   } else {
  //     requestParams.data = {
  //       id: Math.floor(Math.random() * 1000 + 1),
  //       flight_plan: {
  //         id: Math.floor(Math.random() * 1000 + 1),
  //         status: "ACCEPTED",
  //         node_departure: 1,
  //         node_arrival: OperationDetails.details.waypoints.length,
  //         time_departure: OperationDetails.reference.time_start.value,
  //         time_arrival: OperationDetails.reference.time_end.value,
  //         ground_speed: OperationDetails.request.ground_speed,
  //         altitude_reference: "WGS84",
  //         route: OperationDetails.details.waypoints,
  //         rnp: 0,
  //         date_created: OperationDetails.reference.time_created.value,
  //       },
  //       gufi: OperationDetails.reference.id,
  //       operation_state: "ACCEPTED",
  //       rank: OperationDetails.reference.version,
  //       time_submitted: new Date(),
  //       puck_id: puckID,
  //       uas_id: uasID,
  //       env,
  //     };
  //   }

  //   try {
  //     const response = await axios(requestParams);
  //     return [null, response.data];
  //   } catch (err) {
  //     return [err, null];
  //   }
  // },
};

export default server;
