import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { useApi } from "../../api/useApi";

import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage}
    />
  );
}

export default function AddOperatorFormComponent({
  open,
  updating,
  handleClose,
}) {
  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [permitNumber, setPermitNumber] = React.useState("");
  const [permitNumberError, setPermitNumberError] = React.useState("");

  const [expiry, setExpiry] = React.useState(new Date());
  const [expiryError, setExpiryError] = React.useState("");

  const [submittingNewOperator, setSubmittingNewOperator] =
    React.useState(false); // handles overlay
  const api = useApi();
  const dispatch = useDispatch();

  const submitNewOperator = async () => {
    try {
      const response = await api.submitNewOperator({
        username,
        email,
        permit_number: permitNumber,
        permit_expiry: expiry,
      });
      if (response.data) {
        setSubmittingNewOperator(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Operator created",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewOperator(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const validateForm = () => {
    let error = false;
    if (!email) {
      setEmailError("Operator email is required");
      error = true;
    }
    if (!permitNumber) {
      setPermitNumberError("Operator permit number is required");
      error = true;
    }
    if (!expiry) {
      setExpiryError("Permit expiry date is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewOperator(true);
    submitNewOperator();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <LoadingOverlay
        active={submittingNewOperator}
        spinner
        text={
          updating
            ? "Updating operator information..."
            : "Submitting new operator..."
        }
      >
        <div style={{ height: "250px", width: "500px", margin: "30px" }}>
          <Typography
            component="div"
            display="inline"
            variant="h6"
            style={{ color: "black" }}
          >
            {updating ? "Update Operator" : "Add Operator"}
          </Typography>
          {!updating ? (
            <CustomTextField
              label="Username"
              value={username}
              setFuncton={setUsername}
              fullWidth
              errormessage={usernameError}
              setError={setUsernameError}
            />
          ) : null}
          <CustomTextField
            label="Email"
            value={email}
            setFuncton={setEmail}
            fullWidth
            errormessage={emailError}
            setError={setEmailError}
          />
          <CustomTextField
            label="Permit No."
            value={permitNumber}
            setFuncton={setPermitNumber}
            fullWidth={false}
            errormessage={permitNumberError}
            setError={setPermitNumberError}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              style={{ marginLeft: "8px" }}
              id="Expiry-Date"
              label="Permit Expiry Date"
              format="MM/dd/yyyy"
              value={expiry}
              errormessage={expiryError}
              onChange={(date) => setExpiry(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
