/**
 * Websocket modules to handle airbridge websocket messages
 * 1. optional authentication
 * 2. different channels
 * 3. listener sub/unsub
 */
import WebSocket from "isomorphic-ws";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messageTypes } from "../config/messageTypes";
import { addWebsocket } from "../store/actions/websocket";

let WS_URL = "";
const defaultListeners = {
  [messageTypes.TELEMETRY]: [],
  [messageTypes.CONFORMANCE]: [],
  [messageTypes.AUTHORITY_ALERT]: [],
  [messageTypes.ALERT]: [],
  [messageTypes.LOG_MESSAGE]: [],
  [messageTypes.COLLISION]: [],
  [messageTypes.HEARTBEAT]: [],
};
class WebSocketService extends WebSocket {
  static keepAlive = null;

  constructor(url) {
    super(url);
    this.messageListeners = { ...defaultListeners };
    this.isOpen = false;
    this.connection = { initialized: false };
  }

  /**
   *  Show connection status to us in the log
   */
  onopen = () => {
    this.isOpen = true;
    // API gateway times out after 10 min of inactivity. Send ping every 9 min to keep connection alive.
    WebSocketService.keepAlive = setInterval(() => {
      this.sendMessage("ping", { message: "test" });
    }, 540000);
    // send an initial ping to get connection id
    this.sendMessage("ping", { message: "test" });
  };

  /**
   *  Log lost connection for now
   */

  //   onclose = () => {
  // clearInterval(WebSocketService.keepAlive);
  //   };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   */
  sendMessage = (routeKey, message) => {
    if (!this.isOpen) {
      console.error(message);
      return;
    }

    this.send(
      JSON.stringify({
        message: routeKey,
        data: JSON.stringify(message),
      })
    );
  };

  checkConnectionId = () => {
    return this.connection;
  };

  checkConnection = () => {
    // Values returned
    // CONNECTING	0	The connection is not yet open.
    // OPEN	1	The connection is open and ready to communicate.
    // CLOSING	2	The connection is in the process of closing.
    // CLOSED	3	The connection is closed.
    return this.readyState;
  };

  /**
   *  Used by application to register different listeners for
   *  different message types
   *  @param listener Function to handle message type
   */
  addMessageListener = (type, listener) => {
    if (typeof listener !== "function" || !this.messageListeners[type]) {
      return -1;
    }
    const id = Date.now();

    // telemetry listener is now called from 2 places
    // when called at same time, will overwrite the earlier listener
    // added handler for this case
    const checkDuplicate = (listenerId) => {
      if (!this.messageListeners[type][listenerId]) {
        this.messageListeners[type][listenerId] = listener;
      } else {
        const newId = listenerId + 0.5;
        checkDuplicate(newId);
      }
    };
    checkDuplicate(id);
    return () => this.removeMessageListener(type, id);
  };

  removeMessageListener = (type, id) => {
    delete this.messageListeners[type][id];
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body to the appropriate
   * registered handler
   * @param data Message body received from WebSocket
   */
  onmessage = (data) => {
    const body = JSON.parse(data.data);
    // eslint-disable-next-line no-console
    // console.log("webseockettest", body);
    this.dataConformance = body;
    if (!this.connection.initialized) {
      if (body.connectionId && !body.type) {
        this.connection.connectionId = body.connectionId;
        this.connection.initialized = true;
      }
    }
    if (!body || !this.messageListeners[body.type]) return;

    for (const listener of Object.keys(this.messageListeners[body.type]).map(
      (key) => this.messageListeners[body.type][key]
    ))
      listener(body.data);
  };

  // eslint-disable-next-line class-methods-use-this
  onclose = () => {
    this.removeAllListeners();
    clearInterval(WebSocketService.keepAlive);
  };

  removeAllListeners() {
    this.messageListeners = defaultListeners;
  }
}

const useWebsocket = (opt) => {
  // console.log("connecting websocket");
  const { channel } = opt;
  const foundChannel = useSelector((state) => state.websocket?.[channel]);
  const dispatch = useDispatch();
  const [wsUrl, setWsUrl] = useState(WS_URL);
  return {
    channel: foundChannel,
    /**
     *
     * @param {string} jwtToken
     * @param {string} role
     */
    updateWsUrl: (jwtToken, role) => {
      const authWsUrl = `${WS_URL}?jwtToken=${jwtToken}&userGroup=${role}`;

      if (wsUrl === authWsUrl) return;

      foundChannel?.close();
      dispatch(addWebsocket(channel, new WebSocketService(authWsUrl)));
      setWsUrl(authWsUrl);
    },
    updateWsBaseUrl: (newWSUrl) => {
      WS_URL = newWSUrl;
      setWsUrl(WS_URL);
    },
    reconnectWs: (jwtToken, role) => {
      const authWsUrl = `${WS_URL}?jwtToken=${jwtToken}&userGroup=${role}`;
      foundChannel?.close();
      dispatch(addWebsocket(channel, new WebSocketService(authWsUrl)));
      setWsUrl(authWsUrl);
    },
  };
};

export default useWebsocket;
