import * as React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  useTheme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";
import DetailsTrackers from "./DetailsTrackers";
import DetailsPaltforms from "./DetailsPaltforms";
import DetailsPilots from "./DetailsPilots";
import EmbedMapB from "../MapB/embedMapB";
import { setOperationFormRequest } from "../../store/actions";

export default function DetailsOperationsMapDialog({
  openDetailsDialog,
  handleCloseDetailsDialog,
  selectedDialogData,
  handleopenAuthProposeOperationDrawer,
  handleOpenFlightAuthorizationAuthority,
  getOperations,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {}, [selectedDialogData]);
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        // disableBackdropClick
        // fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <LoadingOverlay
          active={submitLoading}
          spinner
          text="Submitting operation..."
        >
          <DialogTitle id="responsive-dialog-title">
            {`Details of ${selectedDialogData?.reference?.description}`}
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Operation :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.reference?.description}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Departure :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.interuss
                          ?.operational_intent_reference?.time_start?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Arrival :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.interuss
                          ?.operational_intent_reference?.time_end?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Pilot :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsPilots
                        pilot_uuid={selectedDialogData?.request?.pilot_uuid}
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Platform :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsPaltforms
                        platform_uuid={
                          selectedDialogData?.request?.platform_uuid
                        }
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Tracker :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsTrackers
                        tracker_uuid={selectedDialogData?.request?.tracker_uuid}
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Status :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.details?.state}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Operation ID :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.reference?.id}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Created On :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.reference?.time_created?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div>
                  <EmbedMapB
                    compareCurrentData={null}
                    compareData={selectedDialogData}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ProposedOperationsHeader
              selectedFlights={[selectedDialogData?.reference?.id]}
              handleopenAuthProposeOperationDrawer={
                handleopenAuthProposeOperationDrawer
              }
              handleOpenFlightAuthorizationAuthority={
                handleOpenFlightAuthorizationAuthority
              }
              handleCloseDetailsDialog={handleCloseDetailsDialog}
              setSubmitLoading={setSubmitLoading}
              getOperations={getOperations}
            />
            <Button
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={handleCloseDetailsDialog}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}

const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));

function ProposedOperationsHeader({
  selectedFlights,
  handleopenAuthProposeOperationDrawer,
  handleOpenFlightAuthorizationAuthority,
  handleCloseDetailsDialog,
  setSubmitLoading,
  getOperations,
}) {
  const api = useApi();
  const dispatch = useDispatch();
  const classesFloating = useFloatingButton();
  const fetchFlight = (operations, selectedFlight) =>
    operations.data.find((e) => e.operation_uuid === selectedFlight);
  const mapboxController = useSelector((state) => state.maps.mapboxController);

  const handleAcceptClick = async () => {
    try {
      setSubmitLoading(true);
      const operations = await api.getOperations();
      const owner = fetchFlight(operations, selectedFlights[0]).operation_uuid;
      const res = await api.setFlightAccepted(selectedFlights[0]);
      // success message and close dialog
      if (res?.status === 200) {
        try {
          // send email
          await api.sendEmail({
            recipientUsername: `${owner}`,
            message: `Flight ${selectedFlights[0]} has been accepted`,
            subject: `Flight ${selectedFlights[0]} Approved`,
          });
        } catch (e) {
          console.log("error :", e);
        }
        getOperations();
        setSubmitLoading(false);
        handleCloseDetailsDialog();
      }
    } catch (e) {
      console.log("error :", e);
      getOperations();
      setSubmitLoading(false);
      handleCloseDetailsDialog();
    }
    setSubmitLoading(false);
  };

  const handleRejectClick = async () => {
    try {
      const operations = await api.getOperations();
      const singleOperation = fetchFlight(operations, selectedFlights[0]);
      const owner = singleOperation.operation_uuid;
      const { two_way } = singleOperation.request_json;
      setSubmitLoading(true);
      if (two_way) {
        singleOperation.operation_json.reference.mission_list.map(
          async (singleOperationUuid) => {
            const res = await api.setFlightRejected(singleOperationUuid);
            if (res?.status === 200) {
              try {
                // send email
                await api.sendEmail({
                  recipientUsername: `${owner}`,
                  message: `Flight ${singleOperationUuid} has been rejected and removed`,
                  subject: `Flight ${singleOperationUuid} Rejected`,
                });
              } catch (e) {
                console.log("error :", e);
              }
              // success message and close dialog
            }
          }
        );
      } else {
        const res = await api.setFlightRejected(selectedFlights[0]);
        if (res?.status === 200) {
          try {
            // send email
            await api.sendEmail({
              recipientUsername: `${owner}`,
              message: `Flight ${selectedFlights[0]} has been rejected and removed`,
              subject: `Flight ${selectedFlights[0]} Rejected`,
            });
          } catch (e) {
            console.log("error :", e);
          }
          // success message and close dialog
        }
      }
    } catch (e) {
      console.log("error ", e);
    } finally {
      getOperations();
      setSubmitLoading(false);
      handleCloseDetailsDialog();
    }
  };
  const handleOpenAuthProposeOperation = async (open) => {
    if (mapboxController) mapboxController.removeDrawFeaturesAll();
    // console.log("selectedFlights", selectedFlights);
    // Noted: need change not to use api call again just use table data
    const response = await api.getOperationData({
      operationID: selectedFlights[0],
    });
    const request = response.data.request_json;
    if (request.two_way) {
      request.description = response.data.operation_json.reference.description;
    }
    dispatch(
      setOperationFormRequest({
        id: selectedFlights[0],
        type: "edit",
        request,
      })
    );
    handleopenAuthProposeOperationDrawer(open);
    handleOpenFlightAuthorizationAuthority();
    // close dialog
    handleCloseDetailsDialog();
  };
  return (
    <div className={classesFloating.buttonFloating}>
      <Grid
        container
        spacing={0}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Grid item xs={8} style={{ textAlign: "right" }}>
          <Button
            disabled={!selectedFlights.length}
            variant="contained"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={handleAcceptClick}
            color="primary"
          >
            Accept
          </Button>
          <Button
            variant="contained"
            disabled={!selectedFlights.length}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            color="secondary"
            onClick={handleRejectClick}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            disabled={!selectedFlights.length}
            style={{ fontSize: "12px", fontWeight: "bold" }}
            color="secondary"
            onClick={() => handleOpenAuthProposeOperation(true)}
          >
            Propose Changes
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
