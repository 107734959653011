import { INIT_ENV_VAR } from "../actions/envVar";

const envVar = (state = {}, action) => {
  switch (action.type) {
    case INIT_ENV_VAR: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default envVar;
