import { OperationJson } from "@/model/api/Operation";
import Tracker from "@/model/api/Tracker";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowBack, IoIosMore } from "react-icons/io";
import Button from "../../Common/Button";
import Loader from "../../Common/Loader";
import TrackerItem from "../AssetManagement/ItemCards/TrackerItem";
import TrackerForm from "../AssetManagement/TrackerForm";
import TrackerMonitoring from "./TrackerMonitor";

interface TrackerManagementProps {
  api: any;
  selectedFlightData: OperationJson[];
  operationSummary: number;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
}

export default function TrackerManagement({
  api,
  selectedFlightData,
  operationSummary,
  showMessage,
}: TrackerManagementProps) {
  /* API State */
  const [isAdd, setIsAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* Data State */
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [selectedTracker, setSelectedTracker] = useState<Tracker | null>(null);

  const [formTitle, setFormTitle] = useState("Create New");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);

    // if (option === 1 && pastpOperations.length === 0) {
    //   fetchOperations(true);
    // } else {
    //   setOperations(option === 0 ? upcomingOperations : pastpOperations);
    // }
  };

  const handleSelectTracker = (tracker: Tracker) => {
    setSelectedTracker(tracker);
    setIsAdd(true);
    setFormTitle("Edit");
  };

  const handleAddNew = () => {
    setIsAdd(true);
    setSelectedTracker(null);
    setFormTitle("Create New");
  };

  const handleSelectedItem = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    }
  };

  const handleSaveSuccess = async () => {
    showMessage && showMessage("Data saved successfully", true);
    setIsAdd(false);
    fetchTrackerData();
  };

  const handleDeleteTracker = async () => {
    setIsDeleting(true);
    showMessage && showMessage("Deleting items... Please wait");

    try {
      let isAPIError = false;

      const promises: Promise<void>[] = selectedIds.map(async (item) => {
        let success = false;
        success = await api.deleteTracker(item);
        if (!success) {
          isAPIError = true;
        }
      });

      await Promise.all(promises);
      if (isAPIError) {
        showMessage && showMessage("Error deleting items", false, true);
      } else {
        showMessage && showMessage("Items deleted successfully", true);
      }

      setIsDeleting(false);
    } catch (err: any) {
      if (err?.response?.status === 403) {
        setIsDeleting(false);
        showMessage &&
          showMessage("User does not have rights to modify asset", false, true);
      } else {
        setIsDeleting(false);
        showMessage && showMessage("Error deleting items", false, true);
      }
    }
    fetchTrackerData();
  };

  const fetchTrackerData = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await api.getTracker();
      if (response.data) {
        // response.data.sort(compare);
        setTrackers(response.data);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(err.message);
    }
  };

  const checkSelectedIds = () => {
    // make sure deleted trackers not in selectedTrackers list
    const filteredSelectedIds = trackers
      .filter((singleTracker) =>
        selectedIds.includes(singleTracker.tracker_uuid)
      )
      .map((singleTracker) => singleTracker.tracker_uuid);
    setSelectedIds(filteredSelectedIds);
  };
  useEffect(() => {
    fetchTrackerData();
  }, []);

  useEffect(() => {
    // make sure deleted trackers not in selectedTrackers list
    checkSelectedIds();
  }, [trackers]);

  return (
    <div className="absolute top-0 w-full z-[9999]">
      {/* Data List */}
      {!isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          <div
            className={`${selectedTab == 0 ? "h-[100%]" : "h-[92%]"} flex-col`}
          >
            {/* Header */}
            <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
              <h4>Tracker Monitoring</h4>
              <span className="grow" />
              <div className="w-6 h-6 bg-blue-200 rounded-lg flex dark:bg-darkSecondary">
                <IoIosMore
                  className="fill-blue-500 dark:fill-white m-auto"
                  size={16}
                />
              </div>
            </div>
            {/* <div className="flex-col px-4">
              <div className="tab-rounded mt-2">
                <span
                  className={`${selectedTab === 0 && "tab-active"} mr-1`}
                  onClick={() => handleSelectedTab(0)}
                >
                  Monitor
                </span>
                <span
                  className={`${selectedTab === 1 && "tab-active"} ml-1`}
                  onClick={() => handleSelectedTab(1)}
                >
                  Trackers
                </span>
              </div>
            </div> */}
            {/* Content */}
            <div className="h-[100%] flex-col overflow-auto p-4">
              {selectedTab === 0 && trackers.length > 0 && (
                <TrackerMonitoring
                  api={api}
                  trackers={trackers}
                  selectedFlightData={selectedFlightData}
                  operationsSummary={operationSummary}
                  showMessage={showMessage}
                />
              )}

              {/* List Of Trackers */}
              {selectedTab === 1 &&
                trackers.length > 0 &&
                trackers.map((tracker) => (
                  <TrackerItem
                    key={tracker.tracker_uuid}
                    data={tracker}
                    onClick={handleSelectTracker}
                    onSelect={handleSelectedItem}
                  />
                ))}

              {/* No Data Available */}
              {!isLoading && trackers.length === 0 && (
                <p className="p-8 text-center">No Data Available</p>
              )}

              <Loader
                isLoading={isLoading}
                isError={isError}
                errorText={errorMessage}
              />
              {selectedTab === 1 && (
                <div className="relative p-4 mb-20 cursor-pointer">
                  {selectedIds.length > 0 ? (
                    <Button
                      type="danger"
                      size="medium"
                      text="Delete"
                      isLoading={isDeleting}
                      disabled={isDeleting}
                      onClick={handleDeleteTracker}
                    />
                  ) : (
                    <Button
                      type="primaryDark"
                      size="medium"
                      text="Add Tracker"
                      icon={<FaPlus size={12} color="white" />}
                      onClick={handleAddNew}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Forms */}
      {isAdd && (
        <div className="flex-col h-[90%] overflow-auto">
          {/* Navigation & Form Title */}
          <div className="flex py-2 items-center content-center">
            <button onClick={() => setIsAdd(false)} className="p-2">
              <IoIosArrowBack size={24} className="m-auto" />
            </button>
            <h5 className="font-medium ml-2">{formTitle} Tracker</h5>
          </div>
          <hr />

          {/* Form */}
          <TrackerForm
            api={api}
            tracker={selectedTracker}
            showMessage={showMessage}
            onSaveSucess={handleSaveSuccess}
          />
        </div>
      )}
    </div>
  );
}
