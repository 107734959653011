/**
 *
 * @returns {number} date (current elapsed time from epoch, floored to nearest 5 minutes)
 */
export function getCurrentRoundedTime() {
  const round = 5 * 60 * 1000;
  let current = Date.now();
  current = Math.floor(current / round) * round;
  return current;
}
