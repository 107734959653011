import React, { useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import TableContainer from "@material-ui/core/TableContainer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { setSnackbarMessage } from "../../store/actions";
import { getWSService } from "../../services/websocket";
import { useApi } from "../../api/useApi";
import FlightLogs from "../FlightLogs/index";
import useCognitoAuth from "../../hooks/useCognitoAuth";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon className={classes.icon} />
        ) : (
          <FirstPageIcon className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon className={classes.icon} />
        ) : (
          <LastPageIcon className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
  },
}));

function OperationsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = React.useState(0);
  const {
    operations,
    selected,
    setSelected,
    setDuplicateTriggered,
    isRemoteId,
    toggleLoadOlderLogs,
    loadOlderLogs,
  } = props;

  const rowsPerPage = 10;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, operations.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");

  return (
    <TableContainer>
      <FlightLogs
        data={operations}
        setDuplicateTriggered={setDuplicateTriggered}
        isRemoteId={isRemoteId}
        toggleLoadOlderLogs={toggleLoadOlderLogs}
        loadOlderLogs={loadOlderLogs}
      />
    </TableContainer>
    // comment to swap back

    // Uncomment to swap back 1/2
    // <TableContainer>
    //   <Table className={classes.table} aria-label="a dense table">
    //     <TableHead>
    //       <TableRow className={classes.tableHeaderRow}>
    //         <TableCell padding="checkbox" align="center">
    //           <Checkbox
    //             checked={
    //               operations.length && selected.length === operations.length
    //             }
    //             onChange={(evt, checked) => {
    //               if (checked) {
    //                 setSelected(operations);
    //               } else {
    //                 setSelected([]);
    //               }
    //             }}
    //           />
    //         </TableCell>
    //         <TableCell className={classes.tableCell}> Operation </TableCell>

    //         <TableCell className={classes.tableCell} align="right">
    //           Departure
    //         </TableCell>
    //         <TableCell className={classes.tableCell} align="right">
    //           Arrival
    //         </TableCell>
    //         <TableCell className={classes.tableCell} align="center">
    //           Status
    //         </TableCell>
    //         <TableCell className={classes.tableCell} align="right">
    //           Operation ID
    //         </TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {operations
    //         .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //         .map((row, index) => {
    //           return (
    //             <TableRow className={classes.tableRow} key={row.reference.id}>
    //               <TableCell padding="checkbox" align="center">
    //                 <Checkbox
    //                   checked={
    //                     selected.filter(
    //                       (operation) =>
    //                         operation.reference.id === row.reference.id
    //                     ).length
    //                   }
    //                   onChange={(evt, checked) => {
    //                     if (checked) {
    //                       setSelected([...selected, row]);
    //                     } else {
    //                       setSelected(
    //                         selected.filter(
    //                           (operation) =>
    //                             operation.reference.id !== row.reference.id
    //                         )
    //                       );
    //                     }
    //                   }}
    //                 />
    //               </TableCell>
    //               <TableCell
    //                 className={classes.tableCell}
    //                 component="th"
    //                 scope="row"
    //               >
    //                 {`${row.reference.description}`}
    //               </TableCell>
    //               <TableCell className={classes.tableCell} align="right">
    //                 {row.interuss?.operational_intent_reference?.time_start?.value &&
    //                   formatDate(
    //                     row.interuss.operational_intent_reference.time_start.value
    //                   )}
    //               </TableCell>
    //               <TableCell className={classes.tableCell} align="right">
    //                 {row.interuss?.operational_intent_reference?.time_end?.value &&
    //                   formatDate(
    //                     row.interuss.operational_intent_reference.time_end.value
    //                   )}
    //               </TableCell>
    //               <TableCell className={classes.tableCell} align="center">
    //                 {row.details.state}
    //               </TableCell>
    //               <TableCell className={classes.tableCell} align="right">
    //                 {row.reference.id}
    //               </TableCell>
    //             </TableRow>
    //           );
    //         })}
    //       {emptyRows > 0 && (
    //         <TableRow style={{ height: 53 * emptyRows }}>
    //           <TableCell colSpan={5} />
    //         </TableRow>
    //       )}
    //     </TableBody>
    //     <TableFooter>
    //       <TableRow>
    //         <TablePagination
    //           rowsPerPageOptions={[]}
    //           colSpan={6}
    //           count={operations.length}
    //           rowsPerPage={rowsPerPage}
    //           page={page}
    //           className={classes.pagination}
    //           onChangePage={handleChangePage}
    //           ActionsComponent={TablePaginationActions}
    //         />
    //       </TableRow>
    //     </TableFooter>
    //   </Table>
    // </TableContainer>
    // Uncomment to swap back 1/2
  );
}

export function SchedulesComponent({
  history,
  auth,
  setDuplicateTriggered,
  isRemoteId,
  loadOlderLogs,
}) {
  const [loading, setLoading] = React.useState(true);
  const [pastOperations, setPastOperations] = React.useState([]);
  // const [platformData, setPlatformData] = React.useState([]);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const [selectedOperations, setSelectedOperations] = React.useState([]);
  const [disableGetOlderLogsButton, setDisableGetOlderLogsButton] =
    React.useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  React.useEffect(() => {
    getData();
  }, []);

  const toggleLoadOlderLogs = () => {
    getData();
  };

  const { username } = useCognitoAuth();

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  const getData = async () => {
    setLoading(true);
    const promises = [];
    promises.push(getOperations(loadOlderLogs));
    // promises.push(getPlatformData())

    try {
      await Promise.all(promises);
    } catch (err) {
      console.log(err);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err,
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  // const getPlatformData = async () => {
  //   const requestParams = {
  //     method: 'post',
  //     url: `${config.mars_base_url}/platform/list`,
  //     headers: {
  //       'content-type': 'application/json',
  //     }
  //   }

  //   const response = await axios(requestParams)
  //   if(!response.data) return

  //   setPlatformData(response.data)
  // }
  const platformListRef = useRef([]);
  const platformTypeListRef = useRef([]);
  const logStateRef = useRef([]);
  const pilotListRef = useRef([]);
  const operationListRef = useRef([]);
  const trackerListRef = useRef([]);

  const getOperations = async () => {
    if (pastOperations.length === 0) {
      const [
        platformList,
        platformTypeList,
        logState,
        pilotList,
        operationList,
        trackerList,
      ] = await Promise.all([
        api.getPlatforms(),
        api.getPlatformTypes(),
        api.getLogState(),
        api.getPilots(),
        api.getOperations(true),
        api.getTracker(),
      ]);
      // if returned data is < 30 means no more logs
      if (operationList.data.length < 30) {
        setDisableGetOlderLogsButton(true);
      }
      compileOperationData(
        platformList,
        platformTypeList,
        logState,
        pilotList,
        operationList,
        trackerList
      );
      [
        platformListRef.current,
        platformTypeListRef.current,
        logStateRef.current,
        pilotListRef.current,
        operationListRef.current,
        trackerListRef.current,
      ] = [
        platformList,
        platformTypeList,
        logState,
        pilotList,
        operationList,
        trackerList,
      ];
    } else {
      // ex. offset of 100 will return operations 101 to 200
      // so if operation list already has 100 operations,
      // this will get next 100 and add to operationListRef
      const offset = operationListRef.current.data.length;
      const [logState, operationList] = await Promise.all([
        api.getLogState(offset),
        api.getOperations(true, offset),
      ]);
      operationListRef.current.data = [
        ...operationListRef.current.data,
        ...operationList.data,
      ];
      logStateRef.current.data = [
        ...logStateRef.current.data,
        ...logState.data,
      ];
      // if returned data is < 30 means no more logs
      if (operationList.data.length < 30) {
        setDisableGetOlderLogsButton(true);
      }
      compileOperationData(
        platformListRef.current,
        platformTypeListRef.current,
        logStateRef.current,
        pilotListRef.current,
        operationListRef.current,
        trackerListRef.current,
        offset
      );
    }
  };

  const compileOperationData = async (
    platformList,
    platformTypeList,
    logState,
    pilotList,
    operationList,
    trackerList,
    offset
  ) => {
    let response;
    // Pilot cannot access operator created operation, so use getPilotOperations for pilots
    if (auth.isPilot) {
      response = { data: [] };
      const user_uuid = pilotList.data.filter((singlePilot) => {
        return singlePilot.pilot_email === auth.userInfo.email;
      });
      const promises = user_uuid.map((id) =>
        api.getPilotOperations(id.pilot_uuid, offset)
      );

      const operationResponses = await Promise.all(promises);
      for (const getOperationResponse of operationResponses) {
        response.data.push(...getOperationResponse.data);
      }
    } else {
      response = operationList;
    }

    if (!response.data) return;
    const operations = [];
    const logStateGrouped = logState?.data?.reduce((acc, log) => {
      const { operation_uuid } = log;
      acc[operation_uuid] = acc[operation_uuid] ?? [];
      if (log.operation_state === "Activated") {
        acc[operation_uuid].takeoff_time = log.event_time;
      } else if (log.operation_state === "Ended") {
        acc[operation_uuid].landing_time = log.event_time;
      }
      acc[operation_uuid].takeoff_time ??= 0;
      acc[operation_uuid].landing_time ??= 0;
      return acc;
    }, {});

    response.data.forEach((flightData) => {
      // Uncomment to swap back 2/2
      // operations.push(flightData.operation_json);
      // Uncomment to swap back 2/2
      const flightDataEdited = flightData.operation_json;
      flightDataEdited.details.pilot_name = "Unknown";
      flightDataEdited.details.platform_type = "Unknown";
      flightDataEdited.details.platform_name = "Unknown";
      flightDataEdited.details.takeoff_time = "Unknown";
      flightDataEdited.details.landing_time = "Unknown";
      flightDataEdited.details.tracker_name = "Unknown";
      // Take Off and Landing Timings
      if (logStateGrouped && flightDataEdited.reference.id in logStateGrouped) {
        flightDataEdited.details.takeoff_time =
          logStateGrouped[flightDataEdited.reference.id].takeoff_time;
        flightDataEdited.details.landing_time =
          logStateGrouped[flightDataEdited.reference.id].landing_time;
      }

      // If no pilots created
      if (pilotList.data) {
        // Check pilot name from uuid
        const pilotNameArr = [];
        pilotList.data.forEach((singlePilot) => {
          // Only works when put in variable to compare
          const flightDataPilotUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.pilot_uuid;
          if (typeof flightDataPilotUuid === "object") {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            flightDataPilotUuid.forEach((flightDataPilotUuidSingle) => {
              if (flightDataPilotUuidSingle === pilotListPilotUuid) {
                pilotNameArr.push(singlePilot.pilot_name);
                // Break does not work with .ForEach
              }
            });
            flightDataEdited.details.pilot_name = pilotNameArr;
          } else {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            if (flightDataPilotUuid === pilotListPilotUuid) {
              flightDataEdited.details.pilot_name = [singlePilot.pilot_name];
              // Break does not work with .ForEach
            }
          }
        });
      }

      // If no trackers created
      if (trackerList.data) {
        // Check pilot name from uuid
        const trackerNameArr = [];
        trackerList.data.forEach((singleTracker) => {
          // Only works when put in variable to compare
          const flightDataTrackerUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.tracker_uuid;
          if (typeof flightDataTrackerUuid === "object") {
            const trackerListTrackerUuid = singleTracker.tracker_uuid;
            flightDataTrackerUuid.forEach((flightDataTrackerUuidSingle) => {
              if (flightDataTrackerUuidSingle === trackerListTrackerUuid) {
                trackerNameArr.push(singleTracker.tracker_name);
                // Break does not work with .ForEach
              }
            });
            flightDataEdited.details.tracker_name = trackerNameArr;
          } else {
            const trackerListTrackerUuid = singleTracker.tracker_uuid;
            if (flightDataTrackerUuid === trackerListTrackerUuid) {
              flightDataEdited.details.tracker_name = [
                singleTracker.tracker_uuid,
              ];
              // Break does not work with .ForEach
            }
          }
        });
      }

      // If no platform created
      if (platformList.data) {
        const platformUuidArr = [];
        const platformTypeArr = [];
        // Check Platform name from uuid
        platformList.data.forEach((singlePlatform) => {
          // Only works when put in variable to compare
          const flightDataPlatformUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.platform_uuid;
          const pilotListPlatformUuid = singlePlatform.platform_uuid;
          if (typeof flightDataPlatformUuid === "object") {
            flightDataPlatformUuid.forEach((flightDataPlatformUuidSingle) => {
              if (flightDataPlatformUuidSingle === pilotListPlatformUuid) {
                platformUuidArr.push(singlePlatform.platform_callsign);
                // Break does not work with .ForEach
                const flightDataPlatformTypeUuid =
                  singlePlatform.platform_type_uuid;

                // If no platformType created
                if (platformTypeList.data) {
                  // Check Platform Type from uuid
                  platformTypeList.data.forEach((singlePlatformType) => {
                    // Only works when put in variable to compare
                    const pilotListPlatformTypeUuid =
                      singlePlatformType.platform_type_uuid;
                    if (
                      flightDataPlatformTypeUuid === pilotListPlatformTypeUuid
                    ) {
                      platformTypeArr.push(singlePlatformType.model);
                      // Break does not work with .ForEach
                    }
                  });
                }
              }
            });
            flightDataEdited.details.platform_name = platformUuidArr;
            flightDataEdited.details.platform_type = platformTypeArr;
          } else if (flightDataPlatformUuid === pilotListPlatformUuid) {
            flightDataEdited.details.platform_name =
              singlePlatform.platform_callsign;
            // Break does not work with .ForEach
            const flightDataPlatformTypeUuid =
              singlePlatform.platform_type_uuid;

            // If no platformType created
            if (platformTypeList.data) {
              // Check Platform Type from uuid
              platformTypeList.data.forEach((singlePlatformType) => {
                // Only works when put in variable to compare
                const pilotListPlatformTypeUuid =
                  singlePlatformType.platform_type_uuid;
                if (flightDataPlatformTypeUuid === pilotListPlatformTypeUuid) {
                  flightDataEdited.details.platform_type =
                    singlePlatformType.model;
                  // Break does not work with .ForEach
                }
              });
            }
          }
        });
      }

      operations.push(flightDataEdited);
    });

    setPastOperations(operations);
  };

  return (
    <Grid
      style={{
        // display: "list-item",
        // flexDirection: "row",
        // position: "fixed",
        // left: 0,
        // height: "100%",
        width: "100vh",
        overflow: "scroll",
      }}
    >
      <LoadingOverlay active={loading} spinner text="">
        <OperationsTable
          operations={pastOperations}
          selected={selectedOperations}
          setSelected={setSelectedOperations}
          setDuplicateTriggered={setDuplicateTriggered}
          isRemoteId={isRemoteId}
          toggleLoadOlderLogs={toggleLoadOlderLogs}
          loadOlderLogs={disableGetOlderLogsButton}
        />
        {/* <SchedulesHeader
          auth={auth}
          selectedOperations={selectedOperations}
          setDuplicateTriggered={setDuplicateTriggered}
        /> */}
        {/* <WebsocketAlert
          open={websocketAlertOpen}
          handleClose={onWebsocketAlertClose}
          websocketMessage={websocketMessage}
        /> */}
      </LoadingOverlay>
    </Grid>
  );
}
