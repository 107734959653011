import "../CreateOperationForm/styles.css";

import { useState, useEffect, memo } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
// import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";

import {
  DeleteOutlined,
  LensOutlined,
  TimelineOutlined,
  PinDropOutlined,
  CropSquareOutlined,
  LabelOutlined,
} from "@material-ui/icons";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useApi } from "../../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../../store/actions";
import CreateOperationForm from "../CreateOperationForm";
import CircleEntries from "../CreateOperationForm/circle";
import AreaEntries from "../CreateOperationForm/area";
import WaypointsEntries from "../CreateOperationForm/route";
import EmergencyLandingPointEntry from "../CreateOperationForm/emergency";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { convertZuluToLocalTime } from "../../../api/timeConvert";
import RejectAcceptOperationResponsiveDialog from "../../../components/RejectAcceptOperation";
import gnssCreateOperation from "../../../components/MapB/gnss/gnssCreateOperation";

LoadingOverlay.propTypes = undefined;

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography component="span" variant="body2">
          {props.children}
        </Typography>
      )}
    </div>
  );
}
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));

function CreateOperationComponent(props) {
  const {
    /* route */
    selectedWaypoints,
    setSelectedWaypoints,
    /* poylygon/area */
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    /* circle */
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    selectedCircleProperties,
    setSelectedCircleProperties,
    /* redundant */
    // onUpdateSelectedWaypoints,
    // onUpdateSelectedAreaWaypoints,
    // updatedSelectedCircleWaypoints,
    // onUpdateSelectedCircleWaypoints,
    // updateSelectedCircleProperties,
    // onUpdateSelectedCircleProperties,
    emergencyLanding,
    setEmergencyLanding,
    mapViewController,
    // setUpdatedEmergencyLanding,
    // contingencyLandingPoint,
    // setContingencyLandingPoint,
    onEmergencyLandingUpdate,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    openFlightAuthorization,
    handleOpenFlightAuthorization,
  } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [lockedTab, setLockedTab] = useState(-1);
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("circle");
  const [awaitTwoWayOperationEdit, setAwaitTwoWayOperationEdit] = useState();

  useEffect(() => {
    getPlatforms();
    getPilots();
    getTrackers();
  }, []);

  useEffect(() => {
    // Update asset list if newly created
    if (openFlightAuthorization) {
      getPlatforms();
      getPilots();
      getTrackers();
    }
  }, [openFlightAuthorization]);

  const onTabClicked = (event, index) => {
    setTabIndex(index);
    if (index === 0) {
      setSelectWaypointMethod("circle");
    } else if (index === 1) {
      setSelectWaypointMethod("area");
    } else if (index === 2) {
      setSelectWaypointMethod("route");
    } else if (index === 3) {
      setSelectWaypointMethod("upload");
    }
  };
  return (
    // <form noValidate autoComplete="off" onSubmit={handleSubmit}>

    <LoadingOverlay
      active={submitLoading}
      spinner
      text="Submitting operation..."
    >
      <div>
        <Typography variant="h6" component="div">
          Select setup methods:
        </Typography>
        <Tabs variant="scrollable" value={tabIndex} onChange={onTabClicked}>
          {/* ({(lockedTab === -1 || lockedTab === 0) && <Tab label="Circle" />})
            ({(lockedTab === -1 || lockedTab === 1) && <Tab label="Polygon" />})
            ({(lockedTab === -1 || lockedTab === 2) && <Tab label="Route" />}) 
            (
            {(lockedTab === -1 || lockedTab === 3) && (
            <Tab label="Upload Mission" />
            )}
            ) */}
          <Tab
            label="Circle"
            disabled={!(lockedTab === -1 || lockedTab === 0)}
          />
          <Tab
            label="Polygon"
            disabled={!(lockedTab === -1 || lockedTab === 1)}
          />
          <Tab
            label="Route"
            disabled={!(lockedTab === -1 || lockedTab === 2)}
          />
          <Tab
            label="Upload Mission"
            disabled={!(lockedTab === -1 || lockedTab === 3)}
          />
        </Tabs>
        {/* Circle */}
        <Panel value={tabIndex} index={0}>
          <CreateOperationForm
            isCircleMethod
            setSubmitLoading={setSubmitLoading}
            onTabClicked={onTabClicked}
            setTabIndex={setTabIndex}
            setLockedTab={setLockedTab}
            setSelectWaypointMethod={setSelectWaypointMethod}
            selectWaypointMethod={selectWaypointMethod}
            // // onEmergencyLandingUpdate={onEmergencyLandingUpdate}
            setEmergencyLanding={setEmergencyLanding}
            emergencyLanding={emergencyLanding}
            // // contingencyLandingPoint={contingencyLandingPoint}
            // // setContingencyLandingPoint={setContingencyLandingPoint}
            mapViewController={mapViewController}
            selectedCircleWaypoints={selectedCircleWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            setSelectedCircleProperties={setSelectedCircleProperties}
            /* others */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            openFlightAuthorization={openFlightAuthorization}
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            awaitTwoWayOperationEdit={awaitTwoWayOperationEdit}
            setAwaitTwoWayOperationEdit={setAwaitTwoWayOperationEdit}
          />
        </Panel>
        {/* Polygon Method */}
        <Panel value={tabIndex} index={1}>
          <CreateOperationForm
            isPolygonMethod
            setSubmitLoading={setSubmitLoading}
            onTabClicked={onTabClicked}
            setTabIndex={setTabIndex}
            setLockedTab={setLockedTab}
            setSelectWaypointMethod={setSelectWaypointMethod}
            selectWaypointMethod={selectWaypointMethod}
            // // onEmergencyLandingUpdate={onEmergencyLandingUpdate}
            setEmergencyLanding={setEmergencyLanding}
            emergencyLanding={emergencyLanding}
            // // contingencyLandingPoint={contingencyLandingPoint}
            // // setContingencyLandingPoint={setContingencyLandingPoint}
            mapViewController={mapViewController}
            // selectedWaypoints={selectedWaypoints}
            // setSelectedWaypoints={setSelectedWaypoints}
            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            setSelectedCircleProperties={setSelectedCircleProperties}
            /* other props */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            openFlightAuthorization={openFlightAuthorization}
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            awaitTwoWayOperationEdit={awaitTwoWayOperationEdit}
            setAwaitTwoWayOperationEdit={setAwaitTwoWayOperationEdit}
          />
        </Panel>
        {/* Route Method */}
        <Panel value={tabIndex} index={2}>
          <CreateOperationForm
            isRouteMethod
            setSubmitLoading={setSubmitLoading}
            onTabClicked={onTabClicked}
            setTabIndex={setTabIndex}
            setLockedTab={setLockedTab}
            setSelectWaypointMethod={setSelectWaypointMethod}
            selectWaypointMethod={selectWaypointMethod}
            // // onEmergencyLandingUpdate={onEmergencyLandingUpdate}
            setEmergencyLanding={setEmergencyLanding}
            emergencyLanding={emergencyLanding}
            // contingencyLandingPoint={contingencyLandingPoint}
            // setContingencyLandingPoint={setContingencyLandingPoint}
            mapViewController={mapViewController}
            selectedWaypoints={selectedWaypoints}
            setSelectedWaypoints={setSelectedWaypoints}
            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            /* other props */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            setSelectedCircleProperties={setSelectedCircleProperties}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            openFlightAuthorization={openFlightAuthorization}
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            awaitTwoWayOperationEdit={awaitTwoWayOperationEdit}
            setAwaitTwoWayOperationEdit={setAwaitTwoWayOperationEdit}
          />
        </Panel>

        {/* ******* Upload ******* */}
        <Panel value={tabIndex} index={3}>
          This feature is under development.
        </Panel>
      </div>
    </LoadingOverlay>
  );
}

export default CreateOperationComponent;
