import { ButtonGroup, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";

export default function WaypointsEntries(props) {
  const { selectedWaypoints, onChange, onFocus } = props;
  const numbersOfWaypoints = selectedWaypoints?.length;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>WP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      {selectedWaypoints.map((waypoint, i) => (
        <>
          {i === 0 && <FlightTakeoffIcon style={{ margin: "auto" }} />}
          {i + 1 === numbersOfWaypoints && (
            <FlightLandIcon style={{ margin: "auto" }} />
          )}
          {i > 0 && i + 1 < numbersOfWaypoints && (
            <span
              style={{ height: "12px", lineHeight: "12px", margin: "auto" }}
            >
              {i}
            </span>
          )}
          <input
            type="number"
            width={100}
            value={waypoint[1]}
            name={`waypointLatititude${i}`}
            onChange={(ev) => {
              selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
              onChange([...selectedWaypoints]);
            }}
          />
          <input
            width={100}
            type="number"
            value={waypoint[0]}
            name={`waypointLongitude${i}`}
            onChange={(ev) => {
              selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
              onChange([...selectedWaypoints]);
            }}
          />
          <ButtonGroup>
            <Button onClick={() => onFocus(waypoint)}>
              <CenterFocusIcon />
            </Button>
            <Button
              onClick={() => {
                onChange([
                  ...selectedWaypoints.slice(0, i),
                  ...selectedWaypoints.slice(i + 1),
                ]);
              }}
              disabled={i === 0 || i + 1 === numbersOfWaypoints}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </>
      ))}

      <Button
        variant="outlined"
        fullWidth
        style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
        onClick={() => {
          onChange([
            ...selectedWaypoints.slice(0, -1),
            [0, 0],
            selectedWaypoints[numbersOfWaypoints - 1],
          ]);
        }}
      >
        Add way point
      </Button>
    </div>
  );
}
