import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useEffect, useState } from "react";

function OverlayManagerComponent({
  toggleShowShipLayer,
  toggleShowAdsbLayer,
  toggleShowConstraintsLayer,
  toggleShowStaticConstraintsLayer,
  toggleShowBuildingLayer,
  toggleShowOperationVolumeLayer,
  togglesshowDroneIconLayer,
  toggleShowRainMapLayer,
  togglesshowAirMapSpace,
  togglesshowGnssLayer,
  toggleShowAnchorageLayer,
  toggleShowDronePortLayer,
  toggleSgBoundaryLayer,
  toggleSgPopulationDensity,
  toggleMapElevation,
  toggleShowCollisionLayer,
  isRemoteId,
}) {
  const [AISvalue, setAISvalue] = useState(false);
  const [ADSBvalue, setADSBvalue] = useState(false);
  const [ConstraintsValue, setConstraintsValue] = useState(true);
  const [StaticConstraintsValue, setStaticConstraintsValue] = useState(true);
  const [BuildingValue, setBuildingValue] = useState(false);
  const [OperationVolumeValue, setOperationVolumeValue] = useState(true);
  const [DroneIconValue, setDroneIconValue] = useState(true);
  const [RainMapValue, setRainMapValue] = useState(false);
  const [airMapSpaceValue, setAirMapSpaceValue] = useState(true);
  const [GnssIconValue, setGnssIconValue] = useState(false);
  const [anchorageValue, setAnchorageValue] = useState(true);
  const [dronePortValue, setDronePortValue] = useState(false);
  const [sgBoundaryValue, setSgBoundaryValue] = useState(false);
  const [sgPopulationDensityValue, setSgPopulationDensityValue] =
    useState(false);
  const [mapElevation, setMapElevation] = useState(false);
  const [collisionValue, setCollisionValue] = useState(true);

  useEffect(() => {
    // add key listener;
    const handleKeyDown = (event) => {
      // ctrl + 1 key
      if (event.ctrlKey && event.keyCode === 49) {
        event.preventDefault();
        handleVesselClick();
      }
      // ctrl + 2 key
      if (event.ctrlKey && event.keyCode === 50) {
        event.preventDefault();
        handleAdsbclick();
      }
      // ctrl + 3 key
      if (event.ctrlKey && event.keyCode === 51) {
        event.preventDefault();
        handleRainMapClick();
      }
      // ctrl + 4 key
      if (event.ctrlKey && event.keyCode === 52) {
        event.preventDefault();
        handleStaticConstraintsClick();
      }
      // ctrl + 5 key
      if (event.ctrlKey && event.keyCode === 53) {
        event.preventDefault();
        handleConstraintsClick();
      }
      // ctrl + 6 key
      if (event.ctrlKey && event.keyCode === 54) {
        event.preventDefault();
        handleBuildingClick();
      }
      // ctrl + 7 key
      if (event.ctrlKey && event.keyCode === 55) {
        event.preventDefault();
        handleOperationClick();
      }
      // ctrl + 8 key
      if (event.ctrlKey && event.keyCode === 56) {
        event.preventDefault();
        handleDroneIconClick();
      }
      // ctrl + 9 key
      if (event.ctrlKey && event.keyCode === 57) {
        event.preventDefault();
        handleAnchorageClick();
      }
      // ctrl + 0 key
      if (event.ctrlKey && event.keyCode === 48) {
        event.preventDefault();
        handleDronePortClick();
      }
      // ctrl + ← key
      if (event.ctrlKey && event.keyCode === 37) {
        event.preventDefault();
        handleSgBoundaryClick();
      }
      // ctrl + ↑ key
      if (event.ctrlKey && event.keyCode === 38) {
        event.preventDefault();
        handleSgPopulationDensityClick();
      }
      // ctrl + → key
      if (event.ctrlKey && event.keyCode === 39) {
        event.preventDefault();
        handleMapElevationClick();
      }
      // ctrl + ↓ key
      if (event.ctrlKey && event.keyCode === 40) {
        event.preventDefault();
        handleCollisionClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleAirMapSpaceClick = () => {
    togglesshowAirMapSpace();
    setAirMapSpaceValue(!airMapSpaceValue);
  };
  const handleConstraintsClick = () => {
    toggleShowConstraintsLayer();
    setConstraintsValue((s) => !s);
  };

  const handleStaticConstraintsClick = () => {
    toggleShowStaticConstraintsLayer();
    setStaticConstraintsValue((s) => !s);
  };

  const handleAdsbclick = () => {
    toggleShowAdsbLayer();
    setADSBvalue((s) => !s);
  };

  const handleVesselClick = () => {
    toggleShowShipLayer();
    setAISvalue((s) => !s);
  };
  const handleBuildingClick = () => {
    toggleShowBuildingLayer();
    setBuildingValue((s) => !s);
  };
  const handleDroneIconClick = () => {
    togglesshowDroneIconLayer();
    setDroneIconValue((s) => !s);
  };
  const handleOperationClick = () => {
    toggleShowOperationVolumeLayer();
    setOperationVolumeValue((s) => !s);
  };

  const handleRainMapClick = () => {
    toggleShowRainMapLayer();
    setRainMapValue((s) => !s);
  };

  const handleGnssClick = () => {
    togglesshowGnssLayer();
    if (GnssIconValue) {
      setGnssIconValue(false);
    } else {
      setGnssIconValue(true);
    }
  };

  const handleAnchorageClick = () => {
    toggleShowAnchorageLayer();
    setAnchorageValue((s) => !s);
  };

  const handleDronePortClick = () => {
    toggleShowDronePortLayer();
    setDronePortValue((s) => !s);
  };

  const handleSgBoundaryClick = () => {
    toggleSgBoundaryLayer();
    setSgBoundaryValue((s) => !s);
  };

  const handleSgPopulationDensityClick = () => {
    toggleSgPopulationDensity();
    setSgPopulationDensityValue((s) => !s);
  };

  const handleMapElevationClick = () => {
    toggleMapElevation();
    setMapElevation((s) => !s);
  };

  const handleCollisionClick = () => {
    toggleShowCollisionLayer();
    setCollisionValue((s) => !s);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          // style={{ width: "150px", marginLeft: "50px" }}
          style={{ width: "100%" }}
          value="AIS"
          selected={AISvalue}
          onChange={handleVesselClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            AIS{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="ADSB"
          selected={ADSBvalue}
          onChange={handleAdsbclick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            ADS-B{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="RAINMAP"
          selected={RainMapValue}
          onChange={handleRainMapClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            WX RADAR{" "}
          </Typography>
        </ToggleButton>
      </Grid>

      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="STATIC_CONSTRAINTS"
          selected={StaticConstraintsValue}
          onChange={handleStaticConstraintsClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            Static Constraints{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="DYNAMIC_CONSTRAINTS"
          selected={ConstraintsValue}
          onChange={handleConstraintsClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            Dynamics Constraints{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="BUILDING"
          selected={BuildingValue}
          onChange={handleBuildingClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            Building{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        {!isRemoteId ? (
          <ToggleButton
            size="small"
            style={{ width: "100%" }}
            value="OPERATIONVOLUME"
            selected={OperationVolumeValue}
            onChange={handleOperationClick}
          >
            <Typography
              style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
            >
              {" "}
              Ops Volume{" "}
            </Typography>
          </ToggleButton>
        ) : (
          <div />
        )}
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="DRONEICON"
          selected={DroneIconValue}
          onChange={handleDroneIconClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            Drone Icon{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      {/* <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="GNSS"
          selected={GnssIconValue}
          onChange={handleGnssClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            GNSS{" "}
          </Typography>
        </ToggleButton>
      </Grid> */}
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="ANCHORAGE"
          selected={anchorageValue}
          onChange={handleAnchorageClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            ANCHORAGE{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="DRONEPORT"
          selected={dronePortValue}
          onChange={handleDronePortClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            DRONE PORT{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="SGBOUNDARY"
          selected={sgBoundaryValue}
          onChange={handleSgBoundaryClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            SG BOUNDARY{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="SGPOPULATIONDENSITY"
          selected={sgPopulationDensityValue}
          onChange={handleSgPopulationDensityClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            SG POPULATION DENSITY{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="MAPELEVATION"
          selected={mapElevation}
          onChange={handleMapElevationClick}
          // disabled
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            MAP ELEVATION{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="COLLISION"
          selected={collisionValue}
          onChange={handleCollisionClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            COLLISION{" "}
          </Typography>
        </ToggleButton>
      </Grid>
      {/* <Grid item xs={4}>
        <ToggleButton
          size="small"
          style={{ width: "100%" }}
          value="DRONEICON"
          selected={airMapSpaceValue}
          onChange={handleAirMapSpaceClick}
        >
          <Typography
            style={{ fontSize: "8px", fontWeight: "bold", color: "black" }}
          >
            {" "}
            Airspace{" "}
          </Typography>
        </ToggleButton>
      </Grid> */}
    </Grid>
  );
}
export default OverlayManagerComponent;
