import { useEffect, useState, useContext, useRef } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LoadingOverlay from "react-loading-overlay";

import { useApi } from "../../../api/useApi";
import OperationsManagement from "../OperationsManagement";
import { AppColourTheme } from "../../../contexts/AppColourTheme";
import { heronTheme } from "../../../themes/heronTheme";
import { Colours } from "../../../themes/Colours";

export default function OperationsPanel({
  GroundSummary,
  AirborneSummary,
  OperationsSummary,
  selectedFlightData,
  telemetryData,
  handleRemoveOperationClick,
  selectedPlatformData,
  onUpdateOperationState,
  handleVisabilityClick,
  role,
  flightDataIsLoading,
  getUserOperations,
  getSelectedOperation,
  handleOpenFlightAuthorizationForced,
  platformList,
  trackerList,
  pilotList,
}) {
  const [operationsData, setOperationsData] = useState([]);
  const [operationsDataLoading, setOperationsDataLoading] = useState(true);
  const [stateFilter, setStateFilter] = useState("All");
  const referenceOperationsData = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);

  const opStates = [
    "All",
    "Contingent",
    "Nonconforming",
    "Activated",
    "Accepted",
    "Ended",
  ];

  useEffect(() => {
    referenceOperationsData.current = selectedFlightData;
  }, [selectedFlightData]);

  useEffect(() => {
    try {
      // const referenceDataHolder = referenceOperationsData.current;

      if (stateFilter === "All") {
        setOperationsData(selectedFlightData);
      } else {
        const filteredFlightData = selectedFlightData.filter(
          (flight) => flight.details.state === stateFilter
        );
        setOperationsData(filteredFlightData);
      }
    } finally {
      setIsFiltering(false);
    }
  }, [selectedFlightData]);

  const handleFilterOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterChange = (state) => {
    setIsFiltering(true);
    setStateFilter(state);
    handleFilterClose();
  };

  const api = useApi();
  const { colourTheme } = useContext(AppColourTheme);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div
        style={{
          borderRadius: "4px",
          border: "1px solid grey",
          marginBottom: "4px",
          marginTop: "4px",
        }}
      >
        <div
          id="Header"
          style={{
            textAlign: "center",
            color: Colours[colourTheme].midnightBlue,
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          {" "}
          OPERATIONS PANEL
        </div>
        <div
          id="SubHeader"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <div
            id="OpsCount"
            style={{
              // backgroundColor: Colours[colourTheme].opsSummary,
              marginBottom: "3px",
              borderRadius: "4px",
              flexGrow: "1",
            }}
          > */}
          <div
            id="TotalCount"
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                // flex: "1",
                textAlign: "center",
                borderTopLeftRadius: "4px",
                // backgroundColor: "black",
                // color: "white",
              }}
            >
              {" "}
              TOTAL
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            >
              {" "}
              {OperationsSummary}
            </div>
          </div>

          <div
            id="GroundCount"
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
              }}
            >
              {" "}
              GROUND
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            >
              {" "}
              {GroundSummary}
            </div>
          </div>
          <div
            id="AirCount"
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                // flex: "1",
                textAlign: "center",
                borderTopRightRadius: "4px",
              }}
            >
              {" "}
              AIR
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "18px",
                marginTop: "-3px",
              }}
            >
              {" "}
              {AirborneSummary}
            </div>
          </div>

          <div id="Filter" style={{}}>
            {" "}
            <Button onClick={handleFilterOpen}>
              {" "}
              <FilterListIcon
                sx={{ color: stateFilter === "All" ? "black" : "blue" }}
              />{" "}
            </Button>
            <Menu
              anchorEl={anchorEl}
              // getContentAnchorEl={null}
              open={Boolean(anchorEl)}
              onClose={handleFilterClose}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {opStates.map((state) => (
                <MenuItem
                  key={state}
                  onClick={() => handleFilterChange(state)}
                  sx={{ justifyContent: "space-between" }}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "14.5px",
                        display: "inline",
                        paddingRight: "5px",
                      }}
                    >
                      {state}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>

      {/* <Paper
        style={{
          // height: `40px`,
          // maxHeight: "calc(90% - 18px)",
          overflow: "auto",
          marginTop: 10,
          marginBottom: 10,
        }}
      > */}
      <div style={{ flex: 1, overflow: "auto" }}>
        <LoadingOverlay active={isFiltering} spinner text="Loading...">
          {flightDataIsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              // minHeight="100vh"
              sx={{ p: 2 }}
            >
              <CircularProgress style={{ color: "darkgrey" }} />
            </Box>
          ) : (
            //   <OperationsControl
            //     operationsData={selectedFlightData}
            //     telemetryData={telemetryData}
            //     onRemoveClick={handleRemoveOperationClick}
            //     selectedPlatformData={selectedPlatformData}
            //     onUpdateOperationState={onUpdateOperationState}
            //     // onVisabilityClick={handleVisabilityClick}
            //     // role={role}
            //   />

            <OperationsManagement
              operationsData={operationsData}
              telemetryData={telemetryData}
              onRemoveClick={handleRemoveOperationClick}
              selectedPlatformData={selectedPlatformData}
              onUpdateOperationState={onUpdateOperationState}
              // onVisabilityClick={handleVisabilityClick}
              // role={role}
              handleOpenFlightAuthorizationForced={
                handleOpenFlightAuthorizationForced
              }
              platformList={platformList}
              trackerList={trackerList}
              pilotList={pilotList}
            />
          )}
        </LoadingOverlay>
      </div>
    </div>
  );
}
