import React, { useState, useRef } from "react";

// Circle component to use as button to display severity level color
export default function Circle(props) {
  // pass in more props for better reusability
  const { size, color, borderColor } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const timeoutRef = useRef(null);
  const hoverDelay = 1000;

  const getHoverText = (circleColor, circleBorderColor) => {
    if (circleColor === "#AA4A44" && circleBorderColor === "#AA4A44") {
      return "Emergency";
    }
    if (circleColor === "#ffa500" && circleBorderColor === "#ffa500") {
      return "Warning";
    }
    if (circleColor === "#ffff01" && circleBorderColor === "#AA4A44") {
      return "Important";
    }
    if (circleColor === "#FFF300" && circleBorderColor === "#FFF300") {
      return "Error";
    }
    if (circleColor === "#9FC5EC" && circleBorderColor === "#9FC5EC") {
      return "Information";
    }
    return "";
  };

  const hoverText = getHoverText(color, borderColor);

  const handleMouseEnter = (event) => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
    timeoutRef.current = setTimeout(() => {
      setIsHovered(true);
      const { offsetX, offsetY } = event.nativeEvent;
      setPosition({ x: offsetX, y: offsetY });
    }, hoverDelay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current); // Clear the timeout when mouse leaves
    setIsHovered(false);
  };

  return (
    <div
      style={{
        position: "relative",
        width: size,
        height: size,
        borderRadius: size / 2,
        backgroundColor: color,
        border: `${size / 7.5}px solid ${borderColor}`,
        margin: "auto",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: position.y - 43,
            left: position.x - 28,
            transform: "translate(-50%, 0)",
            backgroundColor: "#878787",
            padding: "5px",
            borderRadius: "3px",
            color: "white",
            fontSize: "12px",
          }}
        >
          {hoverText}
        </div>
      )}
    </div>
  );
}
