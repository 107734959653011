/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useState } from "react";
import Button from "@/components/v1/Common/Button";
import PlatformType from "@/model/api/PlatformType";

interface PlatformTypeFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  platformType?: PlatformType | null;
  showMessage?: (message: string, isError?: boolean) => void;
  onSaveSucess: () => void;
  hasWritePrivilege: boolean;
}

export default function PlatformTypeForm({
  api,
  platformType,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
}: PlatformTypeFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    platform_type_uuid: platformType?.platform_type_uuid || "",
    model: platformType?.model || "",
    airframe: platformType?.airframe || "",
    takeoffspeed: platformType?.speed_takeoff || "",
    cruisingspeed: platformType?.speed_curising || "",
    landingspeed: platformType?.speed_landing || "",
    endurance: platformType?.endurance || "",
    radiorange: platformType?.radio_range || "",
    tsemean: platformType?.tse_mean || "",
    tsevariance: platformType?.tse_variance || "",
    weight: platformType?.weight || "",
  });

  const [formError, setFormError] = useState({
    model: "",
    airframe: "",
    takeoffspeed: "",
    cruisingspeed: "",
    landingspeed: "",
    endurance: "",
    radiorange: "",
    tsemean: "",
    tsevariance: "",
    weight: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const onAirframeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForms({
      ...form,
      airframe: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      model: "",
      airframe: "",
      takeoffspeed: "",
      cruisingspeed: "",
      landingspeed: "",
      endurance: "",
      radiorange: "",
      tsemean: "",
      tsevariance: "",
      weight: "",
    };

    if (form.model === "") {
      error.model = "Model is required";
      isValid = false;
    }

    if (form.airframe === "") {
      error.airframe = "Airframe is required";
      isValid = false;
    }

    if (form.takeoffspeed === "") {
      error.takeoffspeed = "Takeoff speed is required";
      isValid = false;
    }

    if (form.cruisingspeed === "") {
      error.cruisingspeed = "Cruising speed is required";
      isValid = false;
    }

    if (form.landingspeed === "") {
      error.landingspeed = "Landing speed is required";
      isValid = false;
    }

    if (form.endurance === "") {
      error.endurance = "Endurance is required";
      isValid = false;
    }

    if (form.radiorange === "") {
      error.radiorange = "Radio range is required";
      isValid = false;
    }

    if (form.tsemean === "") {
      error.tsemean = "TSE mean is required";
      isValid = false;
    }

    if (form.tsevariance === "") {
      error.tsevariance = "TSE variance is required";
      isValid = false;
    }

    if (form.weight === "") {
      error.weight = "Weight is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async () => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Saving platform details", false);
    setIsLoading(true);

    try {
      let response: any;
      if (platformType) {
        response = await api.updatePlatformTypeInfo(
          platformType.platform_type_uuid,
          { ...form }
        );
      } else {
        response = await api.submitNewPlatformType(form);
      }

      if (response.data) {
        setIsLoading(false);
        onSaveSucess();
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      if (showMessage) {
        showMessage((err as any).message, true);
      }
    }
  };

  const airframeType = ["Multi Rotor", "Fixed Wing", "Powered Lift", "Airship"];

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="name" className="label">
          UAV Model
        </label>
        <input
          type="text"
          id="model"
          className="input-text"
          defaultValue={form.model}
          onChange={onChange}
        />
        {formError.model && (
          <p className="text-red-500 text-sm mt-1">{formError.model}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          UAV Airframe Type
        </label>
        <select
          id="airframe"
          className="input-select"
          defaultValue={form.airframe}
          onChange={onAirframeTypeChange}
        >
          <option value="">Select Airframe Type</option>
          {airframeType?.map((airframe, index) => (
            <option key={airframe.replace(" ", "_")} value={airframe}>
              {airframe}
            </option>
          ))}
        </select>
        {formError.airframe && (
          <p className="text-red-500 text-sm mt-1">{formError.airframe}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Takeoff Speed (m/s)
          </label>
          <input
            type="number"
            id="takeoffspeed"
            className="input-text"
            defaultValue={form.takeoffspeed}
            onChange={onChange}
          />
          {formError.takeoffspeed && (
            <p className="text-red-500 text-sm mt-1">
              {formError.takeoffspeed}
            </p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            Cruising Speed (m/s)
          </label>
          <input
            type="number"
            id="cruisingspeed"
            className="input-text"
            defaultValue={form.cruisingspeed}
            onChange={onChange}
          />
          {formError.cruisingspeed && (
            <p className="text-red-500 text-sm mt-1">
              {formError.cruisingspeed}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Endurance (s)
          </label>
          <input
            type="number"
            id="endurance"
            className="input-text"
            defaultValue={form.endurance}
            onChange={onChange}
          />
          {formError.endurance && (
            <p className="text-red-500 text-sm mt-1">{formError.endurance}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            Landing Speed (m/s)
          </label>
          <input
            type="number"
            id="landingspeed"
            className="input-text"
            defaultValue={form.landingspeed}
            onChange={onChange}
          />
          {formError.landingspeed && (
            <p className="text-red-500 text-sm mt-1">
              {formError.landingspeed}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            RF Range (km)
          </label>
          <input
            type="number"
            id="radiorange"
            className="input-text"
            defaultValue={form.radiorange}
            onChange={onChange}
          />
          {formError.radiorange && (
            <p className="text-red-500 text-sm mt-1">{formError.radiorange}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            TSE (Mean)
          </label>
          <input
            type="number"
            id="tsemean"
            className="input-text"
            defaultValue={form.tsemean}
            onChange={onChange}
          />
          {formError.tsemean && (
            <p className="text-red-500 text-sm mt-1">{formError.tsemean}</p>
          )}
        </div>
      </div>
      <div className="flex mb-6">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            TSE (Variance)
          </label>
          <input
            type="number"
            id="tsevariance"
            className="input-text"
            defaultValue={form.tsevariance}
            onChange={onChange}
          />
          {formError.tsevariance && (
            <p className="text-red-500 text-sm mt-1">{formError.tsevariance}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            Weight (kg)
          </label>
          <input
            type="number"
            id="weight"
            className="input-text"
            defaultValue={form.weight}
            onChange={onChange}
          />
          {formError.weight && (
            <p className="text-red-500 text-sm mt-1">{formError.weight}</p>
          )}
        </div>
      </div>
      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}
