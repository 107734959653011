import React from "react";
import Button from "@material-ui/core/Button";
import LoadingOverlay from "react-loading-overlay";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddPermit from "./addPermitForm";
import { setSnackbarMessage } from "../../store/actions";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { convertZuluToLocalTime } from "../../api/timeConvert";

function InformationBox(props) {
  const {
    info,
    setUpdatingPermit,
    setUpdatingPermitURL,
    setUpdatingPermitMetaData,
    setUploadDialog,
    onDeletePermitClick,
  } = props;
  const handleClick = () => {
    // onClick(index);
  };

  const api = useApi();

  const handleEditClick = async (fileData) => {
    const metaDataResponse = await api.getPermitMetaData(fileData.Key);
    const objectMetaData = metaDataResponse.data;
    setUpdatingPermitMetaData(objectMetaData);
    const response = await api.getPermitDownloadURL(fileData.Key);
    const URLResponse = response.data;
    setUpdatingPermitURL(URLResponse);
    setUpdatingPermit(true);
    setUploadDialog(true);
  };

  const handleDownloadClick = async (fileData) => {
    const response = await api.getPermitDownloadURL(fileData.Key);
    const URLResponse = response.data;
    FileSaver.saveAs(URLResponse.uploadURL);
  };

  const handleDeleteClick = async (fileData) => {
    onDeletePermitClick(fileData);
  };

  return (
    <Accordion onClick={handleClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          display="inline"
          variant="body2"
          style={{ marginLeft: "3px" }}
        >
          {info.Key.replace("/", " - ").split(".pdf")[0].slice(0, -22)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0} style={{ marginBottom: "1px" }}>
          <Grid item xs={6} style={{ paddingLeft: "1px" }}>
            <DetailItem name="Permit Start" value={info.Key.slice(-25, -14)} />
            <DetailItem name="Permit Expiry" value={info.Key.slice(-15, -4)} />
            <DetailItem
              name="Last Modified"
              value={convertZuluToLocalTime(info.LastModified)}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                style={{ width: "130px", marginTop: "5px" }}
                onClick={(e) => handleEditClick(info)}
              >
                {" "}
                View Permit{" "}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                style={{ width: "130px", marginTop: "5px" }}
                onClick={() => handleDownloadClick(info)}
              >
                {" "}
                Download{" "}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ width: "130px", marginTop: "15px" }}
                onClick={() => handleDeleteClick(info)}
              >
                {" "}
                Delete Permit{" "}
              </Button>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function PermitList(props) {
  const {
    setUpdatingPermit,
    setUpdatingPermitURL,
    setUpdatingPermitMetaData,
    setUploadDialog,
    handleDeleteClick,
  } = props;
  return (
    <List>
      {props.permitList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={props.onClick}
          onEditPilotClick={props.onEditPilotClick}
          onDeletePilotClick={props.onDeletePilotClick}
          selected={props.selected === index}
          setUpdatingPermit={setUpdatingPermit}
          setUpdatingPermitURL={setUpdatingPermitURL}
          setUploadDialog={setUploadDialog}
          setUpdatingPermitMetaData={setUpdatingPermitMetaData}
          onDeletePermitClick={handleDeleteClick}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "3px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function PermitsList({ history, setUserMessage }) {
  const [loading, setLoading] = React.useState(false);
  const [uploadDialog, setUploadDialog] = React.useState(false);
  const [filePath, setFilePath] = React.useState("");
  const [selected, setSelected] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [permitList, setPermitList] = React.useState([]);
  const [updatingPilot, setUpdatingPilot] = React.useState(false);
  const [updatingPermit, setUpdatingPermit] = React.useState(false);
  const [updatingPermitURL, setUpdatingPermitURL] = React.useState("");
  const [updatingPermitMetaData, setUpdatingPermitMetaData] =
    React.useState("");

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();

  const { isAuthority, username } = useCognitoAuth();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // getPilots();
    setUpdatingPermit(false);
    // enableSocketConnection();

    const getData = async () => {
      setLoading(true);
      try {
        const response = await api.getPermits(username);
        if (response) {
          const URLResponse = response.data;
          let permitsListExtracted = [];
          for (const operator in URLResponse) {
            if (URLResponse[operator].downloadURL.Contents) {
              permitsListExtracted = permitsListExtracted.concat(
                URLResponse[operator].downloadURL.Contents
              );
            }
          }
          setPermitList(permitsListExtracted);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    getData();
  }, []);

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.pilot_name < b.pilot_name) {
      return -1;
    }
    if (a.pilot_name > b.pilot_name) {
      return 1;
    }
    return 0;
  }

  const handlePilotClick = (item) => {
    if (selected !== item) setSelected(item);
  };

  const handleEditPilotClick = () => {
    setOpenDialog(true);
    setUpdatingPilot(true);
  };

  const handleDeletePilotClick = () => {
    deletePilotInfo();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setUpdatingPilot(false);
  };

  const deletePilotInfo = async () => {
    try {
      const response = await api.deletePilot(
        permitList[selected].pilot_uuid,
        permitList[selected].pilot_username
      );
      if (response.data) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Pilot deleted",
            severity: "success",
          })
        );
        // getPilots();
        handleClose();
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const handleAddPermitClick = () => {
    setUpdatingPermit(false);
    setUploadDialog(true);
  };

  const handleCloseDialogue = () => {
    setUploadDialog(false);
  };

  const setUpdatingURLValue = (value) => {
    setUpdatingPermitURL(value);
  };

  const handleDeleteClick = async (selectedPermit) => {
    try {
      setLoading(true);
      const response = await api.deletePermit(username, selectedPermit.Key);
      if (response.data) {
        setLoading(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Permit deleted",
            severity: "success",
          })
        );
        // getPilots();
        handleClose();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };
  return (
    <LoadingOverlay active={loading} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddPermitClick}
        >
          Add Permit
        </Button>
      </Grid>
      <PermitList
        onClick={handlePilotClick}
        selected={selected}
        permitList={permitList}
        onEditPilotClick={handleEditPilotClick}
        onDeletePilotClick={handleDeletePilotClick}
        setUpdatingPermit={setUpdatingPermit}
        setUpdatingPermitURL={setUpdatingURLValue}
        setUploadDialog={setUploadDialog}
        setUpdatingPermitMetaData={setUpdatingPermitMetaData}
        handleDeleteClick={handleDeleteClick}
      />
      <Dialog open={uploadDialog}>
        <AddPermit
          setFilePath={setFilePath}
          handleClose={handleCloseDialogue}
          updatingPermit={updatingPermit}
          updatingPermitURL={updatingPermitURL}
          updatingPermitMetaData={updatingPermitMetaData}
        />
      </Dialog>
    </LoadingOverlay>
  );
}
