import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Stack,
  Alert,
  AlertTitle,
  Typography,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import React, { useState } from "react";
import { useApi } from "../../api/useApi";

export default function AuthorityOperationAlertPopup(props) {
  const api = useApi();
  const [open, setOpen] = useState(props.open);
  const [acknowledgeWarning, setAcknowledgeWarning] = useState(false);
  const severityColor = () => {
    if (props.severity === "Warning") {
      return "#ffa500";
    }
    if (props.severity === "Emergency") {
      return "red";
    }
    return "grey";
  };
  const colorTheme = severityColor();

  const closeHandler = (event, reason) => {
    event.preventDefault();
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      setAcknowledgeWarning(true);
      return;
    }
    props.closeHandler();
  };
  const acknowlegeHandler = (event) => {
    event.preventDefault();
    setAcknowledgeWarning(false);
    // TODO
    const msg = {
      severity: "Information",
      type: "ACKOWLEDGEMENT_RECEIVED",
      message: `Acknowledgment of "${props.message}" received`,
    };
    api.createLogMessage(msg);
    props.closeHandler();
  };
  return (
    <Dialog
      open={open === "open"}
      onClose={closeHandler}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "6px",
          outline: "4px solid #ffa500",
          // padding: "12px",
          maxWidth: "450px",
        },
      }}
    >
      {acknowledgeWarning && (
        <Alert severity="error">PLEASE ACKNOWLEDGE MESSAGE!</Alert>
      )}
      <DialogTitle>
        {" "}
        <Stack direction="row" spacing={2}>
          {" "}
          <WarningAmberIcon sx={{ fontSize: 32, color: "#ffa500" }} />{" "}
          <Typography variant="h5"> {props.title} </Typography>{" "}
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <List sx={{ maxHeight: "300" }}>
          {props.message.map((singleMessage, i) => {
            return (
              <ListItem
                key={`listindex${i + 1}`}
                component="div"
                disablePadding
              >
                <ListItemText primary={singleMessage} />
              </ListItem>
            );
          })}
        </List>
        {/* <Typography variant="h5">{props.
        }</Typography> */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button
          sx={{
            backgroundColor: "#ffa450",
            color: "black",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "#cc8340",
            },
          }}
          onClick={acknowlegeHandler}
        >
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
}
