/* eslint-disable import/order */
/**
 * Handles logic for displaying 3D models on Mapbox
 */
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import * as THREE from "three";

/**
 * Zooms 3D models accordingly to display on Mapbox map
 * @param {*} map Mapbox map
 * @returns Zoom factor
 */
function getZoomFactor(map, maxZoomScale) {
  const currZoom = map.getZoom();

  // After more than zoom level 17, set the scale to maxZoomScale
  if (currZoom > 17) {
    return maxZoomScale;
  }
  // doubles the scale of the model, for every one unit decrease in zoom level
  // with max scaling to 5 times the size of the model
  return Math.min(maxZoomScale * 2 ** (17 - currZoom), 5);
}

/**
 * Gets a sprite to display on Mapbox map
 * @param {*} sprite Sprite to display
 * @param {*} center Map center
 * @param {*} map Mapbox map
 * @returns Sprite with relevant fields set
 */
function getSprite(sprite, center, map) {
  const { model, scale, rotate, position, altitude, details, maxZoomScale } =
    sprite;
  const coord = mapboxgl.MercatorCoordinate.fromLngLat(position, altitude);
  const object = model.clone();
  /*
  console.log("Colour check:");
  console.log(object);
  */
  object.traverse((o) => {
    // eslint-disable-next-line no-param-reassign
    if (o.isMesh) o.userData = details;
  });
  const zoomFactor = getZoomFactor(map, maxZoomScale);
  object.position.set(
    coord.x - center.x,
    coord.y - center.y,
    coord.z - center.z
  );
  object.rotation.set(rotate[0], rotate[1], rotate[2]);
  object.scale.set(
    (sprite.fixedSize ? zoomFactor : coord.meterInMercatorCoordinateUnits()) *
      scale[0],
    (sprite.fixedSize ? zoomFactor : coord.meterInMercatorCoordinateUnits()) *
      scale[1],
    (sprite.fixedSize ? zoomFactor : coord.meterInMercatorCoordinateUnits()) *
      scale[2]
  );
  return object;
}

/**
 * Encapsulates a layer for 3D models that handles camera movements to display 3D models accurately
 */
export class ModelLayer {
  constructor(id, convertData) {
    this.id = id;
    this.convertData = convertData;
    this.type = "custom";
    this.renderingMode = "3d";
    this.raycaster = new THREE.Raycaster();
  }

  // eslint-disable-next-line class-methods-use-this
  makeScene() {
    const scene = new THREE.Scene();

    // TODO(danvk): fiddle with lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const light = new THREE.HemisphereLight(0xffffff, 0x080820, 1);
    scene.add(light);

    return scene;
  }

  onAdd(map, gl) {
    this.camera = new THREE.PerspectiveCamera();
    this.center = mapboxgl.MercatorCoordinate.fromLngLat(map.getCenter(), 0);
    const { x, y, z } = this.center;
    this.cameraTransform = new THREE.Matrix4().makeTranslation(x, y, z);
    this.map = map;
    this.scene = this.makeScene();
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;
  }

  async setData(...args) {
    this.data = args;
    await this.repaint();
  }

  async repaint() {
    const scene = this.makeScene();
    (await this.convertData(...this.data))
      .map((s) => {
        const a = getSprite(s, this.center, this.map);
        // console.log(a);
        return a;
      })
      .forEach((m) => {
        scene.add(m);
      });
    this.scene = scene;
  }

  getClickedObjectDetails(point) {
    const mouse = new THREE.Vector2();
    // scale mouse pixel position to a percentage of the screen's width and height
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;

    const camInverseProjection = this.camera.projectionMatrix.invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(
      camInverseProjection
    );
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(
      camInverseProjection
    );
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, viewDirection);

    // calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    if (intersects.length) {
      return intersects[0].object.userData;
    }
    return null;
  }

  render(gl, matrix) {
    // console.log(matrix);
    this.camera.projectionMatrix = new THREE.Matrix4()
      .fromArray(matrix)
      .multiply(this.cameraTransform);
    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }
}
