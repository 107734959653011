import React from "react";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useApi } from "../../api/useApi";

const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");

function descendingComparator(a, b) {
  const comp1 = formatDate(a.reference.time_created.value);
  const comp2 = formatDate(b.reference.time_created.value);

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
  },
}));

export default function AddPilotFormComponent({
  open,
  updating,
  getPilots,
  pilotData,
  handleClose,
  setSelectedOperations,
}) {
  const [operationNameSearch, setoperationNameSearch] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [pastOperations, setPastOperations] = React.useState([]);
  const api = useApi();
  const classes = useTableStyles();

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const promises = [];
    // promises.push(getPlatformData())

    try {
      promises.push(getOperations());
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getOperations = async () => {
    const pilotList = await api.getPilots();
    // // Pilot cannot access operator created operation, so use getPilotOperations for pilots
    // if (auth.isPilot) {
    //   const user_uuid = pilotList.data.filter((singlePilot) => {
    //     return singlePilot.pilot_email === auth.userInfo.email;
    //   });
    //   response = await api.getPilotOperations(user_uuid[0].pilot_uuid);
    // } else {
    //   response = await api.getOperations(true);
    // }
    const response = await api.getOperations(true);

    if (!response.data) return;
    const platformList = await api.getPlatforms();
    const platformTypeList = await api.getPlatformTypes();
    const logState = await api.getLogState();
    const operations = [];

    const logStateGrouped = logState.data.reduce((acc, log) => {
      const { operation_uuid } = log;
      acc[operation_uuid] = acc[operation_uuid] ?? [];
      if (log.operation_state === "Activated") {
        acc[operation_uuid].takeoff_time = log.event_time;
      } else if (log.operation_state === "Ended") {
        acc[operation_uuid].landing_time = log.event_time;
      }
      acc[operation_uuid].takeoff_time ??= 0;
      acc[operation_uuid].landing_time ??= 0;
      return acc;
    }, {});

    response.data.forEach((flightData) => {
      // Uncomment to swap back 2/2
      // operations.push(flightData.operation_json);
      // Uncomment to swap back 2/2
      const flightDataEdited = flightData.operation_json;
      flightDataEdited.details.pilot_name = "Unknown";
      flightDataEdited.details.platform_type = "Unknown";
      flightDataEdited.details.platform_name = "Unknown";
      flightDataEdited.details.takeoff_time = "Unknown";
      flightDataEdited.details.landing_time = "Unknown";
      // Take Off and Landing Timings
      if (logStateGrouped[flightDataEdited.reference.id]) {
        flightDataEdited.details.takeoff_time =
          logStateGrouped[flightDataEdited.reference.id].takeoff_time;
        flightDataEdited.details.landing_time =
          logStateGrouped[flightDataEdited.reference.id].landing_time;
      }

      // If no pilots created
      if (pilotList.data) {
        // Check pilot name from uuid
        const pilotNameArr = [];
        pilotList.data.forEach((singlePilot) => {
          // Only works when put in variable to compare
          const flightDataPilotUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.pilot_uuid;
          if (typeof flightDataPilotUuid === "object") {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            flightDataPilotUuid.forEach((flightDataPilotUuidSingle) => {
              if (flightDataPilotUuidSingle === pilotListPilotUuid) {
                pilotNameArr.push(singlePilot.pilot_name);
                // Break does not work with .ForEach
              }
            });
            flightDataEdited.details.pilot_name = pilotNameArr;
          } else {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            if (flightDataPilotUuid === pilotListPilotUuid) {
              flightDataEdited.details.pilot_name = [singlePilot.pilot_name];
              // Break does not work with .ForEach
            }
          }
        });
      }
      // If no platform created
      if (platformList.data) {
        const platformUuidArr = [];
        const platformTypeArr = [];
        const platformRegistration = [];
        // Check Platform name from uuid
        platformList.data.forEach((singlePlatform) => {
          // Only works when put in variable to compare
          const flightDataPlatformUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.platform_uuid;
          const pilotListPlatformUuid = singlePlatform.platform_uuid;
          if (typeof flightDataPlatformUuid === "object") {
            flightDataPlatformUuid.forEach((flightDataPlatformUuidSingle) => {
              if (flightDataPlatformUuidSingle === pilotListPlatformUuid) {
                platformRegistration.push(singlePlatform.platform_registration);
                platformUuidArr.push(singlePlatform.platform_callsign);
                // Break does not work with .ForEach
                const flightDataPlatformTypeUuid =
                  singlePlatform.platform_type_uuid;

                // If no platformType created
                if (platformTypeList.data) {
                  // Check Platform Type from uuid
                  platformTypeList.data.forEach((singlePlatformType) => {
                    // Only works when put in variable to compare
                    const pilotListPlatformTypeUuid =
                      singlePlatformType.platform_type_uuid;
                    if (
                      flightDataPlatformTypeUuid === pilotListPlatformTypeUuid
                    ) {
                      platformTypeArr.push(singlePlatformType.model);
                      // Break does not work with .ForEach
                    }
                  });
                }
              }
            });
            flightDataEdited.details.platform_name = platformUuidArr;
            flightDataEdited.details.platform_type = platformTypeArr;
            flightDataEdited.details.platform_registration =
              platformRegistration;
          } else if (flightDataPlatformUuid === pilotListPlatformUuid) {
            flightDataEdited.details.platform_name =
              singlePlatform.platform_callsign;
            // Break does not work with .ForEach
            const flightDataPlatformTypeUuid =
              singlePlatform.platform_type_uuid;

            // If no platformType created
            if (platformTypeList.data) {
              // Check Platform Type from uuid
              platformTypeList.data.forEach((singlePlatformType) => {
                // Only works when put in variable to compare
                const pilotListPlatformTypeUuid =
                  singlePlatformType.platform_type_uuid;
                if (flightDataPlatformTypeUuid === pilotListPlatformTypeUuid) {
                  flightDataEdited.details.platform_type =
                    singlePlatformType.model;
                  // Break does not work with .ForEach
                }
              });
            }
          }
        });
      }

      operations.push(flightDataEdited);
    });

    setPastOperations(operations);
  };

  const operationSearch = (row) => {
    return (
      row.reference.id
        .toLowerCase()
        .includes(operationNameSearch.toLowerCase()) ||
      row.reference.description
        .toLowerCase()
        .includes(operationNameSearch.toLowerCase())
    );
  };

  const handleOperationClick = (operationID) => {
    setSelectedOperations(operationID);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <LoadingOverlay active={loading} spinner>
        <div style={{ height: "400px", width: "440px", margin: "30px" }}>
          <Typography display="inline" variant="h6" style={{ color: "black" }}>
            Select Operation
          </Typography>
          <TextField
            label="Search Operation Name/ID"
            onChange={(e) => setoperationNameSearch(e.target.value)}
            fullWidth
          />
          <TableContainer>
            <Table className={classes.table} aria-label="a dense table">
              <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                  <TableCell className={classes.tableCell}>Operation</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Operation ID
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Status
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Pilot
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    Platform
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Created
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(pastOperations, getComparator("desc"))
                  .filter(operationSearch)
                  .slice(0, 7)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={row.reference.id}
                        className={classes.tableRow}
                        id={row.reference.id}
                        onClick={() => handleOperationClick(row)}
                      >
                        <TableCell
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {`${row.reference.description}`}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {row.reference.id}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {row.details.state}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {row.details.pilot_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {row.details.platform_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="right">
                          {row.reference?.time_created?.value &&
                            formatDate(row.reference.time_created.value)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </LoadingOverlay>
    </Dialog>
  );
}
