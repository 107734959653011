/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "@/components/v1/Common/Button";
import Platform from "@/model/api/Platform";
import { useState } from "react";
import InputMask from "react-input-mask";
import moment from "moment";
import PlatformType from "@/model/api/PlatformType";

interface PlatformFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  platform?: Platform | null;
  platformTypes: PlatformType[];
  showMessage?: (message: string, isError?: boolean) => void;
  onSaveSucess: () => void;
  hasWritePrivilege: boolean;
}

export default function PlatformForm({
  api,
  platform,
  platformTypes,
  showMessage,
  onSaveSucess,
  hasWritePrivilege,
}: PlatformFormProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForms] = useState({
    platform_uuid: platform?.platform_uuid || "",
    callsign: platform?.platform_callsign || "",
    registration: platform?.platform_registration || "",
    expiry: platform?.registration_expiry || "",
    platformType: platform?.platform_type_uuid || "",
    remotecontroller: platform?.remote_controller_sn || "",
    flightcontroller: platform?.flight_controller_sn || "",
  });

  const [formError, setFormError] = useState({
    callsign: "",
    registration: "",
    expiry: "",
    remotecontroller: "",
    flightcontroller: "",
    platformType: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const onPlatformTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForms({
      ...form,
      platformType: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      callsign: "",
      registration: "",
      expiry: "",
      remotecontroller: "",
      flightcontroller: "",
      platformType: "",
    };

    if (form.callsign === "") {
      error.callsign = "Calls sign is required";
      isValid = false;
    }

    if (form.registration === "") {
      error.registration = "Registration is required";
      isValid = false;
    }

    if (form.expiry === "") {
      error.expiry = "Expiry is required";
      isValid = false;
    }

    if (form.remotecontroller === "") {
      error.remotecontroller = "Remote controller is required";
      isValid = false;
    }

    if (form.flightcontroller === "") {
      error.flightcontroller = "Flight controller is required";
      isValid = false;
    }

    if (form.platformType === "") {
      error.platformType = "Platform type is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async () => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Saving platform details", false);
    setIsLoading(true);

    try {
      let response: any;
      if (platform) {
        response = await api.updatePlatformInfo(form);
      } else {
        response = await api.submitNewPlatform(form);
      }

      if (response.data) {
        setIsLoading(false);
        onSaveSucess();
      }
    } catch (err) {
      setIsLoading(false);
      if (showMessage) {
        showMessage((err as any).message, true);
      }
    }
  };

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="name" className="label">
          Call Sign
        </label>
        <input
          type="text"
          id="callsign"
          className="input-text"
          defaultValue={form.callsign}
          onChange={onChange}
        />
        {formError.callsign && (
          <p className="text-red-500 text-sm mt-1">{formError.callsign}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Remote Controller s/n
        </label>
        <input
          type="text"
          id="remotecontroller"
          className="input-text"
          defaultValue={form.remotecontroller}
          onChange={onChange}
        />
        {formError.remotecontroller && (
          <p className="text-red-500 text-sm mt-1">
            {formError.remotecontroller}
          </p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Flight Controller s/n
        </label>
        <input
          type="text"
          id="flightcontroller"
          className="input-text"
          defaultValue={form.flightcontroller}
          onChange={onChange}
        />
        {formError.flightcontroller && (
          <p className="text-red-500 text-sm mt-1">
            {formError.flightcontroller}
          </p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="username" className="label">
            Registration Number
          </label>
          <input
            type="text"
            id="registration"
            className="input-text"
            defaultValue={form.registration}
            onChange={onChange}
          />
          {formError.registration && (
            <p className="text-red-500 text-sm mt-1">
              {formError.registration}
            </p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="username" className="label">
            Registration Expiration Date
          </label>
          <InputMask
            id="expiry"
            mask="99/99/9999"
            maskChar={null}
            placeholder="dd/mm/yyyy"
            className="input-text"
            defaultValue={moment(form.expiry).format("DD/MM/YYYY")}
            onChange={onChange}
          />
          {formError.expiry && (
            <p className="text-red-500 text-sm mt-1">{formError.expiry}</p>
          )}
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="username" className="label">
          Platform Type
        </label>
        <select
          id="platformType"
          className="input-select"
          defaultValue={form.platformType}
          onChange={onPlatformTypeChange}
        >
          <option value="">Select Platform Type</option>
          {platformTypes?.map((platformType) => (
            <option
              key={platformType.platform_type_uuid}
              value={platformType.platform_type_uuid}
            >
              {platformType.model}
            </option>
          ))}
        </select>
        {formError.platformType && (
          <p className="text-red-500 text-sm mt-1">{formError.platformType}</p>
        )}
      </div>
      {hasWritePrivilege && (
        <Button
          type="primaryDark"
          isLoading={isLoading}
          text="Save"
          onClick={handleSave}
        />
      )}
    </div>
  );
}
