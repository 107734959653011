import Button from "@/components/v1/Common/Button";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import InputWaypoint from "@/components/v1/InputWaypoint";
import LatLng from "@/model/LatLng";
import Waypoint from "@/model/Waypoint";
import { useEffect, useState } from "react";
import { MdEmergencyShare } from "react-icons/md";
import moment from "moment";
import { useApi } from "@/api/useApi";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import { fetchJSON } from "@/utils/json_utils";
import { Anchorage, AnchorageDetail } from "@/model/anchorage/Anchorage";
import Feature from "@/model/anchorage/Feature";
import {
  Anchorage2,
  Subsection,
  Faf,
  TolName,
} from "@/model/anchorage/Anchorage2";
import { useSelector } from "react-redux";
import MapState from "@/model/MapState";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { getFutureDate } from "@/utils/date_utils";
import data from "./data";
import GeoJsonFile from "../../../../../lib/arcgis/Drone-Port-geojson.geojson";

interface OperationForm {
  intent?: string;
  pilot_uuid: string[];
  platform_uuid: string[];
  tracker_uuid: string[];
  altitude: number;
  altitude_reference: string;
  contingency_plans: {
    landing_point: number[][];
    safe_altitude: number;
  };
  description: string;
  time_start: string;
  faf: {
    name: string;
    tolNames: string;
  };
  subsection: string;
  corridor: string;
  outbound: boolean;
  time_buffer?: number;
  vertical_buffer?: number;
  lateral_buffer?: number;
  rth_altitude?: number;
  elevation?: number;
  ground_speed?: number;
  operation_type?: string;
}

interface PortOperationFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  className?: string;
  pilots: Pilot[];
  platforms: Platform[];
  trackers: Tracker[];
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
}

export default function PortOperationForm({
  api,
  className = "",
  pilots,
  platforms,
  trackers,
  onConfirmation,
  showMessage,
  goToLocation,
}: PortOperationFormProps) {
  const mapboxController = useSelector(
    (state: MapState) => state.maps.mapboxController
  );

  /* Form State */
  const [isLoading, setIsLoading] = useState(false);
  const [isSubsectionDropdownOpen, setSubsectionIsDropdownOpen] =
    useState(false);

  /* Anchorage Data */
  const [anchorages, setAnchorages] = useState<Anchorage2[]>([]);
  const [selectedAnchorage, setSelectedAnchorage] = useState<Anchorage2 | null>(
    null
  );
  const [selectedSubsection, setSelectedSubsection] =
    useState<Subsection | null>(null);
  const [selectedFAF, setSelectedFAF] = useState<Faf | null>(null);
  const [selectedTakeOffPoint, setSelectedTakeOffPoint] =
    useState<TolName | null>(null);

  /* Operation Date */
  const [departureDate, setDepartureDate] = useState<Date | null>(
    getFutureDate(3)
  );

  const [emergencyLandingWaypoints, setEmegencyLandingWaypoints] = useState<
    Waypoint[]
  >(data.initialEmergencyLandingPoint);

  /* Optional Configuration */
  const [customBuffer, setCustomBuffer] = useState(false);
  const [outbound, setoutbound] = useState(false);

  const handleAnchorageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const anchorage: Anchorage2 | undefined = anchorages.find(
      (a) => a.name === e.target.value
    );
    setSelectedAnchorage(anchorage || null);

    if (anchorage) {
      goToLocation({
        lat: anchorage.point[1],
        lng: anchorage.point[0],
      });
    }
  };

  const handleSubsectionChange = (subsection: Subsection) => {
    setSelectedSubsection(subsection);

    if (subsection) {
      mapboxController.highlightGeometry(subsection.geometry);
    } else {
      mapboxController.removeHighlight();
    }
  };

  const handleSubsectionHover = (e: React.MouseEvent<HTMLLIElement>) => {
    const subsection: Subsection | undefined =
      selectedAnchorage?.subsections.find(
        (s) => s.name === e.currentTarget.innerText
      );
    if (subsection) {
      mapboxController.highlightArea(subsection.geometry);
    }
  };

  const removeMapHighlight = () => {
    mapboxController.removeHighlight();
  };

  const handleFAFName = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const faf: Faf | undefined = selectedAnchorage?.faf.find(
      (f) => f.name === e.target.value
    );
    setSelectedFAF(faf || null);
  };

  const handleTakeOffChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const tolName: TolName | undefined = selectedFAF?.tolNames.find(
      (t) => t.name === e.target.value
    );
    setSelectedTakeOffPoint(tolName || null);

    if (tolName) {
      setEmegencyLandingWaypoints([
        {
          position: 1,
          latitude: tolName.point[1],
          longitude: tolName.point[0],
          isFirst: true,
          isLast: true,
        },
      ]);
    } else {
      setEmegencyLandingWaypoints(data.initialEmergencyLandingPoint);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "number"
          ? parseInt(e.target.value, 10)
          : e.target.value,
    });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: string
  ) => {
    if (
      type === "pilot_uuid" ||
      type === "platform_uuid" ||
      type === "tracker_uuid"
    ) {
      let { value } = e.target;
      if (e.target.value === "Select") {
        value = "";
      }
      setForm((prevForm) => ({
        ...prevForm,
        [type]: [value],
      }));
      return;
    }

    setForm({
      ...form,
      [type]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    if (name === "outbound") {
      setoutbound(e.target.checked);
      return;
    }

    if (name === "custom_buffer") {
      setCustomBuffer(e.target.checked);
      return;
    }

    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleDateChange = (date: Date | null) => {
    setDepartureDate(date);

    if (date) {
      const formattedDate = moment(date).toISOString();
      setForm({
        ...form,
        time_start: formattedDate,
      });
    }
  };

  const handleCheckAvalaibility = () => {
    showMessage && showMessage("Coming Soon", true);
  };

  const [form, setForm] = useState<OperationForm>({
    intent: "",
    pilot_uuid: [""],
    platform_uuid: [""],
    tracker_uuid: [""],
    altitude: 60,
    altitude_reference: "W84",
    contingency_plans: {
      landing_point: [[0, 0]],
      safe_altitude: 60,
    },
    description: "",
    time_start: "",
    faf: {
      name: "",
      tolNames: "",
    },
    subsection: "",
    corridor: "",
    outbound: false,
    time_buffer: 30,
    vertical_buffer: 0,
    lateral_buffer: 0,
    rth_altitude: 60,
    elevation: 0,
    ground_speed: 5,
    operation_type: "bvlos",
  });

  const [formError, setFormError] = useState({
    anchorage: "",
    subsection: "",
    faf: "",
    takeOffPoint: "",
    emergencyPoint: "",
    intent: "",
    pilot_uuid: "",
    platform_uuid: "",
    tracker_uuid: "",
    waypoints: "",
    time_buffer: "",
    vertical_buffer: "",
    lateral_buffer: "",
    rth_altitude: "",
    elevation: "",
    max_segment_length: "",
    airspace_optimised: "",
    transit_duration: "",
    altitude: "",
    altitude_reference: "",
    time_start: "",
    transit_duration_hour: "",
    transit_duration_minute: "",
    ground_speed: "",
    operation_type: "",
    description: "",
  });

  const validateForm = () => {
    let isValid = true;
    const error = {
      anchorage: "",
      subsection: "",
      faf: "",
      takeOffPoint: "",
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    };

    if (!selectedAnchorage) {
      error.anchorage = "Anchorage is required";
      isValid = false;
    }

    if (!selectedSubsection) {
      error.subsection = "Subsection is required";
      isValid = false;
    }

    if (!selectedFAF) {
      error.faf = "FAF is required";
      isValid = false;
    }

    if (!selectedTakeOffPoint) {
      error.takeOffPoint = "Take off point is required";
      isValid = false;
    }

    if (form.intent === "") {
      error.intent = "Intent is required";
      isValid = false;
    }

    if (form.pilot_uuid[0] === "") {
      error.pilot_uuid = "Pilot is required";
      isValid = false;
    }

    if (form.platform_uuid[0] === "") {
      error.platform_uuid = "Platform is required";
      isValid = false;
    }

    if (form.tracker_uuid[0] === "") {
      error.tracker_uuid = "Tracker is required";
      isValid = false;
    }

    const isValidEmergencyPoint = emergencyLandingWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidEmergencyPoint) {
      error.emergencyPoint = "Emergency point is required";
      isValid = false;
    }

    if (form.time_buffer === 0 || isNaN(form.time_buffer ?? 0)) {
      error.time_buffer = "Time buffer is required";
      isValid = false;
    }

    if (
      customBuffer &&
      (form.vertical_buffer === 0 || form.lateral_buffer === 0) &&
      (isNaN(form.vertical_buffer ?? 0) || isNaN(form.lateral_buffer ?? 0))
    ) {
      error.vertical_buffer = "Vertical buffer is required";
      isValid = false;
    }

    if (
      customBuffer &&
      (form.lateral_buffer === 0 || form.lateral_buffer === 0)
    ) {
      error.lateral_buffer = "Lateral buffer is required";
      isValid = false;
    }

    if (form.altitude === 0 || isNaN(form.altitude)) {
      error.altitude = "Altitude is required";
      isValid = false;
    }

    if (form.rth_altitude === 0 || isNaN(form.rth_altitude ?? 0)) {
      error.rth_altitude = "RTH altitude is required";
      isValid = false;
    }

    if (form.elevation === 0 || isNaN(form.elevation ?? 0)) {
      error.elevation = "Elevation is required";
      isValid = false;
    }

    if (form.time_start === "") {
      error.time_start = "Time start is required";
      isValid = false;
    }

    if (form.ground_speed === 0 || isNaN(form.ground_speed ?? 0)) {
      error.ground_speed = "Ground speed is required";
      isValid = false;
    }

    if (form.operation_type === "") {
      error.operation_type = "Operation type is required";
      isValid = false;
    }

    if (form.description === "") {
      error.description = "Description is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleResetForm = () => {
    onConfirmation(
      "Reset Confirmation",
      "Are you sure you to reset the form ?",
      (result) => {
        if (result) {
          resetForm();
        }
      }
    );
  };

  const handleSubmitOperation = async () => {
    if (!validateForm()) {
      showMessage && showMessage("Please fill all required fields");
      return;
    }

    showMessage && showMessage("Saving operation details", false);
    setIsLoading(true);

    const operationData = {
      ...form,
      contingency_plans: {
        landing_point: emergencyLandingWaypoints.map((waypoint) => [
          waypoint.longitude,
          waypoint.latitude,
        ]),
        safe_altitude: form.rth_altitude,
      },
      faf: {
        name: selectedFAF?.name,
        tol_name: selectedTakeOffPoint?.name,
      },
      subsection: selectedSubsection?.name,
      corridor: selectedAnchorage?.corridors[0].name,
    };

    try {
      delete operationData.intent;
      delete operationData.time_buffer;
      delete operationData.elevation;
      delete operationData.ground_speed;
      delete operationData.operation_type;
      delete operationData.rth_altitude;

      if (!customBuffer) {
        delete operationData.vertical_buffer;
        delete operationData.lateral_buffer;
      }

      const response = await api.submitPortOperation(operationData);
      if (response.status === 201) {
        showMessage && showMessage("Operation created successfully", true);
        resetForm();
      }
    } catch (error) {
      showMessage && showMessage("Error creating operation", false, true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const portData = await fetchJSON(GeoJsonFile);
      const portDataFeatures = portData.features || [];
      const anchoragesOptions = portDataFeatures.map((feature: Feature) => ({
        name: feature.properties.seamark_na,
        point: feature.geometry.coordinates[0][0],
        id: feature.properties.id,
        geometry: feature.geometry,
      }));

      const groupedAnchorages = anchoragesOptions.reduce(
        (acc: Anchorage, option: AnchorageDetail) => {
          if (option.name.toLowerCase().includes("corridor")) {
            acc.Corridors.push(option);
          } else if (option.name.toLowerCase().includes("section")) {
            acc.Subsections.push(option);
          } else {
            acc.faf.push(option);
          }
          return acc;
        },
        { Corridors: [], Subsections: [], faf: [] }
      );

      const availableAnchorages2 = [
        {
          name: "Eastern Anchorage",
          point: [103.88618933181681, 1.2642828293283515],
          subsections: groupedAnchorages.Subsections.map(
            (entry: Anchorage2) => ({
              ...entry,
              // "Section must be replaced to Subsection in order to read the data fro API Call"
              name: entry.name.includes("Section")
                ? entry.name.replace("Section", "Subsection")
                : entry.name,
            })
          ),
          corridors: groupedAnchorages.Corridors,
          faf: groupedAnchorages.faf.map((entry: Anchorage2) => ({
            name:
              entry.name === "Maritime Drone Estate Helipad"
                ? "Maritime Drone Estate"
                : entry.name,
            tolNames: [{ name: entry.name, point: entry.point }],
          })),
        },
      ];
      setAnchorages(availableAnchorages2);
      return availableAnchorages2;
    } catch (error) {
      showMessage &&
        showMessage("Error fetching anchorage information", false, true);
      throw error;
    }
  };

  const resetForm = () => {
    setEmegencyLandingWaypoints(data.initialEmergencyLandingPoint);
    setCustomBuffer(false);
    setDepartureDate(getFutureDate(3));
    setSelectedTakeOffPoint(null);
    setForm({
      intent: "",
      pilot_uuid: [""],
      platform_uuid: [""],
      tracker_uuid: [""],
      altitude: 60,
      altitude_reference: "W84",
      contingency_plans: {
        landing_point: [[0, 0]],
        safe_altitude: 60,
      },
      description: "",
      time_start: "",
      faf: {
        name: "",
        tolNames: "",
      },
      subsection: "",
      corridor: "",
      outbound: false,
      time_buffer: 30,
      vertical_buffer: 0,
      lateral_buffer: 0,
      rth_altitude: 60,
      elevation: 0,
      ground_speed: 5,
      operation_type: "bvlos",
    });
    setFormError({
      anchorage: "",
      subsection: "",
      faf: "",
      takeOffPoint: "",
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`h-full flex-col px-4 ${className}`}>
      <h5 className="font-medium mb-2">Operation Details</h5>
      {/* Port Selection */}
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">Anchorage</label>
          <select className="input-select" onChange={handleAnchorageChange}>
            <option>Select</option>
            {anchorages.map((anchorage) => (
              <option key={anchorage.name} value={anchorage.name}>
                {anchorage.name}
              </option>
            ))}
          </select>
          {formError.anchorage && (
            <p className="text-red-500 text-sm mt-1">{formError.anchorage}</p>
          )}
        </div>
        <div className="w-full">
          {/* <label className="label">Subsection</label>
          <select className="input-select" onChange={handleSubsectionChange}>
            <option>Select</option>
            {selectedAnchorage?.subsections.map((subsection) => (
              <option key={subsection.name} value={subsection.name}>
                {subsection.name}
              </option>
            ))}
          </select> */}
          <label className="label">Subsection</label>
          <div className="relative w-full">
            <button
              className="input-select"
              type="button"
              onClick={() =>
                setSubsectionIsDropdownOpen(!isSubsectionDropdownOpen)
              }
            >
              <span className="grow">
                {selectedSubsection ? selectedSubsection.name : "Select"}
              </span>
              {isSubsectionDropdownOpen ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </button>

            {isSubsectionDropdownOpen && selectedAnchorage && (
              <div className="absolute top-14 w-[100%] z-[150]">
                <ul className="dropdown-ul">
                  {selectedAnchorage?.subsections.map((subsection) => (
                    <li
                      key={subsection.name}
                      className="dropdown-li"
                      onMouseEnter={handleSubsectionHover}
                      onMouseLeave={() => removeMapHighlight()}
                      onClick={() => {
                        handleSubsectionChange(subsection);
                        setSubsectionIsDropdownOpen(false);
                      }}
                    >
                      {subsection.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {formError.subsection && (
            <p className="text-red-500 text-sm mt-1">{formError.subsection}</p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">FAF Name</label>
          <select className="input-select" onChange={handleFAFName}>
            <option>Select</option>
            {selectedAnchorage?.faf.map((faf) => (
              <option key={faf.name} value={faf.name}>
                {faf.name}
              </option>
            ))}
          </select>
          {formError.faf && (
            <p className="text-red-500 text-sm mt-1">{formError.faf}</p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Take-off Landing Point</label>
          <select className="input-select" onChange={handleTakeOffChange}>
            <option>Select</option>
            {selectedFAF?.tolNames.map((tolName) => (
              <option key={tolName.name} value={tolName.name}>
                {tolName.name}
              </option>
            ))}
          </select>
          {formError.takeOffPoint && (
            <p className="text-red-500 text-sm mt-1">
              {formError.takeOffPoint}
            </p>
          )}
        </div>
      </div>

      {/* Schedule View */}
      <RoundedDatePicker
        label="Start Time (Local Time)"
        value={departureDate}
        className="mb-2"
        onChange={(date) => handleDateChange(date)}
      />
      {formError.time_start && (
        <p className="text-red-500 text-sm mt-2">{formError.time_start}</p>
      )}

      <button
        className="mb-4 w-full text-center"
        onClick={handleCheckAvalaibility}
      >
        <span className="text-blue-500 font-medium">Check Availability</span>
      </button>

      {/* Emergency Point */}
      <div className="flex mb-2 items-center">
        <MdEmergencyShare size={18} color="gray" />
        <h5 className="ms-2 font-medium">Emergency Point</h5>
      </div>
      <div className="rounded-container p-2 mb-2">
        <InputWaypoint
          keyLabel="EP"
          allowMultiple={false}
          pickFromMapButton={true}
          waypointsData={emergencyLandingWaypoints}
          onWaypointChanged={(waypoints) =>
            setEmegencyLandingWaypoints(waypoints)
          }
          goToLocation={goToLocation}
        />
      </div>
      {formError.emergencyPoint && (
        <p className="text-red-500 text-sm -mt-2 mb-4">
          {formError.emergencyPoint}
        </p>
      )}

      {/* Operation Configuration */}
      <div className="mb-2">
        <label className="label">Speed (m/s)</label>
        <input
          type="number"
          name="ground_speed"
          className="input-text"
          defaultValue={form.ground_speed}
          onChange={handleTextChange}
        />
        {formError.ground_speed && (
          <p className="text-red-500 text-sm mt-1">{formError.ground_speed}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="altitude" className="label">
            Max Altitude (m)
          </label>
          <input
            type="number"
            name="altitude"
            className="input-text"
            defaultValue={form.altitude}
            onChange={handleTextChange}
          />
          {formError.altitude && (
            <p className="text-red-500 text-sm mt-1">{formError.altitude}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="rth_altitude" className="label">
            RTH Altitude (m)
          </label>
          <input
            type="number"
            name="rth_altitude"
            className="input-text"
            defaultValue={form.contingency_plans.safe_altitude}
            onChange={handleTextChange}
          />
          {formError.rth_altitude && (
            <p className="text-red-500 text-sm mt-1">
              {formError.rth_altitude}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="vertical_buffer" className="label">
            Vertical Buffer (m)
          </label>
          <input
            type="number"
            name="vertical_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            defaultValue={form.vertical_buffer}
            onChange={handleTextChange}
          />
          {formError.vertical_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.vertical_buffer}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Lateral Buffer (m)</label>
          <input
            type="number"
            name="lateral_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            defaultValue={form.lateral_buffer}
            onChange={handleTextChange}
          />
          {formError.lateral_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.lateral_buffer}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">Time Buffer (s)</label>
          <input
            type="number"
            name="time_buffer"
            className="input-text"
            defaultValue={form.time_buffer}
            onChange={handleTextChange}
          />
          {formError.time_buffer && (
            <p className="text-red-500 text-sm mt-1">{formError.time_buffer}</p>
          )}
        </div>
        <div className="w-full mr-2">
          <label htmlFor="elevation" className="label">
            Elevation (m)
          </label>
          <input
            type="number"
            className="input-text"
            name="elevation"
            defaultValue={form.elevation}
            onChange={handleTextChange}
          />
          {formError.elevation && (
            <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="custom_buffer"
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Custom Buffer</label>
      </div>
      <div className="flex items-center mt-4 mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="outbound"
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Inbound Operation</label>
      </div>

      <div className="mb-2">
        <label htmlFor="pilot" className="label">
          Pilot Name
        </label>
        <select
          name="pilot_uuid"
          onChange={(e) => handleSelectChange(e, "pilot_uuid")}
          className="input-select"
        >
          <option>Select</option>
          {pilots.map((pilot) => (
            <option key={pilot.pilot_uuid} value={pilot.pilot_uuid}>
              {pilot.pilot_username}
            </option>
          ))}
        </select>
        {formError.pilot_uuid && (
          <p className="text-red-500 text-sm mt-1">{formError.pilot_uuid}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="platform" className="label">
            Platform Callsign
          </label>
          <select
            name="platform_uuid"
            onChange={(e) => handleSelectChange(e, "platform_uuid")}
            className="input-select"
          >
            <option>Select</option>
            {platforms.map((platform) => (
              <option
                key={platform.platform_uuid}
                value={platform.platform_uuid}
              >
                {platform.platform_callsign}
              </option>
            ))}
          </select>
          {formError.platform_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.platform_uuid}
            </p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="tracker" className="label">
            Tracker Callsign
          </label>
          <select
            name="tracker_uuid"
            onChange={(e) => handleSelectChange(e, "tracker_uuid")}
            className="input-select"
          >
            <option>Select</option>
            {trackers.map((tracker) => (
              <option key={tracker.tracker_uuid} value={tracker.tracker_uuid}>
                {tracker.tracker_name}
              </option>
            ))}
          </select>
          {formError.tracker_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.tracker_uuid}
            </p>
          )}
        </div>
      </div>

      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="intent" className="label">
            Operation Intent
          </label>
          <input
            type="text"
            name="intent"
            className="input-text"
            defaultValue={form.description}
            onChange={handleTextChange}
          />
          {formError.intent && (
            <p className="text-red-500 text-sm mt-1">{formError.intent}</p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="operation_type" className="label">
            Type
          </label>
          <select
            className="input-select"
            name="operation_type"
            onChange={(e) => handleSelectChange(e, "operation_type")}
          >
            <option value="bvlos">BVLOS</option>
            <option value="vlos">VLOS</option>
          </select>
          {formError.operation_type && (
            <p className="text-red-500 text-sm mt-1">
              {formError.operation_type}
            </p>
          )}
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="description" className="label">
          Description
        </label>
        <textarea
          className="input-text"
          name="description"
          defaultValue={form.description}
          onChange={handleTextAreaChange}
        />
        {formError.description && (
          <p className="text-red-500 text-sm mt-1">{formError.description}</p>
        )}
      </div>

      <div className="flex mt-4 mb-8 pb-8">
        <Button
          type="light"
          size="medium"
          text="Reset"
          className="mr-2"
          disabled={isLoading}
          onClick={handleResetForm}
        />

        <Button
          type="primaryDark"
          size="medium"
          text="Submit"
          isLoading={isLoading}
          onClick={handleSubmitOperation}
        />
      </div>
    </div>
  );
}
