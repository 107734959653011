/* eslint-disable @typescript-eslint/no-explicit-any */
import toast from "react-hot-toast";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import UserAccess from "@/model/UserAccess";
import Button from "@/components/v1/Common/Button";
import { User } from "@/model/api/User";
import { useState, useEffect } from "react";
import PrivilegeForm from "../PrivilegeForm";
import {
  getAvailableScopesToAssign,
  addScopesToAssignedRole,
  removeScopesFromAssignedRole,
} from "./privilegeCheck";

type UserRolesArr = (
  | "administrator"
  | "airspace_manager"
  | "flight_manager"
  | "pilot"
)[];

interface UserAccessState {
  userAccess: UserAccess;
}
interface UserFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  api: any;
  user?: User | null;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onSaveSucess: () => void;
  availableRolesForAssignment?: UserRolesArr;
  organisationAvailablePrivileges: string[];
}

export default function UserForm({
  api,
  user,
  showMessage,
  onSaveSucess,
  availableRolesForAssignment,
  organisationAvailablePrivileges,
}: UserFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [userPrivileges, setUserPrivileges] = useState<string[]>([]);
  const [userPrivilegesOriginal, setUserPrivilegesOriginal] = useState<
    string[]
  >([]);
  const [isManagePrivilegesOpen, setisManagePrivilegesOpen] = useState(false);

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const [form, setForms] = useState({
    name: user?.given_name || "",
    username: user?.username || "",
    email: user?.email || "",
    phone: user?.contact || "",
    defaultRole: user?.current_user_role || "",
    roles: user?.user_roles || [],
  });

  const [formError, setFormError] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    defaultRole: "",
    roles: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
    if (e.target.name === "assigned-roles") {
      if (e.target.checked) {
        setForms({
          ...form,
          [e.target.id]: [...form.roles, e.target.value],
        });
        const newPrivileges = addScopesToAssignedRole(
          e.target.value,
          userPrivileges,
          organisationAvailablePrivileges
        );
        setUserPrivileges(newPrivileges);
      } else {
        const updateRoles = form.roles.filter(
          (singleRole) => singleRole !== e.target.value
        );
        setForms({
          ...form,
          [e.target.id]: updateRoles,
        });
        const newPrivileges = removeScopesFromAssignedRole(
          updateRoles,
          userPrivileges
        );
        setUserPrivileges(newPrivileges);
      }
    }
  };

  const onChangeDefaultRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setForms({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const error = {
      name: "",
      username: "",
      email: "",
      phone: "",
      defaultRole: "",
      roles: "",
    };

    if (form.username === "") {
      error.username = "Username is required";
      isValid = false;
    }

    if (form.email === "") {
      error.email = "Email is required";
      isValid = false;
    }

    if (!form.roles.length) {
      error.phone = "Select at least 1 role";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSave = async (isNewUser: boolean) => {
    // validate if form has an empty field
    if (!validateForm()) {
      return;
    }
    showMessage && showMessage("Saving user details", false);
    setIsLoading(true);

    // Update Role
    let errorMessage = false;
    try {
      if (isNewUser) {
        await api.createUser({
          username: form.username,
          user_roles: form.roles,
          email: form.email,
          privileges: userPrivileges,
          current_user_role: form.defaultRole,
          name: form.name,
          contact: form.phone,
        });
      } else if (
        user?.current_user_role !== form.defaultRole ||
        user?.user_roles !== form.roles
      ) {
        await api.updateUserRole({
          username: form.username,
          current_user_role: form.defaultRole,
          user_roles: form.roles,
        });
      }
    } catch (err: any) {
      errorMessage = true;
      showMessage && showMessage(err.message, false, true);
    }

    // Update Details
    try {
      if (
        user?.email !== form.email ||
        user?.given_name !== form.name ||
        user.contact !== form.phone
      ) {
        await api.updateUserDetails({
          username: form.username,
          email: form.email,
          name: form.name,
          contact: form.phone,
        });
      }
    } catch (err: any) {
      errorMessage = true;
      showMessage && showMessage(err.message, false, true);
    }

    // Update Scopes
    try {
      const hasUserPrivilegeChange =
        JSON.stringify(userPrivileges) !==
        JSON.stringify(userPrivilegesOriginal);
      if (!isNewUser && hasUserPrivilegeChange) {
        await api.updateUserPrivilege({
          username: form.username,
          privileges: userPrivileges,
        });
      }
    } catch (err: any) {
      errorMessage = true;
      showMessage && showMessage(err.message, false, true);
    }

    setIsLoading(false);
    if (showMessage) {
      if (!errorMessage) {
        showMessage("User Updated Successfully", true);
        onSaveSucess();
      }
    }
  };

  useEffect(() => {
    if (!user || !user.username) return;

    const getUserCurrentPrivilege = async () => {
      try {
        const userPrivileges = await api.getUserPrivilege(user.username);
        setUserPrivileges(userPrivileges.data.privileges);
        setUserPrivilegesOriginal(userPrivileges.data.privileges);
      } catch (e) {
        if (showMessage) showMessage((e as any).message, false, true);
      }
    };
    if (userAccess.privileges.includes("general.privilege.read"))
      getUserCurrentPrivilege();
  }, []);

  const handleEditPrivilege = () => {
    if (isManagePrivilegesOpen) return;
    setisManagePrivilegesOpen(true);
    const availableScopes = getAvailableScopesToAssign(
      form.roles,
      organisationAvailablePrivileges
    ).sort();

    const handleSaveAdvancedPrivilege = (newPrivileges: string[]) => {
      setUserPrivileges(newPrivileges);
    };

    const handleCloseAdvancedPrivilege = (toastId: any) => {
      setisManagePrivilegesOpen(false);
      toast.dismiss(toastId);
    };
    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full min-w-[50%] h-[90vh] bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex p-4 h-[8%] border-b border-b-gray-300 dark:border-b-gray-700">
            <h4>Advanced Privilege Settings</h4>
            <span className="grow" />
          </div>
          <div className="overflow-y-auto flex-grow p-4">
            <PrivilegeForm
              onSaveSucess={(newPrivileges: string[]) => {
                handleSaveAdvancedPrivilege(newPrivileges);
                handleCloseAdvancedPrivilege(t.id);
              }}
              userPrivileges={userPrivileges}
              organisationAvailablePrivileges={availableScopes}
              handleClose={() => handleCloseAdvancedPrivilege(t.id)}
              hasWritePrivilegeRights={userAccess.privileges.includes(
                "general.privilege.write"
              )}
            />
          </div>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
  };

  function toProperCase(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div className="flex-col p-4 w-full">
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Username*
        </label>
        <input
          disabled={!!user?.username}
          type="text"
          id="username"
          className="input-text"
          defaultValue={form.username}
          onChange={onChange}
        />
        {formError.username && (
          <p className="text-red-500 text-sm mt-1">{formError.username}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="name" className="label">
          Name
        </label>
        <input
          type="text"
          id="name"
          className="input-text"
          defaultValue={form.name}
          onChange={onChange}
        />
        {formError.name && (
          <p className="text-red-500 text-sm mt-1">{formError.name}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Email*
        </label>
        <input
          type="email"
          id="email"
          className="input-text"
          defaultValue={form.email}
          onChange={onChange}
        />
        {formError.email && (
          <p className="text-red-500 text-sm mt-1">{formError.email}</p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="username" className="label">
          Contact
        </label>
        <input
          type="text"
          id="phone"
          className="input-text"
          defaultValue={form.phone}
          onChange={onChange}
        />
        {formError.phone && (
          <p className="text-red-500 text-sm mt-1">{formError.phone}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="username" className="label">
          Assigned Roles*
        </label>
        {availableRolesForAssignment?.map((singleRole) => {
          return (
            <div className="flex items-center mb-4" key={singleRole}>
              <input
                type="checkbox"
                id="roles"
                value={singleRole}
                className="input-checkbox"
                name="assigned-roles"
                checked={form.roles.includes(singleRole)}
                onChange={onChange}
                disabled={
                  !userAccess.privileges.includes("general.privilege.write")
                }
              />
              <label className="ml-2 text-sm">
                {toProperCase(singleRole.replace("_", " "))}
              </label>
            </div>
          );
        })}
      </div>
      {userAccess.privileges.includes("general.privilege.read") && (
        <div
          className="flex items-center px-2 cursor-pointer"
          onClick={handleEditPrivilege}
        >
          <p className="ml-1 text-blue-500 text-sm font-medium">
            Advanced Privilege Setting
          </p>
        </div>
      )}
      {userAccess.privileges.includes("general.privilege.read") && (
        <div className="w-full mr-2 mb-5">
          <label htmlFor="platform" className="label">
            Default Role
          </label>
          <select
            name="default_role"
            id="defaultRole"
            defaultValue={form.defaultRole}
            onChange={onChangeDefaultRole}
            className="input-select"
          >
            {form.roles?.map((singleRole) => (
              <option key={singleRole} value={singleRole}>
                {toProperCase(singleRole.replace("_", " "))}
              </option>
            ))}
          </select>
        </div>
      )}
      {userAccess.privileges.includes("general.user.write") &&
        user?.username && (
          <Button
            type="primaryDark"
            isLoading={isLoading}
            text="Save"
            onClick={() => handleSave(false)}
          />
        )}
      {userAccess.privileges.includes("general.user.write") &&
        !user?.username && (
          <Button
            type="primaryDark"
            isLoading={isLoading}
            text="Create New User"
            onClick={() => handleSave(true)}
          />
        )}
    </div>
  );
}
