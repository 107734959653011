import SvgIcon from "@mui/material/SvgIcon";

export default function DroneIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="1em"
      height="1em"
      viewBox="36 0 120.000000 120.000000"
    >
      <path
        transform="translate(0.000000,200.000000) scale(0.09300000, -0.120000)"
        fill="#000000"
        stroke="none"
        d="M432 1358 c-7 -7 -61 -13 -139 -15 -246 -7 -260 -25 -25 -33 102 -3
157 -9 159 -16 2 -6 -5 -14 -17 -17 -14 -4 -20 -13 -20 -35 0 -17 -4 -34 -10
-37 -5 -3 -10 -15 -10 -26 0 -21 44 -44 68 -34 8 3 55 -9 105 -26 56 -19 102
-29 121 -26 61 9 66 -4 41 -100 -20 -71 -33 -387 -18 -396 26 -16 38 27 41
147 2 67 8 125 13 130 5 6 9 28 9 50 0 23 6 50 13 61 59 99 75 106 108 50 29
-48 45 -55 131 -55 82 0 108 12 133 61 17 33 52 39 62 12 3 -10 15 -28 26 -41
25 -27 36 -86 46 -252 7 -116 20 -170 40 -170 5 0 11 10 15 23 10 40 -13 335
-30 398 -21 74 -14 83 64 85 36 1 77 10 107 24 28 12 74 24 104 25 66 3 88 26
56 59 -8 8 -15 28 -15 45 0 21 -5 31 -15 31 -8 0 -15 7 -15 15 0 13 24 15 153
15 83 0 158 4 165 9 20 12 -61 21 -192 21 -86 0 -117 4 -134 16 -18 12 -26 13
-37 4 -11 -10 -58 -11 -188 -5 -220 9 -215 -8 11 -31 120 -13 162 -21 162 -31
0 -7 -7 -13 -15 -13 -10 0 -15 -10 -15 -26 l0 -27 -67 7 c-38 4 -135 20 -218
36 -177 35 -234 37 -358 10 -107 -23 -272 -50 -304 -50 -18 0 -23 6 -23 25 0
16 -6 25 -15 25 -8 0 -15 6 -15 14 0 14 2 15 218 37 175 18 141 33 -57 25
-115 -5 -171 -4 -176 3 -8 14 -28 14 -43 -1z"
      />
    </SvgIcon>
  );
}
