import { ButtonGroup, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";

export default function EmergencyLandingPointEntry(props) {
  const { emergencyLandingPoint, onChange, onFocus } = props;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>EP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      <div style={{ margin: "auto" }}>1</div>
      <input
        type="number"
        width={100}
        value={emergencyLandingPoint[1]}
        name="emergencyLandingPointLatititude"
        onChange={(ev) => {
          emergencyLandingPoint[1] = Number.parseFloat(ev.target.value);
          onChange([...emergencyLandingPoint]);
        }}
      />
      <input
        width={100}
        type="number"
        value={emergencyLandingPoint[0]}
        name="emergencyLandingPointLongitude"
        onChange={(ev) => {
          emergencyLandingPoint[0] = Number.parseFloat(ev.target.value);
          onChange([...emergencyLandingPoint]);
        }}
      />
      <ButtonGroup>
        <Button onClick={() => onFocus(emergencyLandingPoint)}>
          <CenterFocusIcon />
        </Button>
        <Button disabled>
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
}
