import React from "react";
import Tooltip from "../Tooltip";

interface SideBarMenuProps {
  iconDefault: string;
  iconActive: string;
  title: string;
  isActive: boolean;
  currentTheme?: string;
  onSelected?: () => void;
}

export default function SideBarMenu({
  iconDefault,
  iconActive,
  title,
  isActive,
  currentTheme,
  onSelected,
}: SideBarMenuProps) {
  return (
    <Tooltip title={title}>
      <div
        className={`sidebar-menu ${isActive && `sidebar-menu-selected`}`}
        onClick={onSelected}
      >
        <img
          src={isActive && currentTheme === "light" ? iconActive : iconDefault}
          alt="icon"
          className="w-7 h-7 m-auto pointer-events-none select-none"
        />
      </div>
    </Tooltip>
  );
}
