import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Paper from "@material-ui/core/Paper";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Message,
  Notifications,
  ExitToApp,
  ExpandMore,
  Minimize,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { getWSService, WebSocketService } from "../../../services/websocket";
import OperationsControl from "../OperationsControl";
import ConstraintOperationsControl from "../ConstraintOperationsControl";
import OverlayManager from "../../../components/OverlayManager";

const drawerWidth = 450;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
    background: "transparent",
    border: 0,
    maxHeight: "calc(100% - 50px)",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  contentTextCenter: {
    justifyContent: "center",
  },
}));
const summaryStyles = {
  root: {
    //  minHeight: 5 * 4,
    minHeight: 6 * 4,

    "&$expanded": {
      // minHeight: 12 * 4,
      minHeight: 6 * 4,
    },
  },
  content: {
    margin: "1px 0",
    "&$expanded": {
      margin: "3px 0",
    },
    justifyContent: "center",
  },
  expandIcon: {
    padding: 1,
  },
  expanded: {},
};

const detailsStyles = {
  root: {
    minHeight: 5 * 4,
    "&$expanded": {
      minHeight: 12 * 4,
    },
    paddingBottom: 0,
    paddingTop: 0,
    paddingRight: 5,
    paddingLeft: 5,
  },
};
const CompactExpansionPanelSummary =
  withStyles(summaryStyles)(AccordionSummary);
const CompactExpansionPanelDetails =
  withStyles(detailsStyles)(AccordionDetails);
const ConstraintPanel = React.memo(
  ({
    auth,
    setConstraintsData,
    selectedFlightData,

    selectedPlatformData,
    onRemoveOperation,
    onUpdateOperationState,
    isDrawerOpen = false,
    constraints,
    onopenConstrainMgrAuthority,
    handleOpenConstraintPanel,
    seteditConstraints,
    editConstrainStatus,
  }) => {
    const classes = useStyles();

    const [websocketMessage, setWebsocketMessage] = useState(null);
    const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);

    useEffect(() => {
      // getPlatformData()
      // getUserOperations()
      enableSocketConnection();
    }, []);

    useEffect(() => {
      if (!websocketMessage || !websocketMessage.type) return;

      if (websocketMessage.type === "state_change") {
        const { operationID } = websocketMessage;
        const newState = websocketMessage.new_state;

        const operations = [...selectedFlightData];
        const index = operations.findIndex((x) => x.id === operationID);
        if (index > -1) {
          operations[index].operation_state = newState;

          setWebsocketAlertOpen(true);
        }
      }
    }, [websocketMessage]);

    const enableSocketConnection = () => {
      WebSocketService.addMessageListener(handleMessage);
    };

    const handleMessage = (message) => {
      setWebsocketMessage(message);
    };

    const handleRemoveOperationClick = () => {
      onRemoveOperation();
    };

    const handleVisabilityClick = (visability, operationID) => {
      //     let operations = JSON.parse(JSON.stringify(selectedFlightData));
      //     const index = operations.findIndex((x) => x.reference.id === operationID);
      //     if (index > -1) {
      //       operations[index].visable = visability;
      //       setSelectedFlightData(operations);
      //     }
    };

    const handleUpdateOperation = (newOperationData) => {
      onUpdateOperationState();
      // let operations = JSON.parse(JSON.stringify(selectedFlightData));
      // console.log(operations);
      // const index = operations.findIndex(
      //   (x) => x.reference.id === newOperationData.reference.id
      // );
      // if (index > -1) {
      //   operations[index] = newOperationData;
      //   setSelectedFlightData(operations);
      // }
    };
    const [expanded, setExpanded] = useState();
    return (
      // <Drawer
      //   open={isDrawerOpen}
      //   variant="persistent"
      //   classes={{
      //     paper: classes.drawerPaper,
      //   }}
      //   anchor="left"
      // >
      <Paper style={{ background: "transparent", zIndex: 1 }}>
        {/* <Toolbar /> */}
        <div className={classes.drawerContainer}>
          <Accordion defaultExpanded>
            <CompactExpansionPanelSummary
              expandIcon={
                expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
              }
              id="panel2-header"
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Constraint Panel
              </Typography>
            </CompactExpansionPanelSummary>
            <CompactExpansionPanelDetails>
              <div id="opsManagement">
                <ConstraintOperationsControl
                  operationsData={selectedFlightData}
                  onRemoveClick={handleRemoveOperationClick}
                  onUpdateOperationState={onUpdateOperationState}
                  onVisabilityClick={handleVisabilityClick}
                  constraints={constraints}
                  onopenConstrainMgrAuthority={onopenConstrainMgrAuthority}
                  handleOpenConstraintPanel={handleOpenConstraintPanel}
                  seteditConstraints={seteditConstraints}
                  editConstrainStatus={editConstrainStatus}
                />
              </div>
            </CompactExpansionPanelDetails>
          </Accordion>
          {/* <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ content: classes.contentTextCenter }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginTop: "0px",
                }}
              >
                Constraint Panel
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div id="opsManagement">
                <ConstraintOperationsControl
                  operationsData={selectedFlightData}
                  telemetryData={telemetryData}
                  onRemoveClick={handleRemoveOperationClick}
                  selectedPlatformData={selectedPlatformData}
                  onUpdateOperationState={onUpdateOperationState}
                  onVisabilityClick={handleVisabilityClick}
                  constraints={constraints}
                  onopenConstrainMgrAuthority={onopenConstrainMgrAuthority}
                  seteditConstraints={seteditConstraints}
                  editConstrainStatus={editConstrainStatus}
                />
              </div>
            </AccordionDetails>
          </Accordion> */}
        </div>
      </Paper>
    );
  }
);

ConstraintPanel.displayName = "ConstraintPanel";

export default ConstraintPanel;
