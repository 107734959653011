import CryptoJS from "crypto-js";
import { store } from "@/store";
import axios from "axios";

export default function djiPilot2Integration(
  api,
  showMessage,
  userUuid,
  pilotName
) {
  const marsBaseUrl = store.getState().envVar["base_url-mars"].Value;
  const djiLiveStreamBaseUrl =
    store.getState().envVar["base_url-dji_livestream"].Value;
  const DJIAppKey = store.getState().envVar["dji-app-key"].Value;
  const DJIAppID = store.getState().envVar["dji-app-id"].Value;
  const DJIAppLicense = store.getState().envVar["dji-app-license"].Value;
  const DJIRTMPURL = `rtmp://${djiLiveStreamBaseUrl}/live/`;
  const DJIRTMPSecret = store.getState().envVar["dji-app-secret"].Value;

  async function verifyLicense() {
    const token = window.djiBridge.platformVerifyLicense(
      DJIAppID,
      DJIAppKey,
      DJIAppLicense
    );
    window.djiBridge.platformIsVerified();
    if (token) {
      alert("License Verified");
      showMessage &&
        showMessage("The license verification is successful", true);
      const workspaceID = window.djiBridge.platformSetWorkspaceId(userUuid);
      const accessToken = localStorage.getItem("accessToken");
      const waylineRegisterParams = JSON.stringify({
        host: `${marsBaseUrl}/djicloud/`, //  Stringify for DJI RC to accept hidden URL
        token: `${accessToken}`,
      });
      const ApiModule = window.djiBridge.platformLoadComponent(
        "api",
        waylineRegisterParams
      );

      const missionRegisterParams = JSON.stringify({});
      const MissionAPI = window.djiBridge.platformLoadComponent(
        "mission",
        missionRegisterParams
      );

      const ModuleLoaded =
        window.djiBridge.platformIsComponentLoaded("mission");

      const setNaming = window.djiBridge.platformSetInformation(
        "Heron AirBridge",
        "Heron AirBridge",
        "DJI Integration"
      );

      const LiveStreamParams = JSON.stringify({
        videoPublishType: "video-by-manual",
        statusCallback: " ",
      });

      const liveShareAPI = window.djiBridge.platformLoadComponent(
        "liveshare",
        LiveStreamParams
      );

      const LSModuleLoaded =
        window.djiBridge.platformIsComponentLoaded("liveshare");

      const SetLS =
        window.djiBridge.liveshareSetVideoPublishType("video-by-manual");

      // TODO FIX
      const RTMPSecret = CryptoJS.MD5(`${userUuid}${DJIRTMPSecret}`).toString();

      const RTMPParam = JSON.stringify({
        url: `${DJIRTMPURL}${userUuid}?secret=${RTMPSecret}`,
      });

      const SetLSParam = window.djiBridge.liveshareSetConfig(2, RTMPParam);

      const startLS = window.djiBridge.liveshareStartLive();

      const connectCallBack = window.djiBridge.platformIsComponentLoaded("api"); // Added this to prevent error from DJI RC

      const thingUsername = `${userUuid}/${RTMPSecret}`;
      const thingPassword = accessToken;

      const DJICloudModule_Params = JSON.stringify({
        host: `tcp://${djiLiveStreamBaseUrl}:1883`,
        connectCallBack: "connectCallback",
        username: thingUsername,
        password: thingPassword,
      });

      const DJICloudModule = window.djiBridge.platformLoadComponent(
        "thing",
        DJICloudModule_Params
      );

      // Set up MQTT Connection
      const Connect = window.djiBridge.thingConnect(
        JSON.stringify(thingUsername),
        JSON.stringify(thingPassword),
        ""
      );

      // Load WebSocket Module on DJI RC
      const WsParams = JSON.stringify({
        host: "wss://sampleurl.com/DJI_WS_Testing/", // REMEMBER TO CHANGE THIS
        token: "token",
        connectCallBack: " ",
      });

      const WsAPI = window.djiBridge.platformLoadComponent("ws", WsParams);
      const WsModuleLoaded = window.djiBridge.platformIsComponentLoaded("ws");
      //   console.log("WS Loaded", WsModuleLoaded);

      // Set up WebSocket Connection
      const ConnectWS = window.djiBridge.wsConnect(
        "wss://sampleurl.com/DJI_WS_Testing/", // REMEMBER TO CHANGE THIS
        "token",
        " "
      );

      // Load TSA Module on DJI RC (Sending Telemetry to FOH)
      const TSAParams = JSON.stringify({});
      const TSAAPI = window.djiBridge.platformLoadComponent("tsa", TSAParams);
      const TSAModuleLoaded = window.djiBridge.platformIsComponentLoaded("tsa");
      // console.log("TSA Loaded", TSAModuleLoaded);

      // Load Map Module on DJI RC
      const setMapUsername = window.djiBridge.mapSetUserName(`${userUuid}`);
      const setPrefix = window.djiBridge.mapSetElementPreName(
        "ProtectedAreaUnderS7ANA"
      );
      const MapParams = JSON.stringify({
        userName: `${pilotName}`,
        elementPreName: "ProtectedAreaUnderS7ANA",
      });
      const MapAPI = window.djiBridge.platformLoadComponent("map", MapParams);
      const MapModuleLoaded = window.djiBridge.platformIsComponentLoaded("map");
      // console.log("Map Loaded", MapModuleLoaded);

      alert(
        "Successful Loaded DJI Mission, TSA, WS, Map Element and Live Stream Module"
      );
    } else {
      showMessage &&
        showMessage(
          "Failed to verify the license. Please check the license or apply again.",
          false,
          true
        );
    }
  }

  //   async function saveRcInformation(userUuid) {
  //     try {
  //       const rcInformation = {
  //         sn: "drone8",
  //         online_status: true,
  //         device_callsign: "-",
  //         user_id: userUuid,
  //         user_callsign: pilotName,
  //       };
  //       const responses = await api?.saveRCInformation(rcInformation, userUuid);
  //       if (responses.status === 200) {
  //         alert("Remote Control Information Succesfully Stored");
  //       } else {
  //         alert("Failed to store Remote Control Information");
  //       }
  //     } catch (err) {
  //       showMessage && showMessage(err.message, false, true);
  //     }
  //   }

  try {
    const userUuid = verifyLicense();
    // saveRcInformation(userUuid);
  } catch (err) {
    showMessage && showMessage("Verification Failed", false, true);
  }
}
