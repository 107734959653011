import React, { useState, useRef, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, Switch } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Sensors, SensorsOff } from "@mui/icons-material";
import axios from "axios";
import { setSnackbarMessage } from "../../store/actions";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";
import { channels } from "../../config/channels";
import { messageTypes } from "../../config/messageTypes";
import useWebsocket from "../../hooks/useWebsocket";

// data used to create operation when enable monitor is clicked
const createSampleOperation = (trackerInfo) => {
  return {
    pilot_uuid: [process.env.REACT_APP_RID_OPERATION_PILOT_UUID],
    platform_uuid: [process.env.REACT_APP_RID_OPERATION_PLATFORM_UUID],
    tracker_uuid: [trackerInfo.tracker_uuid],
    waypoints: [
      [124.14698913971807, 84.02912511174881],
      [124.15454652184047, 84.02924605527377],
    ],
    vertical_buffer: 25,
    lateral_buffer: 25,
    time_buffer: 30,
    elevation: 0,
    max_segment_length: 500,
    airspace_optimised: false,
    altitude: 60,
    altitude_reference: "W84",
    time_start: trackerInfo.depatureTime,
    time_end: trackerInfo.arrivalTime,
    ground_speed: 5,
    operation_type: "vlos",
    description: trackerInfo.tracker_name,
    contingency_plans: {
      landing_point: [[124.14698913971807, 84.02912511174881]],
      safe_altitude: 60,
    },
  };
};

function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function SensorType(props) {
  const {
    monitoringTrackerList,
    recievingTelemetry,
    info,
    recievingTelemetryNoCoordinates,
  } = props;
  if (recievingTelemetry.includes(info.tracker_imei)) {
    if (recievingTelemetryNoCoordinates.includes(info.tracker_imei))
      return <Sensors sx={{ color: "#FFBF00" }} />;
    return <Sensors sx={{ color: "#2a9461" }} />;
  }
  return <SensorsOff key={`${info.tracker_uuid}-offswitch`} />;
}

export function TrackerMonitorList(props) {
  const [trackerList, setTrackerList] = React.useState([]);
  const [loadingPlatforms, setLoadingPlatforms] = React.useState(true);
  const [monitoringTrackerList, setMonitoringTrackerList] = React.useState([]); // trackers that are enabled in tracker tab
  const [trackerOperationUuid, setTrackerOperationUuid] = React.useState({}); // relation between tracker uuid and operation uuid
  const [recievingTelemetry, setRecievingTelemetry] = React.useState([]);
  const [recievingTelemetryNoCoordinates, setRecievingTelemetryNoCoordinates] =
    React.useState([]);
  const [timerId, setTimerId] = React.useState();
  const [enableTrackerOverlay, setEnableTrackerOverlay] = React.useState([]);
  const [disableTrackerOverlay, setDisableTrackerOverlay] = React.useState([]);
  const [telemetryData, setTelemetryData] = useState({});
  const [env, setEnv] = useState("");

  const api = useApi();
  const dispatch = useDispatch();

  const { selectedFlightData, OperationsSummary } = props;

  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });

  const envVar = useSelector((state) => state.envVar);

  useEffect(() => {
    const currenteEnvironment =
      envVar["base_url-sp"].Value === "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";
    setEnv(currenteEnvironment);
  }, []);

  const selectedTrackerDataRef = useRef([]);
  const selectedPlatformDataRef = useRef([]);
  const keyRef = useRef({});
  const teleObjRef = useRef({});

  const checkTelemetryData = () => {
    if (Object.keys(teleObjRef.current).length > 0) {
      const telemetryDataEdited = [];
      const telemetryNoCoordinatesEdited = [];
      const currentTime = Date.now();
      Object.keys(teleObjRef.current).forEach((singleTelemetry) => {
        // check if message older than 20 seconds
        const timePassed =
          currentTime - teleObjRef.current[singleTelemetry].timestamp;
        if (timePassed < 20 * 1000) {
          telemetryDataEdited.push(
            teleObjRef.current[singleTelemetry].trackerImei
          );
        }

        if (
          teleObjRef.current[singleTelemetry].puckResponses[0][1].position
            .lat === null
        )
          telemetryNoCoordinatesEdited.push(
            teleObjRef.current[singleTelemetry].trackerImei
          );
      });
      setRecievingTelemetry(telemetryDataEdited);
      setRecievingTelemetryNoCoordinates(telemetryNoCoordinatesEdited);
    } else {
      setRecievingTelemetry([]);
      setRecievingTelemetryNoCoordinates([]);
    }
  };

  useEffect(() => {
    const checkTrackerImei = (trackerImei) => {
      const dataTracker = [];
      selectedTrackerDataRef.current.map((data, index) => {
        return data.map((datas) => {
          return dataTracker.push(datas);
        });
      });
      const filterDataTracker = dataTracker.filter(function (tracker) {
        return tracker.data.tracker_imei === trackerImei;
      });
      return filterDataTracker;
    };
    const handleMessage = (data) => {
      if (!data) return;
      if (data?.operational_status !== "Undeclared") {
        const checkPlatformUuid = (puck_uuid) => {
          const dataPlatform = [];
          selectedPlatformDataRef.current.map((p, index) => {
            return p.map((datas) => {
              return dataPlatform.push(datas);
            });
          });
          // eslint-disable-next-line func-names
          const filterDataPlatform = dataPlatform.filter(function (platform) {
            return platform.data.puck_uuid === puck_uuid;
          });
          return filterDataPlatform;
        };
        const key = [data.extras.imei, data.operation_id].join("/");
        if (!keyRef.current[key]) {
          // making sure no duplicate keys in keyRef
          keyRef.current = {
            ...keyRef.current,
            [key]: true,
          };
        }
        // setTeleObj({
        //   ...teleObj,
        teleObjRef.current = {
          ...teleObjRef.current,
          [key]: {
            gufi: data.operation_id,
            trackerImei: data.extras?.imei,
            timestamp: Date.now(),
            puckResponses: [
              [
                null,
                {
                  error: null,
                  ...data,
                },
                checkPlatformUuid(data.extras.imei),
                checkTrackerImei(data.extras.imei)
                  ? checkTrackerImei(data.extras.imei)
                  : [],
              ],
            ],
          },
        };
        setTelemetryData(teleObjRef.current);
      }
    };

    const removeListener = channel?.addMessageListener(
      messageTypes.TELEMETRY,
      handleMessage
    );

    return () => {
      removeListener?.();
    };
  }, [channel]);

  React.useEffect(() => {
    getTrackerStatus();
    getTrackers();
    checkTelemetryData();
  }, [OperationsSummary]);

  // used to ensure loading overlay does not deactivate before op appears on operations management panel
  // as there is a delay before operation is created and it appears on operations management panel
  // user can click monitor switch multiple times, causing multiple same operations being created
  React.useEffect(() => {
    if (enableTrackerOverlay.length > 0 || disableTrackerOverlay.length > 0) {
      const listOfOperations = selectedFlightData.map((singleFlightData) => {
        return singleFlightData?.details?.puck_uuid[0];
      });
      const enableTrackerList = enableTrackerOverlay.filter(
        (element) => !listOfOperations.includes(element)
      );
      const disableTrackerList = disableTrackerOverlay.filter((element) =>
        listOfOperations.includes(element)
      );

      setEnableTrackerOverlay(enableTrackerList);
      setDisableTrackerOverlay(disableTrackerList);
    }
  }, [selectedFlightData]);

  React.useEffect(() => {
    // after 10 seconds of not recieving telemetry, will clear data. does not trigger if constantly recieve telemetry
    if (timerId) clearTimeout(timerId);
    checkTelemetryData();
    const timeoutAfterNoTelemetry = setTimeout(() => {
      setRecievingTelemetry([]);
      setRecievingTelemetryNoCoordinates([]);
    }, 1000 * 20);

    setTimerId(timeoutAfterNoTelemetry);

    return () => {
      clearTimeout(timeoutAfterNoTelemetry);
    };
  }, [telemetryData]);

  function compare(a, b) {
    if (a.platform_callsign < b.platform_callsign) {
      return -1;
    }
    if (a.platform_callsign > b.platform_callsign) {
      return 1;
    }
    return 0;
  }

  const getTrackerStatus = async () => {
    const response = await api.getAllOperationSelection();
    if (!response.data) return;
    try {
      const listOfMonitoredTrackers = response.data.map((singleOperation) => {
        if (singleOperation.operation_state === "Activated") {
          return singleOperation.operation_json.request.tracker_uuid[0];
        }
        return "";
      });
      setMonitoringTrackerList(listOfMonitoredTrackers);
      const trackerOperationList = {};
      response.data.forEach((singleOperation) => {
        if (singleOperation.operation_state === "Activated") {
          trackerOperationList[
            singleOperation.operation_json.request.tracker_uuid[0]
          ] = singleOperation.operation_uuid;
        }
        return "";
      });
      const updatedTrackerOperationUuid = {
        ...trackerOperationUuid,
        ...trackerOperationList,
      };
      setTrackerOperationUuid(updatedTrackerOperationUuid);
    } catch (e) {
      console.log("Error ", e);
    }
  };
  const getTrackers = async () => {
    try {
      const response = await api.getTracker();
      if (response.data) {
        response.data.sort(compare);
        setTrackerList(response.data);
        setLoadingPlatforms(false);
      }
    } catch (err) {
      setLoadingPlatforms(false);
      dispatch(
        setSnackbarMessage({
          message: err?.response?.data?.message,
          severity: "error",
        })
      );
    }
  };

  const pairUserTracker = async (tracker) => {
    const tokenData = parseJwt(api.jwtToken);
    const trackerUserPairing = {
      tracker_sn: tracker.tracker_sn,
      approved_user_list: [
        {
          user: tokenData.username,
          userGroups: tokenData["cognito:groups"],
        },
      ],
      env,
    };
    try {
      // user pairing testing
      const puckResponse = await PuckServer.pairUserTracker(trackerUserPairing);
      if (!puckResponse) {
        dispatch(
          setSnackbarMessage({
            message: "Error Pairing Tracker",
            severity: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbarMessage({
          message: "Error Pairing Tracker",
          severity: "error",
        })
      );
    }
  };
  const handleToggleMonitor = async (tracker, enabled) => {
    // setLoadingPlatforms(true);
    if (enabled) {
      const enableOverlay = [...enableTrackerOverlay, tracker.tracker_imei];
      setEnableTrackerOverlay(enableOverlay);
      pairUserTracker(tracker);
      const depatureTime = new Date();
      depatureTime.setMinutes(depatureTime.getMinutes() + 5);
      const arrivalTime = new Date();
      arrivalTime.setMinutes(arrivalTime.getMinutes() + 10);
      const operationDetails = {
        ...tracker,
        depatureTime: depatureTime.toISOString(),
        arrivalTime: arrivalTime.toISOString(),
      };
      const sampleOperation = createSampleOperation(operationDetails);
      try {
        const submitOperationResponse = await api.submitOperation(
          sampleOperation
        );

        if (submitOperationResponse?.status === 201) {
          const takeoffOperationResponse = await api.updateOperation(
            submitOperationResponse?.data?.operations[0]?.reference?.id,
            "Activated"
          );
          if (
            takeoffOperationResponse?.status === 200 ||
            takeoffOperationResponse?.status === 201
          ) {
            dispatch(
              setSnackbarMessage({
                message: `${tracker.tracker_name} Monitoring Enabled`,
                severity: "success",
              })
            );
          }
        }
      } catch (e) {
        console.log("Error ", e);
        dispatch(
          setSnackbarMessage({
            message: `Error, Failed to enable ${tracker.tracker_name} Monitoring`,
            severity: "error",
          })
        );
        const filteredEnableOverlay = enableOverlay.filter(
          (element) => element !== tracker.tracker_imei
        );
        setEnableTrackerOverlay(filteredEnableOverlay);
      }
    } else {
      const disableOverlay = [...disableTrackerOverlay, tracker.tracker_imei];
      setDisableTrackerOverlay(disableOverlay);
      try {
        const endedOperationResponse = await api.updateOperation(
          trackerOperationUuid[tracker.tracker_uuid],
          "Ended"
        );
        if (endedOperationResponse?.status === 200) {
          dispatch(
            setSnackbarMessage({
              message: `${tracker.tracker_name} Monitoring Disabled`,
              severity: "success",
            })
          );
        }
      } catch (e) {
        console.log("error ", e);
        dispatch(
          setSnackbarMessage({
            message: `Error, Failed to disable ${tracker.tracker_name} Monitoring`,
            severity: "error",
          })
        );
        const filteredDisableOverlay = disableOverlay.filter(
          (element) => element !== tracker.tracker_imei
        );
        setDisableTrackerOverlay(filteredDisableOverlay);
      }
    }
    getTrackerStatus();
    getTrackers();
  };

  return (
    <LoadingOverlay active={loadingPlatforms} spinner text="">
      <List>
        {trackerList.map((info, index) => (
          <ListItem
            key={`${info.tracker_uuid}-listitem`}
            sx={{ display: "block", "align-items": "center" }}
          >
            <Box sx={{ width: "100%" }}>
              <LoadingOverlay
                active={
                  enableTrackerOverlay.includes(info.tracker_imei) ||
                  disableTrackerOverlay.includes(info.tracker_imei)
                }
                spinner
                text=""
                style={{ width: "100" }}
              >
                <Grid container spacing={1} sx={{ boxShadow: 3 }}>
                  <Grid item xs={4}>
                    <Switch
                      key={`${info.tracker_uuid}-switch`}
                      checked={monitoringTrackerList.includes(
                        info.tracker_uuid
                      )}
                      color="primary"
                      onChange={(event) =>
                        handleToggleMonitor(info, event.target.checked)
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      key={`${info.tracker_uuid}-text`}
                      variant="body2"
                    >
                      {info.tracker_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <SensorType
                      monitoringTrackerList={monitoringTrackerList}
                      recievingTelemetry={recievingTelemetry}
                      info={info}
                      recievingTelemetryNoCoordinates={
                        recievingTelemetryNoCoordinates
                      }
                    />
                  </Grid>
                </Grid>
              </LoadingOverlay>
            </Box>
          </ListItem>
        ))}
      </List>
    </LoadingOverlay>
  );
}
