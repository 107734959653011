import IncidentReport from "@/model/IncidentReport";
import React from "react";
import { IoMdPerson } from "react-icons/io";

interface ItemReportProps {
  data: IncidentReport;
  onClick: (data: IncidentReport) => void;
  onSelected: (id: string) => void;
}

export default function ItemReport({
  data,
  onClick,
  onSelected,
}: ItemReportProps) {
  return (
    <div
      className="rounded-container-np flex-col mb-4 cursor-pointer"
      onClick={() => onClick(data)}
    >
      <div className="flex items-center">
        <h4 className="mx-2 mt-2">{data.title}</h4>
        <span className="grow" />
        <input
          type="checkbox"
          className="input-checkbox mx-2"
          //   disabled={isDeleting}
          //   checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSelected(data.reportId)}
        />
      </div>
      <hr className="my-2" />
      <div className="flex-col px-2">
        <p className="text-sm font-medium mt-2">{data.reportId}</p>
        <p className="text-sm">Reported by : {data.name}</p>
        <p className="text-sm">Reported on : {data.reportDate}</p>
      </div>
      <hr className="my-2" />
      <div className="flex items-center px-2 pb-2">
        <IoMdPerson />
        <span className="ml-2">
          {data.pilotName} - {data.aircraftModel}
        </span>
      </div>
    </div>
  );
}
