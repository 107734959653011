import { useEffect, useState } from "react";
import axios from "axios";
import { recommender } from "./helpers/iconRecommender";
import "./fonts/fonts.css";
import "../MapB/windmarker/fonts/fonts.css";
import "./view.css";

function WeatherCurrentView() {
  const [latestWeather, setWeather] = useState();
  const [rainfallText, setRainFallText] = useState();

  useEffect(() => {
    axios
      .get("https://api.data.gov.sg/v1/environment/24-hour-weather-forecast", {
        responseType: "json",
      })
      .then((res) => {
        const json = res.data;
        const resultItem = json.items[0];
        const generalWeather = resultItem.general;
        setWeather(generalWeather);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://api.data.gov.sg/v1/environment/rainfall", {
        responseType: "json",
      })
      .then((res) => {
        const json = res.data;
        const resultItem = json.items[0];
        const { readings } = resultItem;
        const count = readings.length;
        if (count >= 2) {
          const sortedReadings = readings
            .map((e) => e.value)
            .sort((l, r) => l - r);
          const first = sortedReadings[0];
          const last = sortedReadings[sortedReadings.length - 1];
          if (first === last) {
            setRainFallText(`${first} mm`);
          } else {
            setRainFallText(`${first}﹣${last} mm`);
          }
        } else if (count == 1) {
          setRainFallText(`${readings[0].value} mm`);
        }
      });
  }, []);

  if (latestWeather) {
    return (
      <div style={{ padding: "0px", fontSize: "5px" }}>
        <div
          style={{
            padding: "0px",
            fontSize: "7pt",
            textAlign: "center",
            fontWeight: "500",
            textTransform: "uppercase",
          }}
        >
          Current Weather
        </div>

        <div key="weathercontainer" className="weather-container">
          <div className="weather-title" key="title">
            <i
              className={`${recommender(latestWeather.forecast)} weather-icon`}
            />
            <span className="weather-title-text">{latestWeather.forecast}</span>
          </div>
          <div className="weather-details">
            <div className="weather-row">
              <i className="icon-ht-temperature weather-row-icon" />
              <span>{`${latestWeather.temperature.low}﹣${latestWeather.temperature.high}℃`}</span>
            </div>
            <div className="weather-row">
              <i className="icon-wind-wind weather-row-icon" />
              <span>{`${latestWeather.wind.direction} ${latestWeather.wind.speed.low}﹣${latestWeather.wind.speed.high} km/h`}</span>
            </div>
            <div className="weather-row">
              <i className="icon-ht-humidity weather-row-icon" />
              <span>{`${latestWeather.relative_humidity.low}﹣${latestWeather.relative_humidity.high}%`}</span>
            </div>
            <div className="weather-row">
              <i className="weather-row-icon icon-meteocons-rainy1" />
              <span>{rainfallText}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return "";
}

export default WeatherCurrentView;
