import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import FileSaver from "file-saver";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DroneImportedOperationList from "./DroneImportedOperationList";
import { useApi } from "../../../api/useApi";
import useCognitoAuth from "../../../hooks/useCognitoAuth";
import { setSnackbarMessage } from "../../../store/actions";

function DownloadToDevice({ waylineList, setWaylineList, getData }) {
  const [selectedFile, setSelectedFile] = useState({});

  const api = useApi();
  const { username } = useCognitoAuth();
  const dispatch = useDispatch();

  const handleDownloadOperation = async () => {
    try {
      if (!selectedFile) {
        alert("No file selected");
        return;
      }
      const filename = selectedFile.Key.split("/").pop();

      if (filename.toLowerCase().endsWith(".json")) {
        const DownloadFile = await api.getWaylineDownloadURL(selectedFile.Key);
        const URLResponse = DownloadFile.data;
        const URL = URLResponse.uploadURL;
        FileSaver.saveAs(URL, filename);
        setSelectedFile([]);
      } else if (filename.toLowerCase().endsWith(".kml")) {
        const DownloadFile = await api.getWaylineDownloadURL(selectedFile.Key);
        const URLResponse = DownloadFile.data;
        const URL = URLResponse.uploadURL;
        FileSaver.saveAs(URL, filename);
        setSelectedFile([]);
      } else if (filename.toLowerCase().endsWith(".zip")) {
        const DownloadFile = await api.getWaylineDownloadURL(selectedFile.Key);
        const URLResponse = DownloadFile.data;
        const URL = URLResponse.uploadURL;
        FileSaver.saveAs(URL, filename);
        setSelectedFile([]);
      } else {
        console.log("Unsupported file type");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleClearSelected = () => {
    setSelectedFile([]);
  };

  const deleteMissionfromCloud = async () => {
    try {
      if (!selectedFile) {
        alert("No file selected");
        return;
      }
      const filename = selectedFile.Key.split("/").pop();
      const filename2 = `Deconflict/${filename}`;
      const fileOwner = selectedFile.Key.split("/")[0];
      const response = await api.deleteWayline(fileOwner, filename2);
      if (response.data) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Mission deleted successfully",
            severity: "success",
          })
        );
        setWaylineList((prevList) =>
          prevList.filter((file) => file.Key !== selectedFile.Key)
        );
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Failed to deleted",
          severity: "error",
        })
      );
    }
    getData();
  };

  const handleDeleteMission = () => {
    deleteMissionfromCloud();
    setSelectedFile([]);
  };

  const handleSelectOperation = (selectedOperation) => {
    setSelectedFile(selectedOperation);
  };

  return (
    <div
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        width: "700px",
        // height: "500px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        style={{
          m: 10,
        }}
      >
        Select Uploaded Operation:
      </Typography>
      <div style={{ flex: 1, overflowY: "auto" }}>
        <DroneImportedOperationList
          waylineList={waylineList}
          selectedFile={selectedFile}
          handleSelectOperation={handleSelectOperation}
        />
        {/* <List
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {waylineList.map((file, index) => {
            const fileName = file.Key.split("/").pop();
            return (
              <ListItem
                key={file.key}
                button
                onClick={() => setSelectedFile(file)}
                style={{
                  outline: selectedFile === file ? "2px solid blue" : "none",
                  width: "120px",
                  height: "120px",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <CloudDownloadIcon fontSize="large" />
                <ListItemText
                  primary={fileName}
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    maxWidth: "100%",
                    wordWrap: "break-word",
                  }}
                  primaryTypographyProps={{
                    style: { maxWidth: "100%", fontSize: "inherit" },
                  }}
                />
              </ListItem>
            );
          })}
        </List> */}
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleDeleteMission}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Delete Mission
        </Button>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleClearSelected}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Clear Selected
        </Button>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "10px",
            marginBottom: "10px",
          }}
          onClick={handleDownloadOperation}
          color="primary"
          disabled={!selectedFile.Key}
        >
          Download to Device
        </Button>
      </div>
    </div>
  );
}

function DroneUploadedOperationComponent({
  handleOpenFlightAuthorizationForced,
  handleCloseAllDrawers,
}) {
  const [enableOverlay, setenableOverlay] = useState(false);
  const [waylineList, setWaylineList] = useState([]);
  const api = useApi();

  const { username } = useCognitoAuth();

  const getData = async () => {
    try {
      setenableOverlay(true);
      const wayline = await api.getWayline(username);
      if (wayline) {
        const URLResponse = wayline.data;
        let waylineListExtracted = [];
        for (const operator in URLResponse) {
          if (URLResponse[operator].downloadURL.Contents) {
            waylineListExtracted = waylineListExtracted.concat(
              URLResponse[operator].downloadURL.Contents
            );
          }
        }

        waylineListExtracted = waylineListExtracted.filter((file) => {
          return file.Key.includes("Deconflict/");
        });

        setWaylineList(waylineListExtracted);
      }
    } catch (err) {
      console.log(err);
    }
    setenableOverlay(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <LoadingOverlay active={enableOverlay} spinner>
      <div>
        <DownloadToDevice
          handleOpenFlightAuthorizationForced={
            handleOpenFlightAuthorizationForced
          }
          handleCloseAllDrawers={handleCloseAllDrawers}
          waylineList={waylineList}
          setWaylineList={setWaylineList}
          username={username}
          getData={getData}
        />
      </div>
    </LoadingOverlay>
  );
}

export default DroneUploadedOperationComponent;
