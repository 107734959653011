/**
 *
 * @param {string} suffix
 * @returns {string} css class that represents meteocons icons
 */
function makeClass(suffix) {
  const prefix = "icon-meteocons-";
  const iconClass = prefix + suffix;
  return iconClass;
}

/**
 * Recommends a CSS class that best fit forecast value.
 * Icons recommended will be of meteocons.
 * @param {string} forecast straight from data.gov.sg API.
 */
export function recommender(forecast) {
  if (!forecast) return "";
  const lowercased = forecast.toLowerCase();
  switch (lowercased) {
    case "sunny":
    case "fair":
    case "fair (day)":
    case "fair (night)":
    case "fair & warm":
      return makeClass("sun2");

    case "partly cloudy":
    case "partly cloudy (day)":
      return makeClass("cloudy");

    case "partly cloudy (night)":
      return makeClass("cloud");

    case "cloudy":
      return makeClass("cloudy1");

    case "hazy":
    case "slightly hazy":
    case "mist":
    case "fog":
      return makeClass("windy"); // windy icon looks like haze on a sun.

    case "windy":
    case "windy, rain":
    case "windy, showers":
    case "windy, cloudy":
    case "strong winds, rain":
    case "strong winds, showers":
      return makeClass("wind");

    case "drizzle":
    case "light rain":
    case "light showers":
      return makeClass("rainy");

    case "moderate rain":
    case "showers":
      return makeClass("rainy1");

    case "heavy rain":
    case "heavy showers":
      return makeClass("rainy3");

    case "passing showers":
      return makeClass("windy2");

    case "thundery showers":
      return makeClass("lightning1");

    case "heavy thundery showers":
      return makeClass("lightning3");

    case "heavy thundery showers with gusty winds":
      return makeClass("lightning4");

    case "overcast":
      return makeClass("cloud4");

    default:
      return makeClass("cloud1");
  }
}
