import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingOverlay from "react-loading-overlay";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddRemoteIdFrom from "../AddRemoteIdForm";
import { setSnackbarMessage } from "../../store/actions";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { convertZuluToLocalTime } from "../../api/timeConvert";

const InformationBox = class InformationBox extends React.Component {
  handleClick = () => {
    this.props.onClick(this.props.index);
  };

  handleEditClick = () => {
    this.props.onEditRemoteIdClick();
  };

  handleDeleteClick = () => {
    this.props.onDeleteRemoteIdClick();
  };

  render() {
    const { info } = this.props;
    return (
      <Accordion onClick={this.handleClick}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            display="inline"
            variant="body2"
            style={{ marginLeft: "3px" }}
          >
            {info.username}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ marginBottom: "1px" }}>
            <Grid item xs={6} style={{ paddingLeft: "1px" }}>
              <DetailItem name="Username" value={info.username} />
              <DetailItem name="Email" value={info.email} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  style={{ width: "130px", marginTop: "5px" }}
                  onClick={this.handleEditClick}
                >
                  {" "}
                  Edit Profile{" "}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={{ width: "130px", marginTop: "15px" }}
                  onClick={this.handleDeleteClick}
                >
                  {" "}
                  Delete Profile{" "}
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

function RemoteIdList(props) {
  return (
    <List component="div">
      {props.remoteIdList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={props.onClick}
          onEditRemoteIdClick={props.onEditRemoteIdClick}
          onDeleteRemoteIdClick={props.onDeleteRemoteIdClick}
          selected={props.selected === index}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "3px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function RemoteIdsComponent() {
  const [selected, setSelected] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [remoteIdList, setRemoteIdList] = React.useState([]);
  const [loadingRid, setLoadingRid] = React.useState(true);
  const [updatingRemoteId, setUpdatingRemoteId] = React.useState(false);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();

  const dispatch = useDispatch();
  React.useEffect(() => {
    getRemoteId();
  }, []);

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.username < b.username) {
      return -1;
    }
    if (a.username > b.username) {
      return 1;
    }
    return 0;
  }
  const getRemoteId = async () => {
    try {
      const response = await api.getRemoteId();
      if (response.data) {
        response.data.sort(compare);
        setRemoteIdList(response.data);
        setLoadingRid(false);
      }
    } catch (err) {
      setLoadingRid(false);
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const handleRemoteIdClick = (item) => {
    if (selected !== item) setSelected(item);
  };

  const handleEditRemoteIdClick = () => {
    setOpenDialog(true);
    setUpdatingRemoteId(true);
  };

  const handleDeleteRemoteIdClick = () => {
    deleteRemoteId();
  };

  const handleAddRemoteIdClick = () => {
    setOpenDialog(true);
    setUpdatingRemoteId(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setUpdatingRemoteId(false);
  };

  const handleRemoteIdUpdate = (newRemoteIdData) => {
    const newRemoteIdList = remoteIdList;
    const index = remoteIdList.findIndex(
      (x) => x.username === newRemoteIdData.username
    );
    newRemoteIdList[index] = newRemoteIdData;
    setRemoteIdList(newRemoteIdList);
  };

  const deleteRemoteId = async () => {
    try {
      const response = await api.deleteRemoteId(
        remoteIdList[selected].username
      );
      if (response.data) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Remote Id successfully deleted",
            severity: "success",
          })
        );
        getRemoteId();
        handleClose();
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <LoadingOverlay active={loadingRid} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddRemoteIdClick}
        >
          {" "}
          Add Remote Id{" "}
        </Button>
      </Grid>
      <RemoteIdList
        onClick={handleRemoteIdClick}
        selected={selected}
        remoteIdList={remoteIdList}
        onEditRemoteIdClick={handleEditRemoteIdClick}
        onDeleteRemoteIdClick={handleDeleteRemoteIdClick}
      />
      <AddRemoteIdFrom
        open={openDialog}
        handleClose={handleClose}
        getRemoteId={getRemoteId}
        remoteIdData={selected !== null ? remoteIdList[selected] : null}
        updating={updatingRemoteId}
        handleRemoteIdUpdate={handleRemoteIdUpdate}
      />
      {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
    </LoadingOverlay>
  );
}
