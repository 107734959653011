import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useSound from "use-sound";
import { makeStyles } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import NotificationSound from "../../alert_sound.mp3";
import { useApi } from "../../api/useApi";
import AssetManagement from "../../components/AssetManagement";
import PortOperationsComponent from "./PortAuthorization";
import ReplayFunction from "./FlightReplayAuthority";
import DroneOperation from "./Drone_Integration/index";
import { WebSocketService } from "../../services/websocket";
import MapB from "../../components/MapB";
import OperatorManagementTab from "../../components/OperatorManagementTab";
import PilotLicenseExpiredDialog from "../../components/PilotsLicenseExpired";
import Schedules from "../../components/Schedules";
import Sidebar from "../../components/Sidebar";
import SnackbarMessage from "../../components/SnackbarMessage";
import TrackerMonitor from "../../components/TrackerMonitor";
import AuthorityOperationAlertPopup from "../../components/AuthorityOperationAlertDialog/AuthorityOperationAlertPopup";
import ActionMessagesTable from "../../components/ActionMessagesTable";
import IncidentReport from "../../components/IncidentReporting";
import WeatherCurrent from "../../components/Weather/weatherCurrentView";
import WeatherForecast from "../../components/Weather/weatherForecastView";
import Tasks from "./Tasks";
import ConstraintPanel from "./ConstrainPanel";
import PuckServer from "../../services/puckserver";
import {
  getShipData,
  setSnackbarMessage,
  getADSBDataPaid,
  getADSBDataFree,
  setEditConstraint,
  getairMapSpaceData,
  setStateConformance,
} from "../../store/actions";
import WebsocketAlert from "../../components/WebsocketAlert";
import { channels } from "../../config/channels";
import { messageTypes } from "../../config/messageTypes";
import { AppColourTheme } from "../../contexts/AppColourTheme";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import useWebsocket from "../../hooks/useWebsocket";
import { getConvert } from "../../services/convertVertices";
import { Colours } from "../../themes/Colours";
import ConstraintMgrAuthority from "./ConstraintMgrAuthority";
import FlightAuthorization from "./FlightAuthorization";
import FlightAuthorizationAuthority from "./FlightAuthorizationAuthority";
import OperationsPanel from "./OperationsPanel";
import ProposeAuthorization from "./ProposeAuthorization";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    zIndex: 99,
    paddingTop: 66,
    maxHeight: "calc(100% - 50px)",
    height: "auto",
  },
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    position: "sticky",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function useNotifications() {
  // const messages = useRef([]);
  const [notifications, setNotifications] = useState([]);
  const [haveNewNotifications, setHaveNewNotifications] = useState(false);
  const [olderNotifications, setOlderNotifications] = useState([]);
  const [olderNotificationsCount, setOlderNotificationsCount] = useState(false); // set to true to retrieve older notifications
  const api = useApi();
  // const { isAuthority } = useCognitoAuth();

  // checking for new notifs
  // useEffect(() => {
  //   console.log("messages: ", messages);
  //   console.log("notifications, ", notifications);
  //   if (
  //     messages?.current?.length !== 0 &&
  //     notifications?.length !== messages?.current?.length
  //   ) {
  //     console.log("set messages current ");
  //     setHaveNewNotifications(true);
  //   }
  //   messages.current = notifications;
  //   console.log("havNEEEEW", haveNewNotifications);
  // }, [notifications]);

  // useEffect(() => {
  //   // console.log("hhavenewnotif protected: ", haveNewNotifications);
  //   // console.log("notfs: ", notifications);
  //   // console.log("messages :", messages);
  //   try {
  //     const lastNotif = notifications?.[0];
  //     const lastNotifSplit = isAuthority
  //       ? lastNotif?.SK?.S.split("#")
  //       : lastNotif?.SK.split("#");
  //     const notifSortKey = lastNotifSplit[1];

  //     const lastMsg = messages?.current?.[0];
  //     const lastMsgSplit = isAuthority
  //       ? lastMsg?.SK?.S.split("#")
  //       : lastMsg?.SK.split("#");
  //     const messageSortKey = lastMsgSplit[1];

  //     if (notifSortKey != messageSortKey) {
  //       console.log("difference found");
  //       setHaveNewNotifications(true);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   messages.current = notifications;

  // }, [notifications]);

  // useEffect(() => {
  //   if (notifications && olderNotificationsCount) {
  //     const olderNotificationList = olderNotifications;
  //     if (olderNotificationList.length > 0) {
  //       const data = {
  //         lastEvaluatedKey: {
  //           PK: {
  //             S: olderNotificationList[olderNotificationList.length - 1].PK,
  //           },
  //           SK: {
  //             S: olderNotificationList[olderNotificationList.length - 1].SK,
  //           },
  //         },
  //       };
  //       getOlderNotifications(data);
  //     } else {
  //       const data = {
  //         lastEvaluatedKey: {
  //           PK: {
  //             S: notifications[notifications.length - 1].PK,
  //           },
  //           SK: {
  //             S: notifications[notifications.length - 1].SK,
  //           },
  //         },
  //       };
  //       getOlderNotifications(data);
  //     }
  //   }
  // }, [olderNotificationsCount]);

  useEffect(() => {
    if (notifications.length > 0 && olderNotificationsCount) {
      const olderNotificationList = olderNotifications;
      if (olderNotificationList.length > 0) {
        const data = {
          lastEvaluatedKey: {
            PK: olderNotificationList[olderNotificationList.length - 1].PK,
            SK: olderNotificationList[olderNotificationList.length - 1].SK,
          },
        };
        getOlderNotifications(data);
      } else {
        const data = {
          lastEvaluatedKey: {
            PK: notifications[notifications.length - 1].PK,
            SK: notifications[notifications.length - 1].SK,
          },
        };
        getOlderNotifications(data);
      }
    }
  }, [olderNotificationsCount]);

  const getOlderNotifications = async (data) => {
    const mergedResponse = [];
    const res = await api?.getLogMessages(data);
    const responseArray = res.data?.items;
    mergedResponse.push(...olderNotifications);
    mergedResponse.push(...responseArray);
    setOlderNotifications(mergedResponse);
    setOlderNotificationsCount(false);
  };

  const handleGetOlderNotifications = () => {
    setOlderNotificationsCount(true);
  };

  return {
    notifications,
    haveNewNotifications,
    handleGetOlderNotifications,
    olderNotifications,
    setNotifications,
    setHaveNewNotifications,
  };
}
function WsAlert() {
  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [NotificationPlay] = useSound(NotificationSound);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);
  const { channel, reconnectWs } = useWebsocket({
    channel: channels.NOTIFICATION_CHANNEL,
  });
  const notificationChannel = useSelector(
    (state) => state.websocket.notification
  );
  // console.log("channel", channel);
  const api = useApi();

  const [messageDisplay, setMessageDisplay] = useState(null);
  const [wsMessageBuffer, setWsMessageBuffer] = useState([]);
  const dispatch = useDispatch();
  const onWebsocketAlertClose = () => {
    setWsMessageBuffer([]);

    const msg = {
      severity: "Information",
      type: "ACKOWLEDGEMENT_RECEIVED",
      message: `Acknowledgment of nearby ${messageDisplay.state.toLowerCase()} operation received`,
    };
    api?.createLogMessage(msg);
    setWebsocketAlertOpen(false);
    handleOnFocus(messageDisplay);
  };
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [conformanceData, setConformanceData] = useState({});
  const retriveData = (conformance) => {
    const operation_id = conformance?.operation_id;
    const timeStart = conformance?.volume?.time_start?.value;
    const timeEnd = conformance?.volume?.time_end?.value;
    const dataConformance = conformance;
    const conformanceDataArr = [
      operation_id,
      timeStart,
      timeEnd,
      dataConformance,
    ];
    return conformanceDataArr;
  };

  const readingDataFromLocalStorage = (dataEntered) => {
    let listConformanceData = localStorage.getItem("conformanceData")
      ? localStorage.getItem("conformanceData")
      : JSON.stringify([]);
    const dataObject = {
      operation_id: dataEntered[0],
      timeStart: dataEntered[1],
      timeEnd: dataEntered[2],
      dataConformance: dataEntered[3],
    };
    listConformanceData = JSON.parse(listConformanceData);
    listConformanceData.push(dataObject);
    localStorage.setItem(
      "conformanceData",
      JSON.stringify(listConformanceData)
    );
    const dataConformanceGL = localStorage.getItem("conformanceData");
    return JSON.parse(dataConformanceGL);
  };
  const handleOnFocus = (conformance) => {
    const operationId = conformance?.operation_id
      ? conformance?.operation_id
      : undefined;
    if (conformance[0] !== "") {
      const dataEnteredLs = retriveData(conformance);
      const readDataLs = readingDataFromLocalStorage(dataEnteredLs);
      remove(readDataLs, "operation_id", operationId);
      dispatch(
        setStateConformance({
          showConformanceLayer: true,
          data: readDataLs,
        })
      );
      const convertCordinate =
        conformance?.volume?.volume?.outline_polygon?.vertices[0];
      const coordinate = {
        reference: {
          id: "",
        },
        request: {
          arrival: getConvert([convertCordinate])[0],
        },
      };
      if (conformance.state === "Activated") return;
      mapboxController.setFocussedOperation(coordinate);
    }
  };
  const deletedOperationIdByTimeEnd = () => {
    const getDataConformanceLS = () => {
      const listConformanceData = localStorage.getItem("conformanceData")
        ? localStorage.getItem("conformanceData")
        : JSON.stringify([]);
      return JSON.parse(listConformanceData);
    };
    const dateNow = new Date();
    const customFilterIndex = () => {
      // const result = [];
      getDataConformanceLS().forEach((element, index) => {
        const time = new Date(element.timeEnd);
        const operationId = element.operation_id;
        if (time < dateNow) {
          removeByTime(getDataConformanceLS(), "operation_id", operationId);
          // return;
          // result.push(index);
        }
      });
      // return result;
    };
    customFilterIndex();
  };
  // Updated State Conformance data By Operation Id
  const remove = (array, key, value) => {
    let arr1 = [];
    let arr2 = [];
    let removeData = [];
    if (value !== undefined) {
      const index = array.findIndex((obj) => obj[key] === value);
      arr1 = index >= 0 ? [...array.slice(0, index)] : array;
      arr2 = index >= 0 ? [...array.slice(index + 1)] : array;
      removeData = arr1.concat(arr2);
      // Chek if find index same with count of new data entered in LS retriveData(); if same then add new operationid data
      if (index === removeData.length) {
        localStorage.setItem("conformanceData", JSON.stringify(array));
      } else {
        localStorage.setItem("conformanceData", JSON.stringify(removeData));
      }
    }
    return removeData;
  };
  // Deleted Conformance data if Time End
  const removeByTime = (array, key, value) => {
    let arr1 = [];
    let arr2 = [];
    let removeData = [];
    if (value !== undefined) {
      const index = array.findIndex((obj) => obj[key] === value);
      arr1 = index >= 0 ? [...array.slice(0, index)] : array;
      arr2 = index >= 0 ? [...array.slice(index + 1)] : array;
      removeData = arr1.concat(arr2);
      localStorage.setItem("conformanceData", JSON.stringify(removeData));
    }
    return removeData;
  };

  useEffect(() => {
    if (!messageDisplay) return;
    const message = wsMessageBuffer;
    const unacknowledgedMessage = messageDisplay;
    const messageArr = message.map((singleMessage) => {
      return singleMessage.state;
    });
    if (message && message.length > 0) {
      if (unacknowledgedMessage.state === "authority_msg") {
        // check for duplicate authority msg
        const checkDuplicate = message.some(
          (item) => item.message === unacknowledgedMessage.message
        );
        if (!checkDuplicate) {
          const combinedMessage = [...message, unacknowledgedMessage];
          setWsMessageBuffer(combinedMessage);
        }
      } else if (!messageArr.includes(unacknowledgedMessage.state)) {
        // as contingent messages are the same regardless of operation, set to remove duplicate contingent/nc messages
        const combinedMessage = [...message, unacknowledgedMessage];
        setWsMessageBuffer(combinedMessage);
      }
    } else {
      setWsMessageBuffer([unacknowledgedMessage]);
    }
  }, [messageDisplay]);

  useEffect(() => {
    const handleAlert = (data) => {
      if (!data) return;
      setWebsocketAlertOpen(true);
      setMessageDisplay({ ...data, state: messageTypes.AUTHORITY_ALERT });
      NotificationPlay();
    };

    const handleMessage = (data) => {
      if (!data) return;
      if (data.message) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: data.message,
            severity: "success",
          })
        );
      } else {
        if (data.track) return; // check if telemetry track
        setWebsocketAlertOpen(true);
        setMessageDisplay(data);
        // dispatch(
        //   setStateConformance({
        //     showConformanceLayer: true,
        //     data,
        //   })
        // );
        NotificationPlay();
      }
      // setConformanceData(data);
    };

    const handleDisconnectMessage = (data) => {
      if (data.connection.connectionId === channel.connection.connectionId) {
        reconnectWsConnection();
      }
    };

    const removeAlertListener = channel?.addMessageListener(
      messageTypes.ALERT,
      handleAlert
    );

    const removeListener = channel?.addMessageListener(
      messageTypes.CONFORMANCE,
      handleMessage
    );

    const removeHeartbeatListener = channel?.addMessageListener(
      messageTypes.HEARTBEAT,
      handleDisconnectMessage
    );

    const retriveConformanceInterval = setInterval(() => {
      deletedOperationIdByTimeEnd();
    }, 1000 * 1); // Updates every 1 sec deleted local storage conformancedata
    return () => {
      clearInterval(retriveConformanceInterval);
      removeListener?.();
      removeAlertListener?.();
      removeHeartbeatListener?.();
    };
  }, [dispatch, mapboxController, NotificationPlay, channel, conformanceData]);

  const reconnectWsConnection = () => {
    const latestIdToken = localStorage.getItem("idToken");
    const role = localStorage.getItem("role");
    if (latestIdToken != null) {
      reconnectWs(latestIdToken, role);
    }
  };
  const checkWsConnection = () => {
    const channelStatus = channel?.checkConnection();
    if (channelStatus > 1) {
      reconnectWsConnection();
    }
  };

  useEffect(() => {
    const wsConnectionStatus = setInterval(async () => {
      checkWsConnection();
    }, 1000 * 3);
    return () => {
      clearInterval(wsConnectionStatus);
    };
  }, [notificationChannel, channel]);

  return (
    <div>
      {!!wsMessageBuffer.length && (
        <WebsocketAlert
          open={websocketAlertOpen}
          handleClose={onWebsocketAlertClose}
          websocketMessage={wsMessageBuffer}
        />
      )}
    </div>
  );
}
function ProtectedComponent(props) {
  const [sidebarWidth, setSidebarWidth] = useState(45);
  const { colourTheme } = useContext(AppColourTheme);

  // AUTHORITY,OPERATOR,PILOT
  const [role, setRole] = useState("AUTHORITY");
  // Try UseRef for selectedFlightData if improves performance
  // const selectedFlightData = useRef([]);
  const [selectedFlightData, setSelectedFlightData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);
  const [telemetryData, setTelemetryData] = useState([]);
  const [selectedPlatformData, setSelectedPlatformData] = useState([]);
  const [selectedTrackerData, setSelectedTrackerData] = useState([]);
  const [selectedPilotData, setSelectedPilotData] = useState([]);
  const [constraints, setConstraints] = useState([]);
  const [editConstraints, seteditConstraints] = useState([]);
  const [buildingData, setbuildingData] = useState([]);
  const [openAssetManagement, setOpenAssetManagement] = useState(false);
  const [openFlightAuthorization, setOpenFlightAuthorization] = useState(false);
  const [openRemoteIdTrackerList, setOpenRemoteIdTrackerList] = useState(false);
  const [openAuthProposeOperation, setOpenAuthProposeOperation] =
    useState(false);
  const [
    openFlightAuthorizationAuthority,
    setOpenFlightAuthorizationAuthority,
  ] = useState(false);
  const [openDronePortOperations, setOpenDronePortOperations] = useState(false);
  const [openReplayFunction, setOpenReplayComponent] = useState(false);
  const [openDroneOperation, setOpenDroneOperation] = useState(false);
  const [openConstrainMgrAuthority, setOpenConstrainMgrAuthority] =
    useState(false);
  const [openOperatorManagement, setOpenOperatorManagement] = useState(false);
  const [renderOperatorManagement, setRenderOperatorManagement] =
    useState(false);
  const [openIncidentReporting, setOpenIncidentReporting] = useState(false);
  const [openSchedules, setOpenSchedules] = useState(false);
  const [loadOlderLogs, setLoadOlderLogs] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openConstraintPanel, setOpenConstraintPanel] = useState(false);
  const [showShipLayer, setShowShipLayer] = useState(false);
  const [showAdsbLayer, setShowAdsbLayer] = useState(false);
  const [showConstraintsLayer, setShowConstraintsLayer] = useState(true);
  const [showStaticConstraintsLayer, setShowStaticConstraintsLayer] =
    useState(true);
  const [showBuildingLayer, setShowBuildingLayer] = useState(false);
  const [showOperationVolumeLayer, setShowOperationVolumeLayer] =
    useState(true);
  const [showDroneIconLayer, setShowDroneIconLayer] = useState(true);
  const [showRainMapLayer, setShowRainMapLayer] = useState(false);
  const [showAirMapSpace, setShowAirMapSpaceLayer] = useState(true);
  const [editConstraintStatusGraphic, setEditConstraintStatusGraphic] =
    useState(false);
  const [showGnssLayer, setShowGnssLayer] = useState(false);
  const [showAnchorageLayer, setShowAnchorageLayer] = useState(true);
  const [showDronePortLayer, setShowDronePortLayer] = useState(false);
  const [showSgBoundaryLayer, setShowSgBoundaryLayer] = useState(false);
  const [showSgPopulationDensity, setShowSgPopulationDensity] = useState(false);
  const [showMapElevation, setShowMapElevation] = useState(false);
  const [showCollisionLayer, setShowCollisionLayer] = useState(true);

  const classes = useStyles();
  const api = useApi();
  const dispatch = useDispatch();

  const {
    notifications,
    haveNewNotifications,
    setNotifications,
    setHaveNewNotifications,
    olderNotifications,
    handleGetOlderNotifications,
  } = useNotifications();

  const notificationsRef = useRef(notifications);

  const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  const [mapViewController, setMapViewController] = useState("");
  const [emergencyLanding, setEmergencyLanding] = useState([0, 0]);
  const [selectedWaypoints, setSelectedWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [selectedAreaWaypoints, setSelectedAreaWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  const [selectedCircleWaypoints, setSelectedCircleWaypoints] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [updatedSelectedCircleWaypoints, setUpdatedSelectedCircleWaypoints] =
    useState([[0.0, 0.0]]);
  const [selectedCircleProperties, setSelectedCircleProperties] = useState({
    isCircle: true,
    center: [0.0, 0.0],
    radiusInKm: 0,
  });
  const [updateSelectedCircleProperties, setUpdatedSelectedCircleProperties] =
    useState();
  const [isDrawerOpenTaskConstrainPanel, setIsDrawerOpenTaskConstrainPanel] =
    useState(false);
  const [OperationsSummary, SetOperationsSummary] = useState("");
  const [GroundSummary, SetGroundSummary] = useState("");
  const [AirborneSummary, SetAirborneSummary] = useState("");
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [openPilotExpiredDialog, setOpenPilotExpiredDialog] = useState(false);
  const [pilotsDataAlert, setPilotsDataAlert] = useState([]);
  const { channel } = useWebsocket({ channel: channels.NOTIFICATION_CHANNEL });
  const [teleObj, setTeleObj] = useState({});
  const [NotificationPlay] = useSound(NotificationSound);

  // const [authorityOperationAlert, setAuthorityOperationAlert] = useState();
  const [authorityOperationAlertOpen, setAuthorityOperationAlertOpen] =
    useState("");
  const [authorityOperationAlertMessage, setAuthorityOperationAlertMessage] =
    useState([]);
  const [messageBuffer, setMessageBuffer] = useState([]);
  const [
    authorityOperationAlertMessagePrefix,
    setAuthorityOperationAlertMessagePrefix,
  ] = useState("");
  const [flightDataIsLoading, setFlightDataIsLoading] = useState(true);
  const [flightAuthorizationTabValue, setFlightAuthorizationTabValue] =
    useState(0);
  const [portOperationTabValue, setPortOperationTabValue] = useState(0);

  const [mostRecentComponent, setMostRecentComponent] = useState("");
  const [openFlightAuthorizationHolder, setOpenFlightAuthorizationHolder] =
    useState(false);
  const [openConstrainMgrAuthorityHolder, setOpenConstrainMgrAuthorityHolder] =
    useState(false);
  const [openAuthProposeOperationHolder, setOpenAuthProposeOperationHolder] =
    useState(false);

  const [authorityAssignOperator, setAuthorityAssignOperation] =
    useState(false);

  // Ship Delivery Operation
  const [selectedShip, setSelectedShip] = useState();
  const [selectedTakeOffLandingPoint, setSelectedTakeOffLandingPoint] =
    useState();

  const envVar = useSelector((state) => state.envVar);
  const mapBoxToken = envVar["api_key-mapbox"]?.Value;
  // mapboxgl.accessToken = mapBoxToken;
  const platformList = useRef();
  const trackerList = useRef();
  const pilotList = useRef();

  const getTrackerAndPlatformData = async () => {
    // previously called getplatforms and gettracker for each operation
    // consolidate into useRef Object
    const [platformListResponse, trackerListResponse, pilotListResponse] =
      await Promise.all([
        api.getPlatforms(),
        api.getTracker(),
        api.getPilots(),
      ]);
    const formattedPlatformList = {};
    const formattedTrackerList = {};
    const formattedPilotList = {};
    platformListResponse.data.forEach((singlePlatform) => {
      formattedPlatformList[singlePlatform.platform_uuid] = singlePlatform;
    });
    trackerListResponse.data.forEach((singleTracker) => {
      formattedTrackerList[singleTracker.tracker_uuid] = singleTracker;
    });
    pilotListResponse.data.forEach((singePilot) => {
      formattedPilotList[singePilot.pilot_uuid] = singePilot;
    });
    platformList.current = formattedPlatformList;
    trackerList.current = formattedTrackerList;
    pilotList.current = formattedPilotList;
  };

  // useEffect(() => {
  //   getTrackerAndPlatformData();
  // }, []);

  const getPlatformFromUuid = (uuid) => {
    if (!platformList.current) throw new Error("Platform List not initialized");
    if (!uuid || !(uuid in platformList.current))
      throw new Error("Platform not in list");
    return { data: platformList.current[uuid] };
  };
  const getTrackerFromUuid = (uuid) => {
    if (!trackerList.current) return "";
    if (!uuid || !(uuid in trackerList.current)) return "";
    return { data: trackerList.current[uuid] };
  };
  const authorityOperationAlertMessageHandler = (message) => {
    if (message?.message.includes("+")) {
      const splitMessage = message?.message.split("+");
      setAuthorityOperationAlertMessagePrefix(splitMessage[0]);
      // setAuthorityOperationAlertMessage(splitMessage[1]);
      setMessageBuffer([splitMessage[1]]);
      setAuthorityOperationAlertOpen("open");
      return;
    }
    // setAuthorityOperationAlertMessage(message?.message);
    setMessageBuffer([message?.message]);
    setAuthorityOperationAlertOpen("open");
  };

  const authorityOperationAlertCloseHandler = () => {
    setAuthorityOperationAlertMessagePrefix("");
    setAuthorityOperationAlertMessage([]);
    setAuthorityOperationAlertOpen("");
  };
  // useEffect(() => {
  //   const {
  //     notifications: notifs,
  //     haveNewNotifications: haveNew,
  //     setNotifications,
  //     setHaveNewNotifications,
  //     olderNotifications: olderNotifs,
  //     handleGetOlderNotifications,
  //   } = useNotifications();

  //   setNotifications(notifs);
  //   setHaveNewNotifications(haveNew);
  //   setOlderNotifications(olderNotifs);
  // }, []);

  const playNotifSound = () => {
    NotificationPlay();
  };

  useEffect(() => {
    // WebSocketService.addMessageListener("alert", (data) => {
    //   authorityOperationAlertMessageHandler(data.message);
    // });
    const handleMessageAlert = (data) => {
      if (!data) return;
      authorityOperationAlertMessageHandler(data);
      NotificationPlay();
    };
    const removeListenerAlertMsg = channel?.addMessageListener(
      messageTypes.AUTHORITY_ALERT,
      handleMessageAlert
    );
    return () => {
      removeListenerAlertMsg?.();
    };
  }, [NotificationPlay, channel]);
  //
  // useEffect(() => {
  //   if (!websocketMessage || !websocketMessage.type) return;

  //   if (websocketMessage.type === "state_change") {
  //     const operationID = websocketMessage.operationID;
  //     const newState = websocketMessage.new_state;

  //     var operations = [...selectedFlightData];
  //     const index = operations.findIndex((x) => x.reference.id === operationID);
  //     const newWebSocketMessage = websocketMessage;
  //     if (index > -1) {
  //       operations[index].operation_state = newState;
  //       setSelectedFlightData(operations);
  //       setWebsocketAlertOpen(true);
  //       getPlatformCallSign(
  //         websocketMessage,
  //         operations[index].request.platform_uuid
  //       );
  //     }
  //   }
  // }, [websocketMessage]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const notificationChannel = useSelector(
  //   (state) => state.websocket.notification
  // );

  {
    /* DO NOT DELETE!! original telemetry data implementation */
  }
  // useEffect(() => {
  //   const checkTrackerImei = (trakcerImei) => {
  //     const dataTracker = [];
  //     selectedTrackerData.map((data, index) => {
  //       return data.map((datas) => {
  //         return dataTracker.push(datas);
  //       });
  //     });
  //     // eslint-disable-next-line func-names
  //     const filterDataTracker = dataTracker.filter(function (tracker) {
  //       return tracker.data.tracker_imei === trakcerImei;
  //     });
  //     return filterDataTracker;
  //   };
  //   const handleMessage = (data) => {
  //     if (!data) return;
  //     if (data?.operational_status !== "Undeclared") {
  //       const checkPlatformUuid = (puck_uuid) => {
  //         const dataPlatform = [];
  //         selectedPlatformData.map((p, index) => {
  //           return p.map((datas) => {
  //             return dataPlatform.push(datas);
  //           });
  //         });
  //         // eslint-disable-next-line func-names
  //         const filterDataPlatform = dataPlatform.filter(function (platform) {
  //           return platform.data.puck_uuid === puck_uuid;
  //         });
  //         return filterDataPlatform;
  //       };
  //       const key = [data.extras.imei, data.operation_id].join("/");
  //       setTeleObj({
  //         ...teleObj,
  //         [key]: {
  //           gufi: data.operation_id,
  //           trackerImei: data.extras?.imei,
  //           timestamp: Date.now(),
  //           puckResponses: [
  //             [
  //               null,
  //               {
  //                 error: null,
  //                 ...data,
  //               },
  //               checkPlatformUuid(data.extras.imei),
  //               checkTrackerImei(data.extras.imei)
  //                 ? checkTrackerImei(data.extras.imei)
  //                 : [],
  //             ],
  //           ],
  //         },
  //       });

  //       const ttt = Object.keys(teleObj).map((k) => teleObj[k]);
  //       setTelemetryData(ttt);
  //     } else {
  //       setTelemetryData([]);
  //     }
  //   };
  //   const removeListener = channel?.addMessageListener(
  //     messageTypes.TELEMETRY,
  //     handleMessage
  //   );

  //   return () => {
  //     removeListener?.();
  //   };
  // }, [teleObj, channel]);

  // const handleOpenNotifications = () => {
  //   // console.log(messages.current);
  //   setHaveNewNotifications(false);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserOperations = async () => {
    try {
      // to reduce api calls, getTrackerandPlatformData will get list of all details first
      // getPlatformData and getTrackerData will then use this list to identify tracker and platforms
      await getTrackerAndPlatformData();

      const res = await api?.getAllOperationSelection();
      if (res?.status === 204 || res?.data?.length === 0) {
        return;
      }
      const flightData = res.data.map((r) => {
        return r.operation_json;
      });
      getPlatformData(flightData);
      getTrackerData(flightData);
      getPilotData(flightData);
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSelectedOperation = async () => {
    try {
      const res = await api?.getAllOperationSelection();
      // if (res.data && res.data === 0) {
      //   SetOperationsSummary(0);
      //   SetGroundSummary(0);
      //   SetAirborneSummary(0);
      //   setSelectedFlightData([]);
      // }
      /**
       * @todo Need to improve on the error handling loading of operations.
       * @author xueyang
       */
      if (!res.data || !Array.isArray(res.data)) {
        console.log(
          "Data from getAllOperationSelection() response might be empty.",
          res
        );
        return;
      }
      const dataGroundSummary = res.data.filter(
        (obj) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Accepted")
      ).length;
      const dataAirborneSummary = res.data.filter(
        (obj) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Activated")
      ).length;
      const dataContingent = res.data.filter(
        (obj) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Contingent")
      ).length;
      const dataNonConforming = res.data.filter(
        (obj) =>
          obj.operation_state !== null &&
          obj.operation_state.includes("Nonconforming")
      ).length;
      SetOperationsSummary(
        dataGroundSummary +
          dataAirborneSummary +
          dataNonConforming +
          dataContingent
      );
      SetGroundSummary(dataGroundSummary);
      SetAirborneSummary(
        dataAirborneSummary + dataNonConforming + dataContingent
      );

      const flightData = res.data.map((r) => {
        return r.operation_json;
      });
      setSelectedFlightData(flightData);
      // selectedFlightData.current = flightData;
    } catch (error) {
      console.log(error);
    } finally {
      setFlightDataIsLoading(false);
    }
  };

  const getBuilding = async (
    data = {
      coordinates: [
        [
          [103.866288, 1.314993],
          [103.87151, 1.314044],
          [103.869494, 1.309757],
          [103.866288, 1.314993],
        ],
      ],
      type: "Polygon",
    }
  ) => {
    try {
      const res = await api?.getBuilding(data);
      setbuildingData(res.data.data);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: "No building data can be retrieved.",
          severity: "error",
        })
      );

      setbuildingData([]);
    }
  };
  const getPlatformData = async (flightData) => {
    try {
      const platformIDs = await flightData.map((flight) => flight.request);
      const platformData = await Promise.all(
        platformIDs.map((platformID, index) => {
          if (!platformID) return undefined;
          const dataPlatform = async () => {
            const dataP = await Promise.all(
              platformID.platform_uuid.map(async (id) => {
                // const returnedData = await api?.getPlatform(id);
                const returnedData = getPlatformFromUuid(id);
                return returnedData;
              })
            );
            return dataP;
          };
          return dataPlatform();
          // return api?.getPlatform(platformID[0]);
        })
      );
      setSelectedPlatformData(platformData);
    } catch (err) {
      console.error(err);
    }
  };
  const getTrackerData = async (flightData) => {
    try {
      const platformIDs = await flightData.map((flight) => flight.request);

      const trackerData = await Promise.all(
        platformIDs.map((platformID, index) => {
          if (!platformID) return undefined;
          const dataTracker = async () => {
            const dataT = await Promise.all(
              platformID.tracker_uuid.map((tracker_uuid) => {
                // return api?.getTrackerScan(tracker_uuid);
                return getTrackerFromUuid(tracker_uuid);
              })
            );
            return dataT;
          };
          return dataTracker();
        })
      );
      setSelectedTrackerData(trackerData);
    } catch (err) {
      console.error(err);
    }
  };
  const getPilotData = async (flightData) => {
    try {
      const pilotData = await api?.getPilots();
      setSelectedPilotData(pilotData.data);
    } catch (err) {
      console.error(err);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  const handleOpenAssetManagement = () => {
    if (openAssetManagement === true) {
      setOpenAssetManagement((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenAssetManagement((s) => !s);
    }
  };

  const handleOpenNotifications = () => {
    if (openNotifications === true) {
      setOpenNotifications((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenNotifications((s) => !s);
      setHaveNewNotifications(false);
    }
  };

  const handleOpenDrawerTaskConstrainPanel = () => {
    setIsDrawerOpenTaskConstrainPanel((s) => !s);
  };
  const handleOpenConstraintPanel = () => {
    if (openConstraintPanel === true) {
      setOpenConstraintPanel((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenConstraintPanel((s) => !s);
    }
  };
  const handleOpenFlightAuthorization = () => {
    if (openFlightAuthorization === true) {
      setOpenFlightAuthorization((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenFlightAuthorization((s) => !s);
    }
  };
  const handleOpenFlightAuthorizationForced = () => {
    setOpenFlightAuthorization(true);
  };
  const handleOpenFlightAuthorizationAuthority = () => {
    if (openFlightAuthorizationAuthority === true) {
      setOpenFlightAuthorizationAuthority((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenFlightAuthorizationAuthority((s) => !s);
    }
  };
  const handleOpenDronePortOperations = () => {
    if (openDronePortOperations === true) {
      setOpenDronePortOperations((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenDronePortOperations((s) => !s);
    }
  };
  const handleOpenShipDeliveryOperationsForced = () => {
    handleCloseAllDrawers();
    setPortOperationTabValue(0);
    setOpenDronePortOperations(true);
  };
  const handleOpenReplayFunction = () => {
    if (openReplayFunction === true) {
      setOpenReplayComponent((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenReplayComponent((s) => !s);
    }
  };
  const handleOpenDroneOperation = () => {
    if (openDroneOperation === true) {
      setOpenDroneOperation((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenDroneOperation((s) => !s);
    }
  };
  const handleOpenTrackerMonitorList = () => {
    if (openRemoteIdTrackerList === true) {
      setOpenRemoteIdTrackerList((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenRemoteIdTrackerList((s) => !s);
    }
  };

  const handleOpenIncidentReporting = () => {
    if (openIncidentReporting === true) {
      setOpenIncidentReporting((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenIncidentReporting((s) => !s);
    }
  };

  const handleOpenSchedules = () => {
    if (openSchedules === true) {
      setOpenSchedules((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenSchedules((s) => !s);
    }
  };

  const handleOpenConstrainMgrAuthority = () => {
    if (openConstrainMgrAuthority === true) {
      setOpenConstrainMgrAuthority((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenConstrainMgrAuthority((s) => !s);
      // mapboxController.removeDrawFeaturesAll();
      // dispatch(
      //   setEditConstraint({
      //     isEdit: false,
      //   })
      // );
    }
    // setOpenConstrainMgrAuthority((s) => !s);
    // if (openConstrainMgrAuthority === true) {
    //   mapboxController.removeDrawFeaturesAll();
    //   dispatch(
    //     setEditConstaint({
    //       isEdit: false,
    //     })
    //   );
    // }
  };

  const handleOpenOperatorManagement = () => {
    if (openOperatorManagement === true) {
      setOpenOperatorManagement((s) => !s);
    } else {
      handleCloseAllDrawers();
      setOpenOperatorManagement((s) => !s);
    }
  };
  // useEffect(() => {
  //   if (openOperatorManagement) {
  //     setRenderOperatorManagement(true);
  //   } else {
  //     setTimeout(() => {
  //       setRenderOperatorManagement(false);
  //     }, 150);
  //   }
  // }, [openOperatorManagement]);

  // useEffect(() => {
  //   if (!selectedFlightData) return undefined;
  //   const checkPlatformUuid = (puck_uuid) => {
  //     const dataPlatform = [];
  //     selectedPlatformData.map((data, index) => {
  //       return data.map((datas) => {
  //         return dataPlatform.push(datas);
  //       });
  //     });
  //     // eslint-disable-next-line func-names
  //     const filterDataPlatform = dataPlatform.filter(function (platform) {
  //       return platform.data.puck_uuid === puck_uuid;
  //     });
  //     return filterDataPlatform;
  //   };

  //   setTelemetryData([
  //     {
  //       gufi: "ff67713f-5e7e-4b67-80de-7249604aa6e2",
  //       puckResponses: [
  //         [
  //           null,
  //           {
  //             error: null,
  //             timestamp: "2022-10-06T01:40:58.557Z",
  //             timestamp_accuracy: 0,
  //             operator: "operva_ai",
  //             operational_status: "Undeclared",
  //             position: {
  //               lat: 1.4740429681887974,
  //               lng: 103.2392219189689,
  //               alt: 40,
  //               accuracy_h: "HAUnknown",
  //               accuracy_v: "VAUnknown",
  //               extrapolated: false,
  //               pressure_altitude: 0,
  //             },
  //             track: 23,
  //             speed: 1.9,
  //             speed_accuracy: "SAUnknown",
  //             vertical_speed: 0,
  //             height: {
  //               distance: 0,
  //               reference: "TakeoffLocation",
  //             },
  //             extras: {
  //               imei: "00000000-0000-0010-0000-000000000000",
  //               imsi: "123456789012345",
  //               batt: 730,
  //               pitch: 0.5,
  //               roll: 1.3,
  //               yaw: 135,
  //               rssi: 23,
  //             },
  //           },
  //           checkPlatformUuid("86112305-1902-9040-0000-000000000000"),
  //         ],
  //       ],
  //     },
  //   ]);
  //   return () => {};
  //   // const pollPuckForTelemetry = async (selectedflights) => {
  //   //   const puckResponses = await Promise.all(
  //   //     selectedflights.details.puck_uuid.map(async (puck_uuid, index) => {
  //   //       const dataPlatform = checkPlaformUuid(puck_uuid);
  //   //       const puckResponse = await PuckServer.getFlightTelemetry(puck_uuid);
  //   //       return [puckResponse[0], puckResponse[1], dataPlatform];
  //   //     })
  //   //   );
  //   //   // const [puckErr, puckResponse] = await PuckServer.getFlightTelemetry(
  //   //   //   selectedflights.details.puck_uuid
  //   //   // );
  //   //   // if (puckErr) {
  //   //   //   dispatch(
  //   //   //     setSnackbarMessage({
  //   //   //       open: true,
  //   //   //       message: puckErr.message,
  //   //   //       severity: "error",
  //   //   //     })
  //   //   //   );
  //   //   // }
  //   //   // const resp = {
  //   //   //   gufi: selectedflights.reference.id,
  //   //   //   puckResponse,
  //   //   // };
  //   //   const errorMSg = async (puckErr) => {
  //   //     if (puckErr) {
  //   //       dispatch(
  //   //         setSnackbarMessage({
  //   //           open: true,
  //   //           message: puckErr.message,
  //   //           severity: "error",
  //   //         })
  //   //       );
  //   //     }
  //   //   };
  //   //   puckResponses?.map((resPuck) => {
  //   //     return errorMSg(resPuck[0]);
  //   //   });
  //   //   const resp = {
  //   //     gufi: selectedflights.reference.id,
  //   //     puckResponses,
  //   //   };
  //   //   return resp;
  //   // };
  //   // const poll = async () => {
  //   //   const respo = await Promise.all(
  //   //     selectedFlightData.map(pollPuckForTelemetry)
  //   //   );
  //   //   // console.log("respo", respo);
  //   //   setTelemetryData([...respo.filter((r) => r)]);
  //   // };

  //   // const interval = setInterval(poll, 1000);
  //   // return () => {
  //   //   clearInterval(interval);
  //   // };

  // authority is res.data

  const fetchingLogMessages = async () => {
    try {
      const res = await api?.getLogMessages();
      if (isAuthority) {
        return res?.data;
      }
      return res?.data?.items;
    } catch (error) {
      console.log("error fetching log messages: ", error);
      return [];
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     const fetchedMessages = await fetchingLogMessages();
  //     try {
  //       const lastNotif = notificationsRef.current?.[0];
  //       const lastNotifSK = isAuthority ? lastNotif?.SK?.S : lastNotif?.SK;

  //       const lastMsg = fetchedMessages?.[0];
  //       const lastMsgSK = isAuthority ? lastMsg?.SK?.S : lastMsg?.SK;
  //       // ONLY SET IF THERE IS CHANGE IN NOTIFS
  //       if (lastNotifSK !== lastMsgSK) {
  //         playNotifSound();
  //         setHaveNewNotifications(true);
  //         setNotifications(fetchedMessages);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }, 1000 * 3);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // Listener for WS Log Messages
  useEffect(() => {
    const handleWSLogMessage = (data) => {
      if (!data) return;
      setNotifications([...notificationsRef.current, data]);
      setHaveNewNotifications(true);
    };

    const removeLogMessageListener = channel?.addMessageListener(
      messageTypes.LOG_MESSAGE,
      handleWSLogMessage
    );
    return () => {
      removeLogMessageListener?.();
    };
  }, [channel]);

  useEffect(() => {
    let refreshTimeout;
    const isNewNotifications = async () => {
      const fetchedMessages = await fetchingLogMessages();
      try {
        const lastNotif = notificationsRef.current?.[0];
        const lastNotifSK = lastNotif?.SK;

        const lastMsg = fetchedMessages?.[0];
        const lastMsgSK = lastMsg?.SK;
        // ONLY SET IF THERE IS CHANGE IN NOTIFS
        if (lastNotifSK !== lastMsgSK) {
          NotificationPlay();
          setHaveNewNotifications(true);
          setNotifications(fetchedMessages);
        }
      } catch (err) {
        console.log(err);
      }
      // Log getLogMessages called every 30mins to ensure messages still in sync
      // New log messages are sent via websocket and added to notifications useState
      refreshTimeout = setTimeout(isNewNotifications, 1000 * 60 * 30);
    };
    isNewNotifications();
    return () => {
      clearTimeout(refreshTimeout);
    };
  }, [NotificationPlay, authorityAssignOperator]);

  const loadOlderMessages = async () => {
    handleGetOlderNotifications();
  };

  useEffect(() => {
    if (haveNewNotifications === true) {
      // NotificationPlay();
    }
  }, [haveNewNotifications]);

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    if (!api) return;
    getUserOperations();
  }, [api]);

  useEffect(() => {
    const loadConstraints = async () => {
      try {
        const res = await api?.getConstraints();
        if (res?.data) {
          setConstraints(res.data);
        }
      } catch (err) {
        if (
          err?.response?.data?.message ===
          "No active constraints can be retrieved."
        )
          return;
        dispatch(
          setSnackbarMessage({
            message: err?.response?.data?.message,
            severity: "error",
          })
        );

        setConstraints([]);
      }
    };

    const interval = setInterval(loadConstraints, 1000 * 6);
    // const pollUserOperations = setInterval(async () => {
    //   await getSelectedOperation();
    //   // getBuilding();
    // }, 1000 * 3);
    return () => {
      clearInterval(interval);
      // clearInterval(pollUserOperations);
    };
  }, [api]);

  useEffect(() => {
    const pollUserOperations = setInterval(async () => {
      await getSelectedOperation();
      // getBuilding();
    }, 1000 * 3);
    return () => {
      clearInterval(pollUserOperations);
    };
  }, []);

  useEffect(() => {
    dispatch(getShipData());
    dispatch(getADSBDataFree());
    dispatch(getADSBDataPaid());

    // TODO: make some adjustments here such as the long and lat can be by userclick?
    const getShipDataInterval = setInterval(() => {
      dispatch(getShipData());
    }, 1000 * 60); // Updates every 1 min
    const getADSBDataInterval = setInterval(() => {
      dispatch(getADSBDataFree());
      dispatch(getADSBDataPaid());
    }, 1000 * 60); // Updates every 1 min. Need to increase freq in future.
    return () => {
      clearInterval(getShipDataInterval);
      clearInterval(getADSBDataInterval);
    };
  }, [dispatch]);

  const toggleShowShipLayer = () => {
    setShowShipLayer((s) => !s);
  };

  const toggleShowAdsbLayer = () => {
    setShowAdsbLayer((s) => !s);
  };

  const toggleShowConstraintsLayer = () => {
    setShowConstraintsLayer((s) => !s);
  };
  const toggleShowBuildingLayer = () => {
    setShowBuildingLayer((s) => !s);
  };
  const toggleShowRainMapLayer = () => {
    setShowRainMapLayer((s) => !s);
  };
  const toggleShowStaticConstraintsLayer = () => {
    setShowStaticConstraintsLayer((s) => !s);
  };
  const toggleshowOperationLayer = () => {
    if (isRemoteId) {
      setShowOperationVolumeLayer(false);
    } else {
      setShowOperationVolumeLayer((s) => !s);
    }
  };
  const togglesshowDroneIconLayer = () => {
    setShowDroneIconLayer((s) => !s);
  };
  const togglesshowAirMapSpace = () => {
    setShowAirMapSpaceLayer((s) => !s);
  };
  const editConstrainStatus = () => {
    setEditConstraintStatusGraphic(true);
  };

  // const handleOperatorProposeOperation = () => {
  //   handleOpenFlightAuthorizationForced();
  //   setFlightAuthorizationTabValue(0);
  // };

  const handleOperatorProposeOperation = useCallback(() => {
    handleOpenFlightAuthorizationForced();
    setFlightAuthorizationTabValue(0);
  }, [handleOpenFlightAuthorizationForced, setFlightAuthorizationTabValue]);

  const togglesshowGnssLayer = () => {
    setShowGnssLayer((s) => !s);
  };
  const toggleShowAnchorageLayer = () => {
    setShowAnchorageLayer((s) => !s);
  };
  const toggleShowDronePortLayer = () => {
    setShowDronePortLayer((s) => !s);
  };
  const toggleSgBoundaryLayer = () => {
    setShowSgBoundaryLayer((s) => !s);
  };

  const toggleSgPopulationDensity = () => {
    setShowSgPopulationDensity((s) => !s);
  };

  const toggleMapElevation = () => {
    setShowMapElevation((s) => !s);
  };

  const toggleShowCollisionLayer = () => {
    setShowCollisionLayer((s) => !s);
  };

  useEffect(() => {
    editConstrainStatus();
  }, [editConstraintStatusGraphic]);
  /**
   * Get Pilots for Operator Rol and check the license expired and Open Dialog.
   * @param {boolean} isOperator from the useCognitoAuth function
   * @returns {getPilots()} call API to GET Pilots
   */
  const { username, isOperator, isAuthority, isPilot, isRemoteId } =
    useCognitoAuth();

  const getPilots = async () => {
    try {
      const response = await api?.getPilots();

      if (response.data) {
        const dateNow = new Date();
        const threeMontshBefore = new Date(
          dateNow.setMonth(dateNow.getMonth() + 3)
        );
        const dataAlert = response.data.filter((d) => {
          const time = new Date(d.registration_expiry);
          return time <= threeMontshBefore;
        });
        if (dataAlert.length > 0) {
          setPilotsDataAlert(dataAlert);
          setOpenPilotExpiredDialog(true);
        }
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (isOperator) {
      getPilots();
    }
  }, []);

  const handleClosePilotExpiredDialog = () => {
    setOpenPilotExpiredDialog(false);
  };
  const handleopenAuthProposeOperationDrawer = (open) => {
    setOpenAuthProposeOperation(open);
  };

  const handleSidebarWidthChange = (width) => {
    setSidebarWidth(width);
  };

  const handleCloseAllDrawers = () => {
    /* Chris: hardcoding to close all other drawers regardless of account type. 
      is there a better way? works for now 
    */
    const drawers = [
      {
        name: "Asset Management",
        setState: setOpenAssetManagement,
      },
      {
        name: "Flight Authorisation",
        setState: setOpenFlightAuthorization,
      },
      {
        name: "Notifications",
        setState: setOpenNotifications,
      },
      {
        name: "Flight Authorisation Authority",
        setState: setOpenFlightAuthorizationAuthority,
      },
      {
        name: "Drone Port Operations",
        setState: setOpenDronePortOperations,
      },
      {
        name: "Flight Replay Authority",
        setState: setOpenReplayComponent,
      },
      {
        name: "Drone Imported Ops",
        setState: setOpenDroneOperation,
      },
      {
        name: "Create Constraint",
        setState: setOpenConstrainMgrAuthority,
      },
      { name: "Operator Management", setState: setOpenOperatorManagement },
      {
        name: "Constraint Panel",
        setState: setOpenConstraintPanel,
      },
      { name: "Incident Reporting", setState: setOpenIncidentReporting },

      { name: "Flight Schedules", setState: setOpenSchedules },

      { name: "Tracker Management", setState: setOpenRemoteIdTrackerList },
    ];
    drawers.forEach((drawer) => {
      drawer.setState(false);
    });
  };
  useEffect(() => {
    if (messageBuffer && messageBuffer.length > 0) {
      const message = messageBuffer;
      const unacknowledgedMessage = authorityOperationAlertMessage;
      if (!unacknowledgedMessage.includes(message[0])) {
        const combinedMessage = [...message, ...unacknowledgedMessage];
        setAuthorityOperationAlertMessage(combinedMessage);
      }
      setMessageBuffer([]);
    }
  }, [messageBuffer]);

  useEffect(() => {
    if (openFlightAuthorization === true) {
      // setOpenFlightAuthorizationHolder(!openFlightAuthorizationHolder);
      setMostRecentComponent("flightAuthorization");
    }
    // when component closes, do nothing?

    if (openConstrainMgrAuthority === true) {
      // setOpenFlightAuthorizationHolder(!openConstrainMgrAuthorityHolder);
      setMostRecentComponent("constrainMgrAuthority");
    }
    if (openAuthProposeOperation === true) {
      // setOpenFlightAuthorizationHolder(!openAuthProposeOperation);
      setMostRecentComponent("authProposeOperation");
    }
    if (openDronePortOperations === true) {
      setMostRecentComponent("openDronePortOperations");
    }
  }, [
    openFlightAuthorization,
    openConstrainMgrAuthority,
    openAuthProposeOperation,
    openDronePortOperations,
  ]);

  const toggleMostRecentComponent = (component) => {
    switch (component) {
      case "flightAuthorization":
        handleOpenFlightAuthorization();
        break;

      case "constrainMgrAuthority":
        handleOpenConstrainMgrAuthority();
        break;

      case "openDronePortOperations":
        handleOpenDronePortOperations();
        break;
      case "authProposeOperation":
        break;
      default:
        break;
    }
  };

  /* this is used to open/close drawers according to coordinate changes, 
  but since the forms can also change these coordinates, this useEffect
  no longer used. Handler now shifted on Map draw in init.js */
  // useEffect(() => {
  //   console.log("this is being called");
  //   switch (mostRecentComponent) {
  //     case "flightAuthorization":
  //       handleOpenFlightAuthorization();
  //       break;

  //     case "constrainMgrAuthority":
  //       handleOpenConstrainMgrAuthority();
  //       break;

  //     case "authProposeOperation":
  //       // handleOpenAuthProposeOperation();
  //       break;
  //   }
  // }, [
  //   selectedCircleWaypoints,
  //   selectedAreaWaypoints,
  //   selectedWaypoints,
  //   emergencyLanding,
  // ]);

  const handleSetEmergencyLanding = (waypoints) => {
    setEmergencyLanding(waypoints);
  };
  const handleSetSelectedWaypoints = (waypoints) => {
    setSelectedWaypoints(waypoints);
  };

  const handleSetSelectedAreaWaypoints = (waypoints) => {
    setSelectedAreaWaypoints(waypoints);
  };

  const handleSetSelectedCircleProperties = (properties) => {
    setSelectedCircleProperties(properties);
  };

  const handleSetSelectedCircleWaypoints = (waypoints) => {
    setSelectedCircleWaypoints(waypoints);
  };

  // For Test with Ship Delivery, delete after testing
  useEffect(() => {
    if (selectedShip?.longitudeDegrees && selectedShip?.latitudeDegrees) {
      const center = [
        // -0.005 as form blocking center
        selectedShip.longitudeDegrees - 0.005,
        selectedShip.latitudeDegrees,
      ];
      mapboxController.flyTo(center, 14);
    }
  }, [selectedShip]);

  // currently drawers are all being rerendered at the background
  // if performance affects, will need to change implementation
  return (
    <div>
      <div className={classes.appBar}>
        <SnackbarMessage />
        <WsAlert />
      </div>
      <div style={{ display: "flex", position: "relative" }}>
        <div style={{ display: "inline", position: "relative", zIndex: 2 }}>
          <Sidebar
            haveNewNotification={haveNewNotifications}
            setHaveNewNotifications={setHaveNewNotifications}
            notifications={notifications}
            handleSidebarWidthChange={handleSidebarWidthChange}
            onOpenAssetManagement={handleOpenAssetManagement}
            onOpenNotifications={handleOpenNotifications}
            onOpenFlightAuthorization={handleOpenFlightAuthorization}
            onOpenFlightAuthorizationAuthority={
              handleOpenFlightAuthorizationAuthority
            }
            onOpenReplayComponent={handleOpenReplayFunction}
            onOpenDroneOperation={handleOpenDroneOperation}
            onOpenDronePortOperations={handleOpenDronePortOperations}
            onOpenConstrainMgrAuthority={handleOpenConstrainMgrAuthority}
            onOpenRemoteIdTrackerList={handleOpenTrackerMonitorList}
            onOpenIncidentReporting={handleOpenIncidentReporting}
            onOpenSchedules={handleOpenSchedules}
            onOpenOperatorManagement={handleOpenOperatorManagement}
            onOpenConstraintPanel={handleOpenConstraintPanel}
            openAssetManagement={openAssetManagement}
            openNotifications={openNotifications}
            openFlightAuthorization={openFlightAuthorization}
            openFlightAuthorizationAuthority={openFlightAuthorizationAuthority}
            openDronePortOperations={openDronePortOperations}
            openConstrainMgrAuthority={openConstrainMgrAuthority}
            openRemoteIdTrackerList={openRemoteIdTrackerList}
            openIncidentReporting={openIncidentReporting}
            openSchedules={openSchedules}
            openOperatorManagement={openOperatorManagement}
            openConstraintPanel={openConstraintPanel}
            openReplayFunction={openReplayFunction}
            openDroneOperation={openDroneOperation}
          />
          {/* <Header
            haveNewNotifications={haveNewNotifications}
            messages={notifications}
            olderMessages={olderNotifications}
            onOpenNotification={handleOpenNotifications}
            // onOpenDrawer={handleOpenDrawer}
            onOpenDrawerTaskConstrainPanel={handleOpenDrawerTaskConstrainPanel}
            onOpenFlightAuthorization={handleOpenFlightAuthorization}
            onOpenTrackerMonitorList={handleOpenTrackerMonitorList}
            onOpenFlightAuthorizationAuthority={
              handleOpenFlightAuthorizationAuthority
            }
            onopenConstrainMgrAuthority={handleOpenConstrainMgrAuthority}
            role={role}
            setHaveNewNotifications={setHaveNewNotifications}
            handleOperatorProposeOperation={handleOperatorProposeOperation}
            loadOlderMessages={loadOlderMessages}
          /> */}
        </div>
        <div style={{ flex: 1 }}>
          {isRemoteId && (
            <Drawer
              id="trackerMonitor"
              variant="persistent"
              anchor="left"
              open={openRemoteIdTrackerList}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <TrackerMonitor
                telemetryData={telemetryData}
                // selectedFlightData={selectedFlightData.current}
                selectedFlightData={selectedFlightData}
                OperationsSummary={OperationsSummary}
              />
            </Drawer>
          )}

          {isOperator && (
            <Drawer
              id="assetManagement"
              variant="persistent"
              anchor="left"
              open={openAssetManagement}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  // paddingTop: 66,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              {openAssetManagement && <AssetManagement />}
            </Drawer>
          )}
          {!isAuthority && (
            <Drawer
              id="flightAuth"
              variant="persistent"
              // classes={{
              //   paper: classes.drawerPaper,
              // }}
              anchor="left"
              open={openFlightAuthorization}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  // paddingTop: 66,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <FlightAuthorization
                // mapViewController={mapViewController}
                // selectedFlightData={selectedFlightData.current}
                selectedFlightData={selectedFlightData}
                // telemetryData={telemetryData}
                constraints={constraints}
                emergencyLanding={emergencyLanding}
                setEmergencyLanding={setEmergencyLanding}
                // contingencyLandingPoint={contingencyLandingPoint}
                // setContingencyLandingPoint={setContingencyLandingPoint}
                selectedWaypoints={selectedWaypoints}
                setSelectedWaypoints={setSelectedWaypoints}
                /* polygon props */
                selectedAreaWaypoints={selectedAreaWaypoints}
                setSelectedAreaWaypoints={setSelectedAreaWaypoints}
                // onUpdateSelectedAreaWaypointsMove={
                //   setUpdatedSelectedAreaWaypoints
                // }
                /* circle props */
                selectedCircleWaypoints={selectedCircleWaypoints}
                setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                // updatedSelectedCircleWaypoints={updatedSelectedCircleWaypoints}
                // onUpdateSelectedCircleWaypointsMove={
                //   setUpdatedSelectedCircleWaypoints
                // }
                selectedCircleProperties={selectedCircleProperties}
                setSelectedCircleProperties={setSelectedCircleProperties}
                // updateSelectedCircleProperties={updateSelectedCircleProperties}
                // onUpdateSelectedCirclePropertiesMove={
                //   setUpdatedSelectedCircleProperties
                // }
                value={flightAuthorizationTabValue}
                setValue={setFlightAuthorizationTabValue}
                handleOpenFlightAuthorization={handleOpenFlightAuthorization}
                openFlightAuthorization={openFlightAuthorization}
              />
            </Drawer>
          )}
          {!isAuthority && (
            <Drawer
              id="dronePortOp"
              variant="persistent"
              anchor="left"
              open={openDronePortOperations}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <PortOperationsComponent
                selectedShip={selectedShip}
                setSelectedShip={setSelectedShip}
                selectedTakeOffLandingPoint={selectedTakeOffLandingPoint}
                setSelectedTakeOffLandingPoint={setSelectedTakeOffLandingPoint}
                telemetryData={telemetryData}
                onEmergencyLandingUpdateMove={setUpdatedEmergencyLanding}
                emergencyLandingMove={emergencyLanding}
                selectedAreaWaypointsMove={selectedAreaWaypoints}
                onUpdateSelectedAreaWaypointsMove={
                  setUpdatedSelectedAreaWaypoints
                }
                value={portOperationTabValue}
                setValue={setPortOperationTabValue}
                openDronePortOperations={openDronePortOperations}
                handleOpenDronePortOperations={handleOpenDronePortOperations}
                /* Ship Delivery Operation Variables */
                // selectedFlightData={selectedFlightData.current}
                selectedFlightData={selectedFlightData}
                constraints={constraints}
                emergencyLanding={emergencyLanding}
                setEmergencyLanding={setEmergencyLanding}
                selectedWaypoints={selectedWaypoints}
                setSelectedWaypoints={setSelectedWaypoints}
                /* polygon props */
                selectedAreaWaypoints={selectedAreaWaypoints}
                setSelectedAreaWaypoints={setSelectedAreaWaypoints}
                /* circle props */
                selectedCircleWaypoints={selectedCircleWaypoints}
                setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                selectedCircleProperties={selectedCircleProperties}
                setSelectedCircleProperties={setSelectedCircleProperties}
                handleOpenFlightAuthorization={handleOpenFlightAuthorization}
                openFlightAuthorization={openFlightAuthorization}
              />
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="flightAuthAuth"
              variant="persistent"
              anchor="left"
              open={openFlightAuthorizationAuthority}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <FlightAuthorizationAuthority
                mapViewController={mapViewController}
                // selectedFlightData={selectedFlightData}
                // constraints={constraints}
                // onEmergencyLandingUpdateMove={setUpdatedEmergencyLanding}
                // emergencyLandingMove={emergencyLanding}
                // selectedWaypointsMove={selectedWaypoints}
                // onUpdateSelectedWaypointsMove={setUpdatedSelectedWaypoints}
                // selectedAreaWaypointsMove={selectedAreaWaypoints}
                // onUpdateSelectedAreaWaypointsMove={
                //   setUpdatedSelectedAreaWaypoints
                // }
                handleopenAuthProposeOperationDrawer={
                  handleopenAuthProposeOperationDrawer
                }
                handleOpenFlightAuthorizationAuthority={
                  handleOpenFlightAuthorizationAuthority
                }
                flightAuthorizationAuthorityDrawerIsOpen={
                  openFlightAuthorizationAuthority
                }
              />
            </Drawer>
          )}
          {(isPilot || isOperator) && (
            <Drawer
              id="Drone Operation"
              variant="persistent"
              anchor="left"
              open={openDroneOperation}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <DroneOperation
                handleOpenDroneOperation={handleOpenDroneOperation}
                handleOpenFlightAuthorizationForced={
                  handleOpenFlightAuthorizationForced
                }
                handleCloseAllDrawers={handleCloseAllDrawers}
                isPilot={isPilot}
              />
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="Replay Function"
              variant="persistent"
              anchor="left"
              open={openReplayFunction}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <ReplayFunction
                handleOpenReplayFunction={handleOpenReplayFunction}
              />
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="operatorMgr"
              variant="persistent"
              anchor="left"
              open={openOperatorManagement}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              {openOperatorManagement ? (
                <OperatorManagementTab
                  setAuthorityAssignOperation={setAuthorityAssignOperation}
                />
              ) : null}
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="constraintMgr"
              variant="persistent"
              anchor="left"
              open={openConstrainMgrAuthority}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <ConstraintMgrAuthority
                // mapViewController={mapViewController}
                // selectedFlightData={selectedFlightData}
                constraints={constraints}
                editConstraints={editConstraints}
                onEmergencyLandingUpdateMove={setUpdatedEmergencyLanding}
                emergencyLanding={emergencyLanding}
                selectedAreaWaypoints={selectedAreaWaypoints}
                setSelectedAreaWaypoints={setSelectedAreaWaypoints}
                selectedCircleWaypoints={selectedCircleWaypoints}
                setSelectedCircleWaypoints={setSelectedCircleWaypoints}
                selectedCircleProperties={selectedCircleProperties}
                setSelectedCircleProperties={setSelectedCircleProperties}
                openConstrainMgrAuthority={openConstrainMgrAuthority}
                handleOpenConstrainMgrAuthority={
                  handleOpenConstrainMgrAuthority
                }
                seteditConstraints={seteditConstraints}
                // deprecated
                onUpdateSelectedWaypointsMove={setUpdatedSelectedWaypoints}
                onUpdateSelectedCircleWaypointsMove={
                  setUpdatedSelectedCircleWaypoints
                }
                onUpdateSelectedCirclePropertiesMove={
                  setUpdatedSelectedCircleProperties
                }
                onUpdateSelectedAreaWaypointsMove={
                  setUpdatedSelectedAreaWaypoints
                }
              />
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="constraintPanel"
              variant="persistent"
              anchor="left"
              open={openConstraintPanel}
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              <ConstraintPanel
                onDrawerClose={() => setIsDrawerOpenTaskConstrainPanel(false)}
                isDrawerOpen={isDrawerOpenTaskConstrainPanel}
                // selectedFlightData={selectedFlightData.current}
                selectedFlightData={selectedFlightData}
                onRemoveOperation={getUserOperations}
                onUpdateOperationState={getUserOperations}
                // toggleShowShipLayer={toggleShowShipLayer}
                // toggleShowAdsbLayer={toggleShowAdsbLayer}
                // toggleShowConstraintsLayer={toggleShowConstraintsLayer}
                // toggleShowStaticConstraintsLayer={
                //   toggleShowStaticConstraintsLayer
                // }
                constraints={constraints}
                onopenConstrainMgrAuthority={handleOpenConstrainMgrAuthority}
                handleOpenConstraintPanel={handleOpenConstraintPanel}
                seteditConstraints={seteditConstraints}
                editConstrainStatus={editConstrainStatus}
              />
            </Drawer>
          )}
          {isAuthority && (
            <Drawer
              id="authProposeOperation"
              variant="persistent"
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
              anchor="left"
              open={openAuthProposeOperation}
            >
              <ProposeAuthorization
                mapViewController={mapViewController}
                // selectedFlightData={selectedFlightData}
                // telemetryData={telemetryData}
                // constraints={constraints}
                onEmergencyLandingUpdateMove={setUpdatedEmergencyLanding}
                emergencyLandingMove={emergencyLanding}
                selectedWaypointsMove={selectedWaypoints}
                onUpdateSelectedWaypointsMove={setUpdatedSelectedWaypoints}
                selectedAreaWaypointsMove={selectedAreaWaypoints}
                handleSetSelectedAreaWaypoints={handleSetSelectedAreaWaypoints}
                setSelectedWaypoints={handleSetSelectedWaypoints}
                setSelectedAreaWaypoints={handleSetSelectedAreaWaypoints}
                setSelectedCircleWaypoints={handleSetSelectedCircleWaypoints}
                setSelectedCircleProperties={setSelectedCircleProperties}
                onUpdateSelectedAreaWaypointsMove={
                  setUpdatedSelectedAreaWaypoints
                }
                selectedCircleWaypointsMove={selectedCircleWaypoints}
                onUpdateSelectedCircleWaypointsMove={
                  setUpdatedSelectedCircleWaypoints
                }
                selectedCirclePropertiesMove={selectedCircleProperties}
                onUpdateSelectedCirclePropertiesMove={
                  setUpdatedSelectedCircleProperties
                }
                handleopenAuthProposeOperationDrawer={
                  handleopenAuthProposeOperationDrawer
                }
              />
            </Drawer>
          )}
          {!isAuthority && (
            <Drawer
              id="schedules"
              open={openSchedules}
              variant="persistent"
              anchor="left"
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                  overflow: "scroll",
                },
              }}
            >
              {openSchedules && (
                <Schedules
                  loadOlderLogs={loadOlderLogs}
                  setloadOlderLogs={setLoadOlderLogs}
                  setDuplicateTriggered={() => {
                    handleOpenFlightAuthorization();
                  }}
                  isRemoteId={isRemoteId}
                />
              )}
            </Drawer>
          )}
          {(isOperator || isPilot) && (
            <Drawer
              id="incidentReporting"
              open={openIncidentReporting}
              variant="persistent"
              anchor="left"
              PaperProps={{
                sx: {
                  zIndex: 1,
                  maxHeight: "calc(100% - 35px)",
                  height: "auto",
                  marginLeft: `${sidebarWidth}px`,
                },
              }}
            >
              {openIncidentReporting && <IncidentReport />}
            </Drawer>
          )}
          <Drawer
            id="notifsTable"
            variant="persistent"
            anchor="left"
            open={openNotifications}
            PaperProps={{
              sx: {
                zIndex: 1,
                maxHeight: "calc(100% - 35px)",
                height: "auto",
                marginLeft: `${sidebarWidth}px`,
              },
            }}
          >
            {/* {openNotifications && (
              <> */}
            {isAuthority && (
              <ActionMessagesTable
                data={notifications}
                olderMessages={olderNotifications}
                handleClickOpenFlightAuthorizationAuthority={
                  handleOpenFlightAuthorizationAuthority
                }
                handleOpenNotifications={handleOpenNotifications}
                loadOlderMessages={loadOlderMessages}
              />
            )}
            {isPilot && (
              <ActionMessagesTable
                handleOperatorProposeOperation={handleOperatorProposeOperation}
                data={notifications}
                olderMessages={olderNotifications}
                handleOpenNotifications={handleOpenNotifications}
                loadOlderMessages={loadOlderMessages}
              />
            )}
            {isOperator && (
              <ActionMessagesTable
                handleOperatorProposeOperation={handleOperatorProposeOperation}
                data={notifications}
                olderMessages={olderNotifications}
                handleOpenNotifications={handleOpenNotifications}
                loadOlderMessages={loadOlderMessages}
              />
            )}
            {isRemoteId && (
              <ActionMessagesTable
                handleOperatorProposeOperation={handleOperatorProposeOperation}
                data={notifications}
                olderMessages={olderNotifications}
                handleOpenNotifications={handleOpenNotifications}
                loadOlderMessages={loadOlderMessages}
              />
            )}
          </Drawer>
          {mapBoxToken && (
            <MapB
              mapBoxToken={mapBoxToken}
              // selectedFlightData={selectedFlightData.current}
              selectedFlightData={selectedFlightData}
              selectedTrackerData={selectedTrackerData}
              selectedPlatformData={selectedPlatformData}
              // telemetryData={telemetryData}
              constraints={constraints}
              buildingData={buildingData}
              isRemoteId={isRemoteId}
              // contingencyLandingPoint={contingencyLandingPoint}
              // setContingencyLandingPoint={setContingencyLandingPoint}
              setMapViewController={setMapViewController}
              emergencyLanding={emergencyLanding}
              handleSetEmergencyLanding={handleSetEmergencyLanding}
              /* Route Waypoints */
              selectedWaypoints={selectedWaypoints}
              setSelectedWaypoints={setSelectedWaypoints}
              handleSetSelectedWaypoints={handleSetSelectedWaypoints}
              /* Area Waypoints */
              selectedAreaWaypoints={selectedAreaWaypoints}
              setSelectedAreaWaypoints={setSelectedAreaWaypoints}
              handleSetSelectedAreaWaypoints={handleSetSelectedAreaWaypoints}
              /* Circle Waypoints */
              selectedCircleWaypoints={selectedCircleWaypoints}
              selectedCircleProperties={selectedCircleProperties}
              handleSetSelectedCircleProperties={
                handleSetSelectedCircleProperties
              }
              /* Ship Delivery Operation */
              setSelectedShip={setSelectedShip}
              handleSetSelectedCircleWaypoints={
                handleSetSelectedCircleWaypoints
              }
              handleOpenShipDeliveryOperationsForced={
                handleOpenShipDeliveryOperationsForced
              }
              /* Layer Shows */
              showShipLayer={showShipLayer}
              showAdsbLayer={showAdsbLayer}
              showConstraintsLayer={showConstraintsLayer}
              showStaticConstraintsLayer={showStaticConstraintsLayer}
              showBuildingLayer={showBuildingLayer}
              showOperationVolumeLayer={showOperationVolumeLayer}
              showDroneIconLayer={showDroneIconLayer}
              showRainMapLayer={showRainMapLayer}
              showAirMapSpace={showAirMapSpace}
              showAnchorageLayer={showAnchorageLayer}
              showDronePortLayer={showDronePortLayer}
              showSgBoundaryLayer={showSgBoundaryLayer}
              showSgPopulationDensity={showSgPopulationDensity}
              showMapElevation={showMapElevation}
              showGnssLayer={showGnssLayer}
              showCollisionLayer={showCollisionLayer}
              /* Layer Togglers */
              toggleShowShipLayer={toggleShowShipLayer}
              toggleShowAdsbLayer={toggleShowAdsbLayer}
              toggleShowConstraintsLayer={toggleShowConstraintsLayer}
              toggleShowStaticConstraintsLayer={
                toggleShowStaticConstraintsLayer
              }
              toggleShowBuildingLayer={toggleShowBuildingLayer}
              toggleShowOperationVolumeLayer={toggleshowOperationLayer}
              togglesshowDroneIconLayer={togglesshowDroneIconLayer}
              toggleShowRainMapLayer={toggleShowRainMapLayer}
              togglesshowAirMapSpace={togglesshowAirMapSpace}
              togglesshowGnssLayer={togglesshowGnssLayer}
              toggleShowAnchorageLayer={toggleShowAnchorageLayer}
              toggleShowDronePortLayer={toggleShowDronePortLayer}
              toggleSgBoundaryLayer={toggleSgBoundaryLayer}
              toggleSgPopulationDensity={toggleSgPopulationDensity}
              toggleMapElevation={toggleMapElevation}
              toggleShowCollisionLayer={toggleShowCollisionLayer}
              /* Drawer Open Togglers */
              toggleMostRecentComponent={toggleMostRecentComponent}
              mostRecentComponent={mostRecentComponent}
              openFlightAuthorization={openFlightAuthorization}
              handleOpenFlightAuthorization={handleOpenFlightAuthorization}
              openConstrainMgrAuthority={openConstrainMgrAuthority}
              handleOpenConstrainMgrAuthority={handleOpenConstrainMgrAuthority}
              /* Pilot, Platform, Tracker List */
              platformList={platformList}
              trackerList={trackerList}
              pilotList={pilotList}
            />
          )}
        </div>
        <div
          style={{
            flex: 0.22,
            minWidth: "245px",
            maxWidth: "400px",
            backgroundColor: Colours[colourTheme].opsPanel,
            padding: "0px 5px",
            // overflow: "auto",
            // height: "100vh",
          }}
        >
          <OperationsPanel
            // telemetryData={telemetryData}
            // selectedFlightData={selectedFlightData.current}
            selectedFlightData={selectedFlightData}
            selectedPlatformData={selectedPlatformData}
            getUserOperations={getUserOperations}
            onUpdateOperationState={getUserOperations}
            getSelectedOperation={getSelectedOperation}
            role={role}
            OperationsSummary={OperationsSummary}
            GroundSummary={GroundSummary}
            AirborneSummary={AirborneSummary}
            flightDataIsLoading={flightDataIsLoading}
            isRemoteId={isRemoteId}
            handleOpenFlightAuthorizationForced={
              handleOpenFlightAuthorizationForced
            }
            platformList={platformList}
            trackerList={trackerList}
            pilotList={pilotList}
          />
        </div>
        {/* </div> */}
        {/* <Tasks
          onDrawerClose={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          telemetryData={telemetryData}
          selectedFlightData={selectedFlightData.current}
          selectedPlatformData={selectedPlatformData}
          showGnssLayer={showGnssLayer}
          showAnchorageLayer={showAnchorageLayer}
          showDronePortLayer={showDronePortLayer}
          showSgBoundaryLayer={showSgBoundaryLayer}
          showSgPopulationDensity={showSgPopulationDensity}
        />
      </div>
      <Tasks
        onDrawerClose={() => setIsDrawerOpen(false)}
        isDrawerOpen={isDrawerOpen}
        telemetryData={telemetryData}
        selectedFlightData={selectedFlightData}
        selectedPlatformData={selectedPlatformData}
        onRemoveOperation={getUserOperations}
        onUpdateOperationState={getUserOperations}
        toggleShowShipLayer={toggleShowShipLayer}
        toggleShowAdsbLayer={toggleShowAdsbLayer}
        toggleShowConstraintsLayer={toggleShowConstraintsLayer}
        toggleShowStaticConstraintsLayer={toggleShowStaticConstraintsLayer}
        togglesshowDroneIconLayer={togglesshowDroneIconLayer}
        role={role}
        OperationsSummary={OperationsSummary}
        toggleShowBuildingLayer={toggleShowBuildingLayer}
        toggleShowOperationVolumeLayer={toggleshowOperationLayer}
        toggleShowRainMapLayer={toggleShowRainMapLayer}
        GroundSummary={GroundSummary}
        AirborneSummary={AirborneSummary}
        togglesshowAirMapSpace={togglesshowAirMapSpace}
        flightDataIsLoading={flightDataIsLoading}
        togglesshowGnssLayer={togglesshowGnssLayer}
        toggleShowAnchorageLayer={toggleShowAnchorageLayer}
        toggleShowDronePortLayer={toggleShowDronePortLayer}
        toggleSgBoundaryLayer={toggleSgBoundaryLayer}
        toggleSgPopulationDensity={toggleSgPopulationDensity}
        isRemoteId={isRemoteId}
      />
      <TasksComponentConstrainPanel
        onDrawerClose={() => setIsDrawerOpenTaskConstrainPanel(false)}
        isDrawerOpen={isDrawerOpenTaskConstrainPanel}
        telemetryData={telemetryData}
        selectedFlightData={selectedFlightData}
        selectedPlatformData={selectedPlatformData}
        onRemoveOperation={getUserOperations}
        onUpdateOperationState={getUserOperations}
        toggleShowShipLayer={toggleShowShipLayer}
        toggleShowAdsbLayer={toggleShowAdsbLayer}
        toggleShowConstraintsLayer={toggleShowConstraintsLayer}
        toggleShowStaticConstraintsLayer={toggleShowStaticConstraintsLayer}
        constraints={constraints}
        onopenConstrainMgrAuthority={handleopenConstrainMgrAuthority}
        seteditConstraints={seteditConstraints}
        editConstrainStatus={editConstrainStatus}
      /> */}
        <PilotLicenseExpiredDialog
          openPilotExpiredDialog={openPilotExpiredDialog}
          handleClosePilotExpiredDialog={handleClosePilotExpiredDialog}
          pilotsDataAlert={pilotsDataAlert}
        />
        {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={messageDisplay}
        /> */}
        {authorityOperationAlertOpen && (
          <AuthorityOperationAlertPopup
            open={authorityOperationAlertOpen}
            closeHandler={authorityOperationAlertCloseHandler}
            title="Notification from Authority"
            messagePrefix={authorityOperationAlertMessagePrefix}
            message={authorityOperationAlertMessage}
          />
        )}
      </div>
      {/* <div style={{position=}}> 
        </div>  */}
    </div>
  );
}

export default memo(ProtectedComponent);
