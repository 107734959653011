import React, { useState, useEffect, useRef, memo } from "react";
import Card from "@mui/material/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@mui/material/CardContent";
import CardTitle from "material-ui/Card/CardTitle";
import Paper from "material-ui/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import { PieChart, BarChart } from "./addChart";
import { useApi } from "../../api/useApi";

import { setSnackbarMessage } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    padding: theme.spacing(0.5, 0.5),
    width: 390,
    height: 330,
    margin: theme.spacing(2),
  },
  card: {
    // boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    position: "relative",
    border: "1px solid #000",
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2),
    width: 400,
    textAlign: "left",
  },
}));

function PilotDetails(props) {
  const { pilot } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardTitle>
        <Typography variant="h6" color="primary">
          {pilot.pilot_name}
        </Typography>
      </CardTitle>
      <Divider />
      <CardContent>
        <Typography variant="caption" display="block">
          Email
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {pilot.pilot_email}
        </Typography>
        <Typography variant="caption" display="block">
          License Number
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {pilot.pilot_registration}
        </Typography>
        <Typography variant="caption" display="block">
          Expiry Date
        </Typography>
        <Typography variant="subtitle1" color="primary" display="block">
          {pilot.registration_expiry.split("T")[0]}
        </Typography>
      </CardContent>
    </Card>
  );
}

function PilotDashBoard(props) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const {
    pilot,
    platformList,
    platformTypeList,
    logState,
    handleClosePilotStatistics,
  } = props;
  const api = useApi();
  const pilotOperationsRef = useRef([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    pilotOperationsRef.current = [];
    getData();
  }, []);

  const getData = async (maxLoops = 2) => {
    try {
      // maxLoops caps the response at 200 operations
      const [pilotOperationsResponse] = await Promise.all([
        api.getPilotOperations(
          pilot.pilot_uuid,
          pilotOperationsRef.current.length
        ),
      ]);
      pilotOperationsRef.current = [
        ...pilotOperationsRef.current,
        ...pilotOperationsResponse.data,
      ];
      if (pilotOperationsResponse.data.length > 99 && maxLoops > 1) {
        getData(maxLoops - 1);
      } else {
        organizeData();
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to Obtain Data ${err}`,
          severity: "error",
        })
      );
      handleClosePilotStatistics();
    }
  };

  const cardDetails = [
    {
      cardHeader: "Operations By Platform Type",
      chartData: "opsByPlatformType",
      type: "pieChart",
    },
    {
      cardHeader: "Operations By Platform",
      chartData: "opsByPlatform",
      type: "pieChart",
    },
    {
      cardHeader: "Operations By Operation Type",
      chartData: "opsByOpsType",
      type: "pieChart",
    },
    {
      cardHeader: "Operations by Month",
      chartData: "opsByMonth",
      type: "pieChart",
    },
    {
      cardHeader: "Total Flight Duration by Platform Type",
      chartData: "totalFlightDurationByPlatformType",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Platform",
      chartData: "totalFlightDurationByPlatform",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Operation Type",
      chartData: "totalFlightDurationByOpsType",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Month",
      chartData: "totalFlightDurationByMonth",
      type: "barChart",
    },
  ];

  const organizeData = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const checkTimeDifference = (timestamp1, timestamp2) => {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);

      const epochTime1 = date1.getTime();
      const epochTime2 = date2.getTime();

      const timeDifference = Math.abs(epochTime1 - epochTime2);
      const timeDifferenceInHours = parseFloat(
        (timeDifference / (1000 * 60 * 60)).toFixed(2)
      );

      return timeDifferenceInHours;
    };

    const organizeDataComplete = {
      totalFlightDuration: 0,
      totalOperationCount: pilotOperationsRef.current.length,
      countOfNonConformance: 0,
      opsByPlatformType: {},
      opsByPlatform: {},
      opsByOpsType: {},
      opsByMonth: {},
      totalFlightDurationByPlatformType: {},
      totalFlightDurationByPlatform: {},
      totalFlightDurationByOpsType: {},
      totalFlightDurationByMonth: {},
    };

    monthNames.forEach((month) => {
      organizeDataComplete.opsByMonth[month] = 0;
      organizeDataComplete.totalFlightDurationByMonth[month] = 0;
    });

    const nonConformingRemoveDuplicates = logState?.reduce(
      (result, operation) => {
        if (
          operation.operation_state === "Nonconforming" ||
          operation.operation_state === "contingent"
        )
          return {
            ...result,
            [operation.operation_uuid]: operation.operation_state,
          };
        return result;
      },
      {}
    );

    pilotOperationsRef.current.forEach((singleOperation) => {
      if (
        nonConformingRemoveDuplicates[
          singleOperation.operation_json.reference.id
        ]
      )
        organizeDataComplete.countOfNonConformance += 1;
      const month = new Date(
        singleOperation.operation_json.reference.time_start.value
      );
      // not sure if need to use telemetry data to calculate instead of operation json
      // use operation json for now, for display purporse
      // *** Duration Calculations
      organizeDataComplete.totalFlightDuration += checkTimeDifference(
        singleOperation.operation_json.reference.time_start.value,
        singleOperation.operation_json.reference.time_end.value
      );

      organizeDataComplete.totalFlightDurationByMonth[
        monthNames[month.getMonth() - 1]
      ]
        ? (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));

      organizeDataComplete.totalFlightDurationByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));
      // *** Duration Calculations END

      // Platform Calculations
      singleOperation.operation_json.request.platform_uuid.forEach(
        (singlePlatformUuid) => {
          organizeDataComplete.opsByPlatform[
            platformList[singlePlatformUuid].callsign
          ]
            ? (organizeDataComplete.opsByPlatform[
                platformList[singlePlatformUuid].callsign
              ] += 1)
            : (organizeDataComplete.opsByPlatform[
                platformList[singlePlatformUuid].callsign
              ] = 1);

          organizeDataComplete.totalFlightDurationByPlatform[
            platformList[singlePlatformUuid].callsign
          ]
            ? (organizeDataComplete.totalFlightDurationByPlatform[
                platformList[singlePlatformUuid].callsign
              ] += checkTimeDifference(
                singleOperation.operation_json.reference.time_start.value,
                singleOperation.operation_json.reference.time_end.value
              ))
            : (organizeDataComplete.totalFlightDurationByPlatform[
                platformList[singlePlatformUuid].callsign
              ] = checkTimeDifference(
                singleOperation.operation_json.reference.time_start.value,
                singleOperation.operation_json.reference.time_end.value
              ));

          organizeDataComplete.opsByPlatformType[
            platformTypeList[platformList[singlePlatformUuid].typeUuid]
          ]
            ? (organizeDataComplete.opsByPlatformType[
                platformTypeList[platformList[singlePlatformUuid].typeUuid]
              ] += 1)
            : (organizeDataComplete.opsByPlatformType[
                platformTypeList[platformList[singlePlatformUuid].typeUuid]
              ] = 1);

          organizeDataComplete.totalFlightDurationByPlatformType[
            platformTypeList[platformList[singlePlatformUuid].typeUuid]
          ]
            ? (organizeDataComplete.totalFlightDurationByPlatformType[
                platformTypeList[platformList[singlePlatformUuid].typeUuid]
              ] += checkTimeDifference(
                singleOperation.operation_json.reference.time_start.value,
                singleOperation.operation_json.reference.time_end.value
              ))
            : (organizeDataComplete.totalFlightDurationByPlatformType[
                platformTypeList[platformList[singlePlatformUuid].typeUuid]
              ] = checkTimeDifference(
                singleOperation.operation_json.reference.time_start.value,
                singleOperation.operation_json.reference.time_end.value
              ));
        }
      );
      // *** Platform Calculations END

      organizeDataComplete.opsByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] += 1)
        : (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] = 1);

      organizeDataComplete.opsByMonth[monthNames[month.getMonth() - 1]]
        ? (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] += 1)
        : (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] = 1);
    });
    setChartData(organizeDataComplete);
    setLoading(false);
  };

  return (
    <Paper style={{ overflow: "auto", padding: 10, width: "100%" }}>
      <LoadingOverlay active={loading} spinner>
        <Grid container style={{ width: 1700 }}>
          <Grid item>
            <PilotDetails
              pilot={pilot}
              countOfNonConformance={chartData?.countOfNonConformance}
            />
          </Grid>
          <Grid item>
            <div
              style={{
                margin: 20,
                justifyContent: "center",
              }}
            >
              <Typography variant="caption" display="block">
                Total Flight Time
              </Typography>
              <Typography variant="subtitle1" color="primary" display="block">
                {chartData?.totalFlightDuration?.toFixed(2)} Hours
              </Typography>
              <Typography variant="caption" display="block">
                Operation Count
              </Typography>
              <Typography variant="subtitle1" color="primary" display="block">
                {chartData?.totalOperationCount} Operations
              </Typography>
              <Typography variant="caption" display="block">
                Non Conformance Count
              </Typography>
              <Typography variant="subtitle1" color="primary" display="block">
                {chartData?.countOfNonConformance} Operations
              </Typography>
            </div>
          </Grid>
        </Grid>
        {!loading && (
          <Grid container style={{ width: 1700 }}>
            {chartData &&
              cardDetails.map((singleCard, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={singleCard.cardHeader}>
                  <Paper className={classes.container}>
                    {singleCard.type === "pieChart" && (
                      <PieChart
                        chartData={chartData[singleCard.chartData]}
                        chartName={singleCard.cardHeader}
                        showLegend
                      />
                    )}
                    {singleCard.type === "barChart" && (
                      <BarChart
                        chartData={chartData[singleCard.chartData]}
                        chartName={singleCard.cardHeader}
                      />
                    )}
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
      </LoadingOverlay>
    </Paper>
  );
}
export default memo(PilotDashBoard);
