import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import CreatePortOperationForm from "../CreatePortOperationForm";

LoadingOverlay.propType = undefined;

function CreatePortOperationComponent(props) {
  const {
    selectedAreaWaypoints,
    onUpdateSelectedAreaWaypoints,
    emergencyLanding,
    onEmergencyLandingUpdate,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    openDronePortOperations,
    handleOpenDronePortOperations,
  } = props;

  const [submittingOperation] = useState(false);

  useEffect(() => {
    getPlatforms();
    getPilots();
    getTrackers();
  }, []);

  return (
    <LoadingOverlay
      active={submittingOperation}
      spinner
      text="Submitting Port Operation..."
    >
      <CreatePortOperationForm
        onEmergencyLandingUpdate={onEmergencyLandingUpdate}
        emergencyLanding={emergencyLanding}
        selectedAreaWaypoints={selectedAreaWaypoints}
        onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypoints}
        availablePilots={availablePilots}
        availableTracker={availableTracker}
        availablePlatforms={availablePlatforms}
        getPlatforms={getPlatforms}
        getPilots={getPilots}
        getTrackers={getTrackers}
        openDronePortOperations={openDronePortOperations}
        handleOpenDronePortOperations={handleOpenDronePortOperations}
      />
    </LoadingOverlay>
  );
}

export default CreatePortOperationComponent;
