import { useEffect, useState } from "react";
import { getUA } from "react-device-detect";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { setSnackbarMessage } from "../../../store/actions";
import { useApi } from "../../../api/useApi";
import useCognitoAuth from "../../../hooks/useCognitoAuth";
import DroneImportedOperation from "./DroneImportedOperation";
import DroneUploadedOperation from "./DroneUploadedOperation";
import DJIMissionAPI from "./DJIMissionAPI";
// import DJILiveStreaming from "./DJILiveStreaming";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DroneOperationComponent(props) {
  const {
    handleOpenDroneImportedOperation,
    handleOpenUploadedDroneOperation,
    handleOpenFlightAuthorizationForced,
    handleCloseAllDrawers,
    isPilot,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const api = useApi();
  const dispatch = useDispatch();
  const [userUuid, setUserUuid] = useState("");

  const UserData = useCognitoAuth();
  const pilotName = UserData.username;
  const DJIDevice = getUA.includes("dji-open-platform");

  function loadDJIMissionAPI() {
    const getPilot = async () => {
      try {
        const responses = await api.getPilots();
        let found = false;
        responses.data.forEach((response) => {
          if (pilotName === response.pilot_username) {
            setUserUuid(response.pilot_uuid);
            found = true;
          }
        });
        if (!found) {
          dispatch(
            setSnackbarMessage({
              message: "User not found in pilots data.",
              severity: "error",
            })
          );
        }
      } catch (err) {
        dispatch(
          setSnackbarMessage({
            message: err.response.data.message,
            severity: "error",
          })
        );
        throw err;
      }
    };

    getPilot();
  }
  useEffect(() => {
    if (!isPilot) return;
    if (DJIDevice) {
      loadDJIMissionAPI();
      if (userUuid !== "" && userUuid) {
        DJIMissionAPI(api, dispatch, userUuid, pilotName);
      }
    }
  }, [userUuid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab label="Imported Operation" {...a11yProps(0)} />
          <Tab label="Uploaded Operation" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DroneImportedOperation
          handleOpenDroneImportedOperation={handleOpenDroneImportedOperation}
          handleOpenFlightAuthorizationForced={
            handleOpenFlightAuthorizationForced
          }
          handleCloseAllDrawers={handleCloseAllDrawers}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DroneUploadedOperation handleCloseAllDrawers={handleCloseAllDrawers} />
      </TabPanel>
    </div>
  );
}
