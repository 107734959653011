import { createStore, applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reducer as formReducer } from "redux-form";

import sagas from "../sagas";
import reducers from "./reducers";

import subscriptionStatus from "./reducers/subscriptions";
import snackbar from "./reducers/snackbar";
import shipData from "./reducers/shipData";
import ADSBDataPaid from "./reducers/ADSBDataPaid";
import ADSBDataFree from "./reducers/ADSBDataFree";
import operationsReducer from "./reducers/operations";
import mapsReducer from "./reducers/maps";
import authReducer from "./reducers/auth";
import userAccessReducer from "./reducers/useAccess";
import constriantsReducer from "./reducers/constraints";
import airMapSpaceData from "./reducers/airMapSpaceData";
import conformanceReducer from "./reducers/conformance";
import websocket from "./reducers/websocket";
import envVar from "./reducers/envVar";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

// export const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// );

export const store = configureStore({
  reducer: {
    auth: authReducer,
    form: formReducer,
    userAccess: userAccessReducer,
    operations: operationsReducer,
    subscriptionStatus,
    snackbar,
    shipData,
    ADSBDataPaid,
    ADSBDataFree,
    maps: mapsReducer,
    constraints: constriantsReducer,
    airMapSpaceData,
    conformanceData: conformanceReducer,
    websocket,
    envVar,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middleware),
});
sagaMiddleware.run(sagas);
