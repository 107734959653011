import { all, call, put, takeEvery } from "redux-saga/effects";

import { sharedEndpoints } from "../api/shared";
import { getAirmapspace } from "../api/airmapspace";

export function* getADSBDataPaid() {
  try {
    const apiResponse = yield call(sharedEndpoints.getADSB, null);
    yield put({
      type: "SET_ADSB_DATA_PAID",
      payload: apiResponse.data,
    });
  } catch (error) {
    yield put({
      type: "GET_ADSB_DATA_PAID_ERROR",
      payload: error,
    });
  }
}

export function* getAirMapSpaceData(action) {
  try {
    const apiResponse = yield call(getAirmapspace, action.data);
    yield put({
      type: "SET_AIRMAP_DATA",
      payload: apiResponse,
    });
  } catch (error) {
    yield put({
      type: "GET_AIRMAP_ERROR",
      payload: error,
    });
  }
}
export function* fetchAirMapData() {
  yield takeEvery("GET_AIRMAP_DATA", getAirMapSpaceData);
}

export function* fetchADSBDataPaid() {
  yield takeEvery("GET_ADSB_DATA_PAID", getADSBDataPaid);
}

export function* getADSBDataFree() {
  try {
    const apiResponse = yield call(sharedEndpoints.getAdsb, null);

    yield put({
      type: "SET_ADSB_DATA_FREE",
      payload: apiResponse.data,
    });
  } catch (error) {
    yield put({
      type: "GET_ADSB_DATA_FREE_ERROR",
      payload: error,
    });
  }
}

export function* fetchADSBDataFree() {
  yield takeEvery("GET_ADSB_DATA_FREE", getADSBDataFree);
}

export function* getShipData() {
  try {
    const apiResponse = yield call(sharedEndpoints.getShips, null);
    yield put({
      type: "SET_SHIP_DATA",
      payload: apiResponse.data,
    });
  } catch (error) {
    yield put({
      type: "GET_SHIP_DATA_ERROR",
      error,
    });
  }
}

/*
    Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
    Allows concurrent fetches of user.
  */
export function* fetchShipData() {
  yield takeEvery("GET_SHIP_DATA", getShipData);
}

export default function* root() {
  yield all([
    fetchShipData(),
    fetchADSBDataFree(),
    fetchADSBDataPaid(),
    // fetchAirMapData(),
  ]);
}
