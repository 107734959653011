import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

function descendingComparator(a, b) {
  const comp1 = a.Key;
  const comp2 = b.Key;

  if (comp2 === "Unknown" && comp1 === "Unknown") {
    return 0;
  }

  if (comp1 === "Unknown") {
    return 1;
  }

  if (comp2 === "Unknown") {
    return -1;
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DroneImportedOperationList(props) {
  const { waylineList, selectedFile, handleSelectOperation } = props;
  const [dataGrped, setDataGrped] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const usePaginationActionsStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    icon: {
      color: "black",
    },
  }));

  const classes = usePaginationActionsStyles();
  // const theme = useTheme();

  // Sort Data by pilotname
  React.useEffect(() => {
    const updatedData = {};
    waylineList.map((singleWayline) => {
      const waylineOwner = singleWayline.Key.split("/")[0];
      const operationFilename = singleWayline.Key.split("/").pop();
      if (updatedData[waylineOwner]) {
        updatedData[waylineOwner].push({
          fileName: operationFilename,
          Key: singleWayline.Key,
        });
      } else {
        updatedData[waylineOwner] = [
          {
            fileName: operationFilename,
            Key: singleWayline.Key,
          },
        ];
      }
    });
    setDataGrped(updatedData);
  }, [waylineList]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {Object.keys(dataGrped).map((objectOwnerName) => (
        <Accordion key={objectOwnerName}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              display="inline"
              variant="body2"
              style={{ marginLeft: "3px" }}
            >
              {objectOwnerName}
            </Typography>
          </AccordionSummary>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table id="pilotas">
              <TableBody>
                {dataGrped[objectOwnerName]
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={objectOwnerName + Math.random()}
                    >
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          checked={selectedFile?.Key === row.Key}
                          onChange={(evt, checked) => {
                            // !checked handles when checkbox is already checked
                            if (!checked) {
                              handleSelectOperation([]);
                            } else {
                              handleSelectOperation(row);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {`${row.fileName}`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataGrped[objectOwnerName].length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Accordion>
      ))}
    </Paper>
  );
}
