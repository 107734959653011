const initialState = { open: false, message: "", severity: "" };
const snackbarMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SNACKBAR_MESSAGE": {
      let { message } = action;
      if (typeof action.message === "object") {
        try {
          message = JSON.parse(action.message.body).message;
        } catch {
          message = "Error";
        }
      }
      return {
        open: action.open,
        message,
        severity: action.severity,
      };
    }
    default:
      return state;
  }
};

export default snackbarMessageReducer;
