import React from "react";
import Constraint from "@/model/api/Constraint";
import moment from "moment";
import { MdDateRange, MdHeight } from "react-icons/md";

interface ItemConstraintProps {
  data: Constraint;
  onClick: (data: Constraint) => void;
  onSelected: (id: string) => void;
}

export default function ItemConstraint({
  data,
  onClick,
  onSelected,
}: ItemConstraintProps) {
  return (
    <div
      className="w-full rounded-container-np flex-col mb-4 cursor-pointer"
      onClick={() => onClick(data)}
    >
      <div className="flex p-2 items-center">
        <h5 className="font-medium mb-1 grow text-ellipsis">{data.name}</h5>
        <input
          type="checkbox"
          className="input-checkbox"
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSelected(data.constraint_id)}
        />
      </div>
      <div className="flex px-2 py-4 border-t dark:border-gray-600">
        <div className="flex flex-col grow">
          <h5 className="font-medium mb-1">{data.description}</h5>
          <div className="flex mb-2">
            <MdHeight className="fill-gray-500 dark:fill-white" size={20} />
            <p className="text-sm font-medium ml-1">
              Alt : {data.extents[0].volume.altitude_lower.value} to{" "}
              {data.extents[0].volume.altitude_upper.value}{" "}
              {data.extents[0].volume.altitude_upper.units}
            </p>
          </div>
          <div className="flex">
            <MdDateRange className="fill-gray-500 dark:fill-white" size={20} />
            <p className="text-sm font-medium ml-1">
              {moment(data.time_start * 1000).format("DD/MM/YYYY HH:mm")} -{" "}
              {moment(data.time_end * 1000).format("DD/MM/YYYY HH:mm")}
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-b-lg flex p-2 border-t dark:border-gray-600 -mt-1 bg-gray-100 dark:bg-darkTertiary items-center content-center">
        <span className="grow" />
        {data.rule.authorisation_required && (
          <span className="new-badge">Authorisation Required</span>
        )}
        {data.rule.information_only && (
          <span className="new-badge">Information Only</span>
        )}
        {data.rule.conditional && (
          <span className="new-badge">Conditional</span>
        )}
        {data.rule.prohibited && <span className="new-badge">Prohibited</span>}
        {data.state && <span className="new-badge">{data.state}</span>}
      </div>
    </div>
  );
}
