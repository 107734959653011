import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";

import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";

import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage}
    />
  );
}

export default function AddRemoteIdComponent({
  open,
  updating,
  getRemoteId,
  remoteIdData,
  handleClose,
  handleRemoteIdUpdate,
}) {
  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [submittingNewRemoteId, setSubmittingNewRemoteId] =
    React.useState(false);
  const api = useApi();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (updating) {
      const { email: remote_id_email } = remoteIdData;
      setEmail(remote_id_email);
    } else {
      setEmail("");
    }
    setEmailError("");
  }, [open]);

  const submitNewRemoteId = async () => {
    try {
      const response = await api.createRemoteId({
        username,
        email,
      });
      if (response.data) {
        handleRemoteIdUpdate(response.data);
        setSubmittingNewRemoteId(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Remote Id created",
            severity: "success",
          })
        );
        getRemoteId();
        handleClose();
      }
    } catch (err) {
      setSubmittingNewRemoteId(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.message,
          severity: "error",
        })
      );
    }
  };

  const updateRemoteIdInfo = async () => {
    try {
      const response = await api.updateRemoteId(remoteIdData.username, {
        email,
      });
      if (response.data) {
        handleRemoteIdUpdate(response.data);
        setSubmittingNewRemoteId(false);
        getRemoteId();
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Remote Id updated",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      console.log(err.response);
      setSubmittingNewRemoteId(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.data,
          severity: "error",
        })
      );
    }
  };

  const validateForm = () => {
    let error = false;
    if (!updating) {
      if (!username) {
        setUsernameError("Remote Id username is required");
        error = true;
      }
      if (username.includes(" ")) {
        setUsernameError("Remote Id username must not contain spaces");
        error = true;
      }
    }
    if (!email) {
      setEmailError("Remote Id email is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewRemoteId(true);
    if (updating) {
      updateRemoteIdInfo();
    } else {
      submitNewRemoteId();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <LoadingOverlay
        active={submittingNewRemoteId}
        spinner
        text={
          updating
            ? "Updating remote id information..."
            : "Submitting new remote id..."
        }
      >
        <div style={{ height: "400px", width: "500px", margin: "30px" }}>
          <Typography
            component="div"
            display="inline"
            variant="h6"
            style={{ color: "black" }}
          >
            {updating ? "Update Remote Id" : "Add Remote Id"}
          </Typography>
          {!updating ? (
            <CustomTextField
              label="Username"
              value={username}
              setFuncton={setUsername}
              fullWidth
              errormessage={usernameError}
              setError={setUsernameError}
            />
          ) : null}
          <CustomTextField
            label="Email"
            value={email}
            setFuncton={setEmail}
            fullWidth
            errormessage={emailError}
            setError={setEmailError}
          />
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
