import React from "react";
import Platform from "@/model/api/Platform";

interface PlatformItemProps {
  data: Platform;
  onClick: (data: Platform) => void;
  onSelect: (id: string, isSelected: boolean) => void;
  onMaintenanceClick?: (data: Platform) => void;
}

export default function PlatformItem({
  data,
  onClick,
  onSelect,
  onMaintenanceClick,
}: PlatformItemProps) {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(data.platform_uuid, e.target.checked);
  };

  return (
    <div className="relative p-4 rounded-container mb-2 cursor-pointer">
      <div
        className="flex-col"
        key={data.platform_uuid}
        onClick={() => onClick(data)}
      >
        <h5 className="font-medium">{data.platform_callsign}</h5>
        <p>Registration Number : {data.platform_registration}</p>
        {onMaintenanceClick && (
          <button
            className="text-primary-500 font-medium mt-2"
            onClick={(e) => {
              e.stopPropagation();
              onMaintenanceClick(data);
            }}
          >
            Maintenance
          </button>
        )}
        {/* <p>
          Registration Expiry : {formatToDisplayDate(data.registration_expiry)}
        </p>
        <p>Remote Controller SN : {data.remote_controller_sn}</p>
        <p>Flight Controller SN : {data.flight_controller_sn}</p> */}
      </div>
      <input
        type="checkbox"
        className="input-checkbox absolute top-2 right-2"
        defaultChecked={false}
        onChange={handleCheckboxChange}
      />
    </div>
  );
}
