import Button from "@/components/v1/Common/Button";
import { GroupedOperator } from "@/model/api/GroupedOperator";
import React, { useState } from "react";

interface ConstraintWhitelistModalProps {
  type: string;
  groupedOperators: GroupedOperator[];
  operatorGroups: string[];
  selectedOperators: string[];
  selectedGroups: string[];
  onSave: (usernames: string[]) => void;
}

export default function ConstraintWhitelistModal({
  type,
  groupedOperators,
  operatorGroups,
  selectedOperators,
  selectedGroups,
  onSave,
}: ConstraintWhitelistModalProps) {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    type === "user" ? selectedOperators : selectedGroups
  );
  const [query, setQuery] = useState<string>("");

  const handleSelect = (userName: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedUsers([...selectedUsers, userName]);
    } else {
      setSelectedUsers(selectedUsers.filter((user) => user !== userName));
    }
    setQuery("");
  };

  return (
    <div className="w-125" onClick={(e) => e.stopPropagation()}>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="input-text mb-2"
        placeholder="Search Group"
      />

      <div className="mb-2 flex flex-wrap gap-1 w-full max-h-32 overflow-y-auto">
        {selectedUsers.map((userName) => (
          <div
            key={userName}
            className="flex items-center space-x-2 bg-gray-200 p-1 rounded m-1 dark:bg-darkQuaternary"
          >
            <span>{userName}</span>
          </div>
        ))}
      </div>

      <ul className="border border-t-0 h-60 overflow-y-auto rounded-md dark:border-gray-600 mb-4">
        {type === "user" ? (
          groupedOperators
            .filter((operator: GroupedOperator) => {
              if (query === "") return true;
              return operator.operatorName
                .toLowerCase()
                .includes(query.toLowerCase());
            })
            .map((operator) => (
              <li
                key={operator.id}
                className="p-2 cursor-pointer border-t dark:border-gray-600"
              >
                <h5 className="font-medium mb-1">{operator.operatorName}</h5>
                <ul className="ml-2">
                  {operator.operatorUser.map((user) => (
                    <li key={user.userName}>
                      <input
                        type="checkbox"
                        className="input-checkbox mr-2"
                        defaultChecked={selectedOperators.includes(
                          user.userName
                        )}
                        onChange={(e) =>
                          handleSelect(user.userName, e.target.checked)
                        }
                      />
                      <label>{user.userName}</label>
                    </li>
                  ))}
                </ul>
              </li>
            ))
        ) : (
          <ul className="ml-2 mt-2">
            {operatorGroups
              .filter((group) => {
                if (query === "") return true;
                return group.toLowerCase().includes(query.toLowerCase());
              })
              .map((group) => (
                <li key={group}>
                  <input
                    type="checkbox"
                    className="input-checkbox mr-2"
                    defaultChecked={selectedGroups.includes(group)}
                    onChange={(e) => handleSelect(group, e.target.checked)}
                  />
                  <label>{group}</label>
                </li>
              ))}
          </ul>
        )}
      </ul>

      <div className="flex">
        <span className="grow" />
        <Button
          type="light"
          size="small"
          text="Save"
          className="w-40"
          onClick={() => onSave(selectedUsers)}
        />
      </div>
    </div>
  );
}
