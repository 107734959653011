import React from "react";

interface MaintenanceDialogProps {
  api: any;
}

export default function MaintenanceDialog({ api }: MaintenanceDialogProps) {
  return (
    <div className="flex-col">
      <table className="table-auto w-full">
        <thead>
          <tr className="border rounded-t-md">
            <th className="px-4 py-2">Maintenance Date</th>
            <th className="px-4 py-2">Purpose</th>
            <th className="px-4 py-2">Defect</th>
            <th className="px-4 py-2">Rectification</th>
            <th className="px-4 py-2">Conducted By</th>
            <th className="px-4 py-2">Time Created</th>
          </tr>
        </thead>
      </table>

      <div className="flex justify-end">
        <button className="btn-primary">Add Maintenance</button>
      </div>
    </div>
  );
}
