import "./styles.css";

import { useState, useEffect, memo } from "react";
/*
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
*/
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";

import { useApi } from "../../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../../store/actions";
import EmergencyLandingPointEntry from "../CreateOperationForm/emergency";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { convertZuluToLocalTime } from "../../../api/timeConvert";
import gnssCreateOperation from "../../../components/MapB/gnss/gnssCreateOperation";
import DroneCorridorCard from "../../../components/DroneCorridorCard";
import GeoJsonFile from "../../../lib/arcgis/Drone-Port-geojson.geojson";

function fetchJSON(url) {
  return fetch(url).then(function (response) {
    return response.json();
  });
}

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography component="span" variant="body2">
          {props.children}
        </Typography>
      )}
    </div>
  );
}
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));
const TypeOptions = [
  {
    value: 0,
    label: "vlos",
  },
  {
    value: 1,
    label: "bvlos",
  },
];
function MultipleDrone({
  availablePlatforms,
  platformid,
  setPlatformID,
  pilotid,
  availablePilots,
  setPilotID,
  selectedMultiDrone,
  setSelectedMultiDrone,
  selectWaypointMethod,
  availableTracker,
  setTrackeruuid,
  getPlatforms,
  getPilots,
  getTrackers,

  unavailablePlatforms,
  unavailableTrackers,
  loadingAssetList,
}) {
  const [keysCounter, setKeysCounter] = useState(0);
  const d = new Date();
  const addFormFields = () => {
    const newfield = {
      platformid: availablePlatforms[0]?.platform_uuid,
      pilotid: availablePilots[0]?.pilot_uuid,
      trackeruuid: availableTracker[0]?.tracker_uuid,
      id: keysCounter + 1 + d.getTime(),
    };
    setSelectedMultiDrone([...selectedMultiDrone, newfield]);
  };
  const removeFields = (index) => {
    const data = [...selectedMultiDrone];
    data.splice(index, 1);
    setSelectedMultiDrone(data);
  };
  const handleFormChange = (index, event) => {
    const data = [...selectedMultiDrone];
    data[index][event.target.name] = event.target.value;
    setSelectedMultiDrone(data);
  };
  useEffect(() => {
    const dataPilotId = selectedMultiDrone.map((item, index, arr) => {
      return item.pilotid;
    });
    setPilotID(dataPilotId);
    const dataPlatformId = selectedMultiDrone.map((item, index, arr) => {
      return item.platformid;
    });
    setPlatformID(dataPlatformId);
    const dataTrackerID = selectedMultiDrone.map((item, index, arr) => {
      return item.trackeruuid;
    });
    setTrackeruuid(dataTrackerID);
  }, [selectedMultiDrone]);
  return (
    <div>
      {selectedMultiDrone.map((element, index) => (
        // <Grid container spacing={3} style={{ marginTop: "15px" }}>
        <div
          key={element.id}
          style={{
            display: "grid",
            gridTemplateColumns: "6fr 6fr 6fr 1fr 1fr",
            gap: "0.25rem",
            marginLeft: "1rem",
          }}
        >
          <LoadingOverlay spinner active={loadingAssetList}>
            <TextField
              id="platform"
              name="platformid"
              label="Platform Callsign"
              fullWidth
              select
              value={element.platformid || ""}
              onChange={(event) => {
                handleFormChange(index, event);
              }}
              onPointerDown={(event) => {
                getPlatforms && getPlatforms();
              }}
              style={{
                width: 0,
                minWidth: "100%",
              }}
            >
              {availablePlatforms.map((option) => (
                <MenuItem
                  key={option.platform_uuid}
                  value={option.platform_uuid}
                  disabled={
                    unavailablePlatforms
                      ? unavailablePlatforms.includes(option.platform_uuid)
                      : false
                  }
                >
                  {option.platform_callsign}{" "}
                  {unavailablePlatforms?.includes(option.platform_uuid)
                    ? "(Unavailable)"
                    : ""}
                </MenuItem>
              ))}
            </TextField>
          </LoadingOverlay>
          <TextField
            id="pilot"
            name="pilotid"
            label="Pilot Name"
            fullWidth
            select
            value={element.pilotid || ""}
            onChange={(event) => {
              handleFormChange(index, event);
            }}
            onPointerDown={(event) => {
              getPilots && getPilots();
            }}
          >
            {availablePilots.map((option) => (
              <MenuItem key={option.pilot_uuid} value={option.pilot_uuid}>
                {option.pilot_name}
              </MenuItem>
            ))}
          </TextField>
          <LoadingOverlay spinner active={loadingAssetList}>
            <TextField
              id="tracker"
              name="trackeruuid"
              label="Tracker Callsign"
              fullWidth
              select
              value={element.trackeruuid || ""}
              onChange={(event) => {
                handleFormChange(index, event);
              }}
              onPointerDown={(event) => {
                getTrackers && getTrackers();
              }}
              style={{
                width: 0,
                minWidth: "100%",
              }}
            >
              {availableTracker.map((option) => (
                <MenuItem
                  key={option.tracker_uuid}
                  value={option.tracker_uuid}
                  defaultValue=""
                  disabled={
                    unavailableTrackers
                      ? unavailableTrackers.includes(option.tracker_uuid)
                      : false
                  }
                >
                  {option.tracker_name}{" "}
                  {unavailableTrackers?.includes(option.tracker_uuid)
                    ? "(Unavailable)"
                    : ""}
                </MenuItem>
              ))}
            </TextField>
          </LoadingOverlay>
          <Button
            onClick={() => {
              removeFields(index);
            }}
            disabled={index <= 0}
          >
            <DeleteIcon />
          </Button>
          <Button
            disabled
            onClick={() => {
              addFormFields();
            }}
          >
            <Add />
          </Button>
        </div>
      ))}
    </div>
  );
}
function CreatePortOperationForm(props) {
  const {
    selectedAreaWaypoints: _selectedAreaWaypoints,
    onUpdateSelectedAreaWaypoints,
    onEmergencyLandingUpdate,
    emergencyLanding,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    openDronePortOperations,
    handleOpenDronePortOperations,
  } = props;

  // Set map
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  // Initialise default values for form
  const [selectedAreaWaypoints, setSelectedAreaPolygon] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [platformid, setPlatformID] = useState("");
  const [pilotid, setPilotID] = useState("");
  const [trackeruuid, setTrackeruuid] = useState("");
  const [operationType, setOperationType] = useState("bvlos");
  const [selectedMultiDrone, setSelectedMultiDrone] = useState([
    {
      platformid: "",
      pilotid: "",
      trackeruuid: "",
      id: "123123",
    },
  ]);
  const currentTime = new Date();
  const [departureTime, setDepartureTime] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeError, setDepartureTimeError] = useState("");
  const [prevTime, setPrevTime] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  );
  const [endTime, setEndTime] = useState(
    new Date(departureTime.getTime() + 30 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeError, setEndTimeError] = useState("");
  const [contingencyLandingPoint, setContingencyLandingPoint] = useState([
    0.0, 0.0,
  ]);
  const [safeAltitude, setSafeAltitude] = useState(60);
  const [operationDescription, setOperationDescription] = useState("");
  const [speed, setSpeed] = useState(5);
  const [altitude, setAltitude] = useState(60);
  const [verticalBuffer, setVerticalBuffer] = useState(25);
  const [lateralBuffer, setLateralBuffer] = useState(25);
  const [timeBuffer, setTimeBuffer] = useState(30);
  const [elevation, setElevation] = useState(0);
  const [maxSegmentLength, setMaxSegmentLength] = useState(500);
  const [airspaceOptimised, setAirspaceOptimised] = useState(false);
  const [customBuffer, setCustomBuffer] = useState(false);
  const [inboundOperation, setInboundOperation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const api = useApi();
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("area");
  const [isInEditMode, setIfEditMode] = useState(false);
  const operationFormRequest = useSelector(
    (state) => state.operations.operationFormRequest
  );
  const [openReject, setOpenReject] = useState(false);
  const [compareCurrentData, setCompareCurrentData] = useState({});
  const [reScheduled, setRescheduled] = useState(false);
  const [reRouted, setRerouted] = useState(false);
  const [compareData, setCompareData] = useState({});
  const [loadingGnss, setLoadingGnss] = useState(false);
  const [unavailableTrackers, setUnavailableTrackers] = useState([]);
  const [unavailablePlatforms, setUnavailablePlatforms] = useState([]);
  const [loadingAssetList, setLoadingAssetList] = useState(false);

  // Corridor availability states
  const [anchorage, setAnchorage] = useState({});
  const [faf, setFaf] = useState({});
  const [fafTol, setFafTol] = useState({});
  const [subsection, setSubsection] = useState({});
  const [outbound, setOutbound] = useState(true);
  const [availableRoutes, setAvailableRoutes] = useState({});
  const [selectedCorridor, setSelectedCorridor] = useState(-1);
  const [checkingCorridor, setCheckingCorridor] = useState(false);
  const [retData, setRetData] = useState([]);
  const [hoveredSubsection, setHoveredSubsection] = useState(null);
  const [selectedAnchorage, setSelectedAnchorage] = useState("");
  const [selectedSubsection, setSelectedSubsection] = useState("");
  const [selectedFaf, setSelectedFaf] = useState("");
  const [selectedFafTol, setSelectedFafTol] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      (selectedAnchorage != "" &&
        selectedSubsection != "" &&
        selectedFaf != "" &&
        selectedFafTol != "") ||
      departureTime != prevTime
    ) {
      setAvailableRoutes({});
      mapboxController.removeHighlightedArea2();
    }
  }, [
    selectedAnchorage,
    selectedSubsection,
    selectedFaf,
    selectedFafTol,
    departureTime,
  ]);
  const fetchData = async () => {
    try {
      const portData = await fetchJSON(GeoJsonFile);
      const portDataFeatures = portData.features || [];
      const anchoragesOptions = portDataFeatures.map((feature) => ({
        name: feature.properties.seamark_na,
        point: feature.geometry.coordinates[0][0],
        id: feature.properties.id,
        geometry: feature.geometry,
      }));

      const groupedAnchorages = anchoragesOptions.reduce(
        (acc, option) => {
          if (option.name.toLowerCase().includes("corridor")) {
            acc.Corridors.push(option);
          } else if (option.name.toLowerCase().includes("section")) {
            acc.Subsections.push(option);
          } else {
            acc.faf.push(option);
          }
          return acc;
        },
        { Corridors: [], Subsections: [], faf: [] }
      );

      const availableAnchorages2 = [
        {
          name: "Eastern Anchorage",
          point: [103.88618933181681, 1.2642828293283515],
          subsections: groupedAnchorages.Subsections.map((entry) => ({
            ...entry,
            // "Section must be replaced to Subsection in order to read the data fro API Call"
            name: entry.name.includes("Section")
              ? entry.name.replace("Section", "Subsection")
              : entry.name,
          })),
          corridors: groupedAnchorages.Corridors,
          faf: groupedAnchorages.faf.map((entry) => ({
            name:
              entry.name === "Maritime Drone Estate Helipad"
                ? "Maritime Drone Estate"
                : entry.name,
            tolNames: [{ name: entry.name, point: entry.point }],
          })),
        },
      ];
      setRetData(availableAnchorages2);

      return availableAnchorages2;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const resetForm = () => {
    const newTime = new Date(new Date().getTime() + 30 * 60000);
    setSelectedAreaPolygon([
      [0.0, 0.0],
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    onEmergencyLandingUpdate([0.0, 0.0]);
    setContingencyLandingPoint([0.0, 0.0]);
    setAltitude(60);
    setSafeAltitude(60);
    setOperationDescription("");
    setOperationType("bvlos");
    setDepartureTime(newTime);
    setEndTime(newTime);
    setPilotID("");
    setPlatformID("");
    setTrackeruuid("");
    setVerticalBuffer(25);
    setLateralBuffer(25);
    setTimeBuffer(30);
    setElevation(0);
    setMaxSegmentLength(500);
    setSelectedMultiDrone([
      {
        platformid: "",
        pilotid: "",
        trackeruuid: "",
      },
    ]);
    setAnchorage({});
    setFaf({});
    setFafTol({});
    setSubsection({});
    setOutbound(true);
    setAvailableRoutes({});
    setSelectedCorridor(-1);
  };

  const handleOpenSchedReroutedDialog = (CompareData, CompareCurrentData) => {
    if (CompareData.details.rescheduled || CompareData.details.rerouted) {
      CompareData?.details?.rescheduled
        ? setRescheduled(true)
        : setRescheduled(false);
      CompareData?.details?.rerouted ? setRerouted(true) : setRerouted(false);
      setCompareData(CompareData);
      setCompareCurrentData(CompareCurrentData);
      setOpenReject(true);
    } else if (CompareData?.details?.state === "Proposed") {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Operation proposed, awaiting approval",
          severity: "info",
        })
      );
    } else {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Operation submitted successfully",
          severity: "success",
        })
      );
    }
  };

  const handlePortOp = async () => {
    if (!platformid[0]) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing Platform Callsign",
          severity: "error",
        })
      );
      return;
    }
    if (!pilotid[0]) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing Pilot Name",
          severity: "error",
        })
      );
      return;
    }
    if (!trackeruuid[0]) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing Tracker Callsign",
          severity: "error",
        })
      );
      return;
    }
    // if (!operationDescription) {
    //   dispatch(
    //     setSnackbarMessage({
    //       open: true,
    //       message: "Missing Operation Description",
    //       severity: "error",
    //     })
    //   );
    //   return;
    // }
    try {
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        altitude,
        altitude_reference: "W84",
        contingency_plans: {
          landing_point: [contingencyLandingPoint],
          safe_altitude: safeAltitude,
        },
        description: operationDescription,
        time_start: departureTime.toISOString(),
        faf: {
          name: faf.name,
          tol_name: fafTol.name,
        },
        subsection: subsection.name,
        corridor: availableRoutes[selectedCorridor].corridor,
        outbound,
      };
      const response = await api.submitPortOperation(data);
      if (response.data) {
        handleOpenSchedReroutedDialog(response.data.operations[0], data);
        resetForm();
        dispatch(setOperationFormRequest(null));
      }
    } catch (err) {
      let { message } = err.response.data;
      if (message && message.startsWith("Airspace unavailable")) {
        message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };

  const handleCheckAvailability = async () => {
    if (!anchorage.name) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing anchorage",
          severity: "error",
        })
      );
      return;
    }
    if (!subsection.name) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing subsection",
          severity: "error",
        })
      );
      return;
    }
    if (!faf.name) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing FAF",
          severity: "error",
        })
      );
      return;
    }
    if (!fafTol.name) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Missing FAF Takeoff/ Landing Point",
          severity: "error",
        })
      );
      return;
    }
    try {
      const data = {
        best_time: departureTime,
        faf: {
          name: faf.name,
          tol_name: fafTol.name,
        },
        subsection: subsection.name,
        outbound,
      };
      setCheckingCorridor(true);
      const response = await api.getCorridorAvailability(data);
      setAvailableRoutes(
        response.data.corridor_availability.filter(
          (d) => d.nextHourAvailability
        )
      );
      setPrevTime(departureTime);
      setCheckingCorridor(false);
    } catch (err) {
      const { message } = err.response.data;
      if (message) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message,
            severity: "error",
          })
        );
        setCheckingCorridor(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    // const editMode = isInEditMode && operationFormRequest.id;
    // Submit Operation
    await handlePortOp();
    setSubmitLoading(false);
    mapboxController.removeHighlight();
    mapboxController.removeHighlightedArea2();
    mapboxController.removeHighlightedArea();
  };

  const handleOnFocus = async (center) => {
    mapboxController.map.flyTo({ center, zoom: 13 });
  };

  useEffect(() => {
    if (_selectedAreaWaypoints.length) {
      setSelectedAreaPolygon(_selectedAreaWaypoints);
      const center = computePolygonCenter(_selectedAreaWaypoints);
      setContingencyLandingPoint(center);
      onEmergencyLandingUpdate(center);

      if (!contingencyLandingPoint[0] || !contingencyLandingPoint[1]) {
        if (!center[0] || !center[1]) {
          setContingencyLandingPoint([
            _selectedAreaWaypoints[0][0],
            _selectedAreaWaypoints[0][1],
          ]);
        }
      }
    }
  }, [_selectedAreaWaypoints]);

  useEffect(() => {
    if (!openDronePortOperations || selectedCorridor > 0) {
      return;
    }
    const timeCheck = departureTime > new Date(currentTime.getTime());
    // if ((isInEditMode || lockVariables) && timeCheck) {
    if (timeCheck) {
      return;
    }
    setDepartureTime(new Date(currentTime.getTime() + 3 * 60000));
  }, [openDronePortOperations]);

  // Clear list of corridors when change start time
  useEffect(() => {
    if (!openDronePortOperations || selectedCorridor > 0) {
      return;
    }
    setAvailableRoutes({});
  }, [departureTime]);

  useEffect(() => {
    if (selectedCorridor === -1) {
      return;
    }
    checkAssetAvailability();
  }, [selectedCorridor]);

  // useEffect(() => {
  //   if (!operationFormRequest) {
  //     return;
  //   }
  //   resetForm();
  //   const operation = operationFormRequest.request;
  //   setAltitude(operation.altitude);
  //   setSafeAltitude(operation.contingency_plans.safe_altitude);
  //   setTimeBuffer(operation.time_buffer);
  //   setElevation(operation.elevation);
  //   setContingencyLandingPoint(operation.contingency_plans.landing_point[0]);
  //   onEmergencyLandingUpdate(operation.contingency_plans.landing_point[0]);
  //   mapboxController.flyTo(operation.contingency_plans.landing_point[0]);
  //   setOperationDescription(operation.description);
  //   setOperationType(operation.operation_type);
  //   setPilotID(operation.pilot_uuid);
  //   setPlatformID(operation.platform_uuid);
  //   setTrackeruuid(operation.tracker_uuid);
  //   const multiDroneData = operation.pilot_uuid.map((item, index, arr) => {
  //     let datas = [];
  //     datas = {
  //       platformid: operation.platform_uuid[index],
  //       pilotid: operation.pilot_uuid[index],
  //       trackeruuid: operation.tracker_uuid[index],
  //     };
  //     return datas;
  //   });
  //   setSelectedMultiDrone(multiDroneData);
  //   setSpeed(operation.ground_speed || 5);
  //   if ("lateral_buffer" in operation) {
  //     setVerticalBuffer(operation.vertical_buffer);
  //     setLateralBuffer(operation.lateral_buffer);
  //     setCustomBuffer(true);
  //   } else {
  //     setCustomBuffer(false);
  //   }
  //   setSelectedAreaPolygon(operation.area_coordinates);
  //   onUpdateSelectedAreaWaypoints(
  //     operation.area_coordinates ? operation.area_coordinates : []
  //   );
  //   setSelectWaypointMethod("area");
  //   setTabIndex(1);
  // }, [operationFormRequest, mapboxController]);

  const handleDepartureTimeChange = (item) => {
    setDepartureTime(new Date(item));
    setDepartureTimeError("");
  };
  useEffect(() => {
    if (emergencyLanding.length) {
      setContingencyLandingPoint([...emergencyLanding]);
    }
  }, [emergencyLanding]);

  if (loading) return <div />;

  const handleCustomBufferClick = (event) => {
    setCustomBuffer(event.target.checked);
  };

  const handleInboundOperationClick = (event) => {
    setOutbound(!event.target.checked);
  };

  const checkAssetAvailability = async () => {
    if (selectedCorridor === -1) {
      return;
    }
    const corridorDepartureTime = new Date(
      availableRoutes[selectedCorridor].time_start.value
    );
    const corridorEndTime = new Date(
      availableRoutes[selectedCorridor].time_end.value
    );
    setLoadingAssetList(true);
    try {
      const upcomingOperations = await api.getActiveOperations(
        corridorDepartureTime.getTime(),
        corridorEndTime.getTime(),
        true
      );
      const platformList = [];
      const trackerList = [];
      if (upcomingOperations?.status === 200) {
        if (upcomingOperations.data.length > 0) {
          upcomingOperations.data.forEach((singleOperation) => {
            if (
              operationFormRequest?.type !== "edit" &&
              singleOperation.operation_json.reference.id !==
                operationFormRequest?.id
            ) {
              trackerList.push(
                ...singleOperation.operation_json.request.tracker_uuid
              );
              platformList.push(
                ...singleOperation.operation_json.request.platform_uuid
              );
            }
          });
        }
      }
      // remove "No-Tracker" from check
      const trackerListEdited = trackerList.filter(
        (singleTracker) =>
          singleTracker !== "e1d4d10a-8615-48d6-b542-fa7dfd2238e5"
      );
      setUnavailableTrackers([...new Set(trackerListEdited)]);
      setUnavailablePlatforms([...new Set(platformList)]);
      setLoadingAssetList(false);
    } catch (e) {
      console.log("error : ", e);
      setLoadingAssetList(false);
    }
  };

  const handleSubsectionHover = (option) => {
    if (option) {
      setHoveredSubsection(option);
      mapboxController.highlightGeometry(option.geometry);
    } else {
      setHoveredSubsection(null);
      mapboxController.removeHighlight();
    }
  };

  const handleAnchorageSelect = (option) => {
    handleOnFocus(option.point);
    setAnchorage(option);
    setSelectedAnchorage(option.name);
  };

  const handleSubsectionSelect = (option) => {
    setSubsection(option);
    mapboxController.highlightArea(option.geometry);
    setSelectedSubsection(option.name);
  };

  const handleFafSelect = (option) => {
    setFaf(option);
    setSelectedFaf(option.name);
  };

  const handleFafTolSelect = (option) => {
    setFafTol(option);
    setSelectedFafTol(option.name);
    if (option.point && Array.isArray(option.point)) {
      setContingencyLandingPoint(option.point);
    }
  };

  const handleResetForm = () => {
    resetForm();
    mapboxController.removeHighlight();
    mapboxController.removeHighlightedArea2();
    mapboxController.removeHighlightedArea();
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <LoadingOverlay
        active={submitLoading}
        spinner
        text="Submitting operation..."
      >
        <div>
          <Panel>
            <div
              style={{
                marginTop: "0px",
                marginLeft: "15px",
                marginRight: "15px",
                marginBottom: "15px",
              }}
            >
              <Paper
                style={{
                  height: `${690}px`,
                  maxHeight: "calc(50% - 0px)",
                  width: "100%",
                  overflow: "auto",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div
                  style={{
                    marginTop: "0px",
                    marginLeft: "15px",
                    marginRight: "15px",
                  }}
                >
                  <Grid container spacing={3} style={{ marginTop: "15px" }}>
                    <Grid item xs={3}>
                      <TextField
                        id="anchorage"
                        name="Anchorage"
                        label="Anchorage"
                        fullWidth
                        select
                        value={anchorage.name || ""}
                      >
                        {retData
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((option) => (
                            <MenuItem
                              key={option.name}
                              value={option.name}
                              onClick={() => {
                                handleAnchorageSelect(option);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        id="subsection"
                        name="Subsection"
                        label="Subsection"
                        fullWidth
                        select
                        disabled={Object.keys(anchorage).length === 0}
                        value={subsection.name || ""}
                      >
                        {!(Object.keys(anchorage).length === 0) ? (
                          anchorage.subsections
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.name}
                                onMouseOver={() =>
                                  handleSubsectionHover(option)
                                }
                                onMouseOut={() => handleSubsectionHover(null)}
                                onClick={() => handleSubsectionSelect(option)}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                        ) : (
                          <div />
                        )}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id="fafName"
                        name="FAF Name"
                        label="FAF Name"
                        fullWidth
                        select
                        disabled={Object.keys(anchorage).length === 0}
                        value={faf.name || ""}
                      >
                        {!(Object.keys(anchorage).length === 0) ? (
                          anchorage.faf
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.name}
                                onClick={() => handleFafSelect(option)}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                        ) : (
                          <div />
                        )}
                      </TextField>
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        id="tolName"
                        name="TakeOffLandingPoint"
                        label="Take-off Landing Point"
                        fullWidth
                        select
                        disabled={Object.keys(faf).length === 0}
                        value={fafTol.name || ""}
                      >
                        {!(Object.keys(faf).length === 0) ? (
                          faf.tolNames
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.name}
                                onClick={() => handleFafTolSelect(option)}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                        ) : (
                          <div />
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="start-time"
                        label="Start Time (Local Time)"
                        type="datetime-local"
                        value={[
                          format(departureTime, "yyyy-MM-dd"),
                          "T",
                          format(departureTime, "HH:mm"),
                        ].join("")}
                        fullWidth
                        onChange={(item) =>
                          handleDepartureTimeChange(item.target.value)
                        }
                        error={!!departureTimeError}
                        helperText={departureTimeError}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        style={{ marginTop: "15px" }}
                        variant="outlined"
                        onClick={() => {
                          handleCheckAvailability();
                        }}
                      >
                        <LoadingOverlay
                          active={checkingCorridor}
                          spinner
                          // text="Checking corridors..."
                          styles={{
                            spinner: (base) => ({
                              ...base,
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "40px", // Change the size of the spinner here as well
                              height: "20px", // Change the size of the spinner here as well
                            }),
                          }}
                        >
                          Check Availability
                        </LoadingOverlay>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid>
                    {!(Object.keys(availableRoutes).length === 0) ? (
                      availableRoutes.map((corridorData, index) => {
                        return (
                          <DroneCorridorCard
                            index={index}
                            corridorData={corridorData}
                            key={corridorData.corridor}
                            selectedCorridor={selectedCorridor}
                            setSelectedCorridor={setSelectedCorridor}
                          />
                        );
                      })
                    ) : (
                      <div />
                    )}
                  </Grid>
                  <EmergencyLandingPointEntry
                    emergencyLandingPoint={contingencyLandingPoint}
                    onFocus={handleOnFocus}
                    onChange={(points) => {
                      setContingencyLandingPoint(points);
                      onEmergencyLandingUpdate(points);
                    }}
                    mapboxController={mapboxController}
                    handleCloseLastDrawer={handleOpenDronePortOperations}
                  />
                  <Grid container spacing={3} style={{ marginTop: "15px" }}>
                    <Grid item xs={2}>
                      <TextField
                        id="speed"
                        label="Speed (m/s)"
                        fullWidth
                        value="NA"
                        onChange={(item) => setSpeed(item.target.value)}
                        type="text"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="altitude"
                        label="Max Altitude (m)"
                        fullWidth
                        value={altitude || 60}
                        onChange={(item) => setAltitude(item.target.value)}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="safeAltitude"
                        label="RTH altitude (m)"
                        fullWidth
                        value={safeAltitude || 60}
                        onChange={(item) => setSafeAltitude(item.target.value)}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: "15px" }} />
                    <Grid item xs={2}>
                      <TextField
                        id="vertical-buffer"
                        label="Vertical Buffer (m)"
                        fullWidth
                        value={verticalBuffer || 25}
                        onChange={(item) =>
                          setVerticalBuffer(item.target.value)
                        }
                        type="number"
                        disabled={!customBuffer}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="lateral-buffer"
                        label="Lateral Buffer (m)"
                        fullWidth
                        value={lateralBuffer || 25}
                        onChange={(item) => setLateralBuffer(item.target.value)}
                        type="number"
                        disabled={!customBuffer}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="time-buffer"
                        label="Time Buffer (s)"
                        fullWidth
                        value={timeBuffer || 30}
                        onChange={(item) => setTimeBuffer(item.target.value)}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        id="elevation"
                        label="Elevation (m)"
                        fullWidth
                        value={elevation || 0}
                        onChange={(item) =>
                          setElevation(parseFloat(item.target.value))
                        }
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={customBuffer}
                            onChange={handleCustomBufferClick}
                            name="custom_buffer"
                          />
                        }
                        label={
                          <Typography
                            variant="caption"
                            style={{ fontSize: "10px" }}
                          >
                            Custom Buffer
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!outbound}
                            onChange={handleInboundOperationClick}
                            name="inbound_operation"
                          />
                        }
                        label={
                          <Typography
                            variant="caption"
                            style={{ fontSize: "10px" }}
                          >
                            Inbound Operation
                          </Typography>
                        }
                      />
                    </Grid>
                    <MultipleDrone
                      availablePlatforms={availablePlatforms}
                      availableTracker={availableTracker}
                      platformid={platformid}
                      setPlatformID={setPlatformID}
                      pilotid={pilotid}
                      availablePilots={availablePilots}
                      setPilotID={setPilotID}
                      selectedMultiDrone={selectedMultiDrone}
                      setSelectedMultiDrone={setSelectedMultiDrone}
                      selectWaypointMethod={selectWaypointMethod}
                      setTrackeruuid={setTrackeruuid}
                      getPlatforms={getPlatforms}
                      getPilots={getPilots}
                      getTrackers={getTrackers}
                      unavailablePlatforms={unavailablePlatforms}
                      unavailableTrackers={unavailableTrackers}
                      loadingAssetList={loadingAssetList}
                    />
                    <Grid item xs={8}>
                      <TextField
                        id="operationDescription"
                        label="Operation Intent (Optional)"
                        fullWidth
                        value={operationDescription}
                        onChange={(item) =>
                          setOperationDescription(item.target.value)
                        }
                        type="string"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="type"
                        label="Type"
                        fullWidth
                        select
                        value={operationType || ""}
                        onChange={(item) => setOperationType(item.target.value)}
                      >
                        {TypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label.toUpperCase()}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="operationDescription"
                        label="Description (Optional)"
                        fullWidth
                        value={operationDescription}
                        onChange={(item) =>
                          setOperationDescription(item.target.value)
                        }
                        type="string"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    onClick={() => handleResetForm()}
                    size="small"
                    style={{ marginTop: "15px", marginBottom: "5px" }}
                    variant="contained"
                    fullWidth
                    type="button"
                    color="secondary"
                    // disabled={selectedCorridor === -1}
                  >
                    Reset Form
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    style={{ marginTop: "5px", marginBottom: "15px" }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={selectedCorridor === -1}
                  >
                    Submit Operation
                  </Button>
                </div>
              </Paper>
            </div>
          </Panel>
        </div>
      </LoadingOverlay>
    </form>
  );
}

export default memo(CreatePortOperationForm);
