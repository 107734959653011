import { connect } from "react-redux";
import { WebsocketAlertComponent } from "./WebsocketAlert";

const mapStatetoProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(WebsocketAlertComponent);
