import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingOverlay from "react-loading-overlay";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddTracker from "../AddTracker";
import { setSnackbarMessage } from "../../store/actions";
import { useApi } from "../../api/useApi";

function InformationBox({
  info,
  onClick,
  index,
  onEditPlatformClick,
  onDeleteTrackerClick,
}) {
  const handleClick = () => {
    onClick(index);
  };
  return (
    <Accordion onClick={handleClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          display="inline"
          variant="body2"
          style={{ marginLeft: "3px" }}
        >
          {info.tracker_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0} style={{ marginBottom: "1px" }}>
          <Grid item xs={8} style={{ paddingLeft: "1px" }}>
            <DetailItem name="Tracker S/N" value={info.tracker_sn} />
            <DetailItem name="Tracker IMEI" value={info.tracker_imei} />
            <DetailItem name="SIM Card Expiry" value={info.sim_expiry} />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                style={{ width: "130px", marginTop: "5px" }}
                onClick={onEditPlatformClick}
              >
                Edit Details
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ width: "130px", marginTop: "15px" }}
                onClick={onDeleteTrackerClick}
              >
                Delete Details
              </Button>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function TrackerList(props) {
  const {
    trackerList,
    onClick,
    onEditPlatformClick,
    onDeleteTrackerClick,
    selected,
  } = props;
  return (
    <List>
      {trackerList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={onClick}
          onEditPlatformClick={onEditPlatformClick}
          onDeleteTrackerClick={onDeleteTrackerClick}
          selected={selected === index}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "1px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function TrackerComponent() {
  const [selected, setSelected] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [trackerList, setTrackerList] = React.useState([]);
  const [loadingPlatforms, setLoadingPlatforms] = React.useState(true);
  const [updatingPlatform, setUpdatingPlatform] = React.useState(false);
  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getTrackers();
  }, []);

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.platform_callsign < b.platform_callsign) {
      return -1;
    }
    if (a.platform_callsign > b.platform_callsign) {
      return 1;
    }
    return 0;
  }
  const getTrackers = async () => {
    try {
      const response = await api.getTracker();
      if (response.data) {
        response.data.sort(compare);
        setTrackerList(response.data);
        setSelected(0);
        setLoadingPlatforms(false);
      }
    } catch (err) {
      setLoadingPlatforms(false);
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const handleTrackerClick = (item) => {
    if (selected !== item) {
      setSelected(item);
    }
  };

  const handleEditTrackerClick = () => {
    setOpenDialog(true);
    setUpdatingPlatform(true);
  };

  const handleDeleteTrackerClick = () => {
    deleteTracker();
  };

  const handleAddTrackerClick = () => {
    setOpenDialog(true);
    setUpdatingPlatform(false);
  };

  const handleClose = (item) => {
    setOpenDialog(false);
    setUpdatingPlatform(false);
  };

  const handleTrackerUpdate = async (newTrackerData) => {
    const res = await api.getTracker();
    setTrackerList(res.data);
  };

  const deleteTracker = async () => {
    try {
      const response = await api.deleteTracker(
        trackerList[selected].tracker_uuid
      );

      if (response.data) {
        if (response.data.message === "Tracker deleted.") {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "Tracker successfully deleted!",
              severity: "success",
            })
          );
          getTrackers();
          handleClose();
        }
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <LoadingOverlay active={loadingPlatforms} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddTrackerClick}
        >
          {" "}
          Add Tracker{" "}
        </Button>
      </Grid>
      <TrackerList
        onClick={handleTrackerClick}
        selected={selected}
        trackerList={trackerList}
        onEditPlatformClick={handleEditTrackerClick}
        onDeleteTrackerClick={handleDeleteTrackerClick}
      />
      <AddTracker
        open={openDialog}
        handleClose={handleClose}
        trackerData={selected !== null ? trackerList[selected] : null}
        updating={updatingPlatform}
        handleTrackerUpdate={handleTrackerUpdate}
      />
      {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
    </LoadingOverlay>
  );
}
