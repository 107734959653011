import "../CreateOperationForm/styles.css";

import { useState, useEffect, memo, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useSelector, useDispatch } from "react-redux";
import { Typography, makeStyles, Checkbox } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import LoadingOverlay from "react-loading-overlay";

import CreateOperationForm from "../CreateOperationForm";
import { setSnackbarMessage } from "../../../store/actions";

LoadingOverlay.propTypes = undefined;

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography component="span" variant="body2">
          {props.children}
        </Typography>
      )}
    </div>
  );
}

function TakeoffLandingSelection(props) {
  const {
    setSelectedShip,
    setSelectedTakeOffLandingPoint,
    selectedShip,
    selectedTakeOffLandingPoint,
    isReturnFromShipOperation,
    setIsReturnFromShipOperation,
    openDronePortOperations,
    tabIndex,
  } = props;
  const shipData = useSelector((state) => state.shipData.data);
  const dispatch = useDispatch();
  useEffect(() => {
    // handle ship left ais range
    // add to trigger only when this tab is open
    if (!selectedShip) return;
    const isSelectedShipInAISData = shipData?.some((singleShip) => {
      return (
        singleShip.callSign === selectedShip.callSign &&
        singleShip.vesselName === selectedShip.vesselName
      );
    });
    if (openDronePortOperations && !isSelectedShipInAISData && tabIndex === 0) {
      const shipDetails = getShipLabel(selectedShip);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `${shipDetails} has left AIS range`,
          severity: "error",
        })
      );
    }
    if (!isSelectedShipInAISData) setSelectedShip(null);
  }, [shipData]);

  const takeOffLandingPoint = [
    {
      label: "MPA Port Helipad East",
      lat: 1.272078663964143,
      lon: 103.8649961903046,
    },
    {
      label: "MPA Port Helipad West",
      lat: 1.2718565832210516,
      lon: 103.86469803651755,
    },
  ];

  // Merge ship callsign and vessel name
  const getShipLabel = (singleShip) => {
    let shipLabel = "";
    if (singleShip.vesselName) shipLabel += `${singleShip.vesselName} `;
    // if (singleShip.callSign) shipLabel += `${singleShip.callSign}`;
    if (singleShip.mmsi) shipLabel += `MMSI:${singleShip.mmsi}`;
    return shipLabel;
  };

  const handleSelectShip = (event, newValue) => {
    setSelectedShip(newValue);
  };

  const handleSelectTakeOffLanding = (event, newValue) => {
    setSelectedTakeOffLandingPoint(newValue);
  };

  const handleDirectionClick = (event) => {
    setIsReturnFromShipOperation(event.target.checked);
  };

  const shipDataSelectionFilter = (shipDataRaw) => {
    let filteredShipData;
    try {
      filteredShipData = shipDataRaw
        ? shipDataRaw.filter((ship) => ship.vesselName || ship.callSign)
        : [];
    } catch (e) {
      filteredShipData = [];
    }
    return filteredShipData;
  };

  return (
    <Paper>
      <Grid container>
        <Grid item xs={5}>
          <h4 style={{ marginLeft: 10 }}>SHIP DELIVERY OPERATION DETAILS</h4>
        </Grid>
        <Grid item xs={4}>
          <div style={{ marginTop: 10 }}>
            <Checkbox
              onClick={handleDirectionClick}
              checked={isReturnFromShipOperation}
            />
            <Typography
              variant="caption"
              style={{ fontSize: "10px", whiteSpace: "nowrap" }}
            >
              Inbound Operation
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginLeft: 10, marginBottom: 10 }}>
          <Autocomplete
            disablePortal
            id="takeOffLandingSelection"
            options={takeOffLandingPoint || null}
            value={selectedTakeOffLandingPoint || null}
            // sx={{ width: 300 }}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={handleSelectTakeOffLanding}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Take Off / Landing Point"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} style={{ marginLeft: 10, marginBottom: 10 }}>
          <Autocomplete
            disablePortal
            id="shipDataSelection"
            value={selectedShip || null}
            options={shipDataSelectionFilter(shipData)}
            onChange={handleSelectShip}
            getOptionLabel={(singleShip) => getShipLabel(singleShip)}
            isOptionEqualToValue={(option, value) =>
              getShipLabel(option) === getShipLabel(value)
            }
            // renderOption={(singleShip) => {
            //   return { ...singleShip, ...{ key: singleShip.id } };
            // }}
            // sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Ship" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

function CreateOperationComponent(props) {
  const {
    /* route */
    selectedWaypoints,
    setSelectedWaypoints,
    /* poylygon/area */
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    /* circle */
    setSelectedCircleWaypoints,
    setSelectedCircleProperties,
    emergencyLanding,
    setEmergencyLanding,
    mapViewController,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    openFlightAuthorization,
    handleOpenFlightAuthorization,
    selectedShip,
    setSelectedShip,
    selectedTakeOffLandingPoint,
    setSelectedTakeOffLandingPoint,
    openDronePortOperations,
  } = props;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("circle");
  const [isReturnFromShipOperation, setIsReturnFromShipOperation] =
    useState(false);

  useEffect(() => {
    getPlatforms();
    getPilots();
    getTrackers();
  }, []);

  useEffect(() => {
    // reset waypoints when user click after selecting ship and landing
    const shipPosition = [
      selectedShip?.longitudeDegrees || 0,
      selectedShip?.latitudeDegrees || 0,
    ];
    const takeOffLandingPosition = [
      selectedTakeOffLandingPoint?.lon || 0,
      selectedTakeOffLandingPoint?.lat || 0,
    ];
    const newWaypoints = isReturnFromShipOperation
      ? [shipPosition, takeOffLandingPosition]
      : [takeOffLandingPosition, shipPosition];
    setSelectedWaypoints(newWaypoints);
  }, [isReturnFromShipOperation]);

  useEffect(() => {
    if (!selectedShip) return;
    // set waypoints [[long , lat], [long, lat]]
    const currentWaypoints = selectedWaypoints || [
      [0, 0],
      [0, 0],
    ];
    const newWaypoints = isReturnFromShipOperation
      ? [
          [selectedShip.longitudeDegrees, selectedShip.latitudeDegrees],
          ...currentWaypoints.slice(1),
        ]
      : [
          ...currentWaypoints.slice(0, -1),
          [selectedShip.longitudeDegrees, selectedShip.latitudeDegrees],
        ];
    setSelectedWaypoints(newWaypoints);
  }, [selectedShip]);

  useEffect(() => {
    if (!selectedTakeOffLandingPoint) return;
    // set waypoints [[long , lat], [long, lat]]
    const currentWaypoints = selectedWaypoints || [
      [0, 0],
      [0, 0],
    ];
    const newWaypoints = isReturnFromShipOperation
      ? [
          ...currentWaypoints.slice(0, -1),
          [selectedTakeOffLandingPoint.lon, selectedTakeOffLandingPoint.lat],
        ]
      : [
          [selectedTakeOffLandingPoint.lon, selectedTakeOffLandingPoint.lat],
          ...currentWaypoints.slice(1),
        ];
    setSelectedWaypoints(newWaypoints);
  }, [selectedTakeOffLandingPoint]);

  return (
    // <form noValidate autoComplete="off" onSubmit={handleSubmit}>

    <LoadingOverlay
      active={submitLoading}
      spinner
      text="Submitting operation..."
    >
      <div>
        <Panel value={tabIndex} index={0}>
          <TakeoffLandingSelection
            selectedShip={selectedShip}
            selectedTakeOffLandingPoint={selectedTakeOffLandingPoint}
            setSelectedShip={setSelectedShip}
            setSelectedTakeOffLandingPoint={setSelectedTakeOffLandingPoint}
            isReturnFromShipOperation={isReturnFromShipOperation}
            setIsReturnFromShipOperation={setIsReturnFromShipOperation}
            openDronePortOperations={openDronePortOperations}
            tabIndex={tabIndex}
          />
          <CreateOperationForm
            isAisOperation
            isRouteMethod
            setSelectedShip={setSelectedShip}
            setSelectedTakeOffLandingPoint={setSelectedTakeOffLandingPoint}
            setIsReturnFromShipOperation={setIsReturnFromShipOperation}
            setSubmitLoading={setSubmitLoading}
            onTabClicked={() => {}}
            setTabIndex={setTabIndex}
            setLockedTab={() => {}}
            setSelectWaypointMethod={setSelectWaypointMethod}
            selectWaypointMethod={selectWaypointMethod}
            // // onEmergencyLandingUpdate={onEmergencyLandingUpdate}
            setEmergencyLanding={setEmergencyLanding}
            emergencyLanding={emergencyLanding}
            // contingencyLandingPoint={contingencyLandingPoint}
            // setContingencyLandingPoint={setContingencyLandingPoint}
            mapViewController={mapViewController}
            selectedWaypoints={selectedWaypoints}
            setSelectedWaypoints={setSelectedWaypoints}
            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            /* other props */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            setSelectedCircleProperties={setSelectedCircleProperties}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            openFlightAuthorization={openFlightAuthorization}
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
          />
        </Panel>
      </div>
    </LoadingOverlay>
  );
}

export default memo(CreateOperationComponent);
