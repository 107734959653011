import { useState, useEffect, forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormGroup,
  MenuItem,
  Box,
} from "@material-ui/core";
import { format } from "date-fns";
import Divider from "@material-ui/core/Divider";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import _without from "lodash/without";
import CancelIcon from "@material-ui/icons/Cancel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ListSubheader from "@material-ui/core/ListSubheader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const MenuItemWithCheckbox = forwardRef(function MenuItemWithCheckbox(
  { children, selected, em = false, ...other },
  ref
) {
  return (
    <MenuItem {...other} selected={selected} ref={ref}>
      <Checkbox checked={selected} />
      {em && <em>{children}</em>}
      {!em && children}
    </MenuItem>
  );
});
export default function Rule(props) {
  const {
    rule,
    setRule,
    whitelistUser,
    setWhitelistUser,
    whitelistUserGroup,
    setWhitelistUserGroup,
    threshold,
    setThreshold,
    others,
    setOthers,
    nocameratehetered,
    setnNoCameraThetered,
    departureTimeAuthCon,
    setDepartureTimeAuthCon,
    departureTimeErrorAuthCon,
    setDepartureTimeErrorAuthCon,
    departureTimeProhibitCon,
    setDepartureTimeProhibitCon,
    departureTimeErrorProhibitCon,
    setDepartureTimeErrorProhibitCon,
    endTimeProhibitCon,
    setEndTimeProhibitCon,
    endTimeErrorProhibitCon,
    setEndTimeErrorProhibitCon,
    endTimeAuthCon,
    setEndTimeAuthCon,
    endTimeErrorAuthCon,
    setEndTimeErrorAuthCon,
    personNameWhitelistUsers,
    setPersonNameWhitelistUsers,
    personNameWhitelistGroups,
    setPersonNameWhitelistGroups,
    whitelistUserNested,
    setWhitelistUserNested,
    authorisationPersonNameWhitelistUsers,
    setAuthorisationPersonNameWhitelistUsers,
    authorisationPersonNameWhitelistGroups,
    setAuthorisationPersonNameWhitelistGroups,
    isRequiring,
    prohibitConRequiringComponent,
    authConRequiringComponent,
  } = props;
  const theme = useTheme();
  const menuItems = [];
  whitelistUserNested.forEach((operators) => {
    menuItems.push(
      <ListSubheader key={operators.id}>{operators.operatorName}</ListSubheader>
    );
    operators.operatorUser.forEach((user) => {
      menuItems.push(
        <MenuItemWithCheckbox key={Math.random()} value={user.userName}>
          {user.userName}
        </MenuItemWithCheckbox>
      );
    });
  });
  // handle invalid date input
  const formatDate = (time) => {
    let formatedDate;
    try {
      formatedDate = [
        format(time, "yyyy-MM-dd"),
        "T",
        format(time, "HH:mm"),
      ].join("");
    } catch (e) {
      const currentDate = new Date();
      formatedDate = [
        format(currentDate, "yyyy-MM-dd"),
        "T",
        format(currentDate, "HH:mm"),
      ].join("");
    }
    return formatedDate;
  };

  const handleChangeRule = (event) => {
    setRule({
      ...rule,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeNoCameraThetered = (event) => {
    setnNoCameraThetered({
      ...nocameratehetered,
      [event.target.name]: event.target.checked,
    });
  };
  const { prohibited, authorisation_required, conditional, information_only } =
    rule;
  const { no_camera, power_tethered } = nocameratehetered;
  const handleDepartureTimeAuthConChange = (item) => {
    setDepartureTimeAuthCon(new Date(item));
    setDepartureTimeErrorAuthCon("");
  };
  const handleEndTimeAuthConChange = (item) => {
    setEndTimeAuthCon(new Date(item));
    setEndTimeErrorAuthCon("");
  };

  const handleDepartureTimeProhibitConChange = (item) => {
    setDepartureTimeProhibitCon(new Date(item));
    setDepartureTimeErrorProhibitCon("");
  };
  const handleEndTimeProhibitConChange = (item) => {
    setEndTimeProhibitCon(new Date(item));
    setEndTimeErrorProhibitCon("");
  };

  const handleChangeWhitelistUser = (event) => {
    const {
      target: { value },
    } = event;
    setPersonNameWhitelistUsers(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeAuthorizationWhitelistGroups = (event) => {
    const {
      target: { value },
    } = event;
    setAuthorisationPersonNameWhitelistGroups(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleDeleteAuthorizationChipUserWhitelist = (e, value) => {
    e.preventDefault();
    setAuthorisationPersonNameWhitelistUsers((current) =>
      _without(current, value)
    );
  };
  const handleDeleteAuthorizationChipUserWhitelistGroup = (e, value) => {
    e.preventDefault();
    setAuthorisationPersonNameWhitelistGroups((current) =>
      _without(current, value)
    );
  };
  const handleChangeAuthorizationWhitelistUser = (event) => {
    const {
      target: { value },
    } = event;
    setAuthorisationPersonNameWhitelistUsers(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeWhitelistGroups = (event) => {
    const {
      target: { value },
    } = event;
    setPersonNameWhitelistGroups(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleDeleteChipUserWhitelist = (e, value) => {
    e.preventDefault();
    setPersonNameWhitelistUsers((current) => _without(current, value));
  };
  const handleDeleteChipUserWhitelistGroup = (e, value) => {
    e.preventDefault();
    setPersonNameWhitelistGroups((current) => _without(current, value));
  };

  useEffect(() => {
    if (information_only) {
      setRule({
        prohibited: false,
        authorisation_required: false,
        conditional: false,
        information_only: true,
      });
    }
  }, [
    information_only,
    prohibited,
    authorisation_required,
    conditional,
    setRule,
  ]);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(255, 0, 0, 0.0)",
        border: "1px solid white",
        marginTop: "20px",
        marginBottom: "5px",
        paddingTop: "1px",
        paddingLeft: "10px",
        paddingRight: "10px",
        boxShadow: "0 0 0 1px #f50057",
      }}
    >
      {" "}
      <Typography
        component="div"
        style={{
          backgroundColor: "white",
          width: "130px",
          marginLeft: "15px",
          color: "#f50057",
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
          }}
          sx={{ fontWeight: "bold", m: -2, textTransform: "uppercase" }}
        >
          Constraint Rules
        </Box>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={prohibited}
                    onChange={handleChangeRule}
                    name="prohibited"
                  />
                }
                label="Prohibited"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={authorisation_required}
                    onChange={handleChangeRule}
                    name="authorisation_required"
                  />
                }
                label="Authorisation Required"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={conditional}
                    onChange={handleChangeRule}
                    name="conditional"
                  />
                }
                label="Conditional"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={information_only}
                    onChange={handleChangeRule}
                    name="information_only"
                  />
                }
                label="Information Only"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      {/* white list */}
      {rule?.prohibited && (
        <div
          style={{
            width: "100%",
            backgroundColor: "rgba(255, 0, 0, 0.0)",
            border: "1px solid white",
            marginTop: "20px",
            marginBottom: "5px",
            paddingTop: "1px",
            paddingLeft: "10px",
            paddingRight: "10px",
            boxShadow: "0 0 0 1px #f50057",
          }}
        >
          {" "}
          <Typography
            component="div"
            style={{
              backgroundColor: "white",
              width: "130px",
              marginLeft: "15px",
              color: "#f50057",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
              }}
              sx={{ fontWeight: "bold", m: -2, textTransform: "uppercase" }}
            >
              Prohibited
            </Box>
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "5px", marginBottom: "5px" }}
            justifyContent="flex-start"
          >
            <Grid item xs={12}>
              <Typography
                component="div"
                style={{
                  width: "100%",
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ m: -1, typography: "subtitle2" }}>
                  Whitelisted Users and Groups
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="user-multiple-chip-label">Users</InputLabel>
              {/* <Select
              labelId="user-multiple-chip-label"
              id="user-multiple-chip"
              multiple
              value={personNameWhitelistUsers}
              onChange={handleChangeWhitelistUser}
              IconComponent={KeyboardArrowDownIcon}
              input={
                <OutlinedInput id="user-select-multiple-chip" label="Users" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      clickable
                      key={value}
                      label={value}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      onDelete={(e) => handleDeleteChipUserWhitelist(e, value)}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {whitelistUser.map((user) => (
                <MenuItem
                  key={user.pilot_uuid}
                  value={user.pilot_name}
                  style={getStyles(
                    user.pilot_name,
                    personNameWhitelistUsers,
                    theme
                  )}
                >
                  {user.pilot_name}
                </MenuItem>
              ))}
            </Select> */}
              <Select
                value={personNameWhitelistUsers || []}
                defaultValue={[]}
                id="grouped-select"
                onChange={handleChangeWhitelistUser}
                multiple
                IconComponent={KeyboardArrowDownIcon}
                input={
                  <OutlinedInput id="user-select-multiple-chip" label="Users" />
                }
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        clickable
                        key={value}
                        label={value}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) =>
                          handleDeleteChipUserWhitelist(e, value)
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {menuItems}
              </Select>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <InputLabel id="groups-multiple-chip-label">Groups</InputLabel>
              <Select
                labelId="groups-multiple-chip-label"
                id="groups-multiple-chip"
                multiple
                value={personNameWhitelistGroups || []}
                onChange={handleChangeWhitelistGroups}
                IconComponent={KeyboardArrowDownIcon}
                input={
                  <OutlinedInput
                    id="groups-select-multiple-chip"
                    label="Groups"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          value.length > 20 ? `${value.slice(0, 20)}...` : value
                        }
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) =>
                          handleDeleteChipUserWhitelistGroup(e, value)
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {whitelistUserGroup.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personNameWhitelistGroups, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="startTimeArea"
                label="Start Date & Time (Local Time)"
                type="datetime-local"
                value={formatDate(departureTimeProhibitCon)}
                fullWidth
                onChange={(item) =>
                  handleDepartureTimeProhibitConChange(item.target.value)
                }
                error={!!departureTimeErrorProhibitCon}
                helperText={departureTimeErrorProhibitCon}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="endTimeArea"
                label="End Date & Time (Local Time)"
                type="datetime-local"
                value={formatDate(endTimeProhibitCon)}
                fullWidth
                onChange={(item) =>
                  handleEndTimeProhibitConChange(item.target.value)
                }
                error={!!endTimeErrorProhibitCon}
                helperText={endTimeErrorProhibitCon}
              />
            </Grid>
            {isRequiring && prohibitConRequiringComponent}
          </Grid>
        </div>
      )}
      {/* Authorisation Condition */}
      {rule?.authorisation_required && (
        <div
          style={{
            width: "100%",
            backgroundColor: "rgba(255, 0, 0, 0.0)",
            border: "1px solid white",
            marginTop: "20px",
            marginBottom: "5px",
            paddingTop: "1px",
            paddingLeft: "10px",
            paddingRight: "10px",
            boxShadow: "0 0 0 1px #f50057",
          }}
        >
          {" "}
          <Typography
            component="div"
            style={{
              backgroundColor: "white",
              width: "130px",
              marginLeft: "15px",
              color: "#f50057",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
              }}
              sx={{ fontWeight: "bold", m: -2, textTransform: "uppercase" }}
            >
              Authorisation
            </Box>
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "5px", marginBottom: "5px" }}
            justifyContent="flex-start"
          >
            <Grid item xs={12}>
              <Typography
                component="div"
                style={{
                  width: "100%",
                  marginLeft: "15px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ m: -1, typography: "subtitle2" }}>
                  Whitelisted Users and Groups
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="user-multiple-chip-label">Users</InputLabel>
              <Select
                value={authorisationPersonNameWhitelistUsers || []}
                defaultValue={[]}
                id="grouped-select"
                onChange={handleChangeAuthorizationWhitelistUser}
                multiple
                IconComponent={KeyboardArrowDownIcon}
                input={
                  <OutlinedInput id="user-select-multiple-chip" label="Users" />
                }
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        clickable
                        key={value}
                        label={value}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) =>
                          handleDeleteAuthorizationChipUserWhitelist(e, value)
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {menuItems}
              </Select>
            </Grid>
            <Grid item xs={5}>
              {" "}
              <InputLabel id="groups-multiple-chip-label">Groups</InputLabel>
              <Select
                labelId="groups-multiple-chip-label"
                id="groups-multiple-chip"
                multiple
                value={authorisationPersonNameWhitelistGroups || []}
                onChange={handleChangeAuthorizationWhitelistGroups}
                IconComponent={KeyboardArrowDownIcon}
                input={
                  <OutlinedInput
                    id="groups-select-multiple-chip"
                    label="Groups"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          value.length > 20 ? `${value.slice(0, 20)}...` : value
                        }
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) =>
                          handleDeleteAuthorizationChipUserWhitelistGroup(
                            e,
                            value
                          )
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {whitelistUserGroup.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personNameWhitelistGroups, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="startTimeArea"
                label="Start Date & Time (Local Time)"
                type="datetime-local"
                value={formatDate(departureTimeAuthCon)}
                fullWidth
                onChange={(item) =>
                  handleDepartureTimeAuthConChange(item.target.value)
                }
                error={!!departureTimeErrorAuthCon}
                helperText={departureTimeErrorAuthCon}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="endTimeArea"
                label="End Date & Time (Local Time)"
                type="datetime-local"
                value={formatDate(endTimeAuthCon)}
                fullWidth
                onChange={(item) =>
                  handleEndTimeAuthConChange(item.target.value)
                }
                error={!!endTimeErrorAuthCon}
                helperText={endTimeErrorAuthCon}
              />
            </Grid>
            {isRequiring && authConRequiringComponent}
          </Grid>
        </div>
      )}
      {/* Conditions power thetered */}
      {rule?.conditional && (
        <div
          style={{
            width: "100%",
            backgroundColor: "rgba(255, 0, 0, 0.0)",
            border: "1px solid white",
            marginTop: "20px",
            marginBottom: "5px",
            paddingTop: "1px",
            paddingLeft: "10px",
            paddingRight: "10px",
            boxShadow: "0 0 0 1px #f50057",
          }}
        >
          {" "}
          <Typography
            component="div"
            style={{
              backgroundColor: "white",
              width: "130px",
              marginLeft: "15px",
              color: "#f50057",
            }}
          >
            <Box
              style={{
                backgroundColor: "white",
              }}
              sx={{ fontWeight: "bold", m: -2, textTransform: "uppercase" }}
            >
              Conditional
            </Box>
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "5px", marginBottom: "5px" }}
            justifyContent="flex-start"
          >
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={no_camera}
                    onChange={handleChangeNoCameraThetered}
                    name="no_camera"
                  />
                }
                label="No Camera"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={power_tethered}
                    onChange={handleChangeNoCameraThetered}
                    name="power_tethered"
                  />
                }
                label="Power Tethered"
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <TextField
                id="noise-threshold"
                name="noise-threshold"
                label="Noise Threshold"
                type="number"
                value={threshold || 40}
                fullWidth
                onChange={(item) => setThreshold(item.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <TextField
                id="others"
                name="others"
                label="Others"
                type="text"
                value={others || ""}
                fullWidth
                onChange={(item) => setOthers(item.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      )}
      <Divider />
    </div>
  );
}
