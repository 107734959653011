import React, { useState } from "react";
import CryptoJS from "crypto-js";
import getLocation from "../../hooks/getLocalLocation";

const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
const authorityDomain = process.env.REACT_APP_AUTHORITY_LOGIN_DOMAIN;
const authorityClientId = process.env.REACT_APP_AUTHORITY_CLIENT_ID;

getLocation();
// async function handleGetLocation() {
//   await getLocation();
// }
function goToAuthPage(clientId, role) {
  const codeVerifier = CryptoJS.lib.WordArray.random(20).toString(
    CryptoJS.enc.Base64url
  );
  const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(
    CryptoJS.enc.Base64url
  );
  localStorage.setItem("codeVerifier", codeVerifier);
  window.location.href = `${cognitoDomain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&state=${role}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
}

function goToAuthPageAuthority(clientId, role) {
  const codeVerifier = CryptoJS.lib.WordArray.random(20).toString(
    CryptoJS.enc.Base64url
  );
  const codeChallenge = CryptoJS.SHA256(codeVerifier).toString(
    CryptoJS.enc.Base64url
  );
  localStorage.setItem("codeVerifier", codeVerifier);
  window.location.href = `${authorityDomain}/oauth2/authorize?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&state=${role}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
}

const handleOldUIChange = () => {
  localStorage.setItem("ui_version", "v1");
  window.location.reload();
};

function LoginPage() {
  return (
    <div className="flex-col h-screen bg-white">
      <div
        className="flex h-[90%] items-center justify-center"
        // style={{
        //   display: "flex",
        //   height: "100vh",
        //   alignItems: "center",
        //   justifyContent: "center",
        // }}
      >
        <button
          type="button"
          onClick={() =>
            goToAuthPage(process.env.REACT_APP_REMOTE_ID_CLIENT_ID, "remote-id")
          }
          style={{
            width: 277,
            height: 150,
            margin: 20,
            background: "url('/RemoteID.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          {}
        </button>
        <button
          type="button"
          onClick={() =>
            goToAuthPage(process.env.REACT_APP_PILOT_CLIENT_ID, "pilot")
          }
          style={{
            width: 277,
            height: 150,
            margin: 20,
            background: "url('/Pilot.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          {}
        </button>
        <button
          type="button"
          onClick={() =>
            goToAuthPage(process.env.REACT_APP_OPERATOR_CLIENT_ID, "operator")
          }
          style={{
            width: 277,
            height: 150,
            margin: 20,
            background: "url('/Operator.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          {}
        </button>
        <button
          type="button"
          onClick={() => {
            goToAuthPageAuthority(authorityClientId, "authority");
          }}
          style={{
            width: 277,
            height: 150,
            margin: 20,
            background: "url('/AuthorityLogin.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            cursor: "pointer",
            backgroundColor: "#211c35",
          }}
        >
          {}
        </button>
      </div>
      <div className="w-full flex">
        <span className="grow" />
        <span
          className="text-darkLink font-medium cursor-pointer"
          onClick={handleOldUIChange}
        >
          Use New UI V1.0 &#62;
        </span>
        <span className="grow" />
      </div>
    </div>
  );
}

export default LoginPage;
