import "./styles.css";

import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Paper from "@material-ui/core/Paper";

import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";

import {
  DeleteOutlined,
  LensOutlined,
  TimelineOutlined,
  PinDropOutlined,
  CropSquareOutlined,
  LabelOutlined,
} from "@material-ui/icons";
import { useApi } from "../../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../../store/actions";

import CircleEntries from "./circle";
import AreaEntries from "./area";
import WaypointsEntries from "./route";
import EmergencyLandingPointEntry from "./emergency";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { convertZuluToLocalTime } from "../../../api/timeConvert";

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography variant="h6" component="div">
          {props.children}
        </Typography>
      )}
    </div>
  );
}
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    marginBottom: "10px",
  },
}));
const TypeOptions = [
  {
    value: 0,
    label: "vlos",
  },
  {
    value: 1,
    label: "bvlos",
  },
];
function MultipleDrone({
  availablePlatforms,
  platformid,
  setPlatformID,
  pilotid,
  availablePilots,
  setPilotID,
  selectedMultiDrone,
  setSelectedMultiDrone,
  selectWaypointMethod,
  availableTracker,
  setTrackeruuid,
  getPlatforms,
  getPilots,
  getTrackers,
}) {
  const addFormFields = () => {
    const newfield = {
      platformid: availablePlatforms[0]?.platform_uuid,
      pilotid: availablePilots[0]?.pilot_uuid,
      trackeruuid: availableTracker[0]?.tracker_uuid,
    };
    setSelectedMultiDrone([...selectedMultiDrone, newfield]);
  };
  const removeFields = (index) => {
    const data = [...selectedMultiDrone];
    data.splice(index, 1);
    setSelectedMultiDrone(data);
  };
  const handleFormChange = (index, event) => {
    const data = [...selectedMultiDrone];
    data[index][event.target.name] = event.target.value;
    setSelectedMultiDrone(data);
  };
  useEffect(() => {
    const dataPilotId = selectedMultiDrone.map((item, index, arr) => {
      return item.pilotid;
    });
    setPilotID(dataPilotId);
    const dataPlatformId = selectedMultiDrone.map((item, index, arr) => {
      return item.platformid;
    });
    setPlatformID(dataPlatformId);
    const dataTrackerID = selectedMultiDrone.map((item, index, arr) => {
      return item.trackeruuid;
    });
    setTrackeruuid(dataTrackerID);
  }, [selectedMultiDrone]);
  return (
    <div>
      {selectedMultiDrone.map((element, index) => (
        // <Grid container spacing={3} style={{ marginTop: "15px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "6fr 6fr 6fr 1fr 1fr",
            gap: "0.25rem",
            marginLeft: "1rem",
          }}
        >
          <TextField
            id="platform"
            name="platformid"
            label="Platform Callsign"
            fullWidth
            select
            value={element.platformid || ""}
            onChange={(event) => {
              handleFormChange(index, event);
            }}
            onPointerDown={(event) => {
              getPlatforms();
            }}
          >
            {availablePlatforms.map((option) => (
              <MenuItem key={option.platform_uuid} value={option.platform_uuid}>
                {option.platform_callsign}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="pilot"
            name="pilotid"
            label="Pilot Name"
            fullWidth
            select
            value={element.pilotid || ""}
            onChange={(event) => {
              handleFormChange(index, event);
            }}
            onPointerDown={(event) => {
              getPilots();
            }}
          >
            {availablePilots.map((option) => (
              <MenuItem key={option.pilot_uuid} value={option.pilot_uuid}>
                {option.pilot_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="tracker"
            name="trackeruuid"
            label="Tracker Callsign"
            fullWidth
            select
            value={element.trackeruuid || ""}
            onChange={(event) => {
              handleFormChange(index, event);
            }}
            onPointerDown={(event) => {
              getTrackers();
            }}
          >
            {availableTracker.map((option) => (
              <MenuItem key={option.tracker_uuid} value={option.tracker_uuid}>
                {option.tracker_name}
              </MenuItem>
            ))}
          </TextField>
          <Button
            onClick={() => {
              removeFields(index);
            }}
            disabled={index <= 0}
          >
            <DeleteIcon />
          </Button>
          <Button
            onClick={() => {
              addFormFields();
            }}
          >
            <Add />
          </Button>
        </div>
      ))}
    </div>
  );
}
function AuthProposeOperationFormComponent(props) {
  const {
    selectedWaypoints: _selectedWaypoints,
    selectedAreaWaypoints: _selectedAreaWaypoints,
    selectedCircleWaypoints: _selectedCircleWaypoints,
    selectedCircleProperties: _selectedCircleProperties,
    onUpdateSelectedWaypoints,
    onUpdateSelectedAreaWaypoints,
    onUpdateSelectedCircleWaypoints,
    onUpdateSelectedCircleProperties,
    onEmergencyLandingUpdate,
    mapViewController,
    emergencyLanding,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    handleopenAuthProposeOperationDrawer,
  } = props;
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [selectedWaypoints, setSelectedPolyline] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [selectedAreaWaypoints, setSelectedAreaPolgon] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [selectedCircleWaypoints, setSelectedAreaCircle] = useState([
    [0.0, 0.0],
  ]);
  const [selectedCircleProperties, setSelectedCircleProperties] = useState({
    isCircle: true,
    center: [0.0, 0.0],
    radiusInKm: 0,
  });
  const [platformid, setPlatformID] = useState("");
  const [pilotid, setPilotID] = useState("");
  const [trackeruuid, setTrackeruuid] = useState("");
  const [operationClarification, setOperationClarification] = useState("");
  const [operationType, setOperationType] = useState("vlos");
  const [selectedMultiDrone, setSelectedMultiDrone] = useState([
    {
      platformid: "",
      pilotid: "",
      trackeruuid: "",
    },
  ]);
  const currentTime = new Date();
  const [departureTime, setDepartureTime] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeError, setDepartureTimeError] = useState("");
  const [endTime, setEndTime] = useState(
    new Date(departureTime.getTime() + 30 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeError, setEndTimeError] = useState("");
  const [contingencyLandingPoint, setContingencyLandingPoint] = useState([
    0.0, 0.0,
  ]);
  const [safeAltitude, setSafeAltitude] = useState(60);
  const [operationDescription, setOperationDescription] = useState("");
  const [speed, setSpeed] = useState(5);
  const [transitDuration, setTransitDuration] = useState(0);
  const [altitude, setAltitude] = useState(60);
  const [altitude_reference, setAltitude_reference] = useState("W84");
  const [verticalBuffer, setVerticalBuffer] = useState(25);
  const [lateralBuffer, setLateralBuffer] = useState(25);
  const [customBuffer, setCustomBuffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const api = useApi();
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("circle");
  const [isInEditMode, setIfEditMode] = useState(false);
  const operationFormRequest = useSelector(
    (state) => state.operations.operationFormRequest
  );
  const [openReject, setOpenReject] = useState(false);
  const [compareCurrentData, setCompareCurrentData] = useState({});
  const [reScheduled, setRescheduled] = useState(false);
  const [reRouted, setRerouted] = useState(false);
  const [reRoutedSchedEdit, setRoutedSchedEdit] = useState([]);

  const [conflictDepartureTime, setConflictDepartureTime] = useState("");
  const [compareData, setCompareData] = useState({});
  const classesFloating = useFloatingButton();
  const [disableCircleTab, setDisableCircleTab] = useState(false);
  const [disablePolyTab, setDisablePolyTab] = useState(false);
  const [disableRouteTab, setDisableRouteTab] = useState(false);
  const onTabClicked = (event, index) => {
    setTabIndex(index);
    if (index === 0) {
      setSelectWaypointMethod("circle");
    } else if (index === 1) {
      setSelectWaypointMethod("area");
    } else if (index === 2) {
      setSelectWaypointMethod("route");
    } else if (index === 3) {
      setSelectWaypointMethod("upload");
    }
  };
  const resetForm = () => {
    setSelectedAreaPolgon([
      [0.0, 0.0],
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    onUpdateSelectedAreaWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    setSelectedPolyline([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    onUpdateSelectedWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    setSelectedAreaCircle([[0.0, 0.0]]);
    setSelectedCircleProperties({
      isCircle: true,
      center: [0.0, 0.0],
      radiusInKm: 0,
    });
    onEmergencyLandingUpdate([0.0, 0.0]);
    setContingencyLandingPoint([0.0, 0.0]);
    setAltitude(60);
    setSafeAltitude(60);
    setOperationDescription("");
    setOperationClarification("");
    setOperationType("vlos");
    setDepartureTime(new Date(new Date().getTime() + 3 * 60000));
    setEndTime(new Date(new Date().getTime() + 30 * 60000));
    setPilotID("");
    setPlatformID("");
    setTrackeruuid("");
    setSelectedMultiDrone([
      {
        platformid: "",
        pilotid: "",
        trackeruuid: "",
      },
    ]);
  };
  const handleDiscardEdit = () => {
    resetForm();
    dispatch(setOperationFormRequest(null));
    setIfEditMode(false);
  };
  const handleOpenSchedReroutedDialog = (CompareData, CompareCurrentData) => {
    if (CompareData.details.rescheduled || CompareData.details.rerouted) {
      CompareData?.details?.rescheduled
        ? setRescheduled(true)
        : setRescheduled(false);
      CompareData?.details?.rerouted ? setRerouted(true) : setRerouted(false);
      setCompareData(CompareData);
      setCompareCurrentData(CompareCurrentData);
      setOpenReject(true);
    }
  };
  const handleClose = () => {
    setOpenReject(false);
  };

  const handleCircleOp = async (editMode) => {
    try {
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        area_coordinates: selectedCircleWaypoints,
        altitude,
        altitude_reference,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          landing_point: [contingencyLandingPoint],
          safe_altitude: safeAltitude,
        },
        properties: selectedCircleProperties,
        clarification: operationClarification,
        original_request: operationFormRequest.request,
      };
      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.setFlightRejected(operationFormRequest.id, data)
        : api.submitAreaOperation(data));
      if (response.status === 200 || response.status === 204) {
        resetForm();
        dispatch(setOperationFormRequest(null));
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Operation submitted successfully",
            error: "success",
          })
        );
      }
    } catch (err) {
      let { message } = err.response.data;
      if (message && message.startsWith("Airspace unavailable")) {
        message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };
  const handleAreaOp = async (editMode) => {
    try {
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        area_coordinates: selectedAreaWaypoints,
        altitude,
        altitude_reference,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          landing_point: [contingencyLandingPoint],
          safe_altitude: safeAltitude,
        },
        properties: {
          isCircle: false,
        },
        clarification: operationClarification,
        original_request: operationFormRequest.request,
      };
      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.setFlightRejected(operationFormRequest.id, data)
        : api.submitAreaOperation(data));
      if (response.status === 200 || response.status === 204) {
        resetForm();
        dispatch(setOperationFormRequest(null));
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Operation submitted successfully",
            error: "success",
          })
        );
      }
    } catch (err) {
      let { message } = err.response.data;
      if (message && message.startsWith("Airspace unavailable")) {
        message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };
  const handleRouteOp = async (editMode) => {
    try {
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        waypoints: selectedWaypoints,
        altitude,
        altitude_reference,
        transit_duration: transitDuration,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        ground_speed: speed,
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          landing_point: [contingencyLandingPoint],
          safe_altitude: safeAltitude,
        },
        clarification: operationClarification,
        original_request: operationFormRequest.request,
      };
      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.setFlightRejected(operationFormRequest.id, data)
        : api.submitOperation(data));
      if (response.status === 200 || response.status === 204) {
        resetForm();
        dispatch(setOperationFormRequest(null));
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Operation submitted successfully",
            error: "success",
          })
        );
      }
    } catch (err) {
      let { message } = err.response.data;
      if (message && message.startsWith("Airspace already reserved")) {
        message = `Airspace already reserved. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const editMode = isInEditMode && operationFormRequest.id;
    // Submit Operation
    if (selectWaypointMethod == "circle") {
      // "circle operation"
      await handleCircleOp(editMode);
    } else if (selectWaypointMethod == "area") {
      // "area operation"
      await handleAreaOp(editMode);
    } else {
      // "route operation"
      await handleRouteOp(editMode);
    }
    setSubmitLoading(false);
    handleOpenAuthProposeOperation(false);
  };

  const handleOnFocus = async (center) => {
    mapboxController.flyTo(center);
  };
  const handleOpenAuthProposeOperation = (open) => {
    handleopenAuthProposeOperationDrawer(open);
  };
  useEffect(() => {
    if (_selectedWaypoints.length) {
      setSelectedPolyline(_selectedWaypoints);
      onEmergencyLandingUpdate([
        _selectedWaypoints[0][0],
        _selectedWaypoints[0][1],
      ]);

      if (!contingencyLandingPoint[0] || !contingencyLandingPoint[1]) {
        setContingencyLandingPoint([
          _selectedWaypoints[0][0],
          _selectedWaypoints[0][1],
        ]);
      }
    }
  }, [_selectedWaypoints]);

  useEffect(() => {
    if (_selectedAreaWaypoints.length) {
      setSelectedAreaPolgon(_selectedAreaWaypoints);
      const center = computePolygonCenter(_selectedAreaWaypoints);
      setContingencyLandingPoint(center);
      onEmergencyLandingUpdate(center);

      if (!center[0] || !center[1]) {
        setContingencyLandingPoint([
          _selectedAreaWaypoints[0][0],
          _selectedAreaWaypoints[0][1],
        ]);
      }
    }
  }, [_selectedAreaWaypoints]);

  useEffect(() => {
    if (_selectedCircleWaypoints.length) {
      setSelectedAreaCircle(_selectedCircleWaypoints);
      setSelectedCircleProperties(_selectedCircleProperties);
      const circleProperties = _selectedCircleProperties?.center;

      if (circleProperties?.length > 0) {
        setContingencyLandingPoint([circleProperties[0], circleProperties[1]]);
        onEmergencyLandingUpdate([circleProperties[0], circleProperties[1]]);
      }
    }
  }, [_selectedCircleWaypoints, _selectedCircleProperties]);

  useEffect(() => {
    if (emergencyLanding.length) {
      setContingencyLandingPoint([...emergencyLanding]);
    }
  }, [emergencyLanding]);

  useEffect(() => {
    setIfEditMode(false);
    if (!operationFormRequest) {
      return;
    }
    resetForm();
    if (operationFormRequest.type === "edit") {
      setIfEditMode(true);
    }
    const operation = operationFormRequest.request;
    setTransitDuration(operation.transit_duration);
    setAltitude(operation.altitude);
    setSafeAltitude(operation.contingency_plans.safe_altitude);
    setContingencyLandingPoint(operation.contingency_plans.landing_point[0]);
    onEmergencyLandingUpdate(operation.contingency_plans.landing_point[0]);
    mapboxController.flyTo(operation.contingency_plans.landing_point[0]);
    setOperationDescription(operation.description);
    setOperationType(operation.operation_type);
    if ("lateral_buffer" in operation) {
      setVerticalBuffer(operation.vertical_buffer);
      setLateralBuffer(operation.lateral_buffer);
      setCustomBuffer(true);
    } else {
      setVerticalBuffer(25);
      setLateralBuffer(25);
      setCustomBuffer(false);
    }
    if (operationFormRequest.type === "edit") {
      setDepartureTime(new Date(operation.time_start));
      setEndTime(new Date(operation.time_end));
    }
    setPilotID(operation.pilot_uuid);
    setPlatformID(operation.platform_uuid);
    setTrackeruuid(operation.tracker_uuid);
    const multiDroneData = operation.pilot_uuid.map((item, index, arr) => {
      let datas = [];
      datas = {
        platformid: operation.platform_uuid[index],
        pilotid: operation.pilot_uuid[index],
        trackeruuid: operation.tracker_uuid[index],
      };
      return datas;
    });
    setSelectedMultiDrone(multiDroneData);
    setSpeed(operation.ground_speed || 5);
    if (operation.area_coordinates) {
      if (operation.properties.isCircle) {
        onUpdateSelectedCircleWaypoints(operation.area_coordinates);
        onUpdateSelectedCircleProperties(operation.properties);
        setSelectedCircleProperties(operation.properties);
        setSelectedAreaCircle(operation.area_coordinates);
        setSelectWaypointMethod("circle");
        setTabIndex(0);
        setDisablePolyTab(true);
        setDisableRouteTab(true);
        return;
      }
      setSelectedCircleProperties({
        isCircle: false,
      });
      setSelectedAreaPolgon(operation.area_coordinates);
      onUpdateSelectedAreaWaypoints(operation.area_coordinates);
      setSelectWaypointMethod("area");
      setTabIndex(1);
      setDisableCircleTab(true);
      setDisableRouteTab(true);
    } else {
      setSelectedPolyline(operation.waypoints);
      onUpdateSelectedWaypoints(operation.waypoints);
      setSelectWaypointMethod("route");
      setTabIndex(2);
      setDisableCircleTab(true);
      setDisablePolyTab(true);
    }
  }, [operationFormRequest, mapboxController]);

  const handleDepartureTimeChange = (item) => {
    setDepartureTime(new Date(item));
    setDepartureTimeError("");
  };

  const handleDefaultEndTime = (dt) => {
    dt.setMinutes(dt.getMinutes() + 30);
    return [format(dt, "yyyy-MM-dd"), "T", format(dt, "HH:mm")].join("");
  };

  const handleEndTimeChange = (item) => {
    setEndTime(new Date(item));
    setEndTimeError("");
  };
  if (loading) return <div />;

  const handleCustomBufferClick = (event) => {
    setCustomBuffer(event.target.checked);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <LoadingOverlay
        active={submitLoading}
        spinner
        text="Submitting operation..."
      >
        <div>
          <Tabs variant="scrollable" value={tabIndex} onChange={onTabClicked}>
            <Tab label="Circle" disabled={disableCircleTab} />
            <Tab label="Polygon" disabled={disablePolyTab} />
            <Tab label="Route" disabled={disableRouteTab} />
          </Tabs>
          {/* Circle */}
          <Panel value={tabIndex} index={0}>
            <Paper
              style={{
                height: `${350}px`,
                maxHeight: "calc(50% - 0px)",
                width: "100%",
                overflow: "auto",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  marginTop: "0px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                <CircleEntries
                  circleCenter={selectedCircleProperties.center}
                  radiusInKm={selectedCircleProperties.radiusInKm}
                  onChange={(updatedWaypoints, updatedProperties) => {
                    setSelectedAreaCircle(updatedWaypoints);
                    setSelectedCircleProperties(updatedProperties);
                    onUpdateSelectedCircleWaypoints(updatedWaypoints);
                    onUpdateSelectedCircleProperties(updatedProperties);
                  }}
                />
                <EmergencyLandingPointEntry
                  emergencyLandingPoint={contingencyLandingPoint}
                  onFocus={handleOnFocus}
                  onChange={(points) => {
                    setContingencyLandingPoint(points);
                    onEmergencyLandingUpdate(points);
                  }}
                />
                <Grid container spacing={3} style={{ marginTop: "15px" }}>
                  <Grid item xs={2}>
                    <TextField
                      id="speed"
                      label="Speed (m/s)"
                      fullWidth
                      value="NA"
                      onChange={(item) => setSpeed(item.target.value)}
                      type="text"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="altitude"
                      label="Max Altitude (m)"
                      fullWidth
                      value={altitude || 60}
                      onChange={(item) => setAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="safeAltitude"
                      label="RTH altitude (m)"
                      fullWidth
                      value={safeAltitude || 60}
                      onChange={(item) => setSafeAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-around",
                        alignItems: "end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_circle");
                          mapboxController.removeDrawFeatures("Polygon");
                        }}
                      >
                        <LensOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_point");
                          mapboxController.removeDrawFeatures("Point");
                        }}
                      >
                        <PinDropOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.trash();
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="vertical-buffer"
                      label="Vertical Buffer (m)"
                      fullWidth
                      value={verticalBuffer || 25}
                      onChange={(item) => setVerticalBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="lateral-buffer"
                      label="Lateral Buffer (m)"
                      fullWidth
                      value={lateralBuffer || 25}
                      onChange={(item) => setLateralBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customBuffer}
                          onChange={handleCustomBufferClick}
                          name="custom_buffer"
                        />
                      }
                      label={
                        <Typography
                          variant="caption"
                          style={{ fontSize: "10px" }}
                        >
                          Custom Buffer
                        </Typography>
                      }
                    />
                  </Grid>
                  {/* <MultipleDrone
                    availablePlatforms={availablePlatforms}
                    availableTracker={availableTracker}
                    platformid={platformid}
                    setPlatformID={setPlatformID}
                    pilotid={pilotid}
                    availablePilots={availablePilots}
                    setPilotID={setPilotID}
                    selectedMultiDrone={selectedMultiDrone}
                    setSelectedMultiDrone={setSelectedMultiDrone}
                    selectWaypointMethod={selectWaypointMethod}
                    setTrackeruuid={setTrackeruuid}
                    getPlatforms={getPlatforms}
                    getPilots={getPilots}
                    getTrackers={getTrackers}
                  /> */}
                  <Grid item xs={6}>
                    <TextField
                      id="start-time"
                      label="Start Time (Local Time)"
                      type="datetime-local"
                      value={[
                        format(departureTime, "yyyy-MM-dd"),
                        "T",
                        format(departureTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleDepartureTimeChange(item.target.value)
                      }
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="end-time"
                      label="End Time (Local Time)"
                      type="datetime-local"
                      // defaultValue={handleDefaultEndTime(new Date())}
                      value={[
                        format(endTime, "yyyy-MM-dd"),
                        "T",
                        format(endTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleEndTimeChange(item.target.value)
                      }
                      error={!!endTimeError}
                      helperText={endTimeError}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="operationDescription"
                      label="Operation Intent"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      type="string"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="type"
                      label="Type"
                      fullWidth
                      select
                      value={operationType || ""}
                      onChange={(item) => setOperationType(item.target.value)}
                      disabled
                    >
                      {TypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="operationDescription"
                      label="Description (Optional)"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      disabled
                      type="string"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: "0px" }}>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      id="operationClarification"
                      aria-label="Clarification"
                      placeholder="Clarification"
                      style={{ width: 300, height: 50 }}
                      value={operationClarification}
                      onChange={(item) =>
                        setOperationClarification(item.target.value)
                      }
                      type="string"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <div className={classesFloating.buttonFloating}>
              <div style={{ marginTop: "25px" }}>
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!isInEditMode}
                  onClick={() => {
                    handleOpenAuthProposeOperation(false);
                    handleDiscardEdit();
                  }}
                >
                  Discard Changes
                </Button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={selectedCircleWaypoints.length < 64}
                >
                  Submit Changes
                </Button>
              </div>
            </div>
          </Panel>

          {/* Area */}
          <Panel value={tabIndex} index={1}>
            <Paper
              style={{
                height: `${350}px`,
                maxHeight: "calc(50% - 0px)",
                width: "100%",
                overflow: "auto",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  marginTop: "0px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                <AreaEntries
                  onFocus={handleOnFocus}
                  selectedAreaWaypoints={selectedAreaWaypoints}
                  onChange={(updatedWaypoints) => {
                    setSelectedAreaPolgon(updatedWaypoints);
                    onUpdateSelectedAreaWaypoints(updatedWaypoints);
                  }}
                />
                <EmergencyLandingPointEntry
                  emergencyLandingPoint={contingencyLandingPoint}
                  onFocus={handleOnFocus}
                  onChange={(points) => {
                    setContingencyLandingPoint(points);
                    onEmergencyLandingUpdate(points);
                  }}
                />
                <Grid container spacing={3} style={{ marginTop: "15px" }}>
                  <Grid item xs={2}>
                    <TextField
                      id="speed"
                      label="Speed (m/s)"
                      fullWidth
                      value="NA"
                      onChange={(item) => setSpeed(item.target.value)}
                      type="text"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="altitude"
                      label="Max Altitude (m)"
                      fullWidth
                      value={altitude || 60}
                      onChange={(item) => setAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="safeAltitude"
                      label="RTH altitude (m)"
                      fullWidth
                      value={safeAltitude || 60}
                      onChange={(item) => setSafeAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-around",
                        alignItems: "end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_polygon");
                          mapboxController.removeDrawFeatures("Polygon");
                        }}
                      >
                        <LabelOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_rectangle");
                          mapboxController.removeDrawFeatures("Polygon");
                        }}
                      >
                        <CropSquareOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_point");
                          mapboxController.removeDrawFeatures("Point");
                        }}
                      >
                        <PinDropOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.trash();
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="vertical-buffer"
                      label="Vertical Buffer (m)"
                      fullWidth
                      value={verticalBuffer || 25}
                      onChange={(item) => setVerticalBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="lateral-buffer"
                      label="Lateral Buffer (m)"
                      fullWidth
                      value={lateralBuffer || 25}
                      onChange={(item) => setLateralBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customBuffer}
                          onChange={handleCustomBufferClick}
                          name="custom_buffer"
                        />
                      }
                      label={
                        <Typography
                          variant="caption"
                          style={{ fontSize: "10px" }}
                        >
                          Custom Buffer
                        </Typography>
                      }
                    />
                  </Grid>
                  {/* <MultipleDrone
                    availablePlatforms={availablePlatforms}
                    availableTracker={availableTracker}
                    platformid={platformid}
                    setPlatformID={setPlatformID}
                    pilotid={pilotid}
                    availablePilots={availablePilots}
                    setPilotID={setPilotID}
                    selectedMultiDrone={selectedMultiDrone}
                    setSelectedMultiDrone={setSelectedMultiDrone}
                    selectWaypointMethod={selectWaypointMethod}
                    setTrackeruuid={setTrackeruuid}
                  /> */}
                  <Grid item xs={6}>
                    <TextField
                      id="start-time"
                      label="Start Time (Local Time)"
                      type="datetime-local"
                      value={[
                        format(departureTime, "yyyy-MM-dd"),
                        "T",
                        format(departureTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleDepartureTimeChange(item.target.value)
                      }
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="end-time"
                      label="End Time (Local Time)"
                      type="datetime-local"
                      // defaultValue={handleDefaultEndTime(new Date())}
                      value={[
                        format(endTime, "yyyy-MM-dd"),
                        "T",
                        format(endTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleEndTimeChange(item.target.value)
                      }
                      error={!!endTimeError}
                      helperText={endTimeError}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="operationDescription"
                      label="Operation Intent"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      type="string"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="type"
                      label="Type"
                      fullWidth
                      select
                      value={operationType || ""}
                      onChange={(item) => setOperationType(item.target.value)}
                      disabled
                    >
                      {TypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="operationDescription"
                      label="Description (Optional)"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      type="string"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: "0px" }}>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      id="operationClarification"
                      aria-label="Clarification"
                      placeholder="Clarification"
                      style={{ width: 300, height: 50 }}
                      value={operationClarification}
                      onChange={(item) =>
                        setOperationClarification(item.target.value)
                      }
                      type="string"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <div className={classesFloating.buttonFloating}>
              <div style={{ marginTop: "25px" }}>
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!isInEditMode}
                  onClick={() => {
                    handleOpenAuthProposeOperation(false);
                    handleDiscardEdit();
                  }}
                >
                  Discard Changes
                </Button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    selectedAreaWaypoints.filter(([a, b]) => !a || !b).length
                  }
                >
                  Submit Changes
                </Button>
              </div>
            </div>
          </Panel>

          {/* Route */}
          <Panel value={tabIndex} index={2}>
            <Paper
              style={{
                height: `${350}px`,
                maxHeight: "calc(50% - 0px)",
                width: "100%",
                overflow: "auto",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  marginTop: "0px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                <WaypointsEntries
                  onFocus={handleOnFocus}
                  selectedWaypoints={selectedWaypoints}
                  onChange={(updatedAreaWaypoints) => {
                    setSelectedPolyline(updatedAreaWaypoints);
                    onUpdateSelectedWaypoints(updatedAreaWaypoints);
                  }}
                />
                <EmergencyLandingPointEntry
                  emergencyLandingPoint={contingencyLandingPoint}
                  onFocus={handleOnFocus}
                  onChange={(points) => {
                    setContingencyLandingPoint(points);
                    onEmergencyLandingUpdate(points);
                  }}
                />
                <Grid container spacing={3} style={{ marginTop: "15px" }}>
                  <Grid item xs={2}>
                    <TextField
                      id="speed"
                      label="Speed (m/s)"
                      fullWidth
                      value={speed || 5}
                      onChange={(item) => setSpeed(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="altitude"
                      label="Cruise Altitude (m)"
                      fullWidth
                      value={altitude || 60}
                      onChange={(item) => setAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="safeAltitude"
                      label="RTH altitude (m)"
                      fullWidth
                      value={safeAltitude || 60}
                      onChange={(item) => setSafeAltitude(item.target.value)}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "space-around",
                        alignItems: "end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_line_string");
                          mapboxController.removeDrawFeatures("LineString");
                        }}
                      >
                        <TimelineOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.changeDrawMode("draw_point");
                          mapboxController.removeDrawFeatures("Point");
                        }}
                      >
                        <PinDropOutlined />
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          mapboxController.trash();
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="vertical-buffer"
                      label="Vertical Buffer (m)"
                      fullWidth
                      value={verticalBuffer || 25}
                      onChange={(item) => setVerticalBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="lateral-buffer"
                      label="Lateral Buffer (m)"
                      fullWidth
                      value={lateralBuffer || 25}
                      onChange={(item) => setLateralBuffer(item.target.value)}
                      type="number"
                      disabled={!customBuffer}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customBuffer}
                          onChange={handleCustomBufferClick}
                          name="custom_buffer"
                        />
                      }
                      label={
                        <Typography
                          variant="caption"
                          style={{ fontSize: "10px" }}
                        >
                          Custom Buffer
                        </Typography>
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <TextField
                      id="platformRoute"
                      label="Platform Callsign"
                      fullWidth
                      select
                      value={platformid || ""}
                      onChange={(item) => {
                        setPlatformID([item.target.value]);
                      }}
                    >
                      {availablePlatforms.map((option) => (
                        <MenuItem
                          key={option.platform_uuid}
                          value={option.platform_uuid}
                        >
                          {option.platform_callsign}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="pilotRoute"
                      label="Pilot Name"
                      fullWidth
                      select
                      value={pilotid || ""}
                      onChange={(item) => {
                        setPilotID([item.target.value]);
                      }}
                    >
                      {availablePilots.map((option) => (
                        <MenuItem
                          key={option.pilot_uuid}
                          value={option.pilot_uuid}
                        >
                          {option.pilot_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="tracker"
                      name="trackeruuid"
                      label="Tracker Callsign"
                      fullWidth
                      select
                      value={trackeruuid || ""}
                      onChange={(item) => {
                        setTrackeruuid([item.target.value]);
                      }}
                    >
                      {availableTracker.map((option) => (
                        <MenuItem
                          key={option.tracker_uuid}
                          value={option.tracker_uuid}
                        >
                          {option.tracker_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      id="start-time"
                      label="Start Time (Local Time)"
                      type="datetime-local"
                      value={[
                        format(departureTime, "yyyy-MM-dd"),
                        "T",
                        format(departureTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleDepartureTimeChange(item.target.value)
                      }
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="end-time"
                      label="End Time (Local Time)"
                      type="text"
                      // defaultValue={handleDefaultEndTime(new Date())}
                      value="NA"
                      fullWidth
                      onChange={(item) =>
                        handleEndTimeChange(item.target.value)
                      }
                      error={!!endTimeError}
                      helperText={endTimeError}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="operationDescription"
                      label="Operation Intent"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      type="string"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="type"
                      label="Type"
                      fullWidth
                      select
                      value={operationType || ""}
                      onChange={(item) => setOperationType(item.target.value)}
                      disabled
                    >
                      {TypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="operationDescription"
                      label="Description (Optional)"
                      fullWidth
                      value={operationDescription}
                      onChange={(item) =>
                        setOperationDescription(item.target.value)
                      }
                      type="string"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{ marginTop: "0px" }}>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      id="operationClarification"
                      aria-label="Clarification"
                      placeholder="Clarification"
                      style={{ width: 300, height: 50 }}
                      value={operationClarification}
                      onChange={(item) =>
                        setOperationClarification(item.target.value)
                      }
                      type="string"
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <div className={classesFloating.buttonFloating}>
              <div style={{ marginTop: "25px" }}>
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!isInEditMode}
                  onClick={() => {
                    handleOpenAuthProposeOperation(false);
                    handleDiscardEdit();
                  }}
                >
                  Discard Changes
                </Button>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    selectedWaypoints.filter(([a, b]) => !a || !b).length
                  }
                >
                  Submit Changes
                </Button>
              </div>
            </div>
          </Panel>
        </div>
      </LoadingOverlay>
    </form>
  );
}

export default AuthProposeOperationFormComponent;
