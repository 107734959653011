const getPrivilegeText = (privilege: string) => {
  if (privilege === "operations") return "Access to Operations Management";
  if (privilege === "airspace.assets")
    return "Allow Airspace Manager to Manage assets";
  if (privilege === "airspace.broadcast")
    return "Access to Notfications Broadcasted to Managed Organisations";
  if (privilege === "airspace.operations")
    return "Allow Airspace Manager to Accept and Reject Proposed Operations";
  if (privilege === "airspace.organisations")
    return "Access to Organisation Management";
  if (privilege === "assets.maintenance")
    return "Access to Platform Asset Maintenance";
  if (privilege === "assets.permit") return "Access to Permits Management";
  if (privilege === "assets.pilot") return "Access to Pilots Asset Management";
  if (privilege === "assets.platform")
    return "Access to Platform Asset Management";
  if (privilege === "assets.platformtype")
    return "Access to PlatformType Asset Management";
  if (privilege === "assets.tracker")
    return "Access to Tracker Asset Management";
  if (privilege === "constraints") return "Access to Constraints Management";
  if (privilege === "general.logs") return "Access to Logs Management";
  if (privilege === "general.organisation")
    return "Access to Organisation User Management";
  if (privilege === "general.privilege")
    return "Access to Organisation User Privilege Management";
  if (privilege === "general.subscription")
    return "Access to Organisation Subscription Management";
  if (privilege === "general.user")
    return "Access to Organisation User Details Management";
  if (privilege === "operations.incident")
    return "Access to Incident Report Management";
  if (privilege === "operations.stream")
    return "Access to Livestreaming Management";
  if (privilege === "sdsp") return "Access to Supplementary Data";
  if (privilege === "telemetry") return "Access to Telemetry History";
  return `${privilege} Configurations`;
};
export { getPrivilegeText };
