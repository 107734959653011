import { useEffect, useRef } from "react";

import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import PinDropIcon from "@mui/icons-material/PinDrop";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import circle from "@turf/circle";
// import mapboxgl from "mapbox-gl";
// import MapboxDraw from "@mapbox/mapbox-gl-draw";

export default function CircleEntries(props) {
  const {
    circleCenter,
    radiusInKm,
    onChange,
    mapboxController,
    onFocus,
    updateRadius,
    updateLongitude,
    updateLatitude,
    handleOpenFlightAuthorization,
  } = props;
  const circleDrawer = useRef(false);

  // const draw = new MapboxDraw({
  //   displayControlsDefault: false,
  // });

  const registerController = () => {
    console.log(mapboxController);
  };

  const toggleCircleDrawer = () => {
    if (circleDrawer.current === false) {
      mapboxController.changeDrawMode("draw_circle");
      mapboxController.removeDrawFeatures("Polygon");
      circleDrawer.current = true;
    } else {
      mapboxController.trash();
      circleDrawer.current = false;
    }
  };

  // draw.on("draw.create", (event) => {
  //   const { features } = event;
  //   // Perform actions based on the drawn features
  //   console.log("User has drawn features:", features);
  //   // Perform your custom logic here
  // });

  const updateDrawnCircle = (center, radius) => {
    // console.log(center, radius);
    const feature = circle(center, radius);
    // console.log(feature);
    onChange(feature.geometry.coordinates[0], {
      isCircle: true,
      radiusInKm: radius,
      center,
    });
  };

  const handleRadiusChange = (newRadius) => {
    const center = circleCenter || [0.0, 0.0];
    if (newRadius !== "" && newRadius !== null) {
      updateRadius(newRadius);
      updateDrawnCircle(center, newRadius);
    } else {
      updateRadius("");
    }
  };

  const handleLongitudeChange = (newLongitude) => {
    const center = circleCenter || [0.0, 0.0];
    if (newLongitude !== "" && newLongitude !== null) {
      updateLongitude(newLongitude);
      updateDrawnCircle([newLongitude, center[1]], radiusInKm);
    } else {
      updateLongitude("");
    }
  };

  const handleLatitudeChange = (newLatitude) => {
    const center = circleCenter || [0.0, 0.0];
    if (newLatitude !== "" && newLatitude !== null) {
      updateLatitude(newLatitude);
      updateDrawnCircle([center[0], newLatitude], radiusInKm);
    } else {
      updateLatitude("");
    }
  };

  const checkNegativeValue = (value, field) => {
    if (value < 0) {
      switch (field) {
        case "Radius":
      }
    }
  };

  return (
    <Grid container columns={12} spacing={0.5} sx={{ marginTop: "15px" }}>
      <Grid item xs={4}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 0px 0px" }}
        >
          LAT (CENTER)
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          LONG (CENTER)
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography textAlign="center" fontWeight="bold" fontSize="14px">
          RADIUS (KM)
        </Typography>
      </Grid>
      <Grid item xs={2} textAlign="center">
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          Actions
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="latitude-center"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          // color="black"
          inputProps={{ sx: { fontSize: 14 } }} // font size of input text
          // value={circleCenter ? circleCenter[1] : 0.0}
          value={circleCenter ? circleCenter[1] : ""}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleLatitudeChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleLatitudeChange(parsedValue);
              }
            }
            // const center = circleCenter || [0.0, 0.0];
            // updateDrawnCircle(
            //   [center[0], Number.parseFloat(ev.target.value)],
            //   radiusInKm
            // );
          }}
          // onBlur={checkVerticalBuffer}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          id="longitude-center"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          inputProps={{ sx: { fontSize: 14 } }} // font size of input text
          // value={circleCenter ? circleCenter[0] : 0.0}
          value={circleCenter ? circleCenter[0] : ""}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleLongitudeChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleLongitudeChange(parsedValue);
              }
            }
            // const center = circleCenter || [0.0, 0.0];
            // updateDrawnCircle(
            //   [Number.parseFloat(ev.target.value), center[1]],
            //   radiusInKm
            // );
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          id="radius"
          type="number"
          fullWidth
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-input": { padding: "8px" },
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
          }}
          inputProps={{
            sx: { fontSize: 14 },
          }} // font size of input text
          // value={radiusInKm || 0.0}
          value={radiusInKm !== undefined ? radiusInKm : ""}
          onChange={(ev) => {
            const inputValue = ev.target.value;
            if (inputValue.trim() === "") {
              handleRadiusChange("");
            } else {
              const parsedValue = Number.parseFloat(inputValue);
              if (!isNaN(parsedValue)) {
                handleRadiusChange(parsedValue);
              }
            }
            // const center = circleCenter || [0.0, 0.0];
            // updateDrawnCircle(center, Number.parseFloat(ev.target.value));
          }}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          onBlur={checkNegativeValue(radiusInKm, "Radius")}
        />
      </Grid>

      <Grid item xs={2} sx={{ textAlign: "center" }}>
        <ButtonGroup sx={{ "& .MuiButton-root": { borderColor: "#b8b8b8" } }}>
          <Button
            onClick={() => {
              onFocus(circleCenter);
            }}
          >
            <CenterFocusIcon style={{ color: "black" }} />
          </Button>
        </ButtonGroup>

        {/* <Button
            variant="outlined"
            onClick={() => {
              mapboxController.changeDrawMode("draw_circle");
              mapboxController.removeDrawFeatures("Polygon");
              handleOpenFlightAuthorization();
              // registerController();
              // toggleCircleDrawer();
            }}
            sx={{
              minWidth: "34px",
              border: "1px solid black",
            }}
          >
            <PanoramaFishEyeIcon sx={{ color: "black" }} />
          </Button>
          <Button
            variant="outlined"
            sx={{ minWidth: "34px", border: "1px solid black" }}
            onClick={() => {
              onFocus(circleCenter);
              handleOpenFlightAuthorization();
            }}

            // onClick={() => {
            //   mapboxController.removeDrawFeaturesAll();
            // }}
          >
            <CenterFocusIcon style={{ color: "black" }} />
          </Button> */}
      </Grid>
    </Grid>
    // <div
    //   style={{
    //     display: "grid",
    //     gridTemplateColumns: "1fr 1fr 1fr 1fr",
    //     gap: "0.25rem",
    //   }}
    // >
    //   <h4 style={{ textAlign: "center" }}>LAT (CENTER)</h4>
    //   <h4 style={{ textAlign: "center" }}>LON (CENTER)</h4>
    //   <h4 style={{ textAlign: "center" }}>RADIUS (KM)</h4>
    //   <h4 style={{ textAlign: "center" }}>Actions</h4>

    //   <input
    //     type="number"
    //     width={85}
    //     value={circleCenter ? circleCenter[1] : 0.0}
    //     name="centerLatititude"
    //     onChange={(ev) => {
    //       const center = circleCenter || [0.0, 0.0];
    //       updateDrawnCircle(
    //         [center[0], Number.parseFloat(ev.target.value)],
    //         radiusInKm
    //       );
    //     }}
    //     style={{ boxSizing: "content-box", height: "30px" }}
    //   />
    //   <input
    //     type="number"
    //     width={85}
    //     height={30}
    //     value={circleCenter ? circleCenter[0] : 0.0}
    //     name="centerLongitude"
    //     onChange={(ev) => {
    //       const center = circleCenter || [0.0, 0.0];
    //       updateDrawnCircle(
    //         [Number.parseFloat(ev.target.value), center[1]],
    //         radiusInKm
    //       );
    //     }}
    //     style={{ boxSizing: "content-box", height: "30px" }}
    //   />
    //   <input
    //     type="number"
    //     width={35}
    //     height={30}
    //     value={radiusInKm || 0.0}
    //     name="radius"
    //     onChange={(ev) => {
    //       const center = circleCenter || [0.0, 0.0];
    //       updateDrawnCircle(center, Number.parseFloat(ev.target.value));
    //     }}
    //     style={{ boxSizing: "content-box", height: "30px" }}
    //   />
    //   <Button
    //     variant="outlined"
    //     onClick={() => {
    //       mapboxController.changeDrawMode("draw_circle");
    //       mapboxController.removeDrawFeatures("Polygon");
    //     }}
    //   >
    //     <PanoramaFishEyeIcon />
    //   </Button>
    // </div>
  );
}
