import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  downloadMultipleJSON,
  downloadSingleJSON,
  downloadCSV,
} from "../../services/json";
import { useApi } from "../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../store/actions";
import ReplayDialog from "../FlightLogs/RelayDialog";

export default function SchedulesHeader({
  selectedOperations,
  setDuplicateTriggered,
  isRemoteId,
  toggleLoadOlderLogs,
  loadOlderLogs,
}) {
  const api = useApi();
  const [selectedFlights, setSelectedFlights] = React.useState([]);
  const [, setLoading] = React.useState(false);

  const handleExportClick = async (e) => {
    e.preventDefault();
    const operations = (
      await Promise.all(
        selectedOperations.map((op) => {
          return api.getOperationData({
            operationID: op.reference.id,
          });
        })
      )
    ).map((response) => response.data);
    if (operations.length === 1) {
      await downloadSingleJSON(
        operations[0],
        operations[0].operation_json.reference.description
      );
      return;
    }
    await downloadMultipleJSON(
      operations.map((o) => {
        return {
          name: o.operation_json.reference.description,
          data: o,
        };
      }),
      "flight-logs"
    );
  };
  const dispatch = useDispatch();
  const handleDuplicateClick = async (e) => {
    const response = await api.getOperationData({
      operationID: selectedOperations[0].reference.id,
    });
    const anchorageValues = ["subsection", "corridor", "outbound"];
    if (
      anchorageValues.some(
        (singleKey) => singleKey in response.data.operation_json.request
      )
    ) {
      dispatch(
        setSnackbarMessage({
          message: "Drone Port Operation functions still in development",
          error: "error",
        })
      );
    } else {
      dispatch(
        setOperationFormRequest({
          id: selectedOperations[0].reference.id,
          type: "duplicate",
          request: response.data.request_json,
        })
      );
      setDuplicateTriggered(true);
    }
  };

  const handleExportCSVClick = () => {
    downloadCSV(selectedOperations, "flight-logs");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleReplayLog = async () => {
    try {
      const operationIDs = [];
      const trackerIDs = [];

      await Promise.all(
        selectedOperations.map(async (selectedOperation) => {
          const response = await api.getOperationData({
            operationID: selectedOperation.reference.id,
          });
          const operationID = response.data.operation_json.reference.id;
          const trackerID =
            response.data.operation_json.details.platform_tracker_pairs[0]
              .tracker_uuid;
          operationIDs.push(operationID);
          trackerIDs.push(trackerID[0]);
          setSelectedFlights([operationIDs, trackerIDs]);
        })
      );
      setOpenDialog(true);
    } catch (error) {
      console.error("error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {}, [selectedFlights]);

  return (
    <Grid
      container
      spacing={0}
      style={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px" }}
    >
      <Grid item xs={12}>
        <Button
          variant="contained"
          style={{ fontSize: "12px", fontWeight: "bold" }}
          color="primary"
          onClick={handleExportClick}
          disabled={selectedOperations.length === 0}
        >
          Export JSON
        </Button>
        {!isRemoteId && (
          <Button
            variant="contained"
            style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "bold" }}
            color="primary"
            onClick={handleDuplicateClick}
            disabled={selectedOperations.length !== 1}
          >
            Repeat
          </Button>
        )}
        <Button
          variant="contained"
          style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "bold" }}
          color="primary"
          onClick={handleExportCSVClick}
          disabled={selectedOperations.length === 0}
        >
          Export CSV
        </Button>
        {!isRemoteId && (
          <Button
            variant="contained"
            style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "bold" }}
            color="primary"
            onClick={() => {
              handleReplayLog();
            }}
            disabled={selectedOperations.length === 0}
          >
            Replay Logs
          </Button>
        )}
        <Button
          variant="contained"
          disabled={loadOlderLogs}
          style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "bold" }}
          color="primary"
          onClick={toggleLoadOlderLogs}
        >
          Get Older Logs
        </Button>
        <ReplayDialog
          open={openDialog}
          handleCloseDialog={handleCloseDialog}
          selectedFlights={selectedFlights}
        />
      </Grid>
    </Grid>
  );
}
