import { store } from "@/store";
import {
  addAISLayer,
  addAdsbLayer,
  addAirSpaceLayers,
  addAirSpaceSource,
  addAnchorageLayers,
  addAnchorageSources,
  addBuildingLayer,
  addCollisionLayer,
  addConformanceLayers,
  addConformanceSource,
  addConstraintSource,
  addConstraintsLayers,
  addDronePortLayers,
  addDronePortSources,
  addGnssLayer,
  addNoFlyLayers,
  addNoFlySources,
  addOperationLayer,
  addOperationSource,
  addPrecipitationLayer,
  addRainMapSourceAndLayer,
  addSgBoundaryLayers,
  addSgBoundarySources,
  addSgPopulationDensityLayer,
  addSgPopulationDensitySource,
  addTerrainSource,
  addTrackerLayer,
  addWindMarkers,
  load3dModels,
} from "./layers";

/**
 * Adds map layers onto Mapbox sequentially
 * Order of layer addition is important, layers that are added first show through the layers that are added after it
 * @param {*} map Mapbox map to add the layers onto
 * @param {*} modelMap 3D models
 * @param {Boolean} showStaticConstraintsLayer Indicates if static constraints layer should be shown
 * @param {*} constraints Constraints layer
 * @param {Boolean} showConstraintsLayer Indicates if constraints layer should be shown
 * @param {*} showAisLayer Indicates if AIS layer should be shown
 * @param {*} aisData AIS data coming in from the sdsp/ais/snapshot endpoint
 * @param {*} showAdsbLayer Indicates if ADSB layer should be shown
 * @param {*} adsbData ADSB data coming in from the sdsp/adsb/snapshot endpoint
 * @param {Boolean} isAdsbPaid Indicates whether ADSB source is paid or free
 */
export async function addMapLayers(
  map,
  modelMap,
  showStaticConstraintsLayer,
  constraints,
  showConstraintsLayer,
  showAisLayer,
  aisData,
  showAdsbLayer,
  adsbData,
  isAdsbPaid,
  showRainMapLayer,
  airMapSpaceData,
  showAirMapSpace,
  conformanceData,
  showConformanceLayer,
  showGnssLayer,
  showAnchorageLayer,
  showDronePortLayer,
  showSgBoundaryLayer,
  toggleSgPopulationDensity,
  showMapElevation,
  showCollisionLayer
) {
  // prevent layers being added before map is ready
  if (!map._loaded) return;
  await load3dModels(modelMap);
  addCollisionLayer(map, showCollisionLayer);
  addTerrainSource(map, showMapElevation);
  await addNoFlySources(map);
  addAnchorageSources(map);
  addDronePortSources(map);
  addConstraintSource(map, constraints);
  addOperationSource(map);
  addSgBoundarySources(map);
  addSgPopulationDensitySource(map);
  addSgPopulationDensityLayer(map, toggleSgPopulationDensity);
  addSgBoundaryLayers(map, showSgBoundaryLayer);
  addBuildingLayer(map);
  addAISLayer(map, showAisLayer, aisData, modelMap);
  addAdsbLayer(map, showAdsbLayer, adsbData, modelMap, isAdsbPaid);
  addTrackerLayer(map);
  addOperationLayer(map);
  addNoFlyLayers(map, showStaticConstraintsLayer);
  addAnchorageLayers(map, showAnchorageLayer);
  addDronePortLayers(map, showDronePortLayer);
  addConstraintsLayers(map, showConstraintsLayer);
  await addWindMarkers(map);
  await addAirSpaceSource(map, airMapSpaceData);
  addConformanceSource(map, conformanceData);
  addConformanceLayers(map, showConformanceLayer);
  // addConformanceSourceLine(map, conformanceData);
  // addConformanceLayersLines(map);
  addGnssLayer(map, showGnssLayer);
  addAirSpaceLayers(map, false);

  const useSimulatedWeather = `${
    store.getState().envVar.use_simulated_weather.Value
  }`;
  if (useSimulatedWeather === "true") {
    addRainMapSourceAndLayer(map, showRainMapLayer);
  } else {
    await addPrecipitationLayer(map, showRainMapLayer);
  }

  // // previous method of switching weather layer based on location

  // if (process.env.REACT_APP_USE_OPENWEATHER_API.toUpperCase() === "TRUE") {
  //   const { lng, lat } = map.getCenter();
  //   const zoomLevel = map.getZoom();
  //   // If within SG disable Open Weather layer
  //   if (
  //     lng < 104.09485833182771 &&
  //     lng > 103.48788722370932 &&
  //     lat < 1.5017122189986765 &&
  //     lat > 1.1997149064574018 &&
  //     zoomLevel > 9
  //   ) {
  //     await addRainMapSourceAndLayer(map, showRainMapLayer);
  //     addPrecipitationLayer(map, false);
  //     addAirSpaceLayers(map, false); // disable openaip layer
  //   } else {
  //     addPrecipitationLayer(map, showRainMapLayer);
  //     await addRainMapSourceAndLayer(map, false);
  //     addAirSpaceLayers(map, false); // disable openaip layer
  //   }
  // } else {
  //   await addRainMapSourceAndLayer(map, showRainMapLayer);
  //   addAirSpaceLayers(map, false); // disable openaip layer
  // }
}

/**
 * Removes features of a given geometry type from a MapboxDraw object
 * @param {*} type Geometry type to remove
 * @param {*} draw MapboxDraw object to check against for removal of features
 */
export function removeFeatures(type, draw) {
  const data = draw.getAll();
  if (data.features.length === 0) {
    return;
  }
  const lid = data.features[data.features.length - 1].id;
  draw.delete(
    data.features
      .filter((f) => f.geometry.type === type && f.id !== lid)
      .map((f) => f.id)
  );
}

/**
 * Removes features of a given geometry type from a MapboxDraw object
 * @param {*} draw MapboxDraw object to check against for removal of features
 */
export function removeFeaturesAll(draw) {
  const data = draw.getAll();
  if (data.features.length >= 1) {
    draw.deleteAll();
  }
}
/**
 * Handles the logic for drawing on a Mapbox map
 * @param {*} map Mapbox map
 * @param {*} draw MapboxDraw object to be used for drawing
 * @param {*} onSelectEmergencyLanding Listener for updates to emergency landing point
 * @param {*} onSelectWaypoints Listener for updates to route waypoints
 * @param {*} onSelectAreaWaypoints Listener for updates to area waypoints
 * @param {*} onSelectCircleWaypoints Listener for updates to circle waypoints
 * @param {*} onSelectCircleProperties Listener for updates to circle properties
 */
export function setMapListeners(
  map,
  draw,
  // onSelectEmergencyLanding,
  handleSetEmergencyLanding,
  handleSetSelectedWaypoints,
  handleSetSelectedAreaWaypoints,
  // onSelectCircleWaypoints,
  // onSelectCircleProperties,
  handleSetSelectedCircleProperties,
  handleSetSelectedCircleWaypoints,
  handleToggleMostRecentComponent
) {
  map.on("draw.modechange", (e) => {
    if (draw.getMode() === "draw_polygon") {
      removeFeatures("Polygon", draw);
    } else if (draw.getMode() === "draw_line_string") {
      removeFeatures("LineString", draw);
    } else if (draw.getMode() === "draw_point") {
      removeFeatures("Point", draw);
    }
  });

  map.on("draw.create", (e) => {
    const { geometry } = e.features[0];
    const { coordinates } = geometry;
    if (geometry.type === "Polygon") {
      if (e.features[0].properties.isCircle) {
        // onSelectCircleWaypoints(coordinates[0]);
        handleSetSelectedCircleWaypoints(coordinates[0]);
        handleSetSelectedCircleProperties(e.features[0].properties);

        // onSelectCircleProperties(e.features[0].properties);
      } else {
        handleSetSelectedAreaWaypoints(coordinates[0]);
      }
    } else if (geometry.type === "LineString") {
      handleSetSelectedWaypoints(coordinates);
    } else if (geometry.type === "Point") {
      handleSetEmergencyLanding(coordinates);
    }
    handleToggleMostRecentComponent();
  });

  map.on("draw.update", (e) => {
    const { geometry } = e.features[0];
    const { coordinates } = geometry;
    if (geometry.type === "Polygon") {
      if (e.features[0].properties.isCircle) {
        // onSelectCircleWaypoints(coordinates[0]);
        // onSelectCircleProperties(e.features[0].properties);
        handleSetSelectedCircleWaypoints(coordinates[0]); // this???
        handleSetSelectedCircleProperties(e.features[0].properties);
      } else {
        handleSetSelectedAreaWaypoints(coordinates[0]);
      }
    } else if (geometry.type === "LineString") {
      handleSetSelectedWaypoints(coordinates);
    } else if (geometry.type === "Point") {
      handleSetEmergencyLanding(coordinates);
    }
    handleToggleMostRecentComponent();
  });
}
