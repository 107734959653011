import "./styles.css";
import { useState, useEffect, memo } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
// import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";
import {
  DeleteOutlined,
  LensOutlined,
  TimelineOutlined,
  PinDropOutlined,
  CropSquareOutlined,
  LabelOutlined,
} from "@material-ui/icons";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import length from "@turf/length";
import turfLineString from "turf-linestring";

import axios from "axios";
import { useApi } from "../../../api/useApi";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../../store/actions";
import CircleEntries from "./circle";
import AreaEntries from "./area";
import WaypointsEntries from "./route";
import EmergencyLandingPointEntry from "./emergency";
import { computePolygonCenter } from "../../../api/polygonCenter";
import { convertZuluToLocalTime } from "../../../api/timeConvert";
import RejectAcceptOperationResponsiveDialog from "../../../components/RejectAcceptOperation";
import gnssCreateOperation from "../../../components/MapB/gnss/gnssCreateOperation";
import { handleKmlImport, handleJsonImport } from "../../../services/json";

function Panel(props) {
  return (
    <div hidden={props.value !== props.index}>
      {props.value === props.index && (
        <Typography component="span" variant="body2">
          {props.children}
        </Typography>
      )}
    </div>
  );
}
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));
const TypeOptions = [
  {
    value: 0,
    label: "vlos",
  },
  {
    value: 1,
    label: "bvlos",
  },
];
function MultipleDrone({
  availablePlatforms,
  platformid,
  setPlatformID,
  pilotid,
  availablePilots,
  setPilotID,
  selectedMultiDrone,
  setSelectedMultiDrone,
  selectWaypointMethod,
  availableTracker,
  setTrackeruuid,
  getPlatforms,
  getPilots,
  getTrackers,

  unavailablePlatforms,
  unavailableTrackers,
  loadingAssetList,
}) {
  const [keysCounter, setKeysCounter] = useState(0);
  const d = new Date();
  const addFormFields = () => {
    const newfield = {
      platformid: availablePlatforms[0]?.platform_uuid,
      pilotid: availablePilots[0]?.pilot_uuid,
      trackeruuid: availableTracker[0]?.tracker_uuid,
      id: `${keysCounter}1${d.getTime().toString()}`,
    };
    setSelectedMultiDrone([...selectedMultiDrone, newfield]);
  };
  const removeFields = (index) => {
    const data = [...selectedMultiDrone];
    data.splice(index, 1);
    setSelectedMultiDrone(data);
  };
  const handleFormChange = (index, event) => {
    const data = [...selectedMultiDrone];
    data[index][event.target.name] = event.target.value;
    setSelectedMultiDrone(data);
  };
  useEffect(() => {
    const dataPilotId = selectedMultiDrone.map((item, index, arr) => {
      return item.pilotid;
    });
    setPilotID(dataPilotId);
    const dataPlatformId = selectedMultiDrone.map((item, index, arr) => {
      return item.platformid;
    });
    setPlatformID(dataPlatformId);
    const dataTrackerID = selectedMultiDrone.map((item, index, arr) => {
      return item.trackeruuid;
    });
    setTrackeruuid(dataTrackerID);
  }, [selectedMultiDrone]);
  return (
    <div>
      {selectedMultiDrone.map((element, index) => (
        // <Grid container spacing={3} style={{ marginTop: "15px" }}>
        <div
          key={`${element.id}${element.trackeruuid}`}
          style={{
            display: "grid",
            gridTemplateColumns: "6fr 6fr 6fr 1fr 1fr",
            gap: "0.25rem",
            marginLeft: "1rem",
          }}
        >
          <LoadingOverlay spinner active={loadingAssetList}>
            <TextField
              id="platform"
              name="platformid"
              label="Platform Callsign"
              fullWidth
              select
              value={element.platformid || ""}
              onChange={(event) => {
                handleFormChange(index, event);
              }}
              onPointerDown={(event) => {
                getPlatforms && getPlatforms();
              }}
              style={{
                width: 0,
                minWidth: "100%",
              }}
            >
              {availablePlatforms.map((option) => (
                <MenuItem
                  key={option.platform_uuid}
                  value={option.platform_uuid}
                  disabled={
                    unavailablePlatforms
                      ? unavailablePlatforms.includes(option.platform_uuid)
                      : false
                  }
                >
                  {option.platform_callsign}{" "}
                  {unavailablePlatforms?.includes(option.platform_uuid)
                    ? "(Unavailable)"
                    : ""}
                </MenuItem>
              ))}
            </TextField>
          </LoadingOverlay>
          <TextField
            id="pilot"
            name="pilotid"
            label="Pilot Name"
            fullWidth
            select
            value={element.pilotid || ""}
            onChange={(event) => {
              handleFormChange(index, event);
            }}
            onPointerDown={(event) => {
              getPilots && getPilots();
            }}
          >
            {availablePilots.map((option) => (
              <MenuItem key={option.pilot_uuid} value={option.pilot_uuid}>
                {option.pilot_name}
              </MenuItem>
            ))}
          </TextField>
          <LoadingOverlay spinner active={loadingAssetList}>
            <TextField
              id="tracker"
              name="trackeruuid"
              label="Tracker Callsign"
              fullWidth
              select
              value={element.trackeruuid || ""}
              onChange={(event) => {
                handleFormChange(index, event);
              }}
              onPointerDown={(event) => {
                getTrackers && getTrackers();
              }}
              style={{
                width: 0,
                minWidth: "100%",
              }}
            >
              {availableTracker.map((option) => (
                <MenuItem
                  key={option.tracker_uuid}
                  value={option.tracker_uuid}
                  defaultValue=""
                  disabled={
                    unavailableTrackers
                      ? unavailableTrackers.includes(option.tracker_uuid)
                      : false
                  }
                >
                  {option.tracker_name}{" "}
                  {unavailableTrackers?.includes(option.tracker_uuid)
                    ? "(Unavailable)"
                    : ""}
                </MenuItem>
              ))}
            </TextField>
          </LoadingOverlay>
          <Button
            onClick={() => {
              removeFields(index);
            }}
            disabled={index <= 0}
          >
            <DeleteIcon />
          </Button>
          <Button
            onClick={() => {
              addFormFields();
            }}
          >
            <Add />
          </Button>
        </div>
      ))}
    </div>
  );
}

function CreateOperationFormComponent(props) {
  const {
    isCircleMethod,
    isPolygonMethod,
    isRouteMethod,
    setSubmitLoading,
    onTabClicked,
    setTabIndex,
    setLockedTab,
    setSelectWaypointMethod,
    selectWaypointMethod,
    //
    // selectedWaypoints: _selectedWaypoints,
    // selectedAreaWaypoints: _selectedAreaWaypoints,
    // selectedCircleWaypoints: _selectedCircleWaypoints,
    // selectedCircleProperties: _selectedCircleProperties,
    //
    selectedWaypoints,
    setSelectedWaypoints,
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    setSelectedCircleProperties,
    selectedCircleProperties,
    //
    // contingencyLandingPoint,
    // setContingencyLandingPoint,
    // onEmergencyLandingUpdate,
    setUpdatedEmergencyLanding,
    // mapViewController,
    emergencyLanding,
    setEmergencyLanding,
    availablePilots,
    availableTracker,
    availablePlatforms,
    getPlatforms,
    getPilots,
    getTrackers,
    openFlightAuthorization,
    handleOpenFlightAuthorization,
    isAisOperation,
    setSelectedShip,
    setSelectedTakeOffLandingPoint,
    setIsReturnFromShipOperation,
    awaitTwoWayOperationEdit,
    setAwaitTwoWayOperationEdit,
  } = props;
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const [platformid, setPlatformID] = useState("");
  const [pilotid, setPilotID] = useState("");
  const [trackeruuid, setTrackeruuid] = useState("");
  const [operationType, setOperationType] = useState("bvlos");
  const [selectedMultiDrone, setSelectedMultiDrone] = useState([
    {
      platformid: "",
      pilotid: "",
      trackeruuid: "",
      id: "123123",
    },
  ]);
  const currentTime = new Date();
  const [departureTime, setDepartureTime] = useState(
    new Date(currentTime.getTime() + 3 * 60000)
  ); // Default set as 3 mins from current time
  const [departureTimeError, setDepartureTimeError] = useState("");
  const [endTime, setEndTime] = useState(
    new Date(currentTime.getTime() + 33 * 60000)
  ); // Default set as 30 mins from start time
  const [endTimeError, setEndTimeError] = useState("");
  const [contingencyLandingPoint, setContingencyLandingPoint] = useState([
    0.0, 0.0,
  ]); // should be deprecated
  const [safeAltitude, setSafeAltitude] = useState(60);
  const [operationDescription, setOperationDescription] = useState("");
  const [speed, setSpeed] = useState(5);
  const [altitude, setAltitude] = useState(60);
  const [altitude_reference, setAltitude_reference] = useState("W84");
  const [verticalBuffer, setVerticalBuffer] = useState(25);
  const [lateralBuffer, setLateralBuffer] = useState(25);
  const [timeBuffer, setTimeBuffer] = useState(30);
  const [elevation, setElevation] = useState(0);
  const [maxSegmentLength, setMaxSegmentLength] = useState(500);
  const [airspaceOptimised, setAirspaceOptimised] = useState(false);
  const [customBuffer, setCustomBuffer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalWaypointDistance, setTotalWaypointDistance] = useState(0);
  // const [submitLoading, setSubmitLoading] = useState(false);
  // const [tabIndex, setTabIndex] = useState(0);
  // const [lockedTab, setLockedTab] = useState(-1);
  const dispatch = useDispatch();
  const api = useApi();
  // const [selectWaypointMethod, setSelectWaypointMethod] = useState("circle");
  const [isInEditMode, setIfEditMode] = useState(false);
  const operationFormRequest = useSelector(
    (state) => state.operations.operationFormRequest
  );
  const [openReject, setOpenReject] = useState(false);
  const [compareCurrentData, setCompareCurrentData] = useState({});
  const [reScheduled, setRescheduled] = useState(false);
  const [reRouted, setRerouted] = useState(false);
  const [reRoutedSchedEdit, setRoutedSchedEdit] = useState([]);

  const [compareData, setCompareData] = useState({});
  const [loadingGnss, setLoadingGnss] = useState(false);
  const [unavailableTrackers, setUnavailableTrackers] = useState([]);
  const [unavailablePlatforms, setUnavailablePlatforms] = useState([]);
  const [loadingAssetList, setLoadingAssetList] = useState(false);
  // when editing operation, elevation data will not be auto calculated when initially loaded
  // the auto calculation should occur on subsequent changes
  // this state is used to track this event
  const [editingElevationData, setEditingElevationData] = useState(false);
  const [lockVariables, setLockVariables] = useState(false); // lock time when drawing operation
  const [mapboxToken, setmapboxToken] = useState("");
  const envVar = useSelector((state) => state.envVar);
  const classesFloating = useFloatingButton();
  // const onTabClicked = (event, index) => {
  //   setTabIndex(index);
  //   if (index === 0) {
  //     setSelectWaypointMethod("circle");
  //   } else if (index === 1) {
  //     setSelectWaypointMethod("area");
  //   } else if (index === 2) {
  //     setSelectWaypointMethod("route");
  //   } else if (index === 3) {
  //     setSelectWaypointMethod("upload");
  //   }
  // };

  // ********* FORM VALIDATIONS  ********* //
  const [operationIntentError, setOperationIntentError] = useState(false);
  const [operationIntentHelperText, setOperationIntentHelperText] =
    useState("");
  const [timeBufferError, setTimeBufferError] = useState(false);
  const [timeBufferHelperText, setTimeBufferHelperText] = useState("");
  const [lateralBufferError, setLateralBufferError] = useState(false);
  const [lateralBufferHelperText, setLateralBufferHelperText] = useState("");
  const [verticalBufferError, setVerticalBufferError] = useState(false);
  const [verticalBufferHelperText, setVerticalBufferHelperText] = useState("");
  const [pilotIdError, setPilotIdError] = useState(false);
  const [pilotIdHelperText, setPilotIdHelperText] = useState("");
  const [maxSegmentLengthError, setMaxSegmentLengthError] = useState(false);
  const [maxSegmentLengthHelperText, setMaxSegmentLengthHelperText] =
    useState("");
  const [ifDuplicateMode, setIfDuplicateMode] = useState(false);
  const [transitDurationError, setTransitDurationError] = useState(false);
  const [transitDurationErrorText, setTransitDurationErrorText] =
    useState(false);

  // ********* Two Way Operations  ********* //
  const [twoWayOperation, setTwoWayOperation] = useState(false);
  const [
    twoWayOperationSecondReroutedVolume,
    setTwoWayOperationSecondReroutedVolume,
  ] = useState("");
  const [
    twoWayOperationReroutedOriginalVolume,
    setTwoWayOperationReroutedOriginalVolume,
  ] = useState([]);
  const [twoWayOperationUuid, setTwoWayOperationUuid] = useState([]);
  const [transitDurationMin, setTransitDurationMin] = useState(0);
  const [transitDurationHour, setTransitDurationHour] = useState(0);
  // const [awaitTwoWayOperationEdit, setAwaitTwoWayOperationEdit] = useState();
  const [submitTriggered, setSubmitTriggered] = useState(false);
  let twoWayOperationDeleted = false;

  useEffect(() => {
    if (envVar["api_key-mapbox"]) {
      setmapboxToken(envVar["api_key-mapbox"].Value);
    }
  }, []);
  useEffect(() => {
    if (isCircleMethod) {
      setSelectWaypointMethod("circle");
    } else if (isPolygonMethod) {
      setSelectWaypointMethod("area");
    } else if (isRouteMethod) {
      setSelectWaypointMethod("route");
    }
    // else if (index === 3) {
    //   setSelectWaypointMethod("upload");
    // }
  }, []);

  const handleImport = async (e) => {
    const [file] = e.target.files;
    e.target.files = null; // Clear the input field

    const importFile = (reader) => {
      return new Promise((resolve) => {
        reader.addEventListener("load", () => {
          resolve(reader.result);
        });
        if (file) {
          reader.readAsText(file);
        }
      });
    };

    if (file.type === "application/json") {
      const reader = new FileReader();
      const data = JSON.parse(await importFile(reader));
      handleJsonImport(data, dispatch);
    } else if (file.type === "") {
      const reader = new FileReader();
      const kmlData = await importFile(reader);
      handleKmlImport(kmlData, dispatch);
    } else {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Import must be a JSON or KML file.",
          severity: "error",
        })
      );
    }
  };

  const registerController = () => {
    console.log(mapboxController);
  };

  const resetForm = () => {
    // mapboxController.trash();
    if (isPolygonMethod) {
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedAreaWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: false,
      });
    }
    if (isRouteMethod) {
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: false,
      });
    }
    if (isCircleMethod) {
      setSelectedCircleWaypoints([
        [0.0, 0.0],
        [0.0, 0.0],
      ]);
      setSelectedCircleProperties({
        isCircle: true,
        center: [0.0, 0.0],
        radiusInKm: 0,
      });
    }
    if (isAisOperation) {
      setSelectedShip(null);
      setSelectedTakeOffLandingPoint(null);
      setIsReturnFromShipOperation(false);
    }
    setEmergencyLanding([0.0, 0.0]);
    // setContingencyLandingPoint([0.0, 0.0]);
    setAltitude(60);
    setSafeAltitude(60);
    setSpeed(5);
    setOperationDescription("");
    setOperationType("bvlos");
    setDepartureTime(new Date(new Date().getTime() + 3 * 60000));
    setEndTime(new Date(new Date().getTime() + 33 * 60000));
    setPilotID("");
    setPlatformID("");
    setTrackeruuid("");
    setVerticalBuffer(25);
    setLateralBuffer(25);
    setTimeBuffer(30);
    setElevation(0);
    setMaxSegmentLength(500);
    setTwoWayOperation(false);
    twoWayOperationDeleted = false;
    setLockVariables(false);
    setSelectedMultiDrone([
      {
        platformid: "",
        pilotid: "",
        trackeruuid: "",
        id: "123123",
      },
    ]);
    setTransitDurationMin(0);
    setTransitDurationHour(0);
    clearFormValidations();
    setTotalWaypointDistance(0);
  };

  const handleDiscardEdit = () => {
    resetForm();
    dispatch(setOperationFormRequest(null));
    setIfEditMode(false);
    setIfDuplicateMode(false);
    setLockedTab(-1);
  };

  const checkWaypointOperationDistanceExceeded = (CompareData) => {
    if (
      CompareData?.details?.recommended_max_distance <
      CompareData?.details?.distance
    ) {
      return true;
    }
    return false;
  };
  const handleOpenSchedReroutedDialog = (CompareData, CompareCurrentData) => {
    if (CompareData?.details?.rescheduled || CompareData?.details?.rerouted) {
      CompareData?.details?.rescheduled
        ? setRescheduled(true)
        : setRescheduled(false);
      CompareData?.details?.rerouted ? setRerouted(true) : setRerouted(false);
      setCompareData(CompareData);
      setCompareCurrentData(CompareCurrentData);
      setOpenReject(true);
    } else if (CompareData?.details?.state === "Proposed") {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Operation proposed, awaiting approval",
          severity: "info",
        })
      );
    } else {
      // this state is used to wait for user to finish editing operation
      // scenerio - both of 2 way operation is rerouted, user selected edit on 1st operation
      // this state is to allow RejectAcceptOperation popup for 2nd operation after 1st is submitted
      if (awaitTwoWayOperationEdit) {
        setSubmitTriggered(true);
      }
      if (checkWaypointOperationDistanceExceeded(CompareData)) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message:
              "Operation submitted successfully but exceeds max recommended distance",
            severity: "info",
          })
        );
      } else {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Operation submitted successfully",
            severity: "success",
          })
        );
      }
    }
  };
  const handleClose = (twoWayOperationEdit = false) => {
    setOpenReject(false);
    if (
      twoWayOperationSecondReroutedVolume &&
      !twoWayOperationDeleted &&
      !twoWayOperationEdit
    ) {
      // this state is used to wait for user to finish editing operation
      // scenerio - both of 2 way operation is rerouted, user selected edit on 1st operation
      // this state is to allow RejectAcceptOperation popup for 2nd operation after 1st is submitted
      if (awaitTwoWayOperationEdit) {
        setSubmitTriggered(true);
      }
      const twoWayOperationReroutedVolumeData =
        twoWayOperationSecondReroutedVolume;
      const twoWayOperationReroutedOriginalVolumeData =
        twoWayOperationReroutedOriginalVolume;

      // in scenerio where this is triggered right after 1st operation
      // may encounter mapbox not updating map, added 1/2 second delay to prevent this.
      setTimeout(() => {
        // reopen dialogue for return operation
        handleOpenSchedReroutedDialog(
          twoWayOperationReroutedVolumeData,
          twoWayOperationReroutedOriginalVolumeData
        );
      }, 500);
      setTwoWayOperationSecondReroutedVolume("");
      setTwoWayOperationReroutedOriginalVolume("");
    } else if (
      twoWayOperationSecondReroutedVolume &&
      !twoWayOperationDeleted &&
      twoWayOperationEdit
    ) {
      if (!openFlightAuthorization) {
        handleOpenFlightAuthorization();
      }
      // useEffect will wait for user to finish editing operation before continuing
      setAwaitTwoWayOperationEdit(true);
    } else if (twoWayOperationEdit) {
      handleOpenFlightAuthorization();
    }
  };

  useEffect(() => {
    if (
      !awaitTwoWayOperationEdit ||
      openFlightAuthorization ||
      !submitTriggered
      // openReject
    )
      return;
    // reopen dialogue for return operation
    handleOpenSchedReroutedDialog(
      twoWayOperationSecondReroutedVolume,
      twoWayOperationReroutedOriginalVolume
    );
    setTwoWayOperationSecondReroutedVolume("");
    setTwoWayOperationReroutedOriginalVolume("");
    setAwaitTwoWayOperationEdit(false);
    setSubmitTriggered(false);
  }, [submitTriggered, openFlightAuthorization, openReject]);

  const handleCircleOp = async (editMode) => {
    if (operationDescription === "") {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Operation Intent cannot be empty",
          severity: "error",
        })
      );
      return;
    }

    try {
      // handle if unavailable platform or tracker is selected
      // this scenerio only occurs if user selects asset first then selects a timeframe where asset is unavailable
      if (
        unavailablePlatforms.some((singlePlatformUuid) =>
          platformid.includes(singlePlatformUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Platform is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      if (
        unavailableTrackers.some((singleTrackerUuid) =>
          trackeruuid.includes(singleTrackerUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Tracker is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        area_coordinates: selectedCircleWaypoints,
        time_buffer: timeBuffer,
        elevation,
        altitude,
        altitude_reference,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          // landing_point: [contingencyLandingPoint],
          landing_point: [emergencyLanding],
          safe_altitude: safeAltitude,
        },
        properties: selectedCircleProperties,
      };

      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.editOperation(operationFormRequest.id, data)
        : api.submitAreaOperation(data));
      if (response.data) {
        if (!isAisOperation) handleOpenFlightAuthorization();
        handleOpenSchedReroutedDialog(response.data.operations[0], data);
        resetForm();
        dispatch(setOperationFormRequest(null));
      }
    } catch (err) {
      let message = "Error getting response, please try again";
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
      if (message && message.startsWith("Airspace already reserved")) {
        message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      if (err.code === "ECONNABORTED") {
        message = "Network Error";
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };
  const handleAreaOp = async (editMode) => {
    try {
      // handle if unavailable platform or tracker is selected
      // this scenerio only occurs if user selects asset first then selects a timeframe where asset is unavailable
      if (
        unavailablePlatforms.some((singlePlatformUuid) =>
          platformid.includes(singlePlatformUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Platform is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      if (
        unavailableTrackers.some((singleTrackerUuid) =>
          trackeruuid.includes(singleTrackerUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Tracker is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        area_coordinates: selectedAreaWaypoints,
        time_buffer: timeBuffer,
        elevation,
        altitude,
        altitude_reference,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          // landing_point: [contingencyLandingPoint],
          landing_point: [emergencyLanding],
          safe_altitude: safeAltitude,
        },
        properties: {
          isCircle: false,
        },
      };
      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.editOperation(operationFormRequest.id, data)
        : api.submitAreaOperation(data));
      if (response.data) {
        if (!isAisOperation) handleOpenFlightAuthorization();
        handleOpenSchedReroutedDialog(response.data.operations[0], data);
        resetForm();
        dispatch(setOperationFormRequest(null));
      }
    } catch (err) {
      let message = "Error getting response, please try again";
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
      if (message && message.startsWith("Airspace already reserved")) {
        message = `Airspace unavailable. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      if (err.code === "ECONNABORTED") {
        message = "Network Error";
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };

  const handleDiscardTwoWayOperation = () => {
    // prevents dialogue popup when both of two way operation deleted
    twoWayOperationDeleted = true;
  };

  const handleRouteOp = async (editMode) => {
    try {
      // handle if unavailable platform or tracker is selected
      // this scenerio only occurs if user selects asset first then selects a timeframe where asset is unavailable
      if (
        unavailablePlatforms.some((singlePlatformUuid) =>
          platformid.includes(singlePlatformUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Platform is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      if (
        unavailableTrackers.some((singleTrackerUuid) =>
          trackeruuid.includes(singleTrackerUuid)
        )
      ) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Tracker is unavailable during selected time",
            severity: "error",
          })
        );
        return;
      }
      const transit_duration =
        (transitDurationHour * 60 + transitDurationMin) * 60;
      const data = {
        pilot_uuid: pilotid,
        platform_uuid: platformid,
        tracker_uuid: trackeruuid,
        waypoints: selectedWaypoints,
        time_buffer: timeBuffer,
        elevation,
        max_segment_length: maxSegmentLength,
        airspace_optimised: airspaceOptimised,
        two_way: twoWayOperation,
        transit_duration,
        altitude,
        altitude_reference,
        time_start: departureTime.toISOString(),
        time_end: endTime.toISOString(),
        ground_speed: speed,
        operation_type: operationType,
        description: operationDescription,
        contingency_plans: {
          // landing_point: [contingencyLandingPoint],
          landing_point: [emergencyLanding],

          safe_altitude: safeAltitude,
        },
      };
      if (customBuffer) {
        data.vertical_buffer = verticalBuffer;
        data.lateral_buffer = lateralBuffer;
      }
      const response = await (editMode
        ? api.editOperation(operationFormRequest.id, data)
        : api.submitOperation(data));
      if (response.data) {
        if (!isAisOperation) handleOpenFlightAuthorization();
        if (
          response.data.operations[0].details.recommended_max_distance <
          response.data.operations[0].details.distance
        ) {
          dispatch(
            setSnackbarMessage({
              open: true,
              message:
                "Waypoint operation distance exceeds recommended max distance",
              severity: "info",
            })
          );
        }
        // two way operation
        const isTwoWayOperation = response.data.operations[0]?.request?.two_way;
        if (isTwoWayOperation) {
          setTwoWayOperationUuid([
            response.data.operations[0].reference.id,
            response.data.operations[1].reference.id,
          ]);
          if (
            response.data.operations[0]?.details?.rescheduled ||
            response.data.operations[0]?.details?.rerouted
          ) {
            // store a copy of current data in case user choose to edit operation and overwrite data
            const editedSecondOperation = response.data.operations[1];
            editedSecondOperation.request.time_start =
              response.data.operations[1].reference.time_start.value;
            setTwoWayOperationSecondReroutedVolume(editedSecondOperation);
            const secondOperationData = {
              ...data,
              time_start:
                response.data.operations[1].reference.time_start.value,
              request: {
                time_start:
                  response.data.operations[1].reference.time_start.value,
              },
            };
            setTwoWayOperationReroutedOriginalVolume(secondOperationData);
            handleOpenSchedReroutedDialog(response.data.operations[0], data);
            // when return trip if rerouted or rescheduled
          } else if (
            response.data.operations[1]?.details?.rescheduled ||
            response.data.operations[1]?.details?.rerouted
          ) {
            const secondOperationData = {
              ...data,
              time_start:
                response.data.operations[1].reference.time_start.value,
            };
            const editedSecondOperation = response.data.operations[1];
            editedSecondOperation.request.time_start =
              response.data.operations[1].reference.time_start.value;
            handleOpenSchedReroutedDialog(
              editedSecondOperation,
              secondOperationData
            );
          }
        } else {
          handleOpenSchedReroutedDialog(response.data.operations[0], data);
        }
        resetForm();
        dispatch(setOperationFormRequest(null));
      }
    } catch (err) {
      let message = "Error getting response, please try again";
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
      if (message && message.startsWith("Airspace already reserved")) {
        message = `Airspace already reserved. Try again after: ${convertZuluToLocalTime(
          message.split(" ").at(-2)
        )}`;
      }
      if (err.code === "ECONNABORTED") {
        message = "Network Error";
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
  };

  const handleFormValidations = () => {
    let error = false;
    if (operationDescription === null || operationDescription === "") {
      setOperationIntentError(true);
      setOperationIntentHelperText("Operation Intent cannot be empty");
      error = true;
    }
    if (pilotid === null || pilotid === "") {
      setPilotIdError(true);
      setPilotIdHelperText("Pilot Name cannot be empty");
      error = true;
    }

    return error;
  };
  const clearFormValidations = () => {
    setOperationIntentError(false);
    setOperationIntentHelperText("");
    setTimeBufferError(false);
    setTimeBufferHelperText("");
    setLateralBufferError(false);
    setLateralBufferHelperText("");
    setVerticalBufferError(false);
    setVerticalBufferHelperText("");
    setPilotIdError(false); // should not be triggered as pilot validation on form submit
    setPilotIdHelperText("");
    setMaxSegmentLengthError(false);
    setMaxSegmentLengthHelperText("");
    setTransitDurationError(false);
    setTransitDurationErrorText("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleFormValidations()) {
      return;
    }
    setSubmitLoading(true);
    const editMode = isInEditMode && operationFormRequest.id;
    // Submit Operation
    if (selectWaypointMethod == "circle") {
      // "circle operation"
      await handleCircleOp(editMode);
    } else if (selectWaypointMethod == "area") {
      // "area operation"
      await handleAreaOp(editMode);
    } else {
      // "route operation"
      await handleRouteOp(editMode);
    }
    clearGnssData();
    setSubmitLoading(false);
    clearFormValidations();
    setLockVariables(false);
    mapboxController.removeDrawFeaturesAll();
    setLockedTab(-1); // resetting to default upon submit
  };

  const handleOnFocus = async (center) => {
    mapboxController.flyTo(center);
  };

  useEffect(() => {
    if (!openFlightAuthorization) return;
    setLockVariables(true);
  }, [departureTime, endTime]);

  useEffect(() => {
    // allow user to enter value larger than 60 in transit duration (m) then check after 1.5sec
    setTimeout(() => {
      checkTransitDuration();
    }, 1500);
  }, [transitDurationMin]);

  // added asset avail refresh on isOpen or not because the component loads only once at start of app
  useEffect(() => {
    if (!api) return;
    if (openFlightAuthorization) {
      checkAssetAvailability();
      checkAssetAvailabilityRoute();
    }
  }, [openFlightAuthorization, api]);

  // useEffect(() => {
  //   if (_selectedWaypoints?.length && isRouteMethod) {
  //     console.log("_selected waypoints triggered");
  //     setSelectedWaypoints(_selectedWaypoints);
  //     setUpdatedEmergencyLanding([
  //       _selectedWaypoints[0][0],
  //       _selectedWaypoints[0][1],
  //     ]);

  //     if (!contingencyLandingPoint[0] && !contingencyLandingPoint[1]) {
  //       setContingencyLandingPoint([
  //         _selectedWaypoints[0][0],
  //         _selectedWaypoints[0][1],
  //       ]);
  //     }
  //   }
  //   checkAssetAvailabilityRoute();
  // }, [_selectedWaypoints]);

  /* updates emergency Landing when route waypoints change */
  useEffect(() => {
    // set emergency landing
    if (selectedWaypoints?.length && isRouteMethod) {
      setSelectedWaypoints(selectedWaypoints);
      setEmergencyLanding([selectedWaypoints[0][0], selectedWaypoints[0][1]]);

      // if (!contingencyLandingPoint[0] && !contingencyLandingPoint[1]) {
      //   setContingencyLandingPoint([
      //     selectedWaypoints[0][0],
      //     selectedWaypoints[0][1],
      //   ]);
      // }
    }
    checkAssetAvailabilityRoute();

    // check total distance
    if (selectedWaypoints?.length && isRouteMethod) {
      const formattedWaypoint = turfLineString(selectedWaypoints);
      const distance = length(formattedWaypoint, { units: "kilometers" });
      if (distance) {
        setTotalWaypointDistance(distance.toFixed(2));
      }
    }
  }, [selectedWaypoints]);

  // useEffect(() => {
  //   if (_selectedAreaWaypoints?.length && isPolygonMethod) {
  //     console.log("_selected area waypoints triggered");

  //     setSelectedAreaWaypoints(_selectedAreaWaypoints);
  //     const center = computePolygonCenter(_selectedAreaWaypoints);
  //     setContingencyLandingPoint(center);
  //     setUpdatedEmergencyLanding(center);

  //     if (!contingencyLandingPoint[0] || !contingencyLandingPoint[1]) {
  //       if (!center[0] || !center[1]) {
  //         setContingencyLandingPoint([
  //           _selectedAreaWaypoints[0][0],
  //           _selectedAreaWaypoints[0][1],
  //         ]);
  //       }
  //     }
  //   }
  // }, [_selectedAreaWaypoints]);

  /* updates emergency Landing when area waypoints change */
  useEffect(() => {
    if (selectedAreaWaypoints?.length && isPolygonMethod) {
      setSelectedAreaWaypoints(selectedAreaWaypoints);
      const center = computePolygonCenter(selectedAreaWaypoints);
      if (typeof center[0] === "number" && isFinite(center[0])) {
        setEmergencyLanding(center);
      } else {
        setEmergencyLanding([0.0, 0.0]);
      }
      // setContingencyLandingPoint(center);
    }
  }, [selectedAreaWaypoints]);

  /* 
   * this is to update local form circle properties when _circle properties
   * from mapbox changes 
   * UPDATE 25/10/23: this should not be necessary anymore 
   * as there is only 1 single variable across all components.
   * /

  // useEffect(() => {
  //   if (_selectedCircleWaypoints?.length && isCircleMethod) {
  //     setSelectedAreaCircle(_selectedCircleWaypoints);
  //     setSelectedCircleProperties(_selectedCircleProperties);
  //     const circleProperties = _selectedCircleProperties?.center;

  //     if (circleProperties?.length > 0) {
  //       setContingencyLandingPoint([circleProperties[0], circleProperties[1]]);
  //       setUpdatedEmergencyLanding([circleProperties[0], circleProperties[1]]);
  //     }
  //     // check if selectCircleProperties length more than 0, seems to be empty on initial set
  //     if (_selectedCircleProperties?.length > 0) {
  //       handleSetCircleAll(_selectedCircleProperties);
  //     }
  //   }
  // }, [_selectedCircleWaypoints, _selectedCircleProperties]);

  /* this updates contingency landing point on selectedCircleProperties change */
  useEffect(() => {
    const circleProperties = selectedCircleProperties?.center;

    if (circleProperties?.length > 0) {
      // setContingencyLandingPoint([circleProperties[0], circleProperties[1]]);
      setEmergencyLanding([circleProperties[0], circleProperties[1]]);
    }
    if (selectedCircleProperties?.length > 0) {
      handleSetCircleAll(selectedCircleProperties);
    }
  }, [selectedCircleProperties]);

  /**
   * Use Effects to track input changes of coordinates on forms, then
   * update accordingly on the Map
   * UPDATE: SHOULD NOT BE NECESSARY ANYMORE 24/10/23
   */

  useEffect(() => {
    if (isPolygonMethod) {
      // onUpdateSelectedAreaWaypoints(selectedAreaWaypoints);
    }
  }, [selectedAreaWaypoints]);

  useEffect(() => {
    if (isRouteMethod) {
      // onUpdateSelectedWaypoints(selectedWaypoints);
    }
  }, [selectedWaypoints]);

  const resetAreaWayPoints = () => {
    setSelectedCircleProperties({
      isCircle: false,
    });
    setSelectedCircleWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    setSelectedAreaWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
  };

  const resetCircleWaypoints = () => {
    setSelectedCircleProperties({
      isCircle: true,
      center: [0.0, 0.0],
      radiusInKm: 0,
    });
    setSelectedCircleWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
  };

  const resetSelectedWaypoints = () => {
    setSelectedCircleProperties({
      isCircle: false,
    });
    setSelectedCircleWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
    setSelectedWaypoints([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);
  };

  const getElevationFromMapboxRGB = async (lat, lng, callback) => {
    const x = Math.floor(((lng + 180) / 360) * 2 ** 20);
    const y = Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        2 ** 20
    );
    if (mapboxToken) {
      const image = await axios.get(
        `https://api.mapbox.com/v4/mapbox.terrain-rgb/20/${x}/${y}.pngraw?access_token=${mapboxToken}`,
        { responseType: "arraybuffer" }
      );
      const imageBlob = new Blob([image.data], { type: "image/png" });
      // const imageObjectURL = URL.createObjectURL(imageBlob);
      // setimageState(imageObjectURL);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const dataUrl = fileReader.result; // Get the data URL
        // Create an Image element and set its source to the data URL
        const img = new Image();
        img.src = dataUrl;

        img.onerror = (err) => {
          console.log(err);
        };

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Get the pixel data
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixelData = imageData.data;
          const middlePixel = pixelData.length / 2;
          const redValue = pixelData[middlePixel];
          const greenValue = pixelData[middlePixel + 1];
          const blueValue = pixelData[middlePixel + 2];
          const pixelElevation =
            -10000 +
            (redValue * 256 * 256 + greenValue * 256 + blueValue) * 0.1;
          callback(pixelElevation);
        };
      };

      fileReader.onerror = (err) => {
        console.error(err);
      };

      // Start reading the Blob as a data URL
      fileReader.readAsDataURL(imageBlob);
    } else {
      callback(0);
    }
  };
  const handleAllElevation = async ({ lat, lng, parameter = null }) => {
    try {
      let elevationData;
      if (!(mapboxController.getTerrain() === null)) {
        elevationData = await mapboxController.map.queryTerrainElevation({
          lat,
          lng,
        });
        if (parameter === null) {
          setAltitude(Math.round(elevationData) + 60);
          setSafeAltitude(Math.round(elevationData) + 60);
          setElevation(Math.round(elevationData));
        } else if (parameter === "altitude") {
          setAltitude(Math.round(elevationData) + 60);
        } else if (parameter === "safeAltitude") {
          setSafeAltitude(Math.round(elevationData) + 60);
        } else if (parameter === "elevation") {
          setElevation(Math.round(elevationData));
        }
      } else {
        await getElevationFromMapboxRGB(lat, lng, (data) => {
          elevationData = data;
          if (parameter === null) {
            setAltitude(Math.round(elevationData) + 60);
            setSafeAltitude(Math.round(elevationData) + 60);
            setElevation(Math.round(elevationData));
          } else if (parameter === "altitude") {
            setAltitude(Math.round(elevationData) + 60);
          } else if (parameter === "safeAltitude") {
            setSafeAltitude(Math.round(elevationData) + 60);
          } else if (parameter === "elevation") {
            setElevation(Math.round(elevationData));
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   if (editingElevationData) setEditingElevationData(false);
  //   else if (emergencyLanding[0] && emergencyLanding[1]) {
  //     handleAllElevation({
  //       lat: contingencyLandingPoint[1],
  //       lng: contingencyLandingPoint[0],
  //     });
  //     setAltitude_reference("W84");
  //   }
  // }, [contingencyLandingPoint]);

  useEffect(() => {
    if (editingElevationData) setEditingElevationData(false);
    else if (emergencyLanding[0] && emergencyLanding[1]) {
      handleAllElevation({
        lat: emergencyLanding[1],
        lng: emergencyLanding[0],
      });
      setAltitude_reference("W84");
    }
  }, [emergencyLanding]);

  useEffect(() => {
    if (!openFlightAuthorization) {
      return;
    }
    const timeCheck = departureTime > new Date(currentTime.getTime());
    if ((isInEditMode || lockVariables) && timeCheck) {
      return;
    }
    setDepartureTime(new Date(currentTime.getTime() + 3 * 60000));
    setEndTime(new Date(currentTime.getTime() + 33 * 60000));
  }, [openFlightAuthorization]);

  useEffect(() => {
    if (isAisOperation) return;
    setIfEditMode(false);
    setIfDuplicateMode(false);
    if (!operationFormRequest) {
      return;
    }
    setTabIndex(0);
    setLockedTab(-1);
    resetForm();
    if (operationFormRequest.type === "edit") {
      setIfEditMode(true);
      setEditingElevationData(true);
      // setTwoWayOperation(false);
    }
    if (operationFormRequest.type === "duplicate") {
      setIfDuplicateMode(true);
      setEditingElevationData(true);
    }

    // change tab based on operation type (route, circle, area)
    if (!Object.hasOwn(operationFormRequest?.request, "properties")) {
      onTabClicked(0, 2);
    }
    if (Object.hasOwn(operationFormRequest?.request, "properties")) {
      if (operationFormRequest?.request?.properties?.isCircle) {
        onTabClicked(0, 0);
      } else {
        onTabClicked(0, 1);
      }
    }
    const operation = operationFormRequest.request;
    setAltitude(operation.altitude);
    setSafeAltitude(operation.contingency_plans.safe_altitude);
    setTimeBuffer(operation.time_buffer);
    setElevation(operation.elevation);
    // setContingencyLandingPoint(operation.contingency_plans.landing_point[0]);
    setEmergencyLanding(operation.contingency_plans.landing_point[0]);
    mapboxController.flyTo(operation.contingency_plans.landing_point[0]);
    setOperationDescription(operation.description);
    setOperationType(operation.operation_type);
    if (operationFormRequest.type === "edit") {
      setDepartureTime(new Date(operation.time_start));
      setEndTime(new Date(operation.time_end));
    }
    setPilotID(operation.pilot_uuid);
    setPlatformID(operation.platform_uuid);
    setTrackeruuid(operation.tracker_uuid);
    const multiDroneData = operation.pilot_uuid.map((item, index, arr) => {
      let datas = [];
      datas = {
        platformid: operation.platform_uuid[index],
        pilotid: operation.pilot_uuid[index],
        trackeruuid: operation.tracker_uuid[index],
      };
      return datas;
    });
    setSelectedMultiDrone(multiDroneData);
    setSpeed(operation.ground_speed || 5);
    if ("lateral_buffer" in operation) {
      setVerticalBuffer(operation.vertical_buffer);
      setLateralBuffer(operation.lateral_buffer);
      setCustomBuffer(true);
    } else {
      setCustomBuffer(false);
    }
    if (operation.area_coordinates) {
      if (operation?.properties?.isCircle && isCircleMethod) {
        setSelectedCircleProperties(operation.properties);
        setSelectedCircleWaypoints(operation.area_coordinates);
        setSelectWaypointMethod("circle");
        setTabIndex(0);
        setLockedTab(0);
      } else if (!operation?.properties?.isCircle && isPolygonMethod) {
        setSelectedAreaWaypoints(operation.area_coordinates);
        setSelectWaypointMethod("area");
        setSelectedCircleProperties({
          isCircle: false,
        });
        setTabIndex(1);
        setLockedTab(1);
      }
    }

    if (isRouteMethod && !Object.hasOwn(operation, "properties")) {
      setSelectedWaypoints(operation.waypoints);
      setSelectWaypointMethod("route");
      setSelectedCircleProperties({
        isCircle: false,
      });

      if (operationFormRequest.type !== "edit") {
        setTwoWayOperation(operation.two_way);
      }
      setMaxSegmentLength(operation.max_segment_length);
      setTwoWayOperation(operation.two_way);
      setTransitDurationMin(Math.round(operation.transit_duration / 60));
      setTabIndex(2);
      setLockedTab(2);
    }
  }, [operationFormRequest, mapboxController]);

  const handleChangeAirspaceOptimised = (event) => {
    setAirspaceOptimised(event.target.checked);
  };

  const handleDepartureTimeChange = (item) => {
    setDepartureTime(new Date(item));
    setDepartureTimeError("");
  };

  const handleDefaultEndTime = (dt) => {
    dt.setMinutes(dt.getMinutes() + 30);
    return [format(dt, "yyyy-MM-dd"), "T", format(dt, "HH:mm")].join("");
  };

  const handleChangeTwoWayOperation = (event) => {
    setTwoWayOperation(event.target.checked);
  };

  const handleEndTimeChange = (item) => {
    setEndTime(new Date(item));
    setEndTimeError("");
  };
  if (loading) return <div />;

  const clearGnssData = () => {
    if (mapboxController.map.getLayer("operation-gnss-layer")) {
      mapboxController.map.removeLayer("operation-gnss-layer");
      mapboxController.map.removeSource("operation-gnss-coords");
    }
  };

  const handleGnssClick = async (type) => {
    const gnssCoordinates = {};
    if (type === "circle") {
      const latRaidusInDeg = selectedCircleProperties.radiusInKm / 111.32;
      const longRaidusInDeg = selectedCircleProperties.radiusInKm / 85;
      gnssCoordinates.nwData = [
        selectedCircleProperties.center[1] + latRaidusInDeg,
        selectedCircleProperties.center[0] - longRaidusInDeg,
      ];
      gnssCoordinates.neData = [
        selectedCircleProperties.center[1] + latRaidusInDeg,
        selectedCircleProperties.center[0] + longRaidusInDeg,
      ];
      gnssCoordinates.swData = [
        selectedCircleProperties.center[1] - latRaidusInDeg,
        selectedCircleProperties.center[0] - longRaidusInDeg,
      ];
      gnssCoordinates.seData = [
        selectedCircleProperties.center[1] - latRaidusInDeg,
        selectedCircleProperties.center[0] + longRaidusInDeg,
      ];
    }
    if (type === "area") {
      const allLats = [];
      const allLongs = [];
      selectedAreaWaypoints.forEach((singleWaypoint) => {
        allLats.push(singleWaypoint[1]);
        allLongs.push(singleWaypoint[0]);
      });

      const highestLat = Math.max(...allLats);
      const lowestLat = Math.min(...allLats);
      const highestLong = Math.max(...allLongs);
      const lowestLong = Math.min(...allLongs);

      gnssCoordinates.nwData = [highestLat, highestLong];
      gnssCoordinates.neData = [lowestLat, highestLong];
      gnssCoordinates.swData = [highestLat, lowestLong];
      gnssCoordinates.seData = [lowestLat, lowestLong];
    }
    if (type === "waypoint") {
      const allLats = [];
      const allLongs = [];
      selectedWaypoints.forEach((singleWaypoint) => {
        allLats.push(singleWaypoint[1]);
        allLongs.push(singleWaypoint[0]);
      });

      const highestLat = Math.max(...allLats);
      const lowestLat = Math.min(...allLats);
      const highestLong = Math.max(...allLongs);
      const lowestLong = Math.min(...allLongs);

      gnssCoordinates.nwData = [highestLat, highestLong];
      gnssCoordinates.neData = [lowestLat, highestLong];
      gnssCoordinates.swData = [highestLat, lowestLong];
      gnssCoordinates.seData = [lowestLat, lowestLong];
    }

    setLoadingGnss(true);
    // const geoJSON = await gnssCreateOperation(gnssCoordinates);
    try {
      gnssCoordinates.altitude = altitude;
      const apiResponse = await api.getGnssData(gnssCoordinates);
      const geoJSON = await gnssCreateOperation(apiResponse);
      if (geoJSON === "Error") {
        clearGnssData();
        setLoadingGnss(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "GNSS Area too large",
            severity: "error",
          })
        );
      }
      clearGnssData();
      // clear layer and source if exists  TODO
      if (geoJSON.length > 0) {
        mapboxController.map.addSource("operation-gnss-coords", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: geoJSON,
          },
        });
        mapboxController.map.addLayer({
          id: "operation-gnss-layer",
          type: "circle",
          source: "operation-gnss-coords",
          paint: {
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "weight"],
              0,
              "rgba(0, 255, 0, 0.05)",
              1,
              "rgba(75, 255, 0, 0.05)",
              2,
              "rgba(150, 255, 0, 0.05)",
              3,
              "rgba(225, 255, 0, 0.05)",
              4,
              "rgba(255, 150, 0, 0.05)",
              5,
              "rgba(255, 75, 0, 0.05)",
              6,
              "rgba(255, 0, 0, 0.05)",
            ],
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              0,
              15,
              1,
              20,
              10,
              21,
              100,
            ],
          },
        });
      }
      setLoadingGnss(false);
    } catch (e) {
      clearGnssData();
      setLoadingGnss(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "GNSS Area too large",
          severity: "error",
        })
      );
    }
  };

  const handleCustomBufferClick = (event) => {
    setCustomBuffer(event.target.checked);
  };

  const checkAssetAvailability = async () => {
    setLoadingAssetList(true);
    try {
      const upcomingOperations = await api.getActiveOperations(
        departureTime.getTime(),
        endTime.getTime(),
        true
      );
      const platformList = [];
      const trackerList = [];
      if (upcomingOperations?.status === 200) {
        if (upcomingOperations.data.length > 0) {
          upcomingOperations.data.forEach((singleOperation) => {
            if (
              operationFormRequest?.type !== "edit" &&
              singleOperation.operation_json.reference.id !==
                operationFormRequest?.id
            ) {
              trackerList.push(
                ...singleOperation.operation_json.request.tracker_uuid
              );
              platformList.push(
                ...singleOperation.operation_json.request.platform_uuid
              );
            }
          });
        }
      }
      // remove "No-Tracker" from check
      const trackerListEdited = trackerList.filter(
        (singleTracker) =>
          singleTracker !== "e1d4d10a-8615-48d6-b542-fa7dfd2238e5"
      );
      setUnavailableTrackers([...new Set(trackerListEdited)]);
      setUnavailablePlatforms([...new Set(platformList)]);
      setLoadingAssetList(false);
    } catch (e) {
      console.log("error : ", e);
      setLoadingAssetList(false);
    }
  };

  const checkAssetAvailabilityRoute = async () => {
    const haversineDistance = (lat1, lon1, lat2, lon2) => {
      const toRadians = (angle) => angle * (Math.PI / 180);

      // Convert latitude and longitude from degrees to radians
      const lat1Radians = toRadians(lat1);
      const lon1Radians = toRadians(lon1);
      const lat2Radians = toRadians(lat2);
      const lon2Radians = toRadians(lon2);

      // Calculate differences between latitudes and longitudes
      const dLat = lat2Radians - lat1Radians;
      const dLon = lon2Radians - lon1Radians;

      // Apply Haversine formula
      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1Radians) * Math.cos(lat2Radians) * Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      // Radius of the Earth in kilometers or miles
      const R = 6371; // Earth's radius in kilometers (use 3958.8 for miles)

      // Calculate the distance
      const distance = R * c;
      return distance;
    };
    let totalDistance = 0;
    for (let i = 1; i < selectedWaypoints?.length; i += 1) {
      const [lon1, lat1] = selectedWaypoints[i - 1];
      const [lon2, lat2] = selectedWaypoints[i];
      totalDistance += haversineDistance(lat1, lon1, lat2, lon2);
    }
    const secondsTaken = Math.round((totalDistance / speed) * 1000);
    const estimatedEndTime = new Date(
      departureTime.getTime() + secondsTaken * 1000
    );
    setLoadingAssetList(true);
    try {
      if (isNaN(estimatedEndTime.getTime())) return;
      const upcomingOperations = await api.getActiveOperations(
        departureTime.getTime(),
        estimatedEndTime.getTime(),
        true
      );
      const platformList = [];
      const trackerList = [];
      if (upcomingOperations?.status === 200) {
        if (upcomingOperations.data.length > 0) {
          upcomingOperations.data.forEach((singleOperation) => {
            if (operationFormRequest?.type !== "edit") {
              trackerList.push(
                ...singleOperation.operation_json.request.tracker_uuid
              );
              platformList.push(
                ...singleOperation.operation_json.request.platform_uuid
              );
            } else if (
              singleOperation.operation_json.reference.id !==
              operationFormRequest?.id
            ) {
              trackerList.push(
                ...singleOperation.operation_json.request.tracker_uuid
              );
              platformList.push(
                ...singleOperation.operation_json.request.platform_uuid
              );
            }
          });
        }
      }
      // remove "No-Tracker" from check
      const trackerListEdited = trackerList.filter(
        (singleTracker) =>
          singleTracker !== "e1d4d10a-8615-48d6-b542-fa7dfd2238e5"
      );
      setUnavailableTrackers([...new Set(trackerListEdited)]);
      setUnavailablePlatforms([...new Set(platformList)]);
      setLoadingAssetList(false);
    } catch (e) {
      console.log("error : ", e);
      setLoadingAssetList(false);
    }
  };

  const isRouteSubmitButtonDisabled = () => {
    return selectedWaypoints?.filter(([a, b]) => !a && !b).length === 2;
  };

  const isAreaSubmitButtonDisabled = () => {
    return selectedAreaWaypoints?.filter(([a, b]) => !a && !b).length === 3;
  };

  const checkAltitude = () => {
    if (altitude === null || altitude === "") {
      handleAllElevation({
        lat: emergencyLanding[1],
        lng: emergencyLanding[0],
        parameter: "altitude",
      });
    }
  };

  const checkSafeAltitude = () => {
    if (safeAltitude === null || safeAltitude === "") {
      handleAllElevation({
        lat: emergencyLanding[1],
        lng: emergencyLanding[0],
        parameter: "safeAltitude",
      });
    }
  };

  const checkElevation = () => {
    if (elevation === null || elevation === "") {
      handleAllElevation({
        lat: emergencyLanding[1],
        lng: emergencyLanding[0],
        parameter: "elevation",
      });
    }
  };

  const checkSpeed = (item) => {
    const inputValue = item.target.value;
    if (inputValue.trim() === "") {
      setSpeed(""); // Keep it as an empty string
    } else {
      const parsedValue = parseFloat(inputValue);
      if (!isNaN(parsedValue)) {
        setSpeed(parsedValue);
      } else {
        setSpeed(5);
      }
    }
  };

  const checkTimeBuffer = () => {
    if (timeBuffer === null || timeBuffer === "") {
      setTimeBuffer(30);
    } else if (timeBuffer < 0) {
      setTimeBufferError(true);
      setTimeBufferHelperText("Time Buffer cannot be negative");
    }
  };

  const checkVerticalBuffer = () => {
    if (verticalBuffer === null || verticalBuffer === "") {
      setVerticalBuffer(25);
    } else if (verticalBuffer < 0) {
      setVerticalBufferError(true);
      setVerticalBufferHelperText("Vertical Buffer cannot be negative");
    }
  };

  const checkLateralBuffer = () => {
    if (lateralBuffer === null || lateralBuffer === "") {
      setLateralBuffer(25);
    } else if (lateralBuffer < 0) {
      setLateralBufferError(true);
      setLateralBufferHelperText("Lateral Buffer cannot be negative");
    }
  };

  const checkMaxSegmentLength = () => {
    if (maxSegmentLength === null || maxSegmentLength === "") {
      setMaxSegmentLength(500);
    } else if (maxSegmentLength < 0) {
      setMaxSegmentLengthError(true);
      setMaxSegmentLengthHelperText("Max Segment Length cannot be negative");
    }
  };

  const checkTransitDuration = () => {
    if (transitDurationHour === null || transitDurationHour === "") {
      setTransitDurationHour(0);
    }
    if (transitDurationMin === null || transitDurationMin === "") {
      setTransitDurationMin(0);
    }
    if (transitDurationHour < 0 || transitDurationMin < 0) {
      setTransitDurationError(true);
      setTransitDurationErrorText("Transit Duration cannot be negative");
    }
    if (transitDurationMin >= 60) {
      setTransitDurationHour((s) => s + Math.round(transitDurationMin / 60));
      setTransitDurationMin((s) => s % 60);
    }
  };

  const handleAltRefChange = (altitudeType) => {
    if (altitudeType === "W84") {
      setAltitude((prev) => prev + elevation);
      setSafeAltitude((prev) => prev + elevation);
    } else if (altitudeType === "AGL") {
      setAltitude((prev) => prev - elevation);
      setSafeAltitude((prev) => prev - elevation);
    }
  };

  const updateRadiusInKm = (newRadius) => {
    setSelectedCircleProperties({
      ...selectedCircleProperties,
      radiusInKm: newRadius,
    });
  };

  const updateCenterLongitude = (newLongitude) => {
    setSelectedCircleProperties({
      ...selectedCircleProperties,
      center: [newLongitude, selectedCircleProperties.center[1]],
    });
  };

  const updateCenterLatitude = (newLatitude) => {
    setSelectedCircleProperties({
      ...selectedCircleProperties,
      center: [selectedCircleProperties.center[0], newLatitude],
    });
  };

  const handleSetCircleAll = (circleProperties) => {
    updateCenterLatitude(circleProperties?.center[0]);
    updateCenterLongitude(circleProperties?.center[1]);
    updateRadiusInKm(circleProperties?.radiusInKm);
  };

  const updateAreaLatitude = (newLatitude, index) => {
    setSelectedAreaWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][1] = newLatitude;
      return updatedWaypoints;
    });
  };

  const updateAreaLongitude = (newLongitude, index) => {
    setSelectedAreaWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][0] = newLongitude;
      return updatedWaypoints;
    });
  };

  const updateWaypointLatitude = (newLatitude, index) => {
    setSelectedWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][1] = newLatitude;
      return updatedWaypoints;
    });
  };

  const updateWaypointLongitude = (newLongitude, index) => {
    setSelectedWaypoints((prev) => {
      const updatedWaypoints = [...prev];
      updatedWaypoints[index][0] = newLongitude;
      return updatedWaypoints;
    });
  };

  const isSubmitButtonDisabled = () => {
    if (isCircleMethod) {
      return (
        selectedCircleWaypoints?.length < 64 ||
        lateralBufferError ||
        timeBufferError ||
        verticalBufferError
      );
    }
    if (isPolygonMethod) {
      return (
        isAreaSubmitButtonDisabled() ||
        lateralBufferError ||
        timeBufferError ||
        verticalBufferError
      );
    }
    if (isRouteMethod) {
      return (
        isRouteSubmitButtonDisabled() ||
        lateralBufferError ||
        timeBufferError ||
        verticalBufferError
      );
    }

    return false;
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Paper
        style={{
          height: `${350}px`,
          maxHeight: "calc(50% - 0px)",
          width: "100%",
          overflow: "auto",
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div
          style={{
            marginTop: "0px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          {!isAisOperation && (
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                {" "}
                DRAWING TOOLS:
              </Grid>
              {isCircleMethod && (
                <Grid item container direction="column" xs={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      mapboxController.changeDrawMode("draw_circle");
                      mapboxController.removeDrawFeatures("Polygon");
                      handleOpenFlightAuthorization();
                    }}
                  >
                    <PanoramaFishEyeIcon sx={{ color: "black" }} />
                  </Button>
                </Grid>
              )}

              {isPolygonMethod && (
                <>
                  <Grid item container direction="column" xs={2}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        mapboxController.changeDrawMode("draw_polygon");
                        mapboxController.removeDrawFeatures("Polygon");
                        handleOpenFlightAuthorization();
                      }}
                    >
                      <LabelOutlined />
                    </Button>
                  </Grid>
                  <Grid item container direction="column" xs={2}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        mapboxController.changeDrawMode("draw_rectangle");
                        mapboxController.removeDrawFeatures("Polygon");
                        handleOpenFlightAuthorization();
                      }}
                    >
                      <CropSquareOutlined />
                    </Button>
                  </Grid>
                </>
              )}

              {isRouteMethod && (
                <Grid item container direction="column" xs={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      mapboxController.changeDrawMode("draw_line_string");
                      mapboxController.removeDrawFeatures("LineString");
                      handleOpenFlightAuthorization();
                    }}
                  >
                    <TimelineOutlined />
                  </Button>
                </Grid>
              )}

              <Grid item container direction="column" xs={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    mapboxController.trash();
                  }}
                >
                  <span className="material-symbols-outlined">ink_eraser</span>

                  {/* <DeleteOutlined /> */}
                </Button>
              </Grid>
              <Grid item container direction="column" xs={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (isCircleMethod) {
                      resetCircleWaypoints();
                    } else if (isPolygonMethod) {
                      resetAreaWayPoints();
                    } else if (isRouteMethod) {
                      resetSelectedWaypoints();
                    }
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Grid>
            </Grid>
          )}
          {isCircleMethod && (
            <CircleEntries
              circleCenter={selectedCircleProperties?.center}
              radiusInKm={selectedCircleProperties?.radiusInKm}
              onChange={(updatedWaypoints, updatedProperties) => {
                setSelectedCircleWaypoints(updatedWaypoints);
                setSelectedCircleProperties(updatedProperties);
              }}
              mapboxController={mapboxController}
              onFocus={handleOnFocus}
              updateRadius={updateRadiusInKm}
              updateLongitude={updateCenterLongitude}
              updateLatitude={updateCenterLatitude}
              handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            />
          )}
          {isPolygonMethod && (
            <AreaEntries
              onFocus={handleOnFocus}
              selectedAreaWaypoints={selectedAreaWaypoints}
              onChange={(updatedAreaWaypoints) => {
                setSelectedAreaWaypoints(updatedAreaWaypoints);
              }}
              resetAreaWayPoints={resetAreaWayPoints}
              handleOpenFlightAuthorization={handleOpenFlightAuthorization}
              updateAreaLongitude={updateAreaLongitude}
              updateAreaLatitude={updateAreaLatitude}
            />
          )}
          {isRouteMethod && (
            <WaypointsEntries
              onFocus={handleOnFocus}
              selectedWaypoints={selectedWaypoints}
              onChange={(updatedWaypoints) => {
                setSelectedWaypoints(updatedWaypoints);
              }}
              handleOpenFlightAuthorization={handleOpenFlightAuthorization}
              updateWaypointLongitude={updateWaypointLongitude}
              updateWaypointLatitude={updateWaypointLatitude}
            />
          )}
          {isRouteMethod && (
            <Typography variant="body2">
              Total Distance {totalWaypointDistance} km
            </Typography>
          )}
          <EmergencyLandingPointEntry
            emergencyLandingPoint={emergencyLanding}
            onFocus={handleOnFocus}
            onChange={(points) => {
              // setContingencyLandingPoint(points);
              setEmergencyLanding(points);
            }}
            mapboxController={mapboxController}
            handleCloseLastDrawer={handleOpenFlightAuthorization}
          />
          <Grid container spacing={3} style={{ marginTop: "15px" }}>
            <Grid item xs={2}>
              <TextField
                id="elevation"
                label="Elevation (m)"
                fullWidth
                value={elevation}
                // defaultValue={0}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setElevation(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setElevation(parsedValue);
                    }
                  }
                }}
                onBlur={checkElevation}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="altitude"
                label="Max Altitude (m)"
                fullWidth
                value={altitude}
                // defaultValue={60}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setAltitude(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setAltitude(parsedValue);
                    }
                  }
                }}
                onBlur={checkAltitude}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="safeAltitude"
                label="RTH Altitude (m)"
                fullWidth
                value={safeAltitude}
                // defaultValue={60}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setSafeAltitude(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setSafeAltitude(parsedValue);
                    }
                  }
                }}
                onBlur={checkSafeAltitude}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="altitudeReference"
                label="Altitude Reference"
                fullWidth
                select
                value={altitude_reference}
                onChange={(e) => {
                  setAltitude_reference(e.target.value);
                  handleAltRefChange(e.target.value);
                }}
              >
                <MenuItem value="W84">W84</MenuItem>
                <MenuItem value="AGL">AGL</MenuItem>
              </TextField>
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "space-around",
                alignItems: "end",
              }}
            >
              <LoadingOverlay
                spinner
                active={loadingGnss}
                styles={{
                  spinner: (base) => ({
                    ...base,
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "20px", // Change the size of the spinner here as well
                    height: "20px", // Change the size of the spinner here as well
                  }),
                }}
              >
                <Button // operation gnss data
                  variant="outlined"
                  disabled={selectedCircleWaypoints?.length < 64}
                  onClick={() => {
                    if (isCircleMethod) handleGnssClick("circle");
                    if (isPolygonMethod) handleGnssClick("area");
                    if (isRouteMethod) handleGnssClick("waypoint");
                  }}
                >
                  <SatelliteAltIcon />
                </Button>
              </LoadingOverlay>
            </Grid>

            <Grid item xs={2} style={{ marginTop: "15px" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "space-around",
                  alignItems: "end",
                }}
              >
                {" "}
              </div>
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="vertical-buffer"
                label="Vertical Buffer (m)"
                fullWidth
                value={verticalBuffer}
                // defaultValue={25}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setVerticalBuffer(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setVerticalBuffer(parsedValue);
                    }
                  }
                  setVerticalBufferError(false);
                  setVerticalBufferHelperText("");
                }}
                onBlur={checkVerticalBuffer}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="number"
                disabled={!customBuffer}
                error={verticalBufferError}
                helperText={verticalBufferHelperText}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="lateral-buffer"
                label="Lateral Buffer (m)"
                fullWidth
                value={lateralBuffer}
                // defaultValue={25}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setLateralBuffer(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setLateralBuffer(parsedValue);
                    }
                  }
                  setLateralBufferError(false);
                  setLateralBufferHelperText("");
                }}
                onBlur={checkLateralBuffer}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="number"
                disabled={!customBuffer}
                error={lateralBufferError}
                helperText={lateralBufferHelperText}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="time-buffer"
                type="number"
                label="Time Buffer (s)"
                fullWidth
                value={timeBuffer}
                // defaultValue={30}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setTimeBuffer(""); // Keep it as an empty string
                  } else {
                    const parsedValue = parseFloat(inputValue);
                    if (!isNaN(parsedValue)) {
                      setTimeBuffer(parsedValue);
                    }
                  }
                  setTimeBufferError(false);
                  setTimeBufferHelperText("");
                }}
                onBlur={checkTimeBuffer}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                error={timeBufferError}
                helperText={timeBufferHelperText}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="speed"
                label="Speed (m/s)"
                fullWidth
                value={isRouteMethod ? speed : "NA"}
                // defaultValue={isRouteMethod ? speed : "NA"}
                onChange={(item) => {
                  const inputValue = item.target.value;
                  if (inputValue.trim() === "") {
                    setSpeed(""); // Keep it as an empty string
                  } else {
                    setSpeed(inputValue);
                  }
                }}
                onBlur={(item) => checkSpeed(item)}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
                type="text"
                disabled={!isRouteMethod}
              />
            </Grid>
            {isRouteMethod && (
              <Grid item xs={2}>
                <TextField
                  id="max-segment-length"
                  label="Max Segment Length (m)"
                  fullWidth
                  value={maxSegmentLength}
                  //  defaultValue={500}
                  // onChange={(item) =>
                  //   setMaxSegmentLength(parseFloat(item.target.value))
                  // }

                  onChange={(item) => {
                    const inputValue = item.target.value;
                    if (inputValue.trim() === "") {
                      setMaxSegmentLength(""); // Keep it as an empty string
                    } else {
                      const parsedValue = parseFloat(inputValue);
                      if (!isNaN(parsedValue)) {
                        setMaxSegmentLength(parsedValue);
                      }
                    }
                    setMaxSegmentLengthError(false);
                    setMaxSegmentLengthHelperText("");
                  }}
                  onBlur={checkMaxSegmentLength}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  type="number"
                  error={maxSegmentLengthError}
                  helperText={maxSegmentLengthHelperText}
                />
              </Grid>
            )}
            {!isRouteMethod && (
              <Grid item xs={2}>
                {" "}
              </Grid>
            )}

            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customBuffer}
                    onChange={handleCustomBufferClick}
                    name="custom_buffer"
                  />
                }
                label={
                  <Typography variant="caption" style={{ fontSize: "10px" }}>
                    Custom Buffer
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Departure Time (Local Time)"
                  value={departureTime}
                  onChange={(newValue) => setDepartureTime(newValue)}
                  views={["day", "month", "year", "hours", "minutes"]}
                  onClose={checkAssetAvailability}
                  format="dd-MM-yyyy hh:mm aa"
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    textField: {
                      onBlur: checkAssetAvailability,
                    },

                    popper: {
                      sx: {
                        "& .MuiMultiSectionDigitalClock-root": {
                          maxHeight: "330px",
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                />
              </LocalizationProvider>
              {/* <TextField
                      id="start-time"
                      label="Start Time (Local Time)"
                      type="datetime-local"
                      value={[
                        format(departureTime, "yyyy-MM-dd"),
                        "T",
                        format(departureTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onChange={(item) =>
                        handleDepartureTimeChange(item.target.value)
                      }
                      onBlur={checkAssetAvailability}
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    /> */}
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Time (Local Time)"
                  disabled={isRouteMethod}
                  value={endTime}
                  onChange={(newValue) => setEndTime(newValue)}
                  views={["day", "month", "year", "hours", "minutes"]}
                  onClose={checkAssetAvailability}
                  format="dd-MM-yyyy hh:mm aa"
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    textField: { onBlur: checkAssetAvailability },

                    popper: {
                      sx: {
                        "& .MuiMultiSectionDigitalClock-root": {
                          maxHeight: "330px",
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                />
              </LocalizationProvider>
              {/* <TextField
                      id="end-time"
                      label="End Time (Local Time)"
                      type="datetime-local"
                      // defaultValue={handleDefaultEndTime(new Date())}
                      value={[
                        format(endTime, "yyyy-MM-dd"),
                        "T",
                        format(endTime, "HH:mm"),
                      ].join("")}
                      fullWidth
                      onBlur={checkAssetAvailability}
                      onChange={(item) =>
                        handleEndTimeChange(item.target.value)
                      }
                      error={!!endTimeError}
                      helperText={endTimeError}
                    /> */}
            </Grid>
            {!isRouteMethod && (
              <MultipleDrone
                availablePlatforms={availablePlatforms}
                availableTracker={availableTracker}
                platformid={platformid}
                setPlatformID={setPlatformID}
                pilotid={pilotid}
                availablePilots={availablePilots}
                setPilotID={setPilotID}
                selectedMultiDrone={selectedMultiDrone}
                setSelectedMultiDrone={setSelectedMultiDrone}
                selectWaypointMethod={selectWaypointMethod}
                setTrackeruuid={setTrackeruuid}
                unavailablePlatforms={unavailablePlatforms}
                unavailableTrackers={unavailableTrackers}
                loadingAssetList={loadingAssetList}
              />
            )}
            {isRouteMethod && (
              <>
                <Grid item xs={3}>
                  <LoadingOverlay spinner active={loadingAssetList}>
                    <TextField
                      id="platformRoute"
                      label="Platform Callsign"
                      fullWidth
                      select
                      value={platformid[0] || ""}
                      onChange={(item) => {
                        setPlatformID([item.target.value]);
                      }}
                    >
                      {availablePlatforms.map((option) => (
                        <MenuItem
                          key={option.platform_uuid}
                          value={option.platform_uuid}
                          disabled={unavailablePlatforms.includes(
                            option.platform_uuid
                          )}
                        >
                          {option.platform_callsign}{" "}
                          {unavailablePlatforms.includes(option.platform_uuid)
                            ? "(Unavailable)"
                            : ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </LoadingOverlay>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="pilotRoute"
                    label="Pilot Name"
                    fullWidth
                    select
                    defaultValue=""
                    value={pilotid[0] || ""}
                    onChange={(item) => {
                      setPilotID([item.target.value]);
                    }}
                  >
                    {availablePilots.map((option) => (
                      <MenuItem
                        key={option.pilot_uuid}
                        value={option.pilot_uuid}
                      >
                        {option.pilot_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <LoadingOverlay spinner active={loadingAssetList}>
                    <TextField
                      id="tracker"
                      name="trackeruuid"
                      label="Tracker Callsign"
                      fullWidth
                      select
                      defaultValue=""
                      value={trackeruuid[0] || ""}
                      onChange={(item) => {
                        setTrackeruuid([item.target.value]);
                      }}
                    >
                      {availableTracker.map((option) => (
                        <MenuItem
                          key={option.tracker_uuid}
                          value={option.tracker_uuid}
                          disabled={unavailableTrackers.includes(
                            option.tracker_uuid
                          )}
                        >
                          {option.tracker_name}{" "}
                          {unavailableTrackers.includes(option.tracker_uuid)
                            ? "(Unavailable)"
                            : ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </LoadingOverlay>
                </Grid>
                <Grid style={{ margin: 0, padding: 0 }}>
                  <Grid
                    item
                    xs={1}
                    style={{ marginBottom: 20, padding: 0, height: 10 }}
                  >
                    <ListItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={airspaceOptimised}
                            onChange={handleChangeAirspaceOptimised}
                            name="airspace_optimised"
                          />
                        }
                        label={
                          <Typography
                            variant="caption"
                            style={{ fontSize: "10px" }}
                          >
                            Optimised
                          </Typography>
                        }
                        style={{ height: "auto" }}
                      />
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ marginTop: 20, padding: 0, height: 10 }}
                  >
                    <ListItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={twoWayOperation}
                            onChange={handleChangeTwoWayOperation}
                            disabled={isInEditMode}
                            name="two_way_operation"
                          />
                        }
                        label={
                          <Typography
                            variant="caption"
                            style={{
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Two Way
                          </Typography>
                        }
                        style={{ height: "auto" }}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </>
            )}
            {twoWayOperation && (
              <>
                <Grid item xs={4}>
                  <h4 style={{ textAlign: "center" }}>Transit Duration :</h4>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="transit-duration-hours"
                    label="Transit Duration(h)"
                    fullWidth
                    value={transitDurationHour}
                    onChange={(item) => {
                      const inputValue = item.target.value;
                      if (inputValue.trim() === "") {
                        setVerticalBuffer(""); // Keep it as an empty string
                      } else {
                        const parsedValue = parseFloat(inputValue);
                        if (!isNaN(parsedValue)) {
                          setTransitDurationHour(parsedValue);
                        }
                      }
                      setTransitDurationError(false);
                      setTransitDurationErrorText("");
                    }}
                    onBlur={checkTransitDuration}
                    type="number"
                    error={transitDurationError}
                    helperText={transitDurationErrorText}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="transit-duration-mins"
                    label="Transit Duration(m)"
                    fullWidth
                    value={transitDurationMin}
                    onChange={(item) => {
                      const inputValue = item.target.value;
                      if (inputValue.trim() === "") {
                        setVerticalBuffer(""); // Keep it as an empty string
                      } else {
                        const parsedValue = parseFloat(inputValue);
                        if (!isNaN(parsedValue)) {
                          setTransitDurationMin(parsedValue);
                        }
                      }
                      setTransitDurationError(false);
                      setTransitDurationErrorText("");
                    }}
                    onBlur={checkTransitDuration}
                    type="number"
                    error={transitDurationError}
                    helperText={transitDurationErrorText}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={8}>
              <TextField
                id="operationDescription"
                label="Operation Intent"
                fullWidth
                required
                value={operationDescription}
                onChange={(item) => {
                  setOperationDescription(item.target.value);
                  setOperationIntentError(false);
                  setOperationIntentHelperText("");
                }}
                type="string"
                error={operationIntentError}
                helperText={operationIntentHelperText}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="type"
                label="Type"
                fullWidth
                select
                value={operationType || ""}
                onChange={(item) => setOperationType(item.target.value)}
              >
                {TypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="operationDescription"
                label="Description (Optional)"
                fullWidth
                value={operationDescription}
                onChange={(item) => setOperationDescription(item.target.value)}
                type="string"
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
      <div className={classesFloating.buttonFloating}>
        <div style={{ marginTop: "25px" }}>
          {isInEditMode || ifDuplicateMode ? (
            <Button
              type="button"
              size="small"
              variant="contained"
              color="secondary"
              fullWidth
              // disabled={!isInEditMode} // should not be necessary anymore
              onClick={handleDiscardEdit}
            >
              Reset Form{" "}
            </Button>
          ) : (
            <Button
              type="button"
              size="small"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={resetForm}
            >
              Reset Form{" "}
            </Button>
          )}
        </div>
        {!isAisOperation && (
          <div style={{ marginTop: "10px" }}>
            <Button
              component="label"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
            >
              Import Operation
              <input type="file" onChange={handleImport} hidden />
            </Button>
          </div>
        )}
        <div style={{ marginTop: "10px" }}>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitButtonDisabled()}
          >
            Submit Operation
          </Button>
        </div>
      </div>
      <RejectAcceptOperationResponsiveDialog
        reScheduled={reScheduled}
        reRouted={reRouted}
        openReject={openReject}
        handleClose={handleClose}
        compareCurrentData={compareCurrentData}
        compareData={compareData}
        twoWayOperationUuid={twoWayOperationUuid}
        handleDiscardTwoWayOperation={handleDiscardTwoWayOperation}
        setRoutedSchedEdit={setRoutedSchedEdit}
        setIfEditMode={setIfEditMode}
      />
    </form>
  );
}

export default memo(CreateOperationFormComponent);
