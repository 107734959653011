import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={!!fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage}
    />
  );
}

export default function AddPlatformFormComponent({
  open,
  platformData,
  updating,
  handleClose,
  platformTypes,
  handlePlatformUpdate,
}) {
  const [callsign, setCallsign] = React.useState("");
  const [callsignError, setCallsignError] = React.useState("");

  const [remotecontroller, setRemoteController] = React.useState("");
  const [remotecontrollerError, setRemoteControllerError] = React.useState("");

  const [flightcontroller, setFlightController] = React.useState("");
  const [flightcontrollerError, setFlightControllerError] = React.useState("");

  const [registration, setRegistration] = React.useState("");
  const [registrationError, setRegistrationError] = React.useState("");

  const [puckID, setPuckID] = React.useState("");
  const [puckIDError, setPuckIDError] = React.useState("");

  const [expiry, setExpiry] = React.useState(new Date());
  const [expiryError, setExpiryError] = React.useState("");

  const [platformType, setPlatformType] = React.useState("");
  const [submittingNewPlatform, setSubmittingNewPlatform] =
    React.useState(false);

  const [selectedPlatformID, setSelectedPlatformID] = React.useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (updating) {
      const {
        id,
        platform_callsign,
        platform_registration,
        // puck_uuid,
        platform_type_uuid,
        registration_expiry,
        remote_controller_sn,
        flight_controller_sn,
      } = platformData;
      console.log(updating);
      setSelectedPlatformID(id);
      setCallsign(platform_callsign);
      setRegistration(platform_registration);
      // setPuckID(puck_uuid);
      setPlatformType(platform_type_uuid);
      setExpiry(registration_expiry);
      setRemoteController(remote_controller_sn);
      setFlightController(flight_controller_sn);
    } else {
      setSelectedPlatformID("");
      setCallsign("");
      setRegistration("");
      // setPuckID("");
      setExpiry(new Date());
      setPlatformType("");
      setRemoteController("");
      setFlightController("");
    }
    setCallsignError("");
    setRegistrationError("");
    // setPuckIDError("");
    setExpiryError("");
    setRemoteControllerError("");
    setFlightControllerError("");
  }, [open]);
  const api = useApi();

  const parseStrToUUIDFormat = (_puckID) => {
    const puckStr = _puckID.replaceAll("-", "");
    const strLength = puckStr.length;
    if (strLength > 32) {
      return [true, "Too many characters to parse to UUID format!", null];
    }
    const zero = "0";
    const charsShort = zero.repeat(32 - strLength);
    const UUIDStr = puckStr.concat(charsShort);
    // `${UUIDStr.slice(0, 8)
    //   }-${
    //   UUIDStr.slice(8, 12)
    //   }-${
    //   UUIDStr.slice(12, 16)
    //   }-${
    //   UUIDStr.slice(16, 20)
    //   }-${
    //   UUIDStr.slice(20)}`;
    // console.log(`Parsed UUID: ${  UUIDStr}`);
    return [false, null, UUIDStr];
  };
  const submitNewPlatform = async () => {
    // let puckUUID = puckID + "00000000000000000";
    // puckUUID =
    //   puckUUID.slice(0, 8) +
    //   "-" +
    //   puckUUID.slice(8, 12) +
    //   "-" +
    //   puckUUID.slice(12, 16) +
    //   "-" +
    //   puckUUID.slice(16, 20) +
    //   "-" +
    //   puckUUID.slice(20);
    // const [parseErr, errMsg, puckUUID] = parseStrToUUIDFormat(puckID);

    try {
      const response = await api.submitNewPlatform({
        callsign,
        registration,
        expiry,
        // puckUUID,
        platformType,
        remotecontroller,
        flightcontroller,
      });
      if (response.data) {
        handlePlatformUpdate(response.data);
        setSubmittingNewPlatform(false);
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Platform successfully registered",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewPlatform(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: err.response.message,
          severity: "error",
        })
      );
    }
  };

  const updatePlatformInfo = async () => {
    try {
      const response = await api.updatePlatformInfo({
        ...platformData,
        callsign,
        registration,
        expiry,
        // puckID,
        platformType,
        remotecontroller,
        flightcontroller,
      });
      if (response.data) {
        handlePlatformUpdate(response.data);
        setSubmittingNewPlatform(false);
        dispatch(
          setSnackbarMessage({
            message: "Platform successfully registered",
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewPlatform(false);
      dispatch(
        setSnackbarMessage({
          message: "Unable to register platform",
          severity: "error",
        })
      );
    }
  };

  const validateForm = () => {
    let error = false;
    if (!callsign) {
      setCallsignError("Callsign is required");
      error = true;
    }
    if (!registration) {
      setRegistrationError("Registration number is required");
      error = true;
    }
    if (!remotecontroller) {
      setRemoteControllerError("Remote Contoller is required");
      error = true;
    }
    if (!flightcontroller) {
      setFlightControllerError("Flight Contoller is required");
      error = true;
    }
    // if (!puckID) {
    //   setPuckIDError("Platform ID is required");
    //   error = true;
    // } else {
    //   const [parseErr, errMsg, puckUUID] = parseStrToUUIDFormat(puckID);
    //   if (parseErr) {
    //     setPuckIDError(errMsg);
    //     error = true;
    //   }
    // }
    if (!expiry) {
      setExpiryError("Platform registration expiry date is required");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewPlatform(true);
    if (updating) {
      updatePlatformInfo();
    } else {
      submitNewPlatform();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <LoadingOverlay
        active={submittingNewPlatform}
        spinner
        text={
          updating
            ? "Updating platform information..."
            : "Submitting new platform..."
        }
      >
        <div style={{ height: "480px", width: "500px", margin: "30px" }}>
          <Typography component="div" display="inline" variant="h6">
            {updating ? "Update Platform" : "Add Platform"}
          </Typography>
          <CustomTextField
            label="Call Sign"
            value={callsign}
            setFuncton={setCallsign}
            fullWidth
            errormessage={callsignError}
            setError={setCallsignError}
          />
          {/* <CustomTextField
            label="Tracker IMEI"
            value={puckID}
            setFuncton={setPuckID}
            fullWidth
            errormessage={puckIDError}
            setError={setPuckIDError}
          /> */}
          <CustomTextField
            label="Remote Controller s/n"
            value={remotecontroller}
            setFuncton={setRemoteController}
            fullWidth
            errormessage={remotecontrollerError}
            setError={setRemoteControllerError}
          />
          <CustomTextField
            label="Flight Controller s/n"
            value={flightcontroller}
            setFuncton={setFlightController}
            fullWidth
            errormessage={flightcontrollerError}
            setError={setFlightControllerError}
          />
          <CustomTextField
            label="Registration No."
            value={registration}
            setFuncton={setRegistration}
            fullWidth={false}
            errormessage={registrationError}
            setError={setRegistrationError}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              style={{ marginLeft: "8px" }}
              id="Expiry-Date"
              label="Expiry Date"
              format="MM/dd/yyyy"
              value={expiry}
              errormessage={expiryError}
              onChange={(event) => {
                setExpiry(event);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            id="platformType"
            label="Platform Type"
            select
            value={platformType}
            fullWidth
            onChange={(event) => {
              setPlatformType(event.target.value);
            }}
            margin="normal"
            autoComplete="off"
            required
          >
            {platformTypes.map((option) => (
              <MenuItem
                key={option.platform_type_uuid}
                value={option.platform_type_uuid}
              >
                {option.model}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
