const ADSBDataPaid = (state = { visible: false, payload: [] }, action) => {
  switch (action.type) {
    case "SET_ADSB_DATA_PAID": {
      return {
        visible: true,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ADSBDataPaid;
