import React from "react";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Radio from "@mui/material/Radio";
import LoadingOverlay from "react-loading-overlay";
import { format } from "date-fns";
import { useApi } from "../../api/useApi";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errorMessage,
  helperText,
  setError,
  type,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      type={type}
      onChange={(event) => {
        if (errorMessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={errorMessage}
      helperText={errorMessage || helperText}
    />
  );
}

export default function MaintenanceReportForm({
  platform_uuid,
  platform_callsign,
  handleClose,
  defectField,
  maintenanceRectificationField,
  maintenanceConductorField,
  maintenancePurposeField,
  dateField,
  record_uuid,
  updating,
  handleCancel,
}) {
  const [name, setName] = React.useState("");
  // const [nameError, setNameError] = React.useState(""); // Check if created by field required else remove
  const [defect, setDefect] = React.useState(defectField);
  const [defectError, setDefectError] = React.useState("");
  const [maintenanceRectification, setMaintenanceRectification] =
    React.useState(maintenanceRectificationField);
  const [maintenanceRectificationError, setMaintenanceRectificationError] =
    React.useState("");
  const [maintenanceConductor, setMaintenanceConductor] = React.useState(
    maintenanceConductorField
  );
  const [maintenanceConductorError, setMaintenanceConductorError] =
    React.useState("");
  const [maintenancePurpose, setMaintenancePurpose] = React.useState(
    maintenancePurposeField || "Scheduled"
  );
  const [date, setDate] = React.useState(
    dateField || format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );
  const [activeOverlay, setActiveOverlay] = React.useState(false);

  const validateForm = () => {
    let error = false;
    // if (!name) {
    //   setNameError("Reporter name is required");
    //   error = true;
    // }
    if (!defect) {
      setDefectError("Platform Defect is required");
      error = true;
    }
    if (!maintenanceRectification) {
      setMaintenanceRectificationError("Rectification done is required");
      error = true;
    }
    if (!maintenanceConductor) {
      setMaintenanceConductorError("Technician Name is required");
      error = true;
    }
    return error;
  };

  const api = useApi();

  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (formErrors) return;
    if (updating) {
      setActiveOverlay(true);
      try {
        await api.updateMaintenance(record_uuid, {
          platform_id: platform_uuid,
          maintenance_date: date,
          purpose: maintenancePurpose,
          defect,
          rectification: maintenanceRectification,
          conducted_by: maintenanceConductor,
          // creator_id: name,
          // time_created: new Date().toISOString().split(".")[0],
        });
        window.alert("Platform Maintenance Updated");
        // handleClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await api.submitNewMaintenance({
          platform_id: platform_uuid,
          maintenance_date: date,
          purpose: maintenancePurpose,
          defect,
          rectification: maintenanceRectification,
          conducted_by: maintenanceConductor,
          // creator_id: name,
          // time_created: new Date().toISOString().split(".")[0],
        });
        // if (response.data) {
        //   console.log(response.data);
        // }
        window.alert("Platform Maintenance Added");
      } catch (err) {
        console.log(err);
      }
    }

    // To Clear Form, uncomment once export done
    setName("");
    setDefect("");
    setMaintenanceRectification("");
    setMaintenanceConductor("");
    setMaintenancePurpose("Scheduled");
    //   if (updating) {
    //     handleClose();
    //     window.alert("Platform Maintenance Updated");
    //   } else {
    //     window.alert("Platform Maintenance Added");
    //   }
    setActiveOverlay(false);
    handleClose();
  };

  const handleChange = (event) => {
    setMaintenancePurpose(event.target.value);
  };

  return (
    <div
      style={{
        // display: "list-item",
        // flexDirection: "row",
        position: "relative",
        width: "500px",
        height: "500px",
        // overflow: "scroll",
      }}
    >
      <LoadingOverlay active={activeOverlay} spinner>
        <Grid
          style={{
            // display: "list-item",
            // flexDirection: "row",
            position: "absolute",
            width: "500px",
            // overflow: "scroll",
          }}
        >
          <Typography
            variant="h6"
            style={{
              marginBottom: "5px",
            }}
          >
            {updating ? "Update Maintenance" : ""}
          </Typography>

          <Typography
            style={{
              marginTop: "5px",
              marginLeft: "10px",
            }}
            variant="caption"
          >
            Platform Callsign : <b>{platform_callsign}</b>
          </Typography>
          <br />
          <Typography
            style={{
              marginTop: "5px",
              marginLeft: "10px",
            }}
            variant="caption"
          >
            Maintenance Purpose :
          </Typography>
          <Radio
            checked={maintenancePurpose === "Scheduled"}
            onChange={handleChange}
            value="Scheduled"
          />
          <Typography variant="caption">Scheduled </Typography>
          <Radio
            checked={maintenancePurpose === "Unscheduled"}
            onChange={handleChange}
            value="Unscheduled"
          />
          <Typography variant="caption">Unscheduled </Typography>
          <CustomTextField
            id="maintenanceDate"
            label="Maintenance Date"
            type="datetime-local"
            fullWidth
            value={date}
            helperText="Date of Maintenance"
            setFuncton={setDate}
          />
          <CustomTextField
            id="defect"
            label="Defect"
            fullWidth
            value={defect}
            setFuncton={setDefect}
            errorMessage={defectError}
            helperText="Description of Defect Found"
            setError={setDefectError}
          />
          <CustomTextField
            id="rectification"
            label="Rectification"
            fullWidth
            value={maintenanceRectification}
            setFuncton={setMaintenanceRectification}
            errorMessage={maintenanceRectificationError}
            helperText="Description of Rectification Actions Taken."
            setError={setMaintenanceRectificationError}
          />
          <CustomTextField
            id="conducted_by"
            label="Maintenance Technician"
            fullWidth
            value={maintenanceConductor}
            setFuncton={setMaintenanceConductor}
            errorMessage={maintenanceConductorError}
            helperText="Person who conducted maintenance"
            setError={setMaintenanceConductorError}
          />
          {/* <CustomTextField
            id="reporterName"
            label="Reported By"
            fullWidth
            value={name}
            setFuncton={setName}
            errorMessage={nameError}
            helperText="Reporter's Name"
            setError={setNameError}
          /> */}
          <MuiDialogActions>
            <Button
              autoFocus
              onClick={() => {
                if (updating) {
                  handleCancel();
                } else {
                  handleClose();
                }
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button autoFocus onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </MuiDialogActions>
        </Grid>
      </LoadingOverlay>
    </div>
  );
}
