import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { downloadMultipleIncidentReport } from "../../services/json";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";

const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");

function descendingComparator(a, b, orderBy) {
  let comp1;
  let comp2;
  if (orderBy === "title") {
    comp1 = a.title;
    comp2 = b.title;
  } else if (orderBy === "description") {
    comp1 = a.description;
    comp2 = b.description;
  } else if (orderBy === "pilotName") {
    comp1 = a.pilotName;
    comp2 = b.pilotName;
  } else if (orderBy === "name") {
    comp1 = a.name;
    comp2 = b.name;
  } else if (orderBy === "incidentDate") {
    comp1 = a.incidentDate;
    comp2 = b.incidentDate;
  } else if (orderBy === "flightno") {
    comp1 = a.flightno;
    comp2 = b.flightno;
  } else if (orderBy === "aircraftRegistration") {
    comp1 = a.aircraftRegistration;
    comp2 = b.aircraftRegistration;
  } else if (orderBy === "aircraftModel") {
    comp1 = a.aircraftModel;
    comp2 = b.aircraftModel;
  } else if (orderBy === "reportId") {
    comp1 = a.reportId;
    comp2 = b.reportId;
  } else {
    comp1 = a.reportDate;
    comp2 = b.reportDate;
  }

  if (comp2 < comp1) {
    return -1;
  }
  if (comp2 > comp1) {
    return 1;
  }
  return 0;
}

/**
 * Gets the corresponding comparator to use for ordering
 * @param {String} order 'asc' or 'desc'
 * @param {String} orderBy Field to order by
 * @returns Comparator to use for comparison
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Custom sorting algorithm for backwards compatibility with IE11
 * @param {Array} array Array to sort
 * @param {*} comparator Comparator to use for sorting
 * @returns Sorting order
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Copy enhanced table head from ActionMessagesTable to edit
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    data,
    selectedOperations,
    setSelectedOperations,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            checked={data.length && selectedOperations.length === data.length}
            onChange={(evt, checked) => {
              if (checked) {
                setSelectedOperations(data);
              } else {
                setSelectedOperations([]);
              }
            }}
          />
        </TableCell>
        {columns.map((headCell) =>
          headCell.id !== "message" ? (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              width={headCell.minWidth}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align="center"
              width={headCell.minWidth}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function ActionMessagesTable(props) {
  const [selectedOperations, setSelectedOperations] = React.useState([]);
  const [activeOverlay, setActiveOverlay] = React.useState(false);
  const [downloadData, setDownloadData] = React.useState([]);
  const [data, setData] = React.useState([
    {
      reportId: "ABC0001",
      title: "Sample Report",
      description: "Sample Incident Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "23/9/2022",
      incidentDate: "2022-09-23T03:07:46",
      flightno: "7e3028e3-7e9b-441b-bfaa-b0eafa003acb",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
    {
      reportId: "ABC0002",
      title: "Sample Report 2",
      description: "Sample Incident 2 Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "24/9/2022",
      incidentDate: "2022-09-24T03:07:46",
      flightno: "7e3028e3-7e9b-441b-bfaa-b0eafa003acb",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
    {
      reportId: "ABC0003",
      title: "Sample Report 3",
      description: "Sample Incident 2 Report for Testing",
      pilotName: "Des-Demo-Pilot-1",
      name: "Desmond",
      reportDate: "24/9/2022",
      incidentDate: "2022-06-10T03:07:46",
      flightno: "606349f4-c4ff-4b27-9b4d-539c24a09dbe",
      aircraftRegistration: "AA1111",
      aircraftModel: "DJI M300",
    },
  ]);

  const columns = [
    { id: "reportId", label: "Report ID" },
    { id: "title", label: "Title" },
    { id: "description", label: "Description" },
    { id: "pilotName", label: "Pilot Name" },
    { id: "name", label: "Reported By" },
    { id: "reportDate", label: "Date Reported" },
    { id: "incidentDate", label: "Incident Date" },
    { id: "flightno", label: "Operation ID" },
    { id: "aircraftRegistration", label: "Aircraft Registration" },
    { id: "aircraftModel", label: "Aircraft Model" },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("reportDate");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const usePaginationActionsStyles = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    icon: {
      color: "black",
    },
  }));

  const classes = usePaginationActionsStyles();
  // const theme = useTheme();

  const api = useApi();

  const getData = async () => {
    const pilotList = await api.getPilots();
    const operations = [];

    // // Pilot cannot access operator created operation, so use getPilotOperations for pilots
    // if (auth.isPilot) {
    //   const user_uuid = pilotList.data.filter((singlePilot) => {
    //     return singlePilot.pilot_email === auth.userInfo.email;
    //   });
    //   response = await api.getPilotOperations(user_uuid[0].pilot_uuid);
    // } else {
    //   response = await api.getOperations(true);
    // }

    data.forEach(async (singleOperation) => {
      const response = await api.getOperationData({
        operationID: singleOperation.flightno,
      });
      if (!response.data) return;
      const platformList = await api.getPlatforms();
      const platformTypeList = await api.getPlatformTypes();
      const logState = await api.getLogState();

      const logStateGrouped = logState.data.reduce((acc, log) => {
        const { operation_uuid } = log;
        acc[operation_uuid] = acc[operation_uuid] ?? [];
        if (log.operation_state === "Activated") {
          acc[operation_uuid].takeoff_time = log.event_time;
        } else if (log.operation_state === "Ended") {
          acc[operation_uuid].landing_time = log.event_time;
        }
        acc[operation_uuid].takeoff_time ??= 0;
        acc[operation_uuid].landing_time ??= 0;
        return acc;
      }, {});

      const flightDataEdited = response.data.operation_json;
      flightDataEdited.incidentReport = singleOperation;
      flightDataEdited.details.pilot_name = "Unknown";
      flightDataEdited.details.platform_type = "Unknown";
      flightDataEdited.details.platform_name = "Unknown";
      flightDataEdited.details.takeoff_time = "Unknown";
      flightDataEdited.details.landing_time = "Unknown";
      // Take Off and Landing Timings
      if (logStateGrouped[flightDataEdited.reference.id]) {
        flightDataEdited.details.takeoff_time =
          logStateGrouped[flightDataEdited.reference.id].takeoff_time;
        flightDataEdited.details.landing_time =
          logStateGrouped[flightDataEdited.reference.id].landing_time;
      }

      // If no pilots created
      if (pilotList.data) {
        // Check pilot name from uuid
        const pilotNameArr = [];
        pilotList.data.forEach((singlePilot) => {
          // Only works when put in variable to compare
          const flightDataPilotUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.pilot_uuid;
          if (typeof flightDataPilotUuid === "object") {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            flightDataPilotUuid.forEach((flightDataPilotUuidSingle) => {
              if (flightDataPilotUuidSingle === pilotListPilotUuid) {
                pilotNameArr.push(singlePilot.pilot_name);
                // Break does not work with .ForEach
              }
            });
            flightDataEdited.details.pilot_name = pilotNameArr;
          } else {
            const pilotListPilotUuid = singlePilot.pilot_uuid;
            if (flightDataPilotUuid === pilotListPilotUuid) {
              flightDataEdited.details.pilot_name = [singlePilot.pilot_name];
              // Break does not work with .ForEach
            }
          }
        });
      }
      // If no platform created
      if (platformList.data) {
        const platformUuidArr = [];
        const platformTypeArr = [];
        // Check Platform name from uuid
        platformList.data.forEach((singlePlatform) => {
          // Only works when put in variable to compare
          const flightDataPlatformUuid =
            typeof flightDataEdited.request === "undefined"
              ? "Unknown"
              : flightDataEdited.request.platform_uuid;
          const pilotListPlatformUuid = singlePlatform.platform_uuid;
          if (typeof flightDataPlatformUuid === "object") {
            flightDataPlatformUuid.forEach((flightDataPlatformUuidSingle) => {
              if (flightDataPlatformUuidSingle === pilotListPlatformUuid) {
                platformUuidArr.push(singlePlatform.platform_callsign);
                // Break does not work with .ForEach
                const flightDataPlatformTypeUuid =
                  singlePlatform.platform_type_uuid;

                // If no platformType created
                if (platformTypeList.data) {
                  // Check Platform Type from uuid
                  platformTypeList.data.forEach((singlePlatformType) => {
                    // Only works when put in variable to compare
                    const pilotListPlatformTypeUuid =
                      singlePlatformType.platform_type_uuid;
                    if (
                      flightDataPlatformTypeUuid === pilotListPlatformTypeUuid
                    ) {
                      platformTypeArr.push(singlePlatformType.model);
                      // Break does not work with .ForEach
                    }
                  });
                }
              }
            });
            flightDataEdited.details.platform_name = platformUuidArr;
            flightDataEdited.details.platform_type = platformTypeArr;
          } else if (flightDataPlatformUuid === pilotListPlatformUuid) {
            flightDataEdited.details.platform_name =
              singlePlatform.platform_callsign;
            // Break does not work with .ForEach
            const flightDataPlatformTypeUuid =
              singlePlatform.platform_type_uuid;

            // If no platformType created
            if (platformTypeList.data) {
              // Check Platform Type from uuid
              platformTypeList.data.forEach((singlePlatformType) => {
                // Only works when put in variable to compare
                const pilotListPlatformTypeUuid =
                  singlePlatformType.platform_type_uuid;
                if (flightDataPlatformTypeUuid === pilotListPlatformTypeUuid) {
                  flightDataEdited.details.platform_type =
                    singlePlatformType.model;
                  // Break does not work with .ForEach
                }
              });
            }
          }
        });
      }
      // Obtain tracker data
      const pollPuckForTelemetry = async (trackerId, operationId) => {
        const [puckErr, puckResponse] = await PuckServer.getTrackerData(
          trackerId,
          operationId
        );
        // if (puckErr) {
        //   dispatch(
        //     setSnackbarMessage({
        //       open: true,
        //       message: puckErr.message,
        //       severity: "error",
        //     })
        //   );
        // }
        // console.log(puckResponse)
        const resp = {
          puckResponse,
        };
        return resp;
      };

      // // Using Sample telemetry data first, once all ok, uncomment
      // const puckTelemetryData = await pollPuckForTelemetry(
      //   flightDataEdited.details.puck_uuid,
      //   flightDataEdited.interuss.operational_intent_reference.id
      // );
      // Sample telemetry data if wish to simulate
      const puckTelemetryData = await pollPuckForTelemetry(
        "1fcbdbe0-2b10-44d2-b9e0-40d1612ab068",
        "c4da73de-0595-44fa-9d69-84799b4469c9"
      );
      flightDataEdited.puckTelemetryData = puckTelemetryData;
      operations.push(flightDataEdited);
    });
    setDownloadData(operations);
  };

  useEffect(() => {
    const fetchData = async () => {
      setActiveOverlay(true);
      // await getData();
      setActiveOverlay(false);
    };
    fetchData().catch(console.error);
    // setData([retrievedData]);
  }, []);

  const handleExportClick = async (e) => {
    const selectedData = selectedOperations.map((operation) => {
      return operation.reportId;
    });
    const selectedDownloads = downloadData.filter((downloadOperation) => {
      return selectedData.includes(downloadOperation.incidentReport.reportId);
    });
    downloadMultipleIncidentReport(selectedDownloads);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <LoadingOverlay active={activeOverlay} spinner>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              data={data}
              selectedOperations={selectedOperations}
              setSelectedOperations={setSelectedOperations}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.reportId}
                  >
                    <TableCell padding="checkbox" align="center">
                      <Checkbox
                        checked={
                          !!selectedOperations.filter((operation) => {
                            return operation.reportId === row.reportId;
                          }).length
                        }
                        onChange={(evt, checked) => {
                          if (checked) {
                            setSelectedOperations([...selectedOperations, row]);
                          } else {
                            setSelectedOperations(
                              selectedOperations.filter(
                                (operation) =>
                                  operation.reportId !== row.reportId
                              )
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.reportId}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {row.title}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.description}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.pilotName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.reportDate}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {formatDate(row.incidentDate)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.flightno}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.aircraftRegistration}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.aircraftModel}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button
          variant="contained"
          style={{ fontSize: "12px", fontWeight: "bold", margin: "20px" }}
          color="primary"
          onClick={handleExportClick}
          disabled={selectedOperations.length === 0}
        >
          Export Report
        </Button>
      </LoadingOverlay>
    </Paper>
  );
}
