import circle from "@turf/circle";

export default function CircleEntries(props) {
  const { circleCenter, radiusInKm, onChange } = props;
  const updateDrawnCircle = (center, radius) => {
    const feature = circle(center, radius);
    onChange(feature.geometry.coordinates[0], {
      isCircle: true,
      radiusInKm: radius,
      center,
    });
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>LAT (CENTER)</h4>
      <h4 style={{ textAlign: "center" }}>LON (CENTER)</h4>
      <h4 style={{ textAlign: "center" }}>RADIUS (KM)</h4>
      <input
        type="number"
        width={100}
        value={circleCenter ? circleCenter[1] : 0.0}
        name="centerLatititude"
        onChange={(ev) => {
          const center = circleCenter || [0.0, 0.0];
          updateDrawnCircle(
            [center[0], Number.parseFloat(ev.target.value)],
            radiusInKm
          );
        }}
        style={{ boxSizing: "content-box", height: "30px" }}
      />
      <input
        type="number"
        width={100}
        height={30}
        value={circleCenter ? circleCenter[0] : 0.0}
        name="centerLongitude"
        onChange={(ev) => {
          const center = circleCenter || [0.0, 0.0];
          updateDrawnCircle(
            [Number.parseFloat(ev.target.value), center[1]],
            radiusInKm
          );
        }}
        style={{ boxSizing: "content-box", height: "30px" }}
      />
      <input
        type="number"
        width={100}
        height={30}
        value={radiusInKm || 0.0}
        name="radius"
        onChange={(ev) => {
          const center = circleCenter || [0.0, 0.0];
          updateDrawnCircle(center, Number.parseFloat(ev.target.value));
        }}
        style={{ boxSizing: "content-box", height: "30px" }}
      />
    </div>
  );
}
