import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingOverlay from "react-loading-overlay";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddPilotForm from "../AddPilotForm";
import { setSnackbarMessage } from "../../store/actions";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import { convertZuluToLocalTime } from "../../api/timeConvert";

const InformationBox = class InformationBox extends React.Component {
  handleClick = () => {
    this.props.onClick(this.props.index);
  };

  handleEditClick = () => {
    this.props.onEditPilotClick();
  };

  handleDeleteClick = () => {
    this.props.onDeletePilotClick();
  };

  render() {
    const { info } = this.props;
    return (
      <Accordion onClick={this.handleClick}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            display="inline"
            variant="body2"
            style={{ marginLeft: "3px" }}
          >
            {info.pilot_name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0} style={{ marginBottom: "1px" }}>
            <Grid item xs={6} style={{ paddingLeft: "1px" }}>
              <DetailItem name="Username" value={info.pilot_username} />
              <DetailItem
                name="Licence Number"
                value={info.pilot_registration}
              />
              <DetailItem
                name="License Expiry"
                value={convertZuluToLocalTime(info.registration_expiry)}
              />
              <DetailItem name="Contact Number" value={info.pilot_contact} />
              <DetailItem name="Email" value={info.pilot_email} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  style={{ width: "130px", marginTop: "5px" }}
                  onClick={this.handleEditClick}
                >
                  {" "}
                  Edit Profile{" "}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  style={{ width: "130px", marginTop: "15px" }}
                  onClick={this.handleDeleteClick}
                >
                  {" "}
                  Delete Profile{" "}
                </Button>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
};

function PilotList(props) {
  return (
    <List component="div">
      {props.pilotList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={props.onClick}
          onEditPilotClick={props.onEditPilotClick}
          onDeletePilotClick={props.onDeletePilotClick}
          selected={props.selected === index}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "3px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function PilotsComponent() {
  const [selected, setSelected] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [pilotList, setPilotList] = React.useState([]);
  const [loadingPilots, setLoadingPilots] = React.useState(true);
  const [updatingPilot, setUpdatingPilot] = React.useState(false);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();

  const { isAuthority } = useCognitoAuth();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getPilots();
  }, []);

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.pilot_name < b.pilot_name) {
      return -1;
    }
    if (a.pilot_name > b.pilot_name) {
      return 1;
    }
    return 0;
  }
  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      if (response.data) {
        response.data.sort(compare);
        setPilotList(response.data);
        setLoadingPilots(false);
      }
    } catch (err) {
      setLoadingPilots(false);
      dispatch(
        setSnackbarMessage({
          message: err.message,
          severity: "error",
        })
      );
    }
  };

  const handlePilotClick = (item) => {
    if (selected !== item) setSelected(item);
  };

  const handleEditPilotClick = () => {
    setOpenDialog(true);
    setUpdatingPilot(true);
  };

  const handleDeletePilotClick = () => {
    deletePilotInfo();
  };

  const handleAddPilotClick = () => {
    setOpenDialog(true);
    setUpdatingPilot(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setUpdatingPilot(false);
  };

  const handlePilotUpdate = (newPilotData) => {
    const newPilotList = pilotList;
    const index = pilotList.findIndex((x) => x.id === newPilotData.id);
    newPilotList[index] = newPilotData;
    setPilotList(newPilotList);
  };

  const deletePilotInfo = async () => {
    try {
      const response = await api.deletePilot(
        pilotList[selected].pilot_uuid,
        pilotList[selected].pilot_username
      );
      if (response.data) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Pilot successfully deleted",
            severity: "success",
          })
        );
        getPilots();
        handleClose();
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <LoadingOverlay active={loadingPilots} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddPilotClick}
        >
          {" "}
          Add Pilot{" "}
        </Button>
      </Grid>
      <PilotList
        onClick={handlePilotClick}
        selected={selected}
        pilotList={pilotList}
        onEditPilotClick={handleEditPilotClick}
        onDeletePilotClick={handleDeletePilotClick}
      />
      <AddPilotForm
        open={openDialog}
        handleClose={handleClose}
        getPilots={getPilots}
        pilotData={selected !== null ? pilotList[selected] : null}
        updating={updatingPilot}
        handlePilotUpdate={handlePilotUpdate}
      />
      {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
    </LoadingOverlay>
  );
}
