import { heronTheme } from "./heronTheme";

export const Colours = {
  Light: {
    // navSidebar: "#000014",
    // sidebarIcon: "#FFFFFF",
    // opsPanel: "#000014",
    sidebarIcon: "#000000",
    sidebarSelectedButton: "#D0D0D0",
    // opsPanel: "#CECECE",
    // navSidebar: "#CECECE",
    opsPanel: heronTheme.colors.neutralLight,
    navSidebar: heronTheme.colors.neutralLight,
    opsSummary: "#D2D2D2",

    primaryBlue: "#157fea ",
    primaryBlueLight: "#61B6E8",
    primaryBlueLighter: "#74CAFF",

    midnightBlue: "#0b0033",
    midnight: "#010e2f",

    black: "#000000",
    white: "#ffffff",
    grey: "#808080",

    altoGrey: "#E0e0e0",

    cardGreen: "#54D016 ",
    cardLightGreen: "#68F157 ",
    cardRed: " ",
    cardLightRed: "#F76F71",
    cardOrange: "",
    cardLightOrange: "#F3AF6F",
  },
  Outdoors: {
    navSidebar: "#FFFFFF",
    sidebarIcon: "#000000",
    sidebarSelectedButton: "#E0e0e0",
    opsPanel: "#FFFFFF",
    opsSummary: heronTheme.colors.neutralLight,

    primaryBlue: "#157fea ",
    primaryBlueLight: "#61B6E8",
    primaryBlueLighter: "#74CAFF",

    midnightBlue: "#0b0033",
    midnight: "#010e2f",

    black: "#000000",
    white: "#ffffff",
    grey: "#808080",

    altoGrey: "#E0e0e0",

    cardGreen: "#54D016 ",
    cardLightGreen: "#68F157 ",
    cardRed: " ",
    cardLightRed: "#F76F71",
    cardOrange: "",
    cardLightOrange: "#F3AF6F",
  },
  Street: {
    navSidebar: "#FFFFFF",
    sidebarIcon: "#000000",
    sidebarSelectedButton: "#E0e0e0",
    opsPanel: "#FFFFFF",
    opsSummary: heronTheme.colors.neutralLight,

    primaryBlue: "#157fea ",
    primaryBlueLight: "#61B6E8",
    primaryBlueLighter: "#74CAFF",

    midnightBlue: "#0b0033",
    midnight: "#010e2f",

    black: "#000000",
    white: "#ffffff",
    grey: "#808080",

    altoGrey: "#E0e0e0",

    cardGreen: "#54D016 ",
    cardLightGreen: "#68F157 ",
    cardRed: " ",
    cardLightRed: "#F76F71",
    cardOrange: "",
    cardLightOrange: "#F3AF6F",
  },
  Satellite: {
    navSidebar: "#FFFFFF",
    sidebarIcon: "#000000",
    sidebarSelectedButton: "#E0e0e0",
    opsPanel: "#FFFFFF",
    opsSummary: heronTheme.colors.neutralLight,

    primaryBlue: "#157fea ",
    primaryBlueLight: "#61B6E8",
    primaryBlueLighter: "#74CAFF",

    midnightBlue: "#0b0033",
    midnight: "#010e2f",

    black: "#000000",
    white: "#ffffff",
    grey: "#808080",

    altoGrey: "#E0e0e0",

    cardGreen: "#54D016 ",
    cardLightGreen: "#68F157 ",
    cardRed: " ",
    cardLightRed: "#F76F71",
    cardOrange: "",
    cardLightOrange: "#F3AF6F",
  },

  // default is Dark theme
  Dark: {
    // navSidebar: "#000014",
    // sidebarIcon: "#B9b9bf",
    sidebarIcon: "#000000",
    sidebarSelectedButton: "#B7B6B6",
    opsPanel: "#CECECE",
    navSidebar: "#CECECE",
    opsSummary: heronTheme.colors.neutralLight,

    primaryBlue: "#157fea ",
    primaryBlueLight: "#61B6E8",
    primaryBlueLighter: "#74CAFF",

    midnightBlue: "#0b0033",
    midnight: "#010e2f",

    black: "#000000",
    white: "#ffffff",
    grey: "#808080",

    altoGrey: "#E0e0e0",

    cardGreen: "#54D016 ",
    cardLightGreen: "#68F157 ",
    cardRed: " ",
    cardLightRed: "#F76F71",
    cardOrange: "",
    cardLightOrange: "#F3AF6F",
  },
};
