import { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Box,
} from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Paper from "@material-ui/core/Paper";

import Divider from "@material-ui/core/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function Requiring(props) {
  const {
    valueTimeThick,
    setvalueTimeThick,
    departureTime,
    departureTimeError,
    handleDepartureTimeChange,
    endTime,
    endTimeError,
    handleEndTimeChange,
    recurringCheck,
    setRecurringChek,
  } = props;
  const [recurringDescription, setRecurringDescription] = useState("");
  const [endTimeRangeError, setEndTimeRangeError] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const recurringEndTimeRef = useRef(endTime);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getDayWithSuffix = (date) => {
    const day = date.getDate();
    const suffix =
      day >= 11 && day <= 13
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][
            day % 10
          ];

    return day + suffix;
  };
  const handleTimeThickChange = (event) => {
    setvalueTimeThick(event.target.value);
  };
  const { recurring_Chek } = recurringCheck;
  const handleChekChange = (event) => {
    setRecurringChek({
      ...recurringCheck,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setOpenDialog(true);
    }
  };
  const handleWeeklyDepartureTimeChange = (value) => {
    handleDepartureTimeChange(value);
  };
  const handleWeeklyEndTimeChange = (value) => {
    handleEndTimeChange(value);
    recurringEndTimeRef.current = value;
  };
  const updateRecurringDescription = () => {
    if (valueTimeThick === "daily") {
      const descriptionDepartureTime = departureTime.toLocaleTimeString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      const descriptionEndTime = endTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const descriptionText = `Recurs daily from ${descriptionDepartureTime} to ${descriptionEndTime}`;
      setRecurringDescription(descriptionText);
    }
    if (valueTimeThick === "weekly") {
      const descriptionDepartureDay = daysOfWeek[departureTime.getDay()];
      const descriptionDepartureTime = departureTime.toLocaleTimeString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      const descriptionEndDay = daysOfWeek[endTime.getDay()];
      const descriptionEndTime = endTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const descriptionText = `Recurs weekly every ${descriptionDepartureDay} ${descriptionDepartureTime} to ${descriptionEndDay} ${descriptionEndTime}`;
      setRecurringDescription(descriptionText);
    }
    if (valueTimeThick === "monthly") {
      const descriptionDepartureDaySuffix = getDayWithSuffix(departureTime);
      const descriptionDepartureTime = departureTime.toLocaleTimeString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      const descriptionEndDaySuffix = getDayWithSuffix(endTime);
      const descriptionEndTime = endTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      const descriptionText = `Recurs monthly from ${descriptionDepartureDaySuffix} at ${descriptionDepartureTime} till ${descriptionEndDaySuffix} at ${descriptionEndTime} `;
      setRecurringDescription(descriptionText);
    }
    if (departureTime > endTime) {
      const descriptionText = `Invalid date/time`;
      setRecurringDescription(descriptionText);
    }
  };
  const errorChecking = () => {
    if (valueTimeThick === "daily") {
      if (
        departureTime.getDate() !== endTime.getDate() ||
        departureTime.getMonth() !== endTime.getMonth() ||
        departureTime.getFullYear() !== endTime.getFullYear()
      ) {
        const today = new Date();
        const setDailyRecurringEndTime = endTime;
        const setDailyRecurringDepartureTime = departureTime;
        setDailyRecurringEndTime.setFullYear(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        setDailyRecurringDepartureTime.setFullYear(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );
        handleDepartureTimeChange(setDailyRecurringDepartureTime);
        handleEndTimeChange(setDailyRecurringEndTime);
      }
    }
    if (valueTimeThick === "weekly") {
      const differenceInMilliseconds = Math.abs(endTime - departureTime);
      const millisecondsInOneDay = 24 * 60 * 60 * 1000;
      const differenceInDays = Math.floor(
        differenceInMilliseconds / millisecondsInOneDay
      );
      if (differenceInDays >= 7) {
        setEndTimeRangeError("Range larger than 7 days");
        setRecurringDescription("Invalid Date/Time");
      } else {
        setEndTimeRangeError();
      }
    }
    if (valueTimeThick === "monthly") {
      const differenceInMilliseconds = Math.abs(endTime - departureTime);
      const millisecondsInOneDay = 24 * 60 * 60 * 1000;
      const differenceInDays = Math.floor(
        differenceInMilliseconds / millisecondsInOneDay
      );
      if (differenceInDays >= 31) {
        setEndTimeRangeError("Range larger than 31 days");
        setRecurringDescription("Invalid Date/Time");
      } else {
        setEndTimeRangeError();
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!departureTime || !endTime) return;
    updateRecurringDescription();
    errorChecking();
  }, [departureTime, endTime, valueTimeThick]);

  useEffect(() => {
    if (!departureTime || !endTime) return;
    try {
      // when change to recurring daily, datetime is edited, this is used to change it back
      // if user changes tab back to weekly or monthly recurring
      if (
        endTime.toISOString() !== recurringEndTimeRef.current.toISOString() &&
        valueTimeThick !== "daily"
      ) {
        handleEndTimeChange(recurringEndTimeRef.current);
      }
      updateRecurringDescription();
      errorChecking();
    } catch (e) {
      setRecurringDescription("Invalid date/time");
    }
  }, [valueTimeThick]);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(255, 0, 0, 0.0)",
        border: "1px solid white",
        marginTop: "30px",
        marginBottom: "5px",
        paddingTop: "1px",
        paddingLeft: "10px",
        paddingRight: "10px",
        boxShadow: "0 0 0 1px #f50057",
      }}
    >
      {" "}
      <Typography
        component="div"
        style={{
          backgroundColor: "white",
          width: "170px",
          marginLeft: "15px",
          color: "#f50057",
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
          }}
          sx={{ fontWeight: "bold", m: -2, textTransform: "uppercase" }}
        >
          Recurring Constraint
        </Box>
      </Typography>
      <Grid item xs={12} style={{ marginLeft: "0rem", marginTop: "20px" }}>
        <FormControlLabel
          control={
            <Checkbox
              name="recurring_Chek"
              checked={recurring_Chek}
              onChange={handleChekChange}
            />
          }
          label={
            recurring_Chek ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setOpenDialog(true)}
              >
                Edit Recurrance
              </Button>
            ) : (
              "Enable"
            )
          }
        />
      </Grid>
      {recurring_Chek ? (
        <Dialog open={openDialog} onClose={() => handleCloseDialog()}>
          <Paper
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.0)",
              border: "1px solid white",
              margin: "5px",
              marginTop: "30px",
              marginBottom: "5px",
              paddingTop: "1px",
              paddingLeft: "10px",
              paddingRight: "10px",
              boxShadow: "0 0 0 1px #f50057",
            }}
          >
            <Grid container spacing={3} style={{ marginTop: "2px" }}>
              <Grid item xs={12}>
                <FormControl>
                  <Typography variant="caption">Recurring Frequency</Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={valueTimeThick}
                    onChange={handleTimeThickChange}
                  >
                    <FormControlLabel
                      value="daily"
                      control={<Radio />}
                      label="Daily"
                    />
                    <FormControlLabel
                      value="weekly"
                      control={<Radio />}
                      label="Weekly"
                    />
                    <FormControlLabel
                      value="monthly"
                      control={<Radio />}
                      label="Monthly"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="caption">
              {`${valueTimeThick.replace(/\b\w/g, (match) => {
                return match.toUpperCase();
              })} Active Time`}
            </Typography>
            {valueTimeThick === "daily" ? (
              <Grid container spacing={3} style={{ marginTop: "0px" }}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      id="start-time"
                      label="Start Time (Local Time)"
                      value={departureTime}
                      onChange={(value) => handleDepartureTimeChange(value)}
                      views={["hours", "minutes"]}
                      format="hh:mm aa"
                      timeSteps={{ minutes: 1 }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        popper: {
                          sx: {
                            "& .MuiMultiSectionDigitalClock-root": {
                              maxHeight: "330px",
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "11.5px 14px",
                        },
                      }}
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      id="end-time"
                      label="End Time (Local Time)"
                      value={endTime}
                      minTime={departureTime}
                      onChange={(value) => handleEndTimeChange(value)}
                      views={["hours", "minutes"]}
                      format="hh:mm aa"
                      timeSteps={{ minutes: 1 }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        popper: {
                          sx: {
                            "& .MuiMultiSectionDigitalClock-root": {
                              maxHeight: "330px",
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "11.5px 14px",
                        },
                      }}
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3} style={{ marginTop: "0px" }}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      id="start-time"
                      label="Start Date & Time (Local Time)"
                      value={departureTime}
                      onChange={(value) =>
                        handleWeeklyDepartureTimeChange(value)
                      }
                      views={["day", "month", "year", "hours", "minutes"]}
                      format="dd-MM-yyyy hh:mm aa"
                      timeSteps={{ minutes: 1 }}
                      slotProps={{
                        popper: {
                          sx: {
                            "& .MuiMultiSectionDigitalClock-root": {
                              maxHeight: "330px",
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "11.5px 14px",
                        },
                      }}
                      error={!!departureTimeError}
                      helperText={departureTimeError}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      id="end-time"
                      label="End Date & Time (Local Time)"
                      minDateTime={departureTime}
                      value={endTime}
                      onChange={(value) => handleWeeklyEndTimeChange(value)}
                      views={["day", "month", "year", "hours", "minutes"]}
                      format="dd-MM-yyyy hh:mm aa"
                      timeSteps={{ minutes: 1 }}
                      slotProps={{
                        textField: {
                          error: !!departureTimeError || !!endTimeRangeError,
                          helperText: departureTimeError || endTimeRangeError,
                        },
                        popper: {
                          sx: {
                            "& .MuiMultiSectionDigitalClock-root": {
                              maxHeight: "330px",
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "11.5px 14px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
            <Typography variant="caption">{recurringDescription}</Typography>
            <Divider style={{ marginBottom: "2px", marginTop: "10px" }} />
          </Paper>
          <div style={{ margin: "5px" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </div>
        </Dialog>
      ) : (
        ""
      )}
      <Typography variant="caption">
        {recurring_Chek && recurringDescription}
      </Typography>
    </div>
  );
}
