import Button from "@/components/v1/Common/Button";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";
import InputWaypoint from "@/components/v1/InputWaypoint";
import LatLng from "@/model/LatLng";
import { TakeoffPoint, VesselInfo } from "@/model/PortOperation";
import Waypoint from "@/model/Waypoint";
import { useEffect, useRef, useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { MdEmergencyShare } from "react-icons/md";
import { useSelector } from "react-redux";
import moment from "moment";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import Tracker from "@/model/api/Tracker";
import { convertZuluToLocalTime } from "@/api/timeConvert";
import axios from "axios";
import { getFutureDate } from "@/utils/date_utils";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import data from "./data";

interface MyState {
  shipData: { visible: false; data: VesselInfo[] };
}

interface OperationForm {
  intent?: string;
  pilot_uuid: string[];
  platform_uuid: string[];
  tracker_uuid: string[];
  waypoints: number[][];
  time_buffer: number;
  vertical_buffer?: number;
  lateral_buffer?: number;
  rth_altitude?: number;
  elevation: number;
  max_segment_length: number;
  airspace_optimised: boolean;
  two_way: boolean;
  transit_duration: number;
  altitude: number;
  altitude_reference: string;
  time_start: string;
  time_end: string;
  transit_duration_hour?: number;
  transit_duration_minute?: number;
  ground_speed: number;
  operation_type: string;
  description: string;
  contingency_plans: {
    landing_point: number[][];
    safe_altitude: number;
  };
  tag: string;
}

interface ShipOperationFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;
  className?: string;
  pilots: Pilot[];
  platforms: Platform[];
  trackers: Tracker[];
  onConfirmation: (
    title: string,
    message: string,
    callback: (result: boolean) => void
  ) => void;
  showMessage?: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  goToLocation: (point: LatLng) => void;
  setSelectedWaypoints?: (waypoints: number[][]) => void;
}

export default function ShipOperationForm({
  api,
  className = "",
  pilots,
  platforms,
  trackers,
  onConfirmation,
  showMessage,
  goToLocation,
  setSelectedWaypoints,
}: ShipOperationFormProps) {
  /* Form State */
  const [isLoading, setIsLoading] = useState(false);

  /* Ship Data */
  const shipData = useSelector((state: MyState) => state.shipData.data);

  /* Operation Date */
  const [departureDate, setDepartureDate] = useState<Date | null>(
    getFutureDate(3)
  );
  const [arrivalDate, setArrivalDate] = useState<Date | null>(
    getFutureDate(30)
  );

  /* Takeoff-Landing and Emergency */
  const [selectedTakeOffPoint, setSelectedTakeOffPoint] =
    useState<TakeoffPoint | null>(null);
  const [selectedShip, setSelectedShip] = useState<VesselInfo | null>(null);

  /* Ship autocomplete */
  const debounceShipFilter = useRef<NodeJS.Timeout | null>(null);
  const [shipFilterStr, setShipFilterStr] = useState("");
  const [isShipDropdownOpen, setShipDropdownOpen] = useState(false);

  const [takeOffLandingWaypoints, setTakeOffLandingWaypoints] = useState<
    Waypoint[]
  >(data.initialTakeoffLandingPoint);

  const [emergencyLandingWaypoints, setEmegencyLandingWaypoints] = useState<
    Waypoint[]
  >(data.initialEmergencyLandingPoint);

  /* Optional Configuration */
  const [customBuffer, setCustomBuffer] = useState(false);
  const [twoWay, setTwoWay] = useState(false);

  const handleTakeOffChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPoint = data.takeOffPoint.find(
      (point) => point.label === e.target.value
    );

    let latitude = 0;
    let longitude = 0;
    let updatedWaypoints: Waypoint[] = [];

    if (selectedPoint) {
      latitude = selectedPoint.lat;
      longitude = selectedPoint.lon;
      setSelectedTakeOffPoint(selectedPoint);
    } else {
      setSelectedTakeOffPoint(null);
    }

    const updatedEmergencyLandingWaypoints = emergencyLandingWaypoints.map(
      (waypoint) =>
        waypoint.isFirst
          ? {
              ...waypoint,
              latitude,
              longitude,
            }
          : waypoint
    );

    updatedWaypoints = takeOffLandingWaypoints.map((waypoint) =>
      waypoint.isFirst
        ? {
            ...waypoint,
            latitude,
            longitude,
          }
        : waypoint
    );

    goToLocation({ lat: latitude, lng: longitude });
    setTakeOffLandingWaypoints(updatedWaypoints);
    setEmegencyLandingWaypoints(updatedEmergencyLandingWaypoints);
  };

  const handleShipChange = (ship: VesselInfo) => {
    // const ship = shipData.find((vessel) => vessel.mmsi === e.target.value);

    let latitude = 0;
    let longitude = 0;
    let updatedWaypoints: Waypoint[] = [];

    if (ship) {
      latitude = ship.latitudeDegrees;
      longitude = ship.longitudeDegrees;
      setSelectedShip(ship);
    } else {
      setSelectedShip(null);
    }

    updatedWaypoints = takeOffLandingWaypoints.map((waypoint) =>
      waypoint.isLast
        ? {
            ...waypoint,
            latitude,
            longitude,
          }
        : waypoint
    );

    goToLocation({ lat: latitude, lng: longitude });
    setTakeOffLandingWaypoints(updatedWaypoints);
  };

  const handleShipFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceShipFilter.current) {
      clearTimeout(debounceShipFilter.current);
    }

    debounceShipFilter.current = setTimeout(() => {
      setShipFilterStr(e.target.value);
    }, 500);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.type === "number"
          ? parseInt(e.target.value, 10)
          : e.target.value,
    });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: string
  ) => {
    if (
      type === "pilot_uuid" ||
      type === "platform_uuid" ||
      type === "tracker_uuid"
    ) {
      let { value } = e.target;
      if (e.target.value === "Select") {
        value = "";
      }
      setForm((prevForm) => ({
        ...prevForm,
        [type]: [value],
      }));
      return;
    }

    setForm({
      ...form,
      [type]: e.target.value,
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    if (name === "inbound_operation") {
      const firstWaypoint = takeOffLandingWaypoints[0];
      const lastWaypoint =
        takeOffLandingWaypoints[takeOffLandingWaypoints.length - 1];

      // reverse last and first waypoint position
      const updatedWaypoints = takeOffLandingWaypoints.map(
        (waypoint, index) => {
          if (index === 0) {
            const newWaypoint = {
              ...lastWaypoint,
              isFirst: false,
              isLast: true,
            };
            return newWaypoint;
          }
          if (index === takeOffLandingWaypoints.length - 1) {
            const newWaypoint = {
              ...firstWaypoint,
              isFirst: true,
              isLast: false,
            };
            return newWaypoint;
          }
          return waypoint;
        }
      );
      setTakeOffLandingWaypoints(updatedWaypoints);

      // set emergency landing point using first updatedWaypoints
      const updatedEmergencyLandingWaypoints = emergencyLandingWaypoints.map(
        (waypoint) =>
          waypoint.isFirst
            ? {
                ...waypoint,
                latitude: updatedWaypoints[0].latitude,
                longitude: updatedWaypoints[0].longitude,
              }
            : waypoint
      );
      setEmegencyLandingWaypoints(updatedEmergencyLandingWaypoints);
      return;
    }

    if (name === "custom_buffer") {
      setCustomBuffer(e.target.checked);
      return;
    }

    if (name === "two_way") {
      setTwoWay(e.target.checked);
      return;
    }

    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleDateChange = (date: Date | null, type: string) => {
    let formField = "";
    if (type === "departure") {
      setDepartureDate(date);
      formField = "time_start";
    }
    if (type === "arrival") {
      setArrivalDate(date);
      formField = "time_end";
    }

    if (date) {
      const formattedDate = moment(date).toISOString();
      setForm({
        ...form,
        [formField]: formattedDate,
      });
    }
  };

  const [form, setForm] = useState<OperationForm>({
    intent: "",
    pilot_uuid: [""],
    platform_uuid: [""],
    tracker_uuid: [""],
    waypoints: [
      [0, 0],
      [0, 0],
    ],
    time_buffer: 30,
    vertical_buffer: 0,
    lateral_buffer: 0,
    rth_altitude: 60,
    elevation: 0,
    max_segment_length: 500,
    airspace_optimised: false,
    two_way: false,
    transit_duration: 0,
    altitude: 60,
    altitude_reference: "W84",
    time_start: "",
    time_end: "",
    transit_duration_hour: 0,
    transit_duration_minute: 0,
    ground_speed: 5,
    operation_type: "bvlos",
    description: "",
    contingency_plans: {
      landing_point: [[0, 0]],
      safe_altitude: 60,
    },
    tag: "operation",
  });

  const [formError, setFormError] = useState({
    takeOffPoint: "",
    ship: "",
    emergencyPoint: "",
    intent: "",
    pilot_uuid: "",
    platform_uuid: "",
    tracker_uuid: "",
    waypoints: "",
    time_buffer: "",
    vertical_buffer: "",
    lateral_buffer: "",
    rth_altitude: "",
    elevation: "",
    max_segment_length: "",
    airspace_optimised: "",
    two_way: "",
    transit_duration: "",
    altitude: "",
    altitude_reference: "",
    time_start: "",
    time_end: "",
    transit_duration_hour: "",
    transit_duration_minute: "",
    ground_speed: "",
    operation_type: "",
    description: "",
  });

  const validateForm = () => {
    let isValid = true;
    const error = {
      takeOffPoint: "",
      ship: "",
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      two_way: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      time_end: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    };

    if (!selectedTakeOffPoint) {
      error.takeOffPoint = "Take off point is required";
      isValid = false;
    }

    if (!selectedShip) {
      error.ship = "Ship is required";
      isValid = false;
    }

    if (form.intent === "") {
      error.intent = "Intent is required";
      isValid = false;
    }

    if (form.pilot_uuid[0] === "") {
      error.pilot_uuid = "Pilot is required";
      isValid = false;
    }

    if (form.platform_uuid[0] === "") {
      error.platform_uuid = "Platform is required";
      isValid = false;
    }

    if (form.tracker_uuid[0] === "") {
      error.tracker_uuid = "Tracker is required";
      isValid = false;
    }

    const isValidWaypoints = takeOffLandingWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidWaypoints) {
      error.waypoints = "Waypoints is required";
      isValid = false;
    }

    const isValidEmergencyPoint = emergencyLandingWaypoints.some(
      (waypoint) => waypoint.latitude !== 0 && waypoint.longitude !== 0
    );

    if (!isValidEmergencyPoint) {
      error.emergencyPoint = "Emergency point is required";
      isValid = false;
    }

    if (form.time_buffer === 0 || isNaN(form.time_buffer)) {
      error.time_buffer = "Time buffer is required";
      isValid = false;
    }

    if (customBuffer && form.vertical_buffer === 0) {
      error.vertical_buffer = "Vertical buffer is required";
      isValid = false;
    }

    if (customBuffer && form.lateral_buffer === 0) {
      error.lateral_buffer = "Lateral buffer is required";
      isValid = false;
    }

    if (form.rth_altitude === 0 || isNaN(form.rth_altitude ?? 0)) {
      error.rth_altitude = "RTH altitude is required";
      isValid = false;
    }

    if (form.elevation === 0 || isNaN(form.elevation)) {
      error.elevation = "Elevation is required";
      isValid = false;
    }

    if (form.max_segment_length === 0 || isNaN(form.max_segment_length)) {
      error.max_segment_length = "Max segment length is required";
      isValid = false;
    }

    if (form.altitude === 0 || isNaN(form.altitude)) {
      error.altitude = "Altitude is required";
      isValid = false;
    }

    if (form.altitude_reference === "") {
      error.altitude_reference = "Altitude reference is required";
      isValid = false;
    }

    if (form.time_start === "") {
      error.time_start = "Time start is required";
      isValid = false;
    }

    if (form.time_end === "") {
      error.time_end = "Time end is required";
      isValid = false;
    }

    if (
      twoWay &&
      (form.transit_duration_hour === 0 ||
        Number.isNaN(form.transit_duration_hour)) &&
      (form.transit_duration_minute === 0 ||
        Number.isNaN(form.transit_duration_minute))
    ) {
      error.transit_duration_hour = "Transit duration is required";
      error.transit_duration_minute = "Transit duration is required";
      isValid = false;
    }

    if (form.ground_speed === 0 || isNaN(form.ground_speed)) {
      error.ground_speed = "Ground speed is required";
      isValid = false;
    }

    if (form.operation_type === "") {
      error.operation_type = "Operation type is required";
      isValid = false;
    }

    if (form.description === "") {
      error.description = "Description is required";
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleResetForm = () => {
    onConfirmation(
      "Reset Confirmation",
      "Are you sure you to reset the form ?",
      (result) => {
        if (result) {
          resetForm();
        }
      }
    );
  };

  const handleSubmitOperation = async () => {
    if (!validateForm()) {
      showMessage && showMessage("Please fill all required fields");
      return;
    }

    const operationData = {
      ...form,
      waypoints: takeOffLandingWaypoints.map((waypoint) => [
        waypoint.longitude,
        waypoint.latitude,
      ]),
      contingency_plans: {
        landing_point: emergencyLandingWaypoints.map((waypoint) => [
          waypoint.longitude,
          waypoint.latitude,
        ]),
        safe_altitude: form.rth_altitude,
      },
    };

    if (twoWay) {
      let transitDuration = 0;
      if (form.transit_duration_hour && form.transit_duration_hour !== 0) {
        transitDuration += form.transit_duration_hour * 3600;
      }

      if (form.transit_duration_minute && form.transit_duration_minute !== 0) {
        transitDuration += form.transit_duration_minute * 60;
      }
      operationData.transit_duration = transitDuration;
    }

    showMessage && showMessage("Saving operation details", false);
    setIsLoading(true);

    try {
      delete operationData.intent;
      delete operationData.transit_duration_hour;
      delete operationData.transit_duration_minute;
      delete operationData.rth_altitude;

      if (!customBuffer) {
        delete operationData.vertical_buffer;
        delete operationData.lateral_buffer;
      }

      operationData.tag = "operation";
      const response = await api.submitOperation(operationData);
      if (response.data) {
        // TODO: handle reroute
        showMessage && showMessage("Operation Submitted", true);
        resetForm();
      }
    } catch (err) {
      let message = "Error getting response, please try again";
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data?.message || err.message;
        if (errorMessage) {
          message = errorMessage;
        }
        if (message && message.startsWith("Airspace already reserved")) {
          const time = message.split(" ").at(-2);
          if (time) {
            message = `Airspace already reserved. Try again after: ${convertZuluToLocalTime(
              time
            )}`;
          }
        }
        if (err.code === "ECONNABORTED") {
          message = "Network Error";
        }
      }
      showMessage && showMessage(message, false, true);
    }

    setIsLoading(false);
  };

  const resetForm = () => {
    setSelectedWaypoints &&
      setSelectedWaypoints([
        [0, 0],
        [0, 0],
      ]);
    setTakeOffLandingWaypoints(data.initialTakeoffLandingPoint);
    setEmegencyLandingWaypoints(data.initialEmergencyLandingPoint);
    setCustomBuffer(false);
    setTwoWay(false);
    setDepartureDate(getFutureDate(3));
    setArrivalDate(getFutureDate(30));
    setSelectedTakeOffPoint(null);
    setSelectedShip(null);
    setForm({
      intent: "",
      pilot_uuid: [""],
      platform_uuid: [""],
      tracker_uuid: [""],
      waypoints: [
        [0, 0],
        [0, 0],
      ],
      time_buffer: 30,
      vertical_buffer: 0,
      lateral_buffer: 0,
      rth_altitude: 60,
      elevation: 0,
      max_segment_length: 500,
      airspace_optimised: false,
      two_way: false,
      transit_duration: 0,
      altitude: 60,
      altitude_reference: "W84",
      time_start: "",
      time_end: "",
      transit_duration_hour: 0,
      transit_duration_minute: 0,
      ground_speed: 5,
      operation_type: "bvlos",
      description: "",
      contingency_plans: {
        landing_point: [[0, 0]],
        safe_altitude: 60,
      },
      tag: "operation",
    });
    setFormError({
      takeOffPoint: "",
      ship: "",
      emergencyPoint: "",
      intent: "",
      pilot_uuid: "",
      platform_uuid: "",
      tracker_uuid: "",
      waypoints: "",
      time_buffer: "",
      vertical_buffer: "",
      lateral_buffer: "",
      rth_altitude: "",
      elevation: "",
      max_segment_length: "",
      airspace_optimised: "",
      two_way: "",
      transit_duration: "",
      altitude: "",
      altitude_reference: "",
      time_start: "",
      time_end: "",
      transit_duration_hour: "",
      transit_duration_minute: "",
      ground_speed: "",
      operation_type: "",
      description: "",
    });
  };

  useEffect(() => {
    if (!selectedTakeOffPoint || !selectedShip) return;

    const takeOffPoint = [selectedTakeOffPoint.lon, selectedTakeOffPoint.lat];

    const landingPoint = [
      selectedShip.longitudeDegrees,
      selectedShip.latitudeDegrees,
    ];

    if (setSelectedWaypoints) {
      setSelectedWaypoints([takeOffPoint, landingPoint]);
    }
  }, [selectedTakeOffPoint, selectedShip, setSelectedWaypoints]);

  return (
    <div className={`h-full flex-col px-4 ${className}`}>
      <h5 className="font-medium mb-2">Operation Details</h5>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">Take Off / Landing Point</label>
          <select className="input-select" onChange={handleTakeOffChange}>
            <option>Select</option>
            {data.takeOffPoint.map((point) => (
              <option key={point.label} value={point.label}>
                {point.label}
              </option>
            ))}
          </select>
          {formError.takeOffPoint && (
            <p className="text-red-500 text-sm mt-1">
              {formError.takeOffPoint}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Ship</label>
          <div className="relative w-full">
            <button
              className="input-select"
              type="button"
              onClick={() => setShipDropdownOpen(!isShipDropdownOpen)}
            >
              <span className="grow">
                {selectedShip ? selectedShip.vesselName : "Select"}
              </span>
              {isShipDropdownOpen ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </button>

            {isShipDropdownOpen && (
              <div className="absolute top-14 w-[100%] z-[150]">
                <div className="dropdown-autocomplete">
                  <input
                    type="text"
                    className="input-text"
                    placeholder="Search by Name or MMSI"
                    onChange={handleShipFilter}
                  />
                </div>
                <ul className="dropdown-ul">
                  {shipData
                    .filter((ship) => {
                      if (!shipFilterStr) return true;
                      const shipName = ship.vesselName.toLowerCase();
                      const mmsi = ship.mmsi.toString();
                      return (
                        shipName.includes(shipFilterStr.toLowerCase()) ||
                        mmsi.includes(shipFilterStr)
                      );
                    })
                    .map((ship) => (
                      <li
                        key={ship.mmsi}
                        className="dropdown-li"
                        // onMouseEnter={handleSubsectionHover}
                        // onMouseLeave={() => removeMapHighlight()}
                        onClick={() => {
                          handleShipChange(ship);
                          setShipDropdownOpen(false);
                        }}
                      >
                        <span className="font-medium">{ship.vesselName}</span>
                        <br /> MMSI: {ship.mmsi}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          {/* <select className="input-select" onChange={handleShipChange}>
            <option>Select</option>
            {shipData.map((ship) => (
              <option key={ship.mmsi} value={ship.mmsi}>
                {ship.vesselName} MMSI: {ship.mmsi}
              </option>
            ))}
          </select> */}
          {formError.ship && (
            <p className="text-red-500 text-sm mt-1">{formError.ship}</p>
          )}
        </div>
      </div>

      <div className="flex items-center mt-4 mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="inbound_operation"
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Inbound Operation</label>
      </div>

      {/* Waypoints */}
      <div className="flex mb-2 items-center">
        <FaLocationArrow size={14} color="gray" />
        <h5 className="ms-2 font-medium">Waypoints</h5>
      </div>
      <div className="rounded-container p-2 mb-4">
        <InputWaypoint
          keyLabel="WP"
          useTakeOffLandingPoint={true}
          waypointsData={takeOffLandingWaypoints}
          onWaypointChanged={(waypoints) =>
            setTakeOffLandingWaypoints(waypoints)
          }
          goToLocation={goToLocation}
        />
      </div>
      {formError.waypoints && (
        <p className="text-red-500 text-sm -mt-2 mb-4">{formError.waypoints}</p>
      )}

      {/* Emergency Point */}
      <div className="flex mb-2 items-center">
        <MdEmergencyShare size={18} color="gray" />
        <h5 className="ms-2 font-medium">Emergency Point</h5>
      </div>
      <div className="rounded-container p-2 mb-4">
        <InputWaypoint
          keyLabel="EP"
          allowMultiple={false}
          pickFromMapButton={true}
          waypointsData={emergencyLandingWaypoints}
          onWaypointChanged={(waypoints) =>
            setEmegencyLandingWaypoints(waypoints)
          }
          goToLocation={goToLocation}
        />
      </div>
      {formError.emergencyPoint && (
        <p className="text-red-500 text-sm -mt-2 mb-4">
          {formError.emergencyPoint}
        </p>
      )}

      {/* Operation Configuration */}
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="elevation" className="label">
            Elevation (m)
          </label>
          <input
            type="number"
            className="input-text"
            name="elevation"
            defaultValue={form.elevation}
            onChange={handleTextChange}
          />
          {formError.elevation && (
            <p className="text-red-500 text-sm mt-1">{formError.elevation}</p>
          )}
        </div>
        <div className="w-full">
          <label htmlFor="altitude" className="label">
            Max Altitude (m)
          </label>
          <input
            type="number"
            name="altitude"
            className="input-text"
            defaultValue={form.altitude}
            onChange={handleTextChange}
          />
          {formError.altitude && (
            <p className="text-red-500 text-sm mt-1">{formError.altitude}</p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="rth_altitude" className="label">
            RTH Altitude (m)
          </label>
          <input
            type="number"
            name="rth_altitude"
            className="input-text"
            defaultValue={form.contingency_plans.safe_altitude}
            onChange={handleTextChange}
          />
          {formError.rth_altitude && (
            <p className="text-red-500 text-sm mt-1">
              {formError.rth_altitude}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Altitude Reference</label>
          <select
            name="altitude_reference"
            className="input-select"
            onChange={(e) => handleSelectChange(e, "altitude_reference")}
          >
            <option value="W84">W84</option>
            <option value="AGL">AGL</option>
          </select>
          {formError.altitude_reference && (
            <p className="text-red-500 text-sm mt-1">
              {formError.altitude_reference}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="vertical_buffer" className="label">
            Vertical Buffer (m)
          </label>
          <input
            type="number"
            name="vertical_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            defaultValue={form.vertical_buffer}
            onChange={handleTextChange}
          />
          {formError.vertical_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.vertical_buffer}
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Lateral Buffer (m)</label>
          <input
            type="number"
            name="lateral_buffer"
            className={customBuffer ? "input-text" : "input-text-disabled"}
            disabled={!customBuffer}
            defaultValue={form.lateral_buffer}
            onChange={handleTextChange}
          />
          {formError.lateral_buffer && (
            <p className="text-red-500 text-sm mt-1">
              {formError.lateral_buffer}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="custom_buffer"
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Custom Buffer</label>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label className="label">Time Buffer (s)</label>
          <input
            type="number"
            name="time_buffer"
            className="input-text"
            defaultValue={form.time_buffer}
            onChange={handleTextChange}
          />
          {formError.time_buffer && (
            <p className="text-red-500 text-sm mt-1">{formError.time_buffer}</p>
          )}
        </div>
        <div className="w-full">
          <label className="label">Speed (m/s)</label>
          <input
            type="number"
            name="ground_speed"
            className="input-text"
            defaultValue={form.ground_speed}
            onChange={handleTextChange}
          />
          {formError.ground_speed && (
            <p className="text-red-500 text-sm mt-1">
              {formError.ground_speed}
            </p>
          )}
        </div>
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="Departure Time (Local Time)"
            value={departureDate}
            className="mr-2"
            onChange={(date) => handleDateChange(date, "departure")}
          />
          {formError.time_start && (
            <p className="text-red-500 text-sm mt-1">{formError.time_start}</p>
          )}
        </div>
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="End Time (Local Time)"
            value={arrivalDate}
            onChange={(date) => handleDateChange(date, "arrival")}
          />
          {formError.time_start && (
            <p className="text-red-500 text-sm mt-1">{formError.time_start}</p>
          )}
        </div>
      </div>
      <div className="mb-2">
        <label htmlFor="max_segment_length" className="label">
          Max Segment Length (m)
        </label>
        <input
          type="number"
          name="max_segment_length"
          className="input-text"
          defaultValue={form.max_segment_length}
          onChange={handleTextChange}
        />
        {formError.max_segment_length && (
          <p className="text-red-500 text-sm mt-1">
            {formError.max_segment_length}
          </p>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="pilot" className="label">
          Pilot Name
        </label>
        <select
          name="pilot_uuid"
          onChange={(e) => handleSelectChange(e, "pilot_uuid")}
          className="input-select"
        >
          <option>Select</option>
          {pilots.map((pilot) => (
            <option key={pilot.pilot_uuid} value={pilot.pilot_uuid}>
              {pilot.pilot_username}
            </option>
          ))}
        </select>
        {formError.pilot_uuid && (
          <p className="text-red-500 text-sm mt-1">{formError.pilot_uuid}</p>
        )}
      </div>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="platform" className="label">
            Platform Callsign
          </label>
          <select
            name="platform_uuid"
            onChange={(e) => handleSelectChange(e, "platform_uuid")}
            className="input-select"
          >
            <option>Select</option>
            {platforms.map((platform) => (
              <option
                key={platform.platform_uuid}
                value={platform.platform_uuid}
              >
                {platform.platform_callsign}
              </option>
            ))}
          </select>
          {formError.platform_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.platform_uuid}
            </p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="tracker" className="label">
            Tracker Callsign
          </label>
          <select
            name="tracker_uuid"
            onChange={(e) => handleSelectChange(e, "tracker_uuid")}
            className="input-select"
          >
            <option>Select</option>
            {trackers.map((tracker) => (
              <option key={tracker.tracker_uuid} value={tracker.tracker_uuid}>
                {tracker.tracker_name}
              </option>
            ))}
          </select>
          {formError.tracker_uuid && (
            <p className="text-red-500 text-sm mt-1">
              {formError.tracker_uuid}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="airspace_optimised"
          checked={form.airspace_optimised}
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Optimized</label>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="input-checkbox"
          name="two_way"
          defaultChecked={form.transit_duration !== 0}
          onChange={handleCheckboxChange}
        />
        <label className="ml-2 text-sm">Two Way</label>
      </div>
      {/* Transit Duration */}
      {twoWay && (
        <div className="mb-4">
          <h4>Transit Duration</h4>
          <div className="flex">
            <div className="w-full mr-2">
              <label htmlFor="transit_duration" className="label">
                Transit Duration (h)
              </label>
              <input
                type="number"
                name="transit_duration_hour"
                className="input-text"
                onChange={handleTextChange}
              />
              {formError.transit_duration_hour && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.transit_duration_hour}
                </p>
              )}
            </div>
            <div className="w-full">
              <label htmlFor="transit_duration" className="label">
                Transit Duration (m)
              </label>
              <input
                type="number"
                name="transit_duration_minute"
                className="input-text"
                onChange={handleTextChange}
              />
              {formError.transit_duration_minute && (
                <p className="text-red-500 text-sm mt-1">
                  {formError.transit_duration_minute}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="intent" className="label">
            Operation Intent
          </label>
          <input
            type="text"
            name="intent"
            className="input-text"
            defaultValue={form.description}
            onChange={handleTextChange}
          />
          {formError.intent && (
            <p className="text-red-500 text-sm mt-1">{formError.intent}</p>
          )}
        </div>

        <div className="w-full">
          <label htmlFor="operation_type" className="label">
            Type
          </label>
          <select
            className="input-select"
            name="operation_type"
            onChange={(e) => handleSelectChange(e, "operation_type")}
          >
            <option value="bvlos">BVLOS</option>
            <option value="vlos">VLOS</option>
          </select>
          {formError.operation_type && (
            <p className="text-red-500 text-sm mt-1">
              {formError.operation_type}
            </p>
          )}
        </div>
      </div>
      <div className="mb-6">
        <label htmlFor="description" className="label">
          Description
        </label>
        <textarea
          className="input-text"
          name="description"
          defaultValue={form.description}
          onChange={handleTextAreaChange}
        />
        {formError.description && (
          <p className="text-red-500 text-sm mt-1">{formError.description}</p>
        )}
      </div>

      <div className="flex mt-4 mb-8 pb-8">
        <Button
          type="light"
          size="medium"
          text="Reset"
          className="mr-2"
          disabled={isLoading}
          onClick={handleResetForm}
        />

        <Button
          type="primaryDark"
          size="medium"
          text="Submit"
          isLoading={isLoading}
          onClick={handleSubmitOperation}
        />
      </div>
    </div>
  );
}
