import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@material-ui/core/Typography";

export function ContigentComponent({
  handleContingent,
  showPopup,
  setShowPopup,
}) {
  const handleClose = () => {
    setShowPopup(false);
  };
  const handleSubmit = () => {
    setShowPopup(false);
    handleContingent();
  };
  return (
    <Dialog
      disableEscapeKeyDown
      // disableBackdropClick
      open={showPopup}
      onClose={handleClose}
    >
      <DialogTitle>
        Set Contingent
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography display="inline" variant="h6" style={{ color: "black" }}>
          Set the state of the operation to contingent? A contingent volume will
          be generated and nearby affected flights will be notified of your
          contingent operation.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
