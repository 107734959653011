import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import { useApi } from "../../api/useApi";
import { setSnackbarMessage } from "../../store/actions";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errorMessage,
  setError,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value}
      onChange={(event) => {
        if (errorMessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={!!fullWidth}
      autoComplete="off"
      required
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
}

export default function AddPlatformTypeFormComponent({
  open,
  updating,
  platformTypeData,
  handleClose,
  handlePlatformTypeUpdate,
}) {
  const [model, setModel] = React.useState("");
  const [modelError, setModelError] = React.useState("");

  const [airframe, setAirframe] = React.useState("");
  const [airframeError, setAirframeError] = React.useState("");

  const [takeoffspeed, setTakeoffspeed] = React.useState("");
  const [takeoffspeedError, setTakeoffspeedError] = React.useState("");

  const [cruisingspeed, setCruisingspeed] = React.useState("");
  const [cruisingspeedError, setCruisingspeedError] = React.useState("");

  const [landingspeed, setLandingspeed] = React.useState(new Date());
  const [landingspeedError, setLandingspeedError] = React.useState("");

  const [endurance, setEndurance] = React.useState(new Date());
  const [enduranceError, setEnduranceError] = React.useState("");

  const [radiorange, setRadiorange] = React.useState(new Date());
  const [radiorangeError, setRadiorangeError] = React.useState("");

  const [tsemean, setTsemean] = React.useState(new Date());
  const [tsemeanError, setTsemeanError] = React.useState("");

  const [tsevariance, setTsevariance] = React.useState(new Date());
  const [tsevarianceError, setTsevarianceError] = React.useState("");

  const [platformWeight, setPlatformWeight] = React.useState();
  const [platformWeightError, setPlatformWeightError] = React.useState("");

  const [submittingNewPlatformType, setSubmittingNewPlatformType] =
    React.useState(false);

  React.useEffect(() => {
    if (updating) {
      const {
        model: _model,
        airframe: _airframe,
        speed_takeoff,
        speed_curising,
        speed_landing,
        endurance: _edurance,
        radio_range,
        tse_mean,
        tse_variance,
        weight,
      } = platformTypeData;
      setModel(_model);
      setAirframe(_airframe);
      setTakeoffspeed(speed_takeoff);
      setCruisingspeed(speed_curising);
      setLandingspeed(speed_landing);
      setEndurance(_edurance);
      setRadiorange(radio_range);
      setTsemean(tse_mean);
      setTsevariance(tse_variance);
      setPlatformWeight(weight);
    } else {
      setModel("");
      setAirframe(uavType[0].value);
      setTakeoffspeed("");
      setCruisingspeed("");
      setLandingspeed("");
      setEndurance("");
      setRadiorange("");
      setTsemean("");
      setTsevariance("");
      setPlatformWeight("");
    }
    setModelError("");
    setAirframeError("");
    setTakeoffspeedError("");
    setCruisingspeedError("");
    setLandingspeedError("");
    setEnduranceError("");
    setRadiorangeError("");
    setTsemeanError("");
    setTsevarianceError("");
  }, [open]);
  const api = useApi();
  const dispatch = useDispatch();

  const submitNewPlatformType = async () => {
    try {
      const response = await api.submitNewPlatformType({
        model,
        airframe,
        takeoffspeed,
        cruisingspeed,
        landingspeed,
        endurance,
        radiorange,
        tsemean,
        tsevariance,
        weight: platformWeight,
      });
      if (response.data) {
        handlePlatformTypeUpdate(response.data);
        setSubmittingNewPlatformType(false);
        // TODO: Update platform array so we dont need to refresh page

        dispatch(
          setSnackbarMessage({
            open: true,
            message: `Platform type ${model} successfully updated`,
            severity: "success",
          })
        );
        handleClose();
      }
    } catch (err) {
      setSubmittingNewPlatformType(false);
    }
  };

  const updatePlatformTypeInfo = async () => {
    try {
      const response = await api.updatePlatformTypeInfo(
        platformTypeData.platform_type_uuid,
        {
          model,
          airframe,
          takeoffspeed,
          cruisingspeed,
          landingspeed,
          endurance,
          radiorange,
          tsemean,
          tsevariance,
          weight: platformWeight,
        }
      );
      if (response.data) {
        handlePlatformTypeUpdate({
          platform_type_uuid: platformTypeData.platform_type_uuid,
          ...response.data,
        });
        setSubmittingNewPlatformType(false);
        // TODO: Update platform type array so we dont need to refresh page
        handleClose();
      }
    } catch (err) {
      if (err.response.status === 400) {
        window.alert(err.response.data.message);
      }
      setSubmittingNewPlatformType(false);
    }
  };

  const validateForm = () => {
    let error = false;
    if (!model) {
      setModelError("UAV model name is required");
      error = true;
    }
    if (!airframe) {
      setAirframeError("UAV airframe type is required");
      error = true;
    }
    if (!takeoffspeed) {
      setTakeoffspeedError("Takeoff speed is required");
      error = true;
    }
    if (!cruisingspeed) {
      setCruisingspeedError("Cruising speed is required");
      error = true;
    }
    if (!landingspeed) {
      setLandingspeedError("Landing speed is required");
      error = true;
    }
    if (!endurance) {
      setEnduranceError("Endurance value is required");
      error = true;
    }
    if (!radiorange) {
      setRadiorangeError("RF range is required");
      error = true;
    }
    if (!tsemean) {
      setTsemeanError("Mean TSE value is required");
      error = true;
    }
    if (!tsevariance) {
      setTsevarianceError("TSE variance value is required");
      error = true;
    }
    if (!platformWeight) {
      setPlatformWeightError("Weight is required");
      error = true;
    }
    if (isNaN(platformWeight) || platformWeight < 0) {
      setPlatformWeightError("Invalid value");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    if (formErrors) return;

    setSubmittingNewPlatformType(true);
    if (updating) {
      updatePlatformTypeInfo();
    } else {
      submitNewPlatformType();
    }
  };
  const uavType = [
    {
      id: "1",
      item: "Multi Rotor",
      value: "Multi Rotor",
    },
    {
      id: "2",
      item: "Fixed Wing",
      value: "Fixed Wing",
    },
    {
      id: "3",
      item: "Powered Lift",
      value: "Powered Lift",
    },
    {
      id: "4",
      item: "Airship",
      value: "Airship",
    },
  ];
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
    >
      <LoadingOverlay
        active={submittingNewPlatformType}
        spinner
        text={
          updating
            ? "Updating platform type information..."
            : "Submitting new platform type..."
        }
      >
        <div style={{ height: "450px", width: "500px", margin: "30px" }}>
          <Typography
            component="div"
            display="inline"
            variant="h6"
            style={{ color: "black" }}
          >
            {updating ? "Update Platform Type" : "Add Platform Type"}
          </Typography>
          <CustomTextField
            label="UAV Model"
            value={model}
            setFuncton={setModel}
            fullWidth
            errorMessage={modelError}
            setError={setModelError}
          />
          {/* <CustomTextField
            label="UAV Airframe Type"
            value={airframe}
            setFuncton={setAirframe}
            fullWidth
            errorMessage={airframeError}
            setError={setAirframeError}
          /> */}
          <TextField
            id="UAVAirframeType"
            label="UAV Airframe Type"
            select
            key={airframe}
            defaultValue={airframe}
            value={airframe}
            fullWidth
            onChange={(event) => {
              setAirframe(event.target.value);
            }}
            margin="normal"
            autoComplete="off"
            required
          >
            {uavType.map((data) => (
              <MenuItem key={data.id} value={data.value}>
                {data.item}
              </MenuItem>
            ))}
          </TextField>
          <CustomTextField
            label="Takeoff Speed (m/s)"
            value={takeoffspeed}
            setFuncton={setTakeoffspeed}
            fullWidth={false}
            errorMessage={takeoffspeedError}
            setError={setTakeoffspeedError}
          />
          <CustomTextField
            label="Cruising Speed (m/s)"
            value={cruisingspeed}
            setFuncton={setCruisingspeed}
            style={{ marginLeft: "80px" }}
            fullWidth={false}
            errorMessage={cruisingspeedError}
            setError={setCruisingspeedError}
          />
          <CustomTextField
            label="Endurance (s)"
            value={endurance}
            setFuncton={setEndurance}
            fullWidth={false}
            errorMessage={enduranceError}
            setError={setEnduranceError}
          />
          <CustomTextField
            label="Landing Speed (m/s)"
            value={landingspeed}
            setFuncton={setLandingspeed}
            fullWidth={false}
            errorMessage={landingspeedError}
            setError={setLandingspeedError}
          />
          <CustomTextField
            label="RF Range (km)"
            value={radiorange}
            setFuncton={setRadiorange}
            fullWidth={false}
            errorMessage={radiorangeError}
            setError={setRadiorangeError}
          />
          <CustomTextField
            label="TSE (Mean)"
            value={tsemean}
            setFuncton={setTsemean}
            fullWidth={false}
            errorMessage={tsemeanError}
            setError={setTsemeanError}
          />
          <CustomTextField
            label="TSE (Variance)"
            value={tsevariance}
            setFuncton={setTsevariance}
            errorMessage={tsevarianceError}
            setError={setTsevarianceError}
          />
          <CustomTextField
            label="Weight (kg)"
            value={platformWeight}
            setFuncton={setPlatformWeight}
            errorMessage={platformWeightError}
            setError={setPlatformWeightError}
          />
        </div>
        <MuiDialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </MuiDialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}
