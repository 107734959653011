import * as React from "react";
import { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Alert from "@mui/material/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import EmbedMapB from "../MapB/embedMapB";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { useApi } from "../../api/useApi";
import {
  setSnackbarMessage,
  setOperationFormRequest,
} from "../../store/actions";

const styles = {
  customMaxWidth: {
    maxWidth: "none", // arbitrary value
  },
};

function ConfirmationDialog({
  open,
  onConfirm,
  onCancel,
  loading,
  isDelete,
  isDeleteBothOperations,
  setIsDeleteBothOperations,
  isTwoWayOperation,
}) {
  const resubmitMessage =
    "Are you sure you want to resubmit the original operation for manual approval?";
  const cancelMessage =
    "Are you sure you want to discard the original operation?";
  return (
    <Dialog open={open} onClose={onCancel}>
      <LoadingOverlay
        active={loading}
        spinner
        text={isDelete ? "Deleting operation..." : "Submitting operation..."}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isDelete ? cancelMessage : resubmitMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </LoadingOverlay>
    </Dialog>
  );
}

export default function RejectAcceptOperationResponsiveDialog({
  reScheduled,
  reRouted,
  openReject,
  handleClose,
  compareData,
  compareCurrentData,
  setRoutedSchedEdit,
  setIfEditMode,
  twoWayOperationUuid,
  handleDiscardTwoWayOperation,
  isTakeOffOperation,
  handleTakeOffOperation,
  handleActivateOperationOnly,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  // for loading overlay purposes
  const [isDelete, setIsDelete] = useState(false);
  // used for two way operations
  const [isDeleteBothOperations, setIsDeleteBothOperations] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(reScheduled, reRouted, compareData, compareCurrentData);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [acknowledgeWarning, setAcknowledgeWarning] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  let resolutionType;
  const enableDeleteTwoWayOperation = useRef(false);
  if (reScheduled) {
    if (reRouted) resolutionType = "rerouted and rescheduled";
    else resolutionType = "rescheduled";
  } else resolutionType = "rerouted";

  // this is used to prevent incorrect deletion of two way
  // user creates 2 way operation (op1) which is rerouted
  // user then clicks on different operation (op2) reproposed by authority
  // user trys to delete op2
  // twoWayOperationUuid holds op1 uuid when user clicks delete op2, causing it to delete op1 instead
  useEffect(() => {
    if (!compareData?.request?.two_way) {
      enableDeleteTwoWayOperation.current = false;
      return;
    }
    const currentOperationUuid = compareData?.reference?.id;
    if (twoWayOperationUuid.includes(currentOperationUuid)) {
      enableDeleteTwoWayOperation.current = true;
    } else {
      enableDeleteTwoWayOperation.current = false;
    }
  }, [twoWayOperationUuid, compareData]);

  const handleCancelOpsClick = async () => {
    setIsDelete(true);
    setConfirmDialog(true);
  };

  const handleResubmitOriginalOpsClick = async () => {
    setConfirmDialog(true);
  };

  const handleConfirm = async () => {
    if (isDelete) {
      try {
        setSubmitLoading(true);
        if (compareData.request.two_way) {
          // to prevent return operation dialog popup after deleting 1st operation
          handleDiscardTwoWayOperation();
          const response = await Promise.all([
            api.deleteOperation(compareData.reference.mission_list[0]),
            api.deleteOperation(compareData.reference.mission_list[1]),
          ]);
          if (response[0].status === 200 && response[1].status === 200) {
            dispatch(
              setSnackbarMessage({
                open: true,
                message: "Operations Deleted",
                severity: "success",
              })
            );
          } else {
            dispatch(
              setSnackbarMessage({
                open: true,
                message:
                  "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
                severity: "Error",
              })
            );
          }
          setIsDelete(false);
          setConfirmDialog(false);
          setSubmitLoading(false);
          handleClose(false);
        } else {
          const response = await api.deleteOperation(compareData.reference.id);
          if (response.status === 200) {
            dispatch(
              setSnackbarMessage({
                open: true,
                message: "Operations Deleted",
                severity: "success",
              })
            );
          }
        }
      } catch (err) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message:
              "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
            severity: "Error",
          })
        );
      } finally {
        setIsDelete(false);
        setConfirmDialog(false);
        setSubmitLoading(false);
        handleClose(false);
      }
    } else {
      try {
        setSubmitLoading(true);
        const { request } = compareData;
        if (request.two_way)
          request.description = compareData?.reference?.description;
        const response = await api.editOperation(
          compareData.reference.id,
          request,
          "true"
        );
        if (response.status === 200) {
          dispatch(
            setSnackbarMessage({
              open: true,
              message:
                "Original plan resubmitted successfully for manual approval",
              severity: "success",
            })
          );
        } else {
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "Unable to resubmit original plan.",
              severity: "error",
            })
          );
        }
      } catch (err) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: "Unable to resubmit original plan.",
            severity: "error",
          })
        );
      } finally {
        setSubmitLoading(false);
        setConfirmDialog(false);
        handleClose();
      }
    }

    // }
  };
  const handleEditOpsClick = async () => {
    // handle two way return trip
    if (compareData?.request?.two_way) {
      const { request } = compareData;
      request.description = compareData?.reference?.description;
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request,
        })
      );
    } else {
      dispatch(
        setOperationFormRequest({
          id: compareData.reference.id,
          type: "edit",
          request: compareData.request,
        })
      );
    }
    handleClose(true);
  };

  const closeHandler = (event, reason) => {
    event.preventDefault();
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      setAcknowledgeWarning(true);
      return;
    }
    handleClose();
  };

  const handleCancel = () => {
    setConfirmDialog(false);
    setTimeout(() => {
      setIsDelete(false);
      setIsDeleteBothOperations(false);
    }, 300);
  };

  const handleAcceptandTakeOff = (e) => {
    // operation is not rescheduled
    if (resolutionType === "rerouted") {
      handleActivateOperationOnly();
    } else {
      handleTakeOffOperation(e);
    }
    handleClose();
  };

  return (
    <div>
      <div>
        <Dialog
          disableEscapeKeyDown
          // fullScreen={fullScreen}
          fullWidth
          maxWidth="sm"
          open={openReject}
          onClose={closeHandler}
          aria-labelledby="responsive-dialog-title"
          // disableBackdropClick
        >
          <LoadingOverlay
            active={submitLoading}
            spinner
            text={
              isDelete ? "Deleting operation..." : "Submitting operation..."
            }
          >
            {/* {acknowledgeWarning && (
              <Alert severity="error">PLEASE ACKNOWLEDGE MESSAGE!</Alert>
            )} */}
            {/* <DialogTitle id="responsive-dialog-title">
              Your flight plan was {`${resolutionType} `}
            </DialogTitle> */}
            <DialogTitle id="responsive-dialog-title">
              Operation{" "}
              <span style={{ color: "red" }}>
                {compareData?.reference?.description}
              </span>{" "}
              was
              {` ${resolutionType} `}
            </DialogTitle>
            <DialogContent dividers>
              <Grid
                container
                spacing={0}
                direction="column"
                justifyContent="center"
              >
                <Grid item sx={{ m: 2 }} xs={12}>
                  The flight plan you have submitted conflicts with existing
                  operation/constraints. It has been automatically deconflicted
                  for you.
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    justifyContent="center"
                    alignItems="start"
                  >
                    <Grid item xs={12}>
                      <Typography display="inline" style={{ color: "black" }}>
                        Original Start Time :{" "}
                        {convertZuluToLocalTime(compareCurrentData.time_start)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        display="inline"
                        style={{ color: reScheduled ? "red" : "black" }}
                      >
                        {`${
                          reScheduled
                            ? `Rescheduled Start Time : ${convertZuluToLocalTime(
                                compareData.interuss
                                  ?.operational_intent_reference?.time_start
                                  .value
                              )}`
                            : "-"
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div>
                    <EmbedMapB
                      compareCurrentData={compareCurrentData}
                      compareData={compareData}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isTakeOffOperation ? (
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  autoFocus
                  onClick={handleCancelOpsClick}
                >
                  Discard Plan
                </Button>
              ) : (
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="secondary"
                  autoFocus
                  onClick={() => handleClose()}
                >
                  Cancel Takeoff
                </Button>
              )}
              {!isTakeOffOperation && (
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleResubmitOriginalOpsClick}
                  autoFocus
                >
                  Resubmit Original
                </Button>
              )}
              <Button
                type="button"
                size="small"
                variant="contained"
                color="primary"
                onClick={handleEditOpsClick}
                autoFocus
              >
                Edit Plan
              </Button>
              {!isTakeOffOperation ? (
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleClose(false)}
                  autoFocus
                >
                  Accept Changes
                </Button>
              ) : (
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAcceptandTakeOff}
                  autoFocus
                >
                  Continue TakeOff
                </Button>
              )}
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
      </div>
      <div>
        <ConfirmationDialog
          open={confirmDialog}
          // message="Are you sure that you want to resubmit the original operation for manual approval?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          loading={submitLoading}
          isDelete={isDelete}
          isDeleteBothOperations={isDeleteBothOperations}
          setIsDeleteBothOperations={setIsDeleteBothOperations}
          isTwoWayOperation={enableDeleteTwoWayOperation.current}
        />
      </div>
    </div>
  );
}
