import { ButtonGroup, Button } from "@material-ui/core";
import { Fragment } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export default function AreaEntries(props) {
  const {
    selectedAreaWaypoints,
    onChange,
    onFocus,
    handleOpenFlightAuthorization,
    resetAreaWayPoints,
    updateAreaLongitude,
    updateAreaLatitude,
  } = props;
  const numbersOfWaypoints = selectedAreaWaypoints?.length;

  const handleLatitudeChange = (newLatitude, index) => {
    if (newLatitude !== "" && newLatitude !== null) {
      updateAreaLatitude(newLatitude, index);
    } else {
      updateAreaLatitude("", index);
    }
  };

  const handleLongitudeChange = (newLongitude, index) => {
    if (newLongitude !== "" && newLongitude !== null) {
      updateAreaLongitude(newLongitude, index);
    } else {
      updateAreaLongitude("", index);
    }
  };

  return (
    <Grid container columns={12} spacing={0.5} sx={{ marginTop: "15px" }}>
      <Grid item xs={1}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          WP
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LAT (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={4.25}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          LONG (DECIMAL)
        </Typography>{" "}
      </Grid>
      <Grid item xs={2.5}>
        <Typography
          textAlign="center"
          fontWeight="bold"
          fontSize="14px"
          // sx={{ margin: "10px 0px 20px 0px" }}
        >
          {" "}
          Actions
        </Typography>{" "}
      </Grid>

      {selectedAreaWaypoints.map((waypoint, i) => (
        <Fragment key={`waypoint${i + 1}`}>
          {/* <span style={{ height: "12px", lineHeight: "12px", margin: "auto" }}> */}
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {i + 1}
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="waypoint-latitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[1]}
              onChange={(ev) => {
                const inputValue = ev.target.value;
                if (inputValue.trim() === "" || inputValue === null) {
                  handleLatitudeChange("", i);
                } else {
                  const parsedValue = Number.parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    handleLatitudeChange(parsedValue, i);
                  }
                }
                // selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              id="waypoint-longitude"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-input": { padding: "8px" },
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "black" },
              }}
              inputProps={{ sx: { fontSize: 14 } }} // font size of input text
              value={waypoint[0]}
              onChange={(ev) => {
                const inputValue = ev.target.value;
                if (inputValue.trim() === "" || inputValue === null) {
                  handleLongitudeChange("", i);
                } else {
                  const parsedValue = Number.parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    handleLongitudeChange(parsedValue, i);
                  }
                }
                // selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
                // onChange([...selectedWaypoints]);
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            />
          </Grid>
          {/* <input
            type="number"
            width={100}
            value={waypoint[1]}
            name={`waypointLatititude${i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][1] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          />
          <input
            width={100}
            type="number"
            value={waypoint[0]}
            name={`waypointLongitude${i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][0] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          /> */}
          <Grid item xs={2.5}>
            <ButtonGroup>
              <Button
                onClick={() => {
                  onFocus(waypoint);
                }}
              >
                <CenterFocusIcon />
              </Button>
              <Button
                onClick={() => {
                  onChange([
                    ...selectedAreaWaypoints.slice(0, i),
                    ...selectedAreaWaypoints.slice(i + 1),
                  ]);
                }}
                // disabled={i === 0 || i + 1 === numbersOfWaypoints}
                disabled={i <= 2}
              >
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
          onClick={() => {
            onChange([
              ...selectedAreaWaypoints.slice(0, -1),
              [0, 0],
              selectedAreaWaypoints[numbersOfWaypoints - 1],
            ]);
          }}
        >
          Add way point
        </Button>
      </Grid>
    </Grid>
    // </div>
  );
}
