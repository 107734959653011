import axios from "axios";

/**
 * FIMS SDSP API
 */
const sdsp = axios.create();

// sdsp.interceptors.request.use((config) => {
//   config.headers = {
//     ...config.headers,
//     "content-type": "application/json",
//     Authorization: jwtToken,
//   };
//   return config;
// });

const sdspbuilding = axios.create();
/**
 * CIF PROD API = REACT_APP_CIF_BASE_URL\ for dev = REACT_APP_FIMS_SDSP_URL
 */
let cifURL = "";
let cifAuthUrl = "";
let fimsAtmUrl = "";
let client_id = "";
let client_secret = "";

const updateUrl = (envVarUrls) => {
  sdsp.defaults.baseURL = envVarUrls.sdspBaseUrl;
  sdspbuilding.defaults.baseURL = envVarUrls.sdspBaseUrl;

  cifURL = envVarUrls.cifBaseUrl;
  cifAuthUrl = envVarUrls.cifAuthUrl;
  fimsAtmUrl = envVarUrls.fimsAtmUrl;
  client_id = envVarUrls.fimsClientId;
  client_secret = envVarUrls.fimsClientSecret;
};

async function getCifToken() {
  const cifToken = localStorage.getItem("cifToken");
  const cifExpiry = localStorage.getItem("cifExpiry");
  if (cifToken && cifExpiry) {
    const expiryDate = new Date(cifExpiry);
    const currentDate = new Date();
    if (currentDate.getTime() < expiryDate.getTime()) {
      return cifToken;
    }
  }

  const params = new URLSearchParams();
  params.append("grant_type", "client_credentials");
  params.append(
    "scope",
    "atm/traffic.read atm/utm.strategic_coordination cif/constraints.read cif/constraints.write cif/utm.constraint_management cif/utm.constraint_processing sdsp/adsb.read sdsp/ais.read sdsp/building.read sdsp/notams.read sdsp/rainfall.read"
  );
  params.append("client_id", client_id);
  params.append("client_secret", client_secret);

  const configHeaders = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post(cifAuthUrl, params, configHeaders);
    const token = response.data.access_token;
    localStorage.setItem("cifToken", token);
    // Calculate expiry time of cif token
    localStorage.setItem(
      "cifExpiry",
      new Date(new Date().getTime() + 50 * 60 * 1000).toISOString()
    );
    return token;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const sharedEndpoints = {
  updateSharedApiBaseUrl: (envVarUrls) => {
    updateUrl(envVarUrls);
  },
  getConstraints: async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "get",
      url: `${cifURL}/constraints/query/active/${Math.round(
        new Date().getTime() / 1000
      )}`,
      headers: {
        Authorization: auth,
      },
    });
  },
  getAdsb: async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "get",
      url: `${fimsAtmUrl}/traffic/query/active/${Math.round(
        // takes in time in epoch seconds to start getting adsb data (free).
        // converts current time to seconds, then subtracts the amount of time
        // to lookback.
        new Date().getTime() / 1000 - 60 * 5
      )}`,
      headers: {
        Authorization: auth,
      },
    });
  },

  getBuilding: async (data) =>
    sdspbuilding.request({
      method: "post",
      url: `/sdsp/buildings/query/polygon`,
      data,
      // params: { api_key: config.vessel_api_key },
    }),
  getShips: async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return sdsp.request({
      method: "get",
      url: `/sdsp/ais/snapshot`,
      // params: { api_key: config.vessel_api_key },
      headers: {
        "content-type": "application/json",
        Authorization: auth,
      },
    });
  },
  // SDSP version
  getADSB: async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return sdsp.request({
      method: "get",
      url: `/sdsp/adsb/snapshot`,
      headers: {
        "content-type": "application/json",
        Authorization: auth,
      },
    });
  },
  getGlobaConstraints: async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "post",
      url: `${cifURL}/constraints/query/global`,
      headers: {
        Authorization: auth,
      },
    });
  },

  submitAuthorityAreaConstrain: async (data) => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "post",
      url: `${cifURL}/constraints/create/uvr`,
      data,
      headers: {
        Authorization: auth,
      },
    });
  },
  submitUpdateAuthorityAreaConstrain: async (data) => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "post",
      url: `${cifURL}/constraints/update/uvr`,
      data,
      headers: {
        Authorization: auth,
      },
    });
  },
  submitAuthorityAreaConstrainPerm: async (data) => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "post",
      url: `${cifURL}/constraints/create/gz`,
      data,
      headers: {
        Authorization: auth,
      },
    });
  },
  submitUpdateAuthorityAreaConstrainPerm: async (data) => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "post",
      url: `${cifURL}/constraints/update/gz`,
      data,
      headers: {
        Authorization: auth,
      },
    });
  },
  deleteAuthorityAreaConstrain: async (data) => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    return axios.request({
      method: "delete",
      url: `${cifURL}/constraints/remove/${data}`,
      headers: {
        Authorization: auth,
      },
    });
  },
};
