import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../store/actions";
import { store } from "../store";

const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const useCognitoAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const envVar = useSelector((state) => state.envVar);

  const logout = () => {
    const params = new URLSearchParams();
    localStorage.clear();
    dispatch(
      setAuth({
        accessToken: "",
        idObject: null,
        isPilot: false,
        isOperator: false,
        isAuthority: false,
        isRemoteId: false,
        isLoggedIn: false,
      })
    );

    const cognitoDomain = envVar["cognito_domain-non_authority"]
      ? envVar["cognito_domain-non_authority"]?.Value
      : "";
    const authCognitoDomain = envVar["cognito_domain-authority"]
      ? envVar["cognito_domain-authority"]?.Value
      : "";
    const clientId = envVar["app_client_id-default"]
      ? envVar["app_client_id-default"]?.Value
      : "";
    const authClientId = envVar["app_client_id-authority"]
      ? envVar["app_client_id-authority"]?.Value
      : "";
    if (auth.isAuthority) {
      window.location.href = `${authCognitoDomain}/logout?client_id=${authClientId}&logout_uri=${redirectUri}`;
    } else {
      window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
    }
  };

  return {
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    isLoggedIn: auth.isLoggedIn,
    username: auth.userInfo?.["cognito:username"],
    isPilot: auth.isPilot,
    isOperator: auth.isOperator,
    isAuthority: auth.isAuthority,
    isRemoteId: auth.isRemoteId,
    logout,
  };
};

export default useCognitoAuth;
