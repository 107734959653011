import { removeFeatures, removeFeaturesAll } from "./init";

export default class MapboxController {
  constructor(map, draw, setFocussedOperationState) {
    this.map = map;
    this.draw = draw;
    this.setFocussedOperationState = setFocussedOperationState;
    this.highlightedGeometryLayerId = "highlighted-geometry";
    this.highlightedAreaGeometryLayerId = "highlighted-Area-geometry";
    this.highlightedArea2GeometryLayerId = "highlighted-Area2-geometry";
  }

  async flyTo(coordinates, zoomLevel = 12) {
    // added default zoom level
    this.map.flyTo({ center: coordinates, zoom: zoomLevel });
  }

  setFocussedOperation(operation) {
    if (operation) {
      if (operation.details) {
        const opsArea = operation.details.operation_area;
        this.flyTo(opsArea.volume.outline_polygon.coordinates[0][0], 12);
      } else {
        this.flyTo(operation.request.arrival, 12);
      }
    }
    this.setFocussedOperationState(operation);
  }

  highlightGeometry(geometry) {
    this.removeHighlight();

    this.map.addSource(this.highlightedGeometryLayerId, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry,
      },
    });

    this.map.addLayer({
      id: this.highlightedGeometryLayerId,
      type: "fill",
      source: this.highlightedGeometryLayerId,
      paint: {
        "fill-color": "#00FF00",
        "fill-opacity": 0.7,
      },
    });
  }

  removeHighlight() {
    if (this.map.getLayer(this.highlightedGeometryLayerId)) {
      this.map.removeLayer(this.highlightedGeometryLayerId);
    }
    if (this.map.getSource(this.highlightedGeometryLayerId)) {
      this.map.removeSource(this.highlightedGeometryLayerId);
    }
  }

  highlightArea(geometry) {
    // Remove the previous highlight if it exists
    this.removeHighlightedArea();

    // Check if the source already exists
    if (!this.map.getSource(this.highlightedAreaGeometryLayerId)) {
      // Add the source if it doesn't exist
      this.map.addSource(this.highlightedAreaGeometryLayerId, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry,
        },
      });

      // Add the layer
      this.map.addLayer({
        id: this.highlightedAreaGeometryLayerId,
        type: "fill",
        source: this.highlightedAreaGeometryLayerId,
        paint: {
          "fill-color": "#00FF00",
          "fill-opacity": 0.3,
        },
      });
    } else {
      // Update the existing source with the new geometry
      this.map.getSource(this.highlightedAreaGeometryLayerId).setData({
        type: "Feature",
        geometry,
      });
    }
  }

  removeHighlightedArea() {
    if (this.map.getLayer(this.highlightedAreaGeometryLayerId)) {
      this.map.removeLayer(this.highlightedAreaGeometryLayerId);
    }
    if (this.map.getSource(this.highlightedAreaGeometryLayerId)) {
      this.map.removeSource(this.highlightedAreaGeometryLayerId);
    }
  }

  highlightArea2(geometry) {
    // Remove the previous highlight if it exists
    this.removeHighlightedArea2();

    // Check if the source already exists
    if (!this.map.getSource(this.highlightedArea2GeometryLayerId)) {
      // Add the source if it doesn't exist
      this.map.addSource(this.highlightedArea2GeometryLayerId, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry,
        },
      });

      // Add the layer
      this.map.addLayer({
        id: this.highlightedArea2GeometryLayerId,
        type: "fill",
        source: this.highlightedArea2GeometryLayerId,
        paint: {
          "fill-color": "#00FF00",
          "fill-opacity": 0.3,
        },
      });
    } else {
      // Update the existing source with the new geometry
      this.map.getSource(this.highlightedArea2GeometryLayerId).setData({
        type: "Feature",
        geometry,
      });
    }
  }

  removeHighlightedArea2() {
    if (this.map.getLayer(this.highlightedArea2GeometryLayerId)) {
      this.map.removeLayer(this.highlightedArea2GeometryLayerId);
    }
    if (this.map.getSource(this.highlightedArea2GeometryLayerId)) {
      this.map.removeSource(this.highlightedArea2GeometryLayerId);
    }
  }

  changeDrawMode(mode) {
    this.draw.changeMode(mode);
  }

  removeDrawFeatures(featureType) {
    removeFeatures(featureType, this.draw);
  }

  removeDrawFeaturesAll() {
    removeFeaturesAll(this.draw);
  }

  getTerrain() {
    return this.map.getTerrain();
  }

  setTerrain(source) {
    this.map.setTerrain(source);
  }

  trash() {
    this.draw.trash();
  }
}
