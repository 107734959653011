import {
  SET_FOCUSSED_OPERATION,
  GET_USER_OPERATION_SUCCESS,
  SET_OPERATION_FORM_REQUEST,
} from "../actions/operations";

const operationsReducer = (state = { operations: [] }, action) => {
  switch (action.type) {
    case GET_USER_OPERATION_SUCCESS: {
      return {
        ...state,
        operations: action.payload,
      };
    }
    case SET_FOCUSSED_OPERATION: {
      return {
        ...state,
        focussedOperation: action.data.focussedOperation,
      };
    }
    case SET_OPERATION_FORM_REQUEST: {
      return {
        ...state,
        operationFormRequest: action.data.operationFormRequest,
      };
    }
    default:
      return state;
  }
};

export default operationsReducer;
