import * as React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  useTheme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { convertZuluToLocalTime } from "../../api/timeConvert";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";
import DetailsTrackers from "./DetailsTrackers";
import DetailsPaltforms from "./DetailsPaltforms";
import DetailsPilots from "./DetailsPilots";
import EmbedMapB from "../MapB/embedMapB";
import {
  setOperationFormRequest,
  setSnackbarMessage,
} from "../../store/actions";
import { parseJwt } from "../../api/operator";

export default function DetailsOperationsMapDialog({
  openDetailsDialog,
  handleCloseDetailsDialog,
  selectedDialogData,
  handleopenAuthProposeOperationDrawer,
  handleOpenFlightAuthorizationAuthority,
  getOperations,
  handleOperatorProposeOperation,
}) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [originalRequestData, setOriginalRequestData] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {}, [selectedDialogData]);
  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        // disableBackdropClick
        // fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <LoadingOverlay
          active={submitLoading}
          spinner
          text="Submitting operation..."
        >
          <DialogTitle id="responsive-dialog-title">
            {`Details of ${selectedDialogData?.reference?.description}`}
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={0}
              // direction="column"
              // justifyContent="start"
            >
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Operation :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.reference?.description}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Departure :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.interuss
                          ?.operational_intent_reference?.time_start?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Arrival :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.interuss
                          ?.operational_intent_reference?.time_end?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Pilot :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsPilots
                        pilot_uuid={selectedDialogData?.request?.pilot_uuid}
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Platform :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsPaltforms
                        platform_uuid={
                          selectedDialogData?.request?.platform_uuid
                        }
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Tracker :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      <DetailsTrackers
                        tracker_uuid={selectedDialogData?.request?.tracker_uuid}
                      />
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Status :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.details?.state}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Operation ID :
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {`${selectedDialogData?.reference?.id}`}{" "}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                // direction="column"
                // justifyContent="start"
              >
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Created On :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {convertZuluToLocalTime(
                        selectedDialogData?.reference?.time_created?.value
                      )}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    Clarification :{" "}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {" "}
                      {selectedDialogData?.request?.clarification}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div>
                  <EmbedMapB
                    compareCurrentData={
                      selectedDialogData?.request?.original_request?.description
                        ? selectedDialogData?.request?.original_request
                        : null
                    }
                    compareData={selectedDialogData}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ProposedOperationsHeader
              selectedFlights={[selectedDialogData?.reference?.id]}
              handleopenAuthProposeOperationDrawer={
                handleopenAuthProposeOperationDrawer
              }
              handleOpenFlightAuthorizationAuthority={
                handleOpenFlightAuthorizationAuthority
              }
              handleCloseDetailsDialog={handleCloseDetailsDialog}
              setSubmitLoading={setSubmitLoading}
              getOperations={getOperations}
              handleOperatorProposeOperation={handleOperatorProposeOperation}
              isTwoWayOperation={
                selectedDialogData?.request?.original_request?.two_way
              }
              twoWayOperationsUuid={selectedDialogData?.reference?.mission_list}
            />
            <Button
              type="button"
              size="small"
              variant="contained"
              color="primary"
              onClick={handleCloseDetailsDialog}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}

const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));

function ProposedOperationsHeader({
  selectedFlights,
  handleopenAuthProposeOperationDrawer,
  handleOpenFlightAuthorizationAuthority,
  handleCloseDetailsDialog,
  setSubmitLoading,
  getOperations,
  handleOperatorProposeOperation,
  isTwoWayOperation,
  twoWayOperationsUuid,
}) {
  const api = useApi();
  const dispatch = useDispatch();
  const classesFloating = useFloatingButton();
  const idToken = localStorage.getItem("idToken");
  const userInfo = parseJwt(idToken);
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const handleAcceptClick = async () => {
    setSubmitLoading(true);
    try {
      const res = await api.updateOperation(selectedFlights[0], "Accepted");
      // console.log(res);
      // success message and close dialog
      if (res?.status === 200) {
        const username = userInfo["cognito:username"];
        try {
          await api.sendEmail(
            `${username}`,
            `Flight ${selectedFlights[0]} has been accepted`,
            `Flight ${selectedFlights[0]} Approved`
          );
        } catch (e) {
          setSubmitLoading(false);
          console.log("error :", e);
        }
        if (getOperations) getOperations();
        setSubmitLoading(false);
        handleCloseDetailsDialog();
      }
    } catch (e) {
      setSubmitLoading(false);
      let message = "Error Accepting Operation";
      if (e.response?.data?.message) {
        message = e.response.data.message;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
    setSubmitLoading(false);
  };

  const handleRejectClick = async () => {
    setSubmitLoading(true);
    try {
      if (isTwoWayOperation) {
        twoWayOperationsUuid.forEach(async (singleUuid) => {
          const res = await api.deleteOperation(singleUuid);
          // success message and close dialog
          if (res?.status === 200) {
            dispatch(
              setSnackbarMessage({
                open: true,
                message: `Operation ${singleUuid} Deleted`,
                severity: "success",
              })
            );
            const username = userInfo["cognito:username"];
            try {
              await api.sendEmail(
                `${username}`,
                `Flight ${singleUuid} has been rejected and removed`,
                `Flight ${singleUuid} Rejected`
              );
            } catch (e) {
              setSubmitLoading(false);
              console.log("error : ", e);
            }
            setSubmitLoading(false);
            handleCloseDetailsDialog();
          } else {
            dispatch(
              setSnackbarMessage({
                open: true,
                message:
                  "Unable to delete operations, please note that Operations can only be deleted if the Ops State is Accepted",
                severity: "error",
              })
            );
            setSubmitLoading(false);
            handleCloseDetailsDialog();
          }
        });
        if (getOperations) getOperations();
      } else {
        const res = await api.deleteOperation(selectedFlights[0]);
        // success message and close dialog
        if (res?.status === 200) {
          const username = userInfo["cognito:username"];
          try {
            await api.sendEmail(
              `${username}`,
              `Flight ${selectedFlights[0]} has been rejected and removed`,
              `Flight ${selectedFlights[0]} Rejected`
            );
          } catch (e) {
            setSubmitLoading(false);
            console.log("error : ", e);
          }
          if (getOperations) getOperations();
          setSubmitLoading(false);
          handleCloseDetailsDialog();
        }
      }
    } catch (e) {
      setSubmitLoading(false);
      let message = "Error Accepting Operation";
      if (e.response?.data?.message) {
        message = e.response.data.message;
      }
      dispatch(
        setSnackbarMessage({
          open: true,
          message,
          severity: "error",
        })
      );
    }
    // setSubmitLoading(false);
  };
  const handleOpenProposeOperation = () => {
    // console.log("selectedFlights", selectedFlights);
    // Noted: need change not to use api call again just use table data
    mapboxController.removeDrawFeaturesAll();
    handleOperatorProposeOperation(selectedFlights[0]);
    // handleopenAuthProposeOperationDrawer(open);
    // handleOpenFlightAuthorizationAuthority();
    // close dialog
    handleCloseDetailsDialog();
  };
  return (
    <div className={classesFloating.buttonFloating}>
      <Grid
        container
        spacing={0}
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <Grid item xs={8} style={{ textAlign: "right" }}>
          <Button
            disabled={!selectedFlights.length}
            variant="contained"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={handleAcceptClick}
            color="primary"
          >
            Accept
          </Button>
          <Button
            variant="contained"
            disabled={!selectedFlights.length}
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            color="secondary"
            onClick={handleRejectClick}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            disabled={!selectedFlights.length}
            style={{ fontSize: "12px", fontWeight: "bold" }}
            color="secondary"
            onClick={() => handleOpenProposeOperation()}
          >
            Propose Changes
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
