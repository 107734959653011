import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingOverlay from "react-loading-overlay";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddPlatformTypeForm from "../AddPlatformTypeForm";
import { useApi } from "../../api/useApi";

function InformationBox({
  info,
  onClick,
  index,
  onDeletePlatformTypeClick,
  onEditPlatformTypeClick,
}) {
  const handleClick = () => {
    onClick(index);
  };

  return (
    <Accordion onClick={handleClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          display="inline"
          variant="body2"
          style={{ marginLeft: "3px" }}
        >
          {info.model}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0} style={{ marginBottom: "1px" }}>
          <Grid item xs={8} style={{ paddingLeft: "1px" }}>
            <DetailItem name="UAV Airframe Type" value={info.airframe} />
            <DetailItem name="Takeoff speed (m/s)" value={info.speed_takeoff} />
            <DetailItem name="RF Range (km)" value={info.radio_range} />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                style={{ width: "130px", marginTop: "0px" }}
                onClick={onEditPlatformTypeClick}
              >
                Edit Details
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ width: "130px", marginTop: "5px" }}
                onClick={onDeletePlatformTypeClick}
              >
                Delete Details
              </Button>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function PlatformTypeList(props) {
  const {
    platformTypeList,
    onClick,
    onEditPlatformTypeClick,
    onDeletePlatformTypeClick,
    selected,
  } = props;
  return (
    <List component="div">
      {platformTypeList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={onClick}
          onEditPlatformTypeClick={onEditPlatformTypeClick}
          onDeletePlatformTypeClick={onDeletePlatformTypeClick}
          selected={selected === index}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "1px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function PlatformTypesComponent({ history, setUserMessage }) {
  const [selected, setSelected] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [platformTypeList, setPlatformTypeList] = React.useState([]);
  const [loadingPlatformsTypes, setLoadingPlatformsTypes] =
    React.useState(true);
  const [updatingPlatformType, setUpdatingPlatformType] = React.useState(false);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();
  React.useEffect(() => {
    getPlatformsType();
  }, []);

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.model < b.model) {
      return -1;
    }
    if (a.model > b.model) {
      return 1;
    }
    return 0;
  }
  const getPlatformsType = async () => {
    try {
      const response = await api.getPlatformTypes();
      if (response.data) {
        response.data.sort(compare);
        setPlatformTypeList(response.data);
        setSelected(0);
        setLoadingPlatformsTypes(false);
      }
    } catch (err) {
      setLoadingPlatformsTypes(false);
      setUserMessage("Unable to load platform type data", "error");
    }
  };

  const handlePlatformTypeClick = (item) => {
    if (selected !== item) {
      setSelected(item);
    }
  };

  const handleEditPlatformTypeClick = () => {
    setOpenDialog(true);
    setUpdatingPlatformType(true);
  };

  const handleDeletePlatformTypeClick = () => {
    deletePlatformTypeInfo();
  };

  const handleAddPlatformTypeClick = () => {
    setOpenDialog(true);
    setUpdatingPlatformType(false);
  };

  const handleClose = (item) => {
    setOpenDialog(false);
    setUpdatingPlatformType(false);
  };

  const handlePlatformTypeUpdate = (newPlatformTypeData) => {
    const newPlatformTypeList = platformTypeList;
    const index = platformTypeList.findIndex(
      (x) => x.platform_type_uuid === newPlatformTypeData.platform_type_uuid
    );
    newPlatformTypeList[index] = newPlatformTypeData;
    setPlatformTypeList(newPlatformTypeList);
  };

  const deletePlatformTypeInfo = async () => {
    try {
      const response = await api.deletePlatformType(
        platformTypeList[selected].platform_type_uuid
      );
      if (response.data) {
        window.alert("Platform Type deleted!");
        getPlatformsType();
        handleClose();
      }
    } catch (err) {
      window.alert("Failed to delete platform type:");
    }
  };

  return (
    <LoadingOverlay active={loadingPlatformsTypes} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddPlatformTypeClick}
        >
          {" "}
          Add Platform Type{" "}
        </Button>
      </Grid>
      <PlatformTypeList
        onClick={handlePlatformTypeClick}
        selected={selected}
        platformTypeList={platformTypeList}
        onEditPlatformTypeClick={handleEditPlatformTypeClick}
        onDeletePlatformTypeClick={handleDeletePlatformTypeClick}
      />
      <AddPlatformTypeForm
        open={openDialog}
        handleClose={handleClose}
        platformTypeData={selected !== null ? platformTypeList[selected] : null}
        updating={updatingPlatformType}
        handlePlatformTypeUpdate={handlePlatformTypeUpdate}
      />
      {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
    </LoadingOverlay>
  );
}
