import { useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import TableCell from "@mui/material/TableCell";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { withStyles } from "@material-ui/core/styles";

import Circle from "./circle";

export default function FilterableTableHead(props) {
  const {
    column,
    options,
    onFilterChange,
    getSeverityBorderColor,
    getSeverityColor,
    filterValue,
    setFilterValue,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([
    "Emergency",
    "Warning",
    "Important",
  ]);

  const colorColumn = column[0];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (option) => {
    onFilterChange(option);
    handleMenuClose();
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (
      selectedFilter.includes("All") &&
      value.includes("All") &&
      value.length !== options.length
    ) {
      const uncheckAll =
        typeof value === "string"
          ? value.split(",").filter((item) => item.toLowerCase() !== "all")
          : value.filter((item) => item.toLowerCase() !== "all");
      setSelectedFilter(uncheckAll);
    } else if (value.includes("All")) {
      setSelectedFilter(options);
    } else if (selectedFilter.includes("All") && !value.includes("All")) {
      setSelectedFilter([]);
    } else {
      setSelectedFilter(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleSubmit = () => {
    onFilterChange(selectedFilter);
  };
  return (
    <TableCell align="center" key={colorColumn.id} width={colorColumn.minWidth}>
      {/* OLD SIMPLE FILTER. KEEP */}
      {/* <Button onClick={handleMenuOpen} endIcon={<MenuRoundedIcon />}>
        <Typography
          variant="Type"
          style={{ textTransform: "none", fontFamily: "Roboto" }}
        >
          {colorColumn.label}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleFilterChange(option)}
            sx={{ justifyContent: "space-between" }}
          >
            <div>
              <span
                style={{
                  fontSize: "14.5px",
                  display: "inline",
                  paddingRight: "5px",
                }}
              >
                {option}
              </span>
            </div>
            {option === "All" ? (
              <div style={{ marginLeft: "auto" }}>
                {" "}
                <HdrAutoIcon sx={{ fontSize: 12.5 }} />
              </div>
            ) : (
              <div style={{ marginLeft: "auto" }}>
                <Circle
                  color={getSeverityColor(option)}
                  borderColor={getSeverityBorderColor(option)}
                  size={10}
                />
              </div>
            )}
          </MenuItem>
        ))}
      </Menu> */}

      <FormControl>
        <InputLabel id="filterCheckboxLabel"> Filter</InputLabel>
        <Select
          labelId="filterCheckboxLabel"
          id="filterCheckbox"
          multiple
          value={selectedFilter}
          onChange={handleChange}
          onClose={handleSubmit}
          input={<OutlinedInput label="Filter" />}
          renderValue={(selected) => selected.join(", ")}
          sx={{ width: "150px", height: "42px" }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{ justifyContent: "space-between" }}
            >
              <Checkbox checked={selectedFilter.indexOf(option) > -1} />
              <ListItemText primary={option} />
              <Circle
                color={getSeverityColor(option)}
                borderColor={getSeverityBorderColor(option)}
                size={10}
              />
            </MenuItem>
          ))}
        </Select>
        {selectedFilter.length < 1 && (
          <FormHelperText>Please select at least 1</FormHelperText>
        )}
      </FormControl>
    </TableCell>
  );
}
