import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import CreatePortOperation from "../CreatePortOperation";
import CreateShipDeliveryOperation from "../CreateShipDeliveryOperation";
import { useApi } from "../../../api/useApi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div />
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function PortOperationsComponent(props) {
  const {
    setValue,
    onEmergencyLandingUpdateMove,
    emergencyLandingMove,
    selectedAreaWaypointsMove,
    onUpdateSelectedAreaWaypointsMove,
    value,
    openDronePortOperations,
    handleOpenDronePortOperations,
    emergencyLanding,
    setEmergencyLanding,
    selectedWaypoints,
    setSelectedWaypoints,
    selectedAreaWaypoints,
    setSelectedAreaWaypoints,
    selectedCircleWaypoints,
    setSelectedCircleWaypoints,
    selectedCircleProperties,
    setSelectedCircleProperties,
    openFlightAuthorization,
    handleOpenFlightAuthorization,
    selectedShip,
    setSelectedShip,
    selectedTakeOffLandingPoint,
    setSelectedTakeOffLandingPoint,
  } = props;
  const [availablePilots, setAvailablePilots] = useState([]);
  const [availableTracker, setAvailableTracker] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getPlatforms = async () => {
    try {
      const response = await api.getPlatforms();
      if (response.data) {
        setAvailablePlatforms(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      if (response.data) {
        setAvailablePilots(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const getTrackers = async () => {
    try {
      const response = await api.getTracker();
      if (response.data) {
        setAvailableTracker(response.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (openDronePortOperations) {
      getPlatforms();
      getPilots();
      getTrackers();
    }
  }, [openDronePortOperations]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // width: "100vw",
        overflow: "auto",
      }}
    >
      <div style={{ width: 750, overflow: "auto" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab
              label="Create Ship Delivery Operation"
              {...a11yProps(0)}
              style={{ minWidth: "300px" }}
            />
            <Tab label="Create Drone Port Operation" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {/* AIS Operation */}
          <CreateShipDeliveryOperation
            isAisOperation
            // mapViewController={mapViewController}
            /* Route props */
            selectedWaypoints={selectedWaypoints}
            setSelectedWaypoints={setSelectedWaypoints}
            /* polygon props */
            selectedAreaWaypoints={selectedAreaWaypoints}
            setSelectedAreaWaypoints={setSelectedAreaWaypoints}
            /* circle props */
            selectedCircleWaypoints={selectedCircleWaypoints}
            setSelectedCircleWaypoints={setSelectedCircleWaypoints}
            selectedCircleProperties={selectedCircleProperties}
            setSelectedCircleProperties={setSelectedCircleProperties}
            /* emergency point props */
            emergencyLanding={emergencyLanding}
            setEmergencyLanding={setEmergencyLanding}
            /* other props */
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            /* drawer toggler */
            handleOpenFlightAuthorization={handleOpenFlightAuthorization}
            openFlightAuthorization={openFlightAuthorization}
            selectedShip={selectedShip}
            setSelectedShip={setSelectedShip}
            selectedTakeOffLandingPoint={selectedTakeOffLandingPoint}
            setSelectedTakeOffLandingPoint={setSelectedTakeOffLandingPoint}
            openDronePortOperations={openDronePortOperations}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* Drone Port Operation */}
          <CreatePortOperation
            selectedAreaWaypoints={selectedAreaWaypointsMove}
            emergencyLanding={emergencyLandingMove}
            onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypointsMove}
            onEmergencyLandingUpdate={onEmergencyLandingUpdateMove}
            availablePilots={availablePilots}
            availableTracker={availableTracker}
            availablePlatforms={availablePlatforms}
            getPlatforms={getPlatforms}
            getPilots={getPilots}
            getTrackers={getTrackers}
            openDronePortOperations={openDronePortOperations}
            handleOpenDronePortOperations={handleOpenDronePortOperations}
          />
        </TabPanel>
      </div>
    </div>
  );
}

export default PortOperationsComponent;
