import React, { useState } from "react";
import { RoundedDatePicker } from "@/components/v1/Common/RoundedDatePicker";

import { MdMoreHoriz } from "react-icons/md";
import { getFutureDate } from "@/utils/date_utils";

interface WhiteListContraintFormProps {
  selectedUserCount: number;
  selectedGroupCount: number;
  title: string;
  onSelectUser: () => void;
  onSelectGroup: () => void;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
}

export default function WhiteListContraintForm({
  selectedUserCount,
  selectedGroupCount,
  title,
  onSelectUser,
  onSelectGroup,
  onStartDateChange,
  onEndDateChange,
}: WhiteListContraintFormProps) {
  const [startDate, setStartDate] = useState<Date | null>(getFutureDate(3));
  const [endDate, setEndDate] = useState<Date | null>(getFutureDate(30));

  const handleDateChange = (date: Date | null, type: string) => {
    if (type === "departure") {
      setStartDate(date);
      onStartDateChange(date?.toISOString() || "");
    } else {
      setEndDate(date);
      onEndDateChange(date?.toISOString() || "");
    }
  };

  return (
    <div className="flex-col mb-2 rounded-container-red">
      <h5 className="font-medium">{title} - Whitelisted Users and Groups</h5>
      <div className="flex mb-2">
        <div className="w-full mr-2">
          <label htmlFor="platform" className="label">
            Users
          </label>
          <div
            className="input-text flex cursor-pointer"
            onClick={onSelectUser}
          >
            <span className="grow">
              {selectedUserCount > 0
                ? `${selectedUserCount} Selected`
                : "Select"}
            </span>
            <MdMoreHoriz />
          </div>
        </div>

        <div className="w-full">
          <label htmlFor="tracker" className="label">
            Groups
          </label>
          <div
            className="input-text flex cursor-pointer"
            onClick={onSelectGroup}
          >
            <span className="grow">
              {selectedGroupCount > 0
                ? `${selectedGroupCount} Selected`
                : "Select"}
            </span>
            <MdMoreHoriz />
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="w-full mr-2">
          <RoundedDatePicker
            label="Start Date Time (Local Time)"
            value={startDate}
            className="mr-2"
            onChange={(date) => handleDateChange(date, "departure")}
          />
        </div>
        <div className="w-full">
          <RoundedDatePicker
            label="End Date Time (Local Time)"
            value={endDate}
            onChange={(date) => handleDateChange(date, "arrival")}
          />
        </div>
      </div>
    </div>
  );
}
