import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import UserAccess from "@/model/UserAccess";

import Button from "@/components/v1/Common/Button";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdHelp,
} from "react-icons/io";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { MdOutlineFileUpload } from "react-icons/md";
import { handleKmlImport, handleJsonImport } from "@/services/json";
import Loader from "../../Common/Loader";

interface ImportOperationSidebarProps {
  api: any;
  showMessage: (
    message: string,
    isSuccess?: boolean,
    isError?: boolean
  ) => void;
  onOpenFlightAuth: () => void;
}

interface WaylineListItem {
  ETag: string;
  Key: string;
  LastModified: string;
  Size: number;
  StorageClass: string;
}

interface WaylineListProps {
  waylineList: WaylineListItem[];
  selectedTab: number;
  onSelect?: (id: string, isSelected: boolean) => void;
  selectedIds: string[];
}

interface UserAccessState {
  userAccess: UserAccess;
}

function WaylineList({
  waylineList,
  selectedTab,
  onSelect,
  selectedIds,
}: WaylineListProps) {
  const [displayWayline, setDisplayWayline] = useState<WaylineListItem[]>([]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(e.target.value, e.target.checked);
  };

  useEffect(() => {
    if (!waylineList) return;
    if (!selectedTab) {
      const notDeconflictedWaylines = waylineList.filter((singleWayline) => {
        return !singleWayline.Key.split("/").includes("Deconflict");
      });
      setDisplayWayline(notDeconflictedWaylines);
    } else {
      const deconflictedWaylines = waylineList.filter((singleWayline) => {
        return singleWayline.Key.split("/").includes("Deconflict");
      });
      setDisplayWayline(deconflictedWaylines);
    }
  }, [selectedTab]);
  return (
    <div className="h-[75%] flex-col overflow-auto p-4">
      {!waylineList || !waylineList.length ? (
        <p className="p-8 text-center">No Data Available</p>
      ) : (
        displayWayline.map((singleWayline) => (
          <div
            className="relative p-2 rounded-container mb-2 cursor-pointer"
            key={singleWayline.Key}
          >
            <div
              // onClick={() => onClick && onClick(data)}
              className="flex-col"
            >
              <h5 className="font-medium">
                {
                  singleWayline.Key.split("/")[
                    singleWayline.Key.split("/").length - 1
                  ]
                }
              </h5>
            </div>
            <input
              type="checkbox"
              value={singleWayline.Key}
              checked={selectedIds.includes(singleWayline.Key)}
              className="input-checkbox absolute top-2 right-2"
              onChange={handleCheckboxChange}
            />
          </div>
        ))
      )}{" "}
    </div>
  );
}

export default function ImportOperationSidebar({
  api,
  showMessage,
  onOpenFlightAuth,
}: ImportOperationSidebarProps) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>();
  const [pilotList, setPilotList] = useState<string[]>([]);
  const [waylineList, setWaylineList] = useState<WaylineListItem[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [importedFile, setImportedFile] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const dispatch = useDispatch();

  const userAccess = useSelector((state: UserAccessState) => state.userAccess);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectedTab = (option: number) => {
    setSelectedTab(option);
    // setIsDropdownOpen(false);
  };

  const handleSelectedOption = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setSelectedIds([]);
  };

  const handleSelectedItem = (id: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    }
  };

  const handleCreateOperationClick = async () => {
    try {
      if (!selectedIds.length || selectedIds.length > 1) {
        showMessage && showMessage("Invalid selection", false, true);
        return;
      }
      const DownloadFile = await api.getWaylineDownloadURL(selectedIds[0]);
      const URLResponse = DownloadFile.data;
      const fileContentResponse = await fetch(URLResponse.uploadURL);
      const fileContent2 = await fileContentResponse.blob();
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event?.target?.result;
        try {
          onOpenFlightAuth();
          // handleCloseAllDrawers();
          // handleOpenFlightAuthorizationForced();
          try {
            if (!fileContent || typeof fileContent !== "string")
              throw new Error();
            const data = JSON.parse(fileContent);
            handleJsonImport(data, dispatch);
            setSelectedIds([]);
          } catch (error) {
            handleKmlImport(fileContent, dispatch);
            setSelectedIds([]);
          }
        } catch (error) {
          showMessage && showMessage("Unable to read file", false, true);
        }
      };

      reader.readAsText(fileContent2);
    } catch (err) {
      showMessage && showMessage("Error parsing file content", false, true);
    }
  };

  const handleImportFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      const file = e.target.files[0];
      try {
        setImportedFile(file);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const handleUpload = async () => {
    if (!importedFile || !selectedOption) {
      showMessage && showMessage("Invalid file/user", false, true);
      return;
    }
    const fileName = importedFile.name;
    const response = await api.getWaylineUploadURL({
      loginUser: selectedOption,
      fileName,
    });
    const URLResponse = response.data;
    try {
      const putResponse = await axios.put(URLResponse.uploadURL, importedFile, {
        headers: { "Content-Type": importedFile.type },
      });
      if (putResponse.statusText === "OK") {
        showMessage && showMessage("File Uploaded", true);
        setImportedFile(null);
      }
    } catch (error: any) {
      showMessage &&
        showMessage(`Error uploading file: ${error.message}`, true);
    }
    refreshWaylines();
  };

  const saveFile = (url: string, filename: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadOperation = async () => {
    try {
      if (!selectedIds.length) {
        alert("No file selected");
        return;
      }

      selectedIds.map(async (singleWayline) => {
        const filename = singleWayline.split("/").pop() || "Downloaded File";
        const DownloadFile = await api.getWaylineDownloadURL(singleWayline);
        const URLResponse = DownloadFile.data;
        const URL = URLResponse.uploadURL;
        saveFile(URL, filename);
      });
    } catch (error: any) {
      showMessage &&
        showMessage(`Error uploading file: ${error.message}`, true);
    }
  };

  // handleUpload triggers after user has selected file and
  // is set into usestate importedFile
  useEffect(() => {
    if (!importedFile) return;
    handleUpload();
    setShowMoreOptions(false);
  }, [importedFile]);

  const handleClickUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updatePilotListDropDown = async () => {
    setIsLoading(true);
    try {
      const pilotListResponse = await api.getPilots();
      const pilotListEdit = pilotListResponse.data.map((singlePilot: any) => {
        return singlePilot.pilot_username;
      });
      setPilotList(pilotListEdit);
      setSelectedOption(pilotListEdit[0]);
    } catch {
      showMessage && showMessage("Unable to obtain Pilot Info", false, true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    updatePilotListDropDown();
  }, []);

  const getPilotWaylineList = async () => {
    setIsLoading(true);
    try {
      const pilotWaylineListResponse = await api.getWayline(selectedOption);
      const pilotWaylines = pilotWaylineListResponse.data.filter(
        (singleResponse: any) =>
          singleResponse.downloadURL.Prefix === selectedOption
      );
      setWaylineList(pilotWaylines[0].downloadURL.Contents);
    } catch {
      showMessage &&
        showMessage("Unable to obtain Pilot Waylines", false, true);
    }
    setIsLoading(false);
  };

  const deleteMissionfromCloud = async () => {
    try {
      if (!selectedIds.length) {
        showMessage && showMessage("No file selected", false, true);
        return;
      }
      await selectedIds.map(async (singleWaypoint) => {
        const filename = singleWaypoint.split("/").slice(1).join("/");
        const fileOwner = singleWaypoint.split("/")[0];
        await api.deleteWayline(fileOwner, filename);
      });
      showMessage && showMessage("Operation Deleted", true);
      // refresh too soon does not reflect deleted wayline
      setTimeout(refreshWaylines, 2000);
    } catch (err) {
      showMessage && showMessage("Failed to delete Mission", false, true);
      refreshWaylines();
    }

    setShowMoreOptions(false);
    refreshWaylines();
  };

  const refreshWaylines = () => {
    getPilotWaylineList();
    setSelectedIds([]);
    setShowMoreOptions(false);
  };
  useEffect(() => {
    // selected option new pilot selected
    // selected tab imported or uploaded
    if (!selectedOption) return;
    refreshWaylines();
  }, [selectedOption, selectedTab]);

  return (
    <div className="flex-col w-full h-full">
      {/* Header */}
      <div className="flex p-4">
        <h4>Import Drone Operation</h4>
        <span className="grow" />
        <div className="w-6 h-6 bg-blue-200 rounded-lg flex">
          <IoMdHelp className="fill-blue-500 m-auto" size={16} />
        </div>
      </div>
      <hr />
      <div className="h-[70%] flex-col">
        {/* Dropdown Pilot Picker */}
        <div className="flex-col">
          <div className="flex px-4 py-2 mt-2">
            <h5 className="font-medium ml-2">Select Pilot</h5>
            <span className="grow" />
            <button onClick={() => showMessage && showMessage("Coming Soon")}>
              <span className="text-primary-600 text-sm font-medium">
                Sort by: Name
              </span>
            </button>
          </div>

          <div className="relative px-4">
            <button
              className="input-select"
              type="button"
              onClick={() => {
                if (!isDeleting) setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              <span className="grow">{selectedOption}</span>
              {isDropdownOpen ? (
                <IoIosArrowUp size={18} />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </button>

            {isDropdownOpen && (
              <div className="absolute top-14 w-[92%] z-[150]">
                <ul className="dropdown-ul">
                  {pilotList.map((option) => (
                    <li
                      key={option}
                      className="dropdown-li"
                      onClick={() => handleSelectedOption(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        {/* Import or Upload Tab */}
        <div className="tab-rounded mx-4 mt-5">
          <span
            className={`${selectedTab === 0 && "tab-active"} mr-1`}
            onClick={() => handleSelectedTab(0)}
          >
            Imported
          </span>
          <span
            className={`${selectedTab === 1 && "tab-active"} ml-1`}
            onClick={() => handleSelectedTab(1)}
          >
            Uploaded
          </span>
        </div>

        <div className="h-full">
          {isLoading ? (
            <Loader isLoading={isLoading} isError={false} />
          ) : (
            <WaylineList
              waylineList={waylineList}
              selectedTab={selectedTab}
              onSelect={handleSelectedItem}
              selectedIds={selectedIds}
            />
          )}
        </div>
      </div>

      <div className="h-[10%] px-4 py-2 items-center content-center mt-auto">
        <div className="flex">
          {!selectedTab ? (
            <Button
              type={!(selectedIds.length === 1) ? "light" : "primaryDark"}
              size="medium"
              text="Create Operation"
              className="mr-2"
              isLoading={isLoading}
              disabled={!(selectedIds.length === 1)}
              onClick={handleCreateOperationClick}
            />
          ) : (
            <Button
              type={selectedIds.length === 0 ? "light" : "primaryDark"}
              size="medium"
              text="Download To Device"
              className="mr-2"
              isLoading={isLoading}
              disabled={selectedIds.length === 0}
              onClick={handleDownloadOperation}
            />
          )}
          {userAccess.privileges.includes("operations.integrations.write") && (
            <Button
              type="light"
              size="medium"
              fixedWidth={true}
              className="mr-2 w-20"
              icon={<IoEllipsisHorizontal size={24} />}
              onClick={() => setShowMoreOptions(!showMoreOptions)}
              // isLoading={isDeleting}
              // disabled={isDeleting || isDisplaying}
              // onClick={handleDeleteOperation}
            />
          )}
        </div>
      </div>

      {/* Button Options */}
      {showMoreOptions && (
        <div
          className={`w-40 ${
            !selectedTab ? "h-20" : "h-10"
          } absolute bottom-40 right-6 popup-menu`}
        >
          <ul className="flex-col">
            {!selectedTab && (
              <li onClick={handleClickUpload}>
                Upload Operation
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImportFile}
                  hidden
                />
              </li>
            )}
            <li onClick={deleteMissionfromCloud}>Delete Operation</li>
          </ul>
        </div>
      )}
    </div>
  );
}
