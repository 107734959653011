import React from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface RoundedDatePickerProps {
  label: string;
  value: Date | null;
  className?: string;
  onChange: (date: Date | null) => void;
  format?: string;
}

export function RoundedDatePicker({
  label,
  value,
  className,
  onChange,
  format,
}: RoundedDatePickerProps) {
  const isDarkMode = localStorage.getItem("color-theme") === '"dark"';

  return (
    <div className={`w-full ${className}`}>
      <label htmlFor="maxaltitude" className="label">
        {label}
      </label>
      <div className="dark:bg-darkTertiary dark:text-white rounded-md">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={value}
            onChange={onChange}
            views={["day", "month", "year", "hours", "minutes"]}
            format={format || "dd-MM-yyyy hh:mm aa"}
            timeSteps={{ minutes: 1 }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.5rem",
                border: isDarkMode ? "1px solid #4B5563" : "1px solid #D1D5DB",
                color: isDarkMode ? "#D1D5DB" : "#111827",
              },
              "& .MuiOutlinedInput-input": {
                padding: "11.5px 14px",
                color: isDarkMode ? "#D1D5DB" : "#111827",
              },
              "& .MuiIconButton-root": {
                color: isDarkMode ? "#D1D5DB" : "#111827",
              },
            }}
            slotProps={{
              popper: {
                sx: {
                  zIndex: 9999,
                },
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
