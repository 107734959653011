import { recommender } from "./iconRecommender";

const allWeather = [
  "sunny",
  "fair & warm",
  "fair",
  "partly cloudy",
  "cloudy",
  "slightly hazy",
  "hazy",
  "mist",
  "fog",
  "strong winds, showers",
  "strong winds, rain",
  "windy, cloudy",
  "windy, showers",
  "windy, rain",
  "windy",
  "drizzle",
  "light rain",
  "light showers",
  "moderate rain",
  "heavy rain",
  "heavy showers",
  "passing showers",
  "heavy thundery showers with gusty winds",
  "heavy thundery showers",
  "thundery showers",
  "overcast",
  "showers",
];

export function guesser(forecastDescription) {
  if (forecastDescription) {
    const lowercased = forecastDescription.toLowerCase();
    const weatherKey = allWeather.find((key) => lowercased.includes(key));

    const resultKey = weatherKey == null ? "unknown" : weatherKey;
    const cssClass = recommender(resultKey);
    return cssClass;
  }
  return recommender("unknown");
}
