import * as React from "react";
import { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import {
  Grid,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  makeStyles,
  useTheme,
  IconButton,
  Checkbox,
  Typography,
  Link,
} from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useApi } from "../../../api/useApi";
import PuckServer from "../../../services/puckserver";
import DetailsOperationsDialog from "../../../components/UpcomingOperationsDetailsDialog";
import DetailsTrackers from "../../../components/UpcomingOperationsDetailsDialog/DetailsTrackers";
import DetailsPaltforms from "../../../components/UpcomingOperationsDetailsDialog/DetailsPaltforms";
import DetailsPilots from "../../../components/UpcomingOperationsDetailsDialog/DetailsPilots";

const puckServer = PuckServer;

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPage className={classes.icon} />
        ) : (
          <FirstPage className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPage className={classes.icon} />
        ) : (
          <LastPage className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    minWidth: 1000,
    minHeight: 53,
    position: "sticky",
    top: 0,
    backgroundColor: "#fafafa",
  },
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
    "& .MuiTablePagination-spacer": {
      display: "none",
    },
  },
  tabelFooter: {
    position: "sticky",
    bottom: 0,
  },
}));
const useFloatingButton = makeStyles((theme) => ({
  buttonFloating: {
    position: "sticky",
    bottom: 0,
    width: "100%",
  },
}));
function OperationsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  const [selectedDialogData, setSelectedDialogData] = React.useState([]);
  const rowsPerPage = 5;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.operations.length - page * rowsPerPage);

  const { operations } = props;
  // Sort operations by time created
  function compare(a, b) {
    if (a.reference.time_created.value < b.reference.time_created.value) {
      return -1;
    }
    if (a.reference.time_created.value > b.reference.time_created.value) {
      return 1;
    }
    return 0;
  }
  operations.sort(compare);
  operations.reverse();
  const mapboxController = useSelector((state) => state.maps.mapboxController);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = operations.map((n) => n.reference.id);
      setSelected(newSelecteds);
      if (props.setSelectedFlights) props.setSelectedFlights(newSelecteds);
      return;
    }
    setSelected([]);
    if (props.setSelectedFlights) props.setSelectedFlights([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    if (props.setSelectedFlights) props.setSelectedFlights(newSelected);
  };

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");
  const handleDetailsClick = (event, rowData) => {
    setSelectedDialogData(rowData);
    mapboxController.setFocussedOperation(rowData);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
  };
  return (
    <div>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableHeaderRow}>
            {/* <TableCell padding="checkbox">
              <Checkbox
                checked={
                  operations.length > 0 && selected.length === operations.length
                }
                onChange={handleSelectAllClick}
              />
            </TableCell> */}
            <TableCell className={classes.tableCell}>Operation</TableCell>
            <TableCell className={classes.tableCell} align="center">
              Departure
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Arrival
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Pilot
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Platform
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Tracker
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Status
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Operation ID
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Created On
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.reference.id);
              return (
                <Tooltip title="Click for details">
                  <TableRow
                    className={classes.tableRow}
                    key={row.reference.id.toString()}
                    onClick={(event) => handleDetailsClick(event, row)}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) =>
                          handleClick(event, row.reference.id)
                        }
                      />
                    </TableCell> */}
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {`${row.reference.description}`}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {formatDate(
                        row.interuss.operational_intent_reference.time_start
                          .value
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {formatDate(
                        row.interuss.operational_intent_reference.time_end.value
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsPilots pilot_uuid={row.request.pilot_uuid} />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsPaltforms
                        platform_uuid={row.request.platform_uuid}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      <DetailsTrackers
                        tracker_uuid={row.request.tracker_uuid}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {row.details.state}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {row.reference.id}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {formatDate(row.reference.time_created.value)}
                    </TableCell>
                  </TableRow>
                </Tooltip>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter className={classes.tabelFooter}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              count={operations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <DetailsOperationsDialog
        handleCloseDetailsDialog={handleCloseDetailsDialog}
        openDetailsDialog={openDetailsDialog}
        selectedDialogData={selectedDialogData}
      />
    </div>
  );
}

// function UpcomingOperationsHeader({ selectedFlights, history }) {
//   const auth = useSelector((state) => state.auth);
//   const api = useApi();
//   const classesFloating = useFloatingButton();
//   const handleCancelOpsClick = async () => {
//     await selectedFlights.map((selectedFlight) => {
//       api.deleteOperation(selectedFlight);
//     });

//     window.location.reload();
//   };

//   const handleSelectedClick = async () => {
//     await selectedFlights.forEach(api.addOperationSelection);

//     const flightData = await Promise.all(
//       selectedFlights.map((operationID) =>
//         api.getOperationData({ operationID })
//       )
//     );

//     flightData.map((operation) => {
//       puckServer.postMission(
//         operation.data.operation_json,
//         operation.data.operation_json.details.puck_uuid
//       );
//     });

//     window.location.reload();
//   };
//   return (
//     <div className={classesFloating.buttonFloating}>
//       <Grid
//         container
//         spacing={0}
//         style={{ paddingTop: "20px", paddingBottom: "20px" }}
//       >
//         {/* <Grid item xs={6} style={{ textAlign: "left" }}>
//           <Button
//             variant="contained"
//             disabled={!!selectedFlights.length}
//             onClick={handleSelectedClick}
//             style={{ fontSize: "12px", fontWeight: "bold" }}
//             color="primary"
//           >
//             Accept
//           </Button>
//         </Grid>
//         <Grid item xs={6}>
//           <Button
//             variant="contained"
//             disabled={!!selectedFlights.length}
//             style={{ fontSize: "12px", fontWeight: "bold" }}
//             color="secondary"
//             onClick={handleCancelOpsClick}
//           >
//             Propose Changes
//           </Button>
//         </Grid> */}
//         <Grid item xs={6} style={{ textAlign: "right" }}>
//           <Button
//             disabled={!selectedFlights.length}
//             variant="contained"
//             style={{
//               fontSize: "12px",
//               fontWeight: "bold",
//               marginRight: "10px",
//             }}
//             color="primary"
//           >
//             Accept
//           </Button>
//           <Button
//             variant="contained"
//             disabled={!selectedFlights.length}
//             style={{ fontSize: "12px", fontWeight: "bold" }}
//             color="secondary"
//           >
//             Propose Changes
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

function ApprovHistoryOperationsComponent({ history }) {
  const [loading, setLoading] = React.useState(true);
  const [upcomingOperations, setUpcomingOperations] = React.useState([]);
  // const [platformData, setPlatformData] = React.useState([]);

  const [selectedFlights, setSelectedFlights] = React.useState([]);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();
  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const promises = [];
    promises.push(getOperations());
    // promises.push(getPlatformData())

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getOperations = async () => {
    const response = await api.getOperations();
    if (!response.data) return;

    const currentTime = new Date();

    response.data.forEach((flightData) => {
      if (
        new Date(flightData.operation_json.reference.time_end.value) >=
          currentTime &&
        flightData.operation_state !== "Proposed" &&
        flightData.operation_state !== "Rejected"
      ) {
        upcomingOperations.push(flightData.operation_json);
      }
    });

    setUpcomingOperations(upcomingOperations);
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      <OperationsTable
        operations={upcomingOperations}
        // platformData = {platformData}
        setSelectedFlights={setSelectedFlights}
      />
      {/* <UpcomingOperationsHeader
        selectedFlights={selectedFlights}
        history={history}
      /> */}
      {/* <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      /> */}
    </LoadingOverlay>
  );
}

export default ApprovHistoryOperationsComponent;
