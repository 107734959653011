import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterableTableHead from "./filterableTableHead";

// Custom table head to implement sorting
export default function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    onRequestFilter,
    getSeverityBorderColor,
    getSeverityColor,
    filterValue,
    setFilterValue,
  } = props;
  const severityColorColumn = columns.filter(
    (column) => column.id === "severityColor"
  );

  const filterOptions = [
    "All",
    "Emergency",
    "Warning",
    "Important",
    "Error",
    "Information",
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const createFilterHandler = (property) => {
    onRequestFilter(property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => {
          if (headCell.id === "datetime") {
            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                align="center"
                width={headCell.minWidth}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          }
          if (headCell.id === "severityColor") {
            return (
              // <TableCell
              //   key={headCell.id}
              //   align="center"
              //   width={headCell.minWidth}
              // >
              <FilterableTableHead
                key={headCell.id}
                column={severityColorColumn}
                options={filterOptions}
                onFilterChange={onRequestFilter}
                getSeverityBorderColor={getSeverityBorderColor}
                getSeverityColor={getSeverityColor}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
              />
              // </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.id}
              align="center"
              width={headCell.minWidth}
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
