import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import SelectOperationForm from "./selectOperationForm";
import { downloadCSV, downloadTelemetryCSV } from "../../services/json";
import PuckServer from "../../services/puckserver";

function CustomTextField({
  label,
  value,
  setFuncton,
  fullWidth,
  errormessage,
  helperText,
  setError,
  type,
}) {
  return (
    <TextField
      id={label}
      label={label}
      value={value || ""}
      type={type}
      onChange={(event) => {
        if (errormessage) setError("");
        setFuncton(event.target.value);
      }}
      margin="normal"
      fullWidth={fullWidth}
      autoComplete="off"
      required
      error={!!errormessage}
      helperText={errormessage || helperText}
    />
  );
}

// Obtain tracker data
const pollPuckForTelemetry = async (trackerId, operationId) => {
  const [puckErr, puckResponse] = await PuckServer.getTrackerData(
    trackerId,
    operationId
  );
  // if (puckErr) {
  //   dispatch(
  //     setSnackbarMessage({
  //       open: true,
  //       message: puckErr.message,
  //       severity: "error",
  //     })
  //   );
  // }
  // console.log(puckResponse)
  const resp = {
    puckResponse,
  };
  // console.log(trackerId);
  // console.log(operationId);
  // console.log(puckResponse);
  // console.log(resp);
  return resp;
};

export default function IncidentReportForm() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedOperations, setSelectedOperations] = React.useState({
    reference: { description: "none selected" },
  });
  const [operationErrorColor, setOperationErrorColor] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [aocRef, setAocRef] = React.useState("");
  const [aocRefError, setAocRefError] = React.useState("");
  const [pilotName, setPilotName] = React.useState("");
  const [pilotNameError, setPilotNameError] = React.useState("");
  const [operationId, setOperationId] = React.useState("");
  const [operationIdError, setOperationIdError] = React.useState("");
  const [countryDepature, setCountryDepature] = React.useState("");
  const [countryDepatureError, setCountryDepatureError] = React.useState("");
  const [countryDestination, setCountryDestination] = React.useState("");
  const [countryDestinationError, setCountryDestinationError] =
    React.useState("");
  const [reportTitle, setReportTitle] = React.useState("");
  const [reportTitleError, setReportTitleError] = React.useState("");
  const [flightPhase, setFlightPhase] = React.useState("");
  const [flightPhaseError, setFlightPhaseError] = React.useState("");
  const [aircraftRegistration, setAircraftRegistration] = React.useState("");
  const [aircraftRegistrationError, setAircraftRegistrationError] =
    React.useState("");
  const [aircraftManufacturer, setAircraftManufacturer] = React.useState("");
  const [aircraftManufacturerError, setAircraftManufacturerError] =
    React.useState("");
  const [aircraftModel, setAircraftModel] = React.useState("");
  const [aircraftModelError, setAircraftModelError] = React.useState("");
  const [reportState, setReportState] = React.useState("");
  const [reportStateError, setReportStateError] = React.useState("");
  const [reportOccurenanceState, setReportOccurenanceState] =
    React.useState("");
  const [reportOccurenanceStateError, setReportOccurenanceStateError] =
    React.useState("");
  const [reportDescription, setReportDescription] = React.useState("");
  const [reportDescriptionError, setReportDescriptionError] =
    React.useState("");
  const [reportPotentialHarzard, setReportPotentialHarzard] =
    React.useState("");
  const [reportPotentialHarzardError, setReportPotentialHarzardError] =
    React.useState("");
  const [date, setDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [incidentDate, setIncidentDate] = React.useState(
    format(new Date(), "yyyy-MM-dd HH:mm:ss")
  );

  const selectOperationClick = () => {
    setOpenDialog(true);
    setOperationErrorColor("");
  };

  useEffect(() => {
    try {
      setPilotName(selectedOperations?.details?.pilot_name);
      setOperationId(
        selectedOperations?.interuss?.operational_intent_reference?.id
      );
      setAircraftModel(selectedOperations?.details?.platform_type);
      setAircraftRegistration(
        selectedOperations?.details?.platform_registration
      );
    } catch (err) {
      console.log(err);
    }
  }, [selectedOperations]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validateForm = () => {
    let error = false;
    if (!name) {
      setNameError("Reporter name is required");
      error = true;
    }
    if (!email) {
      setEmailError("Reporter email is required");
      error = true;
    }
    if (!aocRef) {
      setAocRefError("AOC reference number is required");
      error = true;
    }
    if (!pilotName) {
      setPilotNameError("Pilot Name is required");
      error = true;
    }
    if (!operationId) {
      setOperationIdError("Flight Number is required");
      error = true;
    }
    if (!countryDepature) {
      setCountryDepatureError("Depature Country is required");
      error = true;
    }
    if (!countryDestination) {
      setCountryDestinationError("Destination Country is required");
      error = true;
    }
    if (!reportTitle) {
      setReportTitleError("Title is required");
      error = true;
    }
    if (!flightPhase) {
      setFlightPhaseError("Flight Phase is required");
      error = true;
    }
    if (!aircraftRegistration) {
      setAircraftRegistrationError("Aircraft Registration is required");
      error = true;
    }
    if (!aircraftManufacturer) {
      setAircraftManufacturerError("Aircraft Manufacturer is required");
      error = true;
    }
    if (!aircraftModel) {
      setAircraftModelError("Aircraft Model is required");
      error = true;
    }
    if (!reportState) {
      setReportStateError("Reporting State is required");
      error = true;
    }
    if (!reportOccurenanceState) {
      setReportOccurenanceStateError("Occurenance State is required");
      error = true;
    }
    if (!reportDescription) {
      setReportDescriptionError("Description is required");
      error = true;
    }
    if (selectedOperations.reference.description === "none selected") {
      setOperationErrorColor("red");
      error = true;
    }
    return error;
  };

  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (formErrors) return;
    const incidentReport = {
      incidentReport: {
        name,
        email,
        reportDate: date,
        incidentDate,
        aocRef,
        pilotName,
        flightNumber: operationId,
        countryDepature,
        countryDestination,
        reportTitle,
        flightPhase,
        aircraftRegistration,
        aircraftManufacturer,
        aircraftModel,
        reportState,
        reportOccurenanceState,
        reportDescription,
        reportPotentialHarzard,
      },
      ...selectedOperations,
    };
    // Using Sample telemetry data first, once all ok, uncomment
    const puckTelemetryResArr = incidentReport.details.puck_uuid.map(
      async (singleUuid) => {
        return pollPuckForTelemetry(
          singleUuid,
          incidentReport.interuss.operational_intent_reference.id
        );
      }
    );
    const puckTelemetryData = await Promise.all(puckTelemetryResArr);
    const puckTelemetryDataCombined = {};
    puckTelemetryDataCombined.puckResponse = [];
    puckTelemetryData.forEach((singleUuid) => {
      puckTelemetryDataCombined.puckResponse.push(...singleUuid.puckResponse);
    });
    // // Sample telemetry data if wish to simulate
    // const puckTelemetryData = await pollPuckForTelemetry(
    //   "1fcbdbe0-2b10-44d2-b9e0-40d1612ab068",
    //   "c4da73de-0595-44fa-9d69-84799b4469c9"
    // );
    downloadCSV(
      [incidentReport],
      `incident-report-${incidentReport.interuss.operational_intent_reference.id}`
    );
    downloadTelemetryCSV(
      [puckTelemetryDataCombined],
      `tracker-data-${incidentReport.interuss.operational_intent_reference.id}`
    );
    // To Clear Form, uncomment once export done
    setName("");
    setEmail("");
    setSelectedOperations({
      reference: { description: "none selected" },
    });
    setAocRef("");
    setPilotName("");
    setOperationId("");
    setCountryDepature("");
    setCountryDestination("");
    setReportTitle("");
    setFlightPhase("");
    setAircraftRegistration("");
    setAircraftManufacturer("");
    setAircraftModel("");
    setReportState("");
    setReportOccurenanceState("");
    setReportDescription("");
    setReportPotentialHarzard("");
    setIncidentDate(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  };

  return (
    <div
      style={{
        // display: "list-item",
        // flexDirection: "row",
        position: "relative",
        width: "500px",
        height: "500px",
        // overflow: "scroll",
      }}
    >
      <Grid
        style={{
          // display: "list-item",
          // flexDirection: "row",
          position: "absolute",
          width: "500px",
          // overflow: "scroll",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "30%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={selectOperationClick}
        >
          {" "}
          Select Operation{" "}
        </Button>
        <Typography
          style={{
            marginTop: "5px",
            marginLeft: "10px",
            color: operationErrorColor,
          }}
          variant="caption"
        >
          {`Operation Selected : ${selectedOperations.reference.description}`}
        </Typography>
        <br />
        <CustomTextField
          id="reportTitle"
          label="Title"
          fullWidth
          value={reportTitle}
          setFuncton={setReportTitle}
          errormessage={reportTitleError}
          helperText="Title of your report"
          setError={setReportTitleError}
        />
        <CustomTextField
          id="reporterName"
          label="Reported By"
          fullWidth
          value={name}
          setFuncton={setName}
          errormessage={nameError}
          helperText="Reporter's Name"
          setError={setNameError}
        />
        <CustomTextField
          id="reporterEmail"
          label="Email"
          fullWidth
          value={email}
          setFuncton={setEmail}
          errormessage={emailError}
          helperText="Reporter's Email"
          setError={setEmailError}
        />
        <CustomTextField
          id="reportedDate"
          label="Report Date"
          type="date"
          fullWidth
          value={date}
          helperText="Reported Date"
          setFuncton={setDate}
        />
        <CustomTextField
          id="incidentDateTime"
          label="Incident Date Time"
          type="datetime-local"
          fullWidth
          value={incidentDate}
          helperText="Incident Date"
          setFuncton={setIncidentDate}
        />
        <CustomTextField
          id="aocReference"
          label="AOC Reference"
          fullWidth
          value={aocRef}
          setFuncton={setAocRef}
          errormessage={aocRefError}
          helperText="Attestation of Compliance (AOC) certifying your company’s PCI DSS compliance."
          setError={setAocRefError}
        />
        <CustomTextField
          id="pilotName"
          label="Pilot Name"
          fullWidth
          value={pilotName}
          setFuncton={setPilotName}
          errormessage={pilotNameError}
          helperText="Name of Drone Pilot"
          setError={setPilotNameError}
        />
        <CustomTextField
          id="operationId"
          label="Flight Number"
          fullWidth
          value={operationId}
          setFuncton={setOperationId}
          errormessage={operationIdError}
          helperText="Flight Number or Operation ID"
          setError={setOperationIdError}
        />
        <CustomTextField
          id="aircraftRegistration"
          label="Aircraft Registration"
          fullWidth
          value={aircraftRegistration}
          setFuncton={setAircraftRegistration}
          errormessage={aircraftRegistrationError}
          helperText="Drone Registration Number"
          setError={setAircraftRegistrationError}
        />
        <CustomTextField
          id="aircraftManufacturer"
          label="Aircraft Manufacturer"
          fullWidth
          value={aircraftManufacturer}
          setFuncton={setAircraftManufacturer}
          errormessage={aircraftManufacturerError}
          helperText="Drone Manufacturer (DJI, Intel, Yunneec)"
          setError={setAircraftManufacturerError}
        />
        <CustomTextField
          id="aircraftModel"
          label="Aircraft Model"
          fullWidth
          value={aircraftModel}
          setFuncton={setAircraftModel}
          errormessage={aircraftModelError}
          helperText="Drone Model Number (DJI M300, DJI Mini, Swoop)"
          setError={setAircraftModelError}
        />
        <CustomTextField
          id="countryDepature"
          label="Last Depature Point (Country)"
          fullWidth
          value={countryDepature}
          setFuncton={setCountryDepature}
          errormessage={countryDepatureError}
          helperText="Country of Depature Point"
          setError={setCountryDepatureError}
        />
        <CustomTextField
          id="countryDestination"
          label="Planned Destination Point (Country)"
          fullWidth
          value={countryDestination}
          setFuncton={setCountryDestination}
          errormessage={countryDestinationError}
          helperText="Country of Destination Point"
          setError={setCountryDestinationError}
        />
        <CustomTextField
          id="flightPhase"
          label="Flight Phase"
          fullWidth
          value={flightPhase}
          setFuncton={setFlightPhase}
          errormessage={flightPhaseError}
          helperText="Indicate the phase of flight (During Flight, Landing, Takeoff)"
          setError={setFlightPhaseError}
        />
        <CustomTextField
          id="reportState"
          label="State Reporting"
          fullWidth
          value={reportState}
          setFuncton={setReportState}
          errormessage={reportStateError}
          helperText="State you are reporting from"
          setError={setReportStateError}
        />
        <CustomTextField
          id="reportOccurenanceState"
          label="State Occurence"
          fullWidth
          value={reportOccurenanceState}
          setFuncton={setReportOccurenanceState}
          errormessage={reportOccurenanceStateError}
          helperText="State where incident occured"
          setError={setReportOccurenanceStateError}
        />
        <CustomTextField
          id="reportDescription"
          label="Narrative (Description of Accident/Incident)"
          fullWidth
          value={reportDescription}
          setFuncton={setReportDescription}
          errormessage={reportDescriptionError}
          helperText="Description of Accident/Incident"
          setError={setReportDescriptionError}
        />
        <CustomTextField
          id="reportPotentialHarzard"
          label="Potential Hazard(s) Identified (if any)"
          fullWidth
          value={reportPotentialHarzard}
          setFuncton={setReportPotentialHarzard}
          errormessage={reportPotentialHarzardError}
          helperText="Potential Hazard(s) Identified (if any)"
          setError={setReportPotentialHarzardError}
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "50px",
            marginBottom: "25px",
          }}
          onClick={handleSubmit}
        >
          {" "}
          Create Report{" "}
        </Button>
        <SelectOperationForm
          open={openDialog}
          handleClose={handleClose}
          setSelectedOperations={setSelectedOperations}
        />
      </Grid>
    </div>
  );
}
