import { SET_USER_ACCESS } from "../actions/userAccess";

const initState = {
  role: "",
  subscriptionStatus: "",
  privileges: [],
  // operations: {
  //   write: false,
  //   read: false,
  //   integrations: {
  //     write: false,
  //     read: false,
  //   },
  //   incident: {
  //     write: false,
  //     read: false,
  //   },
  // },
  // telemetry: {
  //   read: false,
  // },
  // general: {
  //   logs: {
  //     read: false,
  //   },
  //   organisation: {
  //     write: false,
  //     read: false,
  //   },
  //   user: {
  //     write: false,
  //     read: false,
  //   },
  //   privilege: {
  //     read: false,
  //     write: false,
  //   },
  //   subscription: {
  //     read: false,
  //     write: false,
  //   },
  // },
  // assets: {
  //   pilot: {
  //     write: false,
  //     read: false,
  //   },
  //   platformtype: {
  //     write: false,
  //     read: false,
  //   },
  //   platform: {
  //     write: false,
  //     read: false,
  //   },
  //   maintenance: {
  //     write: false,
  //     read: false,
  //   },
  //   tracker: {
  //     write: false,
  //     read: false,
  //   },
  //   permit: {
  //     read: false,
  //     write: false,
  //   },
  // },
  // constraints: {
  //   write: false,
  //   read: false,
  // },
  // sdsp: {
  //   read: false,
  // },
  // airspace: {
  //   operations: {
  //     write: false,
  //     read: false,
  //   },
  //   broadcast: {
  //     write: false,
  //   },
  //   organisation: {
  //     write: false,
  //     read: false,
  //   },
  //   assets: {
  //     read: false,
  //   },
  // },
};

const userAccessReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER_ACCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default userAccessReducer;
