/**
 * Computes polygon center in O(n) time
 * @param {*} wps 2D array of lat-lon coordinates
 * @returns 1D array representing lat-lon of polygon center
 */
export function computePolygonCenter(wps) {
  let A = 0;
  let xSum = 0;
  let ySum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < wps.length - 1; i++) {
    A += (wps[i][0] * wps[i + 1][1] - wps[i + 1][0] * wps[i][1]) / 2;
    xSum +=
      (wps[i][0] * wps[i + 1][1] - wps[i + 1][0] * wps[i][1]) *
      (wps[i][0] + wps[i + 1][0]);
    ySum +=
      (wps[i][0] * wps[i + 1][1] - wps[i + 1][0] * wps[i][1]) *
      (wps[i][1] + wps[i + 1][1]);
  }

  return [xSum / (6 * A), ySum / (6 * A)];
}
