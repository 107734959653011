import {
  createAuthorityRequestInstance,
  createOperatorRequestInstance,
} from ".";
import useCognitoAuth from "../hooks/useCognitoAuth";

let instance = null;
export const useApi = () => {
  const {
    refreshToken,
    accessToken,
    isAuthority,
    isOperator,
    isPilot,
    isRemoteId,
  } = useCognitoAuth();
  if (instance) return instance;
  if (isAuthority)
    instance = createAuthorityRequestInstance(accessToken, refreshToken);
  if (isOperator || isPilot || isRemoteId) {
    instance = createOperatorRequestInstance(
      accessToken,
      refreshToken,
      isOperator,
      isRemoteId
    );
    instance.aas = createAuthorityRequestInstance(accessToken, refreshToken);
  }
  return instance;
};
