/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import Loader from "@/components/v1/Common/Loader";
import Pilot from "@/model/api/Pilot";
import Platform from "@/model/api/Platform";
import { PieChart, BarChart } from "../../../../StatisticsDashboard/addChart";

interface ChartData {
  totalFlightDuration: number;
  totalOperationCount: number;
  countOfNonConformance: number;
  opsByPilot: any;
  opsByOpsType: any;
  opsByMonth: any;
  totalFlightDurationByPilot: any;
  totalFlightDurationByOpsType: any;
  totalFlightDurationByMonth: any;
}

interface PlatformStatisticProps {
  api: any;
  platformData: Platform;
}

export default function Platformstatistic({
  api,
  platformData,
}: PlatformStatisticProps) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const platformOperationsRef = useRef<any>([]);

  /* Assets */
  const [pilotList, setPilotList] = useState<any>({});
  const [platformTypeList, setPlatformTypeList] = useState<any>({});

  const cardDetails = [
    {
      cardHeader: "Operations By Pilot",
      chartData: "opsByPilot",
      type: "pieChart",
    },
    {
      cardHeader: "Operations By Operation Type",
      chartData: "opsByOpsType",
      type: "pieChart",
    },
    {
      cardHeader: "Operations by Month",
      chartData: "opsByMonth",
      type: "pieChart",
    },
    {
      cardHeader: "Total Flight Duration by Pilot",
      chartData: "totalFlightDurationByPilot",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Operation Type",
      chartData: "totalFlightDurationByOpsType",
      type: "barChart",
    },
    {
      cardHeader: "Total Flight Duration by Month",
      chartData: "totalFlightDurationByMonth",
      type: "barChart",
    },
  ];

  const getAssets = async () => {
    const [pilots, platformTypes] = await Promise.all([
      api.getPilots(),
      api.getPlatformTypes(),
    ]);

    const pilotList = pilots.data.reduce((result: any, platform: any) => {
      return {
        ...result,
        [platform.platform_uuid]: {
          callsign: platform.platform_callsign,
          typeUuid: platform.platform_type_uuid,
        },
      };
    }, {});

    const platformTypeList = platformTypes.data.reduce(
      (result: any, platform: any) => {
        return {
          ...result,
          [platform.platform_type_uuid]: platform.model,
        };
      },
      {}
    );

    setPilotList(pilotList);
    setPlatformTypeList(platformTypeList);
  };

  const getData = async (maxLoops = 2) => {
    try {
      const [platformOperationsResponse] = await Promise.all([
        api.getPlatformOperations(
          platformData.platform_uuid,
          platformOperationsRef.current.length
        ),
      ]);

      platformOperationsRef.current = [
        ...platformOperationsRef.current,
        ...platformOperationsResponse.data,
      ];

      if (platformOperationsResponse.data.length > 99 && maxLoops > 1) {
        getData(maxLoops - 1);
      } else {
        organizeData();
      }
    } catch (err) {
      /* dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to Obtain Data ${err}`,
          severity: "error",
        })
      );
      handleClosePilotStatistics(); */
    }
  };

  const organizeData = async () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const checkTimeDifference = (timestamp1: string, timestamp2: string) => {
      const date1 = new Date(timestamp1);
      const date2 = new Date(timestamp2);

      const epochTime1 = date1.getTime(); // Convert to epoch time (milliseconds since January 1, 1970)
      const epochTime2 = date2.getTime();

      const timeDifference = Math.abs(epochTime1 - epochTime2); // Get the absolute difference in milliseconds

      // You can convert the time difference to a different unit if needed (e.g., seconds, minutes, hours)
      // For example, to get the difference in seconds:
      // const timeDifferenceInSeconds = timeDifference / 1000;
      const timeDifferenceInHours = parseFloat(
        (timeDifference / (1000 * 60 * 60)).toFixed(2)
      );

      return timeDifferenceInHours;
    };

    const organizeDataComplete: ChartData = {
      totalFlightDuration: 0,
      totalOperationCount: platformOperationsRef.current.length,
      countOfNonConformance: 0,
      opsByPilot: {},
      opsByOpsType: {},
      opsByMonth: {},
      totalFlightDurationByPilot: {},
      totalFlightDurationByOpsType: {},
      totalFlightDurationByMonth: {},
    };

    monthNames.forEach((month: any) => {
      organizeDataComplete.opsByMonth[month] = 0;
      organizeDataComplete.totalFlightDurationByMonth[month] = 0;
    });

    const logState = await api.getLogState();

    const nonConformingRemoveDuplicates = logState.data.reduce(
      (result: any, operation: any) => {
        if (
          operation.operation_state === "Nonconforming" ||
          operation.operation_state === "contingent"
        )
          return {
            ...result,
            [operation.operation_uuid]: operation.operation_state,
          };
        return result;
      },
      {}
    );

    platformOperationsRef.current.forEach((singleOperation: any) => {
      if (
        nonConformingRemoveDuplicates[
          singleOperation.operation_json.reference.id
        ]
      )
        organizeDataComplete.countOfNonConformance += 1;
      const month = new Date(
        singleOperation.operation_json.reference.time_start.value
      );
      // not sure if need to use telemetry data to calculate instead of operation json
      // use operation json for now, for display purporse
      // *** Duration Calculations
      organizeDataComplete.totalFlightDuration += checkTimeDifference(
        singleOperation.operation_json.reference.time_start.value,
        singleOperation.operation_json.reference.time_end.value
      );
      organizeDataComplete.totalFlightDurationByMonth[
        monthNames[month.getMonth() - 1]
      ]
        ? (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByMonth[
            monthNames[month.getMonth() - 1]
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));
      organizeDataComplete.totalFlightDurationByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] += checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ))
        : (organizeDataComplete.totalFlightDurationByOpsType[
            singleOperation.operation_json.details.type
          ] = checkTimeDifference(
            singleOperation.operation_json.reference.time_start.value,
            singleOperation.operation_json.reference.time_end.value
          ));
      // *** Duration Calculations END
      // Platform Calculations
      try {
        singleOperation.operation_json.request.platform_uuid.forEach(
          (singlePlatformUuid: any, index: number) => {
            if (singlePlatformUuid === platformData.platform_uuid) {
              organizeDataComplete.opsByPilot[
                pilotList[
                  singleOperation.operation_json.request.pilot_uuid[index]
                ].name
              ]
                ? (organizeDataComplete.opsByPilot[
                    pilotList[
                      singleOperation.operation_json.request.pilot_uuid[index]
                    ].name
                  ] += 1)
                : (organizeDataComplete.opsByPilot[
                    pilotList[
                      singleOperation.operation_json.request.pilot_uuid[index]
                    ].name
                  ] = 1);
              organizeDataComplete.totalFlightDurationByPilot[
                pilotList[
                  singleOperation.operation_json.request.pilot_uuid[index]
                ].name
              ]
                ? (organizeDataComplete.totalFlightDurationByPilot[
                    pilotList[
                      singleOperation.operation_json.request.pilot_uuid[index]
                    ].name
                  ] += checkTimeDifference(
                    singleOperation.operation_json.reference.time_start.value,
                    singleOperation.operation_json.reference.time_end.value
                  ))
                : (organizeDataComplete.totalFlightDurationByPilot[
                    pilotList[
                      singleOperation.operation_json.request.pilot_uuid[index]
                    ].name
                  ] = checkTimeDifference(
                    singleOperation.operation_json.reference.time_start.value,
                    singleOperation.operation_json.reference.time_end.value
                  ));
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
      // *** Platform Calculations END
      organizeDataComplete.opsByOpsType[
        singleOperation.operation_json.details.type
      ]
        ? (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] += 1)
        : (organizeDataComplete.opsByOpsType[
            singleOperation.operation_json.details.type
          ] = 1);
      organizeDataComplete.opsByMonth[monthNames[month.getMonth() - 1]]
        ? (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] += 1)
        : (organizeDataComplete.opsByMonth[
            monthNames[month.getMonth() - 1]
          ] = 1);
    });
    setChartData(organizeDataComplete);
    setLoading(false);
  };

  useEffect(() => {
    platformOperationsRef.current = [];
    getAssets();
  }, []);

  useEffect(() => {
    getData();
  }, [pilotList, platformTypeList]);

  return (
    <div
      className="p-2 h-[600px] overflow-auto w-[800px]"
      onClick={(e) => e.stopPropagation()}
    >
      {loading && (
        <Loader className="mt-40" isLoading={loading} isError={false} />
      )}
      {!loading && (
        <div className="flex-col">
          <div className="flex">
            <table className="table-auto w-full mr-2">
              <tbody>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Platform Name</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {platformData.platform_callsign}
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Platform Type</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {platformTypeList[platformData.platform_type_uuid]}
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">License Number</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {platformData.platform_registration}
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Expiry Date</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {platformData.registration_expiry.split("T")[0]}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table-auto w-full">
              <tbody>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Total Flight Time</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.totalFlightDuration?.toFixed(2)} Hours
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Operation Count</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.totalOperationCount} Operations
                  </td>
                </tr>
                <tr className="border rounded-t-md">
                  <td className="px-4 py-2">Non Conformance Status</td>
                  <th>:</th>
                  <td className="px-4 py-2">
                    {chartData?.countOfNonConformance} Operations
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="grid grid-cols-2 -ml-3">
            {chartData &&
              cardDetails.map((singleCard, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${index}${singleCard.cardHeader}`}>
                  {singleCard.type === "pieChart" && (
                    <PieChart
                      chartData={chartData[singleCard.chartData]}
                      chartName={singleCard.cardHeader}
                      showLegend
                    />
                  )}
                  {singleCard.type === "barChart" && (
                    <BarChart
                      chartData={chartData[singleCard.chartData]}
                      chartName={singleCard.cardHeader}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
